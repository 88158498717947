import { Injectable } from '@angular/core'
import { FormArray, FormGroup } from '@angular/forms'

import { CalculationType } from '@app/modules/equipment/models/calculation-type.model'
import { UnitRange } from '@app/modules/shared/models/engineering-units/unit-range.model'
import { isNotAValue } from '@app/utils/app-utils.function'
import { CalculationStrategy } from '../models/calculcation-strategy.model'
import { CalibrationDetails } from '../models/calibration-details.model'
import { WeightTemplate } from '../models/weight-template.model'
import { RangeCalculationService } from './range-calculation.service'

@Injectable({
    providedIn: 'root'
})
export class WeightCalibrationService {
    constructor(private rangeCalculationService: RangeCalculationService) { }

    public isInRange(calibration: CalibrationDetails, template: WeightTemplate, value: number): boolean {
        const calculationStrategy = {
            numberOfPoint: template.numberOfPoint,
            calculationType: { id: CalculationStrategy.Linear, name: 'Linear' } as CalculationType,
            tolerance: template.tolerance
        }

        const input = {
            maximumRange: template.input.maximumRange,
            minimumRange: 0
        } as UnitRange

        const range = this.rangeCalculationService.calculateRange(
            calculationStrategy,
            input,
            calibration.calibrationTemplate.numberOfPoint
        )

        return this.rangeCalculationService.calculateInTolerance(
            { maximumRange: range.max, minimumRange: range.min } as UnitRange,
            value
        )
    }

    public autoPopulated(formGroup: FormGroup, calibrationDetails: CalibrationDetails, template: WeightTemplate): void {
        const calibrationResult = formGroup.get('results.calibrationResult.results') as FormArray
        const calibrationValue = calibrationResult.controls[0].get('resultSet') as FormArray

        const numberOfPoint = calibrationDetails.calibrationTemplate.numberOfPoint
        let asFoundCompleted = 0
        let asLeftCompleted = 0
        let asFoundPass = 0

        calibrationValue.controls.forEach(control => {
            asFoundPass += this.isInRange(calibrationDetails, template, control.get('asFound').value) ? 1 : 0
            asFoundCompleted += isNotAValue(control.get('asFound').value) ? 0 : 1
        })

        calibrationValue.controls.forEach(control =>
            asLeftCompleted += isNotAValue(control.get('asLeft').value) ? 0 : 1
        )

        if (asFoundPass === numberOfPoint && asFoundCompleted === numberOfPoint && asLeftCompleted === 0) {
            calibrationValue.controls.forEach(control =>
                control.patchValue(
                    { asLeft: control.get('asFound').value }
                )
            )
        }
    }
}
