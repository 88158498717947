import { Injectable } from '@angular/core'

import { SpecialUomCode } from '@app/models/special-uom.model'
import { CalculationType } from '@app/modules/equipment/models/calculation-type.model'
import { UnitRange } from '@app/modules/shared/models/engineering-units/unit-range.model'
import { UnitValue } from '@app/modules/shared/models/engineering-units/unit-value.model'
import { round } from '@app/utils/app-utils.function'
import { CalculationStrategy } from '../models/calculcation-strategy.model'

export interface ICalculationStrategy {
    numberOfPoint: number
    calculationType: CalculationType
    tolerance: UnitValue
}

@Injectable({
    providedIn: 'root'
})
export class RangeCalculationService {
    public calculatePoint(template: ICalculationStrategy, range: UnitRange, point: number): number {
        let baseFlow = template.numberOfPoint <= 1 ? 1 : ((point - 1) / (template.numberOfPoint - 1))

        if (template.calculationType.id === CalculationStrategy.SquareRootAtInput) {
            baseFlow = baseFlow * baseFlow
        }

        if (template.calculationType.id === CalculationStrategy.SquareRootAtOutput) {
            baseFlow = Math.sqrt(baseFlow)
        }

        return round(baseFlow * (range.maximumRange - range.minimumRange) + range.minimumRange)
    }

    public calculateInjectExpectedResult(
        injected: number,
        inputRange: UnitRange,
        expectedRange: UnitRange,
        calculationType: CalculationStrategy
    ): number {
        let baseFlow = (injected - inputRange.minimumRange) / (inputRange.maximumRange - inputRange.minimumRange)
        if (calculationType !== CalculationStrategy.Linear) {
            baseFlow = Math.sqrt(Math.abs(baseFlow))
        }

        return round(baseFlow * (expectedRange.maximumRange - expectedRange.minimumRange) + expectedRange.minimumRange)
    }

    public calculateRange(template: ICalculationStrategy, range: UnitRange, point: number): { min: number, max: number } {
        const output = this.calculatePoint(template, range, point)
        const tolerance = this.calculateTolerance(template.tolerance, range)

        return {
            min: round(output - tolerance),
            max: round(output + tolerance)
        }
    }

    public calculateRangeDifference(numberOfPoints: Array<number>): number {
        let maxDifference = 0
        const length = numberOfPoints.length
        for (let i = 0; i < length; i++) {
            for (let j = 0; j < length; j++) {
                if (i !== j) {
                    const curDifference = Math.abs(numberOfPoints[i] - numberOfPoints[j])
                    if (curDifference > maxDifference) {
                        maxDifference = curDifference
                    }
                }
            }
        }

        return round(maxDifference)
    }

    public calculateInTolerance(range: UnitRange, value: number): boolean {
        return value >= range.minimumRange && value <= range.maximumRange
    }

    public calculateTolerance(tolerance: UnitValue, range: UnitRange): number {
        if (tolerance.unitOfMeasurement.uomCode === SpecialUomCode.Percentage) {
            return round((tolerance.value / 100.0) * (range.maximumRange - range.minimumRange))
        }

        return tolerance.value
    }

    public calculatePercentageToleranceToValue(value: number, percentageTolerance: number): number {
        return round((percentageTolerance / 100) * value)
    }
}
