import { Component, EventEmitter, Input, Output } from '@angular/core'

export interface Tab {
    name: string
}

@Component({
    selector: 'app-tab-panel',
    templateUrl: './tab-panel.component.html',
    styleUrls: ['./tab-panel.component.scss']
})
export class TabPanelComponent {
    @Input() tabs: any[] = []
    @Input() tabContentIconName: string = null

    @Input() selectedTabIndex: number
    @Input() extraButtonText: string = null
    @Output() chooseTabIndex = new EventEmitter<number>()
    @Output() triggerAction = new EventEmitter<null>()
    @Output() triggerTabIconAction = new EventEmitter<number>()

    public onClickTab(tabIndex: number): void {
        this.chooseTabIndex.emit(tabIndex)
    }

    public onClickAction(): void {
        this.triggerAction.emit()
    }

    public onClickTabIcon(tabIndex: number): void {
        this.triggerTabIconAction.emit(tabIndex)
    }
}
