import { Component, OnDestroy, OnInit } from '@angular/core'
import { AbstractControl, FormArray, FormBuilder, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'

import { User } from '@app/models/user.model'
import { CustomSearchEquipment } from '@app/modules/equipment/models/custom-search-equipment.model'
import { SapEquipmentQueryParameter } from '@app/modules/equipment/models/sap-equipment-query-parameter.model'
import { CustomSearchTestEquipment, TestEquipment } from '@app/modules/test-equipment/models/test-equipment.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import { currentUser } from '@app/store/identity/identity.selectors'
import { isLoading } from '@app/store/loader/loader.selectors'
import { CreateTestEquipmentAction, SearchNewTestEquipmentAction } from '@app/store/test-equipment/test-equipment.actions'
import { testEquipmentToBeAdded } from '@app/store/test-equipment/test-equipment.selectors'

@Component({
    selector: 'app-test-equipment-add-modal',
    templateUrl: './test-equipment-add-modal.component.html',
    styleUrls: ['./test-equipment-add-modal.component.scss']
})
export class TestEquipmentAddModalComponent extends SafeUnsubscriberComponent implements OnInit, OnDestroy {
    public isLoading$: Observable<boolean>
    public searchResults: CustomSearchEquipment[]
    public sapEquipmentQuery: SapEquipmentQueryParameter
    public testEquipmentForm: FormArray
    public currentUser: User
    public isOpen: boolean

    public searchText: string

    constructor(private store: Store<AppState>, private formBuilder: FormBuilder) {
        super()
        this.sapEquipmentQuery = {
            equipmentTag: undefined,
            planningPlant: undefined,
            usrStatus: undefined,
            isTestEquipment: true,
            sortBy: 'equipmentTag',
            page: '1',
            perPage: '5'
        } as SapEquipmentQueryParameter
        this.testEquipmentForm = this.formBuilder.array([])
        this.isOpen = false
    }

    ngOnInit(): void {
        this.isLoading$ = this.store.select(isLoading)
        this.addSubscription(this.store.pipe(select(testEquipmentToBeAdded)).subscribe(testEquipment => {
            this.searchResults = testEquipment
            this.testEquipmentForm = this.formBuilder.array([])
            testEquipment.forEach(eq => {
                const control = this.formBuilder.group({
                    equipmentId: this.formBuilder.control(eq.equipmentId),
                    plantCode: this.formBuilder.control(eq.plantCode),
                    certificationExpiryDate: this.formBuilder.control(eq.certificationExpiryDate, Validators.required)
                })
                this.testEquipmentForm.push(control)
            })
        }))
        this.addSubscription(this.store.select(currentUser).subscribe(user => this.sapEquipmentQuery.planningPlant = user.preferredPlantCode))
        this.isOpen = false
    }

    public searchByEquipmentTag(searchText: string): void {
        const sapEquipmentQueryParameters = { ...this.sapEquipmentQuery }
        sapEquipmentQueryParameters.equipmentTag = searchText
        this.store.dispatch(new SearchNewTestEquipmentAction({ ...sapEquipmentQueryParameters }))

        this.isOpen = true
    }

    public addTestEquipment(equipment: CustomSearchTestEquipment): void {
        const newEquipment: TestEquipment = {
            equipmentId: equipment.equipmentId,
            equipmentTag: equipment.equipmentTag,
            description: equipment.description,
            isActive: true,
            plantCode: equipment.plantCode,
            certificationExpiryDate: this.getControl(equipment).get('certificationExpiryDate').value,
            isExpired: false
        }
        this.store.dispatch(new CreateTestEquipmentAction(newEquipment))
    }

    public closeModal(): void {
        this.isOpen = false
    }

    public getControl(eq: CustomSearchTestEquipment): AbstractControl {
        const index = this.testEquipmentForm.controls.findIndex(c =>
            c.get('equipmentId').value === eq.equipmentId && c.get('plantCode').value === eq.plantCode)

        return this.testEquipmentForm.controls[index]
    }
}
