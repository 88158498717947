import { ApplicationRef, enableProdMode } from '@angular/core'
import { enableDebugTools } from '@angular/platform-browser'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { datadogRum } from '@datadog/browser-rum'

import { AppModule } from './app/app.module'
import { environment } from '@environments/environment'

if (!environment.development) {
    enableProdMode()
}

// (Production only)
// This sends data to DataDog for user behavior tracing in addition to Application Insights.
// See data collected: https://app.datadoghq.com/account/login/id/7bebd0eda
// Ref: https://docs.datadoghq.com/real_user_monitoring/
if (environment.name?.toLocaleLowerCase() === 'mrma sb') {
    datadogRum.init({
        applicationId: 'e51a5098-5585-42bd-be08-8b8865690853',
        clientToken: 'pube35ddaadfa66a4039e547f1521fc16ed',
        site: 'datadoghq.com',
        service:'mrma-web-sandbox',

        version: environment.version,
        sampleRate: 100,
        sessionReplaySampleRate: 100,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask',

        // Workaround for the issue that DataDog can't differentiate paths with '#'
        // Ref: https://github.com/DataDog/browser-sdk/issues/540
        beforeSend: event => {
            event.view.url = event.view.url.replace('#/', '')
        }
    })

    datadogRum.startSessionReplayRecording()
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(moduleRef => {

        if (environment.development) {
            // Enable console debug tools
            // e.g. `ng.profiler.timeChangeDetection()`
            const appRef = moduleRef.injector.get(ApplicationRef)
            const componentRef = appRef.components[0]

            enableDebugTools(componentRef)
        }
    })
    .catch(err => console.error(err))
