import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Store } from '@ngrx/store'

import { equipmentFilterLists } from '@app/modules/equipment/constants/equipmentFilterLists.constant'
import { EquipmentQueryParameter } from '@app/modules/equipment/models/equipment-query-parameter.model'
import { ReportListQueryParameter } from '@app/modules/report/models/report-list-query.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import {
    GetCategoryListAction, GetClassificationListAction, GetCriticalityListAction, GetLocationListAction, GetMaintenancePlantListAction,
    GetMainWorkCenterListAction, GetPlannerGroupListAction, GetPlantSectionListAction
} from '@app/store/equipment/actions/equipment-list.actions'
import {
    equipmentABCIndicatorFilterOptions, equipmentCategoryFilterOptions, equipmentClassificationFilterOptions, equipmentLocationFilterOptions,
    equipmentMaintenancePlantFilterOptions, equipmentMainWorkCenterFilterOptions, equipmentPlannerGroupFilterOptions,
    equipmentPlantSectionFilterOptions
} from '@app/store/equipment/selectors/equipment-list.selectors'
import { deepCopy, safeCallback } from '@app/utils/app-utils.function'

@Component({
    selector: 'app-combined-filter',
    templateUrl: './combined-filter.component.html',
    styleUrls: ['./combined-filter.component.scss']
})
export class CombinedFilterComponent extends SafeUnsubscriberComponent implements OnInit {
    @Input() query: ReportListQueryParameter | EquipmentQueryParameter
    @Input() clearFilterCallback: (...args: any) => any | void
    @Output() filterListState = new EventEmitter<any>(null)

    public equipmentFilterLists = deepCopy(equipmentFilterLists)

    constructor(private store: Store<AppState>) {
        super()
    }

    ngOnInit(): void {
        this.store.dispatch(new GetCriticalityListAction())
        this.store.dispatch(new GetPlannerGroupListAction())
        this.store.dispatch(new GetMainWorkCenterListAction())
        this.store.dispatch(new GetMaintenancePlantListAction())
        this.store.dispatch(new GetPlantSectionListAction())
        this.store.dispatch(new GetLocationListAction())
        this.store.dispatch(new GetCategoryListAction())
        this.store.dispatch(new GetClassificationListAction())

        this.addSubscriptions([
            this.store.select(equipmentABCIndicatorFilterOptions).subscribe(options => {
                this.equipmentFilterLists.criticality.value = deepCopy(options)
                this.applyFilterStateFromQuery()
            }),
            this.store.select(equipmentPlannerGroupFilterOptions).subscribe(options => {
                this.equipmentFilterLists.plannerGroup.value = deepCopy(options)
                this.applyFilterStateFromQuery()
            }),
            this.store.select(equipmentMainWorkCenterFilterOptions).subscribe(options => {
                this.equipmentFilterLists.mainWorkCenter.value = deepCopy(options)
                this.applyFilterStateFromQuery()
            }),
            this.store.select(equipmentMaintenancePlantFilterOptions).subscribe(options => {
                this.equipmentFilterLists.maintenancePlant.value = deepCopy(options)
                this.applyFilterStateFromQuery()
            }),
            this.store.select(equipmentPlantSectionFilterOptions).subscribe(options => {
                this.equipmentFilterLists.plantSection.value = deepCopy(options)
                this.applyFilterStateFromQuery()
            }),
            this.store.select(equipmentLocationFilterOptions).subscribe(options => {
                this.equipmentFilterLists.location.value = deepCopy(options)
                this.applyFilterStateFromQuery()
            }),
            this.store.select(equipmentCategoryFilterOptions).subscribe(options => {
                this.equipmentFilterLists.category.value = deepCopy(options)
                this.applyFilterStateFromQuery()
            }),
            this.store.select(equipmentClassificationFilterOptions).subscribe(options => {
                this.equipmentFilterLists.classification.value = deepCopy(options)
                this.applyFilterStateFromQuery()
            })
        ])
    }

    public filterOptionClicked(): void {
        this.filterListState.next(this.equipmentFilterLists)
    }

    public isEquipmentFilterSelected(filterName: string): boolean {
        const filterField = this.equipmentFilterLists[filterName] as { value: any[] }
        return filterField.value.some(f => f?.selected)
    }

    public clearAllFilters(): void {
        this.equipmentFilterLists.criticality.value.forEach(f => delete f['selected'])
        this.equipmentFilterLists.plannerGroup.value.forEach(f => delete f['selected'])
        this.equipmentFilterLists.mainWorkCenter.value.forEach(f => delete f['selected'])
        this.equipmentFilterLists.maintenancePlant.value.forEach(f => delete f['selected'])
        this.equipmentFilterLists.plantSection.value.forEach(f => delete f['selected'])
        this.equipmentFilterLists.location.value.forEach(f => delete f['selected'])
        this.equipmentFilterLists.category.value.forEach(f => delete f['selected'])
        this.equipmentFilterLists.classification.value.forEach(f => delete f['selected'])

        safeCallback(this.clearFilterCallback)
    }

    private applyFilterStateFromQuery(): void {
        const eqf = this.equipmentFilterLists
        const queryStateList = [
            { name: 'criticality', hasQuery: this.query?.criticality, hasOption: eqf.criticality.value.length > 0 },
            { name: 'plannerGroup', hasQuery: this.query?.plannerGroup, hasOption: eqf.plannerGroup.value.length > 0 },
            { name: 'mainWorkCenter', hasQuery: this.query?.mainWorkCenter, hasOption: eqf.mainWorkCenter.value.length > 0 },
            { name: 'maintenancePlant', hasQuery: this.query?.maintenancePlant, hasOption: eqf.maintenancePlant.value.length > 0 },
            { name: 'plantSection', hasQuery: this.query?.plantSection, hasOption: eqf.plantSection.value.length > 0 },
            { name: 'location', hasQuery: this.query?.location, hasOption: eqf.location.value.length > 0 },
            { name: 'category', hasQuery: this.query?.category, hasOption: eqf.category.value.length > 0 },
            { name: 'classification', hasQuery: this.query?.classification, hasOption: eqf.classification.value.length > 0 }
        ]

        for (const state of queryStateList) {
            if (!state.hasOption || !state.hasQuery) {
                continue
            }

            const params = this.query[state.name].split(',')
            for (const option of eqf[state.name].value) {
                const optionFound = params.some(p => p === option.name)
                if (optionFound) {
                    option['selected'] = true
                }
            }
        }
    }
}
