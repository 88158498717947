import { FormControlError } from '@app/modules/shared/models/form-control-error.model'
import { FormValidatorResult } from '@app/modules/shared/models/form-validator-result.model'
import { humanizeStr } from '@app/utils/strings/humanize-str'
import { FormErrorHandler } from './abstract-form-error-handler'
import { ValidatorFn } from '@angular/forms'
import { every, isEmpty, isNil, negate, pick } from 'lodash'

export class GeneralAfAlErrorHandler extends FormErrorHandler {
    public handle(formControlError: FormControlError, results: FormValidatorResult, ...args: any): FormValidatorResult {
        if (formControlError.errorKey !== 'generalAfAlResultInvalid') {
            return this.next?.handle(formControlError, results, ...args)
        }
        const errorMessage = humanizeStr`PM Calibration part is not valid, please leave it as all empty or fill up all of them.`

        return {
            ...results,
            preventSubmit: true,
            errorMessages: [
                ...results.errorMessages, errorMessage
            ]
        }
    }
}

export const isGeneralAfAlResultValid: ValidatorFn = (control) => {
    const resultSet = control.value.results[0].resultSet[0]
    const readingData = pick(resultSet, ['asFound', 'asLeft'])
    const uomData = pick(resultSet, 'unitOfMeasurement')

    const isEmptyNum = (value: number | null) => isNil(value) || isNaN(value)
    const isEmptyObj = (value: {}) => isEmpty(value)

    const allEmpty = every(readingData, isEmptyNum) && every(uomData, isEmptyObj)
    const allExist = every(readingData, negate(isEmptyNum)) && every(uomData, negate(isEmptyObj))
    const isValid = allEmpty || allExist

    return isValid ? null : { generalAfAlResultInvalid: true }
}
