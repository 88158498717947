import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'

/**
 * Does not do anything. Use this to declare variables or catch Async pipe output without alter the DOM
 */
@Directive({
    selector: '[appLet]'
})
export class LetDirective {

    private hasView = false
    private context: { $implicit: any, appLet: any } = {
        $implicit: null,
        appLet: null
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private vcRef: ViewContainerRef
    ) {
    }

    @Input() set appLet(value: any) {
        this.context.appLet = value
        this.context.$implicit = this.context.appLet

        if (!this.hasView) {
            this.vcRef.createEmbeddedView(this.templateRef, this.context)
            this.hasView = true
        }
    }
}
