import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'

import { TemplateSelectionComponent } from '@app/modules/equipment/components/modals/template-selection/template-selection.component'
import { ModalContainerComponent } from '@app/modules/modal-container/modal-container.component'
import { SharedModule } from '@app/modules/shared/shared.module'
import {
    CalibrationTemplateSelectionComponent
} from '../calibration/components/modals/calibration-template-selection/calibration-template-selection.component'
import { DeferredNotTestedComponent } from '../calibration/components/modals/deferred-not-tested/deferred-not-tested.component'
import { EquipmentAddModalComponent } from '../equipment/components/modals/equipment-add-modal/equipment-add-modal.component'
import { FlamingoFormSelectionComponent } from '../equipment/components/modals/form-selection/form-selection.component'
import { FlamingoModule } from '../flamingo/flamingo.module'
import { ReportFilterPresetModalComponent } from '../report/modals/report-filter/report-filter.component'
import { TestEquipmentAddModalComponent } from '../test-equipment/components/modals/test-equipment-add-modal/test-equipment-add-modal.component'
import {
    TestEquipmentUpdateModalComponent
} from '../test-equipment/components/modals/test-equipment-update-modal/test-equipment-update-modal.component'
import { UserUpdateModalComponent } from '../user/components/modals/user-update-modal/user-update-modal.component'

const modalComponentArray: any[] = [
    EquipmentAddModalComponent,
    TemplateSelectionComponent,
    CalibrationTemplateSelectionComponent,
    UserUpdateModalComponent,
    TestEquipmentUpdateModalComponent,
    TestEquipmentAddModalComponent,
    DeferredNotTestedComponent,
    FlamingoFormSelectionComponent,
    ReportFilterPresetModalComponent,
    ModalContainerComponent,
]

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        FlamingoModule
    ],
    declarations: [
        ...modalComponentArray,

    ],
    exports: [
        ModalContainerComponent
    ],
    bootstrap: [
        ...modalComponentArray
    ]
})

export class ModalContainerModule { }

