import { UnitRange } from '@app/modules/shared/models/engineering-units/unit-range.model'
import { UnitValue } from '@app/modules/shared/models/engineering-units/unit-value.model'
import { CalibrationTemplate } from './calibration-template.model'

export class TemperatureTemplate extends CalibrationTemplate {
    numberOfPoint: number
    input: UnitRange
    expected: UnitRange
    tolerance: UnitValue
    isInstrumentRequired: boolean
}
