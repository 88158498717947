import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular'
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser/dist'

import { LoggerService, LogLevel } from '@app/services/logger.service'
import { environment } from '@environments/environment'

const protectedResourceMap: [string, string[]][] = [
    [environment.baseUrl + '/*', environment.auth.scopes],
    [environment.functionAppUrl + '/*', environment.auth.scopes]
]

const logger = new LoggerService('MSAL Logging')

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1

export function loggerCallback(logLevel: LogLevel, message: string): void {
    if (environment.development) {
        logger.log(logLevel, message)
    }
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: environment.auth,
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false
            }
        }
    })
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map(protectedResourceMap),
    }
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        loginFailedRoute: '/unauthorized'
    }
}
