import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core'
import { Store } from '@ngrx/store'
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs'

import { TooltipsComponent } from '@app/modules/shared/components/tooltips/tooltips.component'
import { FeatureTips } from '@app/modules/shared/models/tooltip.model'
import { AppState } from '@app/store/app.store'
import { isLoading } from '@app/store/loader/loader.selectors'
import { toolTipAcknowledgementState } from '@app/store/tooltip/tooltip.selectors'

@Directive({
    selector: '[appToolTip]'
})
export class ToolTipDirective implements OnDestroy {
    public componentRef: ComponentRef<TooltipsComponent>

    private featureTips$: BehaviorSubject<FeatureTips>
    private tipIndex$: BehaviorSubject<number>
    private tooltipSubscription: Subscription

    constructor(
        private vcRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        private resolver: ComponentFactoryResolver,
        private store: Store<AppState>) {

        this.featureTips$ = new BehaviorSubject<FeatureTips>(null)

        this.tipIndex$ = new BehaviorSubject<number>(-2)

        this.tooltipSubscription = combineLatest([
            this.store.select(toolTipAcknowledgementState),
            this.store.select(isLoading),
            this.featureTips$,
            this.tipIndex$
        ]).subscribe(([currentState, loading, tips, index]) => {
            const currentTurn = currentState[tips?.featureName ?? ''] ?? -1
            const isMyTurn = currentTurn === index - 1
            if (loading || !tips || !isMyTurn) {
                this.displayUiNormally()
            } else {
                this.highlightUiAndAddToolTip(tips, index)
            }
        })
    }

    @Input() set appToolTip(tips: FeatureTips) {
        this.featureTips$.next(tips)
    }

    @Input() set appToolTipIndex(index: number) {
        this.tipIndex$.next(index)
    }

    ngOnDestroy(): void {
        this.tooltipSubscription.unsubscribe()
    }

    private highlightUiAndAddToolTip(tips: FeatureTips, index: number): void {
        this.vcRef.clear()
        const tooltipComp = this.resolver.resolveComponentFactory(TooltipsComponent)
        this.componentRef = this.vcRef.createComponent(tooltipComp)
        this.componentRef.instance.featureTips = tips
        this.componentRef.instance.tipIndex = index
        this.componentRef.instance.template = this.templateRef
    }

    private displayUiNormally(): void {
        this.vcRef.clear()
        this.vcRef.createEmbeddedView(this.templateRef)
    }
}
