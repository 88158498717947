import { Component, ElementRef, Input, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'

import { EquipmentHistory } from '@app/modules/calibration/models/equipment-history.model'
import { SectionLoaderHelperComponent } from '@app/modules/shared/components/section-loader/section-loader-helper-component'
import { SlideUpOverlayType } from '@app/modules/shared/constants/slide-up-overlay.enum'
import { SectionLoaderEnum } from '@app/modules/shared/models/section-loader.enum'
import { SectionLoaderService } from '@app/modules/shared/services/section-loader.service'
import { AppState } from '@app/store/app.store'
import { GetEquipmentHistoryAction } from '@app/store/equipment-history/equipment-history.actions'
import { equipmentHistory } from '@app/store/equipment-history/equipment-history.selectors'
import { SlideOverlayAction } from '@app/store/overlay/overlay.actions'

@Component({
    selector: 'app-equipment-history-mobile',
    templateUrl: './equipment-history-mobile.component.html',
    styleUrls: ['./equipment-history-mobile.component.scss']
})
export class EquipmentHistoryMobileComponent extends SectionLoaderHelperComponent {

    public equipmentHistory: EquipmentHistory

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private store: Store<AppState>,
        sectionLoaderService: SectionLoaderService
    ) {
        super(sectionLoaderService)
    }
    @Input() set fetchEquipmentHistory(fetch: boolean) {
        if (fetch) {
            this.initEquipmentHistory()
        }
    }

    @ViewChild('equipmentHistoryMobileRef', { static: true }) set sectionLoader(ref: ElementRef) {
        if (!this.sectionLoaderRef) {
            this.registerSectionLoader(SectionLoaderEnum.EquipmentHistory, ref)
        }
    }

    public closeOverlay(): void {
        this.store.dispatch(new SlideOverlayAction({ visibility: false, id: SlideUpOverlayType.EquipmentHistory }))
    }

    private initEquipmentHistory(): void {
        this.addSubscription(this.route.params.subscribe(params => {
            if (params['equipmentId']) {
                this.store.dispatch(new GetEquipmentHistoryAction(params['equipmentId']))
            }
        }))

        this.addSubscription(this.store.select(equipmentHistory).subscribe(eqHistory => {
            this.equipmentHistory = eqHistory
        }))
    }

}
