import { Component, Input, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'

import { WorkOrderService } from '@app/modules/work-order/services/work-order.service'
import { AppState } from '@app/store/app.store'
import { GetNotificationAction } from '@app/store/work-order/work-order.actions'

@Component({
    selector: 'app-work-order-info',
    templateUrl: './work-order-info.component.html',
    styleUrls: ['./work-order-info.component.scss']
})
export class WorkOrderInfoComponent implements OnInit {
    @Input() workOrder: any

    public workOrderStatus: string

    constructor(
        public store: Store<AppState>,
        private workOrderService: WorkOrderService
    ) { }

    public get abcIndicator(): string {
        const description = this.workOrder.abcDescription ? `-${this.workOrder.abcDescription}` : ''
        return `${this.workOrder.abcDescription}${description}`
    }


    ngOnInit(): void {
        this.workOrderStatus = this.workOrderService.calculateWorkOrderStatusPill(this.workOrder.systemStatus)

        if (this.workOrder.notification.number) {
            this.store.dispatch(GetNotificationAction({
                workOrderNumber: this.workOrder.workOrderNumber,
                notificationNumber: this.workOrder.notification.number
            }))
        }
    }

    public getPrimaryNotification(notificationNumber: string): string {
        return this.workOrderService.getPrimaryNotification(notificationNumber, this.workOrder.equipmentCalibrations)
    }
}
