import 'zone.js/plugins/zone-error'

import { environment as base } from './environment.base'

export const environment = {
    ...base,
    development: true,
    serviceWorker: true,
    name: 'MRMA SB',
    baseUrl: 'https://api-sb.mrma.xom.cloud',
    apiVersion: 'v2',
    auth: {
        clientId: '4de9e62a-158e-4fa4-8d99-5193c6a18494',
        authority: 'https://login.microsoftonline.com/EMCloudAD.onmicrosoft.com/',
        validateAuthority: false,
        navigateToLoginRequestUrl: false,
        redirectUri: 'https://sb.mrma.xom.cloud', // http://localhost:4200
        postLogoutRedirectUri: 'https://sb.mrma.xom.cloud', // http://localhost:4200
        scopes: [
            'api://01ca348f-538c-4217-ac9d-af5377e04139/MRMASand/Read',
            'api://01ca348f-538c-4217-ac9d-af5377e04139/MRMASand/Write'
        ]
    },
    flamingoForm: {
        baseUrl: 'https://api.sb.flamingo.xom.cloud/api',
        scopes: [
            'api://962e0620-095f-464d-886a-e061e686854b/flamingo-sb/User'
        ],
        environment: 'sb'
    },
    features: {
        showOpenInDev: true,
        'flamingo-viewer': true,
        'flamingo-builder': true
    },
    debug: {
        logLevel: '',
        logCDTime: false,
        enableClassProfiler: false,
        enableFnProfiler: false
    },
    functionAppUrl: 'https://mrma-funct-sb.azurewebsites.net'
}
