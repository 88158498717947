import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'

import { WorkOrderService } from '@app/modules/work-order/services/work-order.service'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { AppState } from '../app.store'
import {
    GetNotificationAction, GetNotificationFailureAction, GetNotificationsAction, GetNotificationSuccessAction, GetWorkOrderLocationAction, GetWorkOrderLocationSuccessAction,
    GetWorkOrderMaintenancePlantAction, GetWorkOrderMaintenancePlantSuccessAction, GetWorkOrderMainWorkCenterAction,
    GetWorkOrderMainWorkCenterSuccessAction, GetWorkOrderPlannerGroupAction, GetWorkOrderPlannerGroupSuccessAction, GetWorkOrderPlantSectionAction,
    GetWorkOrderPlantSectionSuccessAction, LoadWorkOrderDetailsAction, LoadWorkOrderDetailsSuccessAction
} from './work-order.actions'
import { notifications } from './work-order.selectors'

@Injectable()
export class WorkOrderEffects {


    public getWorkOrderDetails: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(LoadWorkOrderDetailsAction),
        switchMap((action) =>
            this.responseHandlerService.query(() => this.workOrderService.getWorkOrderDetails(action.id, action.fetchNotification))
                .pipe(
                    map(workOrderDetails => LoadWorkOrderDetailsSuccessAction({ workOrderDetails })),
                    catchError(_ => of(LoadWorkOrderDetailsSuccessAction(null)))
                ))))


    getMainWorkCenterList: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(GetWorkOrderMainWorkCenterAction),
        mergeMap(() =>
            this.responseHandlerService
                .query(() => this.workOrderService.getWorkOrderFilterListBy('MainWorkCenter'), this.useSimpleStrategy)
                .pipe(
                    map(response => GetWorkOrderMainWorkCenterSuccessAction({ mainWorkCenter: response.body }))
                ))))


    getPlannerGroupList: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(GetWorkOrderPlannerGroupAction),
        mergeMap(() =>
            this.responseHandlerService
                .query(() => this.workOrderService.getWorkOrderFilterListBy('PlannerGroup'), this.useSimpleStrategy)
                .pipe(
                    map(response => GetWorkOrderPlannerGroupSuccessAction({ PlannerGroup: response.body }))
                ))))


    getPlantSectionList: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(GetWorkOrderPlantSectionAction),
        mergeMap(() =>
            this.responseHandlerService
                .query(() => this.workOrderService.getWorkOrderFilterListBy('PlantSection'), this.useSimpleStrategy)
                .pipe(
                    map(response => GetWorkOrderPlantSectionSuccessAction({ plantSection: response.body }))
                ))))


    getMaintenancePlantList: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(GetWorkOrderMaintenancePlantAction),
        mergeMap(() =>
            this.responseHandlerService
                .query(() => this.workOrderService.getWorkOrderFilterListBy('MaintenancePlant'), this.useSimpleStrategy)
                .pipe(
                    map(response => GetWorkOrderMaintenancePlantSuccessAction({ maintenancePlant: response.body }))
                ))))


    getLocationList: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(GetWorkOrderLocationAction),
        mergeMap(() =>
            this.responseHandlerService
                .query(() => this.workOrderService.getWorkOrderFilterListBy('Location'), this.useSimpleStrategy)
                .pipe(
                    map(response => GetWorkOrderLocationSuccessAction({ location: response.body }))
                ))))

    public getNotificationInfo: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(GetNotificationAction),
        mergeMap((action) => {
            return this.responseHandlerService.query(() => this.workOrderService.getNotificationInfo(action.workOrderNumber, action.notificationNumber), this.useSimpleStrategy)
                .pipe(
                    map((notification) => GetNotificationSuccessAction({ notification })),
                    catchError(_ => of(GetNotificationFailureAction()))
                )
        })))

    public getNotificationsInfo: Observable<unknown> = createEffect(() => this.actions$.pipe(
        ofType(GetNotificationsAction),
        map((action) => {
            const size = 10
            let count = 0
            this.store.select(notifications).subscribe((notificationItems) => {
                const notificationsIsOpen = Object.values(notificationItems).filter((notification) => !!(notification.number))
                if ((notificationsIsOpen.length === count)) {
                    for (let i = 0; i < size; i++) {
                        if (count < action.notificationNumbers.length) {
                            this.store.dispatch(GetNotificationAction({
                                workOrderNumber: action.workOrderNumber,
                                notificationNumber: action.notificationNumbers[count]
                            }))
                            count++
                        }
                    }
                }
            })
        }),
        catchError(() => of({ type: 'NO_ACTION' }))
    ), { dispatch: false })

    private useSimpleStrategy: ResponseHandlingStrategy

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private responseHandlerService: ResponseHandlerService,
        private workOrderService: WorkOrderService
    ) {
        this.useSimpleStrategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .responseStrategy
    }
}
