import { HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { FormGroup, Validators } from '@angular/forms'
import _ from 'lodash'
import { ToastrService } from 'ngx-toastr'
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs'
import { catchError, take, tap } from 'rxjs/operators'

import { defaultMassUpdatePayloadTempalte } from '@app/constants/mass-update.constants'
import { mrmaAlertConfigs } from '@app/models/alert-configuration.model'
import { IdentityService } from '@app/services/identity.service'
import { QueryBuilderService } from '@app/services/query-builder.service'
import { dateToLocalISOString } from '@app/utils/date.utils'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { EquipmentCalibration } from '../models/equipment-calibration.model'
import { WorkOrderDetails } from '../models/work-order-details.model'
import { pmAlertMessage } from '@app/modules/calibration/models/calibration-alert-message.model'

@Injectable({
    providedIn: 'root'
})
export class MassUpdateService {

    public updateAllClick$ = new Subject()
    public isRequestInFlight$ = new BehaviorSubject(false)

    /**
     * The form obj containing mass update configuration
     * (final result, comment, etc.)
     * It is stored here to persist the data through route
     * navigation.
     */
    public massUpdateForm: FormGroup

    // [Model] Selected Equipment Calibrations
    public selectedEquipmentCalibrations$ = new BehaviorSubject<EquipmentCalibration[]>([])

    private readonly url = `${environment.baseUrl}/api/${AppSettings.apiVersion}/Calibrations/BulkUpdate`
    private _selectedEquipmentCalibrations: EquipmentCalibration[] = []
    private _currentWorkOrder: WorkOrderDetails

    constructor(
        private queryBuilderService: QueryBuilderService,
        private idendityService: IdentityService,
        private toastrService: ToastrService
    ) { }

    // [Model] Calibration Details
    public set currentWorkOrder(workOrder: WorkOrderDetails) {
        if (this.currentWorkOrder?.workOrderNumber !== workOrder?.workOrderNumber) {
            this._currentWorkOrder = workOrder
            // If work order change, clear current selection
            this.clearAllSelection()
        }
    }
    public get currentWorkOrder(): WorkOrderDetails {
        return this._currentWorkOrder
    }

    private get selectedEquipmentCalibrations(): EquipmentCalibration[] {
        return this._selectedEquipmentCalibrations
    }
    private set selectedEquipmentCalibrations(v: EquipmentCalibration[]) {
        this._selectedEquipmentCalibrations = v
        this.selectedEquipmentCalibrations$.next(this._selectedEquipmentCalibrations)
    }

    public setDeferredNotTestedMode(isDeferredNotTestedMode: boolean): void {
        if (isDeferredNotTestedMode) {
            this.massUpdateForm?.get('finalPMResultStatus')?.setValidators([])
        } else {
            this.massUpdateForm?.get('finalPMResultStatus')?.setValidators([Validators.required])
        }
        this.massUpdateForm.updateValueAndValidity()
    }

    public clearAllSelection(): void {
        this.selectedEquipmentCalibrations = []
    }

    public setSelection(selectedEquipmentCalibrations: EquipmentCalibration[]): void {
        this.selectedEquipmentCalibrations = selectedEquipmentCalibrations
    }

    public updateSelectedEqCalibrationsWith(massUpdateFormValue: any): Observable<HttpResponse<any>> {
        if (!this.idendityService.isCurrentUserATechnician$.value) {
            // Button itself should already be disabled so we can't really send toaster here.
            return
        }
        this.isRequestInFlight$.next(true)
        const persistantToaster = this.toastrService.info(
            'Performing mass update. This could take several minutes...',
            '',
            mrmaAlertConfigs.ProgressInflight.configuration)
        const {
            plantCode,
            workOrderNumber,
            description: workOrderDescription,
            notification: {
                number: notificationNumber
            },
        } = this.currentWorkOrder

        massUpdateFormValue.pmPerformedTechnicians = massUpdateFormValue.pmPerformedTechnicians.filter(technician => !_.isNil(technician?.id))
        const payload = {
            // WorkOrder Details
            plantCode,
            workOrderNumber,
            workOrderDescription,
            notificationNumber,
            // Default Calibration Details
            ...defaultMassUpdatePayloadTempalte,
            // Masss Update Details
            ...massUpdateFormValue,
            isFinalPMResultStatusManuallySet: true,
            pmPerformedDate: dateToLocalISOString(massUpdateFormValue.pmPerformedDate, true, true),
            equipmentIds: this.selectedEquipmentCalibrations.map(eqCalibration => eqCalibration.equipmentId),
            // Copy finalPMResultStatus to calibratinResultStatus for backward compatibility
            calibrationResultStatus: massUpdateFormValue.finalPMResultStatus
        }

        return this.queryBuilderService.post(this.url, payload).pipe(
            take(1),
            tap(() => {
                this.toastrService.success('Mass update calibration succeed!')
                this.isRequestInFlight$.next(false)
                this.toastrService.remove(persistantToaster.toastId)
                this.toastrService.warning(pmAlertMessage.notificationClosureDelay, '',
                    mrmaAlertConfigs.NotificationClosureDelayWarning.configuration)
            }),
            catchError((err) => {
                this.isRequestInFlight$.next(false)
                this.toastrService.error(err?.message, 'Oops! Something went wrong.')
                this.toastrService.remove(persistantToaster.toastId)
                // Rethrow error so the subscriber can handle it in view
                return throwError(err)
            })
        )
    }


}
