import { Component, Input, OnInit } from '@angular/core'

import { SlideUpOverlayType } from '@app/modules/shared/constants/slide-up-overlay.enum'

@Component({
    selector: 'app-overlay-longtext-details',
    templateUrl: './overlay-longtext-details.component.html',
    styleUrls: ['./overlay-longtext-details.component.scss']
})
export class OverlayLongtextDetailsComponent implements OnInit {
    @Input() category: string
    @Input() itemId: string
    @Input() itemDescription: string
    @Input() longText: string

    public header: string
    public overlayId: SlideUpOverlayType

    public options = {
        Equipment: SlideUpOverlayType.EquipmentLongText,
        'Work Order': SlideUpOverlayType.WorkOrderLongText,
    }

    ngOnInit(): void {
        this.header = this.category + ' Long Text'
        this.overlayId = this.options[this.category]
    }
}
