<div class="em-l-grid__item em-u-text-align-left">
    <div class="em-l-grid em-l-grid--2up">
        <div class="em-l-grid__item">
            <div class="em-c-field__body">
                <label class="em-c-search__label">
                    EXPIRATION DATE
                </label>
                <app-date-picker-form [formControl]="testEquipmentForm.get('certificationExpiryDate')"
                                      placeholder="Date"></app-date-picker-form>
            </div>
        </div>
        <div class="em-l-grid__item">
            <label class="em-c-search__label">
                STATUS
            </label>
            <app-dropdown [options]="options"
                          [form]="testEquipmentForm"
                          [formControlPath]="'status'"
                          [value]="'name'">
            </app-dropdown>
        </div>
    </div>
</div>
