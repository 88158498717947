<div class="inline-input input-section">
    <div class="blue-button add-button"
         (click)="addSelector()"
         *ngIf="selectorFormArray.controls.length < maxNumberOfItems && this.selectorFormArray.enabled">
        + Add
    </div>
    <div *ngFor="let control of selectorFormArray.controls; let i = index;">
        <div *ngIf="hasFixedFirstValue && i===0">
            <div class="em-u-margin-half">{{ control.value[displayField] }}</div>
        </div>
        <app-dropdown-search class="dropdown"
                             [index]="i"
                             [control]="control"
                             [selector]="itemSelector"
                             [callback]="removeItem.bind(this)"
                             [displayField]="displayField"
                             [idField]="idField"
                             [excludedItems]="existingItems"
                             [disablingFunction]="disableInactiveAndExpiredItem"
                             *ngIf="i>0 || !hasFixedFirstValue"
                             [inputId]="inputId">
        </app-dropdown-search>
    </div>
</div>
