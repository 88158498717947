import { Injectable } from '@angular/core'
import { FormArray, FormGroup } from '@angular/forms'

import { SetPointService } from '@app/services/engineering-logic-services/setpoint.service'
import { isNotAValue } from '@app/utils/app-utils.function'
import { CalibrationResultStatusEnum } from '../models/calibration-result-status.enum'
import { CalibrationResultValue } from '../models/calibration-result-value.model'
import { MultiPointTemplate } from '../models/multi-point-template.model'

@Injectable({
    providedIn: 'root'
})
export class MultiPointCalibrationService {

    public autoPopulate(calibrationForm: FormArray, template: MultiPointTemplate): boolean {
        for (const resultSet of calibrationForm.getRawValue()) {
            if (!isNotAValue(resultSet.asLeft)) {
                return false
            }
            if (!this.isInRange(template, resultSet, 'asFound')) {
                return false
            }
        }

        calibrationForm.controls.forEach((control: FormGroup) => {
            control.patchValue({
                asLeft: control.value['asFound']
            })
        })

        return true
    }

    public calculateResultStatus(asFoundPass: number, asLeftPass: number, totalPoint: number): CalibrationResultStatusEnum {
        if (asFoundPass === totalPoint && asLeftPass === totalPoint) {
            return CalibrationResultStatusEnum.Passed
        }

        if (asLeftPass === totalPoint) {
            return CalibrationResultStatusEnum.FailedAdjustedPassed
        }

        return CalibrationResultStatusEnum.Failed
    }

    public isInRange(template: MultiPointTemplate, resultSet: CalibrationResultValue, resultSetField: 'asFound' | 'asLeft'): boolean {
        if (isNotAValue(resultSet[resultSetField])) {
            return false
        }
        const index = resultSet.pointNumber - 1
        const setPoint = template.setPoints[index]
        const adjustedInjectedInput = resultSet.adjustedInjectedInput

        if (setPoint.isSetPointAdjustable && isNotAValue(adjustedInjectedInput)) {
            // Technically, we can't tell if it is in range or not without the adjusted input
            // since we would not know what the "range" is...
            return false
        }
        return SetPointService.isValueInAcceptableRange(resultSet[resultSetField], setPoint, resultSet.adjustedInjectedInput)
    }
}
