import { Injectable } from '@angular/core'
import { Update } from '@ngrx/entity'
import { Store } from '@ngrx/store'
import { BehaviorSubject, forkJoin, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { BackgroundSyncStatusEnum, CacheStatusEnum } from '@app/models/offline-status.enum'
import { CalibrationDetails, Cylinder } from '@app/modules/calibration/models/calibration-details.model'
import { CalibrationResultStatusEnum } from '@app/modules/calibration/models/calibration-result-status.enum'
import { CalibrationStatusEnum } from '@app/modules/calibration/models/calibration-status.enum'
import { WorkOrderEquipment } from '@app/modules/calibration/models/work-order-equipment.model'
import { CalibrationActionRequired } from '@app/modules/calibration/services/calibration-action-required.service'
import { CalibrationService } from '@app/modules/calibration/services/calibration.service'
import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { EquipmentTemplateService } from '@app/modules/equipment/services/equipment-template.service'
import { FlamingoSchema } from '@app/modules/flamingo/models/flamingo-form.model'
import { FlamingoAPIService } from '@app/modules/flamingo/services/flamingo-api.service'
import { UploadQueue } from '@app/modules/shared/models/upload-queue.model'
import { WorkOrderListItem } from '@app/modules/work-order-search/models/work-order-list-item.model'
import { NotificationBasic } from '@app/modules/work-order/models/notification-basic.model'
import { WorkOrderDetails } from '@app/modules/work-order/models/work-order-details.model'
import { WorkOrderService } from '@app/modules/work-order/services/work-order.service'
import { QueryBuilderService } from '@app/services/query-builder.service'
import { AppState } from '@app/store/app.store'
import { isOnline } from '@app/store/connection/connection.selectors'
import { RemoveWorkOrderCacheStatusAction } from '@app/store/offline/offline.actions'
import { adapter as offlineAdapter, EquipmentCache, OfflineState, WorkOrderCache } from '@app/store/offline/offline.reducer'
import { pmUploadQueue, selectOffline } from '@app/store/offline/offline.selectors'
import { deepCopy } from '@app/utils/app-utils.function'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { ResponseHandlerService } from './response-handler.service'
import { ResponseHandlingStrategyBuilder } from './response-handling-strategy.builder'

@Injectable({
    providedIn: 'root'
})
export class OfflineService {
    public isOnline: boolean
    public isOnline$ = new BehaviorSubject<boolean>(false)
    public offlineState: OfflineState

    private dataWaitingForUpload: UploadQueue[]
    private customStrategyLoader = new ResponseHandlingStrategyBuilder()
        .useHandle404(null)
        .responseStrategy
    private readonly toggleUrl = `${environment.baseUrl}/api/${AppSettings.apiVersion}/UserToggles?toggleType=AutomaticDownloadsForBookmarkWorkOrders`

    constructor(
        private store: Store<AppState>,
        private responseHandlerService: ResponseHandlerService,
        private workOrderService: WorkOrderService,
        private equipmentTemplateService: EquipmentTemplateService,
        private calibrationService: CalibrationService,
        private flamingoApiService: FlamingoAPIService,
        private queryBuilderService: QueryBuilderService
    ) {
        this.store.select(selectOffline).subscribe(state => this.offlineState = state)

        this.store.select(isOnline).subscribe(online => {
            this.isOnline = online
            this.isOnline$.next(this.isOnline)
        })

        this.store.select(pmUploadQueue).subscribe(queue => this.dataWaitingForUpload = queue)
    }

    public getAllWorkOrderCache(): WorkOrderCache[] {
        return offlineAdapter.getSelectors().selectAll(this.offlineState)
    }

    public getPMUploadQueue(): UploadQueue[] {
        return this.dataWaitingForUpload
    }

    public getIsAutoDownloadEnabled(): Observable<any> {
        return this.queryBuilderService.get(this.toggleUrl).pipe(map(response => response.body))
    }

    public setIsAutoDownloadEnabled(isAutoDownloadEnabled: boolean): Observable<any> {
        return this.responseHandlerService.query(
            () => this.queryBuilderService.post(`${this.toggleUrl}&enable=${isAutoDownloadEnabled ? 'true' : 'false'}`)
        )
    }

    public generateCalibrationSyncStatusUpdateInstance(
        workOrderNumber: string,
        equipmentId: string,
        cacheStatusString: string,
        calibrationStatus: CalibrationStatusEnum,
        calibrationPMFinalResultStatus: CalibrationResultStatusEnum
    ): Update<WorkOrderCache>[] {
        const update: Update<WorkOrderCache>[] = []
        const workOrderCache = this.getAllWorkOrderCache()
            .find(item => item.workOrderNumber === workOrderNumber)

        if (workOrderCache) {
            const updatedEquipments = workOrderCache.equipments.map(item => {
                const clonedEquipment: EquipmentCache = deepCopy(item)
                if (clonedEquipment.equipmentId === equipmentId) {
                    clonedEquipment.backgroundSyncStatus = BackgroundSyncStatusEnum[cacheStatusString]
                    clonedEquipment.calibrationStatusId = calibrationStatus
                    clonedEquipment.finalPMResultStatusId = calibrationPMFinalResultStatus

                    const calibrationDetails = new CalibrationDetails()
                    const calibrationStatusName = CalibrationStatusEnum[calibrationStatus]?.toString()
                    const finalPMStatusName = CalibrationResultStatusEnum[calibrationPMFinalResultStatus]?.toString()
                    calibrationDetails.equipmentId = equipmentId
                    calibrationDetails.workOrderNumber = workOrderNumber
                    calibrationDetails.calibrationStatus = { id: calibrationStatus, name: calibrationStatusName }
                    calibrationDetails.finalPMResultStatus = { id: calibrationPMFinalResultStatus, name: finalPMStatusName }
                    clonedEquipment.actionRequired = CalibrationActionRequired.getRequiredAction(calibrationDetails)
                }

                return clonedEquipment
            })

            update.push({
                id: workOrderCache.workOrderNumber,
                changes: {
                    equipments: updatedEquipments
                }
            })
        }

        return update
    }

    public getWorkOrderCacheStatus(workOrderNumber: string): CacheStatusEnum {
        if (!this.offlineState || !this.offlineState.isAutoDownloadEnabled) {
            return CacheStatusEnum.FAILED
        }

        if (this.offlineState.userListCacheStatus !== CacheStatusEnum.CACHED) {
            return this.offlineState.userListCacheStatus
        }

        const workOrderCache = this.getAllWorkOrderCache().find(item => item.workOrderNumber === workOrderNumber)

        if (!workOrderCache) { return CacheStatusEnum.FAILED }

        for (const eq of workOrderCache.equipments) {
            const cacheStatuses = [
                [eq.isTemplateMapped, eq.cacheTemplateStatus],
                [eq.isFlamingoFormMapped, eq.cacheFlamingoStatus],
                [eq.calibrationStatusId, eq.cacheCalibrationStatus]
            ]

            for (const state of cacheStatuses) {
                if (state[0] && state[1] === CacheStatusEnum.LOADING) {
                    return CacheStatusEnum.LOADING
                }
                if (state[0] && state[1] === CacheStatusEnum.FAILED) {
                    return CacheStatusEnum.FAILED
                }
            }
        }

        return CacheStatusEnum.CACHED
    }

    public getCalibrationBackgroundSyncStatus(
        workOrderNumber: string,
        equipmentId: string
    ): BackgroundSyncStatusEnum {
        const workOrderCache = this.getAllWorkOrderCache().find(item => item.workOrderNumber === workOrderNumber)
        if (!workOrderCache) {
            return BackgroundSyncStatusEnum.NOT_QUEUED
        }

        const equipmentCache = workOrderCache.equipments.find(eq => eq.equipmentId === equipmentId)
        if (!equipmentCache) {
            return BackgroundSyncStatusEnum.NOT_QUEUED
        }

        return equipmentCache.backgroundSyncStatus
    }

    public removeWorkOrderDetailsFromCache(workOrderNumber: string): void {
        this.store.dispatch(new RemoveWorkOrderCacheStatusAction(workOrderNumber))
    }

    public cacheWOListItems(woItems: WorkOrderListItem[]): Observable<WorkOrderDetails[]> {
        const uncachedWorkOrders = this.getUncachedWorkOrders(woItems)
        return forkJoin(uncachedWorkOrders.map(woItem => this.cacheWorkOrderDetails(woItem.workOrderNumber)))
    }

    public cacheEqTemplateItems(equipmentIdList: string[]): Observable<EquipmentTemplateCoreDetails[][]> {
        return forkJoin(equipmentIdList.map(eqId => this.cacheEquipmentTemplateDetails(eqId)))
    }

    public cacheEqsCylinderHistory(equipmentIdList: string[]): Observable<Cylinder[][]> {
        return forkJoin(equipmentIdList.map(eqId => this.cacheEqCylinderHistory(eqId)))
    }

    public cacheEqCalibrationItems(calibrations: WorkOrderEquipment[]): Observable<CalibrationDetails[]> {
        return forkJoin(calibrations.map(cal => this.cacheCalibrationDetails(cal.workOrderNumber, cal.equipmentId)))
    }

    public cacheNotificationItems(notificationNumbers: string[], workOrderNumber: string): Observable<any> {
        return forkJoin(notificationNumbers.map((wn) => this.cacheNotificationDetails(workOrderNumber, wn)))
    }

    public cacheFlamingoFormMappingItems(formIds: string[]): Observable<any> {
        return forkJoin(formIds.map(ff => this.flamingoApiService.getEquipmentFlamingoFormMapping(ff)))
    }

    public cacheFlamingoFormSchemaItems(formIds: string[]): Observable<FlamingoSchema[]> {
        return forkJoin(formIds.map(ff => this.flamingoApiService.getFlamingoFormSchema(ff)))
    }

    private getUncachedWorkOrders(woItems: WorkOrderListItem[]): WorkOrderListItem[] {
        const cachedWorkOrder = this.getAllWorkOrderCache()
        return woItems.filter(newWorkOrder =>
            !cachedWorkOrder.some(cachedWO => newWorkOrder.workOrderNumber === cachedWO.workOrderNumber)
        )
    }

    private cacheWorkOrderDetails(workOrderNumber: string): Observable<WorkOrderDetails> {
        return this.responseHandlerService
            .query(() => this.workOrderService.getWorkOrderDetails(workOrderNumber, false), this.customStrategyLoader)
    }

    private cacheEquipmentTemplateDetails(equipmentItemId: string): Observable<EquipmentTemplateCoreDetails[]> {
        return this.responseHandlerService
            .query(() => this.equipmentTemplateService.getEquipmentTemplateDetails(equipmentItemId), this.customStrategyLoader)
    }

    private cacheEqCylinderHistory(equipmentItemId: string): Observable<Cylinder[]> {
        return this.responseHandlerService
            .query(() => this.calibrationService.getEquipmentCylinderHistory(equipmentItemId), this.customStrategyLoader)
    }

    private cacheCalibrationDetails(workOrderNumber: string, equipmentItemId: string): Observable<CalibrationDetails> {
        return this.responseHandlerService
            .query(() => this.calibrationService.getCalibrationDetails(workOrderNumber, equipmentItemId), this.customStrategyLoader)
    }

    private cacheNotificationDetails(workOrderNumber: string, notificationNumber: string): Observable<NotificationBasic> {
        return this.responseHandlerService
            .query(() => this.workOrderService.getNotificationInfo(workOrderNumber, notificationNumber), this.customStrategyLoader)
    }
}
