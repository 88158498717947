import rfdc from 'rfdc'

export const isNotAValue = (value: any, respectWhiteSpace = true): boolean => {
    if (respectWhiteSpace) {
        return (typeof value === 'undefined') || (value === null) || (value === '')
    }

    return (typeof value === 'undefined') || (value === null) || (typeof value === 'string' && value.trim() === '')
}

export const arrayHasAnyValue = (array: any[]) => {
    return array.length > 0 ? !isNotAValue(array[0]) : false
}


/**
 * @deprecated - Use TypeScript 3.7's **Safe Navigation Operator (`?`)** instead.
 * This method breaks auto-refactoring and prevent many compile-time check.
 *
 * ---
 *
 * Get a nested value safely from an object using dot notation string.
 * i.e. (obj, 'a.b.c.e.x') will return obj.a.b.c.e.x
 *
 * If at any point a key doesn't exist, the whole function
 * will return 'undefined' (or null if the original object is null)
 *
 * @param dotNotation is a string in dot notation -- excluding the original object
 * i.e. if you want `foo.bar.baz`, you would not include 'foo' in the string.
 */
export const safeObjGet = (obj: object, dotNotation: string): any => {
    return dotNotation.split('.').reduce((o, x) => {
        // If `o` is not null or undefined, return o[x], else, return o
        return o && o[x]
    }, obj)
}

/**
 * @deprecated - Use TypeScript 3.7's Optional Operator (`?`) in combination
 * with Nullish Coalescing Operator (`??`) operator instead.
 *
 * E.g.
 * ```ts
 * foo?.bar?.baz ?? 'defaultValue'
 * ```
 */
export const safeDefaultObjGet = (obj: object, key: string, defaultValue: any): any => {
    const myObj = safeObjGet(obj, key)
    return (typeof myObj === 'undefined' || myObj === null) ? defaultValue : myObj
}

export const encodeQueryData = (queryObject: object) => {
    return Object.keys(queryObject).map(key => {
        return [key, queryObject[key]].map(encodeURIComponent).join('=')
    }).join('&')
}

/**
 * Lossless deep copy. Will preserve _most_ object type and structure. \
 * See: https://www.npmjs.com/package/rfdc for full docs
 */
export const deepCopy = rfdc()

export const generateId = (length: number, characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') => {
    let result = ''
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

/** @param keyPath a path to an object's property using Dot Notation */
export const sortComparer = (objA: any, objB: any, keyPath: string): any => {
    const tempA = safeObjGet(objA, keyPath)
    const tempB = safeObjGet(objB, keyPath)
    switch (typeof tempA) {
        case 'string': {
            const a = tempA ?? ''
            const b = tempB ?? ''
            return b.toLowerCase().localeCompare(a.toLowerCase())
        }
        case 'number': {
            const a = tempA ?? -1
            const b = tempB ?? -1
            return b - a
        }
        case 'boolean':
            return Number(!!tempA) - Number(!!tempB)
        case 'object': {
            const a = tempA ?? []
            const b = tempB ?? []
            const arrA = arrayHasAnyValue(a) ? a : ['']
            const arrB = arrayHasAnyValue(b) ? b : ['']
            return arrA[0].toLowerCase().localeCompare(arrB[0].toLowerCase())
        }
        default:
            return 0
    }
}

export const sortArray = (objArray: any[], sortColumn: string, isDescending: boolean): any[] => {
    if (isDescending) {
        return objArray.slice().sort((a, b) => sortComparer(a, b, sortColumn))
    } else {
        return objArray.slice().sort((a, b) => sortComparer(b, a, sortColumn))
    }
}

export const round = (value: number) => {
    if (!value) {
        return value
    }

    return Math.round((value + Number.EPSILON) * 100000) / 100000
}

export const stringIsDifferent = (stringA: string, stringB: string): boolean => {
    if (isNotAValue(stringA) && isNotAValue(stringB)) {
        return false
    }
    if (isNotAValue(stringA) || isNotAValue(stringB)) {
        return true
    }
    return stringA.localeCompare(stringB) !== 0
}

export const stringToBool = (value: string): boolean => {
    return (value || '').toLowerCase() === 'true'
}

export const safeCallback = (callback: (params?: any) => any, params?: any): void => {
    if (callback && typeof callback === 'function') {
        callback(params)
    }
}

export enum ResponsiveClass {
    xl,
    lg,
    md,
    sm,
    xs
}

export const breakpointView = (): ResponsiveClass => {
    // Make sure these value is sync with style/mixins.scss
    let responsiveClass: ResponsiveClass

    if (window.innerWidth > 1200) {
        responsiveClass = ResponsiveClass.xl
    }
    if (window.innerWidth <= 1200) {
        responsiveClass = ResponsiveClass.lg
    }
    if (window.innerWidth <= 992) {
        responsiveClass = ResponsiveClass.md
    }
    if (window.innerWidth <= 768) {
        responsiveClass = ResponsiveClass.sm
    }
    if (window.innerWidth <= 480) {
        responsiveClass = ResponsiveClass.xs
    }

    return responsiveClass
}

export const findOptionWithKey = (key: string, options: { key: string, value: string }[]): { key: string, value: string } => {
    return options.find(it => it.key === key)
}

export const groupByObjectKey = <T>(data: any, key: string): T => {
    return data.reduce((storage, item) => {
        const group = item[key]
        storage[group] = storage[group] || []
        storage[group].push(item)

        return storage
    }, {} as T)
}

/**
 * Check whether an object is empty i.e. obj === {}
 */
export const isEmptyObject = (obj: object) => {
    if (obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object
    }
    return obj
}

/**
 * Prevent a function being call multiple times in a subsequent time limit
 */
export const debounceFunc = (func: (...args: any) => any | void, timeout = 300) => {
    let timer
    return (...args: any): void => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func.apply(this, args)
        }, timeout)
    }
}

export const getRandomItemFromList = (list: any[]) => {
    if (!list) {
        return
    }

    return list[Math.floor(list.length * Math.random())]
}

export const highlightPartText = (str, replaceText) => {
    return str.replace(new RegExp(replaceText, 'g'), `<span class="global-bold-text">${replaceText}</span>`)
}
