import { animate, style, transition, trigger } from '@angular/animations'
import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
    selector: 'app-bottom-sheet',
    templateUrl: './bottom-sheet.component.html',
    styleUrls: ['./bottom-sheet.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'translateY(100%)'
                }),
                animate('300ms ease-out',
                    style({
                        opacity: 1,
                        transform: 'translateY(0%)'
                    })
                )
            ]),
            transition(':leave', [
                style({ opacity: 1, transform: 'translateY(0%)' }),
                animate('300ms ease-in', style({
                    opacity: 0,
                    transform: 'translateY(100%)'
                }))
            ])
        ])
    ]
})
export class BottomSheetComponent {

    @Input() title: string

    @Input() isSheetOpen = false
    @Output() isSheetOpenChange = new EventEmitter<boolean>()

    public closeSheet(): void {
        this.isSheetOpen = false
        this.isSheetOpenChange.next(this.isSheetOpen)
    }
}
