<span *ngIf="!isLoading && hasError" class="label label-error em-u-clickable" (click)="onClickErrorLabel()">
    <app-unity-icon unitySvgName="refresh"></app-unity-icon>
    <span class="left-margin">Click to reload</span>
</span>
<span *ngIf="isLoading" class="label label-info">
    <span>Loading</span>
    <span class="dot-collision"></span>
</span>
<ng-container *ngIf="!isLoading && !hasError">
    <ng-content></ng-content>
</ng-container>
