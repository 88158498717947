import { HttpEventType } from '@angular/common/http'
import { Component, HostListener, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { BehaviorSubject, Observable } from 'rxjs'
import { throttleTime } from 'rxjs/operators'

import { NavAwayInterceptorGuardDataSource } from '@app/guards/nav-away-interceptor.guard'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { FileDownloadService } from '@app/services/file-download.service'

@Component({
    selector: 'app-report-download',
    templateUrl: './report-download.component.html',
    styleUrls: ['./report-download.component.scss']
})
export class ReportDownloadComponent extends SafeUnsubscriberComponent implements OnInit, NavAwayInterceptorGuardDataSource {

    public fileName: string
    public downloadProgress = -1
    public isFileNotFound = false
    public isDownloadInProgress = false

    public downloadProgress$: Observable<number>
    private downloadProgressSubject$ = new BehaviorSubject<number>(-1)

    constructor(
        private route: ActivatedRoute,
        private fileDownloadService: FileDownloadService
    ) {
        super()

        // If we update the progress bar too fast, its animation won't catch up.
        this.downloadProgress$ = this.downloadProgressSubject$.pipe(
            throttleTime(500)
        )
    }

    canDeactivate = () => {
        if (this.isDownloadInProgress) {
            return confirm('Download is still in progress. Are you sure you want to leave this page?')
        }
        return true
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeBrowserUnload(): boolean {
        return this.canDeactivate()
    }

    ngOnInit(): void {
        this.addSubscription(
            this.route.params.subscribe(params => {
                this.fileName = params['reportFileName']
                this.downloadProgress = -1
                this.isDownloadInProgress = false
            })
        )
    }

    public downloadFileClick(): void {
        if (this.isDownloadInProgress) {
            return
        }
        this.initiateDownload(this.fileName)
    }

    private initiateDownload(fileName: string): void {
        this.downloadProgress = -1
        this.isDownloadInProgress = true
        this.fileDownloadService.downloadReportFile(fileName)
            .subscribe(event => {
                if (event.type === HttpEventType.DownloadProgress) {
                    this.downloadProgress = Math.round(100 * event.loaded / event.total)
                    this.downloadProgressSubject$.next(this.downloadProgress)
                }
                if (event.type == HttpEventType.ResponseHeader && event.status == 404) {
                    this.isFileNotFound = true
                }
                if (event.type === HttpEventType.Response) {
                    this.downloadProgress = 100
                    this.downloadProgressSubject$.next(this.downloadProgress)
                    this.fileDownloadService.downloadFile(event)
                    this.isDownloadInProgress = false
                }
            })
    }

}
