import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, HostListener, Injector, Input, OnInit, Type } from '@angular/core'
import { FormArray, FormGroup } from '@angular/forms'
import { BehaviorSubject, Subject } from 'rxjs'
import { distinctUntilChanged, filter, map } from 'rxjs/operators'

import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { ScreenSizeService } from '@app/services/screen-size.service'
import { DebounceTime } from '@app/utils/decorators/debounce-time.decorator'
import { CalibrationDetails } from '../../models/calibration-details.model'
import { CalibrationResultStatusEnum } from '../../models/calibration-result-status.enum'
import { CalibrationStatusEnum } from '../../models/calibration-status.enum'

@Component({
    template: ''
})
export abstract class AbstractCalibrationTemplateComponent
    extends SafeUnsubscriberComponent implements OnInit, AfterContentInit, AfterViewInit {

    // --------------------------------------------------------

    /** Expose the template's disabled state for components on higher level. */
    public isDisabled$ = new BehaviorSubject<boolean>(false)

    // Store enums locally to allow HTML template access to them
    public readonly calibrationStatusEnum = CalibrationStatusEnum
    public readonly calibrationResultStatusEnum = CalibrationResultStatusEnum

    @Input() calibration: CalibrationDetails
    @Input() calibrationForm: FormGroup
    @Input() isReport: boolean

    public isOnMobileOrTabletDevice: boolean

    // ########################################################

    protected cdRef: ChangeDetectorRef


    private screenSizeService: ScreenSizeService
    private viewFinishedInit = false
    private onViewFinishedInit$ = new Subject()

    private _isDisabled: boolean


    constructor(injector: Injector) {
        super()
        this.screenSizeService = injector.get(ScreenSizeService)
        this.cdRef = injector.get<ChangeDetectorRef>(ChangeDetectorRef as Type<ChangeDetectorRef>)
    }

    // --------------------------------------------------------
    // Template Configuration
    // --------------------------------------------------------
    // These should be overridden by the subclass to set appropriate value.
    // These are used by the parent component that create these template
    // to correctly wired up and configured parts of the parent's component.

    public get templateWillCalculateCalibrationResultStatus(): boolean {
        return true
    }

    public get templateWillNotRenderCalibrationResultsCard(): boolean {
        return false
    }

    public set isDisabled(v: boolean) {

        /**
         * 🛠 [WORKAROUND] due to 🐞 (bug) in Angular/ReactiveFrom
         * ---------------------------------------------------
         *
         * ReactiveForm `disabled` state sometime doesn't apply.
         * A workaround found is to force Angular to run its ChangeDetection
         * cycle right before we toggle the `disabled` state.
         */
        const setDisabledState = (disabled: boolean) => {
            this.cdRef.detectChanges()

            // ❗ For some reason, `.disabled()` will not work if we save these
            // in instance variables, so we need to rerun the .get() each time
            // we are going to apply the `disabled`
            const calibrationResult = this.calibrationForm.get('results.calibrationResult')
            const calibrationChecklist = this.calibrationForm.get('results.calibrationChecklist')

            if (calibrationResult) {
                disabled ? calibrationResult.disable() : calibrationResult.enable()
            }

            if (calibrationChecklist) {
                disabled ? calibrationChecklist.disable() : calibrationChecklist.enable()
            }

            this.isDisabled$.next(disabled)
        }

        // `.detectChanges()` will failed if run before the NgInit is completed
        if (!this.viewFinishedInit) {
            const sub = this.onViewFinishedInit$.subscribe(() => {
                setDisabledState(v)
                sub.unsubscribe()
            })
        } else {
            setDisabledState(v)
        }

        this._isDisabled = v
    }
    public get isDisabled(): boolean {
        return this._isDisabled
    }
    public get isCalibrationStatusCompleted(): boolean {
        return this.calibration?.calibrationStatus?.id === this.calibrationStatusEnum.Completed
    }

    // ########################################################
    // Form Getters
    // ########################################################

    // TODO: Some template still called `calibrationFormResults` "form",
    // need to refactor and unify
    /**
     * return `calibrationForm.results`
     */
    public get calibrationFormResults(): FormGroup {
        return this.calibrationForm.get('results') as FormGroup
    }

    /**
     * return `calibrationForm.overview`
     */
    public get calibrationFormOverview(): FormGroup {
        return this.calibrationForm.get('overview') as FormGroup
    }

    /**
     * return `calibrationForm.results.calibrationResult`
     */
    public get calibrationResult(): FormGroup {
        return this.calibrationFormResults.get('calibrationResult') as FormGroup
    }

    /**
     * return `calibrationForm.results.calibrationResult.results`
     */
    public get resultsFormArray(): FormArray {
        return this.calibrationResult.get('results') as FormArray
    }

    /**
     * return `calibrationForm.results.calibrationResult.results[index].resultSet`
     * @param index default to 0
     */
    public getResultSetFormArray(index = 0): FormArray {
        return this.resultsFormArray.controls[0].get('resultSet') as FormArray
    }

    /**
     * Will be called as the calibration is about to be completed.
     *
     * Use this hook to ensure all data are up-to-date and
     * ready to be uploaded to the cloud (or buffer for upload by SW)
     *
     * *** As of now, this hook will NOT wait for callbacks to finish before
     * attempting to complete the calibration though.
     *
     * This hook will call {@link updateCalibrationResultStatus}
     * by default.
     */
    public calibrationWillComplete(): void {
        this.updateCalibrationResultStatus()
    }

    @HostListener('window:resize')
    @DebounceTime(300)
    onResize(): void {
        this.isOnMobileOrTabletDevice = this.screenSizeService.isOnMobileOrTabletDevice()
    }

    // Don't forget to call super.ng______()
    ngOnInit(): void {
        this.isOnMobileOrTabletDevice = this.screenSizeService.isOnMobileOrTabletDevice()

        // FIXME: Currently, only a few templates (e.g. leak-check/third-party) rely on
        // results.calibrationChecklist for thier disabled state...
        // Should implement a better, more unified way to set disable state!
        const calibrationResult = this.calibrationForm.get('results.calibrationResult')
        const calibrationChecklist = this.calibrationForm.get('results.calibrationChecklist')

        // If either one is disabled, consider the form disabled
        this.isDisabled = (calibrationResult && calibrationResult.disabled)
            || (calibrationChecklist && calibrationChecklist.disabled)


        const resultStatusSubscription = this.calibrationForm.valueChanges.pipe(
            map(() => this.calibrationForm.getRawValue()),
            filter(formValue => {
                const { finalPMResultStatus, calibrationResultStatus } = formValue?.results
                return finalPMResultStatus?.id !== calibrationResultStatus?.id
            }),
            distinctUntilChanged((prevPMResultStatus, currPMResultStatus) =>
                prevPMResultStatus?.results?.calibrationResultStatus?.id === currPMResultStatus?.results?.calibrationResultStatus?.id
            )
        ).subscribe(formValue => {
            this.calibrationForm.patchValue({
                results: {
                    calibrationResultStatus: formValue?.results?.calibrationResultStatus
                }
            })

            if (!formValue?.results?.isFinalPMResultStatusManuallySet) {
                this.calibrationForm.patchValue({
                    results: {
                        finalPMResultStatus: formValue?.results?.calibrationResultStatus
                    }
                })
            }
        })

        this.addSubscription(resultStatusSubscription)
    }

    ngAfterContentInit(): void {
        this.updateCalibrationResultStatus()
    }

    ngAfterViewInit(): void {
        this.viewFinishedInit = true
        this.onViewFinishedInit$.next(true)
    }

    /**
     * A life-cycle hook to refresh and update the CalibrationResultStatus.
     *
     * Should implement the logic to determine and update
     * the calibration result status as appropriate for each template.
     *
     * Will be called during `ngAfterContentInit()`
     */
    protected abstract updateCalibrationResultStatus(): void

}
