export interface RadioHorizontalOption {
    icon: string,
    optionText: string,
    value: any
}

export enum RadioHorizontalOptionIcon {
    CHECK = './assets/unity-1.2.0/images/em-icons.svg#circle-check',
    CROSS = './assets/unity-1.2.0/images/em-icons.svg#circle-x',
    MINUS = './assets/unity-1.2.0/images/em-icons.svg#circle-minus'
}
