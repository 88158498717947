<div class="container">
    <div #content
         class="content"
         [ngClass]="{'collapsed': collapsed }">
        <span>{{value}}</span>
        <span class="collapse-button"
              *ngIf="overflows && !collapsed"
              (click)="toggleText()">Show less</span>
    </div>
    <span class="collapse-button"
          *ngIf="overflows && collapsed"
          (click)="toggleText()">Show more</span>
</div>