import { Component, Input } from '@angular/core'

import { CacheStatusEnum } from '@app/models/offline-status.enum'
import { WorkOrderListItem } from '@app/modules/work-order-search/models/work-order-list-item.model'
import { WorkOrderDetails } from '@app/modules/work-order/models/work-order-details.model'
import { OfflineService } from '@app/services/offline.service'
import { PageName } from '../../models/page-name.enum'

@Component({
    selector: 'app-offline-available-status',
    templateUrl: './offline-available-status.component.html',
    styleUrls: ['./offline-available-status.component.scss']
})
export class OfflineAvailableStatusComponent {
    @Input() item: WorkOrderListItem | WorkOrderDetails
    @Input() equipmentId: string
    @Input() offlineStatusEnabled = true
    @Input() alignment: PageName

    public offlineStatus = CacheStatusEnum

    constructor(private offlineService: OfflineService) { }

    public get getAlignmentClass(): string {
        switch (this.alignment) {
            case PageName.Todo: return 'alignment-todo-page'
            case PageName.workOrderOverView: return 'alignment-wo-overview-page'
            default: return 'alignment-todo-page'
        }
    }

    public getAvailableOfflineStatus(cacheStatus: CacheStatusEnum): boolean {
        return this.offlineService.getWorkOrderCacheStatus(this.item.workOrderNumber) === cacheStatus
    }
}
