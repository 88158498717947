import { Component, forwardRef, Input } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

import { RadioHorizontalOption } from './radio-horizontal-option.model'

/**
 * This FormControl allow you to create radio buttons, horizontally aligned
 * with a question to the left.
 * Each radio button can have different icon, text, and value.
 *
 * If the value is an object (or something that `a === b` will fail to compare),
 * a `customValueComparer` will need to be supplied.
 */
@Component({
    selector: 'app-radio-horizontal',
    templateUrl: './radio-horizontal.component.html',
    styleUrls: ['./radio-horizontal.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioHorizontalComponent),
            multi: true
        }
    ]
})
export class RadioHorizontalComponent implements ControlValueAccessor {


    @Input() isDisabled = false
    @Input() useBlack: boolean
    @Input() radioOptions: RadioHorizontalOption[] = []
    @Input() question = ''

    /**
     * Since we allow `radioOptions[i].value` to by `any`,
     * a custom comparer might be needed when the `value` is an object.
     *
     * It should return whether object `a` is equal to object `b`. \
     * e.g. `(a, b) => a.id === b.id`
     *
     * The default behavior is to use `a === b`.
     */
    @Input() customValueComparer: (a: any, b: any) => boolean = ((a, b) => a === b)

    public currentIndex

    public optionClicked(index: number): void {
        if (this.isDisabled) {
            return
        }
        this.currentIndex = index
        this.onChange(this.radioOptions[index].value)
        this.onTouched()
    }

    registerOnChange(fn): void {
        this.onChange = fn
    }

    registerOnTouched(fn): void {
        this.onTouched = fn
    }

    writeValue(v): void {
        if (v) {
            this.currentIndex = this.radioOptions.findIndex((option) => this.customValueComparer(option.value, v))
        }
    }

    setDisabledState(isDisabled): void {
        this.isDisabled = isDisabled
    }

    private onChange: any = () => { }
    private onTouched: any = () => { }


}
