import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormArray, FormBuilder, FormGroup } from '@angular/forms'
import { Store } from '@ngrx/store'
import { debounceTime } from 'rxjs/operators'

import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { IdentityService } from '@app/services/identity.service'
import { AppState } from '@app/store/app.store'
import { GetCustomFormTemplatesAction } from '@app/store/custom-form/custom-form.actions'
import { customFormTemplates } from '@app/store/custom-form/custom-form.selectors'
import { CalibrationDetails } from '../../models/calibration-details.model'
import { CalibrationStatusEnum } from '../../models/calibration-status.enum'
import { CustomFormResultValue } from './models/custom-form-result.model'
import { CustomFormObject } from './models/custom-form.model'
import { find } from 'lodash'

@Component({
    selector: 'app-custom-form',
    templateUrl: './custom-form.component.html',
    styleUrls: ['./custom-form.component.scss']
})
export class CustomFormComponent extends SafeUnsubscriberComponent implements OnInit {
    @Input() hasBorder = false
    @Input() calibration: CalibrationDetails
    @Input() calibrationForm: FormGroup
    @Input() isReport: boolean
    @Output() fieldsChange = new EventEmitter()

    public customFormResult: CustomFormResultValue[]
    public customFormTemplate: CustomFormObject[]
    public formControls: FormArray

    constructor(
        private formBuilder: FormBuilder,
        private store: Store<AppState>,
        private identityService: IdentityService
    ) {
        super()
    }

    ngOnInit(): void {
        this.initFormData()
        this.watchFormData()
    }

    public trackByCustomFormSectionTitle(index: number, item: CustomFormObject): string {
        return item.title
    }

    private initFormData(): void {
        this.formControls = this.formBuilder.array([])
        this.customFormResult = this.calibration.calibrationResult.customForms || []

        if (this.calibration.customFormTemplate?.detail && this.calibration.templateTypeId === this.calibration.customFormTemplate?.templateTypeId) {
            this.customFormTemplate = this.calibration.customFormTemplate.detail
        } else {
            this.store.dispatch(new GetCustomFormTemplatesAction())
            this.store.select(customFormTemplates).subscribe((forms) => {
                const currentForm = find(forms, form => form.templateTypeId === this.calibration.templateTypeId)
                this.customFormTemplate = currentForm.detail
                this.calibration.customFormTemplate = currentForm
            })
        }

        if (this.calibration.calibrationStatus.id === CalibrationStatusEnum.Completed ||
            !this.identityService.isCurrentUserATechnician$.value
        ) {
            this.formControls.disable()
        }
        this.formControls.valueChanges.subscribe(_ => {
            this.fieldsChange.emit()
        })

        this.calibrationForm.addControl('customForm', this.formControls)
    }

    private watchFormData(): void {
        this.addSubscription(
            this.formControls.valueChanges
                .pipe(debounceTime(500))
                .subscribe(value =>
                    this.calibration.calibrationResult.customForms = value
                )
        )
    }
}
