<app-mrma-card title="PM Calibration (optional)">
    <section class="responsive-calibration-field-container">

        <section class="calibration-field-result">
            <label>As Found</label>
            <div class="em-c-input input-section">
                <app-numeric-input *ngIf="!isReport"
                                   [disabled]="!editable"
                                   [ngModel]="initAsFoundValue"
                                   (inputUpdate)="onAsFoundChange($event)"
                                   textAlign="left"
                                   inputId="asFound"></app-numeric-input>
                <p *ngIf="isReport">{{initAsFoundValue | nilDisplay}}</p>
            </div>
        </section>

        <section class="calibration-field-result">
            <label>As Left</label>
            <div class="em-c-input input-section">
                <app-numeric-input *ngIf="!isReport"
                                   [disabled]="!editable"
                                   [ngModel]="initAsLeftValue"
                                   (inputUpdate)="onAsLeftChange($event)"
                                   textAlign="left"
                                   inputId="asLeft"></app-numeric-input>
                <p *ngIf="isReport">{{initAsLeftValue | nilDisplay}}</p>
            </div>
        </section>

        <section class="calibration-field-result">
            <label>Unit of Measurement</label>
            <div class="em-c-input input-section">
                <app-dropdown-no-form *ngIf="!isReport"
                                      [disabled]="!editable"
                                      [enableSelectNone]="true"
                                      [options]="uomOptions"
                                      [initOption]="initUomOption"
                                      (selectedOptionChange)="onUomOptionChange($event)"></app-dropdown-no-form>
                <p *ngIf="isReport">{{uomDisplayForReport | nilDisplay}}</p>
            </div>
        </section>
    </section>
</app-mrma-card>
