import { TestIntervalSetting } from './test-interval-setting.model'

export class Equipment {
    equipmentId: string
    equipmentTag: string
    description?: string
    isActive?: boolean
    plantCode: string
    displayOrder?: number
    abcIndicator?: string
    note?: string
    techIdentificationNumber?: string
    functionalLocation?: string
    criticalityDesc?: string
    catalogProfile?: string
    catalogProfileDesc?: string
    classificationDesc?: string
    classification?: string
    classificationType?: string
    category?: string
    categoryDesc?: string
    longText?: string
    longTextIndicator?: boolean
    location?: string
    locationDesc?: string
    maintenancePlant?: string
    maintenancePlantDesc?: string
    mainWorkCenterDesc?: string
    mainWorkCenter?: string
    manufacturer?: string
    model?: string
    serial?: string
    objectType?: string
    objectTypeDesc?: string
    plantCodeDesc?: string
    plannerGroup?: string
    plannerGroupDesc?: string
    plantSection?: string
    plantSectionDesc?: string
    functionalLocationDesc?: string
    systemStatus?: string
    systemStatusDesc?: string
    userStatus?: string
    userStatusDesc?: string
}

export type DetailedEquipmentAndTestIntervalSetting = Equipment & TestIntervalSetting

export type EquipmentIntervalSetting = TestIntervalSetting & {
    plantCode: string
    equipmentId: string
    failureRateCategory: string,
    consequence: string
    comment: string
}
