import {
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    TemplateRef,
    ViewContainerRef
} from '@angular/core'
import { ArrowTooltipComponent } from '@app/modules/mrma-ui-components/components/arrow-tooltip/arrow-tooltip.component'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { isEmpty } from 'lodash'

@Directive({
    selector: '[appArrowTooltip]'
})
export class ArrowTooltipDirective extends SafeUnsubscriberComponent implements OnDestroy {

    @Input('appArrowTooltip') content: string | TemplateRef<any>

    private tooltipRef: ComponentRef<ArrowTooltipComponent>

    constructor(
        private elementRef: ElementRef,
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
    ) {
        super()
    }

    ngOnInit() {
        this.showTooltip()
    }

    @HostListener('mouseenter')
    onMouseEnter(): void {
        this.showTooltip()
    }

    @HostListener('document:click', ['$event'])
    public hideWhenClickOutside(globalEvent): void {
        if (!this.elementRef.nativeElement.contains(globalEvent.target)) {
            this.destroy()
        }
    }

    public showTooltip(): void {
        if (isEmpty(this.tooltipRef)) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ArrowTooltipComponent)
            this.tooltipRef = this.viewContainerRef.createComponent(componentFactory)
            const host = this.elementRef.nativeElement.parentElement
            host.insertBefore(this.tooltipRef.location.nativeElement, host.firstChild)

            this.setTooltipComponentProperties()
        }
    }

    public destroy(): void {
        if (this.tooltipRef !== null) {
            this.tooltipRef.destroy()
            this.tooltipRef = null
        }
    }

    private setTooltipComponentProperties() {
        if (this.tooltipRef !== null) {
            this.tooltipRef.instance.content = this.content
            const { height, left } = this.elementRef.nativeElement.getBoundingClientRect()
            this.tooltipRef.instance.top = height + 5
            this.tooltipRef.instance.alignRight = left + 304 / 2 > window.innerWidth
        }
    }
}
