<div class="em-c-primary-nav__item nav-area">
    <a class="em-c-primary-nav__link em-c-primary-nav__link--has-children em-js-nav-dropdown-trigger click-area-link"
       (click)="toggleViewInfoDropdown()">
        <a class="em-c-primary-nav__link em-c-primary-nav__link--has-children em-js-nav-dropdown-trigger em-c-btn btn-view-info">
            <svg class="em-c-icon info-icon">
                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="./assets/unity-1.2.0/images/em-icons.svg#info"></use>
            </svg>
            View More Info
            <svg class="em-c-icon caret-down-icon">
                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="assets/unity-1.2.0/images/em-icons.svg#caret-down">
                </use>
            </svg>
        </a>
        <ul class="em-c-primary-nav__sublist em-js-nav-dropdown dropdown-size-pos"
            [ngClass]="{ 'em-is-active': isViewButtonOpen }">
            <span *ngFor="let option of options">
                <li class="em-c-primary-nav__subitem em-c-btn"
                    *ngIf="!option.hidden"
                    (click)="optionSelected(option.callback, option.disabled)"
                    [ngClass]="{ 'disabled' : option.disabled }">
                    {{option.optionName}}
                </li>
            </span>
        </ul>
    </a>
</div>