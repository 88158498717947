import { DOCUMENT } from '@angular/common'
import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, Output } from '@angular/core'
import { Store } from '@ngrx/store'

import { ModalType } from '@app/modules/modal-container/models/modal.model'
import { ModalContainerService } from '@app/modules/modal-container/services/modal-container.service'
import { ReportFilterPresetDeleteComponent } from '@app/modules/report/modals/report-filter-delete/report-filter-delete.component'
import { ReportFilterPresetModalComponent } from '@app/modules/report/modals/report-filter/report-filter.component'
import { reportFilterDeleteModal, reportFilterReNameModal } from '@app/modules/report/modals/report-filter/report-filter.model-object'
import { AppState } from '@app/store/app.store'
import { ShowModalAction } from '@app/store/modal/modal.actions'
import { SelectedFilterPresetOptionAction } from '@app/store/report/report.actions'
import { safeCallback } from '@app/utils/app-utils.function'

@Component({
    selector: 'app-dropdown-filter',
    templateUrl: './dropdown-filter.component.html',
    styleUrls: ['./dropdown-filter.component.scss'],
})

export class DropdownFilterComponent {
    @Input() title = 'Select presets'
    @Input() options: any[]
    @Input() callback: () => any
    @Input() width = 'auto'
    @Input() padding = '.375rem .75rem' // em-c-btn--small
    @Input() childFilterChanged = false
    @Output() optionChange: EventEmitter<any> = new EventEmitter<any>()

    public isOpen: boolean

    constructor(
        private eRef: ElementRef,
        @Inject(DOCUMENT) private document: any,
        private store: Store<AppState>,
        private modalContainerService: ModalContainerService

    ) {
        this.isOpen = false
        this.modalContainerService.registerComponent(ReportFilterPresetModalComponent, ModalType.ReportRenameFilterPreset)
        this.modalContainerService.registerComponent(ReportFilterPresetDeleteComponent, ModalType.ReportRemoveFilterPreset)
    }

    public get selectedIndex(): number {
        return this.options.findIndex(item => item.selected)
    }

    public set selectedIndex(index) {
        this.store.dispatch(new SelectedFilterPresetOptionAction(index))
    }

    public get selectedTitle(): string {
        return this.options.find(item => item.selected)?.name || this.title
    }

    public get isAnyOptionSelected(): boolean {
        return this.options.some(item => item.selected) || false
    }

    public get styleObj(): object {
        return {
            width: this.width,
            padding: this.padding
        }
    }

    public onOptionSelected(index: number): void {
        this.selectedIndex = index
        this.isOpen = false
        safeCallback(this.callback)
        // apply selected preset to child filter
        this.optionChange.next(index)
    }

    public editFilter(id: number, name: string): void {
        this.store.dispatch(new ShowModalAction({ ...reportFilterReNameModal, params: { id, name } }))
    }

    public deleteFilter(id: number, name: string): void {
        this.store.dispatch(new ShowModalAction({ ...reportFilterDeleteModal, params: { id, name } }))
    }

    @HostListener('document:click', ['$event'])
    public toggleDropdown(globalEvent): void {
        if (this.eventTriggeredInsideHost(globalEvent)) {
            // click inside host then open/close dropdown
            if (this.options.length === 0) {
                return
            }
            this.isOpen = !this.isOpen
        } else {
            // click outside host then close dropdown
            this.isOpen = false
        }

    }

    private eventTriggeredInsideHost(event): boolean {
        const current = event.target
        const host = this.eRef.nativeElement
        return host.contains(current)
    }

}
