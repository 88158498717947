<ul>
    <li (click)="applySort('equipmentId')">
        <p>Id</p>
        <app-sorting-caret [isSorted]="getSortedState('equipmentId')"
                           [isDescending]="getDescendingState('equipmentId')">
        </app-sorting-caret>
    </li>
    <li (click)="applySort('equipmentTag')">
        <p>Tag</p>
        <app-sorting-caret [isSorted]="getSortedState('equipmentTag')"
                           [isDescending]="getDescendingState('equipmentTag')">
        </app-sorting-caret>
    </li>
    <li (click)="applySort('techIdentificationNumber')">
        <p>Tech ID</p>
        <app-sorting-caret [isSorted]="getSortedState('techIdentificationNumber')"
                           [isDescending]="getDescendingState('techIdentificationNumber')">
        </app-sorting-caret>
    </li>
    <li (click)="applySort('functionalLocation')">
        <p>FLOC</p>
        <app-sorting-caret [isSorted]="getSortedState('functionalLocation')"
                           [isDescending]="getDescendingState('functionalLocation')">
        </app-sorting-caret>
    </li>
</ul>
