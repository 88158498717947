import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'

import { FeatureFlagService } from '../../../services/feature-flag.service'

@Directive({
    selector: '[appFeatureToggle]'
})
export class FeatureToggleDirective implements OnInit {
    @Input('appFeatureToggle') featureName: string

    private viewEmbedded = false

    constructor(
        private templateRef: TemplateRef<any>,
        private vcRef: ViewContainerRef,
        private featureFlagService: FeatureFlagService) { }

    ngOnInit(): void {
        const isFeatureOff = this.featureFlagService.isFeatureOff(this.featureName)
        if (isFeatureOff && this.viewEmbedded) {
            this.vcRef.clear()
            this.viewEmbedded = false
        } else if (!isFeatureOff && !this.viewEmbedded) {
            this.vcRef.createEmbeddedView(this.templateRef)
            this.viewEmbedded = true
        }
    }
}
