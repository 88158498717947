import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { AppState } from '@app/store/app.store'
import { combineLatest, Observable } from 'rxjs'
import { isOnline } from '@app/store/connection/connection.selectors'
import { filter, map } from 'rxjs/operators'
import { selectAutoDownloadEnabled } from '@app/store/offline/offline.selectors'
import { isUndefined } from 'lodash'

@Injectable({
    providedIn: 'root'
})
export class AutoDownloadsInterceptorGuard implements CanActivate {
    constructor(private router: Router, private store: Store<AppState>) { }

    canActivate(): Observable<boolean> {
        return combineLatest(
            this.store.select(isOnline),
            this.store.select(selectAutoDownloadEnabled)
        ).pipe(
            map(([online, isAutoDownloadEnabled]) => {
                if (online || isAutoDownloadEnabled) {
                    return true
                }
                this.router.navigate(['online-restricted'], {
                    replaceUrl: true
                })
            })
        )
    }
}
