import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { FormGroup } from '@angular/forms'

import { scw } from '@app/utils/classes/simple-changes-wrapper'

@Component({
    selector: 'app-final-pm-result-selector',
    templateUrl: './final-pm-result-selector.component.html',
    styleUrls: ['./final-pm-result-selector.component.scss']
})
export class FinalPmResultSelectorComponent implements OnChanges {

    @Input() calibrationForm: FormGroup
    @Input() useBlack = false
    @Input() isDisabled = false

    /**
     * If a template has no logic to calculate `calibrationResultStatus`
     * set this flag to `true` so we take the selection from this component as
     * the only source.
     *
     * Templates like Pass/Fail, Third Party, and Leak Check should enable this flag.
     */
    @Input() manualMode = false

    constructor() { }

    public get shouldShowWarning(): boolean {
        // In manual mode, the user always manually set final result so
        // we should not show them a warning.
        if (this.manualMode) {
            return false
        }
        return !!this.calibrationForm?.get('results.isFinalPMResultStatusManuallySet').value
    }

    ngOnChanges(changes: SimpleChanges): void {
        scw(changes)
            .forChangeIn('manualMode')
            .do(({ currentValue: currManualMode }) => {
                // If we are in manualMode, set `isFinalPMResultStatusManuallySet`
                // to `true`.
                if (currManualMode) {
                    this.calibrationForm?.get('results').patchValue({
                        isFinalPMResultStatusManuallySet: true
                    })
                }
            })
    }

    public revertResult(): void {
        // Reverting is not allow in manual mode.
        if (this.manualMode || this.isDisabled) {
            return
        }

        const calibrationResultStatus = this.calibrationForm?.get('results.calibrationResultStatus').value
        this.calibrationForm?.get('results').patchValue(
            {
                finalPMResultStatus: calibrationResultStatus,
                isFinalPMResultStatusManuallySet: false
            }
        )
    }

    public selectionChange(): void {
        if (this.manualMode) {
            const currentIsManuallySet = this.calibrationForm?.get('results.isFinalPMResultStatusManuallySet').value
            if (!currentIsManuallySet) {
                // Sometime, the form value changes but manualMode is not.
                // In that case, the above onChange will not run.
                this.calibrationForm?.get('results').patchValue({
                    isFinalPMResultStatusManuallySet: true
                })
            }
            return
        }

        const { finalPMResultStatus, calibrationResultStatus } = this.calibrationForm?.get('results').value
        // If the user select the same `finalPMResultStatus` as `calibrationResultStatus`,
        // we consider that as the user did not override anything.
        this.calibrationForm?.get('results').patchValue({
            isFinalPMResultStatusManuallySet: (finalPMResultStatus?.id !== calibrationResultStatus?.id)
        })
    }

}
