<!-- mobile view only -->

<div class="responsive-view slide-up-overlay">
    <app-overlay-header-info [header]="'Note To Technician'"
                             [subHeader]="'Equipment'"
                             [itemId]="equipmentId"
                             [itemDescription]="equipmentDescription"
                             [overlayId]="overlayId"></app-overlay-header-info>
    <hr class="details-line">
    <div class="body">
        <div body
             class="responsive-calibration-field-container">
            <p class="note">{{ note }}</p>
        </div>
    </div>
</div>