import { AppPolicyType } from '@app/models/app-policy/policy-type.enum'
import { PolicyCheckerServiceInjectorHelper } from '@app/services/policy-checker-injector-helper.service'

/**
 * Check policy to see if is valid before executing a class function member
 *
 * @param policyType - Policy type of enum
 */
export function AccessibleByPolicy(policyType: AppPolicyType): (...args: any) => void {
    return function (target: Object, fnName: string, descriptor: PropertyDescriptor): void {
        const method = descriptor.value

        descriptor.value = function (...args: any[]): any {
            if (PolicyCheckerServiceInjectorHelper.getPolicyCheckerService().isValid(policyType)) {
                method.apply(this, args)
            }
        }
    }
}

