import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-section-break',
    templateUrl: './section-break.component.html',
    styleUrls: ['./section-break.component.scss']
})
export class SectionBreakComponent {
    @Input() sectionTitle = ''
    @Input() breakLine = true
}
