import { Component, Injector, OnInit, ViewChild } from '@angular/core'
import {
    AbstractCalibrationTemplateComponent
} from '@app/modules/calibration/components/calibration-template/abstract-calibration-template.component'
import { FormControl } from '@angular/forms'
import { AnalyzerTemplate } from '@app/modules/calibration/models/analyzer-template.model'
import { MultiPointComponent } from '@app/modules/calibration/components/calibration-template/multi-point/multi-point.component'
import { CalibrationResultStatusService } from '@app/modules/calibration/services/calibration-result-status.service'
import { some } from 'lodash'
import { CalibrationResultStatusEnum } from '@app/modules/calibration/models/calibration-result-status.enum'

@Component({
    selector: 'app-analyzer',
    templateUrl: './analyzer.component.html',
})
export class AnalyzerComponent extends AbstractCalibrationTemplateComponent implements OnInit {
    public template: AnalyzerTemplate

    @ViewChild(MultiPointComponent, { static: true }) multiPointsSection: MultiPointComponent

    constructor(
        private calibrationResultStatusService: CalibrationResultStatusService,
        injector: Injector
    ) {
        super(injector)
        this.updateCalibrationResultByCustomFormAndSetpoints = this.updateCalibrationResultByCustomFormAndSetpoints.bind(this)
    }

    public updateCalibrationResultStatus(): void {
    }

    public onCustomFormFieldsChange(): void {
        this.updateCalibrationResultByCustomFormAndSetpoints(this.multiPointsSection.getCalculatedResultStatusBySetpoints())
    }

    public updateCalibrationResultByCustomFormAndSetpoints(statusFromSetpoints: any): void {
        const result = this.getCalculatedResultStatusByCustomForm(this.calibrationForm.get('customForm').value) || statusFromSetpoints
        this.calibrationResultStatusService.updateCalibrationResultStatus(
            this.calibrationForm.get('results.calibrationResultStatus') as FormControl,
            result)
    }

    private getCalculatedResultStatusByCustomForm(fieldInfos: any[]): CalibrationResultStatusEnum {
        return some(fieldInfos, info => info?.value === 'Failed') ? CalibrationResultStatusEnum.Failed : null
    }
}
