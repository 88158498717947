import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'

import { CalibrationDetails } from '@app/modules/calibration/models/calibration-details.model'
import { CalibrationStatusEnum } from '@app/modules/calibration/models/calibration-status.enum'
import { CalibrationStatusService } from '@app/modules/calibration/services/calibration-status.service'
import { ReopenCalibrationService } from '@app/modules/calibration/services/reopen-calibration.service'
import { modalContent } from '@app/modules/modal-container/constants/modal-message-content.constant'
import { mrmaStatus } from '@app/modules/shared/models/mrma-status-label.model'
import { MassUpdateService } from '@app/modules/work-order/services/mass-update.service'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import { calibrationDetails, reopenNotification } from '@app/store/calibration/calibration.selectors'
import { DestroyModalAction, ShowModalAction } from '@app/store/modal/modal.actions'
import { notifications } from '@app/store/work-order/work-order.selectors'
import { deepCopy, isNotAValue } from '@app/utils/app-utils.function'
import { toLocalMidnight } from '@app/utils/date.utils'
import { head } from 'lodash'

@Component({
    selector: 'app-deferred-not-tested',
    templateUrl: './deferred-not-tested.component.html',
    styleUrls: ['./deferred-not-tested.component.scss']
})
export class DeferredNotTestedComponent extends SafeUnsubscriberComponent implements OnInit {

    public reason: string = null
    public isDeferred: boolean = null
    public isMassUpdateMode: boolean
    public atoNumber: string
    public atoDate: Date

    public today = toLocalMidnight(new Date())

    public whatToInclude = {
        reason: false,
        markAs: false,
        atoNumber: false,
        atoDate: false
    }

    public shouldShowCheckbox = false
    public notAllPMInMassUpdateListAreDeferredOrNotTested: boolean
    private notAllPMInMassUpdateListAreNew: boolean
    private calibrationDetails: CalibrationDetails
    private notificationStatus: boolean

    constructor(
        private store: Store<AppState>,
        private calibrationStatusService: CalibrationStatusService,
        private massUpdateService: MassUpdateService,
        private reopenCalibrationService: ReopenCalibrationService
    ) {
        super()
    }

    public get isFormValid(): boolean {
        if (this.shouldShowCheckbox) {
            const overrideInputRef = [
                [this.whatToInclude.reason, !isNotAValue(this.reason, false)],
                [this.whatToInclude.markAs, !isNotAValue(this.isDeferred)],
                [this.whatToInclude.atoNumber, !isNotAValue(this.atoNumber, false)],
                [this.whatToInclude.atoDate, !isNotAValue(this.atoDate)]
            ]
            return !overrideInputRef.some(col => col[0] && !col[1]) && !overrideInputRef.every(col => !col[0])
        }

        return !isNotAValue(this.reason, false) && !isNotAValue(this.isDeferred)
    }

    ngOnInit(): void {
        this.addSubscription(
            this.store.select(calibrationDetails).subscribe(details => this.calibrationDetails = details)
        )

        this.notificationStatusHandler()

        const massUpdateList = this.massUpdateService.selectedEquipmentCalibrations$.value

        this.isMassUpdateMode = massUpdateList.length > 0
        this.notAllPMInMassUpdateListAreDeferredOrNotTested = !!massUpdateList.find(equipmentCalibration => {
            return equipmentCalibration.result !== 'Deferred' && equipmentCalibration.result !== 'Not Tested'
        })
        this.notAllPMInMassUpdateListAreNew = !!massUpdateList.find(equipmentCalibration => {
            return equipmentCalibration.status !== null
        })

        this.shouldShowCheckbox = this.isMassUpdateMode &&
            this.notAllPMInMassUpdateListAreNew

        if (!this.shouldShowCheckbox) {
            this.whatToInclude = {
                reason: true,
                markAs: true,
                atoNumber: true,
                atoDate: true
            }
        }

        if (this.notAllPMInMassUpdateListAreDeferredOrNotTested) {
            this.whatToInclude.reason = true
            this.whatToInclude.markAs = true
        }
    }

    public onDatePickerDateChanged(date: Date): void {
        this.atoDate = date
    }

    public submit(): void {
        if (!this.isFormValid) {
            return
        }


        if (this.isMassUpdateMode) {
            const resultStatus = this.isDeferred
                ? mrmaStatus.resultStatus.deferred
                : mrmaStatus.resultStatus.notTested

            const deferredOrNotTestedStatus = { id: resultStatus.id, name: resultStatus.name }

            this.massUpdateService.massUpdateForm.get('finalPMResultStatus').setValue(this.whatToInclude.markAs ? deferredOrNotTestedStatus : null)
            this.massUpdateService.massUpdateForm.get('reasonForNotComplete').setValue(this.whatToInclude.reason ? this.reason : null)
            this.massUpdateService.massUpdateForm.get('atoNumber').setValue(this.whatToInclude.atoNumber ? this.atoNumber : null)
            this.massUpdateService.massUpdateForm.get('atoDate').setValue(this.whatToInclude.atoDate ? this.atoDate : null)
            this.massUpdateService.updateAllClick$.next(true)
            this.close()
        } else {
            this.saveCalibrationConfirmation()
        }

    }

    public close(): void {
        if (this.isMassUpdateMode) {
            this.massUpdateService.setDeferredNotTestedMode(false)
        }
        this.store.dispatch(new DestroyModalAction())
    }

    private saveCalibration(): void {
        const resultStatus = this.isDeferred
            ? mrmaStatus.resultStatus.deferred
            : mrmaStatus.resultStatus.notTested

        const deferredOrNotTestedStatus = { id: resultStatus.id, name: resultStatus.name }

        const cloneDetails = deepCopy(this.calibrationDetails) as CalibrationDetails

        cloneDetails.reasonForNotComplete = this.reason
        cloneDetails.calibrationStatus = { id: CalibrationStatusEnum.Completed, name: 'completed' }
        cloneDetails.calibrationResultStatus = null
        cloneDetails.finalPMResultStatus = deferredOrNotTestedStatus
        cloneDetails.isFinalPMResultStatusManuallySet = true
        cloneDetails.atoNumber = this.atoNumber
        cloneDetails.atoDate = this.atoDate

        this.calibrationStatusService.createOrUpdateCalibration(
            cloneDetails.workOrderNumber,
            cloneDetails.equipmentId,
            cloneDetails
        )

        this.close()
    }

    private saveCalibrationConfirmation(): void {
        const isNotificationClosed = this.notificationStatus === false

        if (isNotificationClosed) {
            const modal = deepCopy(modalContent.confirmReOpenNotification)
            const cloneDetails = deepCopy(this.calibrationDetails) as CalibrationDetails
            modal.confirmCallback = () => {
                this.store.select(reopenNotification).subscribe(reopenResult => {
                    if (reopenResult === true) {
                        this.saveCalibration()
                    }
                })

                this.reopenCalibrationService.confirmReopenNotification(
                    cloneDetails.workOrderNumber,
                    cloneDetails.equipmentId,
                    cloneDetails)
            }
            this.store.dispatch(new ShowModalAction(modal))
        } else {
            this.saveCalibration()
        }
    }

    private notificationStatusHandler(): void {
        this.addSubscription(this.store.select(notifications).subscribe((wn) => {
            this.notificationStatus = head(Object.values(wn))?.isOpen
        }))
    }
}
