export class EquipmentCalibration {
    abcIndicator: string
    abcDescription: string
    description: string
    equipmentId: string
    equipmentTag: string
    functionalLocation: string
    techIdentificationNumber: string
    status: string
    result: string
    actionRequired: string[]
    isTemplateMapped: boolean
    equipmentNote: string
    dataSource: string
    flamingoFormIds: string[]
    equipmentNotificationNumber?: string
}
