import { Component, Input } from '@angular/core'

import { EquipmentCalibration } from '@app/modules/work-order/models/equipment-calibration.model'

@Component({
    selector: 'app-equipment-info',
    templateUrl: './equipment-info.component.html',
    styleUrls: ['./equipment-info.component.scss']
})
export class EquipmentInfoComponent {
    @Input() equipment: EquipmentCalibration
    @Input() showNoteToTechnician = true
}
