<div class="pika-title autocomplete-container">
    <div class="box-container" [ngClass]="{'disabled-background': optionsForm.disabled}">
        <app-unity-icon svgPath="./assets/images/filter_gblack.svg" size="small"
                        class="filter-icon"></app-unity-icon>
        <div class="selected-options-container">
            <div class="option-chip" [ngClass]="{'disabled-chip': optionsForm.disabled}"
                 *ngFor="let control of optionsForm.controls; let i = index">
                {{getDisplayedValueForSelectedTag(control.value)}}
                <app-unity-icon class="clickable" [ngClass]="{'clickable-disabled': optionsForm.disabled}"
                                unitySvgName="x-filled"
                                (click)="deleteOption(i)"></app-unity-icon>
            </div>
            <span class="filter-textarea">
            <input class="em-c-input" type="text" [ngModel]="searchText$ | async"
                   [ngClass]="{'disabled-background': optionsForm.disabled}"
                   (ngModelChange)="searchText$.next($event)" [disabled]="optionsForm.disabled"
                   placeholder="Please select filter" (focus)="onSearchBoxFocus()"
            />
        </span>
        </div>
    </div>
    <div *ngIf="!!selectableOptions"
         class="em-c-field__menu" [ngClass]="{'em-is-active': searchBoxFocused}">
        <ul class="em-c-typeahead-list">
            <li class="em-c-typeahead-list__item option-item"
                *ngFor="let option of selectableOptions" (click)="onClickOption(option)"
                [ngClass]="{'selected-option': hasSelectedOption(option)}">
                <app-unity-icon class="option-checked-icon" unitySvgName="check" size="small"
                                *ngIf="hasSelectedOption(option)"></app-unity-icon>
                {{option[config.displayedFieldName]}}
            </li>
            <li class="em-c-typeahead-list__item disabled-option"
                *ngIf="selectableOptions?.length < 1">
                No Result Found
            </li>
        </ul>
        <div class="footer-actions">
            <button class="em-c-btn" (click)="selectAll()">
                Select All
            </button>
            <button class="em-c-btn em-u-margin-left" (click)="deselectAll()">
                Remove All
            </button>
        </div>
    </div>
</div>
