import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import Pikaday, { PikadayOptions } from 'pikaday'

import { AppSettings } from '@settings/app.settings'

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, OnDestroy {

    @Input() isClearable = false

    @Input() placeHolder: string
    @Input() isDisabled: boolean
    @Input() format: object

    @Output() inputValueChange = new EventEmitter<Date>()

    @ViewChild('pikaday', { static: true }) element: ElementRef

    private _minDate: Date = null
    private _maxDate: Date = null

    private _inputValue: string

    private picker: Pikaday

    @Input() set inputValue(v: string) {
        this._inputValue = v
        if (this.picker && this._inputValue) {
            this.picker.setDate(new Date(v), true)
        } else if (this.picker && !this._inputValue) {
            this.picker.setDate(null, true)
        }
    }
    get inputValue(): string {
        return this._inputValue
    }
    @Input() set minDate(date: Date) {
        this._minDate = date
        this.picker?.setMinDate(date)
    }
    get minDate(): Date {
        return this._minDate
    }

    @Input() set maxDate(date: Date) {
        this._maxDate = date
        this.picker?.setMaxDate(date)
    }
    get maxDate(): Date {
        return this._maxDate
    }

    ngOnInit(): void {
        const hoistedInput = this.inputValueChange
        const pikadayOptions: PikadayOptions = {
            defaultDate: this.inputValue ? new Date(this.inputValue) : null,
            setDefaultDate: true,
            yearRange: [
                2018,
                new Date().getFullYear() + 5
            ],
            field: this.element.nativeElement,
            theme: 'triangle-theme',
            format: AppSettings.dateTimeFormat,
            onSelect: (newDate: Date): void => {
                hoistedInput.next(newDate)
            }
        }
        if (this.minDate) {
            pikadayOptions.minDate = this.minDate
        }
        if (this.maxDate) {
            pikadayOptions.maxDate = this.maxDate
        }
        this.picker = new Pikaday(pikadayOptions)
    }

    ngOnDestroy(): void {
        this.picker.destroy()
    }

    public showPicker(): void {
        if (!this.picker.isVisible()) {
            this.picker.show()
        }
    }

    public clearDate(): void {
        if (!this.isDisabled && this.picker) {
            // This is to reflect our custom UI
            this._inputValue = null

            // This is to reflect library's UI
            this.picker.setDate(null, true)

            // This is to send update back to the form
            // (setDate(null, false) alone doesn't work somehow)
            if (this.inputValueChange) {
                this.inputValueChange.next(null)
            }
        }
    }

}
