import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { AsyncValidatorFn, FormArray, FormControl, ValidatorFn } from '@angular/forms'

@Component({
    selector: 'app-multi-number',
    templateUrl: './multi-number.component.html',
    styleUrls: ['./multi-number.component.scss']
})
export class MultiNumberComponent implements OnInit {

    @Input() formArray: FormArray
    @Input() idPrefix = ''

    @Input() initialNumberOfFields
    @Input() maxNumberOfFields: number

    @Input() fieldValidators: ValidatorFn[] = []
    @Input() fieldAsyncValidators: AsyncValidatorFn[] = []
    @Input() defaultValue = null

    @Input() autoArrange: 'none' | 'asc' | 'desc' = 'none'

    // ---------------------------------------------------
    // Forward to Numeric Input
    // ---------------------------------------------------
    @Input() decimalPlaces: 0 | 1 | 2 | 3 | 4 | 5 | 6 = 5
    @Input() allowNegative = true
    @Input() blurToOriginalValue = false
    @Input() clearZeroOnFocus = false
    @Input() enforceDecimalOnBlur = false
    @Input() min: number | null = null
    @Input() max: number | null = null
    @Input() silentMaxWarning = false

    constructor(private cd: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.fillArrayToInitialIfEmpty()
    }

    public removeField(index: number): void {
        if (this.formArray && this.formArray.enabled) {
            this.formArray.removeAt(index)
            this.formArray.markAsDirty()
            this.formArray.markAsTouched()
        }
    }

    public addField(): void {
        if (this.formArray && this.formArray.enabled) {
            this.formArray.push(
                new FormControl(this.defaultValue, this.fieldValidators, this.fieldAsyncValidators)
            )
            this.formArray.markAsDirty()
            this.formArray.markAsTouched()
        }
    }

    public arrange(): void {
        // Guard against case when unknown option is passed (TS doesn't enforce type in HTML)
        if (this.autoArrange !== 'asc' && this.autoArrange !== 'desc') {
            return
        }

        const arrayValues = this.formArray.value as number[]
        const sortedValues = arrayValues.sort((a, b) => {

            if (a === b) {
                return 0
            }
            // keep null last
            if (a === null) {
                return 1
            }
            if (b === null) {
                return -1
            }

            if (this.autoArrange === 'asc') {
                return a - b
            } else {
                return b - a
            }

        })

        this.formArray.patchValue(sortedValues)

    }

    /** Init the array to the initialNumberOfFields */
    private fillArrayToInitialIfEmpty(): void {
        if (this.formArray && this.formArray.controls.length <= 0) {
            while (this.formArray.controls.length < this.initialNumberOfFields) {
                this.addField()
            }
        }
    }

}
