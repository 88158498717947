import { Component, ElementRef, Input, ViewChild } from '@angular/core'
import { Store } from '@ngrx/store'

import { AppState } from '@app/store/app.store'
import { SectionLoaderEnum } from '../../models/section-loader.enum'
import { SectionLoaderService } from '../../services/section-loader.service'
import { PagedListComponent } from '../paged-list/paged-list.component'
import { chain, findIndex, forEach, includes, isEmpty } from 'lodash'
import { FormArray, FormControl } from '@angular/forms'

@Component({
    selector: 'app-paged-list-checkbox',
    templateUrl: './paged-list-checkbox.component.html',
    styleUrls: ['./paged-list-checkbox.component.scss']
})

export class PagedListCheckboxComponent extends PagedListComponent {
    @Input() idKey = 'id'
    @Input() isUpdateMode: boolean
    @Input() selectedItemIdForm: FormArray

    constructor(store: Store<AppState>, sectionLoaderService: SectionLoaderService) {
        super(store, sectionLoaderService)
    }

    @ViewChild('pagedListReference', { static: true }) set sectionLoader(ref: ElementRef) {
        if (!this.sectionLoaderRef) {
            this.registerSectionLoader(SectionLoaderEnum.PagedList, ref)
        }
    }

    public selectAll(): void {
        if (this.isAllChecked()) {
            this.selectedItemIdForm.clear()
        } else {
            forEach(this.pages[this.selectedPage], item => {
                if (!this.selectedItemIdForm.value.includes(item[this.idKey])) {
                    this.selectedItemIdForm.push(new FormControl(item[this.idKey]))
                }
            })
        }
    }

    public isAllChecked(): boolean {
        return !isEmpty(this.items)
            && chain(this.pages[this.selectedPage])
                .map(this.idKey).xor(this.selectedItemIdForm.value).isEmpty().value()
    }

    public isItemChecked(item: any): boolean {
        return includes(this.selectedItemIdForm.value, item[this.idKey])
    }

    public inputBoxClicked(event: any, item: any): void {
        event.stopPropagation()
        const foundIndex = findIndex(this.selectedItemIdForm.value, id => id === item[this.idKey])
        if (foundIndex !== -1) {
            this.selectedItemIdForm.removeAt(foundIndex)
        } else {
            this.selectedItemIdForm.push(new FormControl(item[this.idKey]))
        }
    }
}
