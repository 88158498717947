import { animate, style, transition, trigger } from '@angular/animations'
import { Component, Injector, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { combineLatest, Observable } from 'rxjs'

import { UserProfile } from '@app/models/user.model'
import { WebComponentLoadStatusService } from '@app/modules/web-component/services/web-component-load-status.service'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { IdentityService } from '@app/services/identity.service'
import { OfflineService } from '@app/services/offline.service'
import { AppState } from '@app/store/app.store'
import { currentUser } from '@app/store/identity/identity.selectors'
import { environment } from '@environments/environment'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [
        trigger('slideInFromTop', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'translateY(-100%)'
                }),
                animate('500ms ease-out',
                    style({
                        opacity: 1,
                        transform: 'translateY(0%)'
                    })
                )
            ])
        ])
    ]
})
export class HeaderComponent extends SafeUnsubscriberComponent implements OnInit {
    public user$: Observable<UserProfile>
    public isMenuActive = false

    public isDevModeUI = false
    public isDevModeMenuExpanded = false

    public headerCSSClass = ''

    public userPermission: { [role: string]: boolean } = {}
    public webComponentLoadStatus$: Observable<{ [key: string]: false | 'cdn' | 'npm' }>

    // This function is wrapped in a closure so that they would not be init and available at all
    // in Production mode (in case there is a bug in the code that allow people to exploit these funcs).
    private devModeFunctions = (() => {
        const isLocalBackend = () => {
            const baseUrl = environment?.baseUrl ?? ''
            if (baseUrl.includes('localhost') || baseUrl.includes('127.0.0.1')) {
                return true
            }
            return false
        }
        const bindUserPermissionCalculation = () => {
            const identityService = this.injector.get(IdentityService)
            this.addSubscription(
                combineLatest([
                    identityService.isCurrentUserATechnician$,
                    identityService.isCurrentUserASupport$,
                    identityService.isCurrentUserAnAdmin$,
                    identityService.isCurrentUserAReviewer$,
                    identityService.isCurrentUserAnEngineer$
                ]).subscribe(([isTechnician, isSupport, isAdmin, isReviewer, isEngineer]) => {
                    this.userPermission = {
                        isTechnician,
                        isSupport,
                        isAdmin,
                        isReviewer,
                        isEngineer
                    }
                })
            )
        }
        const bindWebComponentLoadStatus = () => {
            const webComponentLoadStatusService = this.injector.get(WebComponentLoadStatusService)
            this.webComponentLoadStatus$ = webComponentLoadStatusService.loadedWebComponents$
        }
        return !environment.development ? undefined : {
            isLocalBackend,
            bindUserPermissionCalculation,
            bindWebComponentLoadStatus
        }
    }
    )()

    constructor(
        private router: Router,
        // Some services (such as the `identityService` are only needed here in Dev mode
        // so we use the `Injector` to inject it on demand instead of normal DI.
        private injector: Injector,
        private store: Store<AppState>,
        public offlineService: OfflineService,
    ) {
        super()
    }

    public disableDevModeUI(): void {
        this.isDevModeUI = false
        this.isDevModeMenuExpanded = false
        this.headerCSSClass = ''
    }

    public openCurrentLinkInDevEnv(): void {
        this.isDevModeMenuExpanded = false
        const urlPath = this.router.url
        window.open(`https://dev.mrma.xom.cloud/#${urlPath}`, '_blank')
    }

    ngOnInit(): void {
        this.user$ = this.store.pipe(select(currentUser))
        this.isDevModeUI = environment.development
        if (this.isDevModeUI) {
            this.configuredDevMode()
        }
        this.calculateHeaderCSSClass()
    }

    private configuredDevMode(): void {
        this.devModeFunctions?.bindUserPermissionCalculation()
        this.devModeFunctions?.bindWebComponentLoadStatus()
    }

    private calculateHeaderCSSClass(): void {
        if (environment.name?.toLocaleLowerCase()?.includes('acceptance')) {
            this.headerCSSClass = 'acc-mode'
            return
        }
        if (environment.development) {
            const isLocalBackend = !!this.devModeFunctions?.isLocalBackend()
            this.headerCSSClass = 'dev-mode' + (isLocalBackend ? ' local-backend' : '')
            // 🥚: Throw a 🎲 and apply some RAINBOWWWW~ (only in Dev and if not connecting to local BE)
            if (!isLocalBackend && Math.random() <= 0.025) {
                this.headerCSSClass = 'glorious-rainbow'
            }
            return
        }
    }
}
