<section class="em-l-container">
    <div class="floating-button"
         *ngIf="uploadData.length > 0"
         (cdkDragStarted)="handleDragStart()"
         (click)="handleClick()"
         cdkDrag>
        <p>
            <svg *ngIf="remainingPM > 0 else close"
                 class="em-c-icon em-c-icon--medium em-u-margin-right-half a-icon">
                <use xlink:href="./assets/unity-1.2.0/images/em-icons.svg#upload-cloud"
                     xmlns:xlink="http://www.w3.org/1999/xlink"></use>
            </svg>
            <ng-template #close>
                <svg class="em-c-icon em-c-icon--medium em-c-btn__icon">
                    <use xlink:href="./assets/unity-1.2.0/images/em-icons.svg#x-filled"
                         xmlns:xlink="http://www.w3.org/1999/xlink"></use>
                </svg>
            </ng-template>
            {{ remainingPM > 0 ? 'Upload Queue' : 'Close' }}
            <span *ngIf="remainingPM > 0">{{ remainingPM }}</span>
        </p>
        <div *ngIf="displayStatus"
             class="upload-status"
             appClickStopPropagation>
            <div class="status">
                <div class="header">
                    <h3>Upload Waiting list ({{ completed }} of {{ total }})</h3>
                    <button (click)="reSyncAll()"
                            *ngIf="syncFailedOnSome">
                        Re-Sync All
                    </button>
                </div>
                <div class="content">
                    <div *ngFor="let pm of uploadData"
                         class="pm-upload">
                        <div>
                            <p>WO: {{ pm.workOrderNumber }}</p>
                            <div class="line-break"></div>
                            <p>EQ: {{ pm.equipmentId }}</p>
                        </div>
                        <div class="status-info"
                             [ngClass]="[
                            pm.status === status.QUEUED ? 'queued': '',
                            pm.status === status.FAILED ? 'failed': '',
                            pm.status === status.SYNCED ? 'synced': ''
                        ]">
                            <div>
                                <svg *ngIf="pm.status===status.SYNCED"
                                     class="em-c-icon em-c-icon--medium em-u-margin-right-half a-icon synced">
                                    <use xlink:href="./assets/unity-1.2.0/images/em-icons.svg#circle-check"
                                         xmlns:xlink="http://www.w3.org/1999/xlink"></use>
                                </svg>
                                <svg *ngIf="pm.status === status.FAILED"
                                     class="em-c-icon em-c-icon--medium em-u-margin-right-half a-icon failed">
                                    <use xlink:href="./assets/unity-1.2.0/images/em-icons.svg#exclamation-filled"
                                         xmlns:xlink="http://www.w3.org/1999/xlink"></use>
                                </svg>
                                <svg *ngIf="pm.status === status.QUEUED"
                                     class="em-c-icon em-c-icon--medium em-u-margin-right-half a-icon">
                                    <use xlink:href="./assets/unity-1.2.0/images/em-icons.svg#clock"
                                         xmlns:xlink="http://www.w3.org/1999/xlink"></use>
                                </svg>
                                <img *ngIf="pm.status === status.SYNCING"
                                     src="./assets/unity-1.2.0/images/icon-spinner.svg"
                                     alt="Loading" />
                                <p>{{ getStatusText(pm.status) }}</p>
                            </div>
                            <button (click)="reSync(pm)"
                                    *ngIf="pm.status === status.FAILED"
                                    appClickStopPropagation>
                                Re-Sync
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
