import { HttpErrorResponse } from '@angular/common/http'
import { AfterViewInit, Component, OnInit } from '@angular/core'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Action, Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { debounceTime, filter, take, tap } from 'rxjs/operators'

import { DeferredNotTestedComponent } from '@app/modules/calibration/components/modals/deferred-not-tested/deferred-not-tested.component'
import { deferredOrNotTestedModalObject } from '@app/modules/calibration/components/modals/deferred-not-tested/deferred-not-tested.modal-object'
import { ModalType } from '@app/modules/modal-container/models/modal.model'
import { ModalContainerService } from '@app/modules/modal-container/services/modal-container.service'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { IdentityService } from '@app/services/identity.service'
import { AppState } from '@app/store/app.store'
import { currentUser } from '@app/store/identity/identity.selectors'
import { ShowModalAction } from '@app/store/modal/modal.actions'
import { GetAllUserAction } from '@app/store/user/user.actions'
import { techniciansInCurrentPlant } from '@app/store/user/user.selectors'
import { MassUpdateService } from '../../services/mass-update.service'
import { reasonRequiredWhenDeferNotTest } from './mass-update-validations.validators'

@Component({
    selector: 'app-mass-update',
    templateUrl: './mass-update.component.html',
    styleUrls: ['./mass-update.component.scss']
})
export class MassUpdateComponent extends SafeUnsubscriberComponent implements OnInit, AfterViewInit {
    public techniciansInCurrentPlantSelector = techniciansInCurrentPlant
    public isTechnician = false
    public datePickerMaxDate = new Date()

    constructor(
        public massUpdateService: MassUpdateService,
        public identityService: IdentityService,
        private modalContainerService: ModalContainerService,
        private store: Store<AppState>,
        private toastrService: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        super()
    }

    public get userGetAction(): Action {
        return new GetAllUserAction()
    }

    ngOnInit(): void {
        if (!this.massUpdateService.massUpdateForm) {
            this.store.select(currentUser)
                .pipe(filter(user => user !== undefined))
                .pipe(take(1))
                .subscribe(_currentUser => {
                    this.massUpdateService.massUpdateForm = new FormGroup({
                        pmPerformedDate: new FormControl(new Date(), [Validators.required]),
                        pmPerformedTechnicians: new FormArray([
                            new FormControl({
                                ..._currentUser, displayOrder: 1
                            })
                        ]),
                        comments: new FormControl(''),
                        finalPMResultStatus: new FormControl(null, [Validators.required]),
                        reasonForNotComplete: new FormControl(''),
                        atoNumber: new FormControl(null),
                        atoDate: new FormControl(null)
                    }, { validators: reasonRequiredWhenDeferNotTest })
                    if (!this.identityService.isCurrentUserATechnician$.value) {
                        this.massUpdateService.massUpdateForm.disable()
                    }
                })
        }
        this.massUpdateService.massUpdateForm.patchValue({ comments: '' })

        this.modalContainerService.registerComponent(
            DeferredNotTestedComponent,
            ModalType.DeferredNotTested)

        this.addSubscriptions([
            this.massUpdateService.updateAllClick$.pipe(
                filter(() => !this.massUpdateService.isRequestInFlight$.value && this.isTechnician),
                tap(() => {
                    setTimeout(() => {
                        this.massUpdateService.massUpdateForm?.disable()
                    })
                }),
                debounceTime(200)
            ).subscribe(() => {
                this.updateAll()
            }),

            this.identityService.isCurrentUserATechnician$.subscribe(isTechnician => {
                this.isTechnician = isTechnician
            })
        ])
    }

    ngAfterViewInit(): void {
        // If the user land on this page without any Eq. selected, redirect them
        // to ../overview page
        this.addSubscription(
            this.massUpdateService.selectedEquipmentCalibrations$.subscribe(
                selectedECs => {
                    if (!selectedECs || selectedECs.length <= 0) {
                        this.router.navigate(['../overview'], { relativeTo: this.route })
                    }
                }
            )
        )
    }

    public updateAll(): void {
        // Button should be disabled but well...
        this.massUpdateService.massUpdateForm.updateValueAndValidity()
        if (this.massUpdateService.massUpdateForm.invalid) {
            this.toastrService.error('', 'Missing some required fields.')
            setTimeout(() => {
                this.massUpdateService.massUpdateForm?.enable()
            })
            return
        }

        this.massUpdateService
            .updateSelectedEqCalibrationsWith(this.massUpdateService.massUpdateForm.getRawValue())
            .subscribe(
                () => {
                    // Reset the service's data
                    this.massUpdateService.clearAllSelection()
                    this.massUpdateService.massUpdateForm = undefined

                    this.router.navigate(['../overview'], { relativeTo: this.route })
                },
                (err: HttpErrorResponse) => {
                    this.router.navigate(['../overview'], { relativeTo: this.route })
                    setTimeout(() => {
                        this.massUpdateService.massUpdateForm?.enable()
                    })
                }
            )
    }

    public deferOrNotTestClick(): void {
        this.store.dispatch(new ShowModalAction(deferredOrNotTestedModalObject))
        this.massUpdateService.setDeferredNotTestedMode(true)
    }
}
