import { SortedState, SortingState } from '@app/modules/shared/models/filter.model'

export const resultListSortSetting = [
    {
        sortId: 'status',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'workOrderNumber',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'workOrderDescription',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'abcIndicator',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'equipmentId',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'equipmentTag',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'techIdentificationNumber',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'functionalLocation',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'lacd',
        sortingState: SortingState.None,
        sortOrder: 0
    }
] as SortedState[]
