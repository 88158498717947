<section #drawer
         class="responsive-sm">
    <div appClickStopPropagation
         class="drawer-header"
         (click)="close()">
        <a>CLOSE X</a>
    </div>
    <div *ngIf="selectionOptions as options"
         class="drawer-body">
        <ul>
            <li appClickStopPropagation
                *ngFor="let option of options"
                (click)="optionSelected(option.callback, option.disabled, option.allowDisabledDrawerCallback)"
                [ngClass]="{ 'disabled' : option.disabled, 'selected': option.currentSelection }">
                <div class="selected-icon-container">
                    <svg class="em-c-icon em-c-icon--medium em-c-icon--green"
                         *ngIf="option.currentSelection">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink"
                             xlink:href="./assets/unity-1.2.0/images/em-icons.svg#check"></use>
                    </svg>
                </div>
                <div class="option-text-container">
                    <span class="em-u-font-style-bold">{{ option.optionName }}</span>
                    <span *ngIf="option.subtitleLine1">{{ option.subtitleLine1 }}</span>
                    <span *ngIf="option.subtitleLine2">{{ option.subtitleLine2 }}</span>
                </div>
            </li>
        </ul>
    </div>
</section>
<div #background
     class="dark-overlay responsive-sm"
     (click)="close()"></div>