/**
 * Return a ISO-8601 formatted string in local time for a given `Date` object. \
 * ISO-8601: YYYY-MM-DDTHH:mm:ss.sssZ
 *
 * FYI, 'Z' is a shorthand for +00:00 timezone (UTC)
 *
 * @param date the Date() object to convert to local ISO-8601 string - timezone will be inferred from offset in this Date() object
 * @param includeMilliseconds default to **true**, set to false to return YYYY-MM-DDTHH:mm:ssZ instead
 * @param forceMidnight default to **false**, set to true to truncate time and set to **local** midnight
 */
export function dateToLocalISOString(date: Date, includeMilliseconds = true, forceMidnight = false): string {

    if (!(date instanceof Date)) {
        throw new Error(`Expect an instance of Date, received ${typeof (date)}`)
    }

    // A utility function to pad a number to two/three digits.
    // This function are not generalized to save performance
    // by avoiding branching and looping logics
    const zeroPad = (value: string | number, width: 2 | 3 = 2): string => {
        const str = '00000' + value
        return str.substr(-width)
    }

    // ------------------------------------------------
    // Prepare Date & Time Components
    // ------------------------------------------------

    const YYYY = date.getFullYear()

    // NOTE: getMonth() return 0 for Jan, 1 for Feb, and so on...
    const MM = zeroPad(date.getMonth() + 1, 2)
    const DD = zeroPad(date.getDate(), 2)

    // Yes, this date.getHours() returns a 24-hours hour
    const HH = forceMidnight ? '00' : zeroPad(date.getHours(), 2)
    const mm = forceMidnight ? '00' : zeroPad(date.getMinutes(), 2)
    const ss = forceMidnight ? '00' : zeroPad(date.getSeconds(), 2)
    const sss = forceMidnight ? '000' : zeroPad(date.getMilliseconds(), 3)

    // ------------------------------------------------
    // Prepare Timezone Component
    // ------------------------------------------------

    // 'Z' indicate that offset is 0 (or is in UTC)
    let tzStr = 'Z'

    const absTzOffsetInMinutes = Math.abs(date.getTimezoneOffset())

    if (absTzOffsetInMinutes > 0) {
        const tzHH = zeroPad(Math.floor(absTzOffsetInMinutes / 60), 2)
        const tzmm = zeroPad(absTzOffsetInMinutes % 60, 2)

        // *** This is INTENTIONALLY in opposite direction.
        // If OFFSET is negative, TIMEZONE should be positive
        const tzSign = date.getTimezoneOffset() > 0 ? '-' : '+'

        tzStr = `${tzSign}${tzHH}:${tzmm}`
    }

    return includeMilliseconds ?
        `${YYYY}-${MM}-${DD}T${HH}:${mm}:${ss}.${sss}${tzStr}` :
        `${YYYY}-${MM}-${DD}T${HH}:${mm}:${ss}${tzStr}`
}

/**
 * Return date in ***UTC*** **YYYY-M-D** format (*not* YYYY-MM-DD)
 *
 * i.e. 2020-08-31 6 AM GMT+7 time will result in 2020-08-**30**
 */
export const getUTCShortDate = (date: Date) => {
    return `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`
}

/**
 * Return current (machine's) ***UTC*** date-time
 */
export const getUTCNow = (
    daysToAdd = 0
): Date => {
    const nowDate = new Date()
    nowDate.setDate(nowDate.getDate() + daysToAdd)
    return nowDate
}

/**
 * Return current (machine's) ***UTC*** date-time in **YYYY-M-D** format (*not* YYYY-MM-DD)
 */
export const getUTCNowShortDate = (
    daysToAdd = 0
): string => {
    return getUTCShortDate(getUTCNow(daysToAdd))
}

/**
 * Return the local **timezone** (opposite sign of offset) in hours.
 *
 * i.e. GMT+7 will return +7 (not -7) \
 *      GMT+7:30 will return 7.5
 */
export const getLocalTimezoneHours = () => {
    const timeOffset = (new Date()).getTimezoneOffset()
    return timeOffset / -60
}

/**
 * Truncate time component from Date object by setting time to **local** midnight.
 */
export const toLocalMidnight = (date: Date): Date => {
    return new Date(dateToLocalISOString(date, true, true))
}

/**
 * Truncate time component from Date object by setting time to **UTC** midnight.
 */
export const toUTCMidnight = (date: Date): Date => {
    // This Date constructor also expect `monthIndex` (Jan = 0, Feb = 1, ...)
    // This date.getUTCMonth() already return the correct `monthIndex`, no need for +1
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0)
}

/**
 * Check to see if time in minute has passed by x amount
 * Note that this is used in equipment-list reducer to allow EQ sync, by passing null to dateTime it will return true
 * This is an expected behavior because Date in reducer will always start with null thus allowing initial sync time to be stored
 */
export const hasTimePastInMinutes = (dateTime: Date, minutesPast: number): boolean => {
    if (!dateTime) {
        return true
    }

    const intervalPastInMinutes = (new Date().valueOf() - dateTime.valueOf()) / 1000 / 60
    if (intervalPastInMinutes > minutesPast) {
        return true
    }

    return false
}

export const getStartOfMonthAtLocalMidnight = (date = new Date()) => {
    const dateCopy = new Date(date)
    dateCopy.setDate(1)
    return toLocalMidnight(dateCopy)
}

export const getEndOfMonthAtLocalMidnight = (date = new Date()) => {
    // If 0 is given as date, the resulting date will go 1 day back.
    // Thus, we increment month by 1, then go back one day by giving 0 as date
    return toLocalMidnight(new Date(date.getFullYear(), date.getMonth() + 1, 0))
}

export const getLocalDateString = (date: Date | string) => {
    if (typeof date === 'string') {
        date = new Date(date)
    }
    return date.toLocaleDateString('en-US')
}
