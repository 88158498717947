import { Component, HostListener, OnDestroy, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'

import { UserProfile } from '@app/models/user.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppMonitoringService } from '@app/services/app-monitoring.service'
import { OfflineService } from '@app/services/offline.service'
import { AppState } from '@app/store/app.store'
import { currentUser } from '@app/store/identity/identity.selectors'
import { LogoutUserAction } from '@app/store/user/user.actions'
import { environment } from '@environments/environment'

@Component({
    selector: 'app-header-status',
    templateUrl: './header-status.component.html',
    styleUrls: ['./header-status.component.scss']
})
export class HeaderStatusComponent extends SafeUnsubscriberComponent implements OnInit, OnDestroy {
    public readonly version = environment.version
    public readonly envName = environment.name?.substring(5) ?? 'Unknown'
    public user$: Observable<UserProfile>

    shouldShowBackground = false

    constructor(
        public offlineService: OfflineService,
        public appMonitoringService: AppMonitoringService,
        private store: Store<AppState>
    ) {
        super()
    }

    ngOnInit(): void {
        this.user$ = this.store.pipe(select(currentUser))
    }

    logout(): void {
        this.store.dispatch(new LogoutUserAction())
    }

    @HostListener('window:scroll', [])
    onWindowScroll(): void {
        const scrollIsAtTop = document.documentElement.scrollTop < 50 && document.body.scrollTop < 50
        if (!scrollIsAtTop && !this.shouldShowBackground) {
            this.shouldShowBackground = true
        } else if (scrollIsAtTop && this.shouldShowBackground) {
            this.shouldShowBackground = false
        }
    }
}
