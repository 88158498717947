import { cloneDeep, some } from 'lodash'

import { FilterFields } from '@app/modules/equipment/models/equipment-filter-fields.model'
import { NotificationBasics } from '@app/modules/work-order/models/notification-basic.model'
import { WorkOrderDetails } from '@app/modules/work-order/models/work-order-details.model'
import { isNotAValue } from '@app/utils/app-utils.function'
import * as ToDoAction from '../to-do/to-do.actions'
import * as WorkOrderAction from './work-order.actions'

export interface WorkOrderState {
    details: WorkOrderDetails
    isLoading: boolean
    mainWorkCenterFilterState: FilterFields[]
    plannerGroupFilterState: FilterFields[]
    plantSectionFilterState: FilterFields[]
    maintenancePlantFilterState: FilterFields[]
    locationFilterState: FilterFields[]
    isNotificationLoading: boolean
    notifications: NotificationBasics
}

const initialState: WorkOrderState = {
    details: undefined,
    isLoading: false,
    mainWorkCenterFilterState: [],
    plannerGroupFilterState: [],
    plantSectionFilterState: [],
    maintenancePlantFilterState: [],
    locationFilterState: [],
    isNotificationLoading: false,
    notifications: {}
}

export function reducer(
    state = initialState,
    action: WorkOrderAction.WorkOrderActions | ToDoAction.ToDoAction
): WorkOrderState {
    switch (action.type) {
        case WorkOrderAction.LoadWorkOrderDetailsAction.type:
            return {
                ...state,
                details: undefined,
                isLoading: true
            }
        case WorkOrderAction.LoadWorkOrderDetailsSuccessAction.type:
            return {
                ...state,
                details: action.workOrderDetails,
                notifications: {},
                isLoading: false
            }
        case ToDoAction.ActionType.AddToDo:
            return state?.details?.workOrderNumber === action.payload.workOrderNumber ? {
                ...state,
                details: {
                    ...state.details,
                    isBookmarked: true
                }
            } : state
        case ToDoAction.ActionType.AddToDoFailure:
            return state?.details?.workOrderNumber === action.payload.workOrderNumber ? {
                ...state,
                details: {
                    ...state.details,
                    isBookmarked: false
                }
            } : state
        case ToDoAction.ActionType.DeleteToDo:
            return state?.details?.workOrderNumber === action.payload.workOrderNumber ? {
                ...state,
                details: {
                    ...state.details,
                    isBookmarked: false
                }
            } : state
        case ToDoAction.ActionType.DeleteToDos:
            return some(action.payload, wo => wo.workOrderNumber === state?.details?.workOrderNumber) ? {
                ...state,
                details: {
                    ...state.details,
                    isBookmarked: false
                }
            } : state
        case ToDoAction.ActionType.DeleteToDoFailure:
            return state?.details?.workOrderNumber === action.payload.workOrderNumber ? {
                ...state,
                details: {
                    ...state.details,
                    isBookmarked: true
                }
            } : state
        case WorkOrderAction.GetWorkOrderMainWorkCenterSuccessAction.type:
            const mainWorkCenter = action.mainWorkCenter
                .map(c => ({ name: isNotAValue(c) ? '-' : c, selected: false }))
            return {
                ...state,
                mainWorkCenterFilterState: mainWorkCenter
            }
        case WorkOrderAction.GetWorkOrderPlannerGroupSuccessAction.type:
            const plannerGroup = action.PlannerGroup
                .map(c => ({ name: isNotAValue(c) ? '-' : c, selected: false }))
            return {
                ...state,
                plannerGroupFilterState: plannerGroup
            }
        case WorkOrderAction.GetWorkOrderPlantSectionSuccessAction.type:
            const plantSection = action.plantSection
                .map(c => ({ name: isNotAValue(c) ? '-' : c, selected: false }))
            return {
                ...state,
                plantSectionFilterState: plantSection
            }
        case WorkOrderAction.GetWorkOrderMaintenancePlantSuccessAction.type:
            const maintenancePlant = action.maintenancePlant
                .map(c => ({ name: isNotAValue(c) ? '-' : c, selected: false }))
            return {
                ...state,
                maintenancePlantFilterState: maintenancePlant
            }
        case WorkOrderAction.GetWorkOrderLocationSuccessAction.type:
            const location = action.location
                .map(c => ({ name: isNotAValue(c) ? '-' : c, selected: false }))
            return {
                ...state,
                locationFilterState: location
            }
        case WorkOrderAction.ApplyWorkOrderMainWorkCenterFilterAction.type:
            return {
                ...state,
                mainWorkCenterFilterState: action.filter
            }
        case WorkOrderAction.GetNotificationAction.type:
            const notificationsObject = cloneDeep(state.notifications)
            notificationsObject[action.notificationNumber] = { isLoading: true }
            return {
                ...state,
                notifications: notificationsObject,
                isNotificationLoading: true
            }
        case WorkOrderAction.GetNotificationSuccessAction.type:
            const notificationObject = cloneDeep(state.notifications)
            if (notificationObject[action.notification.number]) {
                Object.assign(notificationObject[action.notification.number], { ...action.notification, isLoading: false })
            } else {
                notificationObject[action.notification.number] = { ...action.notification, isLoading: false }
            }
            
            return {
                ...state,
                notifications: notificationObject,
                isNotificationLoading: false
            }
        case WorkOrderAction.GetNotificationFailureAction.type:
            return {
                ...state,
                notifications: {},
                isNotificationLoading: false
            }
        default:
            return state
    }
}
