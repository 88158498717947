import { AfterViewInit, Directive, Input } from '@angular/core'
import { NgControl } from '@angular/forms'

/**
 * Allow you to set a Reactive Form Control's disabled state through the DOM template
 */
@Directive({
    selector: '[formControlName][appDisableReactiveFormControl],[formControl][appDisableReactiveFormControl]',
})
export class DisableReactiveFormControlDirective implements AfterViewInit {

    private isDisabled: boolean

    constructor(private readonly ngControl: NgControl) { }

    @Input() set appDisableReactiveFormControl(_isDisabled: boolean) {
        this.isDisabled = _isDisabled
        this.applyControlDisabledState(_isDisabled)
    }

    ngAfterViewInit(): void {
        // Sometimes, the view have yet to finished initializing
        // when the setter run. This ensure to apply the state again
        // when the view finished init.
        // ----
        // The setTimeout() queue the function to after current CD cycle
        // to avoid ExpressionChangedAfterChecked error
        setTimeout(() => {
            this.applyControlDisabledState(this.isDisabled)
        })
    }

    private applyControlDisabledState(_isDisabled: boolean): void {
        if (_isDisabled) {
            this.ngControl?.control?.disable()
        } else {
            this.ngControl?.control?.enable()
        }
    }
}
