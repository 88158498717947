import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { take } from 'rxjs/operators'

import { CalibrationDetails } from '@app/modules/calibration/models/calibration-details.model'
import { modalContent } from '@app/modules/modal-container/constants/modal-message-content.constant'
import { Modal } from '@app/modules/modal-container/models/modal.model'
import { AppState } from '@app/store/app.store'
import { ResumeCalibration } from '@app/store/calibration/calibration.actions'
import { equipmentTemplateList } from '@app/store/equipment/selectors/equipment-template.selectors'
import { ShowModalAction } from '@app/store/modal/modal.actions'
import { deepCopy } from '@app/utils/app-utils.function'
import { safeCallback } from '../../../utils/app-utils.function'

@Injectable({
    providedIn: 'root'
})
export class ResumeCalibrationService {

    constructor(private store: Store<AppState>) { }

    public confirmResume(
        calibration: CalibrationDetails,
        closeCallback?: any
    ): void {
        let modal: Modal

        modal = deepCopy(modalContent.resumeCalibration) as Modal
        modal.confirmCallback = () => this.resume(calibration)
        modal.closeCallback = () => safeCallback(closeCallback)
        this.store.dispatch(new ShowModalAction(modal))
    }

    private resume(calibration: CalibrationDetails): void {
        this.store.select(equipmentTemplateList).pipe(take(1)).subscribe(template => {
            this.store.dispatch(new ResumeCalibration(calibration, template.length > 1))
        })
    }
}
