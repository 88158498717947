<!--
    Since the bar itself will have position fix, if page is too
    short, the bar could end up covering the other things.
    This spacer ensure there is sufficient space at the bottom.
-->
<div class="bar-spacer"></div>
<div class="bar">
    <app-main-container height="100%">
        <div class="toolbar">
            <div class="toolbar--left">
                <ng-container *ngTemplateOutlet="leftToolbar"></ng-container>
            </div>
            <div class="toolbar--right">
                <ng-container *ngTemplateOutlet="rightToolbar"></ng-container>
            </div>
        </div>
    </app-main-container>
</div>
