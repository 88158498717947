<section *ngIf="!isOnMobileOrTabletDevice">
    <div class="em-l-grid em-l-grid--4up">
        <div class="em-l-grid__item">
            <app-label [title]="'Tolerance (Repeatability)'">
                {{ calibration.calibrationTemplate.tolerance.value }}
                {{ calibration.calibrationTemplate.tolerance.unitOfMeasurement.uomCodeForTech }}
            </app-label>
        </div>
    </div>
    <hr class="details-line">
    <div class="compare-header em-l-grid em-l-grid--4up">
        <div class="em-l-grid__item em-c-field__label">
            Equipment
        </div>
        <div class="em-l-grid__item em-c-field__label">
            As Found ({{ calibration.calibrationTemplate.tolerance.unitOfMeasurement.uomCodeForTech }})
        </div>
        <div class="em-l-grid__item em-c-field__label">
            As Left ({{ calibration.calibrationTemplate.tolerance.unitOfMeasurement.uomCodeForTech }})
        </div>
    </div>
    <div *ngFor="let form of getResultSetControl().controls; let i = index"
         class="em-l-grid em-l-grid--4up compare-item">
        <div class="em-l-grid__item">
            <div class="em-c-field__label mobile-label">Equipment</div>
            {{ equipmentList[i].equipmentTag }}
        </div>
        <div class="em-l-grid__item">
            <div class="em-c-field__label mobile-label">As Found
            </div>
            <app-label [classList]="isDisabled ? 'em-is-disabled' : ''">
                <app-numeric-input appInputValidator
                                   [appCalibrationInputReport]="isReport"
                                   [formControl]="form.get('asFound')"
                                   [input]="isInputValid('asFound')"
                                   (inputBlur)="autoPopulateAsLeft()"
                                   placeholder="0.0"
                                   inputId="asFound"
                                   [tabIndex]="i + 1">
                </app-numeric-input>
            </app-label>
        </div>
        <div class="em-l-grid__item">
            <div class="em-c-field__label mobile-label">As Left
            </div>
            <app-label [classList]="isDisabled ? 'em-is-disabled' : ''">
                <app-numeric-input appInputValidator
                                   [appCalibrationInputReport]="isReport"
                                   [formControl]="form.get('asLeft')"
                                   [input]="isInputValid('asLeft')"
                                   placeholder="0.0"
                                   inputId="asLeft"
                                   [tabIndex]="i + 4">
                </app-numeric-input>
            </app-label>
        </div>
    </div>
</section>
<section *ngIf="isOnMobileOrTabletDevice">
    <app-section title="Ranges and Tolerances">
        <div body
             class="responsive-spacing">
            <app-responsive-calibration-input [singleFieldColumn]="true"
                                              [rowLabelHead]="'Tolerance (' + calibration.calibrationTemplate.tolerance.unitOfMeasurement.uomCodeForTech + ')'"
                                              [rowLabelResult]="calibration.calibrationTemplate.tolerance.value">
            </app-responsive-calibration-input>
        </div>
    </app-section>
    <app-section [title]="'As Found (' + calibration.calibrationTemplate.tolerance.unitOfMeasurement.uomCodeForTech + ')'">
        <div body
             class="responsive-spacing">
            <div *ngFor="let form of getResultSetControl().controls; let i = index">
                <app-responsive-calibration-input [allowReading]="true"
                                                  [displayInputLabel]="equipmentList[i].equipmentTag">
                    <div readingInput>
                        <app-numeric-input appInputValidator
                                           [appCalibrationInputReport]="isReport"
                                           [formControl]="form.get('asFound')"
                                           [input]="isInputValid('asFound')"
                                           (inputBlur)="autoPopulateAsLeft()"
                                           placeholder="0.0">
                        </app-numeric-input>
                    </div>
                </app-responsive-calibration-input>
            </div>
        </div>
    </app-section>
    <app-section [title]="'As Left (' + calibration.calibrationTemplate.tolerance.unitOfMeasurement.uomCodeForTech + ')'">
        <div body
             class="responsive-spacing">
            <div *ngFor="let form of getResultSetControl().controls; let i = index">
                <app-responsive-calibration-input [allowReading]="true"
                                                  [displayInputLabel]="equipmentList[i].equipmentTag">
                    <div readingInput>
                        <app-numeric-input appInputValidator
                                           [appCalibrationInputReport]="isReport"
                                           [formControl]="form.get('asLeft')"
                                           [input]="isInputValid('asLeft')"
                                           placeholder="0.0">
                        </app-numeric-input>
                    </div>
                </app-responsive-calibration-input>
            </div>
        </div>
    </app-section>
</section>
