import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'minMax'
})
export class MinMaxPipe implements PipeTransform {
    transform(values: number[]): any {
        const min = Math.min(...values) ?? '-'
        const max = Math.max(...values) ?? '-'

        return [min, max]
    }
}
