import { createSelector } from '@ngrx/store'

import { FlamingoFormsQuery } from '@app/modules/flamingo/models/flamingo-form-query.model'
import { FlamingoForm } from '@app/modules/flamingo/models/flamingo-form.model'
import { deepCopy, sortArray } from '@app/utils/app-utils.function'
import { AppState } from '../app.store'
import * as fromFlamingo from './flamingo.reducer'

export const flamingoStore = (state: AppState) => state.flamingo
export const flamingoForms = createSelector(flamingoStore, fromFlamingo.selectAll)

export const queryFlamingoForms = (query: FlamingoFormsQuery) => createSelector(
    flamingoForms,
    (_flamingoForms: FlamingoForm[]) => {
        const copiedList = deepCopy(_flamingoForms)
        let filteredList = copiedList
        if (query.search) {
            const searchText = query.search.toLocaleLowerCase()
            filteredList = copiedList.filter(form => {
                return (form.name || '').toLocaleLowerCase().includes(searchText)
                    || (form.description || '').toLocaleLowerCase().includes(searchText)
            })
        }
        const sortKey = query.sortBy ?? 'name'
        const sortedList = sortArray(filteredList, sortKey, !!query.isDesc)
        const startIndex = (query.currentPage - 1) * query.itemPerPage
        const endIndex = startIndex + query.itemPerPage
        return sortedList.slice(startIndex, endIndex)
    }
)

export const totalFlamingoFormsCount = createSelector(
    flamingoStore,
    (_flamingoStore: fromFlamingo.FlamingoState) => {
        return _flamingoStore.totalFlamingoFormsCount
    }
)

export const isListLoading = createSelector(
    flamingoStore,
    (_flamingoStore: fromFlamingo.FlamingoState) => {
        return _flamingoStore.isListLoading
    }
)

export const hasError = createSelector(
    flamingoStore,
    (_flamingoStore: fromFlamingo.FlamingoState) => {
        return _flamingoStore.hasError
    }
)
