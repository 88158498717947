<header class="page-header"
        [ngClass]="{ 'show-line': showLine }">
    <a class="em-c-text-link back-button"
       *ngIf="backButtonText"
       [routerLink]="backButtonRouterLink"
       [queryParams]="backButtonQueryParams">
        <svg class="em-c-icon em-c-icon--small em-u-margin-right-half a-icon">
            <use xlink:href="./assets/unity-1.2.0/images/em-icons.svg#chevron-left-filled"
                 xmlns:xlink="http://www.w3.org/1999/xlink"></use>
        </svg>
        <span class="back-button--text">
            {{ backButtonText }}
        </span>
    </a>
    <h4>{{ subtitle }}</h4>
    <h1>{{ titleMain }} <span> {{titleDesc}} </span></h1>
    <div class="toolbar"
         *ngIf="leftToolbar || rightToolbar">
        <div class="toolbar--left">
            <ng-container *ngTemplateOutlet="leftToolbar"></ng-container>
        </div>
        <div class="toolbar--right">
            <ng-container *ngTemplateOutlet="rightToolbar"></ng-container>
        </div>
    </div>
</header>
