<section class="admin-form-section">
    <app-admin-form-row [formGroup]="form"
                        label="PROCESS *">
        <div class="em-l-grid__item admin-input-width">
            <app-dropdown [form]="form"
                          [formControlPath]="'process'"
                          [options]="(processes$ | async)"
                          [key]="'id'"
                          [value]="'name'">
            </app-dropdown>
        </div>
    </app-admin-form-row>
    <app-admin-form-row [formGroup]="form"
                        label="TEMPLATE ALIAS">
        <div class="em-l-grid__item admin-input-width">
            <input formControlName="alias"
                   type="text"
                   class="em-c-input em-u-font-style-regular"
                   maxlength="20">
        </div>
    </app-admin-form-row>

    <app-admin-form-row [formGroup]="form"
                        label="PROCEDURE NUMBER">
        <div class="em-l-grid__item admin-input-width em-c-field">
            <input formControlName="procedure"
                   type="text"
                   placeholder="B4P-RXG-XXX.doc"
                   class="em-c-input em-u-font-style-regular">
        </div>
    </app-admin-form-row>
</section>
<section class="admin-form-section">
    <app-admin-form-row [formGroup]="form.get('numberOfPoints')"
                        label="NUMBER OF POINTS">
        <div class="em-l-grid__item admin-input-width">
            <app-dropdown [form]="form.get('numberOfPoints')"
                          [formControlPath]="'point'"
                          [options]="numberOfPointOptions"
                          [key]="'key'"
                          [value]="'value'">
            </app-dropdown>
        </div>
    </app-admin-form-row>
    <app-admin-form-row [formGroup]="form.get('input')"
                        label="TESTING VALUE *">
        <div class="em-l-grid__item admin-input-width">
            <app-dropdown [form]="form.get('input')"
                          [formControlPath]="'unitOfMeasurement'"
                          [options]="(uomList$ | async | uomPresentationPipe)"
                          [key]="'uomCode'"
                          [value]="'uomDisplayValue'">
            </app-dropdown>
        </div>
        <div [formGroup]="form.get('input')"
             class="em-l-grid__item admin-input-width em-c-field">
            <app-feet-inches-inch16 *ngIf="isInch16('input.unitOfMeasurement') else inputMinimumRange"
                                    [isInch16]="false"
                                    [form]="form.get('input.minimumRange')"></app-feet-inches-inch16>
            <ng-template #inputMinimumRange>
                <app-numeric-input formControlName="minimumRange"
                                   [clearZeroOnFocus]="true">
                </app-numeric-input>
            </ng-template>
        </div>
    </app-admin-form-row>
    <app-admin-form-row [formGroup]="form.get('accuracyTolerance')"
                        label="TOLERANCE *">
        <div class="em-l-grid__item admin-input-width">
            <app-dropdown [form]="form.get('accuracyTolerance')"
                          [formControlPath]="'unitOfMeasurement'"
                          [options]="toleranceUoMs | uomPresentationPipe"
                          [key]="'uomCode'"
                          [value]="'uomDisplayValue'">
            </app-dropdown>
        </div>
        <div [formGroup]="form.get('accuracyTolerance')"
             class="em-l-grid__item admin-input-width em-c-field">
            <app-feet-inches-inch16 *ngIf="isInch16('accuracyTolerance.unitOfMeasurement') else accuracyToleranceValue"
                                    [isInch16]="true"
                                    [form]="form.get('accuracyTolerance.value')"></app-feet-inches-inch16>
            <ng-template #accuracyToleranceValue>
                <app-numeric-input formControlName="value"
                                   [clearZeroOnFocus]="true">
                </app-numeric-input>
            </ng-template>
        </div>
    </app-admin-form-row>
</section>
<app-admin-form-row [formGroup]="form"
                    label="ADJUSTABLE TESTING VALUE"
                    description="(Technician can overwrite testing value)">
    <div class="em-l-grid__item admin-input-width">
        <app-options [formGroup]="form"
                     [formControlPath]="'isInjectedInputRequired'"
                     [defaultOption]="getOption(form.get('isInjectedInputRequired').value, yesNoOptions)"
                     [labelStyle]="'em-u-width-50'"
                     [options]="yesNoOptions"
                     [disabled]="!isAdmin"
                     [key]="'key'"
                     [value]="'value'">
        </app-options>
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="form"
                    label="AVERAGE READING">
    <div class="em-l-grid__item admin-input-width">
        <app-options [formGroup]="form"
                     [formControlPath]="'isAverageReadingUsed'"
                     [defaultOption]="getOption(form.get('isAverageReadingUsed').value, yesNoOptions)"
                     [labelStyle]="'em-u-width-50'"
                     [options]="yesNoOptions"
                     [disabled]="!isAdmin"
                     [key]="'key'"
                     [value]="'value'">
        </app-options>
    </div>
</app-admin-form-row>
