import { Component, Input, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'

import { SlideUpOverlayType } from '@app/modules/shared/constants/slide-up-overlay.enum'
import { WorkOrderDetails } from '@app/modules/work-order/models/work-order-details.model'
import { WorkOrderService } from '@app/modules/work-order/services/work-order.service'
import { AppState } from '@app/store/app.store'
import { SlideOverlayAction } from '@app/store/overlay/overlay.actions'
import { GetNotificationAction } from '@app/store/work-order/work-order.actions'

@Component({
    selector: 'app-overlay-workorder-details',
    templateUrl: './overlay-workorder-details.component.html',
    styleUrls: ['./overlay-workorder-details.component.scss']
})
export class OverlayWorkorderDetailsComponent implements OnInit {
    @Input() workOrderDetails: WorkOrderDetails
    @Input() isReport = false
    @Input() lazyLoadNotification = true

    public overlayId = SlideUpOverlayType.WorkOrderDetails
    public workOrderStatus: string

    constructor(
        private store: Store<AppState>,
        private workOrderService: WorkOrderService
    ) { }


    ngOnInit(): void {
        this.workOrderStatus = this.workOrderService.calculateWorkOrderStatusPill(this.workOrderDetails.systemStatus)
        this.store.dispatch(GetNotificationAction({
            workOrderNumber: this.workOrderDetails.workOrderNumber,
            notificationNumber: this.workOrderDetails.notification.number
        }))
    }

    public closeOverlay(): void {
        this.store.dispatch(new SlideOverlayAction({ visibility: false, id: SlideUpOverlayType.WorkOrderDetails }))
    }

    public getPrimaryNotification(notificationNumber: string): string {
        return this.workOrderService.getPrimaryNotification(notificationNumber, this.workOrderDetails.equipmentCalibrations)
    }
}
