import { Injectable, Type } from '@angular/core'

import { environment } from '@environments/environment.base'
import { TemplateTypeEnum } from '../../equipment/models/template-type.enum'
import { CompareComponent } from '../components/calibration-template/compare/compare.component'
import { MultiPointComponent } from '../components/calibration-template/multi-point/multi-point.component'
import { OnePointComponent } from '../components/calibration-template/one-point/one-point.component'
import { PassFailCheckComponent } from '../components/calibration-template/pass-fail-check/pass-fail-check.component'
import { RepeatabilityComponent } from '../components/calibration-template/repeatability/repeatability.component'
import { SafetyValveComponent } from '../components/calibration-template/safety-valve/safety-valve.component'
import { SequentialComponent } from '../components/calibration-template/sequential/sequential.component'
import { TemperatureComponent } from '../components/calibration-template/temperature/temperature.component'
import { ThirdPartyComponent } from '../components/calibration-template/third-party/third-party.component'
import { WeightComponent } from '../components/calibration-template/weight/weight.component'
import {
    AnalyzerComponent
} from '../components/calibration-template/analyzer/analyzer.component'

@Injectable({
    providedIn: 'root'
})
export class TemplateContainerService {
    private templateRegistry = {}

    constructor() {
        this.templateRegistry[TemplateTypeEnum[TemplateTypeEnum.sequential]] = SequentialComponent
        this.templateRegistry[TemplateTypeEnum[TemplateTypeEnum.leakCheck]] = ThirdPartyComponent
        this.templateRegistry[TemplateTypeEnum[TemplateTypeEnum.weightFourPoint]] = WeightComponent
        this.templateRegistry[TemplateTypeEnum[TemplateTypeEnum.weightSixPoint]] = WeightComponent
        this.templateRegistry[TemplateTypeEnum[TemplateTypeEnum.temperature]] = TemperatureComponent
        this.templateRegistry[TemplateTypeEnum[TemplateTypeEnum.compareTwoPoint]] = CompareComponent
        this.templateRegistry[TemplateTypeEnum[TemplateTypeEnum.compareThreePoint]] = CompareComponent
        this.templateRegistry[TemplateTypeEnum[TemplateTypeEnum.thirdParty]] = ThirdPartyComponent
        this.templateRegistry[TemplateTypeEnum[TemplateTypeEnum.leakCheck]] = ThirdPartyComponent
        this.templateRegistry[TemplateTypeEnum[TemplateTypeEnum.passFailCheck]] = PassFailCheckComponent
        this.templateRegistry[TemplateTypeEnum[TemplateTypeEnum.repeatability]] = RepeatabilityComponent
        this.templateRegistry[TemplateTypeEnum[TemplateTypeEnum.onePoint]] = OnePointComponent
        this.templateRegistry[TemplateTypeEnum[TemplateTypeEnum.safetyValve]] = SafetyValveComponent
        this.templateRegistry[TemplateTypeEnum[TemplateTypeEnum.multiPoint]] = MultiPointComponent
        this.templateRegistry[TemplateTypeEnum[TemplateTypeEnum.analyzer]] = AnalyzerComponent
    }

    /**
     * Get Component class for typeId
     *
     * @param typeId is a number from {@link TemplateTypeEnum}
     *
     * @throws `Error` if `typeId` is not recognized.
     */
    public getComponent(typeId: TemplateTypeEnum): Type<{}> {
        if (!typeId) {
            return this.templateRegistry[TemplateTypeEnum[TemplateTypeEnum.passFailCheck]]
        }

        // There might be a case where the user is trying to access a report created by
        // a newer version of MRMA with a template that doesn't yet exist in their local version
        if (!this.isAKnownTemplateTypeId(typeId)) {
            throw new Error(`Template not found for TemplateTypeId: ${typeId}. `
                + `You may need to update MRMA to a newer version. (Currently on ${environment.version})`)
        }

        return this.templateRegistry[typeId]
    }

    public isAKnownTemplateTypeId(typeId: TemplateTypeEnum): boolean {
        return !!this.templateRegistry[typeId]
    }
}
