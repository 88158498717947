import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'

import { DirectivesModule } from '../directives/directives.module'
import { IconButtonComponent } from './components/icon-button/icon-button.component'
import { LeftLabeledSheetComponent } from './components/left-labeled-sheet/left-labeled-sheet.component'
import { ResLabeledElementComponent } from './components/res-labeled-element/res-labeled-element.component'
import { ResSectionHeaderComponent } from './components/res-section-header/res-section-header.component'
import { StickyBottomBarComponent } from './components/sticky-bottom-bar/sticky-bottom-bar.component'
import { CustomFormDropdownComponent } from './controls/form-builder/custom-form-dropdown/custom-form-dropdown.component'
import { CustomFormInputComponent } from './controls/form-builder/custom-form-input/custom-form-input.component'
import { CustomFormTextareaComponent } from './controls/form-builder/custom-form-textarea/custom-form-textarea.component'
import { MultiNumberComponent } from './controls/multi-number/multi-number.component'
import { MultiselectChecklistComponent } from './controls/multiselect-checklist/multiselect-checklist.component'
import { NumericInputComponent } from './controls/numeric-input/numeric-input.component'
import { RadioButtonListComponent } from './controls/radio-button-list/radio-button-list.component'
import { SimpleCheckboxComponent } from './controls/simple-checkbox/simple-checkbox.component'
import { RectGhostLoadingComponent } from './ghost-loading/rect-ghost-loading/rect-ghost-loading.component'
import { BottomSheetComponent } from './layouts/bottom-sheet/bottom-sheet.component'
import { DetailedPageHeaderComponent } from './layouts/detailed-page-header/detailed-page-header.component'
import { ExpandableSectionComponent } from './layouts/expandable-section/expandable-section/expandable-section.component'
import { InlineLabelComponent } from './layouts/inline-label/inline-label.component'
import { MainContainerComponent } from './layouts/main-container/main-container.component'
import { MRMACardComponent } from './layouts/mrma-card/mrma-card.component'
import { ResTableComponent } from './layouts/res-table/res-table.component'
import { ArrowTooltipComponent } from './components/arrow-tooltip/arrow-tooltip.component'
import { ArrowTooltipDirective } from './components/arrow-tooltip/arrow-tooltip.directive'
import { OverlayModule } from '@angular/cdk/overlay'

/**
 * This module wrap MRMA's custom UI Components.
 *
 * These are components whose sole purpose is to display data.
 * They should not contain any business logics
 * (instead, they should expose API to allow other functions to be wired).
 */

const declareAndExport = [
    InlineLabelComponent,
    RectGhostLoadingComponent,
    NumericInputComponent,
    MultiNumberComponent,
    CustomFormDropdownComponent,
    CustomFormInputComponent,
    CustomFormTextareaComponent,
    ResSectionHeaderComponent,
    ResLabeledElementComponent,
    ResTableComponent,
    ExpandableSectionComponent,
    MRMACardComponent,
    MainContainerComponent,
    DetailedPageHeaderComponent,
    BottomSheetComponent,
    IconButtonComponent,
    StickyBottomBarComponent,
    LeftLabeledSheetComponent,
    RadioButtonListComponent,
    MultiselectChecklistComponent,
    SimpleCheckboxComponent,
    ArrowTooltipComponent,
    ArrowTooltipDirective,
]
@NgModule({
    declarations: [
        ...declareAndExport
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DirectivesModule,
        RouterModule,
        OverlayModule
    ],
    exports: [
        ...declareAndExport,
    ],
    bootstrap: [RectGhostLoadingComponent],
})
export class MrmaUiComponentsModule { }
