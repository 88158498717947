import { camelToStartCase } from './string.utils'

const mapping = {
    calibrationResultStatus: 'PM Result',
    finalPMResultStatus: 'PM Result',
    response: 'Checklist'
}

/**
 * Convert any variables (stuffs inside ${}) in a string literal to
 * a more "humanized" string.
 *
 * If the literal has a known mapping, it will be replaced with the mapping.
 * If not, it will attempt to convert the string from camelCase to Start Case.
 * Otherwise, it will simply leave the literal as is.
 *
 * Example:
 *
 * ```ts
 * const city = 'bangkokCityTH'
 * humanizeStr`I am living in ${city} at the time of writing.`
 * ```
 *
 * will return `"I am living in Bangkok City TH at the time of writing."`
 */
export const humanizeStr = (strings, ...values) => {
    const results = []

    strings.forEach(str => {
        results.push(str)
        const value = values.shift() || ''
        results.push(mapping[value] || camelToStartCase(value) || value)
    })

    return results.join('')
}
