import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import { TemplateTypeEnum } from '@app/modules/equipment/models/template-type.enum'
import { LabelStatus, mrmaStatus } from '@app/modules/shared/models/mrma-status-label.model'
import { AppState } from '@app/store/app.store'
import { CreateCalibrationDetailsAction, UpdateCalibrationDetailsAction } from '@app/store/calibration/calibration.actions'
import { isNotAValue } from '@app/utils/app-utils.function'
import { CalibrationDetails } from '../models/calibration-details.model'
import { CalibrationStatusEnum } from '../models/calibration-status.enum'
import { CalibrationStatus } from '../models/calibration-status.model'
import { CalibrationResultStatusService } from './calibration-result-status.service'

@Injectable({
    providedIn: 'root'
})
export class CalibrationStatusService {

    constructor(
        private store: Store<AppState>,
        private calibrationResultStatusService: CalibrationResultStatusService
    ) { }

    public getCalibrationStatusDisplay(calibrationStatus: CalibrationStatus): LabelStatus {
        if (calibrationStatus) {
            return mrmaStatus.calibrationStatus[calibrationStatus.id]
        }
        return mrmaStatus.calibrationStatus[0]
    }

    // TODO: Refactor - maybe this should be in calibration service or something?
    public createOrUpdateCalibration(workOrderNumber: string, equipmentId: string, calibration: CalibrationDetails): void {
        calibration = this.reconcileCalibrationStatus(calibration)

        if (calibration.id) {
            this.store.dispatch(new UpdateCalibrationDetailsAction(workOrderNumber, equipmentId, calibration))
        } else {
            this.store.dispatch(new CreateCalibrationDetailsAction(calibration))
        }
    }

    public reconcileCalibrationStatus(calibration: CalibrationDetails): CalibrationDetails {
        const resultStatusName = calibration?.finalPMResultStatus?.name
        if (this.calibrationResultStatusService.isCalibrationDeferredOrNotTested(resultStatusName)) {
            return calibration
        }

        calibration.calibrationStatus = this.getCalibrationStatus(calibration.calibrationStatus)
        if (calibration?.calibrationResult?.results &&
            calibration?.calibrationResult?.results?.length > 0 &&
            calibration?.templateTypeId !== TemplateTypeEnum[TemplateTypeEnum.safetyValve]
        ) {
            const resultSet = calibration.calibrationResult.results[0].resultSet
            resultSet.every(r => {
                if (isNotAValue(r.asFound) || isNotAValue(r.asLeft)) {
                    calibration.calibrationResultStatus = null
                    // If we found one fail, there is no need to loop further
                    return false
                }
                return true
            })
        }

        return calibration
    }

    private getCalibrationStatus(calibrationStatus: CalibrationStatus): CalibrationStatus {
        if (calibrationStatus.id === CalibrationStatusEnum.NotStarted) {
            return { id: CalibrationStatusEnum.Draft, name: mrmaStatus.calibrationStatus[1].name } as CalibrationStatus
        }
        return calibrationStatus
    }
}
