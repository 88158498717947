<div class="notification-container"
     [style]="addStyle">
    <label *ngIf="showLabel">{{ notificationLabel }}</label>
    <div class="notification-info"
         [ngClass]="isOnline ? 'viewable-notification' : 'disabled-view-notification'">
        <p *ngIf="noLink || !notificationNumber else Link"
           class="no-link-text">
            <span>{{notificationNumber || '-'}}</span>
        </p>
        <ng-template #Link>
            <p class="inline"
               (click)="openNotificationPopup()">
                <app-unity-icon size="small"
                                unitySvgName="edit"
                                class="em-c-icon--small">
                </app-unity-icon>
                <span>{{notificationNumber || '-'}}</span>
            </p>
        </ng-template>
        <div *ngIf="notificationNumber" class="relative-container">
            <span *ngIf="isNotificationLoading"
                  class="label label-info">
                <span>Loading</span>
                <span class="dot-collision"></span>
            </span>
            <ng-container *ngIf="!isNotificationLoading && notificationFromStore">
                <ng-container *ngIf="(needTooltip && canShowTooltip) else statusWithoutTooltip">
                    <span class="label label-warning"
                          appArrowTooltip="Please do not reopen the calibration in the meantime"
                          >
                        <span>Open</span>
                    </span>
                </ng-container>
                <ng-template #statusWithoutTooltip>
                    <span class="label"
                          [ngClass]="isNotificationOpen ? 'label-warning' : 'label-default'">
                        <span>{{isNotificationOpen ? 'Open' : 'Closed'}}</span>
                    </span>
                </ng-template>
            </ng-container>
            <span *ngIf="!isNotificationLoading && !notificationFromStore"
                  class="label label-error em-u-clickable"
                  (click)="reloadNotification($event)">
                <app-unity-icon unitySvgName="refresh">
                </app-unity-icon>
                <span class="left-margin">Click to reload</span>
            </span>
        </div>
    </div>
</div>
