import { Component, Input, TemplateRef } from '@angular/core'

@Component({
    selector: 'app-arrow-tooltip',
    templateUrl: './arrow-tooltip.component.html',
    styleUrls: ['./arrow-tooltip.component.scss']
})
export class ArrowTooltipComponent {
    @Input() content: string | TemplateRef<any>

    top = 0
    alignRight = false
}
