import { AfterViewInit, Component, Input, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'

import { CustomFormInput } from '@app/modules/calibration/components/custom-form/models/custom-form.model'
import { isNotAValue } from '@app/utils/app-utils.function'
import { NumericInputComponent } from '../../numeric-input/numeric-input.component'

@Component({
    selector: 'app-custom-form-input',
    templateUrl: './custom-form-input.component.html',
    styleUrls: ['./custom-form-input.component.scss']
})
export class CustomFormInputComponent implements AfterViewInit {
    @Input() formDetail: CustomFormInput
    @Input() formControl = new FormControl(null)

    @ViewChild(NumericInputComponent) numInputComponent?: NumericInputComponent

    ngAfterViewInit(): void {
        // Config are done here so we don't override default values with `null` or `undefined` if one is not given.
        if (this.formDetail.inputType === 'number') {
            if (!this.numInputComponent || !this.formDetail.configs) {
                return
            }
            const { min, max, allowNegative, decimalPlaces, suffix } = this.formDetail.configs
            if (!isNotAValue(min)) { this.numInputComponent.min = min }
            if (!isNotAValue(max)) { this.numInputComponent.max = max }
            if (!isNotAValue(allowNegative)) { this.numInputComponent.allowNegative = allowNegative }
            if (!isNotAValue(decimalPlaces)) { this.numInputComponent.decimalPlaces = decimalPlaces as any }
            if (!isNotAValue(suffix)) { this.numInputComponent.suffix = suffix }
        }
    }

}
