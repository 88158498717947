import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'

import { SharedModule } from '../shared/shared.module'
import { FlamingoEditorPageComponent } from './components/flamingo-editor-page/flamingo-editor-page.component'
import { FlamingoListPageComponent } from './components/flamingo-list-page/flamingo-list-page.component'
import { FlamingoListTableComponent } from './components/flamingo-list-table/flamingo-list-table.component'

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'new-form',
                component: FlamingoEditorPageComponent
            },
            {
                path: ':formId/edit',
                component: FlamingoEditorPageComponent
            },
            {
                path: '',
                component: FlamingoListPageComponent
            }
        ]),
        CommonModule,
        SharedModule
    ],
    declarations: [FlamingoListPageComponent, FlamingoListTableComponent, FlamingoEditorPageComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [FlamingoListTableComponent],
})
export class FlamingoModule { }
