<section class="web-view">
    <div class="em-c-field__body">
        <app-paged-list *ngIf="links"
                        [items]="links"
                        [clickable]="false"
                        [itemsPerPage]="10">
            <ng-template #header>
                <th scope="col"
                    class="em-c-table__header-cell em--no-wrap em-u-width-10">
                    hyperlink
                </th>
            </ng-template>
            <ng-template #content
                         let-item>
                <td class="em-c-table__cell em-u-width-100 em-u-text-align-left hyperlink-row"
                    [ngClass]="{'blue-button': !!item.externalLink}" (click)="onClickProcedure(item)">
                    <app-unity-icon unitySvgName="link-external" *ngIf="item.externalLink" size="small"
                                    class="em-u-space-right-half"></app-unity-icon>
                    <span class="em-u-max-width-40">{{item?.attachmentDescription}}</span>
                </td>
            </ng-template>
        </app-paged-list>
    </div>
</section>
