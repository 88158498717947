import { Directive, HostBinding } from '@angular/core'

/**
 * Use this to tell Res Table that this particular element should start a new row.
 */
@Directive({
    selector: '[appResTableNewRow]'
})
export class ResTableNewRowDirective {

    @HostBinding('style.grid-column-start')
    gridColumnStart = 1

}
