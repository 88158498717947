import { filter, find, isArray, isDate, isEmpty, isEqual, isNil, isObject, range, size, some } from 'lodash'

interface SpecialComparator {
    propName: string
    comparator: (object: any, anotherObject: any) => boolean
}

export const isArrayEqual = (array, anotherArray, specialComparators?: SpecialComparator[]): boolean => {
    const valuableArray = filter(array, v => !isNil(v))
    const anotherValuableArray = filter(anotherArray, v => !isNil(v))

    if (isEmpty(valuableArray) && isEmpty(anotherValuableArray)) {
        return true
    }

    if (!isArray(valuableArray) || !isArray(anotherValuableArray)) {
        return false
    }

    if (size(valuableArray) !== size(anotherValuableArray)) {
        return false
    }

    for (const index in range(size(valuableArray))) {
        // eslint-disable-next-line no-use-before-define, @typescript-eslint/no-use-before-define
        if (!isObjectDeepEqual(valuableArray[index], anotherValuableArray[index], specialComparators)) {
            return false
        }
    }

    return true
}

export const isObjectDeepEqual = (object: any, anotherObject: any, specialComparators?: SpecialComparator[]): boolean => {
    if (isEqual(object, anotherObject)) {
        return true
    }

    if (isNil(object) && isNil(anotherObject)) {
        return true
    }

    if (isArray(object) || isArray(anotherObject)) {
        return isArrayEqual(object, anotherObject, specialComparators)
    }

    if (isDate(object) || isDate(anotherObject)) {
        return new Date(object.toString()).toLocaleDateString() === new Date(anotherObject.toString()).toLocaleDateString()
    }

    if (isObject(object) && !isNil(object) && isObject(anotherObject) && !isNil(anotherObject)) {
        const longerPropsObject = Object.keys(object).length >= Object.keys(anotherObject).length ? object : anotherObject
        for (const propKey in longerPropsObject) {
            if (some(specialComparators, config => config.propName === propKey)) {
                if (!find(specialComparators, config => config.propName === propKey).comparator(object[propKey], anotherObject[propKey])) {
                    return false
                }
            } else if (!isObjectDeepEqual(object[propKey], anotherObject[propKey], specialComparators)) {
                return false
            }
        }
        return true
    }

    return false
}
