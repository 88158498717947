import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { AppPolicyType } from '@app/models/app-policy/policy-type.enum'
import { AccessibleByPolicy } from '@app/utils/decorators/access-policy.decorator'
import { FlamingoForm } from '../../models/flamingo-form.model'

@Component({
    selector: 'app-flamingo-list-page',
    templateUrl: './flamingo-list-page.component.html',
    styleUrls: ['./flamingo-list-page.component.scss']
})
export class FlamingoListPageComponent {
    public engineerAccessPolicy = AppPolicyType.EngineerAccess
    public isClickable = false

    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.setIsClickable()
    }

    @AccessibleByPolicy(AppPolicyType.EngineerAccess)
    public flamingoFormClick(form: FlamingoForm): void {
        this.router.navigate([form.formId, 'edit'], { relativeTo: this.route })
    }

    @AccessibleByPolicy(AppPolicyType.EngineerAccess)
    private setIsClickable(): void {
        this.isClickable = true
    }
}
