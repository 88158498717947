import { DOCUMENT } from '@angular/common'
import { Component, ElementRef, Inject, Input, OnDestroy } from '@angular/core'

@Component({
    selector: 'app-dropdown-sorting',
    templateUrl: './dropdown-sorting.component.html',
    styleUrls: ['./dropdown-sorting.component.scss']
})
export class DropdownSortingComponent implements OnDestroy {
    @Input() title: string
    @Input() options: any[]
    @Input() sortingState: () => any
    @Input() decendingState: () => any
    @Input() callback: () => any

    public isOpen = false
    private clickHandler: any

    constructor(
        private eRef: ElementRef,
        @Inject(DOCUMENT) private document: any
    ) { }

    ngOnDestroy(): void {
        this.document.removeEventListener('click', this.clickHandler)
    }

    public toggleDropdown(): void {
        this.isOpen = !this.isOpen
        if (!this.isOpen) {
            this.document.removeEventListener('click', this.clickHandler)
            return
        }

        this.clickHandler = event => {
            if (!this.eRef.nativeElement.contains(event.target)) {
                this.toggleDropdown()
            }
        }
        this.document.addEventListener('click', this.clickHandler)
    }
}
