<div class="em-c-field ">
    <label *ngIf="formDetail?.title"
           class="em-c-field__label">{{ formDetail.title }}</label>
    <div class="em-c-field__body custom-form-input-wrapper">
        <app-numeric-input #numericInput
                           *ngIf="formDetail?.inputType === 'number'"
                           [inputId]="formDetail?.id"
                           class="em-c-input em-js-input"
                           [placeholder]="formDetail?.placeHolder || ''"
                           [formControl]="formControl"></app-numeric-input>
        <input *ngIf="formDetail?.inputType !== 'number'"
               [type]="formDetail?.inputType || 'text'"
               [id]="formDetail?.id"
               class="em-c-input em-js-input"
               [placeholder]="formDetail?.placeHolder || ''"
               [value]="formControl.value"
               [formControl]="formControl" />
    </div>
</div>
