<div class="loading-bar"
     [ngClass]="{loading: isLoading$ | async}"></div>
<table class="em-c-table em-u-margin-bottom-none table"
       #pagedListReference>
    <thead class="em-c-table__header">
        <tr class="em-c-table__header-row">
            <ng-container *ngTemplateOutlet="header"></ng-container>
        </tr>
    </thead>
    <tbody class="em-c-table__body">
        <ng-container *ngIf="currentPage$ | async as currentPage">
            <tr class="em-c-table__row e -u-margin-bottom-none align-middle"
                [ngClass]="{'non-clickable': !clickable, 'em-u-clickable': clickable}"
                *ngFor="let item of (currentPage | slice:0:pageSize)"
                (click)="itemClicked(item)">
                <ng-container *ngTemplateOutlet="content;context: { $implicit: item }"></ng-container>
            </tr>
        </ng-container>
        <tr *ngIf="numberOfItems <= 0">
            <td class="em-c-table__cell em-u-text-align-center"
                colspan="10">
                No record found {{noRecordDescription}}
            </td>
        </tr>
    </tbody>
    <tfoot class="em-c-table__footer">
        <tr class="em-c-table__footer-row"></tr>
    </tfoot>
</table>

<div class="tiles"
     *ngIf="currentPage$ | async as currentPage">
    <ul class="em-c-card-list">
        <li class="em-c-card-list__item em-l-grid__item"
            [ngClass]="{'non-clickable': !clickable, 'em-u-clickable': clickable}"
            *ngFor="let item of (currentPage | slice:currentOffset:currentOffset+pageSize)"
            (click)="itemClicked(item)">
            <ng-container *ngTemplateOutlet="content;context: { $implicit: item }"></ng-container>
        </li>
    </ul>
</div>

<div class="em-c-toolbar"
     *ngIf="numberOfItems > pageSize">
    <div class="em-c-toolbar__item fixed-content"></div>
    <div class="em-c-toolbar__item">
        {{showingItemFrom}}-{{showingItemTo}} of {{numberOfItems}}
    </div>
    <div class="em-c-toolbar__item">
        <ol class="em-c-pagination"
            role="navigation"
            aria-labelledby="pagination-label">
            <li class="em-c-pagination__item"
                [ngClass]="{'em-u-clickable': clickable}">
                <a class="em-c-pagination__link"
                   [ngClass]="{ 'em-is-disabled': isFirstPage }"
                   (click)="previousPage()">
                    <app-unity-icon unitySvgName="icon-caret-left" size="small"></app-unity-icon>
                </a>
            </li>
            <li class="em-c-pagination__maintitem"
                [ngClass]="{'em-u-clickable': clickable }">
                <a class="em-c-pagination__link"
                   [ngClass]="{'em-is-disabled': isLastPage }"
                   (click)="nextPage()">
                    <app-unity-icon unitySvgName="icon-caret-right" size="small"></app-unity-icon>
                </a>
            </li>
        </ol>
    </div>
</div>
