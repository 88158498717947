<app-section [title]="'Ranges and Tolerances'"
             [titleMobileOnly]="true"
             [showDetailsLine]="false">
    <section body>
        <div class="web-view em-l-grid em-l-grid--4up">
            <div class="em-l-grid__item">
                <app-label [title]="'Input'"
                           [subtitle]="getUomCodeForTech('input')">
                    <ng-container *ngIf="isSinglePoint else multiplePoint">
                        {{ getRange('input').maximumRange }}
                    </ng-container>
                    <ng-template #multiplePoint>
                        {{ getRange('input').minimumRange }} to {{ getRange('input').maximumRange }}
                    </ng-template>
                </app-label>
            </div>
            <div class="em-l-grid__item"
                 *ngIf="!isWeightTemplate">
                <app-label [title]="'Expected Output'"
                           [subtitle]="getUomCodeForTech('expected')">
                    <ng-container *ngIf="isSinglePoint else expectedRangeMultiplePoint">
                        {{ getRange('expected').maximumRange }}
                    </ng-container>
                    <ng-template #expectedRangeMultiplePoint>
                        {{ getRange('expected').minimumRange }} to {{ getRange('expected').maximumRange }}
                    </ng-template>
                </app-label>
            </div>
            <div class="em-l-grid__item">
                <app-label [title]="'Tolerance'"
                           [subtitle]="getUomCodeForTech('tolerance')">
                    {{ template.tolerance.value }}
                </app-label>
            </div>
        </div>
        <div class="responsive-view">
            <div class="responsive-calibration-container">
                <div class="calibration-info">
                    <app-label [title]="'Input'"
                               [subtitle]="getUomCodeForTech('input')">
                    </app-label>
                    <p>
                        <ng-container *ngIf="isSinglePoint else multiplePoint">
                            {{ getRange('input').maximumRange }}
                        </ng-container>
                        <ng-template #multiplePoint>
                            {{ getRange('input').minimumRange }} to {{ getRange('input').maximumRange }}
                        </ng-template>
                    </p>
                </div>
                <div class="calibration-info"
                     *ngIf="!isWeightTemplate">
                    <app-label [title]="'Expected Output'"
                               [subtitle]="getUomCodeForTech('expected')">
                    </app-label>
                    <p>
                        <ng-container *ngIf="isSinglePoint else multiplePoint">
                            {{ getRange('expected').maximumRange }}
                        </ng-container>
                        <ng-template #multiplePoint>
                            {{ getRange('expected').minimumRange }} to {{ getRange('expected').maximumRange }}
                        </ng-template>
                    </p>
                </div>
                <div class="calibration-info">
                    <app-label [title]="'Tolerance'"
                               [subtitle]="getUomCodeForTech('tolerance')">
                    </app-label>
                    <p>{{ template.tolerance.value }}</p>
                </div>
            </div>
        </div>
    </section>
</app-section>