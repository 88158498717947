import { Injectable } from '@angular/core'

import { EquipmentQueryParameter } from '@app/modules/equipment/models/equipment-query-parameter.model'
import { ReportListQueryParameter } from '@app/modules/report/models/report-list-query.model'
import { deepCopy, isNotAValue, sortArray } from '@app/utils/app-utils.function'
import { SortedState, SortingState } from '../models/filter.model'

@Injectable({
    providedIn: 'root'
})
export class FilteringService {
    public filter<T>(data: T[], field: string, searchText: string): T[] {
        return searchText ? data.filter(item => item[field].toLowerCase().includes(searchText.toLowerCase())) : data
    }

    public isSorted(sortStates: SortedState[], field: string): boolean {
        const currentField = sortStates.find(sortState => sortState.sortId === field)
        if (currentField.sortingState === SortingState.None || !currentField.isSelected) {
            return false
        }

        return true
    }

    public isDescending(sortStates: SortedState[], field: string): boolean {
        const currentField = sortStates.find(sortState => sortState.sortId === field)
        if (currentField.sortingState !== SortingState.Descending || !currentField.isSelected) {
            return false
        }

        return true
    }

    public configSorting(sortingState: SortedState[], column: string): SortedState[] {
        const state = sortingState.find(c => c.sortId === column)
        const sortOrder = Math.max(...sortingState.map(x => x.sortOrder))
        const enumLength = Object.keys(SortingState).length / 2

        // Go through and update sorting state each time
        if (state.sortingState < enumLength) {
            state.sortingState += 1
            state.sortOrder = sortOrder + 1
        } else {
            state.sortingState = SortingState.None
            state.isSelected = false
            return sortArray(sortingState, 'sortOrder', false)
        }

        // Set sorting state to ascending when first execute
        if (!state.isSelected) {
            state.sortingState = SortingState.Ascending
        }

        // Allow only one field to be selected
        sortingState.forEach(x => {
            x.isSelected = false

            if (x.sortId !== state.sortId) {
                x.sortingState = SortingState.None
                x.sortOrder = 0
            }
        })

        state.isSelected = true

        return sortArray(sortingState, 'sortOrder', false)
    }

    public getQueryParamFromFilters(
        queryParams: ReportListQueryParameter | EquipmentQueryParameter,
        equipmentFilterList: any
    ): ReportListQueryParameter | EquipmentQueryParameter {
        const query = deepCopy(queryParams)

        if (equipmentFilterList) {
            query.plannerGroup = this.getSelected(equipmentFilterList.plannerGroup.value, 'name')
            query.mainWorkCenter = this.getSelected(equipmentFilterList.mainWorkCenter.value, 'name')
            query.maintenancePlant = this.getSelected(equipmentFilterList.maintenancePlant.value, 'name')
            query.plantSection = this.getSelected(equipmentFilterList.plantSection.value, 'name')
            query.location = this.getSelected(equipmentFilterList.location.value, 'name')
            query.criticality = this.getSelected(equipmentFilterList.criticality.value, 'name')
            query.category = this.getSelected(equipmentFilterList.category.value, 'name')
            query.classification = this.getSelected(equipmentFilterList.classification.value, 'name')
        }

        this.removeEmptyField(query)

        return query
    }

    public removeEmptyField(query: any): any {
        for (const name in query) {
            if (isNotAValue(query[name])) {
                delete query[name]
            }
        }
    }

    public getSelected(values: any[], field: string): string {
        if (values?.length === 0) {
            return null
        }

        return values
            .filter(v => v?.selected)
            .map(v => v[field])
            .join(',')
    }
}
