import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Observable } from 'rxjs'

import { mrmaAlertConfigs } from '@app/models/alert-configuration.model'
import { SectionLoaderHelperComponent } from '@app/modules/shared/components/section-loader/section-loader-helper-component'
import { SectionLoaderEnum } from '@app/modules/shared/models/section-loader.enum'
import { FlamingoAuthenticationService } from '@app/modules/shared/services/flamingo-auth.service'
import { SectionLoaderService } from '@app/modules/shared/services/section-loader.service'
import { AppState } from '@app/store/app.store'
import { CreateOrUpdateFlamingoFormAction } from '@app/store/flamingo/flamingo.actions'
import { environment } from '@environments/environment'
import { FlamingoFormCreateUpdateDTO } from '../../models/flamingo-form.model'

@Component({
    selector: 'app-flamingo-editor-page',
    templateUrl: './flamingo-editor-page.component.html',
    styleUrls: ['./flamingo-editor-page.component.scss']
})
export class FlamingoEditorPageComponent extends SectionLoaderHelperComponent implements OnInit {

    public flamingoEnv = environment['flamingoForm']?.['environment']

    // If not exist, should use create mode
    public formId?: string

    public flamingoToken$: Observable<string>

    constructor(
        private route: ActivatedRoute,
        private flamingoAuthService: FlamingoAuthenticationService,
        private toastrService: ToastrService,
        private store: Store<AppState>,
        private router: Router,
        sectionLoaderService: SectionLoaderService
    ) {
        super(sectionLoaderService)
    }

    @ViewChild('flamingoFormEditorRef', { static: true }) set sectionLoader(ref: ElementRef) {
        if (!this.sectionLoaderRef) {
            this.registerSectionLoader(SectionLoaderEnum.EquipmentHistory, ref)
        }
    }

    ngOnInit(): void {
        this.flamingoToken$ = this.flamingoAuthService.flamingoToken$
        this.route.params.subscribe(params => {
            this.formId = params['formId']
        })
    }

    public onFormSubmit(event: any): void {
    }

    public onToastMessage(event, type: 'success' | 'error' | 'warning' | 'info'): void {
        const message = '[Flamingo] - ' + event.detail
        switch (type) {
            case 'success':
                this.toastrService.success('', message, { ...mrmaAlertConfigs.WorkflowSuccess.configuration })
                break
            case 'error':
                this.toastrService.error('', message, { ...mrmaAlertConfigs.NetworkError.configuration })
                break
            case 'warning':
                this.toastrService.warning('', message, { ...mrmaAlertConfigs.WorkflowWarning.configuration })
                break
            case 'info':
                this.toastrService.info('', message, { ...mrmaAlertConfigs.WorkflowInfo.configuration })
                break
            default:
                break
        }
    }

    onFormCreateOrUpdateSuccess(event: any, isUpdate = false): void {
        this.toastrService.info(
            '',
            `Registering ${isUpdate ? 'changes' : 'form'} with MRMA... please do not leave this page.`,
            { ...mrmaAlertConfigs.WorkflowInfo.configuration }
        )
        console.log({ event })
        const form = event.detail
        const mrmaFlamingoForm: FlamingoFormCreateUpdateDTO = {
            formId: form.id,
            name: form.title,
            modifiedDate: form.modifiedDate,
            modifiedByEmail: form.editorEmail,
            isActive: true
        }
        this.store.dispatch(CreateOrUpdateFlamingoFormAction(mrmaFlamingoForm))
        if (!isUpdate) {
            this.router.navigate(['..', form.id, 'edit'], { relativeTo: this.route })
        }
    }

}
