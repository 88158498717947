import { AfterViewInit, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core'

@Directive({
    selector: '[appHoverTooltip]'
})
export class HoverTooltipDirective implements AfterViewInit {

    domElement

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) {
        this.domElement = this.elementRef.nativeElement
    }

    ngAfterViewInit(): void {
        this.renderer.setProperty(this.domElement, 'scrollTop', 1)
        this.setToolTip()
    }

    @HostListener('window:resize', ['$event.target'])
    public setToolTip(): void {
        this.renderer.setAttribute(this.domElement, 'title',
            this.domElement.textContent)
    }
}
