<form [formGroup]="calibrationForm"
      appPreventFormSubmitOnEnter>
    <div *ngIf="calibrationForm">
        <div class="em-u-padding-top">
            <hr class="web-view details-line">
            <app-calibration-overview body
                                      [calibration]="calibration"
                                      [calibrationForm]="calibrationForm"
                                      [workOrderDetails]="workOrderDetails"
                                      [equipmentDetails]="equipmentDetails"
                                      [equipment]="equipment"></app-calibration-overview>
        </div>
        <app-calibration-results [calibration]="calibration"
                                 [calibrationForm]="calibrationForm"
                                 [equipment]="equipment"
                                 [equipmentDetails]="equipmentDetails"
                                 [isATechnician]="isATechnician"
                                 (fileUpload)="saveCalibration($event)"></app-calibration-results>
    </div>
    <div class="footer"
         *ngIf="isATechnician">
        <div class="em-l-container">
            <app-message-footer-bar *ngIf="calibration.dataSource==='GRT'"
                                    [messageBarClass]="'info'"
                                    [description]="calibrationGrtDataSourceMessage"></app-message-footer-bar>
            <app-message-footer-bar *ngIf="isOnlySaveAllowed"
                                    [messageBarClass]="'error'"
                                    [description]="remindMessage.error.offlineCompletionIsDisabled"></app-message-footer-bar>
            <button *ngIf="!calibrationCompleted"
                    class="footer-element em-c-btn em-c-btn--primary"
                    [ngClass]="{'em-c-btn--disabled': !canCompleteCalibration}"
                    (click)="validateCompleteCalibration(calibration)">
                Complete
            </button>
            <h6 *ngIf="calibrationCompleted && !(calibrationFormService.isPMDeferredOrNotTested$ | async)">PM Completed</h6>
            <h6 *ngIf="calibrationFormService.isPMDeferred$ | async">PM Deferred</h6>
            <h6 *ngIf="calibrationFormService.isPMNotTested$ | async">PM Not Tested</h6>
            <button *ngIf="calibrationFormService.isPMDeferredOrNotTested$ | async"
                    class="footer-element em-c-btn em-c-btn--primary"
                    (click)="confirmResumeCalibration(calibration)"
                    [ngClass]="{'em-c-btn--disabled': !resumeCalibrationEnabled}"
                    [disabled]="!resumeCalibrationEnabled">
                Resume
            </button>
            <button class="footer-element em-c-btn save-button"
                    (click)="saveCalibration(calibration)"
                    [ngClass]="{'em-c-btn--disabled': !saveButtonEnable}"
                    [disabled]="!saveButtonEnable">
                {{ calibrationCreated ? 'Save' : 'Save as Draft' }}
            </button>
            <div class="em-c-primary-nav__item web-view footer-element custom-right-item">
                <a class="em-c-primary-nav__link em-c-primary-nav__link--has-children em-js-nav-dropdown-trigger em-c-btn footer-right-button"
                   [appToggleActive]="!(calibrationFormService.isPMDeferredOrNotTested$ | async)"
                   [ngClass]="{'em-c-btn--disabled': !moreActionIsEnabled}">
                    More Actions
                </a>
                <ul class="em-c-primary-nav__sublist em-js-nav-dropdown footer-right-button">
                    <li class="em-c-primary-nav__subitem em-c-btn"
                        *ngIf="!calibrationCompleted"
                        [ngClass]="{'em-c-btn--disabled': !enableChangeTemplate }"
                        (click)="changeTemplate()">
                        Select Template
                    </li>
                    <li class="em-c-primary-nav__subitem em-c-btn"
                        *ngIf="!calibrationCompleted"
                        [ngClass]="{'em-c-btn--disabled': !isDeferredNotTestedOptionEnabled}"
                        (click)="deferredNotTested()">
                        Defer/Not Test PM
                    </li>
                    <li class="em-c-primary-nav__subitem em-c-btn"
                        [ngClass]="{'em-c-btn--disabled': !reopenCalibrationEnabled}"
                        *ngIf="canReopenCalibration"
                        (click)="confirmReopenCalibration(calibration)">
                        Re-open PM
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- Tab for responsive stuffs -->
    <div #tabbar
         class="footer-responsive">
        <div class="more-button-container">
            <app-message-footer-bar *ngIf="calibration.dataSource==='GRT'"
                                    [messageBarClass]="'info'"
                                    [description]="calibrationGrtDataSourceMessage"></app-message-footer-bar>
            <app-message-footer-bar *ngIf="isOnlySaveAllowed"
                                    [messageBarClass]="'error'"
                                    [description]="remindMessage.error.offlineCompletionIsDisabled"></app-message-footer-bar>
            <div class="footer-responsive-btn"
                 (click)="showMoreOptions()">
                <svg class="em-c-icon em-c-icon--small"
                     [ngClass]="(calibrationFormService.isPMDeferredOrNotTested$ | async) ? 'em-c-icon--gray' : 'em-c-icon--blue'">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink"
                         xlink:href="./assets/unity-1.2.0/images/em-icons.svg#settings"></use>
                </svg>
                <span [ngClass]="{'footer-responsive-btn--disabled': (calibrationFormService.isPMDeferredOrNotTested$ | async)}">
                    More
                </span>
            </div>
        </div>

        <div class="footer-responsive-btn"
             (click)="showInfoOptions()">
            <div class="icon-container">
                <svg class="em-c-icon em-c-icon--small">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink"
                         xlink:href="./assets/unity-1.2.0/images/em-icons.svg#info"></use>
                </svg>
                <svg class="em-c-icon em-c-icon--tiny em-c-icon--red icon-badge"
                     *ngIf="equipment?.equipmentNote">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink"
                         xlink:href="./assets/unity-1.2.0/images/em-icons.svg#circle-exclamation-mark-filled"></use>
                </svg>
            </div>
            <span>Info</span>
        </div>

        <div class="footer-responsive-btn"
             (click)="showSubmitOptions()">
            <svg class="em-c-icon em-c-icon--small em-c-icon--blue">
                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="./assets/unity-1.2.0/images/em-icons.svg#send"></use>
            </svg>
            <span>Submit</span>
        </div>
    </div>
</form>

<app-selection-drawer [drawerName]="moreDrawerName"
                      [selectionOptions]="moreDrawerOptions"></app-selection-drawer>
<app-selection-drawer [drawerName]="moreInfoName"
                      [selectionOptions]="moreDrawerInfoOptions"></app-selection-drawer>
<app-selection-drawer [drawerName]="submitDrawerName"
                      [selectionOptions]="submitDrawerOptions"></app-selection-drawer>
