import { Pipe, PipeTransform } from '@angular/core'

/*
 * Append ordinal to number (e.g. "1st" position)
 * Usage:
 *   value | ordinal:keepNumber
 * Example:
 *   {{ 23 |  ordinal}}
 *   formats to: '23rd'
*/
@Pipe({ name: 'ordinal' })
export class OrdinalPipe implements PipeTransform {

    private readonly ordinals: string[] = ['th', 'st', 'nd', 'rd']

    transform(n: number): string {
        const v = n % 100
        return n + '' + (this.ordinals[(v - 20) % 10] || this.ordinals[v] || this.ordinals[0])
    }
}
