import { Action } from '@ngrx/store'

import { CalibrationReportDetails } from '@app/modules/report/models/calibration-report-details.model'
import { ReportListQueryParameter } from '@app/modules/report/models/report-list-query.model'
import { ReportWorkOrderDetail } from '@app/modules/report/models/report-work-order-detail.model'
import { WorkManagementReportQueryParameter } from '@app/modules/report/models/work-management-report-filter.model'
import { WorkOrderEquipmentPair } from '@app/modules/report/models/workorder-equipment.modal'
import { QuerySummary } from '@app/modules/shared/models/query-summary.model'
import { ReportFilterFields, CalibrationPresets } from '@app/modules/report/constants/report-filter-list.constant'
import { DateRange } from '@app/modules/report/models/date-range.model'

export enum ActionType {
    GetReportList = '[Report] Get Report List',
    GetReportListSuccess = '[Report] Get Report List Success',
    GetReportListFailure = '[Report] Get Report List Failure',
    GetReportDetail = '[Report] Get Report Detail',
    GetReportDetailSuccess = '[Report] Get Report Detail Success',
    ApproveCalibration = '[Report] Approve Calibration',
    RejectCalibration = '[Report] Reject Calibration',
    ApplySort = '[Report] Apply Sort',
    ApplyFilter = '[Report] Apply Filter',
    ApplySearch = '[Report] Apply Search',
    SelectWorkOrder = '[Report] Select work order',
    RequestWorkManagementReport = '[Report] Request Work Management Report',
    GetFilterPresetList = '[Report] Get FilterPresetList',
    GetFilterPresetListSuccess = '[Report] Get FilterPresetList Success',
    AddFilterPreset = '[Report] Add A FilterPreset',
    AddFilterPresetSuccess = '[Report] Add A FilterPreset Suceess',
    EditFilterPreset = '[Report] Edit A FilterPreset',
    EditFilterPresetSuccess = '[Report] Edit A FilterPreset Success',
    DeleteFilterPreset = '[Report] Delete A FilterPreset',
    DeleteFilterPresetSuccess = '[Report] Delete A FilterPreset Success',
    SaveReportFilterList = '[Report] Save ReportFilterList',
    SelectedFilterPresetOption = '[Report] Selected an FilterPreset option',
    DeselectAllFilters = '[Report] Deselect All Filters',
    ModifyPmPerformedDate = '[Report] Modify PmPerformedDate',
    SaveTemplateTypeFilterOptions = '[Report] Save Template Type Filter Options',
    SavePmPerformedByFilterOptions = '[Report] Save PmPerformedBy Filter Options',
}

export class GetReportListAction implements Action {
    public readonly type = ActionType.GetReportList
    constructor(public payload: QuerySummary<ReportListQueryParameter>, public replace = false) { }
}

export class GetReportListSuccessAction implements Action {
    public readonly type = ActionType.GetReportListSuccess
    constructor(
        public payload: {
            querySummary?: QuerySummary<ReportListQueryParameter>,
            result: CalibrationReportDetails[],
            replace: boolean
        }
    ) { }
}

export class GetReportListFailureAction implements Action {
    public readonly type = ActionType.GetReportListFailure
}

export class GetReportDetailAction implements Action {
    public readonly type = ActionType.GetReportDetail
    constructor(public payload: string) { }
}

export class SelectWorkOrderAction implements Action {
    public readonly type = ActionType.SelectWorkOrder
    constructor(public payload: string) { }
}

export class GetReportDetailSuccessAction implements Action {
    public readonly type = ActionType.GetReportDetailSuccess
    constructor(public payload: ReportWorkOrderDetail) { }
}

export class RejectCalibrationAction implements Action {
    public readonly type = ActionType.RejectCalibration
    constructor(public payload: WorkOrderEquipmentPair) { }
}

export class ApproveCalibrationAction implements Action {
    public readonly type = ActionType.ApproveCalibration
    constructor(public payload: WorkOrderEquipmentPair) { }
}

export class ApplySearchAction implements Action {
    public readonly type = ActionType.ApplySearch
    constructor(public payload: string) { }
}

export class RequestWorkManagementReportAction implements Action {
    public readonly type = ActionType.RequestWorkManagementReport

    constructor(public query: WorkManagementReportQueryParameter) { }
}
export class GetFilterPresetListAction implements Action {
    public readonly type = ActionType.GetFilterPresetList
}
export class GetFilterPresetListSuccessAction implements Action {
    public readonly type = ActionType.GetFilterPresetListSuccess
    constructor(public payload: any[]) { }
}
export class AddFilterPresetAction implements Action {
    public readonly type = ActionType.AddFilterPreset
    constructor(public payload: { name: string }) { }
}
export class AddFilterPresetSuccessAction implements Action {
    public readonly type = ActionType.AddFilterPresetSuccess
    constructor(public payload: CalibrationPresets) { }
}
export class EditFilterPresetAction implements Action {
    public readonly type = ActionType.EditFilterPreset
    constructor(public payload: { name?: string, id: number }) { }
}

export class EditFilterPresetSuccessAction implements Action {
    public readonly type = ActionType.EditFilterPresetSuccess
    constructor(public payload: CalibrationPresets) { }
}
export class DeleteFilterPresetAction implements Action {
    public readonly type = ActionType.DeleteFilterPreset
    constructor(public payload: { id: number }) { }
}
export class DeleteFilterPresetSuccessAction implements Action {
    public readonly type = ActionType.DeleteFilterPresetSuccess
    constructor(public payload: { id: number }) { }
}
export class SaveReportFilterListAction implements Action {
    public readonly type = ActionType.SaveReportFilterList
    constructor(public payload: ReportFilterFields) { }
}
export class SelectedFilterPresetOptionAction implements Action {
    public readonly type = ActionType.SelectedFilterPresetOption
    constructor(public payload: number) { }
}
export class DeselectAllFiltersAction implements Action {
    public readonly type = ActionType.DeselectAllFilters
}
export class ModifyPmPerformedDateAction implements Action {
    public readonly type = ActionType.ModifyPmPerformedDate
    constructor(public payload: DateRange) { }
}
export class SaveTemplateTypeFilterOptionsAction implements Action {
    public readonly type = ActionType.SaveTemplateTypeFilterOptions
    constructor(public payload: any[]) { }
}
export class SavePmPerformedByFilterOptionsAction implements Action {
    public readonly type = ActionType.SavePmPerformedByFilterOptions
    constructor(public payload: any[]) { }
}

export type ReportAction = GetReportListAction
    | GetReportListSuccessAction
    | GetReportListFailureAction
    | ApplySearchAction
    | GetReportDetailAction
    | SelectWorkOrderAction
    | GetReportDetailSuccessAction
    | RejectCalibrationAction
    | ApproveCalibrationAction
    | RequestWorkManagementReportAction
    | GetFilterPresetListAction
    | GetFilterPresetListSuccessAction
    | AddFilterPresetAction
    | EditFilterPresetAction
    | SaveReportFilterListAction
    | SelectedFilterPresetOptionAction
    | DeselectAllFiltersAction
    | AddFilterPresetSuccessAction
    | EditFilterPresetSuccessAction
    | DeleteFilterPresetSuccessAction
    | ModifyPmPerformedDateAction
    | SaveTemplateTypeFilterOptionsAction
    | SavePmPerformedByFilterOptionsAction
