import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import {
    CustomFormDropdownComponent
} from '../mrma-ui-components/controls/form-builder/custom-form-dropdown/custom-form-dropdown.component'
import {
    CustomFormInputComponent
} from '../mrma-ui-components/controls/form-builder/custom-form-input/custom-form-input.component'
import {
    CustomFormTextareaComponent
} from '../mrma-ui-components/controls/form-builder/custom-form-textarea/custom-form-textarea.component'
import { SharedModule } from '../shared/shared.module'
import { CalibrationFormComponent } from './components/calibration-form/calibration-form.component'
import {
    CalibrationEquipmentInfoComponent
} from './components/calibration-overview/calibration-equipment-info/calibration-equipment-info.component'
import { CalibrationInfoComponent } from './components/calibration-overview/calibration-info/calibration-info.component'
import { CalibrationOverviewComponent } from './components/calibration-overview/calibration-overview.component'
import { EquipmentInfoComponent } from './components/calibration-overview/equipment-info/equipment-info.component'
import { CalibrationResultsComponent } from './components/calibration-results/calibration-results.component'
import {
    FinalPmResultSelectorComponent
} from './components/calibration-results/final-pm-result-selector/final-pm-result-selector.component'
import {
    CalibrationTemplateSelectionDrawerComponent
} from './components/calibration-template-selection-drawer/calibration-template-selection-drawer.component'
import {
    CalibrationTemplateHeaderComponent
} from './components/calibration-template/calibration-template-header/calibration-template-header.component'
import { CompareComponent } from './components/calibration-template/compare/compare.component'
import { MultiPointComponent } from './components/calibration-template/multi-point/multi-point.component'
import { OnePointComponent } from './components/calibration-template/one-point/one-point.component'
import { PassFailCheckComponent } from './components/calibration-template/pass-fail-check/pass-fail-check.component'
import { RepeatabilityComponent } from './components/calibration-template/repeatability/repeatability.component'
import {
    ResponsiveCalibrationInputComponent
} from './components/calibration-template/responsive-calibration-input/responsive-calibration-input.component'
import { SafetyValveComponent } from './components/calibration-template/safety-valve/safety-valve.component'
import { SequentialComponent } from './components/calibration-template/sequential/sequential.component'
import {
    ResultResponsiveComponent
} from './components/calibration-template/temperature/result-set/result-responsive/result-responsive.component'
import {
    ResultRowComponent
} from './components/calibration-template/temperature/result-set/result-row/result-row.component'
import { ResultSetComponent } from './components/calibration-template/temperature/result-set/result-set.component'
import { TemperatureComponent } from './components/calibration-template/temperature/temperature.component'
import { ThirdPartyComponent } from './components/calibration-template/third-party/third-party.component'
import { WeightComponent } from './components/calibration-template/weight/weight.component'
import { CalibrationComponent } from './components/calibration/calibration.component'
import { CustomFormSectionComponent } from './components/custom-form/custom-form-section/custom-form-section.component'
import { CustomFormComponent } from './components/custom-form/custom-form.component'
import {
    EquipmentHistoryMobileComponent
} from './components/equipment-history/mobile/equipment-history-mobile/equipment-history-mobile.component'
import {
    EquipmentHistoryWebComponent
} from './components/equipment-history/web/equipment-history-web/equipment-history-web.component'
import { ResultSetHeaderComponent } from './components/result-set-header/result-set-header.component'
import { TemplateContainerService } from './services/template-container.service'
import {
    NoTemplateComponent
} from '@app/modules/calibration/components/calibration-template/no-template/no-template.component'
import { AnalyzerComponent } from './components/calibration-template/analyzer/analyzer.component'

const calibrationForms = [
    ThirdPartyComponent,
    SequentialComponent,
    CompareComponent,
    WeightComponent,
    TemperatureComponent,
    PassFailCheckComponent,
    RepeatabilityComponent,
    OnePointComponent,
    SafetyValveComponent,
    MultiPointComponent,
    NoTemplateComponent,
    AnalyzerComponent
]

const customForm = [
    CustomFormDropdownComponent,
    CustomFormInputComponent,
    CustomFormTextareaComponent
]

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [
        TemplateContainerService
    ],
    bootstrap: [
        ...calibrationForms,
        ...customForm
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        ...calibrationForms,
        CalibrationComponent,
        CalibrationOverviewComponent,
        CalibrationResultsComponent,
        CalibrationFormComponent,
        ResultSetHeaderComponent,
        ResultSetComponent,
        ResultRowComponent,
        ResultSetHeaderComponent,
        CalibrationTemplateHeaderComponent,
        ResponsiveCalibrationInputComponent,
        ResultResponsiveComponent,
        CalibrationInfoComponent,
        CalibrationTemplateSelectionDrawerComponent,
        EquipmentHistoryWebComponent,
        EquipmentHistoryMobileComponent,
        EquipmentInfoComponent,
        CustomFormComponent,
        CustomFormSectionComponent,
        FinalPmResultSelectorComponent,
        CalibrationEquipmentInfoComponent,
    ],
    exports: [CalibrationResultsComponent, CalibrationOverviewComponent]
})
export class CalibrationModule {
}
