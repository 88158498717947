<div *ngIf="dataLoaded"
     class="em-l-container">

    <!-- Navigations -->
    <div class="em-l-grid__item">
        <a class="back-button"
           (click)="navigateBack()">
            <svg class="em-c-icon em-c-icon--small em-u-margin-right-half a-icon">
                <use xlink:href="./assets/unity-1.2.0/images/em-icons.svg#chevron-left-filled"
                     xmlns:xlink="http://www.w3.org/1999/xlink"></use>
            </svg>
            <span class="a-text">
                Back to Work Order Overview
            </span>
        </a>
    </div>

    <!-- Information Header -->
    <section class="calibration-header">
        <div>
            <h4>Equipment</h4>
            <h1>
                {{equipmentCalibration.equipmentId}} <span>{{equipmentCalibration.description}}</span>
            </h1>
        </div>

        <app-offline-available-status [item]="workOrderDetails"
                                      [equipmentId]="equipmentId"
                                      [alignment]="pageName.workOrderOverView"
                                      class="offline-status"
                                      *ngIf="calibrationIntervention$ | async as calibrationIntervention; else includeButton">
        </app-offline-available-status>

        <ng-template #includeButton>
            <div class="right-info-header">
                <app-offline-available-status [item]="workOrderDetails"
                                              [equipmentId]="equipmentId"
                                              [alignment]="pageName.workOrderOverView"
                                              class="offline-status"></app-offline-available-status>
                <span class="breakline"></span>
                <app-dropdown-view-more-info [options]="moreDropdownInfoOptions"
                                             class="web-view dropdown-view-more-info"></app-dropdown-view-more-info>
            </div>
        </ng-template>
    </section>

    <!---------------------------------------------------------------
        Start of actual form
     ---------------------------------------------------------------->

    <!-- Show error section if error exist -->
    <div class="error-section"
         *ngIf="calibrationIntervention$ | async as calibrationIntervention; else calibrationSection">
        <div *ngIf="calibrationIntervention === calibrationInterventionStep.HasMultipleTemplate">
            <p>
                There are multiple templates mapped to this Equipment. Please click button below to select a template.
            </p>
            <button class="em-c-btn custom-btn-center"
                    (click)="showTemplateSelector()">
                <span class="em-c-btn__text">
                    Select Template
                </span>
            </button>
        </div>
        <div *ngIf="calibrationIntervention === calibrationInterventionStep.NotTechnician">
            <p>
                This calibration has not been created. Please ask your supervisor to grant you a technician role before you can create a calibration.
            </p>
        </div>
        <div *ngIf="calibrationIntervention === calibrationInterventionStep.TemplateNotMapped">
            <p>
                The equipment is not mapped to any templates. Please contact Planner or SAP Admin to update.
            </p>
        </div>
        <div *ngIf="calibrationIntervention === calibrationInterventionStep.TechnicianExpired">
            <p>
                Technician certification date has already expired. Please contact Planner or SAP Admin to update.
            </p>
        </div>
    </div>

    <!-- Actual Form -->
    <ng-template #calibrationSection>
        <ng-container *ngIf="calibration">
            <app-calibration-form [calibration]="calibration"
                                  [workOrderDetails]="workOrderDetails"
                                  [equipmentDetails]="equipmentDetails"
                                  [equipment]="equipmentCalibration"
                                  [reopenCalibrationEnabled]="reopenCalibrationEnabled()"
                                  [isATechnician]="isATechnician$ | async"></app-calibration-form>
        </ng-container>
    </ng-template>

</div>

<app-calibration-template-selection-drawer></app-calibration-template-selection-drawer>