import { Component, forwardRef, Input, OnInit } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
    selector: 'app-multiselect-checklist',
    templateUrl: './multiselect-checklist.component.html',
    styleUrls: ['./multiselect-checklist.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiselectChecklistComponent),
            multi: true
        }
    ]
})
export class MultiselectChecklistComponent implements ControlValueAccessor, OnInit {

    /**
     * A checklist id must be unique for the entire page (even across different form group).
     * If your id clashes (e.g. multiple items with id '1'), use this to prefix it.
     */
    @Input() idPrefix: string

    @Input() clearInputOnDisable = false

    @Input() numberOfColumns = 2

    public localChecklistViewState: { id: string, name: string, value: any, checked: boolean }[] = []
    private localValue: any[] = []

    private _isDisabled = false

    @Input() set options(optionList: { name: string, value: any, id: string }[]) {
        this.localChecklistViewState = optionList.map(o => {
            return {
                ...o,
                checked: this.localValue.includes(o.value)
            }
        })
    }
    // Do not set this through @Input, else CVA/ReactiveForm will not be aware of the change
    // If you need to set this in the DOM, see `appDisableReactiveFormControl` directive.
    public set isDisabled(disabled: boolean) {
        this._isDisabled = disabled
        if (this.clearInputOnDisable && disabled) {
            this.setStateFromValue([])
            this.onChange(this.localValue)
        }
    }
    public get isDisabled(): boolean {
        return this._isDisabled
    }

    ngOnInit(): void {
        if (!this.idPrefix) {
            this.idPrefix = '' + Math.round(Math.random() * 1000000) + '-'
        }
    }

    public checklistChanged(event): void {
        const target = event.target

        if (this.isDisabled) {
            // This reverse the view state
            if (!target.checked) {
                this.localValue.push(target.value)
            } else {
                this.localValue.splice(this.localValue.indexOf(target.value), 1)
            }
            return
        }

        if (target.checked) {
            this.localValue.push(target.value)
        } else {
            this.localValue.splice(this.localValue.indexOf(target.value), 1)
        }
        const viewState = this.localChecklistViewState.find(_viewState => {
            return String(_viewState.value) === String(target.value)
        })
        viewState.checked = target.checked

        this.onChange(this.localValue)
        this.onTouched()
    }

    writeValue(value: any[]): void {
        this.setStateFromValue(value)
    }

    setDisabledState(isDisabled): void {
        this.isDisabled = isDisabled
    }

    registerOnChange(fn): void {
        this.onChange = fn
    }

    registerOnTouched(fn): void {
        this.onTouched = fn
    }

    /**
     * Compute localValues and viewState based on given control value
     */
    private setStateFromValue(value: any[]): void {
        this.localValue = value
        this.localChecklistViewState = this.localChecklistViewState.map(viewState => {
            return {
                ...viewState,
                checked: !!value.find(v => String(v.value) === String(viewState.value))
            }
        })
    }

    private onChange: any = () => { }
    private onTouched: any = () => { }
}
