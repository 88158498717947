import { Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core'

import { SectionLoaderEnum } from '../../models/section-loader.enum'
import { SectionLoaderService } from '../../services/section-loader.service'
import { SectionLoaderHelperComponent } from '../section-loader/section-loader-helper-component'

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent extends SectionLoaderHelperComponent {
    @Input() items: any[]
    @Input() clickable = true
    @Input() displayNoRecord = false
    @Output() clickItem = new EventEmitter<any>()
    @ContentChild('header') header: TemplateRef<any>
    @ContentChild('content') content: TemplateRef<any>

    constructor(
        sectionLoaderService: SectionLoaderService
    ) {
        super(sectionLoaderService)
    }
    @ViewChild('listReference', { static: true }) set sectionLoader(ref: ElementRef) {
        if (!this.sectionLoaderRef) {
            this.registerSectionLoader(SectionLoaderEnum.List, ref)
        }
    }

    public itemClicked(item: any): void {
        if (this.clickable) {
            this.clickItem.next(item)
        }
    }
}
