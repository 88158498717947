import { FormControlError } from '@app/modules/shared/models/form-control-error.model'
import { FormValidatorResult } from '@app/modules/shared/models/form-validator-result.model'
import { humanizeStr } from '@app/utils/strings/humanize-str'
import { FormErrorHandler } from './abstract-form-error-handler'

export class SpecialControlRequiredErrorHandler extends FormErrorHandler {
    public handle(formControlError: FormControlError, results: FormValidatorResult, ...args: any): FormValidatorResult {
        if (formControlError.errorKey === 'required' || formControlError.errorKey === 'requiredText') {
            // These special fields should prevent Submit without showing named message
            if (this.shouldHandleThisName(formControlError.controlName)) {
                const errorMessage = humanizeStr`Calibration Form is incomplete. Some required field(s) may be missing. `
                return {
                    ...results,
                    preventSubmit: true,
                    errorMessages: [
                        ...results.errorMessages, errorMessage
                    ]
                }
            }
        }

        return this.next?.handle(formControlError, results, ...args)
    }

    private shouldHandleThisName(controlName: string): boolean {
        return controlName === 'calibrationResultStatus' ||
            controlName === 'value' ||
            !!(controlName.match(/[0-9]/)?.length > 0)
    }
}
