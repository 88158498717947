import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router'
import { combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { IdentityService } from '@app/services/identity.service'

@Injectable({
    providedIn: 'root',
})
export class IsTechnicianGuard implements CanActivate {
    constructor(private identityService: IdentityService) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return combineLatest([
            this.identityService.isCurrentUserATechnician$,
            this.identityService.isCurrentUserASupport$,
        ]).pipe(map(([isTechnician, isSupport]) => isTechnician || isSupport))
    }
}
