import { Component } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { Store } from '@ngrx/store'

import { MultiPointComponent } from '@app/modules/equipment/components/equipment-template/template-forms/multi-point/multi-point.component'
import * as TemplateOptions from '@app/modules/equipment/models/default-template-options-values'
import { MultiPointValidationService } from '@app/modules/equipment/services/template-validations/multi-point-validation.service'
import { AppState } from '@app/store/app.store'

@Component({
    selector: 'app-analyzer',
    templateUrl: '../multi-point/multi-point.component.html',
})
export class AnalyzerComponent extends MultiPointComponent {
    public numberOfPointOptions = TemplateOptions.numberOfPointAnalyzerMultiPointOptions;
    public defaultSetPointNames = [['Low Point', 'Zero Point'], ['High Point', 'Span'], ['Response Time']]

    constructor(
        protected store: Store<AppState>,
        protected formBuilder: FormBuilder,
        protected validationService: MultiPointValidationService
    ) {
        super(store, formBuilder, validationService)
    }
}
