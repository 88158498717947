import { Component, OnInit } from '@angular/core'
import { modal } from '@app/store/modal/modal.selectors'
import { Modal } from '@app/modules/modal-container/models/modal.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { Store } from '@ngrx/store'
import { AppState } from '@app/store/app.store'
import { Procedure } from '@app/modules/calibration/models/hyperlink.model'

@Component({
    selector: 'app-hyperlink-list-modal',
    templateUrl: './hyperlink-list-modal.component.html',
    styleUrls: ['./hyperlink-list-modal.component.scss']
})
export class HyperlinkListModalComponent extends SafeUnsubscriberComponent implements OnInit {

    public links: Procedure[] = []
    public onClickProcedure: (procedure: Procedure) => void

    constructor(private store: Store<AppState>) {
        super()
    }

    ngOnInit(): void {
        this.addSubscription(
            this.store.select(modal).subscribe((modalState: Modal) => {
                if (modalState) {
                    this.links = modalState.params.links
                    this.onClickProcedure = modalState.params.onClickProcedure
                }
            })
        )
    }
}
