<section *ngIf="workOrderDetails"
         class="em-l-container end-of-page-padding">
    <div class="em-l-grid__item">
        <a class="em-c-text-link"
           (click)="navigateBack()">
            <svg class="em-c-icon em-c-icon--small em-u-margin-right-half a-icon">
                <use xlink:href="./assets/unity-1.2.0/images/em-icons.svg#chevron-left-filled"
                     xmlns:xlink="http://www.w3.org/1999/xlink"></use>
            </svg>
            <span class="a-text">
                Back to
                <span *ngIf="!isBackToDoList()">Search Results</span>
                <span *ngIf="isBackToDoList()">My Bookmarks</span>
            </span>
        </a>
    </div>
    <section class="work-order-overview"
             *ngIf="workOrderOverview">
        <div class="web-view">
            <h2>
                Work Order
            </h2>
            <br />
            <span>
                <h1>
                    <app-star-check class="wo-star-bookmarked"
                                    [disabled]="!(isOnline$ | async)"
                                    [checked]="workOrderOverview.isBookmarked"
                                    (check)="toggleBookmark($event, workOrderOverview)">
                    </app-star-check>
                    <span>{{workOrderOverview.workOrderNumber}}</span>
                </h1>
                <app-expandable-label [value]="workOrderDetails.description"
                                      class="wo-description"></app-expandable-label>
            </span>
            <app-dropdown-view-more-info [options]="moreDropdownInfoOptions"
                                         class="dropdown-view-more-info"></app-dropdown-view-more-info>
        </div>
        <div class="responsive-view">
            <h1>
                <span>WO - {{ workOrderOverview.workOrderNumber }}</span>
                <app-star-check class="wo-star-booked-mark"
                                [disabled]="!(isOnline$ | async)"
                                [checked]="workOrderOverview.isBookmarked"
                                (check)="toggleBookmark($event, workOrderOverview)">
                </app-star-check>
            </h1>
            <app-expandable-label [value]="workOrderDetails.description"
                                  class="wo-description"></app-expandable-label>
        </div>
    </section>
    <section class="work-order-details">
        <app-work-order-info [workOrder]="workOrderDetails"></app-work-order-info>
        <app-work-order-calibration-summary [calibrationSummary]="getCalibrationSummaryFormat(workOrderDetails)"></app-work-order-calibration-summary>
    </section>
    <section>
        <app-work-order-equipment [workOrder]="workOrderDetails"></app-work-order-equipment>
    </section>

    <app-slide-up-overlay id="WorkOrderDetails">
        <app-overlay-workorder-details [workOrderDetails]="workOrderDetails"></app-overlay-workorder-details>
    </app-slide-up-overlay>
    <app-slide-up-overlay id="WorkOrderLongText">
        <app-overlay-longtext-details [category]="'Work Order'"
                                      [itemId]="workOrderDetails?.workOrderNumber"
                                      [itemDescription]="workOrderDetails?.description"
                                      [longText]="workOrderDetails?.longText">></app-overlay-longtext-details>
    </app-slide-up-overlay>

    <div #tabbar
         class="footer-responsive">
        <div class="footer-responsive-btn"
             (click)="showInfoOptions()">
            <div class="icon-container">
                <svg class="em-c-icon em-c-icon--small">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink"
                         xlink:href="./assets/unity-1.2.0/images/em-icons.svg#info"></use>
                </svg>
            </div>
            <span>Info</span>
        </div>
    </div>
    <app-selection-drawer [drawerName]="moreInfoName"
                          [selectionOptions]="moreDrawerInfoOptions"></app-selection-drawer>
</section>
