import { isNotAValue } from '../app-utils.function'

export const camelToStartCase = (str) => {
    // Add space in front of all non-consecutive capital letters
    const spacedStr = str.replace(/([A-Z]+)/g, ' $1')
    // Make the first character upper case then return
    return spacedStr.charAt(0).toUpperCase() + spacedStr.slice(1)
}

// Alias
export const camelToTitleCase = camelToStartCase

export const valueAndUnit = (value: number, unit: string): string => {
    if (isNotAValue(value) || isNotAValue(unit)) {
        return '-'
    }

    return `${value} ${unit}`
}

/**
 * Create a function that takes a number and return the first value when the input is 1 and the second value otherwise.
 */
export const singularPluralFormatter = (valueWhenSingular, valueWhenPlural) => {
    return (n) => n === 1 ? valueWhenSingular : valueWhenPlural
}

export const escapeStrForRegExp = (str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export const strReplaceAll = (str, mapping) => {
    const reg = new RegExp(Object.keys(mapping).map(key => escapeStrForRegExp(key)).join('|'), 'gi')
    return str.replace(reg, (matched) => {
        return mapping[matched]
    })
}
