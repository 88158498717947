import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core'
import { FormControl } from '@angular/forms'

import { isNotAValue } from '@app/utils/app-utils.function'

@Directive({
    selector: '[appInputDisplayReport]'
})
export class InputDisplayReportDirective implements AfterViewInit, OnChanges {
    @Input() formControl: FormControl
    @Input('appInputDisplayReport') isReport: boolean
    @Input() valueIfEmpty = '-'

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (!(changes?.formControl?.firstChange ?? true)) {
            this.manageContent(changes.formControl.currentValue.value, this.isReport)
        }
    }

    ngAfterViewInit(): void {
        this.manageContent(this.formControl.value, this.isReport)
    }

    private manageContent(controlValue: any, isReport: boolean): void {
        if (isReport) {
            const parentElement = this.renderer.parentNode(this.el.nativeElement)
            let spanElement
            // to hide '-' and show '*Required' for repair work order
            if (parentElement.className.includes('required-field')) {
                spanElement = this.constructSpan(controlValue, '')
            } else {
                spanElement = this.constructSpan(controlValue, this.valueIfEmpty)
            }

            this.replaceSelfNode(parentElement, spanElement)
        }
    }

    private replaceSelfNode(element: any, replacementNode: any): void {
        this.renderer.insertBefore(element, replacementNode, this.el.nativeElement)
        this.renderer.removeChild(element, this.el.nativeElement)
        this.el.nativeElement = replacementNode
    }

    private constructSpan(value: any, defaultValue: any): any {
        const spanElement = this.renderer.createElement('span')
        const text = this.renderer.createText(isNotAValue(value) ? defaultValue : value)
        this.renderer.appendChild(spanElement, text)

        return spanElement
    }
}
