<div class="notification-bar">
    <div class="notification-disclaimer">
        Use this button when the task is complete but not all equipment associated to this work order are tested <br />
        it will force calibration to complete, close SAP notification and mark all equipment that are not tested as "Not Done"
    </div>
    <div class="notification-button-container">
        <button class="em-c-btn em-c-btn-secondary notification-button">
            Close SAP Notification
        </button>
    </div>
</div>