import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core'
import { FormArray } from '@angular/forms'

@Directive({
    selector: '[appMultipleSelectorDisplayReport]'
})
export class MultipleSelectorDisplayReportDirective implements AfterViewInit, OnChanges {
    @Input() selectorFormArray: FormArray
    @Input() displayField: string
    @Input('appMultipleSelectorDisplayReport') isReport: boolean

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (!(changes?.selectorFormArray?.firstChange ?? true)) {
            this.manageContent(changes.selectorFormArray.currentValue.value, this.isReport)
        }
    }

    ngAfterViewInit(): void {
        this.manageContent(this.selectorFormArray.value, this.isReport)
    }

    private manageContent(controlValue: any[], isReport: boolean): void {
        if (isReport) {
            this.removeChild(this.el.nativeElement)

            for (const value of controlValue) {
                const displayValue = value?.[this.displayField] ?? '-'
                const divElement = this.constructDiv(displayValue)
                this.renderer.appendChild(this.el.nativeElement, divElement)
            }
        }
    }

    private removeChild(element: any): void {
        const children = Array.from(element.children)

        children.forEach(child => {
            this.renderer.removeChild(element, child)
        })
    }

    private constructDiv(value: string): any {
        const spanElement = this.renderer.createElement('div')
        const text = this.renderer.createText(value)
        this.renderer.appendChild(spanElement, text)

        return spanElement
    }
}
