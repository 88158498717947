import { Component, Injector, Input, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'

import { CalibrationResultValue } from '@app/modules/calibration/models/calibration-result-value.model'
import { CalibrationResult } from '@app/modules/calibration/models/calibration-result.model'
import { CalibrationValidationStatus } from '@app/modules/calibration/models/calibration-validation-status.enum'
import { CalibrationInitializerService } from '@app/modules/calibration/services/calibration-initializer.service'
import { CalibrationValidatorService } from '@app/modules/calibration/services/calibration-validator.service'
import { CalibrationResultStatusService } from '@app/modules/calibration/services/calibration-result-status.service'
import { CompareCalibrationService } from '@app/modules/calibration/services/compare-calibration.service'
import { Equipment } from '@app/modules/equipment/models/equipment.model'
import { AbstractCalibrationTemplateComponent } from '../abstract-calibration-template.component'

@Component({
    selector: 'app-compare',
    templateUrl: './compare.component.html',
    styleUrls: ['./compare.component.scss']
})
export class CompareComponent extends AbstractCalibrationTemplateComponent implements OnInit {

    @Input() equipment: Equipment

    public equipmentList: Equipment[]
    public getResultSetControl: () => FormArray

    constructor(
        private calibrationResultStatusService: CalibrationResultStatusService,
        private calibrationValidatorService: CalibrationValidatorService,
        private calibrationInitializerService: CalibrationInitializerService,
        private compareUtils: CompareCalibrationService,
        private formBuilder: FormBuilder,
        injector: Injector
    ) {
        super(injector)
    }

    ngOnInit(): void {
        super.ngOnInit()
        this.initForm()
        this.watchFormControl()
        this.compareUtils.isValid(this.calibrationForm, this.calibration)
    }

    public isInputValid(rowName: string): number {
        const calibrationResult = this.calibrationForm.get('results.calibrationResult.results') as FormArray
        const resultStatus = calibrationResult.controls[0].get('resultStatus').value as CalibrationResultValue
        return resultStatus[rowName]
    }

    public autoPopulateAsLeft(): void {
        if (this.compareUtils.isValid(this.calibrationForm, this.calibration).asFound) {
            this.compareUtils.autoPopulate(this.calibrationForm, this.calibration)
            this.compareUtils.isValid(this.calibrationForm, this.calibration)
            this._updateCalibrationResultStatus()
        }
    }

    protected updateCalibrationResultStatus(): void {
        this._updateCalibrationResultStatus()
    }

    private initForm(): void {
        this.equipmentList = [
            { equipmentTag: this.equipment.equipmentTag } as Equipment,
            ...this.calibration.calibrationTemplate.compareEquipment
        ]
        const numberOfPoint = this.calibration.calibrationTemplate.numberOfPoint
        const calibrationResultSet = (this.calibration?.calibrationResult ?? new CalibrationResult())

        const calibrationForm = this.calibrationInitializerService.initializeResultSetForm(
            '', calibrationResultSet.results[0], numberOfPoint
        )

        calibrationForm.addControl('resultStatus', this.formBuilder.group({
            asFound: CalibrationValidationStatus.Initialize,
            asLeft: CalibrationValidationStatus.Initialize
        }))

        const innerForm = this.calibrationForm.get('results') as FormGroup
        innerForm.setControl('calibrationResult', this.formBuilder.group({
            results: this.formBuilder.array([calibrationForm])
        }))
        this.getResultSetControl = this.calibrationValidatorService.getResultSetControl(this.calibrationForm)
    }

    private _updateCalibrationResultStatus(): void {
        const asFoundResult = this.isInputValid('asFound')
        const asLeftResult = this.isInputValid('asLeft')
        const resultStatusForm = this.calibrationForm.get('results.calibrationResultStatus') as FormControl
        const resultStatusValue = this.compareUtils.updateCalibrationResult(asFoundResult, asLeftResult)
        const allResultValueFilled = this.calibrationValidatorService.allInputResultHasValue(this.getResultSetControl())

        if (allResultValueFilled) {
            this.calibrationResultStatusService.updateCalibrationResultStatus(resultStatusForm, resultStatusValue)
        } else {
            this.calibrationResultStatusService.updateCalibrationResultStatus(resultStatusForm, null)
        }
    }

    private watchFormControl(): void {
        this.addSubscription(
            this.calibrationForm.get('results.calibrationResult.results').valueChanges.subscribe(form => {
                this.compareUtils.isValid(this.calibrationForm, this.calibration)
                this._updateCalibrationResultStatus()
            })
        )
    }
}
