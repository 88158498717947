import { Component } from '@angular/core'

@Component({
    selector: 'app-work-order-notification',
    templateUrl: './work-order-notification.component.html',
    styleUrls: ['./work-order-notification.component.scss']
})
export class WorkOrderNotificationComponent {

}
