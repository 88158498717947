<app-section>
    <div body>
        <div class="responsive-calibration-container">
            <div class="calibration-info">
                <label>Work Order</label>
                <p class="work-order-info">
                    <span>{{workOrder.workOrderNumber}}</span>
                    <span class="label"
                          [ngClass]="workOrderStatus === 'open' ? 'label-warning' : 'label-default'">{{workOrderStatus}}</span>
                </p>
            </div>
            <div class="calibration-info notification">
                <app-notification-status [needTooltip]="true"
                                         [notificationNumber]="getPrimaryNotification(workOrder.notification.number)"></app-notification-status>
            </div>
            <div class="calibration-info">
                <label>LACD</label>
                <p>{{workOrder.lacd | date: 'MMM dd, yyyy':'+0000'}}</p>
            </div>
        </div>
    </div>
</app-section>