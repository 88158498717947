import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core'
import { FormControl } from '@angular/forms'



@Directive({
    selector: '[appGenericDisplayReport]'
})
export class GenericDisplayReportDirective implements AfterViewInit, OnChanges {
    @Input() control: FormControl
    @Input('appGenericDisplayReport') isReport: boolean

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (!(changes?.control?.firstChange ?? true)) {
            this.manageContent(changes.control.currentValue.value, this.isReport)
        }
    }

    ngAfterViewInit(): void {
        this.manageContent(this.control.value, this.isReport)
    }

    private manageContent(controlValue: any, isReport: boolean): void {
        if (isReport) {
            const parentElement = this.renderer.parentNode(this.el.nativeElement)
            const spanElement = this.constructSpan(controlValue, '-')

            this.replaceSelfNode(parentElement, spanElement)
        }
    }

    private replaceSelfNode(element: any, replacementNode: any): void {
        this.renderer.insertBefore(element, replacementNode, this.el.nativeElement)
        this.renderer.removeChild(element, this.el.nativeElement)
        this.el.nativeElement = replacementNode
    }

    private constructSpan(value: any, defaultValue: any): any {
        const spanElement = this.renderer.createElement('span')
        const text = this.renderer.createText(value ? value : defaultValue)
        this.renderer.appendChild(spanElement, text)
        this.renderer.setStyle(spanElement, 'white-space', 'pre-wrap')

        return spanElement
    }
}
