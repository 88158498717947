import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'

import { TemplateTypeUtilService } from '@app/modules/equipment/services/template-type-util.service'
import { TemplateTypeService } from '@app/modules/equipment/services/template-type.service'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { AppState } from '@app/store/app.store'
import { ApplyEquipmentListTemplateFilterAction } from '../actions/equipment-list.actions'
import { ActionType, GetTemplateTypesAction, GetTemplateTypesSuccessAction } from '../actions/template-type.actions'
import { SaveTemplateTypeFilterOptionsAction } from '@app/store/report/report.actions'

@Injectable()
export class TemplateTypeEffects {
    
    getTemplate: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<GetTemplateTypesAction>(ActionType.GetTemplateTypes),
        switchMap(() =>
            this.responseHandlerService.query(() =>
                    this.templateTypeService.getTemplatesTypes(),
                this.useSimpleStrategy
            ).pipe(
                tap(response => {
                    this.store.dispatch(
                        new ApplyEquipmentListTemplateFilterAction(
                            this.templateTypeUtilService.transformTemplateTypeFilter(response)
                        )
                    )
                }),
                tap(response => {
                    this.store.dispatch(new SaveTemplateTypeFilterOptionsAction(response.map((item) => ({
                        id: item.id,
                        name: item.name,
                        selected: false
                    }))))
                }),
                map(response => new GetTemplateTypesSuccessAction(response))
            )
        )
    ))

    private useSimpleStrategy: ResponseHandlingStrategy

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private templateTypeService: TemplateTypeService,
        private responseHandlerService: ResponseHandlerService,
        private templateTypeUtilService: TemplateTypeUtilService
    ) {
        this.useSimpleStrategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .responseStrategy
    }
}
