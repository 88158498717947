import { Injectable } from '@angular/core'
import { AbstractControl } from '@angular/forms'

import { FormControlError } from '../models/form-control-error.model'

@Injectable({
    providedIn: 'root'
})
export class FormService {
    /**
     * Get all errors that exist in all controls (deep search).
     *
     * This function search for errors recursively.
     * **Try not to run this too often as it can be pretty expensive,
     * depending on the size of the form tree.**
     */
    // TODO: this does not works for form array
    public getErrorsRecursive(control: AbstractControl, controlName = 'root'): FormControlError[] {

        const errors = []

        // Inner recursive function
        const _getErrors = (_control: any, _controlName: string) => {
            // If the control doesn't have more child, add its error the return
            if (!_control.controls || Object.keys(_control.controls).length < 1) {
                const controlErrors = _control.errors as { [key: string]: any }
                if (controlErrors) {
                    Object.entries(controlErrors).forEach(([errorKey, errorValue]) => {
                        errors.push({
                            control: _control,
                            controlName: _controlName,
                            errorKey,
                            errorValue
                        })
                    })
                }
                return
            }

            Object.entries(_control.controls).forEach(([name, c]) => {
                _getErrors(c, name)
                return
            })
        }

        // Start Recursive
        _getErrors(control, controlName)

        return errors
    }
}

