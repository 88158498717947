import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core'
import { FormControl } from '@angular/forms'

@Directive({
    selector: '[appCalibrationInputReport]'
})
export class CalibrationInputReportDirective implements AfterViewInit {
    @Input() formControl: FormControl
    @Input('appCalibrationInputReport') isReport: boolean
    @Input() valueIfEmpty = ''

    constructor(public el: ElementRef) { }

    ngAfterViewInit(): void {
        if (this.isReport) {
            const newChildSpan = document.createElement('span')
            const valueSpan = document.createElement('span')

            // TODO: From docs, there is a way to access value regardless of whether formControl or formControlName is used.
            // I need to remember how to do it and implement.
            const value = this?.formControl?.value ??
                // To support input that doesnt use formControl but is using formControlName
                // this.el.nativeElement?.children[0]?.children[0]?.value is the only way to get that value
                (this.el.nativeElement?.children[0]?.children[0]?.value ?? this.valueIfEmpty)

            const passFailIcon = this.el.nativeElement.parentNode?.children[1]

            try {
                passFailIcon?.classList.remove('hcp-calibration-icon')
                newChildSpan.insertAdjacentElement('afterbegin', passFailIcon)
                valueSpan.innerHTML = '&nbsp;' + value
            } catch {
                // If it fail, passFailIcon likely doesn't exist (e.g. AdjustedSetPoint field)
                // We don't need the space in front here.
                valueSpan.innerHTML = value
            }

            newChildSpan.insertAdjacentElement('beforeend', valueSpan)

            this.el?.nativeElement?.parentNode?.replaceChild(newChildSpan, this.el.nativeElement)

        }
    }
}
