<div [ngClass]="{ 'em-is-active': isOpen }"
     class="em-c-dropdown-check em-js-dropdown-check">
    <button class="em-c-btn em-c-btn--is-dropdown em-js-dropdown-trigger"
            [ngClass]="{'em-c-btn--disabled': options?.length === 0}"
            [ngStyle]="styleObj">
        <div class="em-c-btn__inner">
            <span class="em-c-btn__text"
                  [ngClass]="{'gray-out-text': grayOutText}">{{ title }} {{ selectedItemCount }}</span>
            <svg class="em-c-icon em-c-icon--small em-c-btn__icon em-c-btn__icon-only">
                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="./assets/unity-1.2.0/images/em-icons.svg#caret-down"></use>
            </svg>
        </div>
    </button>
    <div class="em-c-dropdown-check__panel"
         #expandedArea>
        <div class="search-area"
             [hidden]="!showSearchBox">
            <input class="text-box"
                   type="text"
                   placeholder="search"
                   (input)="applySearch($event.target.value)"
                   #searchText>
            <hr class="detail-line">
        </div>
        <ul class="em-c-dropdown-check__list"
            [ngClass]="{'scrollable-box' : scrollableBox, 'have-clear-btn' : showClearBtn}">
            <ng-content></ng-content>
            <li *ngFor="let option of options; let i = index"
                class="em-c-dropdown-check__item"
                [hidden]="hiddenOptions[i]">
                <label for="dropdown-check-option-{{ componentId }}-{{ i }}"
                       class="em-c-input-group">
                    <input id="dropdown-check-option-{{ componentId }}-{{ i }}"
                           [(ngModel)]="option.selected"
                           (ngModelChange)="onOptionChange()"
                           type="checkbox"
                    />
                    <span class="em-c-input-group__text">{{option.name}}</span>
                </label>
            </li>
        </ul>
        <div [hidden]="!showClearBtn">
            <div class="footer">
                <div class="footer-text em-u-clickable"
                     (click)="clearCheckbox()">
                    <app-unity-icon size="small" unitySvgName="x-filled"></app-unity-icon>
                    Clear
                </div>
            </div>
        </div>
    </div>
</div>
