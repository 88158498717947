import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-message-footer-bar',
    templateUrl: './message-footer-bar.component.html',
    styleUrls: ['./message-footer-bar.component.scss']
})
export class MessageFooterBarComponent {
    @Input() cssClasses: string[] = []
    @Input() messageBarClass = undefined
    @Input() description = '-'

    get isError(): boolean {
        return this.messageBarClass === 'error'
    }

    get isInfo(): boolean {
        return this.messageBarClass === 'info'
    }
}
