<app-mrma-card title="PM Information"
               class="desktop-margin-bottom__md">
    <div [formGroup]="calibrationForm.get('results')">
        <div class="responsive-calibration-field-container">
            <div *ngIf="!(calibrationFormService.isPMDeferredOrNotTested$ | async)"
                 class="calibration-field-result">
                <label>PM Performed Date</label>
                <app-date-picker-form [formControl]="calibrationForm.get('results.performedDate')"
                                      [maxDate]="datePickerMaxDate"
                                      [appDatePickerDisplayReport]="isReport"></app-date-picker-form>
            </div>
            <div class="calibration-field-result">
                <label>PM Performed By</label>
                <app-multiple-selector [selectorFormArray]="calibrationForm.get('results.pmPerformedTechnicians')"
                                       [isRequired]="false"
                                       [initAction]="userGetAction"
                                       [displayField]="'displayName'"
                                       [idField]="'guid'"
                                       [itemSelector]="techniciansInCurrentPlantSelector"
                                       [appMultipleSelectorDisplayReport]="isReport"
                                       [hasFixedFirstValue]="true"
                                       class="dd-privacy-mask"></app-multiple-selector>
            </div>
            <div *ngIf="!(calibrationFormService.isPMDeferredOrNotTested$ | async)"
                 class="calibration-field-result">
                <label>Last Modified Date</label>
                <div [ngClass]="{'last-modified-date-label': !isReport}">{{ (calibrationForm.get('results.modifiedDate').value | date) || '-' }}</div>
            </div>
        </div>
        <div *ngIf="!(calibrationFormService.isPMDeferredOrNotTested$ | async)"
             class="responsive-calibration-field-container">
            <div class="calibration-field-result">
                <label>Serial Number</label>
                <input [appCopyToInput]="equipmentDetails?.serial"
                       [isVisible]="!isCalibrationStatusCompleted"
                       class="em-c-input input-section"
                       type="text"
                       placeholder="Enter Serial Number"
                       maxlength="100"
                       [appInputDisplayReport]="isReport"
                       [formControl]="calibrationForm.get('results.equipmentSerialNumber')" />
            </div>
            <div class="calibration-field-result">
                <label>Model</label>
                <input [appCopyToInput]="equipmentDetails?.model"
                       [isVisible]="!isCalibrationStatusCompleted"
                       class="em-c-input input-section"
                       type="text"
                       placeholder="Enter Model Number"
                       maxlength="100"
                       [appInputDisplayReport]="isReport"
                       [formControl]="calibrationForm.get('results.modelNumber')" />
            </div>
            <div class="calibration-field-result">
                <label>Manufacturer</label>
                <input [appCopyToInput]="equipmentDetails?.manufacturer"
                       [isVisible]="!isCalibrationStatusCompleted"
                       class="em-c-input input-section"
                       type="text"
                       placeholder="Enter Manufacturer"
                       maxlength="100"
                       [appInputDisplayReport]="isReport"
                       [formControl]="calibrationForm.get('results.manufacturer')" />
            </div>
        </div>
        <div *ngIf="calibrationFormService.isPMDeferredOrNotTested$ | async"
             class="responsive-calibration-field-container">
            <div class="calibration-field-result deferred-not-test-comment">
                <label>Deferred/Not tested Comment</label>
                <textarea disabled
                          height="110px"
                          readonly>{{calibrationForm.get('results.reasonForNotComplete')?.value}}</textarea>
            </div>
            <div class="calibration-field-result">
                <label>ATO Number</label>
                <input class="em-c-input input-section"
                       type="text"
                       placeholder="Enter ATO Number"
                       maxlength="100"
                       [appInputDisplayReport]="isReport"
                       [formControl]="calibrationForm.get('results.atoNumber')" />
            </div>
            <div class="calibration-field-result">
                <label>ATO Date</label>
                <app-date-picker-form [formControl]="calibrationForm.get('results.atoDate')"
                                      [placeHolder]="'Select Date'"
                                      [appDatePickerDisplayReport]="isReport"></app-date-picker-form>
            </div>
        </div>
        <app-expandable-section [sectionTitle]="'Replacement Equipment'"
                                [(isSectionExpanded)]="showReplacementEquipment"
                                [isReport]="isReport"
                                class="replace-eq-expandable-section"
                                *ngIf="!(calibrationFormService.isPMDeferredOrNotTested$ | async)
                                    && (!isReport || (isReport && showReplacementEquipment))">
            <div class="responsive-calibration-field-container">
                <div class="calibration-field-result">
                    <label>Replaced Date</label>
                    <app-date-picker-form [formControl]="calibrationForm.get('results.equipmentReplacedDate')"
                                          [isClearable]="true"
                                          [appDatePickerDisplayReport]="isReport"></app-date-picker-form>
                </div>
                <div class="calibration-field-result">
                    <label>Replaced By</label>
                    <app-multiple-selector [selectorFormArray]="calibrationForm.get('results.equipmentReplacedTechnicians')"
                                           [isRequired]="false"
                                           [initAction]="userGetAction"
                                           [displayField]="'displayName'"
                                           [itemSelector]="techniciansInCurrentPlantSelector"
                                           [appMultipleSelectorDisplayReport]="isReport"
                                           [hasFixedFirstValue]="false"></app-multiple-selector>

                </div>
            </div>
            <div class="responsive-calibration-field-container">
                <div class="calibration-field-result">
                    <label>Serial Number</label>
                    <input [appCopyToInput]="equipmentDetails?.serial"
                           class="em-c-input input-section"
                           type="text"
                           placeholder="Enter Serial Number"
                           maxlength="100"
                           [appInputDisplayReport]="isReport"
                           [formControl]="calibrationForm.get('results.replacedEquipmentSerialNumber')" />
                </div>
                <div class="calibration-field-result">
                    <label>Model</label>
                    <input [appCopyToInput]="equipmentDetails?.model"
                           class="em-c-input input-section"
                           type="text"
                           placeholder="Enter Model Number"
                           maxlength="100"
                           [appInputDisplayReport]="isReport"
                           [formControl]="calibrationForm.get('results.replacedModelNumber')" />
                </div>
                <div class="calibration-field-result">
                    <label>Manufacturer</label>
                    <input [appCopyToInput]="equipmentDetails?.manufacturer"
                           class="em-c-input input-section"
                           type="text"
                           placeholder="Enter Manufacturer"
                           maxlength="100"
                           [appInputDisplayReport]="isReport"
                           [formControl]="calibrationForm.get('results.replacedManufacturer')" />
                </div>
            </div>
            <div class="text-error em-c-field__note"
                 *ngIf="isReplaceEquipmentSameAsOriginalEquipment">
                Replacement indicated is exactly the same as original
            </div>
        </app-expandable-section>
        <div class="responsive-calibration-field-container"
             *ngIf="!(calibrationFormService.isPMDeferredOrNotTested$ | async)
             && (isRepairWorkOrderRequired || (calibration && !isNoTemplate(calibration)))
             ">
            <ng-container *ngIf="calibration && !isNoTemplate(calibration)">
                <div class="calibration-field-result"
                     [ngClass]="{'required-field': isProcedureRequired()}">
                    <label>Procedure Number</label>
                    <input *ngIf="!isProcedureLink(); else procedureLink"
                           [formControl]="calibrationForm.get('overview.procedureNumber')"
                           [attr.disabled]="!isProcedureEditable()"
                           [appInputDisplayReport]="isReport"
                           valueIfEmpty="-"
                           class="em-c-input input-section"
                           type="text"
                           data-testid="procedure-number"
                           id="procedure-number" />
                    <ng-template #procedureLink>
                        <a [href]="extractLinkFromProcedure()"
                           target="_blank"
                           rel="noopener noreferrer"
                           class="blue-button">{{formatProcedureLink()}}</a>
                    </ng-template>
                    <div></div> <!-- placeholder for *Required indicator to make it appear under this input -->
                </div>
                <div class="calibration-field-result"
                     [ngClass]="{'required-field': isRequiredTestEQ()}">
                    <label>Test Equipment</label>
                    <app-multiple-selector [selectorFormArray]="calibrationForm.get('results.testEquipments')"
                                           [isRequired]="isRequiredTestEQ()"
                                           [initAction]="testEquipmentGetAction"
                                           [displayField]="'equipmentTag'"
                                           [itemSelector]="testEquipmentSelector"
                                           [appMultipleSelectorDisplayReport]="isReport"
                                           [title]="'Test Equipment'"
                                           [inputId]="'test-equipment'">
                    </app-multiple-selector>
                </div>
            </ng-container>
            <div class="calibration-field-result"
                 *ngIf="isRepairWorkOrderRequired"
                 [ngClass]="{
                'em-is-disabled' : calibrationForm.get('overview.repairWorkOrderNumber').disabled,
                'required-field': !calibrationForm.get('overview.repairWorkOrderNumber').value}">
                <label>
                    Repair Work Order
                </label>
                <input class="em-c-input input-section"
                       type="number"
                       maxlength="8"
                       [formControl]="calibrationForm.get('overview.repairWorkOrderNumber')"
                       [appInputDisplayReport]="isReport" />
                <div class="text-error em-c-field__note"
                     *ngIf="invalidRepairWorkOrderNumber">
                    *Must be 8 digits
                </div>
                <div></div> <!-- placeholder for *Required indicator to make it appear under this input -->
            </div>
        </div>
        <app-expandable-section sectionTitle="Reference Material"
                                [(isSectionExpanded)]="showReferenceMaterial"
                                [toggleOnAdd]="isCalibrationEditable"
                                (addEvent)="addCylinder()"
                                class="replace-eq-expandable-section"
                                formArrayName="referenceMaterials"
                                *ngIf="!(calibrationFormService.isPMDeferredOrNotTested$ | async) && !isReport">
            <div *ngFor="let referenceMaterial of cylinders.controls;let i=index"
                 [formGroupName]="i"
                 class="responsive-calibration-field-container reference-from endline">

                <div *ngIf="i !== 0 && isCalibrationEditable"
                     class="remove-button-mobile"
                     [ngClass]="{'required-field-remove': isReferenceIdInvalid(referenceMaterial.get('referenceId'), i)}">
                    <div class="warning-button blue-button"
                         (click)="removeCylinder(i)"
                         appClickStopPropagation>
                        Remove
                    </div>
                </div>

                <div class="calibration-field-result"
                     [ngClass]="{'required-field required-in-reference-material': isReferenceIdInvalid(referenceMaterial.get('referenceId'), i)}">
                    <label>Reference ID</label>
                    <app-dropdown-customize-content class="dropdown"
                                                    [options]="equipmentCylinderHistory"
                                                    [searchText]="referenceMaterial.get('referenceId').value"
                                                    displayField="referenceId"
                                                    disabledField="isExpired"
                                                    placeholder="Enter Cylinders ID"
                                                    (selectedEvent)="applyCylinderInfo($event,i)"
                                                    (unSelectedEvent)="applyCylinderInfo($event,i)"
                                                    [customizeSlot]="defaultSlot"
                                                    (inputEvent)="onReferenceIdInput($event, i)"
                                                    [isDisabled]="referenceMaterial.disabled"
                                                    ngDefaultControl
                                                    [formControl]="referenceMaterial.get('referenceId')"
                                                    [appInputDisplayReport]="isReport">
                        <ng-template #defaultSlot
                                     let-data
                                     let-searchText="searchText">
                            <div class="cylinder-history-item">
                                <span [innerHTML]="highlightPartText(data.referenceId, searchText)"></span>
                                <span>{{data.isExpired ? 'Expired' : ''}}</span>
                            </div>
                        </ng-template>
                    </app-dropdown-customize-content>
                    <div class="text-error-mobile"
                         *ngIf="isReferenceIdInvalid(referenceMaterial.get('referenceId'), i)">
                        <div class="text-error"
                             *ngIf="isReferenceIdDuplicated(referenceMaterial.get('referenceId'), i)">
                            *{{cylinderIdDuplicatedError}}
                        </div>
                        <div class="text-error"
                             *ngIf="isFieldEmpty(referenceMaterial.get('referenceId')) && !isReferenceIdDuplicated(referenceMaterial.get('referenceId'), i)">
                            *{{uncompletedCylindersError}}
                        </div>
                    </div>
                </div>

                <div class="calibration-field-result"
                     [ngClass]="{'required-field required-in-reference-material': isFieldEmpty(referenceMaterial.get('content'))}">
                    <label>Content</label>
                    <input class="em-c-input input-section"
                           type="text"
                           placeholder="Enter Content"
                           maxlength="100"
                           [appInputDisplayReport]="isReport"
                           [formControl]="referenceMaterial.get('content')"
                           (input)="calibrationValidatorService.validateCylinderCompletely(referenceMaterial)" />
                    <div class="text-error-mobile"
                         *ngIf="isFieldEmpty(referenceMaterial.get('content'))">
                        <div class="text-error">
                            *{{uncompletedCylindersError}}
                        </div>
                    </div>
                </div>

                <div class="calibration-field-result reference-from"
                     [ngClass]="{'required-field required-in-reference-material': isFieldEmpty(referenceMaterial.get('expiryDate'))}">
                    <label>Expiry Date</label>
                    <app-date-picker-form [formControl]="referenceMaterial.get('expiryDate')"
                                          [isClearable]="true"
                                          placeHolder="Enter Expiry Date"
                                          (valueChange)="calibrationValidatorService.validateCylinderCompletely(referenceMaterial)"
                                          [appDatePickerDisplayReport]="isReport"></app-date-picker-form>
                    <div *ngIf="i !== 0 && isCalibrationEditable"
                         class="blue-button remove-button"
                         (click)="removeCylinder(i)"
                         appClickStopPropagation>
                        Remove
                    </div>
                    <div class="text-error-mobile"
                         *ngIf="isFieldEmpty(referenceMaterial.get('expiryDate'))">
                        <div class="text-error">
                            *{{uncompletedCylindersError}}
                        </div>
                    </div>
                </div>
            </div>
        </app-expandable-section>
        <div *ngIf="isReport && cylinders.length > 0">
            <div class="cylinder-report">
                <label>Reference ID</label>
                <label>Content</label>
                <label>Expired Date</label>
            </div>
            <div *ngFor="let referenceMaterial of cylinders.controls"
                 class="cylinder-report">
                <span>{{referenceMaterial.get('referenceId').value}}</span>
                <span>{{referenceMaterial.get('content').value}}</span>
                <span>{{getLocalDateString(referenceMaterial.get('expiryDate').value)}}</span>
            </div>
        </div>
    </div>
</app-mrma-card>

<!--Default template part-->
<div class="desktop-margin-bottom__md"
     *ngIf="isNoTemplate(calibration)">
    <app-no-template [calibrationForm]="calibrationForm"
                     [isReport]="isReport"
                     [editable]="isCalibrationEditable"></app-no-template>
</div>

<!--
    We need <ng-template> to exist so *ngIf will not work.
    Somehow, putting [hidden] on <app-...> itself does not work.
-->
<div [hidden]="shouldHideCalibrationResults || (calibrationFormService.isPMDeferredOrNotTested$ | async)">
    <app-mrma-card title="PM Calibration"
                   class="desktop-margin-bottom__md">
        <app-section title="Details"
                     [showDetailsLine]="false"
                     [titleMobileOnly]="true">
        </app-section>
        <div class="pm-calibration responsive-calibration-field-container"
             *ngIf="!(calibrationFormService.isPMDeferredOrNotTested$ | async) && calibration && !isNoTemplate(calibration)">
            <div class="calibration-info">
                <label>Process</label>
                <p>{{ calibration?.processName || '-' }}</p>
            </div>
            <div class="calibration-info">
                <label>Template Type</label>
                <p>{{ templateType[calibration.templateTypeId] || '-' }}</p>
            </div>
            <div class="calibration-info">
                <label>Alias</label>
                <p>{{ calibration?.calibrationTemplate?.alias || '-' }}</p>
            </div>
        </div>
        <hr class="web-view details-line">
        <ng-template #calibrationResult></ng-template>
        <div *ngIf="hasCalibrationTemplateLoadingError"
             class="calibration-error-box">
            <p>
                Error Loading Template. If there is an update available for MRMA, please update and try again.
            </p>
        </div>
    </app-mrma-card>
</div>

<div *appFeatureToggle="'flamingo-viewer'">
    <div [ngClass]="{'display-none': calibrationWithoutFlamingoForm}">
        <div *ngFor="let item of calibrationFlamingoService.flamingoForms; index as i">
            <app-mrma-card [ngClass]="{'display-none': calibrationFlamingoService.getFlamingLoadingState(i) === 'error'}"
                           [title]="item?.title || 'Custom Form'"
                           class="desktop-margin-bottom__md">
                <app-section title="Details"
                             [showDetailsLine]="false"
                             [titleMobileOnly]="true">
                </app-section>
                <div *ngIf="calibrationFlamingoService.isFlamingoLoading(i)">
                    <div class="spinner">
                        <img src="./assets/unity-1.2.0/images/icon-spinner.svg"
                             alt="Loading" />
                    </div>
                </div>
                <!-- *ngIf and [hidden] does not render flamingo-viewer correctly -->
                <div [ngClass]="{'display-none': calibrationFlamingoService.isFlamingoLoading(i) }">
                    <flamingo-viewer #flamingo
                                     [formTemplateId]="isCalibrationStatusCompleted ? null : item.formId"
                                     [schema]="item.formSchema"
                                     [uiSchema]="item.uiSchema"
                                     [formData]="item.formData"
                                     [accessToken]="calibrationFlamingoService.flamingoToken"
                                     [environment]="calibrationFlamingoService.flamingoEnv"
                                     [readOnly]="flamingoFormReadOnly"
                                     [showSubmitButton]="false"></flamingo-viewer>
                </div>
            </app-mrma-card>
        </div>
    </div>
</div>

<app-mrma-card *ngIf="!(calibrationFormService.isPMDeferredOrNotTested$ | async)"
               title="PM Result"
               class="desktop-margin-bottom__md">
    <div>
        <app-final-pm-result-selector [calibrationForm]="calibrationForm"
                                      [isDisabled]="isDisabled"
                                      [useBlack]="isReport || isCalibrationStatusCompleted"
                                      [manualMode]="useManualModeForFinalPMResultStatusSelector">
        </app-final-pm-result-selector>
    </div>
</app-mrma-card>

<app-mrma-card title="Comment and Attachment"
               class="desktop-margin-bottom__md margin-110">
    <div class="comment-and-attachment">
        <div class="comments">
            <label>Equipment Comment</label>
            <textarea class="em-c-textarea em-u-font-style-regular custom-full-height"
                      placeholder="A comment will be typed here"
                      rows="5"
                      [formControl]="calibrationForm.get('results.comments')"
                      [control]="calibrationForm.get('results.comments')"
                      [appGenericDisplayReport]="isReport"
                      (focus)="commentBoxCDController.focus()"
                      (blur)="commentBoxCDController.blur()"
                      autocomplete="off"
                      autocorrect="off"
                      autocapitalize="off"
                      spellcheck="false"
                      id="equipment-comment"></textarea>
        </div>
        <div class="attachment"
             [ngClass]="{'action-require': isAttachmentRequired()}">
            <label [ngClass]="{'required-field': isAttachmentRequired()}">Attachment</label>
            <div class="attachment-container">
                <app-attachment [isReport]="isReport"
                                [isAttachmentAllowed]="!!calibration?.id"
                                [isAttachmentRequired]="isAttachmentRequired()"
                                [isUserAuthorized]="isATechnician"
                                [calibration]="calibration"
                                [getAttachmentAction]="calibrationAttachmentGetAction"
                                (deleteAttachment)="deleteAttachment($event)"
                                (replaceAttachment)="replaceAttachment($event)"
                                (uploadAttachment)="uploadAttachment($event)"></app-attachment>
            </div>
        </div>
    </div>
</app-mrma-card>