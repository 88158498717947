import { createSelector } from '@ngrx/store'

import { CustomSearchTestEquipment, TestEquipment } from '@app/modules/test-equipment/models/test-equipment.model'
import { sortArray } from '@app/utils/app-utils.function'
import { selectedPlant } from '../identity/identity.selectors'
import { selectAll, TestEquipmentState } from './test-equipment.reducer'

export const testEquipmentState = (state): TestEquipmentState => state.testEquipment

export const testEquipments = createSelector(
    testEquipmentState,
    selectAll
)

export const testEquipmentsWithFilter = () => createSelector(
    testEquipments,
    (allTestEquipment: TestEquipment[], props: TestEquipment[]) => {
        return allTestEquipment.filter(testEquipment => {
            return props.findIndex(selectedTestEquipment => {
                return selectedTestEquipment.equipmentId === testEquipment.equipmentId
            }) === -1
        })
    }
)

export const queryParameters = createSelector(testEquipmentState, state => state.queryParameters)
export const selectedMultipleTestEquipment = createSelector(testEquipmentState, state => state.selectedMultipleTestEquipment)
export const selectedSingleTestEquipment = createSelector(testEquipmentState, state => state.selectedSingleTestEquipment)
export const searchTestEquipment = createSelector(testEquipmentState, state => state.searchTestEquipment)
export const searchKey = createSelector(testEquipmentState, state => state.searchKey)

export const testEquipmentInCurrentPlant = createSelector(testEquipments, selectedPlant, (allTestEquipment, plantCode) => {
    return allTestEquipment.filter(eq => eq.plantCode === plantCode)
})

export const filteredTestEquipment = createSelector(testEquipments, selectedPlant, queryParameters, (allTestEquipment, plantCode, query) => {
    allTestEquipment = allTestEquipment.filter(eq => eq.plantCode === plantCode)

    if (query.equipmentTag) {
        allTestEquipment = allTestEquipment.filter(eq => eq.equipmentTag.toLowerCase().includes(
            query.equipmentTag.toLowerCase()))
    }

    if (query.sortBy) {
        const isDescending = query.sortBy.charAt(0) === '-'
        const sortColumn = isDescending ? query.sortBy.substring(1) : query.sortBy
        allTestEquipment = sortArray(allTestEquipment, sortColumn, isDescending)
    } else {
        allTestEquipment = sortArray(allTestEquipment, 'displayName', false)
    }

    return allTestEquipment
})

export const testEquipmentToBeAdded = createSelector(testEquipments, selectedPlant, searchTestEquipment, searchKey,
    (allTestEquipment, plantCode, sapEquipment, equipmentTag) => {
        allTestEquipment = allTestEquipment.filter(eq => eq.plantCode === plantCode)

        if (equipmentTag) {
            allTestEquipment = allTestEquipment.filter(eq => eq.equipmentTag === equipmentTag)
        }

        if (allTestEquipment && sapEquipment) {
            return sapEquipment.map(se => {
                const existingTestEquipment = allTestEquipment.find(eq => eq.equipmentId === se.equipmentId && eq.plantCode === se.plantCode)
                const searchedEquipment: CustomSearchTestEquipment = {
                    equipmentId: se.equipmentId,
                    equipmentTag: se.equipmentTag,
                    description: se.description,
                    functionalLocation: se.functionalLocation,
                    functionalLocationDescription: se.functionalLocationDescription,
                    location: se.location,
                    plantCode: se.plantCode,
                    abcIndicator: se.abcIndicator,
                    abcDescription: se.abcDescription,
                    certificationExpiryDate: existingTestEquipment ? existingTestEquipment.certificationExpiryDate : undefined,
                    isExisting: existingTestEquipment ? true : false
                }
                return searchedEquipment
            })
        }
    })
