import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { MemoizedSelector, Store } from '@ngrx/store'
import { concat, get, isEmpty } from 'lodash'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'

import { SpecialUomCode } from '@app/models/special-uom.model'
import { OnePointCalibrationService } from '@app/modules/calibration/services/one-point-calibration.service'
import * as TemplateOptions from '@app/modules/equipment/models/default-template-options-values'
import { ServiceFluid } from '@app/modules/equipment/models/equipment-list-api-response.model'
import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { Process } from '@app/modules/equipment/models/process.model'
import { TemplateTypeOption } from '@app/modules/equipment/models/template-type.model'
import { TestingDirection } from '@app/modules/equipment/models/testing-direction.enum'
import { ToleranceFormValidationsService } from '@app/modules/equipment/services/template-validations/tolerance-form-validations.service'
import { ToleranceValidationService } from '@app/modules/equipment/services/tolerance-validation.service'
import { Tolerance } from '@app/modules/shared/models/engineering-units/tolerance.model'
import { UOM } from '@app/modules/shared/models/engineering-units/unit-of-measurement.model'
import { UnitValue } from '@app/modules/shared/models/engineering-units/unit-value.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import * as EquipmentTemplateAction from '@app/store/equipment/actions/equipment-template.actions'
import * as TemplateFormAction from '@app/store/equipment/actions/template-form.actions'
import { serviceFluidOptions } from '@app/store/equipment/selectors/equipment-list.selectors'
import { equipmentTemplateList, selectedTemplate } from '@app/store/equipment/selectors/equipment-template.selectors'
import { process } from '@app/store/equipment/selectors/process.selectors'
import { uomList } from '@app/store/equipment/selectors/uom.selectors'
import { deepCopy, findOptionWithKey, isNotAValue } from '@app/utils/app-utils.function'
import { mapUomObjToUomDisplay } from '@app/utils/uom/uom.util'

@Component({
    selector: 'app-safety-valve',
    templateUrl: './safety-valve.component.html',
    styleUrls: ['./safety-valve.component.scss']
})
export class SafetyValveComponent extends SafeUnsubscriberComponent implements OnInit {
    @Input() isAdmin: boolean

    public uomList$: Observable<UOM[]>
    public toleranceUoMs: UOM[] = []
    public asLeftToleranceUoMs: UOM[] = []
    public processes$: Observable<Process[]>
    public isRequiredOption = TemplateOptions.isRequiredOption
    public testingDirectionOptions = TemplateOptions.testingDirectionOptions
    public form: FormGroup
    public getOption = findOptionWithKey
    public mapUomObjToUomDisplay = mapUomObjToUomDisplay

    private selectedTemplate: TemplateTypeOption
    private templateDetails: EquipmentTemplateCoreDetails
    private percentageUOM: UOM

    constructor(
        protected store: Store<AppState>,
        protected formBuilder: FormBuilder,
        protected validationService: ToleranceFormValidationsService,
        protected onePointCalibrationService: OnePointCalibrationService,
        protected toleranceValidationService: ToleranceValidationService
    ) {
        super()
    }

    public get expectedReadingRange(): string {
        const tolerance = this.getFormGroup('tolerance').getRawValue() as Tolerance
        const expectedReading: UnitValue = {
            value: this.form.get('expectedReading.value').value as number,
            unitOfMeasurement: this.form.get('expectedReading.unitOfMeasurement').value as UOM
        }

        if (!this.toleranceValidationService.isValid(tolerance) ||
            isNotAValue(expectedReading.value) ||
            isNotAValue(expectedReading.unitOfMeasurement) ||
            isNotAValue(expectedReading.unitOfMeasurement.uomCode)) {
            return 'N/A'
        }

        const range = this.onePointCalibrationService.toleranceToNumericRange(
            tolerance, expectedReading
        )

        const uom = expectedReading.unitOfMeasurement.uomCodeForTech

        return `${range.min} to ${range.max} ${uom}`
    }

    public get asLeftExpectedReadingRange(): string {
        const tolerance = (this.form.get('asLeftTolerance') as FormGroup).getRawValue() as Tolerance
        const expectedReading: UnitValue = {
            value: this.form.get('asLeftExpectedReading.value').value as number,
            unitOfMeasurement: this.form.get('asLeftExpectedReading.unitOfMeasurement').value as UOM
        }

        if (!this.toleranceValidationService.isValid(tolerance) ||
            isNotAValue(expectedReading.value) ||
            isNotAValue(expectedReading.unitOfMeasurement) ||
            isNotAValue(expectedReading.unitOfMeasurement.uomCode)) {
            return 'N/A'
        }

        const range = this.onePointCalibrationService.toleranceToNumericRange(
            tolerance, expectedReading
        )

        const uom = expectedReading.unitOfMeasurement.uomCodeForTech

        return `${range.min} to ${range.max} ${uom}`
    }

    public get serviceFluidsSelector(): MemoizedSelector<any, ServiceFluid[]> {
        return serviceFluidOptions
    }

    ngOnInit(): void {
        this.uomList$ = this.store.select(uomList)
        this.processes$ = this.store.select(process)
        this.initPercentageUOM()
        this.addSubscription(
            this.store.select(selectedTemplate).subscribe(template => this.selectedTemplate = template)
        )
        this.initTemplateData()
        this.watchMainFormControl()
    }

    public updateToleranceRange(val: number | null, fieldName: 'tolerance' | 'asLeftTolerance', rangeName: 'lowerRange' | 'higherRange'): void {
        const patch: any = {
            [fieldName]: {
                [rangeName]: rangeName === 'higherRange' ? +val : 0 - val
            }
        }
        this.form.patchValue(patch)
    }

    public getFormGroup(controlName: string): FormGroup {
        return this.form.get(controlName) as FormGroup
    }

    public getFormControl(controlName: string): FormControl {
        return this.form.get(controlName) as FormControl
    }

    private initPercentageUOM(): void {
        this.addSubscription(
            this.uomList$.subscribe(uoms => {
                this.percentageUOM = uoms.find(uom => uom.uomCode === SpecialUomCode.Percentage)
                if (!isEmpty(this.percentageUOM)) {
                    this.toleranceUoMs = concat(this.toleranceUoMs, this.percentageUOM)
                    this.asLeftToleranceUoMs = concat(this.asLeftToleranceUoMs, this.percentageUOM)
                }
            })
        )
    }

    private initTemplateData(): void {
        // build an empty form first to prevent race condition when form.get() is called else where
        this.form = this.buildForm()

        const subscription = this.store.select(equipmentTemplateList).pipe(take(1)).subscribe(equipmentTemplate => {
            this.templateDetails = equipmentTemplate.find(template =>
                template.id === this.selectedTemplate.templateUniqueId
            )

            this.form = this.buildForm()

            if (!this.isAdmin) {
                this.form.disable()
            }

            this.bindFormDependencies()
            this.disableExpectedReadingValueIfNeeded()

            this.store.dispatch(new TemplateFormAction.SelectCurrentTemplateFormsAction(
                this.form.getRawValue()
            ))
        })
        this.addSubscription(subscription)
    }

    private bindFormDependencies(): void {
        this.bindSetPointUoMChange()
        this.bindSetPointValueChange()
        this.bindExpectedReadingUoMChange()
        this.bindAsLeftExpectedReadingUoMChange()
        this.bindToleranceLowerRangeChange()
        this.bindAsLeftToleranceLowerRangeChange()
    }

    private bindSetPointUoMChange(): void {
        const setPointGroup = this.form.get('setPoint.unitOfMeasurement')
        const uomChange = setPointGroup.valueChanges.subscribe(val => {
            const setPointValue = this.form.get('setPoint.value').value
            const patch: any = {
                expectedReading: {
                    unitOfMeasurement: val,
                    value: setPointValue
                },
                asLeftExpectedReading: {
                    unitOfMeasurement: val,
                    value: setPointValue
                }
            }
            this.form.patchValue(patch)
            this.form.get('expectedReading.value').disable()
            this.form.get('asLeftExpectedReading.value').disable()
        })
        this.addSubscription(uomChange)
    }

    private bindSetPointValueChange(): void {
        const setPointGroup = this.form.get('setPoint.value')
        const valueChange = setPointGroup.valueChanges.subscribe(val => {
            const patch: any = {}
            if (this.areSetPointAndExpectedReadingUoMsTheSame()) {
                patch.expectedReading = { value: val || 0 }
            }
            if (this.areSetPointAndAsLeftExpectedReadingUoMsTheSame()) {
                patch.asLeftExpectedReading = { value: val || 0 }
            }
            this.form.patchValue(patch)
        })
        this.addSubscription(valueChange)
    }

    private bindExpectedReadingUoMChange(): void {
        const expectedReadingGroup = this.form.get('expectedReading.unitOfMeasurement')
        const uomChange = expectedReadingGroup.valueChanges.subscribe((val: UOM) => {
            const setPointUoM = this.form.get('setPoint.unitOfMeasurement').value as UOM
            const setPointValue = this.form.get('setPoint.value').value
            const expectedReadingControl = this.form.get('expectedReading.value')
            this.setToleranceUoMs(val)

            const patch: any = {
                expectedReading: {
                    unitOfMeasurement: val
                },
                tolerance: {
                    unitOfMeasurement: val
                }
            }

            expectedReadingControl.enable()
            if (setPointUoM?.uomCode === val?.uomCode) {
                patch.expectedReading = { value: setPointValue }
                expectedReadingControl.disable()
            }
            this.form.patchValue(patch, { emitEvent: false })
        })
        this.addSubscription(uomChange)
    }

    private bindAsLeftExpectedReadingUoMChange(): void {
        const asLeftExpectedReadingGroup = this.form.get('asLeftExpectedReading.unitOfMeasurement')
        const asLeftUomChange = asLeftExpectedReadingGroup.valueChanges.subscribe((val: UOM) => {
            const setPointUoM = this.form.get('setPoint.unitOfMeasurement').value as UOM
            const setPointValue = this.form.get('setPoint.value').value
            const asLeftExpectedReadingControl = this.form.get('asLeftExpectedReading.value')
            this.setAsLeftToleranceUoMs(val)

            const patch: any = {
                asLeftExpectedReading: {
                    unitOfMeasurement: val
                },
                asLeftTolerance: {
                    unitOfMeasurement: val
                }
            }

            asLeftExpectedReadingControl.enable()
            if (setPointUoM?.uomCode === val?.uomCode) {
                patch.asLeftExpectedReading = {
                    value: setPointValue
                }
                asLeftExpectedReadingControl.disable()
            }
            this.form.patchValue(patch, { emitEvent: false })
        })
        this.addSubscription(asLeftUomChange)
    }

    private bindToleranceLowerRangeChange(): void {
        const lowerRangeControl = this.form.get('tolerance.lowerRange')
        const higherRangeControl = this.form.get('tolerance.higherRange')
        const lowerRangeChange = lowerRangeControl.valueChanges.subscribe(val => {
            if (isNotAValue(higherRangeControl.value)) {
                higherRangeControl.setValue(Math.abs(val))
            }
        })
        this.addSubscription(lowerRangeChange)
    }

    private bindAsLeftToleranceLowerRangeChange(): void {
        const lowerRangeControl = this.form.get('asLeftTolerance.lowerRange')
        const higherRangeControl = this.form.get('asLeftTolerance.higherRange')
        const lowerRangeChange = lowerRangeControl.valueChanges.subscribe(val => {
            if (isNotAValue(higherRangeControl.value)) {
                higherRangeControl.setValue(Math.abs(val))
            }
        })
        this.addSubscription(lowerRangeChange)
    }

    private disableExpectedReadingValueIfNeeded(): void {
        if (this.areSetPointAndExpectedReadingUoMsTheSame()) {
            this.form.get('expectedReading.value').disable()
        } else {
            this.form.get('expectedReading.value').enable()
        }
    }

    private areSetPointAndExpectedReadingUoMsTheSame(): boolean {
        const setPointUoM = this.form.get('setPoint.unitOfMeasurement').value as UOM
        const expectedReadingUoM = this.form.get('expectedReading.unitOfMeasurement').value as UOM
        return setPointUoM && expectedReadingUoM && setPointUoM.uomCode === expectedReadingUoM.uomCode
    }

    private areSetPointAndAsLeftExpectedReadingUoMsTheSame(): boolean {
        const setPointUoM = this.form.get('setPoint.unitOfMeasurement').value as UOM
        const expectedReadingUoM = this.form.get('asLeftExpectedReading.unitOfMeasurement').value as UOM
        return setPointUoM && expectedReadingUoM && setPointUoM.uomCode === expectedReadingUoM.uomCode
    }

    private buildForm(): FormGroup {
        const expectedReadingUoM = get(this.templateDetails, 'detail.expectedReading.unitOfMeasurement', null)
        if (!isEmpty(expectedReadingUoM)) {
            this.toleranceUoMs = concat(this.toleranceUoMs, expectedReadingUoM)
        }

        const asLeftExpectedReadingUom = get(this.templateDetails, 'detail.asLeftExpectedReading.unitOfMeasurement', null)
        if (!isEmpty(asLeftExpectedReadingUom)) {
            this.asLeftToleranceUoMs = concat(this.asLeftToleranceUoMs, asLeftExpectedReadingUom)
        }

        return this.formBuilder.group({
            process: {
                id: (this.templateDetails?.processId ?? 1),
                name: (this.templateDetails?.processName ?? 'Generic')
            },
            alias: (this.templateDetails?.detail?.alias ?? ''),
            setPoint: this.formBuilder.group({
                unitOfMeasurement: get(this.templateDetails, 'detail.setPoint.unitOfMeasurement', null),
                value: get(this.templateDetails, 'detail.setPoint.value', null)
            }),
            expectedReading: this.formBuilder.group({
                unitOfMeasurement: expectedReadingUoM,
                value: get(this.templateDetails, 'detail.expectedReading.value', null)
            }),
            tolerance: this.formBuilder.group({
                unitOfMeasurement: (this.templateDetails?.detail?.tolerance?.unitOfMeasurement ?? null),
                lowerRange: get(this.templateDetails, 'detail.tolerance.lowerRange', null),
                higherRange: get(this.templateDetails, 'detail.tolerance.higherRange', null)
            }),
            asLeftExpectedReading: this.formBuilder.group({
                unitOfMeasurement: asLeftExpectedReadingUom,
                value: get(this.templateDetails, 'detail.asLeftExpectedReading.value', null)
            }),
            asLeftTolerance: this.formBuilder.group({
                unitOfMeasurement: get(this.templateDetails, 'detail.asLeftTolerance.unitOfMeasurement', null),
                lowerRange: get(this.templateDetails, 'detail.asLeftTolerance.lowerRange', null),
                higherRange: get(this.templateDetails, 'detail.asLeftTolerance.higherRange', null)
            }),
            testingDirection: get(this.templateDetails, 'detail.testingDirection', TestingDirection.NotAvailable),
            isTestEquipmentRequired: (this.templateDetails?.detail?.isTestEquipmentRequired ?? false) + '',
            procedure: (this.templateDetails?.detail?.procedure ?? ''),
            serviceFluid: (this.templateDetails?.detail?.serviceFluid ?? '')
        })
    }

    private setToleranceUoMs(val: UOM): void {
        if (this.percentageUOM?.uomCode && val?.uomCode !== this.percentageUOM?.uomCode) {
            this.toleranceUoMs = [val, this.percentageUOM]
        } else {
            this.toleranceUoMs = [val]
        }
    }

    private setAsLeftToleranceUoMs(val: UOM): void {
        if (this.percentageUOM?.uomCode && val?.uomCode !== this.percentageUOM?.uomCode) {
            this.asLeftToleranceUoMs = [val, this.percentageUOM]
        } else {
            this.asLeftToleranceUoMs = [val]
        }
    }

    private watchMainFormControl(): void {
        this.addSubscription(
            this.form.valueChanges.subscribe(_form => {
                const raw = this.form.getRawValue()

                const eqCoreDetails = deepCopy(this.templateDetails) as EquipmentTemplateCoreDetails
                eqCoreDetails.detail.numberOfPoint = 1

                this.store.dispatch(new TemplateFormAction.SelectCurrentTemplateFormsAction(raw))
                this.store.dispatch(new EquipmentTemplateAction.EquipmentTemplateWasModifiedAction(
                    eqCoreDetails,
                    this.validationService.isSafetyValveFormValid(raw)
                ))
            })
        )
    }
}

