export enum SlideUpOverlayType {
    EquipmentHistory = 'EquipmentHistory',
    CalibrationInfo = 'CalibrationInfo',
    EquipmentDetails = 'EquipmentDetails',
    EquipmentLongText = 'EquipmentLongText',
    WorkOrderDetails = 'WorkOrderDetails',
    WorkOrderLongText = 'WorkOrderLongText',
    MaintenanceHistory = 'MaintenanceHistory',
    NoteToTechnician = 'NoteToTechnician',
    Hyperlink = 'Hyperlink',
    EquipmentChangelogs = 'EquipmentChangelogs'
}
