<div>
    <div class="loading-bar"
         [ngClass]="{loading: isLoading}"></div>
    <app-list [items]="results"
              [displayNoRecord]="shouldDisplayNoRecord"
              (clickItem)="navigateToOverview($event.workOrderNumber)">
        <ng-template #header>
            <th scope="col"
                class="em-c-table__header-cell em--no-wrap em-u-width-5 em-u-clickable"></th>
            <th (click)="applySorting('status')"
                scope="col"
                class="em-c-table__header-cell em--no-wrap em-u-clickable">
                STATUS
                <app-sorting-caret [isSorted]="getSortedState('status')"
                                   [isDescending]="getDescendingState('status')">
                </app-sorting-caret>
            </th>
            <th (click)="applySorting('workOrderNumber')"
                scope="col"
                class="em-c-table__header-cell em--no-wrap work-order-header em-u-clickable">
                WORK ORDER
                <app-sorting-caret [isSorted]="getSortedState('workOrderNumber')"
                                   [isDescending]="getDescendingState('workOrderNumber')">
                </app-sorting-caret>
            </th>
            <th scope="col"
                width="22%"
                class="em-c-table__header-cell em--no-wrap em-u-clickable">
                EQUIPMENT
                <app-sorting-caret [isSorted]="equipmentStackSortingCaret?.isSorted"
                                   [isDescending]="equipmentStackSortingCaret?.isDescending">
                </app-sorting-caret>
                <app-hoverdown>
                    <app-equipment-sort-stack [applySorting]="applySorting.bind(this)"
                                              [getSortedState]="getSortedState.bind(this)"
                                              [getDescendingState]="getDescendingState.bind(this)"
                                              (sortingState)="getEquipmentStackSortingCaretState($event)">
                    </app-equipment-sort-stack>
                </app-hoverdown>
            </th>

            <th (click)="applySorting('workOrderDescription')"
                scope="col"
                class="em-c-table__header-cell em--no-wrap em-u-clickable">
                DESCRIPTION
                <app-sorting-caret [isSorted]="getSortedState('workOrderDescription')"
                                   [isDescending]="getDescendingState('workOrderDescription')">
                </app-sorting-caret>
            </th>
            <th (click)="applySorting('abcIndicator')"
                scope="col"
                width="12%"
                class="em-c-table__header-cell em--no-wrap em-u-clickable em-u-text-align-center">
                CRITICALITY
                <app-sorting-caret [isSorted]="getSortedState('abcIndicator')"
                                   [isDescending]="getDescendingState('abcIndicator')">
                </app-sorting-caret>
            </th>
            <th (click)="applySorting('lacd')"
                scope="col"
                width="12%"
                class="em-c-table__header-cell em--no-wrap em-u-clickable">
                LACD
                <app-sorting-caret [isSorted]="getSortedState('lacd')"
                                   [isDescending]="getDescendingState('lacd')">
                </app-sorting-caret>
            </th>
            <th *ngIf="displayOfflineColumn"
                scope="col"
                width="19%"
                class="em-c-table__header-cell em--no-wrap">
                OFFLINE STATUS
            </th>
        </ng-template>
        <ng-template #content
                     let-item>
            <td class="em-c-table__cell em-u-text-align-center table-cell" (click)="onItemCheckboxClick($event, item)">
                <app-star-check *ngIf="!isSelectionMode"
                                [disabled]="!(isOnline$ | async)"
                                [checked]="item.isBookmarked"
                                (check)="toggleBookmark($event, item)"
                                (click)="$event.stopPropagation()">
                </app-star-check>
                <input type="checkbox"
                       *ngIf="isSelectionMode"
                       [checked]="isItemChecked(item)"
                       (click)="onItemCheckboxClick($event, item)"/>
            </td>
            <td class="em-c-table__cell table-cell">
                <div *ngIf="item.status"
                     class="label"
                     [ngClass]="item.status?.toLowerCase() === 'completed' ? 'label-success' : 'label-warning'">
                    {{ item.status }}
                </div>
            </td>
            <td class="em-c-table__cell em-c-table__cell-custom table-cell">
                {{ item.workOrderNumber }}
                <div class="label label-info label-multi"
                     *ngIf="getNumberOfEquipment(item.equipments) > 1">
                    Multiple EQ ({{getNumberOfEquipment(item.equipments)}})
                </div>
            </td>
            <td class="em-c-table__cell table-cell">
                <app-equipment-stack-info *ngIf="item.equipments"
                                          [equipment]="item.equipments[0]"
                                          [sortBy]="equipmentStackSortingCaret?.sortBy"></app-equipment-stack-info>
            </td>
            <td class="em-c-table__cell table-cell">
                <div>{{ item.workOrderDescription }}</div>
                <div class="label label-warning label-multi"
                     *ngFor="let action of getConsolidatedAction(item.equipments)">
                    {{ action }}
                </div>
            </td>
            <td class="em-c-table__cell table-cell em-u-text-align-center">
                {{ item.abcIndicator }}
            </td>
            <td class="em-c-table__cell table-cell">
                {{ item.lacd | date: 'MMM dd, yyyy':'+0000' }}
            </td>
            <td *ngIf="displayOfflineColumn"
                class="em-c-table__cell table-cell">
                <app-offline-available-status [item]="item"
                                              [offlineStatusEnabled]="displayOfflineColumn"></app-offline-available-status>
            </td>
            <div class="em-c-card" [ngClass]="{'selected-card': isItemChecked(item)}">
                <div class="em-c-card__body">
                    <div class="title-container">
                        <h2>
                            {{ item.workOrderNumber }}
                            <span class="label label-default em-u-font-style-semibold"
                                  *ngIf="getNumberOfEquipment(item.equipments) > 1">
                            Multi EQ ({{getNumberOfEquipment(item.equipments)}})
                        </span>
                        </h2>
                        <app-star-check *ngIf="!isSelectionMode"
                                        [disabled]="!(isOnline$ | async)"
                                        [checked]="item.isBookmarked"
                                        (check)="toggleBookmark($event, item)"
                                        (click)="$event.stopPropagation()">
                        </app-star-check>
                        <input type="checkbox"
                               *ngIf="isSelectionMode"
                               [checked]="isItemChecked(item)"
                               (click)="onItemCheckboxClick($event, item)"/>
                    </div>
                    <p>{{ item.workOrderDescription }}</p>
                    <div class="break"></div>
                    <p>LACD: {{ item.lacd | date: 'MMM dd, yyyy' }}</p>
                    <h3 [ngClass]="getCalibrationStatusClass(item)">{{ item.status || '-' }}</h3>
                    <div class="eq-tag-container">
                        <h2>{{ item.equipments[0]?.equipmentTag }}</h2>
                        <app-offline-available-status [item]="item"
                                                      [offlineStatusEnabled]="displayOfflineColumn"></app-offline-available-status>
                    </div>
                </div>
            </div>
        </ng-template>
    </app-list>
    <div *ngIf="!(isSectionLoading$ | async) && !results.length"
         class="no-record-found">
        <h1>
            <svg class="em-c-icon em-c-icon--medium em-u-clickable">
                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="./assets/unity-1.2.0/images/em-icons.svg#icon-x"></use>
            </svg>
        </h1>
        <p>No record found</p>
    </div>
</div>
