<ul class="multi-numbers">
    <li class="number-field"
        *ngFor="let control of formArray.controls; let i = index">
        <app-numeric-input placeholder="0.00"
                           [inputId]="'' + idPrefix + i"
                           [ngClass]="'removable'"
                           [injectedStyles]="{'padding-right': '1.6rem'}"
                           [formControl]="control"
                           [decimalPlaces]="decimalPlaces"
                           [allowNegative]="allowNegative"
                           [blurToOriginalValue]="blurToOriginalValue"
                           [clearZeroOnFocus]="clearZeroOnFocus"
                           [enforceDecimalOnBlur]="enforceDecimalOnBlur"
                           [max]="max"
                           textAlign="left"
                           [min]="min"
                           [silentMaxWarning]="silentMaxWarning"
                           (emitWhenInputLosingFocus)="arrange()"></app-numeric-input>
        <span class="btn btn--remove"
              (click)="removeField(i)">
            <svg class="em-c-icon em-c-icon--small">
                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="./assets/unity-1.2.0/images/24/em-icons.svg#icon-x"></use>
            </svg>
        </span>
    </li>
    <li class="add-button--wrapper">
        <button *ngIf="formArray?.enabled"
                class="btn btn--add"
                type="button"
                (click)="addField()">+ Add</button>
    </li>
</ul>
