import { AbstractControl, ValidatorFn } from '@angular/forms'

import { isNotAValue } from '@app/utils/app-utils.function'

/**
 * A simple range validator for Reactive Form.
 *
 * Once created, max and min value will be static and cannot/will not
 * change afterward.
 */
export const staticRangeValidator = (opts: {
    max: number,
    min: number,
    onValid?: () => {},
    onInvalid?: () => {},
    onNeutral?: () => {}
}): ValidatorFn => {

    const { max, min, onValid, onInvalid, onNeutral } = opts

    return (control: AbstractControl): { [s: string]: any } => {
        const v = control.value

        if (isNotAValue(v)) {
            if (onNeutral) {
                onNeutral()
            }
            return
        }

        if (v > max || v < min) {
            if (onInvalid) {
                onInvalid()
                return { ValueOutOfRange: { allowSubmit: true, max, min, value: v } }
            }
        } else {
            if (onValid) {
                onValid()
            }
        }
        return
    }
}
