export const defaultWorkOrderFilters = {
    plannerGroup: {
        name: 'Planner Group',
        value: [],
    },
    mainWorkCenter: {
        name: 'Main Work Center',
        value: [],
    },
    maintenancePlant: {
        name: 'Maintenance Plant',
        value: [],
    },
    plantSection: {
        name: 'Plant Section',
        value: [],
    },
    location: {
        name: 'Location',
        value: [],
    },
    criticality: {
        name: 'Criticality',
        value: [],
    },
    category: {
        name: 'Category',
        value: [],
    },
    classification: {
        name: 'Classification',
        value: [],
    },
}
