import { FormControlError } from '@app/modules/shared/models/form-control-error.model'
import { FormValidatorResult } from '@app/modules/shared/models/form-validator-result.model'
import { humanizeStr } from '@app/utils/strings/humanize-str'
import { FormErrorHandler } from './abstract-form-error-handler'

export class RequiredErrorHandler extends FormErrorHandler {
    public handle(formControlError: FormControlError, results: FormValidatorResult, ...args: any): FormValidatorResult {
        if (formControlError.errorKey !== 'required' && formControlError.errorKey !== 'requiredText') {
            return this.next?.handle(formControlError, results, ...args)
        }
        const errorMessage = humanizeStr`The field '${formControlError.controlName}' is required to complete the calibration.`

        return {
            ...results,
            preventSubmit: true,
            errorMessages: [
                ...results.errorMessages, errorMessage
            ]
        }
    }
}
