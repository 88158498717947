<section class="web-view">
    <div class="result-set-header em-l-grid em-l-grid--4up">
        <div class="em-l-grid__item">
            <div class="em-c-field__label">Input ({{template.input.unitOfMeasurement.uomCodeForTech}})
            </div>
        </div>
        <div class="em-l-grid__item"
             *ngIf="hasInjectedInput">
            <div class="em-c-field__label">Adjusted Input ({{template.input.unitOfMeasurement.uomCodeForTech}})</div>
        </div>
        <div class="em-l-grid__item">
            <div class="em-c-field__label">Expected ({{template.expected.unitOfMeasurement.uomCodeForTech}})</div>
        </div>
        <div class="em-l-grid__item"
             *ngIf="!hasInjectedInput || (hasInjectedInput && isAsFound)">
            <div class="em-c-field__label">As Found ({{template.expected.unitOfMeasurement.uomCodeForTech}})</div>
        </div>
        <div class="em-l-grid__item"
             *ngIf="!hasInjectedInput || (hasInjectedInput && !isAsFound)">
            <div class="em-c-field__label">As Left ({{template.expected.unitOfMeasurement.uomCodeForTech}})</div>
        </div>
    </div>
</section>
