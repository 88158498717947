import { Component, Injector, OnInit } from '@angular/core'
import { FormArray, FormGroup } from '@angular/forms'

import { CalibrationValidationStatus } from '@app/modules/calibration/models/calibration-validation-status.enum'
import { OnePointTemplate } from '@app/modules/calibration/models/one-point-template.model'
import { OnePointCalibrationService } from '@app/modules/calibration/services/one-point-calibration.service'
// FIXME: This line is refering to something outside its own module and not AppModule or SharedModule
import { testingDirectionOptions } from '@app/modules/equipment/models/default-template-options-values'
import { UnitOfMeasurement } from '@app/modules/shared/models/engineering-units/unit-of-measurement.model'
import { isNotAValue } from '@app/utils/app-utils.function'
import { AbstractCalibrationTemplateComponent } from '../abstract-calibration-template.component'

@Component({
    selector: 'app-one-point',
    templateUrl: './one-point.component.html'
})
export class OnePointComponent extends AbstractCalibrationTemplateComponent implements OnInit {

    public form: FormGroup

    constructor(
        private onePointCalibrationService: OnePointCalibrationService,
        injector: Injector
    ) {
        super(injector)
    }

    public get template(): OnePointTemplate {
        return this.calibration.calibrationTemplate as OnePointTemplate
    }

    public get resultSetControl(): FormArray {
        const resultFormArray = this.form.get('calibrationResult.results') as FormArray
        return resultFormArray.at(0).get('resultSet') as FormArray
    }

    public get setPointUoM(): string {
        return this.formatUoM(this.template.setPoint.unitOfMeasurement)
    }

    public get expectedReadingRange(): string {
        const range = this.onePointCalibrationService.toleranceToNumericRange(
            this.template.tolerance,
            this.template.expectedReading
        )

        return `${range.min} to ${range.max}`
    }

    public get expectedReadingUoM(): string {
        return this.formatUoM(this.template.expectedReading.unitOfMeasurement)
    }

    public get toleranceUoM(): string {
        return this.formatUoM(this.template.tolerance.unitOfMeasurement)
    }

    ngOnInit(): void {
        super.ngOnInit()
        this.form = this.calibrationForm.get('results') as FormGroup

        this.onePointCalibrationService.initialize(
            this.form,
            this.calibration,
            this.template
        )

        if (!this.isReport) {
            this.watchFormControl()
        }
    }

    public autoPopulateAsLeft(): void {
        const set = this.resultSetControl.at(0)
        const asLeft = set.get('asLeft')
        const asFoundVal = set.get('asFound').value

        if (isNotAValue(asLeft.value) &&
            this.onePointCalibrationService.validateValue(asFoundVal, this.template) === CalibrationValidationStatus.Valid) {
            asLeft.setValue(asFoundVal)
        }
    }

    public validateAsFound(pointControl: FormGroup): CalibrationValidationStatus {
        return this.onePointCalibrationService.validateValue(
            pointControl.get('asFound').value,
            this.template
        )
    }

    public validateAsLeft(pointControl: FormGroup): CalibrationValidationStatus {
        return this.onePointCalibrationService.validateValue(
            pointControl.get('asLeft').value,
            this.template
        )
    }

    public getTestingDirection(): string {
        const dir = testingDirectionOptions.find(it => it.key === this.template.testingDirection)
        if (!dir) {
            return ''
        }

        return dir.value
    }

    protected updateCalibrationResultStatus(): void {
        this.onePointCalibrationService.updateCalibrationResultStatus(
            this.form,
            this.template
        )
    }

    private formatUoM(uom: UnitOfMeasurement): string {
        return uom ? `(${uom.uomCodeForTech})` : ''
    }

    private watchFormControl(): void {
        this.addSubscription(
            this.form.get('testEquipments').valueChanges.subscribe(() => {
                this.updateCalibrationResultStatus()
            })
        )

        this.addSubscription(
            this.calibrationForm.get('overview.procedureNumber').valueChanges.subscribe(() => {
                this.updateCalibrationResultStatus()
            })
        )

        this.addSubscription(
            this.form.get('calibrationResult').valueChanges.subscribe(() => {
                this.updateCalibrationResultStatus()
            })
        )
    }
}
