import { Component, Input } from '@angular/core'
import { Store } from '@ngrx/store'

import { AppState } from '@app/store/app.store'
import { SlideOverlayAction } from '@app/store/overlay/overlay.actions'
import { SlideUpOverlayType } from '../../constants/slide-up-overlay.enum'

@Component({
    selector: 'app-overlay-header-info',
    templateUrl: './overlay-header-info.component.html',
    styleUrls: ['./overlay-header-info.component.scss']
})
export class OverlayHeaderInfoComponent {

    @Input() header: string
    @Input() subHeader: string
    @Input() itemId: string
    @Input() itemDescription: string
    @Input() overlayId: SlideUpOverlayType
    @Input() isLoading: boolean = false

    constructor(
        private store: Store<AppState>,
    ) { }

    public closeOverlay(): void {
        this.store.dispatch(new SlideOverlayAction({ visibility: false, id: this.overlayId }))
    }
}
