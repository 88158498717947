import { Injectable } from '@angular/core'

import { QueryParameter, QuerySummary } from '../models/query-summary.model'

@Injectable({
    providedIn: 'root'
})
export class PaginationService {

    public getPaginationList(querySummary: QuerySummary<QueryParameter>): number[] {
        const paginationList = []
        const totalPage = this.getTotalPage(querySummary)
        const startingPage = this.getPaginationStartingPoint(querySummary)
        let endingPage = startingPage + 4
        if (endingPage > totalPage) {
            endingPage = totalPage
        }
        for (let i = startingPage; i <= endingPage; i++) {
            paginationList.push(i)
        }

        return paginationList
    }

    public getTotalPage(querySummary: QuerySummary<QueryParameter>): number {
        const itemPerPage = parseInt(querySummary.itemPerPage, 10)
        if (querySummary.itemPerPage === 'all') {
            return 1
        } else if (isNaN(itemPerPage)) {
            // when input is other string
            return 1
        } else {
            const totalPages = Math.ceil(querySummary.totalItemCount / itemPerPage)
            return totalPages < 1 ? 1 : totalPages
        }
    }

    private getPaginationStartingPoint(querySummary: QuerySummary<QueryParameter>): number {
        const totalPages = this.getTotalPage(querySummary)
        if (totalPages < 4 || querySummary.currentPage < 4) {
            return 1
        }

        return Math.min(querySummary.currentPage - 2, totalPages - 4)
    }
}
