<div class="em-u-padding-bottom-quad"
     #pagedListReference>
    <table class="em-c-table em-u-margin-bottom-none table">
        <thead class="em-c-table__header">
        <tr class="em-c-table__header-row">
            <th scope="col"
                class="em-c-table__header-cell em-u-clickable em--no-wrap em-u-text-align-center"
                (click)="selectAll()">
                <input type="checkbox"
                       [checked]="isAllChecked()"
                       [hidden]="!clickable"
                       class="em-c-input-group__control"/>
            </th>
            <ng-container *ngTemplateOutlet="header"></ng-container>
        </tr>
        </thead>
        <tbody class="em-c-table__body">
        <tr class="em-c-table__row em-u-margin-bottom-none align-middle"
            [ngClass]="{'non-clickable': !clickable, 'em-u-clickable': clickable}"
            *ngFor="let item of pages[selectedPage]; let i = index"
            (click)="itemClicked(item)">
            <td class="em-c-table__cell em-u-text-align-center"
                (click)="inputBoxClicked($event, item)">
                <input type="checkbox"
                       class="em-c-input-group__control"
                       [hidden]="!clickable"
                       [checked]="isItemChecked(item)"
                       (click)="inputBoxClicked($event, item)"/>
            </td>
            <ng-container *ngTemplateOutlet="content;context: { $implicit: item }"></ng-container>
        </tr>
        <tr *ngIf="items.length <= 0">
            <td class="em-c-table__cell em-u-text-align-center"
                colspan="10">
                No record found
            </td>
        </tr>
        </tbody>
        <tfoot class="em-c-table__footer">
        <tr class="em-c-table__footer-row"></tr>
        </tfoot>
    </table>

    <div class="tiles">
        <ul class="em-c-card-list  em-l-grid em-l-grid--2up">
            <li class="em-c-card-list__item em-l-grid__item"
                [ngClass]="{'non-clickable': !clickable, 'em-u-clickable': clickable}"
                *ngFor="let item of pages[selectedPage]; let i = index"
                (click)="itemClicked(item)">
                <div class="em-c-card">
                    <div class="em-c-card__body">
                        <div class="em-c-media-block em-c-media-block--small">
                            <div class="em-c-media-block__body">
                                <h2 class="em-c-media-block__headline fn">{{ item.equipmentTag }}</h2>
                                <input type="checkbox"
                                       class="em-c-input-group__control checkbox"
                                       [hidden]="!clickable"
                                       [checked]="isItemChecked(item)"
                                       (click)="inputBoxClicked($event, item)"/>
                            </div>
                            <ng-container *ngTemplateOutlet="content;context: { $implicit: item }"></ng-container>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <div class="em-c-toolbar"
         *ngIf="pages.length > 1">
        <div class="em-c-toolbar__item fixed-content"></div>
        <div class="em-c-toolbar__item">
            {{firstItemIndex}}-{{lastItemIndex}} of {{items.length}}
        </div>
        <div class="em-c-toolbar__item">
            <ol class="em-c-pagination"
                role="navigation"
                aria-labelledby="pagination-label">
                <li class="em-c-pagination__item"
                    [ngClass]="{'em-u-clickable': clickable }">
                    <a class="em-c-pagination__link"
                       [ngClass]="{ 'em-is-disabled': isFirstPage }"
                       (click)="previousPage()">
                        <app-unity-icon unitySvgName="icon-caret-left" size="small"></app-unity-icon>
                    </a>
                </li>
                <li class="em-c-pagination__item"
                    [ngClass]="{'em-u-clickable': clickable }">
                    <a class="em-c-pagination__link"
                       [ngClass]="{'em-is-disabled': isLastPage }"
                       (click)="nextPage()">
                        <app-unity-icon unitySvgName="icon-caret-right" size="small"></app-unity-icon>
                    </a>
                </li>
            </ol>
        </div>
    </div>
</div>
