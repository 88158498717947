import { Directive, ElementRef, HostListener, Input } from '@angular/core'
import { NgModel } from '@angular/forms'

@Directive({
    selector: '[ngModel][appOnPasteCommaSeparator]'
})
export class OnPasteCommaSeparatorDirective {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('appOnPasteCommaSeparator') enable: boolean

    constructor(
        private el: ElementRef,
        private ngModel: NgModel
    ) { }

    @HostListener('paste', ['$event'])
    public onPaste(event: any): void {
        if (!this.enable) {
            return
        }

        // Using setTimeout to perform formatting after paste event has finished
        const caratPosition = event.target.selectionStart
        const pasteData = event.clipboardData.getData('text')
        setTimeout(() => {
            const commaSeparatedWOs = this.el.nativeElement.value
                .replace(/ +/g, ', ')
                .replace(/,,/g, ',')

            this.ngModel.update.emit(commaSeparatedWOs)

            const pasteCharLength = pasteData
                .replace(/ +/g, ', ')
                .replace(/,,/g, ',')
                .length

            // To correct carat position when pasting inbetween text
            const finalCaretPostion = caratPosition + pasteCharLength
            setTimeout(() => {
                this.el.nativeElement.focus()
                this.el.nativeElement.setSelectionRange(finalCaretPostion, finalCaretPostion)
            })
        })
    }
}
