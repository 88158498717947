import { Injectable } from '@angular/core'
import { MsalService } from '@azure/msal-angular'
import { Store } from '@ngrx/store'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { distinctUntilChanged, filter } from 'rxjs/operators'
import { Workbox } from 'workbox-window'

import { ServiceWorkerStatusEnum } from '@app/models/service-worker-status.enum'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import { isOnline } from '@app/store/connection/connection.selectors'
import { SetLoaderAction } from '@app/store/loader/loader.actions'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { BackgroundQueueHandlerService } from './background-queue-handler.service'
import { LoggerService } from './logger.service'

const minTokenExpirationForSync = 30

@Injectable({
    providedIn: 'root'
})
export class ServiceWorkerService extends SafeUnsubscriberComponent {
    private workboxInstance: Workbox = null

    private _serviceWorkerStatus$ = new BehaviorSubject<string>(undefined)
    private reloading: boolean
    private logger: LoggerService = new LoggerService('SW Service')

    private isOnline$ = new BehaviorSubject(false)

    constructor(
        private backgroundQueueHandleService: BackgroundQueueHandlerService,
        private store: Store<AppState>,
        private authService: MsalService
    ) {
        super()
        this.store.select(isOnline).subscribe(online => {
            this.logger.info('isOnline', online)
            this.isOnline$.next(online)
        })

        if ('serviceWorker' in navigator && environment.serviceWorker) {
            this.workboxInstance = new Workbox('sw-workbox.js')
            this.setupHandler()
            this.registerServiceWorker()
            this.listenToServiceWorkerEvents()
        } else {
            this.logger.warn('SW NOT Found')
        }

        // Will trigger SYNC once online and service worker is READY
        this.addSubscription(combineLatest([
            this.isOnline$,
            this._serviceWorkerStatus$
        ]).pipe(
            distinctUntilChanged(),
            filter(([online, swStatus]) => {
                return online && swStatus === ServiceWorkerStatusEnum.READY
            }),
        ).subscribe(() => {
            this.triggerSynchronization()
        }))
    }

    public get serviceWorkerStatus$(): Observable<string> {
        return this._serviceWorkerStatus$.asObservable()
    }

    public updateApplication(): void {
        this.skipServiceWorkerWaitingState()
        this.store.dispatch(new SetLoaderAction())

        // Force refresh when SW skip waiting is stale
        setTimeout(() => {
            window.location.reload()
        }, AppSettings.forceRefreshIntervalAfterSWUpdate)
    }

    private skipServiceWorkerWaitingState(): void {
        this.workboxInstance.messageSW({ type: 'SKIP-WAITING' })
    }

    /**
     * Setup channel for service worker to client communication.
     * Will default to BroadcastChannel if available (Chrome)
     * and fallback to Client API otherwise (Safari 14+)
     */
    private listenToServiceWorkerEvents(): void {
        if ('BroadcastChannel' in window) {
            this.logger.info('[ServiceWorkerService] BroadcastChannel is supported.')
            const broadcastChannel = new BroadcastChannel('workbox')
            broadcastChannel.addEventListener('message', event => {
                this.handleSWMessage(event)
            })
        } else {
            this.logger.info('[ServiceWorkerService] BroadcastChannel is NOT supported.')
            this.workboxInstance?.addEventListener('message', event => {
                this.handleSWMessage(event)
            })
        }
    }

    private handleSWMessage(event: any): void {
        const type = event.data.type

        if (type === 'APPLICATION-FILE-CACHE-WILL-UPDATE'
            && this._serviceWorkerStatus$.value === undefined) {
            this._serviceWorkerStatus$.next(ServiceWorkerStatusEnum.UPDATING)
            return
        }

        if (type === 'BACKGROUND-QUEUE-HANDLER') {
            this.backgroundQueueHandleService.handleBackgroundSyncStatus(event)
            return
        }

        if (type === 'SERVICE-WORKER-READY') {
            this._serviceWorkerStatus$.next(ServiceWorkerStatusEnum.READY)
            this.triggerSynchronization()
        }

    }

    private setupHandler(): void {
        this.workboxInstance.addEventListener('installed', (event) => {
            if (!event.isUpdate) {
                this._serviceWorkerStatus$.next(ServiceWorkerStatusEnum.FIRST_INSTALL)
            }
        })

        this.workboxInstance.addEventListener('waiting', (event) => {
            this._serviceWorkerStatus$.next(ServiceWorkerStatusEnum.UPDATE_AVAILABLE)
        })

        this.workboxInstance.addEventListener('controlling', (event) => {
            if (!this._serviceWorkerStatus$.value) {
                this._serviceWorkerStatus$.next(ServiceWorkerStatusEnum.READY)
            } else if (this._serviceWorkerStatus$.value !== ServiceWorkerStatusEnum.READY) {
                if (!this.reloading) {
                    this.reloading = true
                    window.location.reload()
                }
            }

        })

        this.workboxInstance.addEventListener('externalactivated', (event) => {
            if (!this.reloading) {
                this.reloading = true
                window.location.reload()
            }
        })

        this.workboxInstance.addEventListener('externalwaiting', (event) => {
            this._serviceWorkerStatus$.next(ServiceWorkerStatusEnum.UPDATE_AVAILABLE)
        })
    }

    private registerServiceWorker(): void {
        this.workboxInstance.register().then(registration => {
            setInterval(() => registration.update(), 1800000)
        })
    }

    private triggerSynchronization(): void {
        if (!this.isOnline$.value) {
            this.logger.warn('Unable to start synchronization. Application is offline.')
            return
        }

        if (!this.workboxInstance) {
            this.logger.warn('Unable to start synchronization. Service worker not initialized.')
            return
        }

        this.authService.acquireTokenSilent({
            scopes: environment.auth.scopes,
            forceRefresh: true
        }).subscribe(authResponse => {
            const maxExpirationDate = new Date()
            maxExpirationDate.setMinutes(maxExpirationDate.getMinutes() + minTokenExpirationForSync)

            this.logger.info('Token expiration', { expiresOn: authResponse.expiresOn, maxExpirationDate })

            if (authResponse.expiresOn < maxExpirationDate) {
                this.logger.info('Token requires refresh before syncing')
                return
            }

            this.sendStartSync(authResponse.accessToken)
        }, error => {
            this.logger.error('Unable to start synchronization. Unable to acquire token.', error)
        })
    }

    private sendStartSync(accessToken: string): void {
        this.logger.info('Sending START-SYNC message')
        this.workboxInstance.messageSW({
            type: 'START-SYNC',
            accessToken
        })
    }
}
