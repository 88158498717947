<div class="sheet"
     *ngIf="isSheetOpen"
     @slideInOut>
    <div class="sheet--header">
        <span class="header-title">{{title}}</span>
        <span class="btn-close"
              (click)="closeSheet()">CLOSE X</span>
    </div>
    <div class="sheet--content">
        <ng-content></ng-content>
    </div>
</div>
