<ul class="em-c-primary-nav__list"
    *ngIf="plants$ | async as plants">
    <li class="em-c-primary-nav__item"
        *ngIf="selectedPlant$ | async as selectedPlant">
        <a class="em-c-primary-nav__link em-c-primary-nav__link--has-children em-js-nav-dropdown-trigger em-u-clickable"
           [appToggleActive]="(hasMorePlants() | async)">
            <span class="em-c-btn__text">Plant: {{formatPlant(selectedPlant)}}</span>
            <svg class="em-c-icon em-c-icon--small em-c-primary-nav__icon"
                 *ngIf="(hasMorePlants() | async)">
                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="assets/unity-1.2.0/images/em-icons.svg#caret-down"></use>
            </svg>
        </a>
        <ul class="em-c-primary-nav__sublist em-js-nav-dropdown scrollGradient">
            <ng-container *ngIf="(isOnline$ | async) && (hasMorePlants() | async)">
                <li class="em-c-primary-nav__subitem"
                    *ngFor="let plant of plants"
                    (click)="selectPlant(plant, selectedPlant)">
                    <a class="em-c-primary-nav__sublink em-u-clickable"
                       [ngClass]="{ 'em-u-font-style-bold': plant === selectedPlant }">
                        {{ formatPlant(plant) }}
                    </a>
                </li>
            </ng-container>
            <li class="em-c-primary-nav__subitem"
                *ngIf="!(isOnline$ | async)">
                <a class="em-c-primary-nav__sublink offline-label">
                    Cannot change plant while offline
                </a>
            </li>
        </ul>
    </li>
</ul>
