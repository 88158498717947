<div class="responsive-view slide-up-overlay">
    <app-overlay-header-info [header]="'Hyperlink'"
                             [overlayId]="overlayId"></app-overlay-header-info>
    <div class="body em-u-margin-top">
        <p *ngIf="!isOnline">Unable to load data when offline.</p>
        <app-loading-label *ngIf="isOnline"
                           [isLoading]="isLoading"
                           [hasError]="hasError"
                           (clickErrorLabel)="reloadHyperlinkInfo()">
            <p *ngIf="linkCount === 0"
               class="procedure-row">No record found</p>
            <div class="responsive-calibration-field-container procedure-row"
                 [ngClass]="{'blue-button': !!procedure.externalLink}"
                 *ngFor="let procedure of hyperlinkInfo?.procedureList"
                 (click)="onClickProcedure(procedure)">
                <app-unity-icon unitySvgName="link-external"
                                *ngIf="procedure.externalLink"
                                size="small"
                                class="em-u-space-right-half"></app-unity-icon>
                <span>{{getProcedureLinkName(procedure)}}</span>
            </div>
        </app-loading-label>
    </div>
</div>