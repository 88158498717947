import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'

import { FlamingoForm } from '@app/modules/flamingo/models/flamingo-form.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import { LinkFlamingoFormToEquipmentAction } from '@app/store/equipment/actions/equipment-flamingo-form.actions'
import { equipmentDetail } from '@app/store/equipment/selectors/equipment.selectors'
import { DestroyModalAction } from '@app/store/modal/modal.actions'

@Component({
    selector: 'app-form-selection',
    templateUrl: './form-selection.component.html',
    styleUrls: ['./form-selection.component.scss']
})
export class FlamingoFormSelectionComponent extends SafeUnsubscriberComponent implements OnInit {

    public equipmentId: string

    constructor(
        private store: Store<AppState>
    ) {
        super()
    }

    ngOnInit(): void {
        this.addSubscription(this.store.select(equipmentDetail).subscribe(eqDetails => this.equipmentId = eqDetails.equipmentId))
    }

    public formSelected(form: FlamingoForm): void {
        this.store.dispatch(LinkFlamingoFormToEquipmentAction({ flamingoFormId: form.formId, equipmentId: this.equipmentId }))
        this.store.dispatch(new DestroyModalAction())
    }

}
