import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'

import { BackgroundSyncStatusEnum } from '@app/models/offline-status.enum'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import { ReSyncCalibrationAction } from '@app/store/calibration/calibration.actions'
import { RemovePMFromUploadQueueAction } from '@app/store/offline/offline.actions'
import { pmUploadQueue } from '@app/store/offline/offline.selectors'
import { UploadQueue } from '../../models/upload-queue.model'

@Component({
    selector: 'app-offline-upload-status-queue',
    templateUrl: './offline-upload-status-queue.component.html',
    styleUrls: ['./offline-upload-status-queue.component.scss']
})
export class OfflineUploadStatusQueueComponent extends SafeUnsubscriberComponent implements OnInit {
    public uploadData: UploadQueue[]
    public displayStatus = true
    public dragging: boolean
    public status = BackgroundSyncStatusEnum
    public total: number
    public completed: number
    public syncFailedOnSome: boolean

    constructor(private store: Store<AppState>) {
        super()
    }

    public get remainingPM(): number {
        return this.total - this.completed
    }

    ngOnInit(): void {
        this.addSubscription(
            this.store.select(pmUploadQueue).subscribe(uploadQueue => {
                this.uploadData = uploadQueue
                this.total = this.uploadData.length
                this.completed = this.uploadData.filter(d => d.status === BackgroundSyncStatusEnum.SYNCED)?.length
                this.syncFailedOnSome = this.uploadData.filter(d => d.status === BackgroundSyncStatusEnum.FAILED)?.length > 1
            })
        )
    }

    public getStatusText(status: string): string {
        switch (status) {
            case BackgroundSyncStatusEnum.SYNCING:
                return 'Syncing'
            case BackgroundSyncStatusEnum.SYNCED:
                return 'Synced'
            case BackgroundSyncStatusEnum.FAILED:
                return 'Sync Failed'
            case BackgroundSyncStatusEnum.QUEUED:
                return 'Pending'
        }
    }

    public handleDragStart(): void {
        this.dragging = true
    }

    public handleClick(): void {
        if (this.dragging) {
            this.dragging = false
            return
        }

        if (this.remainingPM === 0) {
            this.displayStatus = false
            this.store.dispatch(new RemovePMFromUploadQueueAction())
            return
        }

        this.displayStatus = !this.displayStatus
    }

    public reSyncAll(): void {
        this.store.dispatch(new ReSyncCalibrationAction())
    }

    public reSync(pm: UploadQueue): void {
        this.store.dispatch(new ReSyncCalibrationAction(pm.workOrderNumber, pm.notificationNumber, pm.equipmentId))
    }
}
