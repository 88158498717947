<div class="em-c-tooltip em-c-tooltip--light em-is-active"
     [ngClass]="{'em-c-tooltip--below': featureTips.tips[tipIndex].position === 'bottom'}">
    <ng-container *ngTemplateOutlet="template"></ng-container>
    <div class="em-c-tooltip__content">
        <div class="em-c-card">
            <div class="em-c-media-block em-c-media-block--small">
                <div class="em-c-media-block__body">
                    <h2 class="em-c-media-block__headline ">
                        {{ featureTips.tips[tipIndex].title }}
                    </h2>
                    <p class="em-c-media-block__desc">
                        {{ featureTips.tips[tipIndex].message }}
                    </p>
                    <button *ngIf="isLastTips"
                            class="em-c-btn em-c-btn--primary"
                            (click)="acknowledgeTip()">Got it</button>
                    <button *ngIf="!isLastTips"
                            class="em-c-btn em-c-btn--primary"
                            (click)="acknowledgeTip()">Next</button>
                    <button *ngIf="!isLastTips"
                            class="em-c-btn"
                            (click)="skipFeatureTips()">Skip</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="dark-background"></div>