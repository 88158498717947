import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { AccessPolicyDirective } from './auth/access-policy.directive'
import { CalibrationInputReportDirective } from './calibration/calibration-input-report.directive'
import { DatePickerDisplayReportDirective } from './calibration/date-picker-display-report.directive'
import { GenericDisplayReportDirective } from './calibration/generic-display-report.directive'
import { InputDisplayReportDirective } from './calibration/input-display-report.directive'
import { InputValidatorDirective } from './calibration/input-validator.directive'
import { MultipleSelectorDisplayReportDirective } from './calibration/multiple-selector-display-report.directive'
import { ClickStopPropagationDirective } from './common/click-stop-propagation.directive'
import { CopyToInputDirective } from './common/copy-to-input.directive'
import { DisableReactiveFormControlDirective } from './common/disable-reactive-form-control.directive'
import { FeatureToggleDirective } from './common/feature-toggle.directive'
import { HoverTooltipDirective } from './common/hover-tooltip.directive'
import { LetDirective } from './common/let.directive'
import { OnPasteCommaSeparatorDirective } from './common/on-paste-comma-separator.directive'
import { PreventFormSubmitOnEnterDirective } from './common/prevent-form-submit-on-enter.directive'
import { ToggleActiveDirective } from './common/toggle-active.directive'
import { ToolTipDirective } from './common/tooltip.directive'
import { RectGhostLoadingIfDirective } from './mrma-ui-components/rect-ghost-loading-if.directive'
import { ResTableColumnDirective } from './mrma-ui-components/res-table-column.directive'
import { ResTableNewRowDirective } from './mrma-ui-components/res-table-new-row.directive'

const directives = [
    CalibrationInputReportDirective,
    DatePickerDisplayReportDirective,
    GenericDisplayReportDirective,
    InputDisplayReportDirective,
    InputValidatorDirective,
    MultipleSelectorDisplayReportDirective,
    ClickStopPropagationDirective,
    FeatureToggleDirective,
    ToggleActiveDirective,
    RectGhostLoadingIfDirective,
    ResTableNewRowDirective,
    ResTableColumnDirective,
    PreventFormSubmitOnEnterDirective,
    OnPasteCommaSeparatorDirective,
    LetDirective,
    AccessPolicyDirective,
    DisableReactiveFormControlDirective,
    CopyToInputDirective,
    HoverTooltipDirective,
    ToolTipDirective
]

@NgModule({
    declarations: [
        ...directives,
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ...directives,
        HoverTooltipDirective
    ]
})
export class DirectivesModule { }
