import { DragDropModule } from '@angular/cdk/drag-drop'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { DatePickerComponent } from '@app/modules/shared/components/date-picker/date-picker.component'
import { DropdownNoFormComponent } from '@app/modules/shared/components/dropdown/dropdown-no-form.component'
import { HyperlinkInfoComponent } from '@app/modules/shared/components/hyperlink-info/hyperlink-info.component'
import { HyperlinkListModalComponent } from '@app/modules/shared/components/hyperlink-info/hyperlink-list-modal/hyperlink-list-modal.component'
import { InputComponent } from '@app/modules/shared/components/input/input.component'
import { OptionsComponent } from '@app/modules/shared/components/options/options.component'
import { SearchComponent } from '@app/modules/shared/components/search/search.component'
import { NilDisplayPipe } from '@app/modules/shared/pipes/nil-display.pipe'
import { DirectivesModule } from '../directives/directives.module'
import { MrmaUiComponentsModule } from '../mrma-ui-components/mrma-ui-components.module'
import { WebComponentModule } from '../web-component/web-component.module'
import { APIPagedListComponent } from './components/api-paged-list/api-paged-list.component'
import { AttachmentComponent } from './components/attachment/attachment.component'
import { CalibrationResultSelectorComponent } from './components/calibration-result-selector.component/calibration-result-selector.component'
import { CheckboxGroupComponent } from './components/checkbox-group/checkbox-group.component'
import { CombinedFilterComponent } from './components/combined-filter/combined-filter.component'
import { DataTableComponent } from './components/data-table/data-table.component'
import { DatePickerFormComponent } from './components/date-picker-form/date-picker-form.component'
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component'
import { DropdownCheckboxComponent } from './components/dropdown-checkbox/dropdown-checkbox.component'
import { DropdownCustomizeContentComponent } from './components/dropdown-customize-content/dropdown-customize-content.component'
import { DropdownFilterComponent } from './components/dropdown-filter/dropdown-filter.component'
import { DropdownSearchComponent } from './components/dropdown-search/dropdown-search.component'
import { DropdownSortingComponent } from './components/dropdown-sorting/dropdown-sorting.component'
import { DropdownViewMoreInfoComponent } from './components/dropdown-view-more-info/dropdown-view-more-info.component'
import { DropdownComponent } from './components/dropdown/dropdown.component'
import { EquipmentExternalLinkModalComponent } from './components/equipment-external-link-container/equipment-external-link-modal.component'
import { EquipmentSortStackComponent } from './components/equipment-sort-stack/equipment-sort-stack.component'
import { EquipmentStackInfoComponent } from './components/equipment-stack-info/equipment-stack-info.component'
import { ExpandableLabelComponent } from './components/expandable-label/expandable-label.component'
import { FeetInchesInch16Component } from './components/feet-inches-inch16/feet-inches-inch16.component'
import { GRTFooterBarComponent } from './components/grt-footer-bar/grt-footer-bar.component'
import { HoverdownComponent } from './components/hoverdown/hoverdown.component'
import { InputFormComponent } from './components/input-form/input-form.component'
import { LabelComponent } from './components/label/label.component'
import { LoadingLabelComponent } from './components/label/loading-label/loading-label.component'
import { ListComponent } from './components/list/list.component'
import { MessageFooterBarComponent } from './components/message-footer-bar/message-footer-bar.component'
import { MultipleSelectorComponent } from './components/multiple-selector/multiple-selector.component'
import { NotificationStatusComponent } from './components/notification-status/notification-status.component'
import { OfflineAvailableStatusComponent } from './components/offline-available-status/offline-available-status.component'
import { OfflineUploadStatusQueueComponent } from './components/offline-upload-status-queue/offline-upload-status-queue.component'
import { OverlayEquipmentDetailsComponent } from './components/overlay-view-more-info/overlay-equipment-details/overlay-equipment-details.component'
import { OverlayHeaderInfoComponent } from './components/overlay-view-more-info/overlay-header-info.component'
import { OverlayHyperlinksComponent } from './components/overlay-view-more-info/overlay-hyperlinks/overlay-hyperlinks.component'
import { OverlayLongtextDetailsComponent } from './components/overlay-view-more-info/overlay-longtext-details/overlay-longtext-details.component'
import {
    OverlayMaintenanceHistoryComponent
} from './components/overlay-view-more-info/overlay-maintenance-history/overlay-maintenance-history.component'
import {
    OverlayNotetotechDetailsComponent
} from './components/overlay-view-more-info/overlay-notetotech-details/overlay-notetotech-details.component'
import { OverlayWorkorderDetailsComponent } from './components/overlay-view-more-info/overlay-workorder-details/overlay-workorder-details.component'
import { PageContainerComponent } from './components/page-container/page-container.component'
import { PagedListCheckboxComponent } from './components/paged-list-checkbox/paged-list-checkbox.component'
import { PagedListComponent } from './components/paged-list/paged-list.component'
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component'
import { RadioHorizontalComponent } from './components/radio-horizontal/radio-horizontal.component'
import { ReportCombinedFilterComponent } from './components/report-combined-filter/report-combined-filter.component'
import { ReportDownloadComponent } from './components/report-download/report-download.component'
import { ResultListComponent } from './components/result-list/result-list.component'
import { SectionBreakComponent } from './components/section-break/section-break.component'
import { SectionLoaderComponent } from './components/section-loader/section-loader.component'
import { SectionComponent } from './components/section/section.component'
import { SelectionDrawerComponent } from './components/selection-drawer/selection-drawer.component'
import { AutocompleteComponent } from './components/selection/autocomplete/autocomplete.component'
import { WithToggleComponent } from './components/selection/with-toggle/with-toggle.component'
import { SlideUpOverlayComponent } from './components/slide-up-overlay/slide-up-overlay.component'
import { SortingCaretComponent } from './components/sorting-caret/sorting-caret.component'
import { StarCheckComponent } from './components/star-check/star-check.component'
import { SwitchComponent } from './components/switch/switch.component'
import { TabPanelComponent } from './components/tab-panel/tab-panel.component'
import { TooltipsComponent } from './components/tooltips/tooltips.component'
import { UnityIconComponent } from './components/unity-icon/unity-icon.component'
import { WorkOrderInfoComponent } from './components/work-order-info/work-order-info.component'
import { MathAbsolutePipe } from './pipes/math-absolute.pipe'
import { MinMaxPipe } from './pipes/min-max.pipe'
import { OrdinalPipe } from './pipes/ordinal.pipe'
import { SafeHtml } from './pipes/safe-html.pipe'
import { SelectStorePipe } from './pipes/select-store.pipe'
import { ToReadableTestingDirection } from './pipes/to-readable-testing-direction.pipe'
import { TruncatePipe } from './pipes/truncate.pipe'
import { ParseJsonPipe } from '@app/modules/shared/pipes/parse-json.pipe'

const sharedComponents = [
    TabPanelComponent,
    PageContainerComponent,
    DatePickerComponent,
    DropdownCheckboxComponent,
    DropdownFilterComponent,
    OptionsComponent,
    SearchComponent,
    InputComponent,
    DropdownComponent,
    DropdownNoFormComponent,
    ExpandableLabelComponent,
    LabelComponent,
    ListComponent,
    PagedListComponent,
    APIPagedListComponent,
    SectionLoaderComponent,
    StarCheckComponent,
    DropdownSearchComponent,
    DropdownCustomizeContentComponent,
    DatePickerFormComponent,
    InputFormComponent,
    CalibrationResultSelectorComponent,
    SectionComponent,
    DataTableComponent,
    ResultListComponent,
    SortingCaretComponent,
    PagedListCheckboxComponent,
    FeetInchesInch16Component,
    OfflineAvailableStatusComponent,
    SectionBreakComponent,
    SelectionDrawerComponent,
    SlideUpOverlayComponent,
    MultipleSelectorComponent,
    MathAbsolutePipe,
    NilDisplayPipe,
    MinMaxPipe,
    OrdinalPipe,
    ToReadableTestingDirection,
    DropdownSortingComponent,
    RadioHorizontalComponent,
    ProgressBarComponent,
    GRTFooterBarComponent,
    MessageFooterBarComponent,
    HoverdownComponent,
    EquipmentSortStackComponent,
    EquipmentStackInfoComponent,
    OfflineUploadStatusQueueComponent,
    AttachmentComponent,
    DropdownViewMoreInfoComponent,
    OverlayLongtextDetailsComponent,
    OverlayWorkorderDetailsComponent,
    OverlayEquipmentDetailsComponent,
    OverlayMaintenanceHistoryComponent,
    OverlayNotetotechDetailsComponent,
    OverlayHeaderInfoComponent,
    OverlayHyperlinksComponent,
    CombinedFilterComponent,
    DateRangePickerComponent,
    ReportDownloadComponent,
    ReportCombinedFilterComponent,
    TruncatePipe,
    SelectStorePipe,
    NotificationStatusComponent,
    CheckboxGroupComponent,
    AutocompleteComponent,
    UnityIconComponent,
    WorkOrderInfoComponent,
    WithToggleComponent,
    LoadingLabelComponent,
    HyperlinkInfoComponent,
    HyperlinkListModalComponent,
    WithToggleComponent,
    SwitchComponent,
    TooltipsComponent,
    EquipmentExternalLinkModalComponent,
    ParseJsonPipe
]

const sharedModules = [
    MrmaUiComponentsModule,
    DirectivesModule
]

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        WebComponentModule,
        ...sharedModules
    ],
    declarations: [
        ...sharedComponents,
        SafeHtml
    ],
    exports: [
        ...sharedComponents,
        SafeHtml,
        FormsModule,
        ReactiveFormsModule,
        WebComponentModule,
        ...sharedModules
    ]
})
export class SharedModule { }
