import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
    selector: 'app-loading-label',
    templateUrl: './loading-label.component.html',
    styleUrls: ['./loading-label.component.scss']
})
export class LoadingLabelComponent {

    @Input() isLoading = false
    @Input() hasError = false
    @Output() clickErrorLabel = new EventEmitter()

    public onClickErrorLabel(): void {
        this.clickErrorLabel.emit()
    }
}
