import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { scw } from '@app/utils/classes/simple-changes-wrapper'
import { trim } from 'lodash'

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent extends SafeUnsubscriberComponent implements OnInit, OnChanges, OnDestroy {
    public _searchText = ''
    @Input() placeHolder: string
    @Input() callback: any
    @Input() inputType = 'text'
    @Input() width = 'auto'
    @Input() showButton = true

    @Input() debounceTimeMs = 300
    @Output() searchTextChange = new EventEmitter<string>()
    @Output() searchTextChangeWithDebounce = new EventEmitter<string>()

    @Output() searchTextSubmit = new EventEmitter<string>()
    private debouncer = new Subject<string>()
    private debouncerSubscription: Subscription

    @Input() set searchText(value: string) {
        this._searchText = value
    }

    get trimmedSearchText(): string {
        return trim(this._searchText)
    }

    ngOnInit(): void {
        this.debouncerSubscription = this.debouncer.pipe(debounceTime(this.debounceTimeMs)).subscribe((text) => {
            this.searchTextChangeWithDebounce.next(trim(text))
        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        scw(changes)
            .forChangeIn('debounceTimeMs')
            .do(() => {
                this.debouncerSubscription?.unsubscribe()
                this.debouncerSubscription = this.debouncer.pipe(debounceTime(this.debounceTimeMs)).subscribe((text) => {
                    this.searchTextChangeWithDebounce.next(trim(text))
                })
            })
    }

    ngOnDestroy(): void {
        super.ngOnDestroy()
        this.debouncerSubscription?.unsubscribe()
    }

    public onSubmit(event: Event): void {
        event.preventDefault()
        if (typeof this.callback === 'function') {
            this.callback(this.trimmedSearchText)
        }
        this.searchTextSubmit.next(this.trimmedSearchText)
    }

    public onSearchTextChange(text: string): void {
        this._searchText = text
        this.searchTextChange.next(this.trimmedSearchText)
        this.debouncer.next(trim(this.trimmedSearchText))
    }
}
