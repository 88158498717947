import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-result-set-header',
    templateUrl: './result-set-header.component.html',
    styleUrls: ['./result-set-header.component.scss']
})
export class ResultSetHeaderComponent {
    @Input() template: any
    @Input() hasInjectedInput: boolean
    @Input() isAsFound: boolean
}
