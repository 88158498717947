<app-admin-form-row [formGroup]="temperatureTemplateForm"
                    label="PROCESS *">
    <div class="em-l-grid__item admin-input-width">
        <app-dropdown [form]="temperatureTemplateForm"
                      [formControlPath]="'process'"
                      [options]="(processes$ | async)"
                      [key]="'id'"
                      [value]="'name'">
        </app-dropdown>
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="temperatureTemplateForm"
                    label="TEMPLATE ALIAS">
    <div class="em-l-grid__item admin-input-width">
        <input formControlName="alias"
               type="text"
               class="em-c-input em-u-font-style-regular"
               maxlength="20">
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="temperatureTemplateForm"
                    label="NUMBER OF POINTS *">
    <div class="em-l-grid__item admin-input-width">
        <app-dropdown [form]="temperatureTemplateForm.controls.numberOfPoints"
                      [formControlPath]="'point'"
                      [options]="numberOfPointOptions"
                      [key]="'key'"
                      [value]="'value'">
        </app-dropdown>
    </div>
</app-admin-form-row>
<br>
<app-admin-form-row [formGroup]="temperatureTemplateForm"
                    label="INPUT RANGE *"
                    description="(Measurement Range)">
    <div class="em-l-grid__item admin-input-width">
        <app-dropdown [form]="temperatureTemplateForm.controls.input"
                      [formControlPath]="'unitOfMeasurement'"
                      [options]="(uomList$ | async)"
                      [key]="'uomCode'"
                      [value]="mapUomObjToUomDisplay">
        </app-dropdown>
    </div>
    <div class="em-l-grid__item admin-input-width">
        <div formGroupName="input"
             class="em-l-grid em-l-grid--2up em-c-field"
             [ngClass]="{'em-is-disabled': temperatureTemplateForm.disabled}">
            <div class="em-l-grid__item em-u-padding-top-none">
                <app-numeric-input formControlName="minimumRange"
                                   [clearZeroOnFocus]="true">
                </app-numeric-input>
            </div>
            <div class="em-l-grid__item em-u-padding-top-none">
                <app-numeric-input formControlName="maximumRange"
                                   [clearZeroOnFocus]="true">
                </app-numeric-input>
            </div>
        </div>
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="temperatureTemplateForm"
                    label="EXPECTED OUTPUT RANGE *"
                    description="(DCS Range)">
    <div class="em-l-grid__item admin-input-width">
        <app-dropdown [form]="temperatureTemplateForm.controls.expected"
                      [formControlPath]="'unitOfMeasurement'"
                      [options]="(uomList$ | async)"
                      [key]="'uomCode'"
                      [value]="mapUomObjToUomDisplay">
        </app-dropdown>
    </div>
    <div class="em-l-grid__item admin-input-width">
        <div formGroupName="expected"
             class="em-l-grid em-l-grid--2up em-c-field"
             [ngClass]="{'em-is-disabled': temperatureTemplateForm.disabled}">
            <div class="em-l-grid__item em-u-padding-top-none">
                <app-numeric-input formControlName="minimumRange"
                                   [clearZeroOnFocus]="true">
                </app-numeric-input>
            </div>
            <div class="em-l-grid__item em-u-padding-top-none">
                <app-numeric-input formControlName="maximumRange"
                                   [clearZeroOnFocus]="true">
                </app-numeric-input>
            </div>
        </div>
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="temperatureTemplateForm"
                    label="TOLERANCE *">
    <div class="em-l-grid__item admin-input-width">
        <app-dropdown *ngIf="selectiveUOMList.length > 0"
                      [form]="temperatureTemplateForm.controls.tolerance"
                      [formControlPath]="'unitOfMeasurement'"
                      [options]="selectiveUOMList"
                      [key]="'uomCode'"
                      [value]="mapUomObjToUomDisplay">
        </app-dropdown>
        <app-dropdown *ngIf="selectiveUOMList.length === 0"
                      [form]="temperatureTemplateForm.controls.tolerance"
                      [formControlPath]="'unitOfMeasurement'"
                      [options]="(uomList$ | async)"
                      [key]="'uomCode'"
                      [value]="mapUomObjToUomDisplay">
        </app-dropdown>
    </div>
    <div formGroupName="tolerance"
         class="em-l-grid__item admin-input-width em-c-field"
         [ngClass]="{'em-is-disabled': temperatureTemplateForm.disabled}">
        <app-numeric-input formControlName="value"
                           [clearZeroOnFocus]="true">
        </app-numeric-input>
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="temperatureTemplateForm"
                    label="PROCEDURE">
    <div class="em-l-grid__item admin-input-width em-c-field"
         [ngClass]="{'em-is-disabled': temperatureTemplateForm.disabled}">
        <input formControlName="procedure"
               type="text"
               placeholder="B4P-RXG-XXX.doc"
               class="em-c-input em-u-font-style-regular">
    </div>
</app-admin-form-row>
<br>
<app-admin-form-row [formGroup]="temperatureTemplateForm"
                    label="INSTRUMENT REQUIRED">
    <div class="em-l-grid__item admin-input-width">
        <app-options [formGroup]="temperatureTemplateForm"
                     [formControlPath]="'isInstrumentRequired'"
                     [defaultOption]="getOption(temperatureTemplateForm.controls.isInstrumentRequired.value, yesNoOption)"
                     [labelStyle]="'em-u-width-50'"
                     [disabled]="temperatureTemplateForm.disabled"
                     [options]="yesNoOption">
        </app-options>
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="temperatureTemplateForm"
                    label="TEST EQUIPMENT"
                    description="(Choose 'Required' if you want technician to select test equipment before completing the work)">
    <div class="em-l-grid__item admin-input-width">
        <app-options [formGroup]="temperatureTemplateForm"
                     [formControlPath]="'isTestEquipmentRequired'"
                     [defaultOption]="getOption(temperatureTemplateForm.controls.isTestEquipmentRequired.value, isRequiredOption)"
                     [labelStyle]="'em-u-width-50'"
                     [disabled]="temperatureTemplateForm.disabled"
                     [options]="isRequiredOption">
        </app-options>
    </div>
</app-admin-form-row>
