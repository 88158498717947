<nav id="global-nav"
     class="em-c-global-nav"
     [ngClass]="{
         'is-offline': !offlineService?.isOnline,
         'sticky-bg': shouldShowBackground
        }"
     role="navigation"
     #statusBar>
    <ul class="em-c-global-nav__list">
        <li class="em-c-global-nav__item em-c-global-nav__link">
            <div id="wifi-icon">
                <svg class="em-c-icon em-c-icon--small em-c-btn__icon">
                    <title>{{ offlineService.isOnline ? 'Online' : 'Offline' }}</title>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink"
                         xlink:href="assets/unity-1.2.0/images/em-icons.svg#wifi"></use>
                </svg>
            </div>
            <span class="em-c-online-label">
                {{ offlineService.isOnline ? 'Online' : 'Offline' }}
            </span>
            <!-- <span class="em-c-version-label">v.{{version}}</span> -->
        </li>
        <li class="em-c-global-nav__item">
            <app-sync-status-list></app-sync-status-list>
        </li>
        <li class="em-c-global-nav__item em-is-aligned-right"></li>

        <!-- TODO: Allow download more detailed logs using this button to send manually to devs -->
        <li class="em-c-global-nav__item em-u-margin-right-none">
            <a class="em-c-global-nav__link em-u-display-inline-block">
                <span class="em-c-version-label">
                    V.{{version}} - {{envName}}
                </span>
                <span *ngIf="appMonitoringService.sessionId as logSessionId">
                    ({{logSessionId | slice: 0:6}})
                </span>
            </a>
        </li>
        <ng-container *ngIf="user$ | async as user">
            <li class="em-c-global-nav__item em-u-margin-right-none">
                <a class="em-c-global-nav__link em-u-display-inline-block dd-privacy-mask">
                    {{ user.displayName }}
                </a>
            </li>
            <li class="em-c-global-nav__item em-u-margin-right-none">
                <a class="em-c-global-nav__link em-u-clickable"
                   (click)="logout()">Logout</a>
            </li>
            <li class="em-c-global-nav__item em-u-margin-right-none">
                <app-plant-selection></app-plant-selection>
            </li>
        </ng-container>
    </ul>
</nav>