import { Component, Input } from '@angular/core'

import { Equipment } from '@app/modules/equipment/models/equipment.model'
import { SlideUpOverlayType } from '@app/modules/shared/constants/slide-up-overlay.enum'

@Component({
    selector: 'app-overlay-equipment-details ',
    templateUrl: './overlay-equipment-details.component.html',
    styleUrls: ['./overlay-equipment-details.component.scss']
})
export class OverlayEquipmentDetailsComponent {
    @Input() equipmentDetail: Equipment

    public overlayId = SlideUpOverlayType.EquipmentDetails
}
