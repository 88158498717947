import { Component, Input } from '@angular/core'

import { CalibrationSummary } from '@app/modules/work-order/models/calibration-summary.model'

@Component({
    selector: 'app-work-order-calibration-summary',
    templateUrl: './work-order-calibration-summary.component.html',
    styleUrls: ['./work-order-calibration-summary.component.scss']
})
export class WorkOrderCalibrationSummaryComponent {
    @Input() calibrationSummary: CalibrationSummary[]

    public get allCount(): number {
        return this.calibrationSummary.length
    }

    public get completeCountLabel(): string {
        return `${this.completedCount} of ${this.allCount} Completed`
    }

    public get completedCount(): number {
        return this.calibrationSummary
            .filter(e => (e.status ? e.status.toLowerCase() : '').includes('completed'))
            .length
    }

    public get passedCount(): number {
        return this.getResultCount('Passed')
    }

    public get failedAdjustedCount(): number {
        return this.getResultCount('Failed Adjusted Passed')
    }

    public get failedCount(): number {
        return this.getResultCount('Failed')
    }

    public get deferredCount(): number {
        return this.getResultCount('Deferred')
    }

    public get notTestedCount(): number {
        return this.getResultCount('Not Tested')
    }

    public get passedAdjustedPassedCount(): number {
        return this.getResultCount('Passed Adjusted Passed')
    }

    private getResultCount(result: string): number {
        return this.calibrationSummary
            .filter(e => e.result === result)
            .length
    }
}
