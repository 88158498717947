import { UserProfile } from '@app/models/user.model'
import { PermissionService } from '@app/modules/shared/services/permission.service'
import { AppPolicyType } from './policy-type.enum'
import { Policy } from './policy.model'

export class AdminOrSupportAccessPolicy extends Policy {
    public readonly type = AppPolicyType.AdminOrSupportAccess

    public hasPolicy(permissionService: PermissionService, user: UserProfile, plantCode: string): boolean {
        const isAccessible =
            permissionService.isAdminOrSupport(user, plantCode)

        return isAccessible
    }
}

export class AdminPolicy extends Policy {
    public readonly type = AppPolicyType.AdminFullAccess

    public hasPolicy(permissionService: PermissionService, user: UserProfile, plantCode: string): boolean {
        const isAccessible =
            permissionService.isAdmin(user, plantCode)

        return isAccessible
    }
}

export class EngineerPolicy extends Policy {
    public readonly type = AppPolicyType.EngineerAccess

    public hasPolicy(permissionService: PermissionService, user: UserProfile, plantCode: string): boolean {
        const isAccessible =
            permissionService.isAdmin(user, plantCode) ||
            permissionService.isEngineer(user, plantCode)

        return isAccessible
    }
}

export class TechnicianPolicy extends Policy {
    public readonly type = AppPolicyType.TechnicianAccess

    public hasPolicy(permissionService: PermissionService, user: UserProfile, plantCode: string): boolean {
        const isAccessible =
            permissionService.isTechnician(user, plantCode)

        return isAccessible
    }
}
