import { Component, Input } from '@angular/core'

import { SlideUpOverlayType } from '@app/modules/shared/constants/slide-up-overlay.enum'

@Component({
    selector: 'app-overlay-notetotech-details',
    templateUrl: './overlay-notetotech-details.component.html',
    styleUrls: ['./overlay-notetotech-details.component.scss']
})
export class OverlayNotetotechDetailsComponent {
    @Input() equipmentId: string
    @Input() equipmentDescription: string
    @Input() note: string

    public overlayId = SlideUpOverlayType.NoteToTechnician
}
