import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'

import { AppPolicyType } from '@app/models/app-policy/policy-type.enum'
import { PolicyCheckerService } from '@app/services/policy-checker.service'

@Directive({
    selector: '[appAccessPolicy]'
})
export class AccessPolicyDirective implements OnInit {
    @Input('appAccessPolicy') policy: AppPolicyType

    constructor(
        private policyCheckerService: PolicyCheckerService,
        private templateRef: TemplateRef<any>,
        private vcRef: ViewContainerRef
    ) { }

    ngOnInit(): void {
        const accessible = this.policyCheckerService.isValid(this.policy)

        if (!accessible) {
            this.vcRef.clear()
        } else {
            this.vcRef.createEmbeddedView(this.templateRef)
        }
    }
}
