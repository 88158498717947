<app-main-container>
    <app-detailed-page-header titleMain="Custom Form Management">
        <ng-template #rightToolbar>
            <div *appAccessPolicy="engineerAccessPolicy">
                <app-icon-button *appFeatureToggle="'flamingo-builder'"
                                 unitySvgName="circle-plus"
                                 [routerLink]="['new-form']">Add New Form</app-icon-button>
            </div>
        </ng-template>
    </app-detailed-page-header>
    <app-flamingo-list-table [clickable]="isClickable"
                             (flamingoFormClicked)="flamingoFormClick($event)"></app-flamingo-list-table>
</app-main-container>