<div class="slide-up-overlay">
    <app-overlay-header-info [header]="'Equipment Details'"
                             [subHeader]="'Equipment'"
                             [itemId]="equipmentDetail?.equipmentId"
                             [itemDescription]="equipmentDetail?.description"
                             [overlayId]="overlayId"></app-overlay-header-info>
    <div class="body">
        <div class="em-l-container">
            <hr class="details-line">
            <div class="web-single-line">
                <div class="calibration-info">
                    <label>Equipment ID</label>
                    <p> {{equipmentDetail?.equipmentId || '-'}} </p>
                </div>
                <div class="calibration-info">
                    <label>Equipment Tag</label>
                    <p> {{equipmentDetail?.equipmentTag || '-'}} </p>
                </div>
                <div class="calibration-info">
                    <label>Equipment Tech ID</label>
                    <p> {{equipmentDetail?.techIdentificationNumber || '-'}} </p>
                </div>
                <div class="calibration-info">
                    <label>Functional Location</label>
                    <p> {{equipmentDetail?.functionalLocation || '-'}} </p>
                    <h6 class="description">{{equipmentDetail?.functionalLocationDesc}}</h6>
                </div>
            </div>
            <hr class="details-line responsive-line">
            <div class="web-single-line light-grey-background">
                <div class="calibration-info">
                    <label>System Status</label>
                    <p> {{equipmentDetail?.systemStatus || '-'}} </p>
                    <h6 class="description">{{equipmentDetail?.systemStatusDesc}}</h6>
                </div>
                <div class="calibration-info">
                    <label>User Status</label>
                    <p> {{equipmentDetail?.userStatus || '-'}} </p>
                    <h6 class="description">{{equipmentDetail?.userStatusDesc}}</h6>
                </div>
                <!-- Add User Status without Status No. later on -->
                <!-- <div class="calibration-info">
                    <label>User Status without Status No.</label>
                    <p> NOT INCLUDED YET </p>
                    <h6 class="description">Description</h6>
                </div> -->
                <div class="calibration-info">
                    <label>Criticality</label>
                    <p> {{equipmentDetail?.abcIndicator || '-'}} </p>
                    <h6 class="description">{{equipmentDetail?.criticalityDesc}}</h6>
                </div>
            </div>
            <hr class="details-line">
            <div class="web-single-line">
                <div class="calibration-info">
                    <label>Planning Plant</label>
                    <p> {{equipmentDetail?.plantCode || '-'}} </p>
                    <h6 class="description">{{equipmentDetail?.plantCodeDesc}}</h6>
                </div>
                <div class="calibration-info">
                    <label>Planner Group</label>
                    <p> {{equipmentDetail?.plannerGroup || '-'}} </p>
                    <h6 class="description">{{equipmentDetail?.plannerGroupDesc}}</h6>
                </div>
                <div class="calibration-info">
                    <label>Main Work Center</label>
                    <p> {{equipmentDetail?.mainWorkCenter || '-'}} </p>
                    <h6 class="description">{{equipmentDetail?.mainWorkCenterDesc}}</h6>
                </div>
                <div class="calibration-info">
                    <label>Catalog Profile</label>
                    <p> {{equipmentDetail?.catalogProfile || '-'}} </p>
                    <h6 class="description">{{equipmentDetail?.catalogProfileDesc}}</h6>
                </div>
            </div>
            <hr class="details-line responsive-line">
            <div class="web-single-line light-grey-background">
                <div class="calibration-info">
                    <label>Maintenance Plant</label>
                    <p> {{equipmentDetail?.maintenancePlant || '-'}} </p>
                    <h6 class="description">{{equipmentDetail?.maintenancePlantDesc}}</h6>
                </div>
                <div class="calibration-info">
                    <label>Plant Section</label>
                    <p> {{equipmentDetail?.plantSection || '-'}} </p>
                    <h6 class="description">{{equipmentDetail?.plantSectionDesc}}</h6>
                </div>
                <div class="calibration-info">
                    <label>Location</label>
                    <p> {{equipmentDetail?.location || '-'}} </p>
                    <h6 class="description">{{equipmentDetail?.locationDesc}}</h6>
                </div>
            </div>
            <hr class="details-line">
            <div class="web-single-line">
                <div class="calibration-info">
                    <label>Category</label>
                    <p> {{equipmentDetail?.category || '-'}} </p>
                    <h6 class="description">{{equipmentDetail?.categoryDesc}} </h6>
                </div>
                <div class="calibration-info">
                    <label>Classification</label>
                    <p> {{equipmentDetail?.classification || '-'}} </p>
                    <h6 class="description">{{equipmentDetail?.classificationDesc}}</h6>
                </div>
            </div>
            <hr class="details-line responsive-line">
            <div class="web-single-line light-grey-background">
                <div class="calibration-info">
                    <label>Serial</label>
                    <p> {{equipmentDetail?.serial || '-'}} </p>
                </div>
                <div class="calibration-info">
                    <label>Model</label>
                    <p> {{equipmentDetail?.model || '-'}} </p>
                </div>
                <div class="calibration-info">
                    <label>Manufacturer</label>
                    <p> {{equipmentDetail?.manufacturer || '-'}} </p>
                </div>
            </div>
        </div>
    </div>
</div>