<section *ngIf="!isOnMobileOrTabletDevice">
    <div class="em-l-grid em-l-grid--4up">
        <div class="em-l-grid__item">
            <app-label title="Set Point"
                       [subtitle]="setPointUoM">
                <div>
                    {{ template.setPoint.value }}
                </div>
            </app-label>
        </div>
        <div class="em-l-grid__item">
            <app-label title="Expected reading"
                       [subtitle]="expectedReadingUoM">
                <div>
                    {{ template.expectedReading.value }}
                </div>
            </app-label>
        </div>
        <div class="em-l-grid__item">
            <app-label title="Tolerance"
                       [subtitle]="toleranceUoM">
                <div>
                    {{ template.tolerance.lowerRange }} to {{ template.tolerance.higherRange }}
                </div>
            </app-label>
        </div>
        <div class="em-l-grid__item">
            <app-label title="Testing Direction">
                <div>
                    {{ getTestingDirection() }}
                </div>
            </app-label>
        </div>
    </div>
    <hr class="details-line">
    <div *ngFor="let point of resultSetControl.controls; let i = index">
        <div class="em-l-grid em-l-grid--4up">
            <div class="em-l-grid__item">
                <app-label title="Expected"
                           [subtitle]="expectedReadingUoM">
                    <div>
                        {{ expectedReadingRange }}
                    </div>
                </app-label>
            </div>
            <div class="em-l-grid__item em-c-field__body">
                <app-label title="As Found"
                           [subtitle]="expectedReadingUoM"
                           [classList]="isDisabled ? 'em-is-disabled' : ''">
                    <app-numeric-input appInputValidator
                                       [input]="validateAsFound(point)"
                                       [appCalibrationInputReport]="isReport"
                                       valueIfEmpty="-"
                                       [formControl]="point.get('asFound')"
                                       (inputBlur)="autoPopulateAsLeft()"
                                       placeholder="0.0">
                    </app-numeric-input>
                </app-label>
            </div>
            <div class="em-l-grid__item em-c-field__body">
                <app-label title="As Left"
                           [subtitle]="expectedReadingUoM"
                           [classList]="isDisabled ? 'em-is-disabled' : ''">
                    <app-numeric-input appInputValidator
                                       [input]="validateAsLeft(point)"
                                       [appCalibrationInputReport]="isReport"
                                       valueIfEmpty="-"
                                       [formControl]="point.get('asLeft')"
                                       placeholder="0.0">
                    </app-numeric-input>
                </app-label>
            </div>
        </div>
    </div>
</section>

<section *ngIf="isOnMobileOrTabletDevice">
    <app-section title="Ranges and Tolerances">
        <div body
             class="responsive-spacing">
            <app-responsive-calibration-input [singleFieldColumn]="true"
                                              [showLineBreak]="true"
                                              [rowLabelHead]="'Set Point ' + setPointUoM"
                                              [rowLabelResult]="template.setPoint.value">
            </app-responsive-calibration-input>
            <app-responsive-calibration-input [singleFieldColumn]="true"
                                              [showLineBreak]="true"
                                              [rowLabelHead]="'Expected reading ' + expectedReadingUoM"
                                              [rowLabelResult]="template.expectedReading.value">
            </app-responsive-calibration-input>
            <app-responsive-calibration-input [singleFieldColumn]="true"
                                              [showLineBreak]="true"
                                              [rowLabelHead]="'Tolerance ' + toleranceUoM"
                                              [rowLabelResult]="template.tolerance.lowerRange + ' to ' + template.tolerance.higherRange">
            </app-responsive-calibration-input>
            <app-responsive-calibration-input [singleFieldColumn]="true"
                                              [rowLabelHead]="'Testing Direction'"
                                              [rowLabelResult]="getTestingDirection()">
            </app-responsive-calibration-input>
        </div>
    </app-section>
    <app-section [title]="'As Found ' + expectedReadingUoM">
        <div body
             class="responsive-spacing">
            <div *ngFor="let point of resultSetControl.controls; let i = index">
                <app-responsive-calibration-input [allowReading]="true"
                                                  [displayInputLabel]="'Reading'"
                                                  [displayResultLabel]="'Expected ' + expectedReadingRange">
                    <div readingInput>
                        <app-numeric-input appInputValidator
                                           [input]="validateAsFound(point)"
                                           [appCalibrationInputReport]="isReport"
                                           valueIfEmpty="-"
                                           [formControl]="point.get('asFound')"
                                           (inputBlur)="autoPopulateAsLeft()"
                                           placeholder="0.0">
                        </app-numeric-input>
                    </div>
                </app-responsive-calibration-input>
            </div>
        </div>
    </app-section>
    <app-section [title]="'As Left ' + expectedReadingUoM">
        <div body
             class="responsive-spacing">
            <div *ngFor="let point of resultSetControl.controls; let i = index">
                <app-responsive-calibration-input [allowReading]="true"
                                                  [displayInputLabel]="'Reading'"
                                                  [displayResultLabel]="'Expected ' + expectedReadingRange">
                    <div readingInput>
                        <app-numeric-input appInputValidator
                                           [input]="validateAsLeft(point)"
                                           [appCalibrationInputReport]="isReport"
                                           valueIfEmpty="-"
                                           [formControl]="point.get('asLeft')"
                                           placeholder="0.0">
                        </app-numeric-input>
                    </div>
                </app-responsive-calibration-input>
            </div>
        </div>
    </app-section>
</section>
