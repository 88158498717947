import { Component, ElementRef, EventEmitter, HostListener, Input, Output, TemplateRef } from '@angular/core'
import { highlightPartText } from '@app/utils/app-utils.function'

@Component({
    selector: 'app-dropdown-customize-content',
    templateUrl: './dropdown-customize-content.component.html',
    styleUrls: ['./dropdown-customize-content.component.scss']
})
export class DropdownCustomizeContentComponent {
    @Input() searchText: string


    @Input() isError: boolean
    @Input() isDisabled: boolean
    @Input() displayField: string
    @Input() disabledField: string
    @Input() placeholder: string
    @Input() customStyle: object
    @Input() customizeSlot: TemplateRef<any>
    @Output() selectedEvent = new EventEmitter()
    @Output() unSelectedEvent = new EventEmitter()
    @Output() inputEvent = new EventEmitter()
    public isExpandDropdown: boolean
    public filteredOptions: any[]
    public highlightPartText = highlightPartText
    private _options: any[]

    constructor(
        private elementRef: ElementRef
    ) {
    }

    @Input() set options(v: any[]) {
        this._options = v
        if (this.searchText) {
            this.filterOptionsBySearchText(this.searchText)
        } else {
            this.filteredOptions = v
        }
    }

    get options(): any[] {
        return this._options
    }

    get showRemoveButton(): boolean {
        return !!this.searchText && !this.isDisabled
    }

    public filterOptionsBySearchText(searchText: any): void {
        this.filteredOptions = this.options
            .filter(
                item => item[this.displayField].toLowerCase().includes(searchText.toLowerCase())
            )
    }

    public select(item: any): void {
        if (this.disabledField && item[this.disabledField]) {
            return
        }
        this.searchText = item[this.displayField]
        this.filterOptionsBySearchText(this.searchText)
        this.selectedEvent.emit(item)
        this.isExpandDropdown = false
    }

    public unselect(): void {
        this.searchText = ''
        this.filterOptionsBySearchText(this.searchText)
        this.unSelectedEvent.emit()
    }

    @HostListener('document:click', ['$event'])
    public toggleDropdown(globalEvent): void {
        if (!this.elementRef.nativeElement.contains(globalEvent.target)) {
            this.isExpandDropdown = false
        }
    }
}
