import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'

import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import { DestroyModalAction } from '@app/store/modal/modal.actions'
import { modal } from '@app/store/modal/modal.selectors'
import { Modal, ModalType } from '@app/modules/modal-container/models/modal.model'
import { AddFilterPresetAction, EditFilterPresetAction } from '@app/store/report/report.actions'
import { DOCUMENT } from '@angular/common'
import { calibrationPresetNameErrorMessage, CalibrationPresets } from '../../constants/report-filter-list.constant'
import { calibrationPresetList } from '@app/store/report/report.selectors'


@Component({
    selector: 'app-report-filter',
    templateUrl: './report-filter.component.html',
    styleUrls: ['./report-filter.component.scss']
})
export class ReportFilterPresetModalComponent extends SafeUnsubscriberComponent implements OnInit, OnDestroy {
    @Input() params: any

    public presetName: string = null
    public title: string = null
    public type: ModalType
    public filterPresetList: CalibrationPresets[]
    public hasErrorOnPresetName: boolean
    public reportFilterPresetNameErrorMessage: string = calibrationPresetNameErrorMessage

    constructor(
        private store: Store<AppState>,
        @Inject(DOCUMENT) private document: any,
    ) {
        super()
    }

    public get disabledSaveBtn(): boolean {
        return this.hasErrorOnPresetName || !Boolean(this.presetName).valueOf()
    }

    ngOnInit(): void {
        this.addSubscriptions(
            [
                this.store.select(modal).subscribe((modalState: Modal) => {
                    if (modalState) {
                        this.title = modalState.title
                        this.type = modalState.type
                    }
                }),
                this.store.select(calibrationPresetList).subscribe((list) => {
                    this.filterPresetList = list
                })
            ]
        )

        this.presetName = this.params?.name
        this.document.addEventListener('click', this.clickHandler)
    }

    ngOnDestroy(): void {
        this.document.removeEventListener('click', this.clickHandler)
    }

    public submit(): void {
        // request save api
        if (this.type === ModalType.ReportNewFilterPreset) {
            this.store.dispatch(new AddFilterPresetAction({ name: this.presetName }))
        }
        if (this.type === ModalType.ReportRenameFilterPreset) {
            this.store.dispatch(new EditFilterPresetAction({ name: this.presetName, id: this.params.id }))
        }
        this.close()
    }

    public close(): void {
        this.store.dispatch(new DestroyModalAction())
    }

    public onInput(event: InputEvent): void {
        const { value } = event.target as HTMLInputElement
        this.hasErrorOnPresetName = this.filterPresetList.some(item => item.name === value)
    }

    private clickHandler(event): void {
        event.stopImmediatePropagation()
    }
}
