<div class="em-l custom-form"
     [ngClass]="{'has-border': hasBorder}">
    <div *ngFor="let formSection of customFormTemplate; trackBy:trackByCustomFormSectionTitle"
         class="em-l__main"
         [ngClass]="{'is-report-page': isReport}">

        <div class="custom-form-section">
            <app-section *ngIf="formSection?.title"
                         [title]="formSection.title">
                <app-custom-form-section body
                                         [formTemplate]="formSection.content"
                                         [formResult]="customFormResult"
                                         [formControls]="formControls"
                                         [isReport]="isReport"></app-custom-form-section>
            </app-section>
        </div>
    </div>
</div>
