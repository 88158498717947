export const defaultMassUpdatePayloadTempalte = {
    equipmentTag: null,
    templateId: null,
    templateTypeId: null,
    processId: 1,
    processName: 'Generic',
    calibrationChecklist: [],
    calibrationTemplate: null,
    testEquipments: [],
    reviewStatusId: 1,
    equipmentReplacedTechnicians: [],
    repairWorkOrderNumber: null,
    equipmentSerialNumber: null,
    equipmentReplacedDate: null,
    replacedEquipmentSerialNumber: null,
    modelNumber: null,
    manufacturer: null,
    replacedModelNumber: null,
    replacedManufacturer: null,
    calibrationResultStatus: null,
    calibrationResult: null,
    calibrationStatus: {
        id: 3,
        name: 'completed'
    },
}
