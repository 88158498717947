import { Component, Input } from '@angular/core'
import { FormControl } from '@angular/forms'
import { allOrFilterRadioOptions } from '@app/modules/shared/constants/filter-config.constant'

@Component({
    selector: 'app-with-toggle',
    templateUrl: './with-toggle.component.html',
    styleUrls: ['./with-toggle.component.scss']
})
export class WithToggleComponent {
    @Input() groupName: string
    @Input() toggleForm: FormControl
    @Input() radioOptions = allOrFilterRadioOptions

    public hasSelect(value): boolean {
        return this.toggleForm.value === value
    }

    public onSelectOption(event, optionValue: any): void {
        if (!this.hasSelect(optionValue) && event.target.checked) {
            this.toggleForm.setValue(optionValue)
        }
    }
}
