import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core'
import { FormControl } from '@angular/forms'
import moment from 'moment'



@Directive({
    selector: '[appDatePickerDisplayReport]'
})
export class DatePickerDisplayReportDirective implements AfterViewInit, OnChanges {
    @Input() formControl: FormControl
    @Input('appDatePickerDisplayReport') isReport: boolean

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (!(changes?.control?.firstChange ?? true)) {
            this.manageContent(changes.control.currentValue.value, this.isReport)
        }
    }

    ngAfterViewInit(): void {
        this.manageContent(this.formControl.value, this.isReport)
    }

    private manageContent(controlValue: string, isReport: boolean): void {
        if (isReport) {
            const spanElement = this.constructSpan(controlValue ? moment(controlValue).format('MMM DD, YYYY') : '-', '')

            this.removeChild(this.el.nativeElement)
            this.renderer.appendChild(this.el.nativeElement, spanElement)
        }
    }

    private removeChild(element: any): void {
        const children = Array.from(element.children)

        children.forEach(child => {
            this.renderer.removeChild(element, child)
        })
    }

    private constructSpan(value: any, defaultValue: any): any {
        const spanElement = this.renderer.createElement('span')
        const text = this.renderer.createText(value ? value : defaultValue)
        this.renderer.appendChild(spanElement, text)

        return spanElement
    }
}
