<div class="table-wrapper">
    <div class="table--header">
        <div class="table-toolbar">
            <button class="em-c-btn btn-mass-update web-view"
                    *ngIf="!isMassUpdateMode"
                    [ngClass]="{'em-c-btn--disabled': !isMassUpdateEnable}"
                    (click)="startMassUpdateMode()">
                <svg class="em-c-icon btn-mass-update--icon">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink"
                         xlink:href="./assets/unity-1.2.0/images/em-icons.svg#edit"></use>
                </svg>
                Mass Update
            </button>
            <div class="select-bar web-view"
                 *ngIf="isMassUpdateMode && searchText.length <= 0">
                <img class="em-c-icon em-c-icon--small em-c-icon-update"
                     src="./assets/images/checkbox-icon.svg" />
                <select #selectedElem
                        class="em-c-btn btn-update-padding"
                        (change)="massUpdateSelectionSelected($event.target.value)">
                    <option hidden
                            selected
                            value="select">
                        Select
                    </option>
                    <option value="all">All ({{allSelectableEqCount}})</option>
                    <option value="new">New ({{newSelectableEqCount}})</option>
                    <option value="deferred">Deferred ({{deferredEqCount}})</option>
                    <option value="not-tested">Not Tested ({{notTestedEqCount}})</option>
                    <option value="deferred-not-tested">Deferred or Not Tested ({{deferredEqCount + notTestedEqCount}})</option>
                    <option value="none">None</option>
                </select>
            </div>
            <div class="search-bar">
                <app-search (searchTextChangeWithDebounce)="applySearch($event)"
                            [placeHolder]="searchBoxPlaceholder"
                            [callback]="applySearch.bind(this)"
                            [showButton]="false"></app-search>
            </div>
        </div>
    </div>
    <div class="table--content">
        <app-list [items]="searchResultView"
                  (clickItem)="equipmentCalibrationSelected($event)">
            <ng-template #header>
                <th *ngIf="isMassUpdateMode"></th>
                <th (click)="applySorting('status')"
                    scope="col"
                    class="em-c-table__header-cell em--no-wrap em-u-width-10">
                    STATUS
                    <app-sorting-caret [isSorted]="getSortedState('status')"
                                       [isDescending]="getDescendingState('status')">
                    </app-sorting-caret>
                </th>

                <th scope="col"
                    class="em-c-table__header-cell em--no-wrap">
                    EQUIPMENT
                    <app-sorting-caret [isSorted]="equipmentStackSortingCaret?.isSorted"
                                       [isDescending]="equipmentStackSortingCaret?.isDescending">
                    </app-sorting-caret>
                    <app-hoverdown>
                        <app-equipment-sort-stack [applySorting]="applySorting.bind(this)"
                                                  [getSortedState]="getSortedState.bind(this)"
                                                  [getDescendingState]="getDescendingState.bind(this)"
                                                  (sortingState)="getEquipmentStackSortingCaretState($event)">
                        </app-equipment-sort-stack>
                    </app-hoverdown>
                </th>
                <th (click)="applySorting('description')"
                    scope="col"
                    class="em-c-table__header-cell em--no-wrap">
                    DESCRIPTION
                    <app-sorting-caret [isSorted]="getSortedState('description')"
                                       [isDescending]="getDescendingState('description')">
                    </app-sorting-caret>
                </th>
                <th (click)="applySorting('abcIndicator')"
                    scope="col"
                    class="em-c-table__header-cell em--no-wrap">
                    CRITICALITY
                    <app-sorting-caret [isSorted]="getSortedState('abcIndicator')"
                                       [isDescending]="getDescendingState('abcIndicator')">
                    </app-sorting-caret>
                </th>
                <th (click)="applySorting('result')"
                    scope="col"
                    class="em-c-table__header-cell em--no-wrap">
                    Result
                    <app-sorting-caret [isSorted]="getSortedState('result')"
                                       [isDescending]="getDescendingState('result')">
                    </app-sorting-caret>
                </th>
                <th (click)="applySorting('equipmentNotificationNumber')"
                    scope="col"
                    class="em-c-table__header-cell em--no-wrap">
                    EQ Notification
                    <app-sorting-caret [isSorted]="getSortedState('equipmentNotificationNumber')"
                                       [isDescending]="getDescendingState('equipmentNotificationNumber')">
                    </app-sorting-caret>
                </th>
                <th scope="col"
                    class="em-c-table__header-cell em--no-wrap"></th>
                <th scope="col"
                    class="em-c-table__header-cell em--no-wrap"
                    *ngIf="!isMassUpdateMode"></th>
            </ng-template>
            <ng-template #content
                         let-item>
                <td *ngIf="isMassUpdateMode"
                    [ngClass]="{
                    'eq-selected': item._viewMeta._isSelected,
                    'eq-disabled-row': !item._viewMeta._selectable
                    }"
                    class="em-c-table__cell em-c-table__cell-custom em-u-width-5 table-cell eq-checkbox-td">
                    <label [for]="item.equipmentId"
                           class="em-c-input-group em-c-checkbox--container">
                        <input class="eq-checkbox em-c-input-group__control"
                               type="checkbox"
                               [ngClass]="{'em-c-btn--disabled': !item._viewMeta._selectable}"
                               [name]="item.equipmentId"
                               [id]="item.equipmentId"
                               [disabled]="!item._viewMeta._selectable"
                               [(ngModel)]="item._viewMeta._isSelected"
                               (click)="equipmentSelected($event)">
                        <span class="em-c-checkbox--checkmark"></span>
                    </label>
                </td>
                <td class="em-c-table__cell em-u-width-10 table-cell">
                    <div [ngClass]="getStatusLabel('calibration-status', item.status).className">
                        {{ getStatusLabel('calibration-status', item.status).statusName }}
                    </div>
                    <div *ngIf="item.dataSource === 'GRT'"
                         class="ng-tns-c182-2 label label-grt ng-star-inserted">
                        {{item.dataSource}}
                    </div>
                </td>
                <td class="em-c-table__cell table-cell">
                    <app-equipment-stack-info [equipment]="item"
                                              [sortBy]="equipmentStackSortingCaret?.sortBy"
                                              [isPrimaryEquipment]="isPrimaryEquipment(item)">
                    </app-equipment-stack-info>
                </td>
                <td class="em-c-table__cell table-cell">
                    <div class="text">{{ item.description }}</div>
                    <div class="label label-warning label-multi table-cell"
                         *ngFor="let action of item.actionRequired">{{ action }}</div>
                </td>
                <td class="em-c-table__cell table-cell">
                    <div class="text">{{ item.abcIndicator }}</div>
                </td>
                <td class="em-c-table__cell table-cell"
                    [ngClass]="{'result-status-text-error': isItemFinalPMResultDeferredOrNotTested(item.result)}">
                    <div class="text">{{ item.result }}</div>
                </td>
                <td class="em-c-table__cell table-cell">
                    <app-notification-status [needTooltip]="true"
                                             [notificationNumber]="getEquipmentNotification(item.equipmentNotificationNumber)"
                                             [showLabel]="false"
                                             [noLink]="true"
                                             notificationType="equipment"></app-notification-status>
                </td>
                <td class="em-c-table__cell table-cell">
                    <div class="template-warning"
                         *ngIf="equipmentErrorStatus(item) > 0">
                        <span *ngIf="equipmentErrorStatus(item) === 1">Sync failed</span>
                        <svg class="em-c-icon em-c-icon--small exclamation">
                            <use xmlns:xlink="http://www.w3.org/1999/xlink"
                                 xlink:href="./assets/unity-1.2.0/images/em-icons.svg#circle-exclamation-mark-filled"></use>
                        </svg>
                    </div>
                </td>
                <td class="em-c-table__cell table-cell"
                    *ngIf="!isMassUpdateMode">
                    <svg class="em-c-icon em-c-icon--medium chevron">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink"
                             xlink:href="./assets/unity-1.2.0/images/em-icons.svg#chevron-right"></use>
                    </svg>
                </td>
                <div class="em-c-card"
                     [ngClass]="{'bookmarked-row-cell' : item?.isBookmarked}">
                    <div class="em-c-card__body">
                        <div class="title grid-container-title">
                            <h2>{{ item.equipmentTag }}</h2>
                            <div>
                                <span *ngIf="isPrimaryEquipment(item)"
                                      class="primary-pill label">Primary</span>
                                <span>
                                    <svg class="em-c-icon em-c-icon--medium chevron">
                                        <use xmlns:xlink="http://www.w3.org/1999/xlink"
                                             xlink:href="./assets/unity-1.2.0/images/em-icons.svg#chevron-right"></use>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div class="em-c-media-block__desc">
                            <div class="grid-container">
                                <span>ID</span>
                                <p>
                                    {{ item.equipmentId }}
                                </p>
                            </div>
                            <div class="grid-container">
                                <span>Tag</span>
                                <p>
                                    {{ item.equipmentTag }}
                                </p>
                            </div>
                            <div class="grid-container">
                                <span>Tech ID</span>
                                <p>
                                    {{ item.techIdentificationNumber }}
                                </p>
                            </div>
                            <div class="grid-container">
                                <span>FLOC.</span>
                                <p>
                                    {{ item.functionalLocation }}
                                </p>
                            </div>
                            <div class="grid-container">
                                <span>Desc.</span>
                                <p>
                                    {{ item.description }}
                                </p>
                            </div>
                            <div class="grid-container">
                                <span>Equipment Notification</span>
                                <app-notification-status [needTooltip]="true"
                                                         [notificationNumber]="getEquipmentNotification(item.equipmentNotificationNumber)"
                                                         [showLabel]="false"
                                                         [noLink]="true"
                                                         notificationType="equipment"
                                                         addStyle="padding: 10px 0;"></app-notification-status>
                            </div>
                            <h3 [ngClass]="getConsolidatedLabelStatus(item).className">
                                {{ getConsolidatedLabelStatus(item).statusName }}
                            </h3>
                        </div>
                    </div>
                </div>
            </ng-template>
        </app-list>
    </div>
    <div class="table--footer"></div>
</div>

<app-message-footer-bar *ngIf="shouldShowGRTBanner && !isMassUpdateMode"
                        [cssClasses]="['message-footer-bar-fixed-to-bottom']"
                        [messageBarClass]="'info'"
                        [description]="calibrationGrtDataSourceMessage"></app-message-footer-bar>

<!-- Mass Update Footer Bar -->
<div *ngIf="isMassUpdateMode"
     [@slideInOut]
     class="mass-update-footer-bar">
    <app-message-footer-bar *ngIf="shouldShowGRTBanner"
                            [messageBarClass]="'info'"
                            [description]="calibrationGrtDataSourceMessage"></app-message-footer-bar>
    <div class="em-l-container mass-update-footer-bar--btn-row">
        <div class="mass-update-footer-bar--btn-container">
            <button class="em-c-btn em-c-btn--primary btn-proceed"
                    [ngClass]="{'em-c-btn--disabled': numEqSelected <= 0 || !offlineService.isOnline}"
                    [disabled]="numEqSelected <= 0"
                    (click)="confirmMassUpdate()">
                Proceed ({{numEqSelected}}
                Equipment)
            </button>
            <button class="em-c-btn"
                    (click)="cancelMassUpdateMode()">Cancel</button>
        </div>
    </div>
</div>