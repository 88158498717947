<div class="em-c-field ">
    <label *ngIf="formDetail?.title"
           class="em-c-field__label">{{ formDetail.title }}</label>
    <div class="em-c-field__body">
        <textarea class="em-c-textarea"
                  rows="5"
                  [id]="formDetail?.id"
                  [placeholder]="formDetail?.placeHolder || ''"
                  [value]="formControl.value"
                  [formControl]="formControl"></textarea>
    </div>
</div>
