import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'

import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import { GetLatestSyncDateAction } from '@app/store/sap-data-sync/sap-data-sync.actions'
import { getSapDataSyncDate } from '@app/store/sap-data-sync/sap-data-sync.selectors'
import { getUserListFetchingProgress, getUserListLastSyncDate } from '@app/store/user/user.selectors'

@Component({
    selector: 'app-sync-status-list',
    templateUrl: './sync-status-list.component.html',
    styleUrls: ['./sync-status-list.component.scss']
})
export class SyncStatusListComponent extends SafeUnsubscriberComponent implements OnInit {

    public isInSyncingState = false
    public lastSync: {
        userListSyncDate: string,
        workOrderSyncDate: string,
        equipmentSyncDate: string,
        maintenancePlanSyncDate: string
    } = {
            userListSyncDate: undefined,
            workOrderSyncDate: undefined,
            equipmentSyncDate: undefined,
            maintenancePlanSyncDate: undefined
        }

    constructor(private store: Store<AppState>) {
        super()
    }

    public get displayUserListSyncDate(): string {
        return this.getSyncDate(this.lastSync?.userListSyncDate)
    }

    public get displayWorkOrderSyncDate(): string {
        return this.getSyncDate(this.lastSync?.workOrderSyncDate)
    }

    public get displayEquipmentSyncDate(): string {
        return this.getSyncDate(this.lastSync?.equipmentSyncDate)
    }

    public get displayMaintenancePlanSyncDate(): string {
        return this.getSyncDate(this.lastSync?.maintenancePlanSyncDate)
    }

    ngOnInit(): void {
        this.addSubscriptions([
            this.store.select(getUserListFetchingProgress).subscribe(isFetching => this.isInSyncingState = isFetching),
            this.store.select(getUserListLastSyncDate).subscribe(lastSyncDate => this.lastSync.userListSyncDate = lastSyncDate),
            this.store.select(getSapDataSyncDate).subscribe(lastSyncDate => {
                this.lastSync.workOrderSyncDate = lastSyncDate?.woLastSyncDate
                this.lastSync.equipmentSyncDate = lastSyncDate?.eqLastSyncDate
                this.lastSync.maintenancePlanSyncDate = lastSyncDate?.lastSyncDate
            })
        ])

        this.store.dispatch(GetLatestSyncDateAction())
    }

    private getSyncDate(syncDate: string): string {
        if (!syncDate) {
            return 'Syncing in progress'
        }

        return (/[0-9]/).test(syncDate) ? new Date(`${syncDate} UTC`).toLocaleString('en-US') : syncDate
    }
}
