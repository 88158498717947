import { Injectable } from '@angular/core'
import { AbstractControl, FormArray, FormGroup } from '@angular/forms'

import { isNotAValue } from '@app/utils/app-utils.function'

@Injectable({
    providedIn: 'root'
})
export class CalibrationValidatorService {
    private hasUncompletedCylinders: boolean

    public allInputResultHasValue(calibration: FormArray): boolean {
        for (const input of calibration.controls) {
            if (isNotAValue(input.value.asFound) || isNotAValue(input.value.asLeft)) {
                return false
            }
        }
        return true
    }

    public areAllInputResultEmpty(calibration: FormArray): boolean {
        for (const control of calibration.controls) {
            if (!isNotAValue(control.value.asFound) || !isNotAValue(control.value.asLeft)) {
                return false
            }
        }
        return true
    }

    /**
     * @deprecated - use `getResultSetFormArray` in `AbstractCalibrationTemplateComponent` instead
     */
    public getResultSetControl(calibrationForm: FormGroup): () => FormArray {
        return () => {
            const calibrationResult = calibrationForm.get('results.calibrationResult.results') as FormArray
            return calibrationResult.controls[0].get('resultSet') as FormArray
        }
    }

    public getFinalPMResultStatusName(calibrationForm: FormGroup): string {
        return calibrationForm.get('results.finalPMResultStatus')?.value?.name ?? '-'
    }

    public getHasIncompleteCylinders(): boolean {
        return this.hasUncompletedCylinders
    }

    public getIsDuplicatedReferenceId(cylinders: FormArray): boolean {
        return this.isSomeIdDuplicated(cylinders)
    }

    public validateCylinderCompletely(cylinder): void {
        this.hasUncompletedCylinders = this.hasUncompletedCylinderForInput(cylinder)
    }

    public validateAllCylindersCompletely(cylinders): void {
        this.hasUncompletedCylinders = cylinders.controls.some(
            cylinder => this.hasUncompletedCylinderForInput(cylinder)
        )
    }

    public hasUncompletedCylinderForInput(cylinder: AbstractControl): boolean {
        const referenceId = cylinder.get('referenceId').value
        const content = cylinder.get('content').value
        const expiryDate = cylinder.get('expiryDate').value

        const allFilled = referenceId && content && expiryDate
        const allBlank = !referenceId && !content && !expiryDate

        return !(allFilled || allBlank)
    }

    public hasUncompletedCylindersForAddAction(cylinders): void {
        this.hasUncompletedCylinders = cylinders.controls.some(cylinder => {
                const referenceId = cylinder.get('referenceId').value
                const content = cylinder.get('content').value
                const expiryDate = cylinder.get('expiryDate').value

                return !referenceId || !content || !expiryDate
            }
        )
    }

    public isSomeIdDuplicated(cylinders: FormArray): boolean {

        const uniqueValue = {}

        for (let cylinder of cylinders.controls) {
            if (!cylinder.get('referenceId').value) {
                continue
            }

            if (uniqueValue[cylinder.get('referenceId').value]) {
                return true
            }

            uniqueValue[cylinder.get('referenceId').value] = true
        }

        return false
    }

    public isCylinderIdDuplicated(cylinderId: string, currentIndex: number, cylinders: FormArray): boolean {
        return cylinders.controls.some((cylinder, index: number) => {
            // for check in previous fields that duplicate or not
            // will show error individual field
            if (index >= currentIndex) {
                return false
            }
            return cylinder.get('referenceId').value === cylinderId
        })
    }
}
