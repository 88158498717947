import { DOCUMENT } from '@angular/common'
import { Directive, ElementRef, HostListener, Inject, Input } from '@angular/core'

@Directive({
    selector: '[appToggleActive]'
})
export class ToggleActiveDirective {
    @Input('appToggleActive') isActive = true

    private globalClickHandler: (event: Event) => void
    private element: any

    constructor(private elementRef: ElementRef, @Inject(DOCUMENT) private document: any) {
        this.element = elementRef.nativeElement
    }

    get elementIsActive(): boolean {
        return this.element.classList.contains('em-is-active')
    }

    @HostListener('click', ['$event'])
    public onMouseClick(event: Event): void {
        if (this.canToggle('em-is-active')) {
            event.stopPropagation()
            this.toggleElements()

            if (!this.elementIsActive) {
                this.removeClickHandler()
            } else if (!this.globalClickHandler) {
                this.setClickHandler()
            }
        }
    }

    private onGlobalClick(event: Event): void {
        if (this.canToggle(event.target)) {
            event.stopPropagation()
            this.toggleElements()
            this.removeClickHandler()
        }
    }

    private canToggle(target: any): boolean {
        return this.isActive || this.element.classList.contains(target)
    }

    private toggleElements(): void {
        const nextElement = this.element.nextElementSibling
        if (nextElement.tagName === 'UL') {
            nextElement.classList.toggle('em-is-active')
        }

        this.element.classList.toggle('em-is-active')
    }

    private setClickHandler(): void {
        this.globalClickHandler = (event) => this.onGlobalClick(event)
        this.document.addEventListener('click', this.globalClickHandler)
    }

    private removeClickHandler(): void {
        this.document.removeEventListener('click', this.globalClickHandler)
        this.globalClickHandler = null
    }
}
