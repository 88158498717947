import { Component, Injector, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'

import { CalibrationResult } from '@app/modules/calibration/models/calibration-result.model'
import { CalibrationValidationStatus } from '@app/modules/calibration/models/calibration-validation-status.enum'
import { SequentialTemplate } from '@app/modules/calibration/models/sequential-template.model'
import { CalibrationInitializerService } from '@app/modules/calibration/services/calibration-initializer.service'
import { CalibrationValidatorService } from '@app/modules/calibration/services/calibration-validator.service'
import { CalibrationResultStatusService } from '@app/modules/calibration/services/calibration-result-status.service'
import { SequentialCalibrationService } from '@app/modules/calibration/services/sequential-calibration.service'
import { AbstractCalibrationTemplateComponent } from '../abstract-calibration-template.component'

@Component({
    selector: 'app-sequential',
    templateUrl: './sequential.component.html',
    styleUrls: ['./sequential.component.scss'],
})
export class SequentialComponent extends AbstractCalibrationTemplateComponent implements OnInit {

    public template: SequentialTemplate
    public isInjected: boolean
    public getResultSetControl: () => FormArray

    constructor(
        private calibrationResultStatusService: CalibrationResultStatusService,
        private calibrationValidatorService: CalibrationValidatorService,
        private calibrationInitializerService: CalibrationInitializerService,
        private sequentialCalibrationService: SequentialCalibrationService,
        private formBuilder: FormBuilder,
        injector: Injector
    ) {
        super(injector)
    }

    ngOnInit(): void {
        super.ngOnInit()

        this.template = this.calibration.calibrationTemplate as SequentialTemplate
        this.isInjected = this.template.isInjectedInputRequired
        this.initForm()
        this.watchFormControl()
        this.setResponsiveTemplateData()
    }

    public validateInput(asFound: boolean, form: FormGroup): CalibrationValidationStatus {
        return this.sequentialCalibrationService.validateInput(asFound, form, this.template)
    }

    public autoPopulateAsLeft(): void {
        this.sequentialCalibrationService.autoPopulate(this.getResultSetControl(), this.template)
        this._updateCalibrationResultStatus()
    }

    protected updateCalibrationResultStatus(): void {
        this._updateCalibrationResultStatus()
    }

    private setResponsiveTemplateData(): void {
        if (this.isOnMobileOrTabletDevice) {
            this.calibrationInitializerService.updateMobileViewInjectAndAdjustedInput(this.calibrationForm)
        }
    }

    private initForm(): void {
        const numberOfPoint = this.calibration.calibrationTemplate.numberOfPoint
        const calibrationResultSet = (this.calibration?.calibrationResult ?? new CalibrationResult())

        const calibrationForm = this.calibrationInitializerService.initializeResultSetForm(
            '', calibrationResultSet.results[0], numberOfPoint, this.isInjected
        )

        const innerForm = this.calibrationForm.get('results') as FormGroup
        innerForm.setControl('calibrationResult', this.formBuilder.group({
            results: this.formBuilder.array([calibrationForm])
        }))

        this.getResultSetControl = this.calibrationValidatorService.getResultSetControl(this.calibrationForm)
        this.sequentialCalibrationService.fillResultSet(this.getResultSetControl(), this.template)
    }

    private _updateCalibrationResultStatus(): void {
        const resultStatusForm = this.calibrationForm.get('results.calibrationResultStatus') as FormControl
        const resultStatusValue = this.sequentialCalibrationService.updateCalibrationResult(this.getResultSetControl(), this.template)
        const allResultValueFilled = this.calibrationValidatorService.allInputResultHasValue(this.getResultSetControl())

        if (allResultValueFilled) {
            this.calibrationResultStatusService.updateCalibrationResultStatus(resultStatusForm, resultStatusValue)
        } else {
            this.calibrationResultStatusService.updateCalibrationResultStatus(resultStatusForm, null)
        }
    }

    private watchFormControl(): void {
        this.addSubscription(
            this.calibrationForm.get('results.calibrationResult.results').valueChanges.subscribe(form => {
                this.sequentialCalibrationService.updateExpectedResults(this.getResultSetControl(), this.template)
                this._updateCalibrationResultStatus()
            })
        )
    }
}
