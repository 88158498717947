import { createSelector } from '@ngrx/store'

import { PageComponentName } from '@app/models/page-component-state.model'
import { PageHistoryState } from './page-history.reducer'

export const pageHistory = (state): PageHistoryState => state.pageHistory

export const pageHistorySettings = createSelector(pageHistory, status => status.pageState)

export const pageHistorySettingForPageName = (pageName: PageComponentName) => {
    return createSelector(pageHistory, status => status.pageState.find(s => s.name === pageName))
}
