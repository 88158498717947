import { FormControlError } from '@app/modules/shared/models/form-control-error.model'
import { FormValidatorResult } from '@app/modules/shared/models/form-validator-result.model'

export abstract class FormErrorHandler {
    private _next?: FormErrorHandler
    protected get next(): FormErrorHandler | null {
        return this._next
    }
    public setNext(next: FormErrorHandler): FormErrorHandler {
        this._next = next
        return this._next
    }

    public abstract handle(formControlError: FormControlError, results: FormValidatorResult, ...args: any): FormValidatorResult
}
