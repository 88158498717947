import { SortedState, SortingState } from '@app/modules/shared/models/filter.model'

export const equipmentCalibrationSortSetting = [
    {
        sortId: 'status',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'equipmentId',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'equipmentTag',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'abcIndicator',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'techIdentificationNumber',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'functionalLocation',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'description',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'result',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'equipmentNotificationNumber',
        sortingState: SortingState.None,
        sortOrder: 0
    },
] as SortedState[]
