import { EquipmentTemplateDetailInput } from '@app/modules/equipment/models/equipment-template-detail-input.model'
import { Equipment } from '@app/modules/equipment/models/equipment.model'

export class CalibrationTemplate {
    public procedure: string
    public alias: string
    public numberOfPoint: number
    public isChecklistRequired?: boolean
    public tolerance?: EquipmentTemplateDetailInput
    public compareEquipment?: Equipment[]
}
