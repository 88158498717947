<section class="em-l-container end-of-page-padding main-section">
    <div class="spinner"
         *ngIf="massUpdateService.isRequestInFlight$ | async">
        <div class="spinner-inner">
            <img src="./assets/unity-1.2.0/images/icon-spinner.svg"
                 alt="Loading" />
        </div>
    </div>
    <div class="em-l-grid__item">
        <a class="em-c-text-link"
           [routerLink]="['../overview']">
            <svg class="em-c-icon em-c-icon--small em-u-margin-right-half a-icon">
                <use xlink:href="./assets/unity-1.2.0/images/em-icons.svg#chevron-left-filled"
                     xmlns:xlink="http://www.w3.org/1999/xlink"></use>
            </svg>
            <span class="a-text">
                Back to Work Order Overview
            </span>
        </a>
    </div>

    <div class="spacer__s"></div>

    <h5 class="subtitle">Mass PM Update</h5>
    <h1>PM Result</h1>

    <div class="spacer__s"></div>
    <ng-container *appRectGhostLoadingIf="!massUpdateService.massUpdateForm">
        <app-section title="PM Information"
                     class="margin-top__s">
            <ng-container body>
                <app-res-table contentColumnsWidth="20%">
                    <app-res-labeled-element labelText="PM Performed Date"
                                             desktopStyle="standalone"
                                             labelStyle='bold'
                                             [hideLabelOnDesktop]="false">
                        <app-date-picker-form [formControl]="massUpdateService.massUpdateForm.get('pmPerformedDate')"
                                              [maxDate]="datePickerMaxDate">
                        </app-date-picker-form>
                    </app-res-labeled-element>
                    <app-res-labeled-element labelText="PM Performed By"
                                             desktopStyle="standalone"
                                             labelStyle='bold'
                                             [hideLabelOnDesktop]="false">
                        <div class="multiple-selector-wrapper">
                            <app-multiple-selector [selectorFormArray]="massUpdateService.massUpdateForm.get('pmPerformedTechnicians')"
                                                   [isRequired]="false"
                                                   [initAction]="userGetAction"
                                                   [displayField]="'displayName'"
                                                   [itemSelector]="techniciansInCurrentPlantSelector"
                                                   [hasFixedFirstValue]="true"
                                                   class="dd-privacy-mask"></app-multiple-selector>
                        </div>

                    </app-res-labeled-element>
                </app-res-table>
                <div class="spacer__s"></div>
                <app-res-labeled-element labelText="Comment"
                                         labelStyle='bold'
                                         desktopStyle="standalone"
                                         [hideLabelOnDesktop]="false">
                    <textarea class="em-c-textarea em-u-font-style-regular custom-full-height"
                              placeholder="A comment will be typed here"
                              rows="5"
                              autocomplete="off"
                              autocorrect="off"
                              autocapitalize="off"
                              spellcheck="false"
                              id="equipment-comment"
                              [formControl]="massUpdateService.massUpdateForm.get('comments')"></textarea>

                </app-res-labeled-element>
            </ng-container>
        </app-section>

        <div class="spacer__s"></div>

        <app-section title="PM Result"
                     class="margin-top__s end-of-page-padding">
            <ng-container body>
                <!--
                The pure calibrationResultSelector is used here as we do not
                need any logic to compare the finalResult vs calibrationResult
                -->
                <app-calibration-result-selector [formControl]="massUpdateService.massUpdateForm.get('finalPMResultStatus')"
                                                 [isDisabled]="
                                                    (massUpdateService.isRequestInFlight$ | async)
                                                    || (!identityService.isCurrentUserATechnician$ | async)
                                                    || !isTechnician
                                                    "
                                                 [useBlack]="massUpdateService.isRequestInFlight$ | async">
                </app-calibration-result-selector>
            </ng-container>
        </app-section>
    </ng-container>
</section>

<!-- TODO: Refactor into a Footer Bar Component -->
<!-- Mass Update Footer Bar -->
<div class="mass-update-footer-bar"
     *ngIf="massUpdateService.selectedEquipmentCalibrations$ | async as selectedECs">
    <div class="em-l-container mass-update-footer-bar--btn-row">
        <div class="mass-update-footer-bar--btn-container">
            <button class="em-c-btn em-c-btn--primary btn-proceed"
                    [ngClass]="{
                        'em-c-btn--disabled':
                            massUpdateService.massUpdateForm.invalid || (massUpdateService.isRequestInFlight$ | async) || !isTechnician
                        }"
                    [disabled]="massUpdateService.massUpdateForm.invalid || (massUpdateService.isRequestInFlight$ | async)"
                    (click)="massUpdateService.updateAllClick$.next()">
                Complete All ({{selectedECs.length}} Equipment)
            </button>
            <button class="em-c-btn custom-right-item btn-deferNotTest"
                    [ngClass]="{'em-c-btn--disabled': (massUpdateService.isRequestInFlight$ | async) || !isTechnician}"
                    [disabled]="massUpdateService.isRequestInFlight$ | async"
                    (click)="deferOrNotTestClick()">
                Defer/Not Test
            </button>
        </div>
    </div>
</div>