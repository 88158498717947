import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core'
import { Store } from '@ngrx/store'

import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import { overlaySlider } from '@app/store/overlay/overlay.selectors'
import { SlideUpOverlayType } from '../../constants/slide-up-overlay.enum'

@Component({
    selector: 'app-slide-up-overlay',
    templateUrl: './slide-up-overlay.component.html',
    styleUrls: ['./slide-up-overlay.component.scss']
})
export class SlideUpOverlayComponent extends SafeUnsubscriberComponent implements OnInit {
    @ViewChild('overlay', { static: true }) el: ElementRef
    @Input() id: SlideUpOverlayType

    constructor(private store: Store<AppState>, private renderer: Renderer2) {
        super()
    }

    ngOnInit(): void {
        this.addSubscription(
            this.store.select(overlaySlider).subscribe(slider => {
                if (!slider) {
                    return
                }

                if (slider.visibility && slider.id === this.id) {
                    this.overlaySlideUp()
                } else {
                    this.overlaySlideDown()
                }
            })
        )
    }

    private overlaySlideDown(): void {
        this.renderer.setStyle(this.el.nativeElement, 'visibility', 'hidden')
        this.renderer.setStyle(this.el.nativeElement, 'bottom', '-100vh')
    }

    private overlaySlideUp(): void {
        this.renderer.setStyle(this.el.nativeElement, 'visibility', 'visible')
        this.renderer.setStyle(this.el.nativeElement, 'bottom', '0vh')
    }
}
