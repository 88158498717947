export class SelectionDrawerState {
    name: SelectionDrawerName
    visibility = false
}

export enum SelectionDrawerName {
    CalibrationMoreOption = 'PM - More options',
    CalibrationChangeTemplate = 'PM - Change template',
    CalibrationSubmitOption = 'PM - Submit options',
    CalibrationInfoOption = 'PM - Info options'
}

export class SelectionDrawerOption {
    optionName: string
    subtitleLine1?: string
    subtitleLine2?: string
    currentSelection?: boolean
    disabled?: boolean
    allowDisabledDrawerCallback?: boolean
    callback: () => any
    optionId?: string | number
}
