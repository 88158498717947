import { animate, style, transition, trigger } from '@angular/animations'
import { Component, ContentChild, forwardRef, Input, TemplateRef } from '@angular/core'
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
    selector: 'app-radio-button-list',
    templateUrl: './radio-button-list.component.html',
    styleUrls: ['./radio-button-list.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({ opacity: 0, height: 0 }),
                animate('300ms ease-out', style({ opacity: 1, height: '*' }))
            ]),
            transition(':leave', [
                style({ opacity: 1, height: '*' }),
                animate('300ms ease-out', style({ opacity: 0, height: '0' }))
            ])
        ])
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioButtonListComponent),
            multi: true
        }
    ]
})
export class RadioButtonListComponent implements ControlValueAccessor {

    // TODO: Add disabled state CSS

    @Input() options: { name: string, value: any }[] = []
    @Input() name: string

    @ContentChild('renderUnderChecked') renderUnderChecked: TemplateRef<any>

    // Do not set this through @Input, else CVA/ReactiveForm will not be aware of the change
    // If you need to set this in the DOM, see `appDisableReactiveFormControl` directive.
    public isDisabled = false

    public innerControl = new FormControl()
    private currentValue: any

    public radioSelectionChangedFromView(): void {
        if (this.isDisabled) {
            // Reverse view state
            this.innerControl.patchValue(this.currentValue)
            return
        }
        this.currentValue = this.innerControl.value
        this.onChange(this.currentValue)
        this.onTouched()
    }

    writeValue(value: any): void {
        this.currentValue = value
        this.innerControl.patchValue(value)
    }

    registerOnChange(fn): void {
        this.onChange = fn
    }

    registerOnTouched(fn): void {
        this.onTouched = fn
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled
    }

    private onChange: any = () => { }
    private onTouched: any = () => { }

}
