import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-grt-footer-bar',
    templateUrl: './grt-footer-bar.component.html',
    styleUrls: ['./grt-footer-bar.component.scss']
})
export class GRTFooterBarComponent {
    @Input() fixedToBottom = false
    @Input() withinContainer = false
    @Input() pageName = 'Work Order'
}
