import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

export const noValueDropdownOption: DropdownOption = {
    displayValue: '-- none --',
    value: undefined
}

export interface DropdownOption<T = any> {
    displayValue: string,
    value: T | undefined,
}

export type SelectedOption<T> = DropdownOption<T>

@Component({
    selector: 'app-dropdown-no-form',
    templateUrl: './dropdown-no-form.component.html',
    styleUrls: ['./dropdown-no-form.component.scss']
})
export class DropdownNoFormComponent<T> implements OnInit {
    @Input() disabled = false

    @Input() enableSelectNone = false
    @Input() options: DropdownOption<T>[] = []
    @Output() selectedOptionChange = new EventEmitter<SelectedOption<T>>()

    public noValueDropdownOption = noValueDropdownOption
    private _initOption: SelectedOption<T> = this.noValueDropdownOption
    private _selectedOption: SelectedOption<T> = this.noValueDropdownOption

    @Input()
    set selectedOption(newOption: SelectedOption<T>) {
        this._selectedOption = newOption
        this.selectedOptionChange.emit(this.selectedOption)
    }

    get selectedOption(): SelectedOption<T> {
        return this._selectedOption
    }

    @Input()
    set initOption(value: SelectedOption<T>) {
        this._initOption = value
        this._selectedOption = value
    }

    ngOnInit(): void {
        /** will not emit the selectedOptionChange event */
        this._selectedOption = this._initOption
    }

    public isSelected(option: DropdownOption<T>): boolean {
        return this.selectedOption?.displayValue === option.displayValue
    }

    public getOptionStyle(option: DropdownOption<T>): string {
        return this.isSelected(option) ? 'selected' : ''
    }
}
