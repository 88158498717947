import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { filter, map, tap } from 'rxjs/operators'

import { SpecialTemplateId } from '@app/constants/app-special.constants'
import { CalibrationInitializerService } from '@app/modules/calibration/services/calibration-initializer.service'
import { TemplateType } from '@app/modules/equipment/models/template-type.model'
import { EquipmentTemplateUtilService } from '@app/modules/equipment/services/equipment-template-util.service'
import { TemplateTypeUtilService } from '@app/modules/equipment/services/template-type-util.service'
import { SelectionDrawerName, SelectionDrawerOption } from '@app/modules/shared/models/selection-drawer.model'
import { AppState } from '@app/store/app.store'
import { selectedTemplateId } from '@app/store/calibration/calibration.selectors'
import { equipmentTemplateList } from '@app/store/equipment/selectors/equipment-template.selectors'
import { ToggleSelectionDrawerAction } from '@app/store/selection-drawer/selection-drawer.actions'
import { workOrderDetails } from '@app/store/work-order/work-order.selectors'
import { sortArray } from '@app/utils/app-utils.function'
import { CalibrationTemplateSelectionComponent } from '../modals/calibration-template-selection/calibration-template-selection.component'

@Component({
    selector: 'app-calibration-template-selection-drawer',
    templateUrl: './calibration-template-selection-drawer.component.html',
    styleUrls: ['./calibration-template-selection-drawer.component.scss']
})

export class CalibrationTemplateSelectionDrawerComponent extends CalibrationTemplateSelectionComponent implements OnInit {
    public templateSelectionDrawerName = SelectionDrawerName.CalibrationChangeTemplate
    public selectTemplateOptions: SelectionDrawerOption[] = []

    constructor(
        store: Store<AppState>,
        route: Router,
        templateTypeUtilService: TemplateTypeUtilService,
        equipmentTemplateUtilService: EquipmentTemplateUtilService,
        calibrationInitializerService: CalibrationInitializerService,
        public cdRef: ChangeDetectorRef
    ) {
        super(store, route, templateTypeUtilService, equipmentTemplateUtilService, calibrationInitializerService)
    }

    ngOnInit(): void {
        super.initCurrentCalibrationDetail()
        super.initEquipmentInfo()

        this.initOptions()
        this.initDrawer()
    }

    private initOptions(): void {
        this.addSubscriptions([
            this.store.select(equipmentTemplateList).pipe(
                filter(templates => {
                    return !!this.equipmentTemplateUtilService.findEquipmentTemplateById(templates, this.equipmentId)
                }),
                tap(existingTemplates => {
                    this.actualTemplateDetails = existingTemplates
                }),
                map(existingTemplates => {
                    // Fetch and filter for all available templates type for this eq.
                    const availableTemplates = existingTemplates.map(template => {
                        const tempTemplateObject = {
                            id: template.templateTypeId,
                            name: template.templateTypeName,
                            numberOfPoint: [template.detail.numberOfPoint],
                            isActive: template.isActive,
                            processName: template.processName,
                            alias: template.detail.alias
                        } as TemplateType
                        tempTemplateObject.name = this.templateTypeUtilService.getTemplateTypeName(tempTemplateObject)
                        tempTemplateObject.id = template.id
                        return tempTemplateObject
                    })

                    return sortArray(availableTemplates, 'name', false)
                }),
                tap(availableTemplates => {
                    // Use it to populate our options menu
                    if (availableTemplates.length === this.selectTemplateOptions.length - 1) {
                        return
                    }

                    availableTemplates.forEach(t => {
                        const isSelectedTemplate = this.selectedTemplateId && (this.selectedTemplateId === t.id)

                        this.selectTemplateOptions.push({
                            optionName: t.processName,
                            callback: () => this.selectTemplate(t),
                            currentSelection: isSelectedTemplate,
                            subtitleLine1: (t.alias && t.alias.length > 0) ? t.alias : t.name,
                            subtitleLine2: (t.alias && t.alias.length > 0) ? t.name : null,
                            optionId: t.id
                        })
                    })

                    const noTemplateId = {
                        id: SpecialTemplateId.NoTemplateTemplateId,
                        name: `Don't use a template`,
                        numberOfPoint: [0],
                        isActive: false
                    }

                    const noTemplateOption = {
                        optionName: `Don't use a template`,
                        currentSelection: false,
                        callback: () => this.selectTemplate(noTemplateId),
                        optionId: SpecialTemplateId.NoTemplateTemplateId
                    } as SelectionDrawerOption

                    this.selectTemplateOptions.push(noTemplateOption)
                })
            ).subscribe(),

            this.store.select(workOrderDetails).subscribe(workOrder => {
                if (workOrder) {
                    this.equipmentCalibration = workOrder.equipmentCalibrations.find(equipment =>
                        equipment.equipmentId === this.equipmentId
                    )
                }
            }),

            this.store.select(selectedTemplateId).subscribe(templateId => {
                // Update to local selectedId, in case the array finish init before this
                this.selectedTemplateId = templateId
                // Update the array option
                // Note that the first object passed to forEach() may not mutate the
                // original array, so we use index to access it here just to be safe
                this.selectTemplateOptions.forEach((option, index) => {
                    this.selectTemplateOptions[index].currentSelection =
                        option.optionId === this.selectedTemplateId
                })
            })
        ])
    }

    private initDrawer(): void {
        this.store.dispatch(new ToggleSelectionDrawerAction({
            name: this.templateSelectionDrawerName,
            visibility: false
        }))
    }
}
