<a (click)="isDisabled ? '' : buttonClick.next(true)"
   class="icon-button-container"
   [ngClass]="{'em-u-clickable': !isDisabled, 'grey-button-disabled': isDisabled}"
   [ngStyle]="extraStyle">
    <span class="icon">
        <svg class="em-c-icon em-c-icon--small">
            <use [attr.xlink:href]="svgPath ? svgPath : 'assets/unity-1.2.0/images/em-icons.svg#' + unitySvgName"></use>
        </svg>
    </span>
    <span class="text"
          [ngClass]="{'text__bold': bold}">
        <ng-content></ng-content>
    </span>
</a>