<div class="wrapper"
     [ngClass]="{ disabled: isDisabled}"
     [ngStyle]="{ 'grid-template-columns': 'repeat('+numberOfColumns+', 1fr)' }">
    <ng-container *ngFor="let option of localChecklistViewState">
        <label [for]="idPrefix + option.id"
               class="em-c-input-group em-c-checkbox--container">
            <input type="checkbox"
                   class="em-c-input-group__control"
                   [name]="option.name"
                   [id]="idPrefix + option.id"
                   [value]="option.value"
                   [checked]="option.checked"
                   [disabled]="isDisabled"
                   (change)="checklistChanged($event)">
            <span class="">{{ option.name }}</span>
        </label>
    </ng-container>
</div>
