import { Component, Injector, OnInit } from '@angular/core'
import { FormArray, FormControl, FormGroup } from '@angular/forms'

import { SpecialUomCode } from '@app/models/special-uom.model'
import { RepeatabilityCalibrationResultRow } from '@app/modules/calibration/models/calibration-result-value.model'
import { CalibrationValidationStatus } from '@app/modules/calibration/models/calibration-validation-status.enum'
import { RepeatabilityCalibrationService } from '@app/modules/calibration/services/repeatability-calibration.service'
import { UnitOfMeasurement } from '@app/modules/shared/models/engineering-units/unit-of-measurement.model'
import { UnitRange } from '@app/modules/shared/models/engineering-units/unit-range.model'
import { UnitValue } from '@app/modules/shared/models/engineering-units/unit-value.model'
import { FeetInch16FormatterService } from '@app/modules/shared/services/feet-inch16-formatter.service'
import { deepCopy, isNotAValue } from '@app/utils/app-utils.function'
import { RepeatabilityTemplate } from '../../../models/repeatability-template.model'
import { AbstractCalibrationTemplateComponent } from '../abstract-calibration-template.component'

@Component({
    selector: 'app-repeatability',
    templateUrl: './repeatability.component.html',
    styleUrls: ['./repeatability.component.scss']
})
export class RepeatabilityComponent extends AbstractCalibrationTemplateComponent implements OnInit {

    // Cache validation statuses to optimize Angular's CD
    public asFoundValidationStatuses: CalibrationValidationStatus[] = []
    public asLeftValidationStatuses: CalibrationValidationStatus[] = []

    public repeatabilityForm: FormGroup

    private completedAsFoundInput: boolean[]

    constructor(
        private repeatabilityCalibrationService: RepeatabilityCalibrationService,
        private feetInch16FormatterService: FeetInch16FormatterService,
        injector: Injector
    ) {
        super(injector)
    }

    public get template(): RepeatabilityTemplate {
        return this.calibration.calibrationTemplate as RepeatabilityTemplate
    }

    public get resultSetControl(): FormArray {
        const resultFormArray = this.repeatabilityForm.get('calibrationResult.results') as FormArray
        return resultFormArray.at(0).get('resultSet') as FormArray
    }

    public get accuracyToleranceUoM(): string {
        return this.formatUoM(this.template.accuracyTolerance.unitOfMeasurement)
    }

    public get inputUoM(): string {
        if (this.template.isAccuracyTesting) {
            return this.formatUoM(this.template.input.unitOfMeasurement)
        } else {
            return this.formatUoM(this.template.repeatabilityTolerance.unitOfMeasurement)
        }
    }

    public get repeatabilityToleranceUoM(): string {
        return this.formatUoM(this.template.repeatabilityTolerance.unitOfMeasurement)
    }

    public get isInch16(): boolean {
        return (this.template.isAccuracyTesting && this.template.input.unitOfMeasurement.uomCode === SpecialUomCode.FeetInch16)
            || (this.template.isRepeatabilityTesting && this.template.repeatabilityTolerance.unitOfMeasurement.uomCode === SpecialUomCode.FeetInch16)
    }

    public get injectedInputControl(): FormControl {
        return this.resultSetControl.at(0).get('injectedInput') as FormControl
    }

    public get getReferencingDevice(): FormControl {
        return this.resultSetControl.at(0).get('referencingDevice') as FormControl
    }

    public get expectedValueString(): string {
        if (this.template.isInjectedInputRequired) {
            const clonedRange: UnitRange = deepCopy(this.template.expected)
            const injectedValue = this.injectedInputControl.value
            if (!isNotAValue(injectedValue)) {
                clonedRange.minimumRange = injectedValue
            }

            return this.formatUnitRange(clonedRange)
        }

        return this.formatUnitRange(this.template.expected)
    }

    public get accuracyToleranceValueString(): string {
        return this.formatUnitValue(this.template.accuracyTolerance)
    }

    public get repeatabilityToleranceValueString(): string {
        return this.formatUnitValue(this.template.repeatabilityTolerance)
    }

    public get inch16TabIndex(): number {
        if (this.isInch16) {
            return 3
        }
        return 0
    }

    public get refDeviceTabIndex(): number {
        return this.template.referencingDeviceRequired ? 1 : 0
    }

    public get numberOfPoint(): number {
        return this.calibration.calibrationTemplate.numberOfPoint
    }

    public get isInjectedInputBoxVisible(): boolean {
        return this.template.isInjectedInputRequired && !this.isReport
    }

    ngOnInit(): void {
        super.ngOnInit()
        this.repeatabilityForm = this.calibrationForm.get('results') as FormGroup
        this.repeatabilityCalibrationService.initialize(
            this.repeatabilityForm,
            this.repeatabilityForm.get('calibrationResult').value.results[0],
            this.template
        )

        // Init validation statuses
        for (const point of this.resultSetControl.controls) {
            this.asFoundValidationStatuses.push(this.validateInput(true, point as FormGroup))
            this.asLeftValidationStatuses.push(this.validateInput(false, point as FormGroup))
        }

        if (!this.isReport) {
            this.watchFormControl()
        }

        this.completedAsFoundInput = new Array<boolean>(this.calibration.calibrationTemplate.numberOfPoint).fill(true)
    }

    public autoPopulateAsLeft(): void {
        if (this.completedAsFoundInput.every(x => x === true)) {
            this.repeatabilityCalibrationService.autoPopulate(this.resultSetControl, this.template)
            this.repeatabilityCalibrationService.updateCalibrationResultStatus(
                this.repeatabilityForm.get('calibrationResultStatus') as FormControl,
                this.resultSetControl,
                this.template
            )
            // Make sure to highlight all asLeft in green
            this.revalidateAllInputs(false)
        }
    }

    public validateInput(isAsFound: boolean, pointControl: FormGroup): CalibrationValidationStatus {
        if (this.template.isAverageReadingUsed) {
            if (!this.repeatabilityCalibrationService.allInputInTheSameColumnHasValue(isAsFound, this.resultSetControl)) {
                return CalibrationValidationStatus.Initialize
            }
            return this.repeatabilityCalibrationService.calculateAverageReadingInTolerance(isAsFound, this.resultSetControl, this.template)
        } else {
            return this.repeatabilityCalibrationService.validateInput(
                isAsFound,
                pointControl,
                this.template,
                this.resultSetControl.value
            )
        }
    }


    public pointInputChange(index: number, isAsFound: boolean, pointControl: FormGroup): void {
        // If column is populated, change in one field will effect validation
        // for entire column so we need to re-validate the whole column
        if (this.isColumnPopulated(isAsFound)) {
            this.revalidateAllInputs(isAsFound)
        } else {
            this.revalidateInput(index, isAsFound, pointControl)
        }
    }

    public getAverageAsFound(): string {
        const resultSetValue = this.resultSetControl.value as RepeatabilityCalibrationResultRow[]
        const average = this.repeatabilityCalibrationService.getAverage(resultSetValue.map(result => result.asFound))

        if (this.isInch16) {
            return this.feetInch16FormatterService.toLongString(average)
        }

        return average.toString()
    }

    public getAverageAsLeft(): string {
        const resultSetValue = this.resultSetControl.value as RepeatabilityCalibrationResultRow[]
        const average = this.repeatabilityCalibrationService.getAverage(resultSetValue.map(result => result.asLeft))

        if (this.isInch16) {
            return this.feetInch16FormatterService.toLongString(average)
        }

        return average.toString()
    }

    public injectedBlur(event: Event): void {
        if (isNotAValue((event.target as HTMLInputElement).value)) {
            this.resultSetControl.controls.forEach(resultControl => {
                resultControl.patchValue({
                    injectedInput: this.template.input.minimumRange
                })
            })
        }
    }

    public getAverageInTolerance(isAsFound: boolean): boolean {
        return this.repeatabilityCalibrationService.calculateAverageReadingInTolerance(
            isAsFound,
            this.resultSetControl,
            this.template
        ) === CalibrationValidationStatus.Valid
    }

    public isColumnPopulated(isAsFound: boolean): boolean {
        return this.repeatabilityCalibrationService.allInputInTheSameColumnHasValue(isAsFound, this.resultSetControl)
    }

    public countCompletedAsFound(allInputEntered: boolean, index: number): void {
        this.completedAsFoundInput[index] = allInputEntered
    }

    protected updateCalibrationResultStatus(): void {
        this.repeatabilityCalibrationService.updateCalibrationResultStatus(
            this.repeatabilityForm.get('calibrationResultStatus') as FormControl,
            this.resultSetControl,
            this.template
        )
    }

    private revalidateInput(index: number, isAsFound: boolean, pointControl: FormGroup): void {
        const newStatus = this.validateInput(isAsFound, pointControl)
        if (isAsFound) {
            this.asFoundValidationStatuses[index] = newStatus
        } else {
            this.asLeftValidationStatuses[index] = newStatus
        }
    }

    private revalidateAllInputs(isAsFound: boolean): void {
        let index = 0
        for (const point of this.resultSetControl.controls) {
            this.revalidateInput(index++, isAsFound, point as FormGroup)
        }
    }

    private formatUoM(uom: UnitOfMeasurement): string {
        return uom ? `(${uom.uomCodeForTech})` : ''
    }

    private formatUnitRange(value: UnitRange): string {
        if (this.isInch16) {
            return this.feetInch16FormatterService.toLongString(value.minimumRange)
        }

        return value.minimumRange.toString()
    }

    private formatUnitValue(value: UnitValue): string {
        if (this.isInch16) {
            return this.feetInch16FormatterService.toShortString(value.value)
        }

        return value.value.toString()
    }

    private fillInRemainingRefDevice(): void {
        const refDeviceName = this.resultSetControl.at(0).get('referencingDevice').value
        for (let i = 1; i < this.resultSetControl.controls.length; i++) {
            this.resultSetControl
                .at(i)
                .get('referencingDevice')
                .setValue(refDeviceName, { emitEvent: false })
        }
    }

    private watchFormControl(): void {
        this.addSubscription(
            this.repeatabilityForm.get('calibrationResult.results').valueChanges.subscribe(() => {
                this.repeatabilityCalibrationService.updateInjectedInput(
                    this.repeatabilityForm.get('calibrationResult.results') as FormArray
                )

                this.updateCalibrationResultStatus()

                this.fillInRemainingRefDevice()
            })
        )
    }
}
