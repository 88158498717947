<section *ngIf="!isOnMobileOrTabletDevice">
    <div class="modal-container"
         [ngClass]="{'modal-container--dark': !(isLoading$ | async), 'modal-container--flex': visibility}"
         [hidden]="!visibility">
        <div class="modal-inner">
            <div class="modal-body em-u-text-align-center">
                <div class="calibration-modal-upper">
                    <div class="upper-wrapper">
                        <button class="em-c-btn em-c-btn--bare em-c-modal__close-btn em-js-modal-close-trigger"
                                (click)="exitModal()">
                            <div class="em-c-btn__inner">
                                <svg class="em-c-icon em-c-icon--small em-c-btn__icon custom-close-modal-icon">
                                    <use xlink:href="./assets/unity-1.2.0/images/em-icons.svg#x-filled"
                                         xmlns:xlink="http://www.w3.org/1999/xlink"></use>
                                </svg>
                            </div>
                        </button>
                        <svg class="em-c-icon em-c-icon--white em-c-icon--custom">
                            <use xmlns:xlink="http://www.w3.org/1999/xlink"
                                 attr.xlink:href="./assets/unity-1.2.0/images/48/em-icons.svg#{{ modal.icon }}"></use>
                        </svg>
                    </div>
                    <div class="calibration-status-message em-u-font-style-semibold">
                        {{ modal.title }}
                    </div>
                </div>
                <div class="calibration-modal-lower"
                     #modalLowerReference>
                    <div class="calibration-message">
                        <div class="em-c-field">
                            <div class="em-c-field__body">
                                <div *ngIf="modal.body; else modalContainer"
                                     [innerHTML]="modal.body"></div>
                                <ng-template #modalContainer></ng-template>

                            </div>
                        </div>
                    </div>
                    <div class="em-u-font-size-med-2 em-u-padding-top em-u-padding-bottom-double em-u-padding-left-quad em-u-padding-right-quad"
                         *ngIf="modal.footer">
                        <div class="em-c-btn-group">
                            <button *ngIf="modal?.confirmLabel"
                                    data-cy="modal-confirm"
                                    (click)="confirmModal()"
                                    [ngClass]="{'em-c-btn--disabled': !isValid()}"
                                    class="em-c-btn em-c-btn--primary">
                                <span class="em-c-btn__text ">{{ modal.confirmLabel }}</span>
                            </button>
                            <button *ngIf="modal?.closeLabel"
                                    data-cy="modal-cancel"
                                    (click)="closeModal()"
                                    class="em-c-btn em-c-btn--secondary">
                                <span class="em-c-btn__text">{{ modal.closeLabel }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section *ngIf="isOnMobileOrTabletDevice">
    <div class="overlay"
         [hidden]="!visibility"
         [ngClass]="{
            'overlay-warning' : modal?.warningOverlay,
            'overlay-confirm' : !modal?.warningOverlay
        }">
        <div *ngIf="modal.body; else modalContainer"
             class="overlay-body">
            <sub>{{ modal.title }}</sub>
            <div [innerHTML]="modal?.body | safe"></div>
        </div>
        <ng-template #modalContainer></ng-template>
        <div class="overlay-footer">
            <button *ngIf="modal?.confirmLabel"
                    data-cy="mobile-modal-confirm"
                    class="em-c-btn em-c-btn-primary confirm-btn"
                    (click)="confirmModal()">
                {{ modal.confirmLabel }}
            </button>
            <button *ngIf="modal?.closeLabel"
                    data-cy="mobile-modal-cancel"
                    class="em-c-btn em-c-btn-primary"
                    (click)="closeModal()">
                {{ modal.closeLabel }}
            </button>
        </div>
    </div>
</section>