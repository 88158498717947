import { Component, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { Store } from '@ngrx/store'
import { FormArray, FormBuilder, FormControl } from '@angular/forms'
import { BehaviorSubject } from 'rxjs'
import { chain, difference, get, includes, isEmpty, isNumber, toString, trim } from 'lodash'
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators'
import { AppState } from '@app/store/app.store'
import { FilteringService } from '@app/modules/shared/services/filtering.service'
import { AutocompleteConfig } from '@app/modules/shared/components/selection/selection.model'
import { DOCUMENT } from '@angular/common'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent extends SafeUnsubscriberComponent implements OnInit, OnChanges, OnDestroy {

    @Input() optionsForm: FormArray
    @Input() config: AutocompleteConfig

    public dataSource: any[]
    public searchText$: BehaviorSubject<string> = new BehaviorSubject('')

    public searchBoxFocused = false
    public selectableOptions: any[] = []
    private focusHandler: any

    constructor(
        private formBuilder: FormBuilder,
        private store: Store<AppState>,
        private elementRef: ElementRef,
        private filteringService: FilteringService,
        @Inject(DOCUMENT) private document: any
    ) {
        super()
        this.selectAll = this.selectAll.bind(this)
        this.searchText$.pipe(
            debounceTime(250),
            distinctUntilChanged(),
            map(value => {
                return this.filteringService.filter(this.dataSource, this.config.displayedFieldName, trim(value))
            })
        ).subscribe(processedList => {
                this.selectableOptions = processedList
            }
        )
    }

    ngOnInit(): void {
        this.store.select(this.config.selector).subscribe(data => {
            this.dataSource = data
            this.selectableOptions = data
            this.removeNotExistedData()
        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.optionsForm.firstChange && !isEmpty(changes.optionsForm.currentValue.value)) {
            this.removeNotExistedData()
        }
    }

    ngOnDestroy(): void {
        this.removeFocusState()
    }

    public removeNotExistedData(): void {
        this.optionsForm.value.forEach((value, index) => {
            if (!this.dataSource.some(item => toString(item[this.config.mappedFieldName]) === toString(value))) {
                this.optionsForm.removeAt(index)
            }
        })
    }

    public deleteOption(optionIndex): void {
        if (!this.optionsForm.disabled) {
            this.optionsForm.removeAt(optionIndex)
        }
    }

    public hasSelectedOption(option): boolean {
        return includes(this.optionsForm.value.map(toString), toString(option[this.config.mappedFieldName]))
    }

    public onClickOption(option): void {
        const existingIndex = this.optionsForm.value.findIndex(value => toString(value) === toString(option[this.config.mappedFieldName]))
        if (existingIndex === -1) {
            this.optionsForm.push(new FormControl(toString(option[this.config.mappedFieldName])))
        } else {
            this.optionsForm.removeAt(existingIndex)
        }
    }

    public onSearchBoxFocus(): void {
        this.searchBoxFocused = true

        this.focusHandler = event => {
            if (!this.elementRef.nativeElement.contains(event.target)) {
                this.removeFocusState()
            }
        }

        this.document.addEventListener('click', this.focusHandler, {
            passive: true
        })
    }

    public getDisplayedValueForSelectedTag(selectedOptionValue: any): string {
        if (!isEmpty(this.dataSource)) {
            return chain(this.dataSource)
                .filter(item => toString(get(item, this.config.mappedFieldName)) === toString(selectedOptionValue))
                .map(this.config.displayedFieldName)
                .head()
                .value()
        }
        return selectedOptionValue
    }

    public selectAll(): void {
        const notSelectedOptions = difference(
            this.selectableOptions.map(item => toString(item[this.config.mappedFieldName])),
            this.optionsForm.value.map(toString))
        notSelectedOptions.forEach(option => this.optionsForm.push(new FormControl(option)))
    }

    public deselectAll(): void {
        const needToRemoveIndex = this.optionsForm.value.map((item, index) =>
            includes(
                this.selectableOptions.map(i => toString(i[this.config.mappedFieldName])),
                toString(item))
                ? index : '')
            .filter(isNumber)
        needToRemoveIndex.forEach((processedIndex, index) => {
            this.optionsForm.removeAt(processedIndex - index)
        })
    }

    private removeFocusState(): void {
        this.searchText$.next('')
        this.searchBoxFocused = false

        this.document.removeEventListener('click', this.focusHandler)
    }
}
