import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Store } from '@ngrx/store'
import { Observable, Subscription } from 'rxjs'
import { take } from 'rxjs/operators'

import { SpecialUomCode } from '@app/models/special-uom.model'
import * as TemplateOptions from '@app/modules/equipment/models/default-template-options-values'
import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { Process } from '@app/modules/equipment/models/process.model'
import { TemplateTypeOption } from '@app/modules/equipment/models/template-type.model'
import {
    RepeatabilityTemplateValidationsService
} from '@app/modules/equipment/services/template-validations/repeatability-template-validations.service'
import { UOM } from '@app/modules/shared/models/engineering-units/unit-of-measurement.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import * as EquipmentTemplateAction from '@app/store/equipment/actions/equipment-template.actions'
import * as TemplateFormAction from '@app/store/equipment/actions/template-form.actions'
import { equipmentTemplateList, selectedTemplate } from '@app/store/equipment/selectors/equipment-template.selectors'
import { process } from '@app/store/equipment/selectors/process.selectors'
import { uomList } from '@app/store/equipment/selectors/uom.selectors'
import { deepCopy, findOptionWithKey } from '@app/utils/app-utils.function'

@Component({
    selector: 'app-repeatability',
    templateUrl: './repeatability.component.html'
})
export class RepeatabilityComponent extends SafeUnsubscriberComponent implements OnInit, OnDestroy {
    @Input() isAdmin: boolean

    public uomList$: Observable<UOM[]>
    public toleranceUoMs: UOM[] = []
    public processes$: Observable<Process[]>
    public numberOfPointOptions = TemplateOptions.numberOfPointRepeatabilityOptions
    public enableDisableOptions = TemplateOptions.enableDisableOptions
    public yesNoOptions = TemplateOptions.yesNoOption
    public showTip = true
    public form: FormGroup
    public getOption = findOptionWithKey

    private selectedTemplate: TemplateTypeOption
    private templateDetails: EquipmentTemplateCoreDetails
    private percentageUOM: UOM

    // If we rebuild form, we need to manually unsubscribe the old subscription
    private formChangeSubscription: Subscription

    constructor(
        private store: Store<AppState>,
        private formBuilder: FormBuilder,
        private validationService: RepeatabilityTemplateValidationsService,
        public cdRef: ChangeDetectorRef
    ) {
        super()
    }

    ngOnInit(): void {
        this.uomList$ = this.store.select(uomList)
        this.processes$ = this.store.select(process)
        this.initPercentageUOM()
        this.addSubscription(
            this.store.select(selectedTemplate).subscribe(template => this.selectedTemplate = template)
        )
        this.initTemplateData()
    }

    ngOnDestroy(): void {
        super.ngOnDestroy()
        this.formChangeSubscription?.unsubscribe()
    }

    public isInch16(controlPath: string): boolean {
        const value = this.form.get(controlPath).value
        return (value || false) && (value.uomCode === SpecialUomCode.FeetInch16)
    }

    private initPercentageUOM(): void {
        this.addSubscription(
            this.uomList$.subscribe(uoms => {
                this.percentageUOM = uoms.find(uom => uom.uomCode === SpecialUomCode.Percentage)
            })
        )
    }

    private initTemplateData(): void {
        this.store.select(equipmentTemplateList).pipe(take(1)).subscribe(equipmentTemplate => {
            this.templateDetails = equipmentTemplate.find(template =>
                template.id === this.selectedTemplate.templateUniqueId
            )

            this.form = this.buildForm()

            this.bindFormDependencies()
            this.setShowTip()

            this.store.dispatch(new TemplateFormAction.SelectCurrentTemplateFormsAction(
                this.form.getRawValue()
            ))

            if (!this.isAdmin) {
                this.form.disable()
            }
            this.watchMainFormControl()
        })
    }

    private buildForm(): FormGroup {
        const inputUoM = (this.templateDetails?.detail?.input?.unitOfMeasurement ?? null)

        if (inputUoM) {
            this.setToleranceUoMs(inputUoM)
        }

        const form = this.formBuilder.group({
            process: {
                id: (this.templateDetails?.processId ?? 1),
                name: (this.templateDetails?.processName ?? 'Generic')
            },
            alias: (this.templateDetails?.detail?.alias ?? ''),
            procedure: (this.templateDetails?.detail?.procedure ?? ''),
            numberOfPoints: this.formBuilder.group({
                point: this.numberOfPointOptions.find(point =>
                    point.key === (this.templateDetails?.detail?.numberOfPoint ?? null)
                )
            }),
            isAccuracyTesting: 'true',
            input: this.formBuilder.group({
                unitOfMeasurement: inputUoM,
                minimumRange: (this.templateDetails?.detail?.input?.minimumRange ?? 0),
                maximumRange: 0
            }),
            isInjectedInputRequired: '' + (!!this.templateDetails?.detail?.isInjectedInputRequired),
            expected: this.formBuilder.group({
                unitOfMeasurement: {
                    value: (this.templateDetails?.detail?.expected?.unitOfMeasurement ?? null),
                    disabled: true
                },
                minimumRange: {
                    value: (this.templateDetails?.detail?.expected?.minimumRange ?? 0),
                    disabled: true
                },
                maximumRange: 0
            }),
            accuracyTolerance: this.formBuilder.group({
                unitOfMeasurement: (this.templateDetails?.detail?.accuracyTolerance?.unitOfMeasurement ?? null),
                value: (this.templateDetails?.detail?.accuracyTolerance?.value ?? 0)
            }),
            isRepeatabilityTesting: 'false',
            repeatabilityTolerance: this.formBuilder.group({
                unitOfMeasurement: (this.templateDetails?.detail?.repeatabilityTolerance?.unitOfMeasurement ?? null),
                value: (this.templateDetails?.detail?.repeatabilityTolerance?.value ?? 0)
            }),

            referencingDeviceRequired: 'true',
            isAverageReadingUsed: '' + (!!this.templateDetails?.detail?.isAverageReadingUsed)
        })

        return form
    }

    private bindFormDependencies(): void {
        this.bindIsAccuracyTesting()
        this.bindUomChanges()
        this.bindMinimumRangeChanges()
    }

    private bindIsAccuracyTesting(): void {
        const isAccuracyTestingChange = this.form.controls.isAccuracyTesting.valueChanges.subscribe(val => {
            const updateTolerance = val === 'true' && this.form.controls.isRepeatabilityTesting.value === 'true'
            if (!updateTolerance) {
                return // --------->
            }

            const repeatabilityToleranceGroup = this.form.controls.repeatabilityTolerance as FormGroup
            const selectedUoMCode = (repeatabilityToleranceGroup.controls.unitOfMeasurement.value || { uomCode: null }).uomCode
            const isDifferentCode = selectedUoMCode && !this.toleranceUoMs.find(it => it.uomCode === selectedUoMCode)
            if (this.toleranceUoMs.length === 0 || isDifferentCode) {
                this.form.patchValue({
                    repeatabilityTolerance: {
                        unitOfMeasurement: null
                    }
                })
            }
        })
        this.addSubscription(isAccuracyTestingChange)
    }

    private bindMinimumRangeChanges(): void {
        const inputGroup = this.form.controls.input as FormGroup
        const minimumRangeChange = inputGroup.controls.minimumRange.valueChanges.subscribe(val => {
            this.form.patchValue({
                expected: {
                    minimumRange: val
                }
            })
        })
        this.addSubscription(minimumRangeChange)
    }

    private bindUomChanges(): void {
        const inputGroup = this.form.controls.input as FormGroup
        const uomChange = inputGroup.controls.unitOfMeasurement.valueChanges.subscribe(val => {
            this.setToleranceUoMs(val)
            const patch: any = {
                expected: {
                    unitOfMeasurement: val
                },
                accuracyTolerance: {
                    unitOfMeasurement: val
                }
            }

            if (this.form.controls.isRepeatabilityTesting.value === 'true') {
                patch.repeatabilityTolerance = {
                    unitOfMeasurement: val
                }
            }

            this.form.patchValue(patch)
        })
        this.addSubscription(uomChange)
    }

    private setToleranceUoMs(val: any): void {
        this.toleranceUoMs = [val, this.percentageUOM]
    }

    private watchMainFormControl(): void {
        this.formChangeSubscription?.unsubscribe()
        this.formChangeSubscription = this.form.valueChanges.subscribe(_form => {
            const raw = this.form.getRawValue()

            const point = this.form.get('numberOfPoints.point').value
            const eqCoreDetails = deepCopy(this.templateDetails) as EquipmentTemplateCoreDetails
            eqCoreDetails.detail.numberOfPoint = point?.['key'] as number

            this.setShowTip()
            const isValid = this.validationService.isValid(raw)

            this.store.dispatch(new TemplateFormAction.SelectCurrentTemplateFormsAction(raw))
            this.store.dispatch(new EquipmentTemplateAction.EquipmentTemplateWasModifiedAction(
                eqCoreDetails,
                isValid
            ))
        })
    }

    private setShowTip(): void {
        this.showTip = !(this.form.get('isAccuracyTesting').value === 'true' || this.form.get('isRepeatabilityTesting').value === 'true')
    }
}
