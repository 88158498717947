<div class="hyperlink-container">
    <label class="title em-u-margin-bottom">
        Hyperlink
        <ng-container *ngIf="isOnline">
            <div class="view-all-container em-u-clickable"
                 [ngClass]="{'grey-button-disabled': isLoading || hasError}"
                 (click)="onClickViewAll()">
                <app-unity-icon unitySvgName="search"></app-unity-icon>
                <span>View all</span>
                ({{linkCount}})
            </div>
        </ng-container>
    </label>
    <p *ngIf="!isOnline"
       class="hyperlink-info-row">Unable to load data when offline.</p>
    <ng-container *ngIf="isOnline">
        <app-loading-label [isLoading]="isLoading"
                           [hasError]="hasError"
                           (clickErrorLabel)="reloadHyperlink()">
            <p *ngIf="linkCount === 0">-</p>
            <div *ngFor="let procedure of hyperlinkInfo?.procedureList | slice:0:3"
                 class="em-u-margin-bottom hyperlink-info-row"
                 [ngClass]="{'blue-button': !!procedure.externalLink}"
                 (click)="onClickProcedure(procedure)">
                <app-unity-icon unitySvgName="link-external"
                                *ngIf="procedure.externalLink"
                                size="small"
                                class="em-u-space-right-half"></app-unity-icon>
                <span appHoverTooltip
                      class="text">{{getProcedureLinkName(procedure)}}</span>
            </div>
        </app-loading-label>
    </ng-container>
</div>