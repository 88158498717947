<section #equipmentHistoryWebRef
         class="web-view em-l-container">
    <div class="em-l-grid__item">
        <a class="back-button"
           (click)="navigateBack()">
            <svg class="em-c-icon em-c-icon--small em-u-margin-right-half a-icon">
                <use xlink:href="./assets/unity-1.2.0/images/em-icons.svg#chevron-left-filled"
                     xmlns:xlink="http://www.w3.org/1999/xlink"></use>
            </svg>
            <span class="a-text">
                Back to PM
            </span>
        </a>
    </div>
    <h1>
        Equipment History - {{equipmentHistory?.equipmentTag}}
    </h1>
    <app-expandable-label [value]="equipmentHistory?.equipmentDescription"
                          class="workorder-description"></app-expandable-label>
    <app-list [items]="equipmentHistory?.calibrationHistories"
              [clickable]=false>
        <ng-template #header>
            <th scope="col"
                class="em-c-table__header-cell em--no-wrap em-u-width-15">
                PM PERFORMED DATE
            </th>
            <th scope="col"
                class="em-c-table__header-cell em--no-wrap em-u-width-10">
                NOTIFICATION
            </th>
            <th scope="col"
                class="em-c-table__header-cell em--no-wrap em-u-width-45">
                COMMENT
            </th>
            <th scope="col"
                class="em-c-table__header-cell em--no-wrap em-u-width-20">
                STATUS
            </th>
            <th scope="col"
                class="em-c-table__header-cell em--no-wrap em-u-width-10">
                REPAIR WO #
            </th>
        </ng-template>
        <ng-template #content
                     let-item>
            <td class="em-c-table__cell">
                {{ item.pmPerformedDate | date: 'MMM dd, yyyy' }}
            </td>
            <td class="em-c-table__cell">
                {{ item.notificationNumber }}
            </td>
            <td class="em-c-table__cell"
                [innerHTML]="item.comment">
            </td>
            <td class="em-c-table__cell">
                {{ item.resultStatus }}
            </td>
            <td class="em-c-table__cell">
                {{ item.repairWorkOrderNumber }}
            </td>
        </ng-template>
    </app-list>
</section>
