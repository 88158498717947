import { Component, Input } from '@angular/core'
import { FormArray, FormControl } from '@angular/forms'
import { includes, map, toString } from 'lodash'

export interface CheckboxOption {
    name: string
    value: any
}

@Component({
    selector: 'app-checkbox-group',
    templateUrl: './checkbox-group.component.html',
    styleUrls: ['./checkbox-group.component.scss']
})
export class CheckboxGroupComponent {

    @Input() options: CheckboxOption[]
    @Input() selectedOptionForm: FormArray

    public onOptionChange(event): void {
        const currentCheckboxIndex = this.selectedOptionForm.controls.findIndex(control => toString(control.value) === event.target.value)
        if (currentCheckboxIndex !== -1) {
            this.selectedOptionForm.removeAt(currentCheckboxIndex)
        } else {
            this.selectedOptionForm.push(new FormControl(event.target.value))
        }
    }

    public isOptionChecked(value): boolean {
        return includes(map(this.selectedOptionForm.controls, v => toString(v.value)), toString(value))
    }
}
