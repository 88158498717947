<div #equipmentHistoryRef
     class="slide-up-overlay">
    <app-overlay-header-info [header]="'Maintenance History'"
                             [subHeader]="'Equipment'"
                             [itemId]="equipmentHistory?.equipmentId"
                             [itemDescription]="equipmentHistory?.equipmentDescription"
                             [overlayId]="overlayId"></app-overlay-header-info>
    <div class="body web-body-view">
        <section class="web-view em-l-container">
            <app-list [items]="equipmentHistory?.calibrationHistories"
                      [clickable]=false>
                <ng-template #header>
                    <th scope="col"
                        class="em-c-table__header-cell em--no-wrap em-u-width-15">
                        PM PERFORMED DATE
                    </th>
                    <th scope="col"
                        class="em-c-table__header-cell em--no-wrap em-u-width-10">
                        NOTIFICATION
                    </th>
                    <th scope="col"
                        class="em-c-table__header-cell em--no-wrap em-u-width-45">
                        COMMENT
                    </th>
                    <th scope="col"
                        class="em-c-table__header-cell em--no-wrap em-u-width-20">
                        STATUS
                    </th>
                    <th scope="col"
                        class="em-c-table__header-cell em--no-wrap em-u-width-10">
                        REPAIR WO #
                    </th>
                </ng-template>
                <ng-template #content
                             let-item>
                    <td class="em-c-table__cell">
                        {{ item.pmPerformedDate | date: 'MMM dd, yyyy' }}
                    </td>
                    <td class="em-c-table__cell">
                        {{ item.notificationNumber }}
                    </td>
                    <td class="em-c-table__cell"
                        [innerHTML]="item.comment">
                    </td>
                    <td class="em-c-table__cell">
                        {{ item.resultStatus }}
                    </td>
                    <td class="em-c-table__cell">
                        {{ item.repairWorkOrderNumber }}
                    </td>
                </ng-template>
            </app-list>
        </section>
    </div>
    <div class="body responsive-body-view">
        <div *ngFor="let item of equipmentHistory?.calibrationHistories">
            <app-section [title]="'WN ' + item.notificationNumber">
                <div body
                     class="overlay-info-field-container">
                    <div *ngIf="item.pmPerformedDate"
                         class="calibration-info">
                        <label>Date PM Performed</label>
                        <p>{{ item.pmPerformedDate | date: 'MMM dd, yyyy' }}</p>
                    </div>
                    <div *ngIf="item.resultStatus"
                         class="calibration-info">
                        <label>Status</label>
                        <p>{{ item.resultStatus }}</p>
                    </div>
                    <div *ngIf="item.comment"
                         class="calibration-info">
                        <label>Comment</label>
                        <div [innerHTML]="item.comment"></div>
                    </div>
                    <div *ngIf="item.repairWorkOrderNumber"
                         class="calibration-info">
                        <label>Repair WO#</label>
                        <p>{{ item.repairWorkOrderNumber }}</p>
                    </div>
                </div>
            </app-section>
        </div>
    </div>
</div>