import { Modal, ModalType } from '@app/modules/modal-container/models/modal.model'

export const reportFilterNewModal = {
    title: 'SAVE AS NEW PRESET',
    icon: 'circle-exclamation-mark',
    footer: false,
    close: true,
    type: ModalType.ReportNewFilterPreset
} as Modal

export const reportFilterReNameModal = {
    title: 'RENAME PRESET',
    icon: 'circle-exclamation-mark',
    footer: false,
    close: true,
    type: ModalType.ReportRenameFilterPreset
} as Modal

export const reportFilterDeleteModal = {
    title: 'DELETE PRESET',
    icon: 'circle-exclamation-mark',
    footer: false,
    close: true,
    type: ModalType.ReportRemoveFilterPreset
} as Modal
