import { createAction, props, union } from '@ngrx/store'

import { FilterFields } from '@app/modules/equipment/models/equipment-filter-fields.model'
import { NotificationBasic } from '@app/modules/work-order/models/notification-basic.model'
import { WorkOrderDetails } from '@app/modules/work-order/models/work-order-details.model'

/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match */

export const LoadWorkOrderDetailsAction =
    createAction('[WorkOrder] Load details', props<{ id: string, fetchNotification: boolean }>())
export const LoadWorkOrderDetailsSuccessAction =
    createAction('[WorkOrder] Load details success', props<{ workOrderDetails: WorkOrderDetails }>())

export const GetWorkOrderMainWorkCenterAction =
    createAction('[WorkOrder] Get work order main work center')
export const GetWorkOrderPlannerGroupAction =
    createAction('[WorkOrder] Get work order planner group')
export const GetWorkOrderPlantSectionAction =
    createAction('[WorkOrder] Get work order plant section')
export const GetWorkOrderMaintenancePlantAction =
    createAction('[WorkOrder] Get work order maintenance plant')
export const GetWorkOrderLocationAction =
    createAction('[WorkOrder] Get work order location')
export const GetWorkOrderMainWorkCenterSuccessAction =
    createAction('[WorkOrder] Get work order main work center success', props<{ mainWorkCenter: string[] }>())
export const GetWorkOrderPlannerGroupSuccessAction =
    createAction('[WorkOrder] Get work order planner group success', props<{ PlannerGroup: string[] }>())
export const GetWorkOrderPlantSectionSuccessAction =
    createAction('[WorkOrder] Get work order plant section success', props<{ plantSection: string[] }>())
export const GetWorkOrderMaintenancePlantSuccessAction =
    createAction('[WorkOrder] Get work order maintenance plant success', props<{ maintenancePlant: string[] }>())
export const GetWorkOrderLocationSuccessAction =
    createAction('[WorkOrder] Get work order location success', props<{ location: string[] }>())
export const ApplyWorkOrderMainWorkCenterFilterAction =
    createAction('[WorkOrder] Apply main work order filter action', props<{ filter: FilterFields[] }>())
export const GetNotificationAction =
    createAction('[WorkOrder] Get notification info', props<{ workOrderNumber: string, notificationNumber: string }>())
export const GetNotificationSuccessAction =
    createAction('[WorkOrder] Get notification info success', props<{ notification: NotificationBasic }>())
export const GetNotificationFailureAction =
    createAction('[WorkOrder] Get notification info failure')
export const GetNotificationsAction =
    createAction('[WorkOrder] Get notifications info', props<{ workOrderNumber: string, notificationNumbers: string[] }>())

const all = union({
    LoadWorkOrderDetailsAction,
    LoadWorkOrderDetailsSuccessAction,
    GetWorkOrderMainWorkCenterAction,
    GetWorkOrderPlannerGroupAction,
    GetWorkOrderPlantSectionAction,
    GetWorkOrderMaintenancePlantAction,
    GetWorkOrderLocationAction,
    GetWorkOrderMainWorkCenterSuccessAction,
    GetWorkOrderPlannerGroupSuccessAction,
    GetWorkOrderPlantSectionSuccessAction,
    GetWorkOrderMaintenancePlantSuccessAction,
    GetWorkOrderLocationSuccessAction,
    ApplyWorkOrderMainWorkCenterFilterAction,
    GetNotificationAction,
    GetNotificationSuccessAction,
    GetNotificationFailureAction,
    GetNotificationsAction
})
export type WorkOrderActions = typeof all
