import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'

import { APIPagedListDataSource } from '@app/modules/shared/components/api-paged-list/api-paged-list.component'
import { APIPagedListService } from '@app/modules/shared/services/api-paged-list.service'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import { GetFlamingoFormsAction } from '@app/store/flamingo/flamingo.actions'
import { isListLoading, queryFlamingoForms, totalFlamingoFormsCount } from '@app/store/flamingo/flamingo.selectors'
import { FlamingoForm } from '../../models/flamingo-form.model'

@Component({
    selector: 'app-flamingo-list-table',
    templateUrl: './flamingo-list-table.component.html',
    styleUrls: ['./flamingo-list-table.component.scss'],
    providers: [APIPagedListService]
})
export class FlamingoListTableComponent extends SafeUnsubscriberComponent implements OnInit {

    @Input() searchText = ''
    @Input() clickable = true

    @Output() flamingoFormClicked = new EventEmitter<FlamingoForm>()

    public sortBy = 'name'
    public isDesc = false

    public totalCount$: Observable<number>

    constructor(
        private store: Store<AppState>,
        private apiPagedListService: APIPagedListService
    ) {
        super()
    }

    ngOnInit(): void {
        this.totalCount$ = this.store.select(totalFlamingoFormsCount)
        this.addSubscriptions([
            this.store.select(isListLoading).subscribe(isLoading =>
                this.apiPagedListService.isRequestInProgress$.next(isLoading))
        ])
    }

    public pagedListDataSource: APIPagedListDataSource = (offset, pageSize) => {
        const query = {
            currentPage: Math.floor(offset / pageSize) + 1,
            itemPerPage: pageSize,
            isDesc: this.isDesc,
            sortBy: this.sortBy,
            search: this.searchText
        }
        this.store.dispatch(GetFlamingoFormsAction(query))
        return this.store.select(queryFlamingoForms(query))
    }

    public refreshList(): void {
        // When sorting/filtering change, we need to push user back to first page,
        // else positioning might be wrong.
        this.apiPagedListService.refreshList({
            resetPosition: true
        })
    }

    public applySearch(searchText): void {
        this.searchText = searchText
        this.refreshList()
    }

    public itemClicked(flamingoForm: FlamingoForm): void {
        if (!this.clickable) {
            return
        }
        this.flamingoFormClicked.emit(flamingoForm)
    }

    public applySorting(column: string): void {
        if (this.sortBy === column) {
            this.isDesc = !this.isDesc
        } else {
            this.sortBy = column
            this.isDesc = false
        }
        this.refreshList()
    }
}
