import { Injectable } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'

import { SpecialUomCode } from '@app/models/special-uom.model'
import { Tolerance } from '@app/modules/shared/models/engineering-units/tolerance.model'
import { UnitValue } from '@app/modules/shared/models/engineering-units/unit-value.model'
import { isNotAValue, round } from '@app/utils/app-utils.function'
import { CalibrationDetails } from '../models/calibration-details.model'
import { CalibrationResultStatusEnum } from '../models/calibration-result-status.enum'
import { CalibrationResult } from '../models/calibration-result.model'
import { CalibrationValidationStatus } from '../models/calibration-validation-status.enum'
import { OnePointTemplate } from '../models/one-point-template.model'
import { CalibrationInitializerService } from './calibration-initializer.service'
import { CalibrationResultStatusService } from './calibration-result-status.service'

@Injectable({
    providedIn: 'root'
})
export class OnePointCalibrationService {
    constructor(
        private formBuilder: FormBuilder,
        private calibrationResultStatusService: CalibrationResultStatusService,
        private calibrationInitializerService: CalibrationInitializerService
    ) { }

    public initialize(form: FormGroup, calibrationDetails: CalibrationDetails, template: OnePointTemplate): void {

        // If calibrationResult exist, use that to fill in the form, else create a new empty []
        const calibrationResultSet = (calibrationDetails?.calibrationResult ?? new CalibrationResult())

        if (!calibrationResultSet.results) {
            calibrationResultSet.results = []
        }

        const resultSetControl = this.calibrationInitializerService.initializeResultSetForm(
            '',
            calibrationResultSet.results[0],
            template.numberOfPoint,
            false
        )

        this.updateCalibrationForm(resultSetControl, form)
    }

    public validateValue(value: null | number, template: OnePointTemplate): CalibrationValidationStatus {
        if (value === null) {
            return CalibrationValidationStatus.Initialize
        }

        const range = this.toleranceToNumericRange(template.tolerance, template.expectedReading)
        if (value < range.min || value > range.max) {
            return CalibrationValidationStatus.Invalid
        }

        return CalibrationValidationStatus.Valid
    }

    public updateCalibrationResultStatus(form: FormGroup, template: OnePointTemplate): void {
        const resultStatusValue = this.calculateCalibrationResult(form, template)
        const resultStatusControl = form.get('calibrationResultStatus') as FormControl
        this.calibrationResultStatusService.updateCalibrationResultStatus(resultStatusControl, resultStatusValue)
    }

    public calculateCalibrationResult(form: FormGroup, template: OnePointTemplate): CalibrationResultStatusEnum {
        const calibrationResult = form.getRawValue().calibrationResult as CalibrationResult
        const resultSet = calibrationResult.results[0].resultSet[0]
        const asFound = resultSet.asFound
        const asLeft = resultSet.asLeft

        const requiredValues: any[] = [
            asFound,
            asLeft
        ]

        for (const value of requiredValues) {
            if (isNotAValue(value)) {
                return null
            }
        }

        if (this.validateValue(asLeft, template) === CalibrationValidationStatus.Invalid) {
            return CalibrationResultStatusEnum.Failed
        }

        if (this.validateValue(asFound, template) === CalibrationValidationStatus.Invalid) {
            return CalibrationResultStatusEnum.FailedAdjustedPassed
        }

        return CalibrationResultStatusEnum.Passed
    }

    public toleranceToNumericRange(tolerance: Tolerance, expectedValue: UnitValue): { min: number, max: number } {
        const result = {
            min: 0,
            max: 0
        }

        const refValue = expectedValue.value

        if (tolerance.unitOfMeasurement.uomCode === SpecialUomCode.Percentage) {
            result.min = refValue + (refValue * tolerance.lowerRange / 100)
            result.max = refValue + (refValue * tolerance.higherRange / 100)
        } else {
            result.min = refValue + tolerance.lowerRange
            result.max = refValue + tolerance.higherRange
        }

        result.min = round(result.min)
        result.max = round(result.max)

        return result
    }

    private updateCalibrationForm(resultSetControl: FormGroup, form: FormGroup): void {
        const results = this.formBuilder.group({
            results: this.formBuilder.array([resultSetControl])
        })

        form.setControl('calibrationResult', results)
    }
}
