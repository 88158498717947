import { FormGroup } from '@angular/forms'
import { Component, OnInit, Input } from '@angular/core'
import { safeObjGet } from '@app/utils/app-utils.function'
import { Option, OptionReturnType } from '@app/modules/shared/models/option.model'

@Component({
    selector: 'app-options',
    templateUrl: './options.component.html',
    styleUrls: ['./options.component.scss']
})
export class OptionsComponent implements OnInit {
    @Input() options: object[]
    @Input() defaultOption: string
    @Input() disabled = false
    @Input() formGroup: FormGroup
    @Input() formControlPath: string
    @Input() returnType = OptionReturnType.key
    @Input() labelStyle: string
    @Input() key = 'key'
    @Input() value = 'value'

    public selected: string

    ngOnInit(): void {
        this.selected = this.defaultOption
    }

    public select(option: Option, elementId: string): void {
        if (this.isDisabled(option)) {
            return
        }
        this.selected = Object(option)[this.key]
        document.getElementById(elementId).click()
    }

    public getDefaultOption(): string {
        if (typeof this.defaultOption === 'object') {
            return this.defaultOption[this.key]
        }
        return this.defaultOption
    }
    public isDisabled(option: object): boolean {
        if (this.disabled || safeObjGet(option, 'disabled')) {
            this.selected = this.defaultOption
            return true
        }
        return false
    }

    public returnOption(option: Option): any {
        switch (this.returnType) {
            case OptionReturnType.key:
                return option[this.key]
            case OptionReturnType.value:
                return option[this.value]
            case OptionReturnType.object:
                return option
            default:
                return option
        }
    }
}
