import { FormControlError } from '@app/modules/shared/models/form-control-error.model'
import { FormValidatorResult } from '@app/modules/shared/models/form-validator-result.model'
import { FormErrorHandler } from './form-error-handlers/abstract-form-error-handler'

export class FormErrorValidator {

    private rootHandler?: FormErrorHandler
    private lastHandler?: FormErrorHandler

    public handleError(formControlError: FormControlError): FormValidatorResult {
        if (!this.rootHandler) {
            throw new Error('No FormErrorHandler set!')
        }
        // Start at the top of the chain with default Result Value
        return this.rootHandler.handle(formControlError, { preventSubmit: false, errorMessages: [] })
    }

    public use(formErrorHandler: FormErrorHandler): FormErrorValidator {
        if (!this.rootHandler) {
            this.rootHandler = formErrorHandler
            this.lastHandler = formErrorHandler
        }
        this.lastHandler = this.lastHandler.setNext(formErrorHandler)
        return this
    }
}
