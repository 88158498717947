<div class="em-c-collapsible-toolbar">
    <div class="em-c-toolbar">
        <div class="filter-section">
            <ng-content></ng-content>
            <app-dropdown-checkbox class="filter-option"
                                   width="100%"
                                   padding="0.86rem 0.6rem"
                                   [options]="equipmentFilterLists.plannerGroup.value"
                                   [title]="equipmentFilterLists.plannerGroup.name"
                                   [callback]="filterOptionClicked.bind(this)"
                                   [grayOutText]="!isEquipmentFilterSelected('plannerGroup')"
                                   [showSearchBox]="true"
                                   [scrollableBox]="true"
                                   [showClearBtn]="true"
                                   [showSelected]="true"></app-dropdown-checkbox>
            <app-dropdown-checkbox class="filter-option"
                                   width="100%"
                                   padding="0.86rem 0.6rem"
                                   [options]="equipmentFilterLists.mainWorkCenter.value"
                                   [title]="equipmentFilterLists.mainWorkCenter.name"
                                   [callback]="filterOptionClicked.bind(this)"
                                   [grayOutText]="!isEquipmentFilterSelected('mainWorkCenter')"
                                   [showSearchBox]="true"
                                   [scrollableBox]="true"
                                   [showClearBtn]="true"
                                   [showSelected]="true"></app-dropdown-checkbox>
            <app-dropdown-checkbox class="filter-option"
                                   width="100%"
                                   padding="0.86rem 0.6rem"
                                   [options]="equipmentFilterLists.maintenancePlant.value"
                                   [title]="equipmentFilterLists.maintenancePlant.name"
                                   [callback]="filterOptionClicked.bind(this)"
                                   [grayOutText]="!isEquipmentFilterSelected('maintenancePlant')"
                                   [showSearchBox]="true"
                                   [scrollableBox]="true"
                                   [showClearBtn]="true"
                                   [showSelected]="true"></app-dropdown-checkbox>
            <app-dropdown-checkbox class="filter-option"
                                   width="100%"
                                   padding="0.86rem 0.6rem"
                                   [options]="equipmentFilterLists.plantSection.value"
                                   [title]="equipmentFilterLists.plantSection.name"
                                   [callback]="filterOptionClicked.bind(this)"
                                   [grayOutText]="!isEquipmentFilterSelected('plantSection')"
                                   [showSearchBox]="true"
                                   [scrollableBox]="true"
                                   [showClearBtn]="true"
                                   [showSelected]="true"></app-dropdown-checkbox>
            <app-dropdown-checkbox class="filter-option"
                                   width="100%"
                                   padding="0.86rem 0.6rem"
                                   [options]="equipmentFilterLists.location.value"
                                   [title]="equipmentFilterLists.location.name"
                                   [callback]="filterOptionClicked.bind(this)"
                                   [grayOutText]="!isEquipmentFilterSelected('location')"
                                   [showSearchBox]="true"
                                   [scrollableBox]="true"
                                   [showClearBtn]="true"
                                   [showSelected]="true"></app-dropdown-checkbox>
            <app-dropdown-checkbox class="filter-option"
                                   width="100%"
                                   padding="0.86rem 0.6rem"
                                   [options]="equipmentFilterLists.criticality.value"
                                   [title]="equipmentFilterLists.criticality.name"
                                   [callback]="filterOptionClicked.bind(this)"
                                   [grayOutText]="!isEquipmentFilterSelected('criticality')"
                                   [showSearchBox]="true"
                                   [scrollableBox]="true"
                                   [showClearBtn]="true"
                                   [showSelected]="true"></app-dropdown-checkbox>
            <app-dropdown-checkbox class="filter-option"
                                   width="100%"
                                   padding="0.86rem 0.6rem"
                                   [options]="equipmentFilterLists.category.value"
                                   [title]="equipmentFilterLists.category.name"
                                   [callback]="filterOptionClicked.bind(this)"
                                   [grayOutText]="!isEquipmentFilterSelected('category')"
                                   [showSearchBox]="true"
                                   [scrollableBox]="true"
                                   [showClearBtn]="true"
                                   [showSelected]="true"></app-dropdown-checkbox>
            <app-dropdown-checkbox class="filter-option"
                                   width="100%"
                                   padding="0.86rem 0.6rem"
                                   [options]="equipmentFilterLists.classification.value"
                                   [title]="equipmentFilterLists.classification.name"
                                   [callback]="filterOptionClicked.bind(this)"
                                   [grayOutText]="!isEquipmentFilterSelected('classification')"
                                   [showSearchBox]="true"
                                   [scrollableBox]="true"
                                   [showClearBtn]="true"
                                   [showSelected]="true"></app-dropdown-checkbox>
        </div>
        <div class="clear-filter-section">
            <button (click)="clearAllFilters()"
                    class="em-c-btn btn">
                <div class="em-c-btn__inner">
                    <img class="clear-icon em-c-icon em-c-icon--small em-c-btn__icon"
                         src="./assets/images/reset_gblack.svg">
                    <p>Clear All Filter</p>
                </div>
            </button>
        </div>
    </div>
</div>