import { Component, forwardRef, Input } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
    selector: 'app-simple-checkbox',
    templateUrl: './simple-checkbox.component.html',
    styleUrls: ['./simple-checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SimpleCheckboxComponent),
            multi: true
        }
    ]
})
export class SimpleCheckboxComponent implements ControlValueAccessor {

    @Input() name = ''
    @Input() id = ''
    @Input() value: any

    // Use ngModel or reactive form to set this
    public checked = false
    public isDisabled = false

    public checkboxChanged(event: any): void {
        const target = event.target
        if (this.isDisabled) {
            // This reverse the view state
            this.checked = !target.checked
            return
        }

        this.checked = target.checked
        this.onChange(this.checked ? this.value : null)
        this.onTouched()
    }

    writeValue(value: any): void {
        this.checked = value === this.value
    }

    setDisabledState(isDisabled): void {
        this.isDisabled = isDisabled
    }

    registerOnChange(fn): void {
        this.onChange = fn
    }

    registerOnTouched(fn): void {
        this.onTouched = fn
    }

    private onChange: any = () => { }
    private onTouched: any = () => { }

}
