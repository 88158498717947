<div id="offline-bar"
     *ngIf="!offlineService?.isOnline">Offline</div>
<header class="em-c-header em-c-header--blue"
        [ngClass]="headerCSSClass"
        [@slideInFromTop]
        role="banner">
    <div class="em-l-container">
        <div class="em-c-header__body">
            <h2 class="em-c-header__title">
                <a routerLink="/"
                   rel="home"
                   class="em-c-header__title-link">
                    MRMA
                </a>

                <span class="em-c-primary-nav__item dev-menu"
                      *ngIf="isDevModeUI">
                    <a class="em-js-nav-dropdown-trigger dev-mode--banner"
                       (click)="isDevModeMenuExpanded = !isDevModeMenuExpanded">
                        Dev Mode {{ isLocalBackend ? '(Local Backend)' : ''}}
                    </a>
                    <ul class="em-c-primary-nav__sublist em-js-nav-dropdown dev-menu-dropdown"
                        [ngClass]="{ 'em-is-active': isDevModeMenuExpanded }">
                        <li class="em-c-primary-nav__subitem"
                            *appFeatureToggle="'showOpenInDev'">
                            <a class="em-c-primary-nav__sublink"
                               (click)="openCurrentLinkInDevEnv()">
                                Open in DevEnv
                            </a>
                        </li>
                        <li class="em-c-primary-nav__subitem">
                            <a class="em-c-primary-nav__sublink"
                               (click)="disableDevModeUI()">
                                Disable DevMode UI
                            </a>
                        </li>
                        <h5>Current User Roles:</h5>
                        <li *ngFor="let entry of userPermission | keyvalue"
                            class="dev-menu--item-status"
                            [ngClass]="entry.value ? 'dev-menu--item-status--green' : 'dev-menu--item-status--red' ">
                            {{ entry.key }}
                        </li>
                        <h5>Web Component Load Statuses:</h5>
                        <ng-container *ngIf="webComponentLoadStatus$">
                            <li *ngFor="let entry of webComponentLoadStatus$ | async | keyvalue"
                                class="dev-menu--item-status"
                                [ngClass]="entry.value ? 'dev-menu--item-status--green' : 'dev-menu--item-status--red' ">
                                {{ entry.key }} ({{ entry.value }})
                            </li>
                        </ng-container>
                    </ul>
                </span>
            </h2>

            <app-header-mobile class='em-c-header_nav-mobile'
                               [isMenuActive]="isMenuActive"
                               (menuToggled)='isMenuActive = $event'>
            </app-header-mobile>
            <div class="em-c-header__nav-container"
                 [ngClass]="{ 'em-is-active': isMenuActive }">
                <div [hidden]="false">
                    <app-header-navigation (showMenu)="isMenuActive = $event"></app-header-navigation>
                </div>

                <app-header-status></app-header-status>
            </div>
        </div>
    </div>
    <app-progress-bar></app-progress-bar>
</header>