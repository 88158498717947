<div class="em-c-field {{classList}}">
    <label class="em-c-field__label">
        <div *ngIf="title">
            {{title}}
            <span *ngIf="subtitle">
                {{subtitle}}
            </span>
        </div>
        <div *ngIf="!title">
            <ng-content select="[title]"></ng-content>
        </div>
    </label>
    <div class="em-c-field__body">
        <ng-content></ng-content>
    </div>
</div>
