<div class="header">
    <div class="nav">
        <div class="em-l-container">
            <div (click)="closeOverlay()"
                 class="close em-u-clickable">Close X</div>
            <h1>{{ header }}</h1>
        </div>
    </div>
    <div class="loading-bar loading" *ngIf="isLoading"></div>
    <div class="description em-l-container" *ngIf="subHeader">
        <h4>{{ subHeader }}</h4>
        <h1>
            {{ itemId || '-'}}<br class="breakline"><span>{{ itemDescription }}</span>
        </h1>
    </div>
</div>
