<form (submit)="onSubmit($event)"
      role="search"
      class="em-c-search-form em-js-header-search">
    <label for="search"
           class="em-c-search__label em-u-is-vishidden">Search</label>
    <div class="em-c-search__body"
         [ngStyle]="{'width': width}">
        <input [ngModel]="_searchText"
               (ngModelChange)="onSearchTextChange($event)"
               class="em-c-search__input"
               name="search"
               [type]="inputType"
               id="search"
               placeholder="{{ placeHolder }}" />
        <svg *ngIf="!showButton"
             class="em-c-icon em-c-icon--small search-icon"
             data-em-icon-path="assets/unity-1.2.0/images/em-icons.svg">
            <use xmlns:xlink="http://www.w3.org/1999/xlink"
                 xlink:href="assets/unity-1.2.0/images/em-icons.svg#search"></use>
        </svg>
        <button *ngIf="showButton"
                class="em-c-btn em-c-btn--primary"
                aria-label="Search">
            <div class="em-c-btn__inner">
                <svg class="em-c-icon em-c-icon--small em-c-btn__icon"
                     data-em-icon-path="assets/unity-1.2.0/images/em-icons.svg">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink"
                         xlink:href="assets/unity-1.2.0/images/em-icons.svg#search"></use>
                </svg>
            </div>
        </button>
    </div>
</form>
