import { ElementRef, Injectable, QueryList } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subscription } from 'rxjs'
import { debounceTime, filter } from 'rxjs/operators'

import { mrmaAlertConfigs } from '@app/models/alert-configuration.model'
import { AppPolicyType } from '@app/models/app-policy/policy-type.enum'
import { FlamingoForm } from '@app/modules/flamingo/models/flamingo-form.model'
import { FlamingoLoadingState } from '@app/modules/flamingo/models/flamingo-loading-state.type'
import { FlamingoFormSnapshot } from '@app/modules/flamingo/models/flamingo-snapshot.model'
import { modalContent } from '@app/modules/modal-container/constants/modal-message-content.constant'
import { Modal } from '@app/modules/modal-container/models/modal.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import { calibrationDetails } from '@app/store/calibration/calibration.selectors'
import { equipmentFlamingoFormsMapping, isEquipmentFlamingoFormLoading } from '@app/store/equipment/selectors/equipment-flamingo-form.selectors'
import { isLoading } from '@app/store/loader/loader.selectors'
import { ShowModalAction } from '@app/store/modal/modal.actions'
import { deepCopy, isEmptyObject, isNotAValue, safeCallback } from '@app/utils/app-utils.function'
import { AccessibleByPolicy } from '@app/utils/decorators/access-policy.decorator'
import { environment } from '@environments/environment'
import { CalibrationDetails } from '../models/calibration-details.model'
import { CalibrationStatusEnum } from '../models/calibration-status.enum'

@Injectable()
export class CalibrationFlamingoService extends SafeUnsubscriberComponent {
    public calibrationDetail: CalibrationDetails        // Calibration reference obj

    // This is the place where we actually update form data (this one is not pass as @Input to the viewer)
    // so updating this will not trigger ChangeDetection.
    // TODO: Maybe think about only deepCopying the `flamingoForms` inside this `CalibrationDetails` object instead of the whole thing
    public flamingoCalibration: CalibrationDetails      // Calibration deepCopy obj

    public flamingoEnv = environment.flamingoForm.environment
    public flamingoToken = ''
    public flamingoSubscriptions: Subscription[] = []
    public readonly isFlamingoDataChanged = new BehaviorSubject<boolean>(false)

    // This is the one that is passed to the <flamingo-viewer> and its value should never change
    // until we are ready to save/update form, else Ng will rerender the form and wipe out data.
    public flamingoForms: FlamingoFormSnapshot[] = []

    private flamingoComponents: QueryList<ElementRef>[] = []
    private flamingoFormListeners: (() => void)[] = []
    private flamingoStateListeners: (() => void)[] = []
    private flamingoAPIPayloadListeners: (() => void)[] = []
    private flamingoVersionChangedDetected = false
    private flamingoFormsLoadingState: FlamingoLoadingState[] = []
    private flamingoIsLoading = new BehaviorSubject(false)
    private flamingoFormIdsRemoved: FlamingoFormSnapshot[] = []
    private flamingoFormIdsAdded: FlamingoFormSnapshot[] = []

    private noUpdateRequired = false

    constructor(
        private router: Router,
        private store: Store<AppState>,
        private toastrService: ToastrService
    ) {
        super()
    }

    public get inReportPage(): boolean {
        return this.router.url.includes('/reports/')
    }

    public getFlamingLoadingState(index: number): FlamingoLoadingState {
        if (this.flamingoFormsLoadingState[index]) {
            return this.flamingoFormsLoadingState[index]
        }

        return null
    }

    public isFlamingoLoading(index: number): boolean {
        if (!isNotAValue(this.flamingoFormsLoadingState[index])) {
            return this.flamingoFormsLoadingState[index] === 'loading'
        }

        return true
    }

    public isAnyFlamingoLoading(): boolean {
        return this.flamingoFormsLoadingState.indexOf('loading') > -1
    }

    /**
     * Write back form data to locally stored flamingoForms array.
     * * We only want to write these back on submit, else the form will rerender on every keystrokes.
     */
    public reconcileLocalFormData(): void {
        this.flamingoCalibration.flamingoForm.forEach((form: FlamingoFormSnapshot, i: number) => {
            this.flamingoForms[i].formSchema = JSON.parse(form.formSchema)
            this.flamingoForms[i].uiSchema = JSON.parse(form.uiSchema)
            this.flamingoForms[i].formData = JSON.parse(form.formData)
        })
    }

    public watchFlamingoEvent(flamingoComponent: QueryList<ElementRef>[], calibrationDetail?: CalibrationDetails): void {
        this.flamingoComponents = flamingoComponent

        if (calibrationDetail) {
            this.calibrationDetail = calibrationDetail
        }

        const returnJsonStringOrNull = (obj: any) => isEmptyObject(obj) ? null : JSON.stringify(obj)

        const updateForm = (index: number, event: any) => {
            if (this.noUpdateRequired || !this.flamingoCalibration.flamingoForm[index] || this.inReportPage) {
                return
            }

            this.isFlamingoDataChanged.next(true)

            this.flamingoCalibration.flamingoForm[index].formSchema = returnJsonStringOrNull(event.detail.schema)
            this.flamingoCalibration.flamingoForm[index].uiSchema = returnJsonStringOrNull(event.detail.uiSchema)
            this.flamingoCalibration.flamingoForm[index].formData = returnJsonStringOrNull(event.detail.formData)
            this.calibrationDetail.flamingoForm = deepCopy(this.flamingoCalibration.flamingoForm)
        }

        const updateState = (index: number, event: any) => {
            const isFlamingoComponentsLoading = this.flamingoFormsLoadingState.findIndex(loading => loading === 'loading') > -1
            this.flamingoFormsLoadingState[index] = event.detail
            this.flamingoIsLoading.next(isFlamingoComponentsLoading)

            if (event.detail === 'error') {
                this.toastrService.error(
                    `It looks like a network error or this custom form has been deleted!`,
                    `Error loading custom form (${index})`,
                    mrmaAlertConfigs.Validation.configuration)
            }
        }

        const updateVersion = (index: number, event: any) => {
            if (this.noUpdateRequired || !this.flamingoCalibration.flamingoForm[index] || this.inReportPage) {
                return
            }

            if (this.flamingoForms[index]?.formId === event?.detail?.id &&
                this.flamingoForms[index]?.versionNumber &&
                this.flamingoForms[index].versionNumber !== event.detail.version
            ) {
                this.flamingoVersionChangedDetected = true
            }

            this.updateFlamingoFormObj(this.flamingoForms[index], event)
            this.updateFlamingoFormObj(this.flamingoCalibration.flamingoForm[index], event, true)
            this.calibrationDetail.flamingoForm = this.flamingoCalibration.flamingoForm
        }

        flamingoComponent.forEach((el: any, i) => {
            this.addSubscriptions([
                fromEvent(el.nativeElement, 'formChange').pipe(
                    debounceTime(500)
                ).subscribe(event => updateForm(i, event)),

                fromEvent(el.nativeElement, 'stateChange')
                    .subscribe(event => updateState(i, event)),

                fromEvent(el.nativeElement, 'formRetrieved')
                    .subscribe(event => updateVersion(i, event))
            ])
        })
    }

    public initFlamingoData(): void {
        const initFlamingFormsSubscription = this.observableForCalibrationAndEquipmentObject()
            .subscribe(([flamingoForms, calibrationDetail, , _]) => {

                this.flamingoVersionChangedDetected = false
                this.noUpdateRequired = calibrationDetail?.calibrationStatus?.id === CalibrationStatusEnum.Completed
                if (!this.templateChanged(calibrationDetail)) {
                    this.flamingoCalibration = deepCopy(calibrationDetail)
                }

                // To reset flamingoForm when changing equipment in report page
                if (this.inReportPage) {
                    this.flamingoForms = []
                }

                const prepareToUseSnapshot = this.flamingoForms.length === 0 && this.flamingoCalibration.flamingoForm.length > 0
                if (prepareToUseSnapshot) {
                    for (const ff of this.flamingoCalibration.flamingoForm) {
                        this.flamingoForms.push(
                            this.createFlamingoFormObj(ff.formId, ff.versionNumber, ff.formSchema, ff.uiSchema, ff.formData)
                        )
                    }
                }

                const calibrationNotStarted = this.flamingoCalibration.calibrationStatus.id === CalibrationStatusEnum.NotStarted
                const prepareToUseApiData = this.flamingoForms.length === 0 && calibrationNotStarted
                if (prepareToUseApiData) {
                    for (const ff of flamingoForms) {
                        const onlyIdForm = this.createFlamingoFormObj(ff.formId)
                        this.flamingoForms.push(onlyIdForm)
                        if (this.flamingoCalibration.flamingoForm.length === 0) {
                            this.flamingoCalibration.flamingoForm.push(deepCopy(onlyIdForm))
                        }
                    }
                }

                if (this.formsNotMatch(flamingoForms, calibrationDetail.flamingoForm)) {
                    this.flamingoFormIdsRemoved = []
                    for (const ff of this.flamingoForms) {
                        const formIdNotFound = !flamingoForms.some(f => f.formId === ff.formId)
                        if (formIdNotFound) {
                            this.flamingoFormIdsRemoved.push(ff)
                        }
                    }

                    for (const ff of flamingoForms) {
                        this.flamingoFormIdsAdded = []
                        const formIdNotFound = !this.flamingoForms.some(f => f.formId === ff.formId)
                        if (formIdNotFound) {
                            this.flamingoFormIdsAdded.push({ formId: ff.formId })
                        }
                    }

                    if (!this.noUpdateRequired) {
                        this.handleFlamingoFormsMismatch()
                    }
                }
            })

        const detectVersionChangedSubscription = this.flamingoIsLoading.subscribe(_isLoading => {
            if (this.flamingoVersionChangedDetected) {
                setTimeout(() => {
                    const notification: Modal = deepCopy(modalContent.flamingoNotificationModal)
                    notification.body = '<p>It looks like the admin has updated this form to a newer version</p>'
                    this.store.dispatch(new ShowModalAction(notification))
                })
            }
        })

        this.flamingoSubscriptions.push(initFlamingFormsSubscription)
        this.flamingoSubscriptions.push(detectVersionChangedSubscription)
    }

    public handleReferenceFlamingoFormOnSaveCompleteReopen(calibration: CalibrationDetails): void {
        this.calibrationDetail = calibration
        if (this.templateChanged(calibration)) {
            this.calibrationDetail.flamingoForm = deepCopy(this.flamingoCalibration.flamingoForm)
        }
    }

    public destroy(): void {
        this.unlistenToFlamingoEvent()
        this.unsubscribeToFlamingoObservable()
    }

    private formsNotMatch(flamingoForms: FlamingoForm[], formSnapshot: FlamingoFormSnapshot[]): boolean {
        if (this.noUpdateRequired) {
            return false
        }

        if (flamingoForms.length !== formSnapshot.length) {
            return true
        }

        for (const form of flamingoForms) {
            const formIdNotMatched = !formSnapshot.some(f => f.formId === form.formId)
            if (formIdNotMatched) {
                return true
            }
        }

        return false
    }


    private templateChanged(calibration: CalibrationDetails): boolean {
        return calibration.calibrationStatus.id === CalibrationStatusEnum.NotStarted &&
            (
                this.flamingoCalibration?.calibrationStatus.id === CalibrationStatusEnum.Draft ||
                this.flamingoCalibration?.calibrationStatus.id === CalibrationStatusEnum.NotStarted
            )
    }

    private observableForCalibrationAndEquipmentObject(): Observable<[FlamingoForm[], CalibrationDetails, boolean, boolean]> {
        return combineLatest([
            this.store.select(equipmentFlamingoFormsMapping),
            this.store.select(calibrationDetails),
            this.store.select(isEquipmentFlamingoFormLoading),
            this.store.select(isLoading)
        ]).pipe(
            filter(([_, detail, isCalibrationLoading, isFlamingFormLoading]) =>
                !isCalibrationLoading &&
                !isFlamingFormLoading &&
                !isNotAValue(detail))
        )
    }

    @AccessibleByPolicy(AppPolicyType.TechnicianAccess)
    private handleFlamingoFormsMismatch(): void {
        if (this.inReportPage) {
            return
        }

        const formAdded = this.flamingoFormIdsAdded.length > 0 && this.flamingoFormIdsRemoved.length === 0
        const formRemoved =
            (this.flamingoFormIdsRemoved.length > 0 && this.flamingoFormIdsAdded.length === 0) ||
            (this.flamingoFormIdsRemoved.length > 0 && this.flamingoFormIdsAdded.length > 0 && !this.formIdsNotMatch())
        const formReplaced = this.flamingoFormIdsAdded.length > 0 && this.flamingoFormIdsRemoved.length > 0 && this.formIdsNotMatch()
        const formHasChanged = formAdded || formRemoved || formReplaced
        const notification: Modal = deepCopy(modalContent.flamingoNotificationModal)

        if (formAdded) {
            notification.body = '<p>It looks like the admin has added a custom form to this maintenance record.</p>'
            notification.confirmCallback = this.addForm.bind(this)
        }

        if (formRemoved) {
            notification.body = '<p>It looks like the admin has removed a custom form from this maintenance record.</p>'
            notification.confirmCallback = this.removeForm.bind(this)
        }

        if (formReplaced) {
            notification.body =
                '<p>It looks like the admin has replaced previously saved custom form with a new one for this maintenance record.</p>'
            notification.confirmCallback = this.replaceForm.bind(this)
        }

        if (formHasChanged) {
            this.store.dispatch(new ShowModalAction(notification))
        }
    }

    private formIdsNotMatch(): boolean {
        for (const form of this.flamingoFormIdsAdded) {
            const formIdNotMatched = !this.flamingoFormIdsRemoved.some(f => f.formId === form.formId)
            if (formIdNotMatched) {
                return true
            }
        }
        return false
    }

    private createFlamingoFormObj(
        formId: string,
        versionNumber: number = null,
        formSchema: string = null,
        uiSchema: string = null,
        formData: string = null
    ): FlamingoFormSnapshot {
        const flamingoForm = {
            formId,
            versionNumber,
            formSchema: formSchema && JSON.parse(formSchema),
            uiSchema: uiSchema && JSON.parse(uiSchema),
            formData: formData && JSON.parse(formData)
        }

        return flamingoForm
    }

    private updateFlamingoFormObj(toUpdate: FlamingoFormSnapshot, ref: any, stringify = false): void {
        toUpdate.versionNumber = ref.detail.version
        toUpdate.title = ref.detail.title

        if (stringify) {
            toUpdate.formSchema = JSON.stringify(ref.detail.schema)
            toUpdate.uiSchema = JSON.stringify(ref.detail.uiSchema)
        } else {
            toUpdate.formSchema = ref.detail.schema
            toUpdate.uiSchema = ref.detail.uiSchema
        }
    }

    private addForm(): void {
        this.unlistenToFlamingoEvent()
        this.flamingoForms.push(...this.flamingoFormIdsAdded)
        this.flamingoCalibration.flamingoForm = deepCopy(this.flamingoForms)
        this.calibrationDetail.flamingoForm = deepCopy(this.flamingoForms)
        setTimeout(() => this.watchFlamingoEvent(this.flamingoComponents))
    }

    private removeForm(): void {
        this.unlistenToFlamingoEvent()
        this.flamingoForms = this.flamingoFormIdsRemoved.filter(ff => !this.flamingoFormIdsRemoved.some(fr => fr.formId === ff.formId))
        this.flamingoCalibration.flamingoForm = deepCopy(this.flamingoForms)
        this.calibrationDetail.flamingoForm = deepCopy(this.flamingoForms)
        setTimeout(() => this.watchFlamingoEvent(this.flamingoComponents))
    }

    private replaceForm(): void {
        this.unlistenToFlamingoEvent()
        this.flamingoForms = this.flamingoFormIdsRemoved.filter(ff => !this.flamingoFormIdsRemoved.some(fr => fr.formId === ff.formId))
        this.flamingoForms.push(...this.flamingoFormIdsAdded)
        this.flamingoCalibration.flamingoForm = deepCopy(this.flamingoForms)
        this.calibrationDetail.flamingoForm = deepCopy(this.flamingoForms)
        setTimeout(() => this.watchFlamingoEvent(this.flamingoComponents))
    }

    private unlistenToFlamingoEvent(): void {
        this.flamingoFormListeners = []
        this.flamingoStateListeners = []
        this.flamingoAPIPayloadListeners = []

        for (let i = 0; i < this.flamingoFormListeners.length; i++) {
            this.flamingoFormListeners[i]()
            this.flamingoStateListeners[i]()
        }

        for (const listener of this.flamingoAPIPayloadListeners) {
            listener()
        }
    }

    private unsubscribeToFlamingoObservable(): void {
        for (const subscription of this.flamingoSubscriptions) {
            subscription.unsubscribe()
        }
    }
}
