import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'

// Don't provide this in root. Instead, provide it in whichever component is going to use the paged list.
@Injectable()
export class APIPagedListService {

    public refreshList$ = new Subject<{ resetPosition: boolean }>()
    public isRequestInProgress$ = new BehaviorSubject<boolean>(false)

    public refreshList(args: { resetPosition: boolean }): void {
        this.refreshList$.next(args)
    }
}
