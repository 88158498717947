<section>
    <h1 class="responsive-view">{{title}}</h1>

    <div class="report-filter-row">
        <label for="name">Name of Preset</label>
        <input id="name"
               type="text"
               data-cy="report-modal-name-textbox"
               [(ngModel)]="presetName"
               [maxlength]="45"
               (input)="onInput($event)"
               [ngClass]="{'error-class': hasErrorOnPresetName}">
        <div>
            <span *ngIf="hasErrorOnPresetName">{{reportFilterPresetNameErrorMessage}}</span>
        </div>
    </div>
    <div class="action-buttons">
        <p>PM Performed Date is not included</p>
        <button (click)="submit()"
                data-cy="preset-modal-confirm-button"
                class="em-c-btn em-c-btn--primary"
                [ngClass]="{'em-c-btn--disabled': disabledSaveBtn}"
                [disabled]="disabledSaveBtn">
            Save
        </button>
        <button (click)="close()"
                data-cy="preset-modal-cancel-button"
                class="em-c-btn">
            Cancel
        </button>
    </div>
</section>