import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { forkJoin, Observable, of } from 'rxjs'
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators'

import { EquipmentSortStackService } from '@app/modules/shared/services/equipment-sort-stack.service'
import { WorkOrderListItem } from '@app/modules/work-order-search/models/work-order-list-item.model'
import { BookmarkService } from '@app/modules/work-order-search/services/bookmark.service'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { InitAPICaching } from '../offline/offline.actions'
import {
    ActionType,
    AddToDoAction,
    AddToDoActionFailure,
    AddToDoActionSuccess,
    DeleteToDoAction,
    DeleteToDoActionFailure,
    DeleteToDoActionSuccess,
    DeleteToDosAction,
    GetToDoAction,
    GetToDoSuccessAction
} from './to-do.actions'
import { AppState } from '@app/store/app.store'

@Injectable()
export class ToDoEffects {

    
    public getMyToDos = createEffect(() => this.actions$.pipe(
        ofType(ActionType.GetToDo),
        exhaustMap((action: GetToDoAction) => {
            return this.responseHandlerService.query<WorkOrderListItem[]>(() => this.bookmarkService.getMyToDos(), this.customStrategySection)
                .pipe(
                    switchMap(workOrderDetails => {
                        this.equipmentSortStackService.applyMainEquipmentToWorkOrders(workOrderDetails)
                        return [
                            new GetToDoSuccessAction(workOrderDetails),
                            new InitAPICaching(workOrderDetails)
                        ]
                    })
                )
        })
    ))

    
    public addToDo: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionType.AddToDo),
        switchMap((action: AddToDoAction) => {
            return this.responseHandlerService.query<boolean>(() => this.bookmarkService.addBookmark(action.payload.workOrderNumber))
                .pipe(
                    switchMap(addBookmarkSucceeded => {
                        if (addBookmarkSucceeded) {
                            return [new AddToDoActionSuccess(action.payload), new InitAPICaching([action.payload])]
                        }
                    }),
                    catchError(_ => of(new AddToDoActionFailure(action.payload)))
                )
        })
    ))

    
    public deleteToDo: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionType.DeleteToDo),
        switchMap((action: DeleteToDoAction) => {
            return this.responseHandlerService.query<boolean>(() => this.bookmarkService.deleteBookmark(action.payload.workOrderNumber))
                .pipe(
                    map(deletedBookmarkSucceeded => {
                        if (deletedBookmarkSucceeded) {
                            return new DeleteToDoActionSuccess(action.payload)
                        }
                    }),
                    catchError(_ => of(new DeleteToDoActionFailure(action.payload)))
                )
        })
    ))

    
    public deleteToDos: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionType.DeleteToDos),
        exhaustMap((action: DeleteToDosAction) => {
            return forkJoin(action.payload.map(wo =>
                this.responseHandlerService.query<boolean>(() => this.bookmarkService.deleteBookmark(wo.workOrderNumber))
                .pipe(
                    map(deletedBookmarkSucceeded => {
                        return { deletedBookmarkSucceeded, wo }
                    }),
                    catchError(_ => of({ deletedBookmarkSucceeded: false, wo }))
                )
            )).pipe(
                map((deletedResults: any[]) => {
                    deletedResults.forEach((result: { deletedBookmarkSucceeded: boolean, wo: WorkOrderListItem }) => {
                        if (result.deletedBookmarkSucceeded) {
                            this.store.dispatch(new DeleteToDoActionSuccess(result.wo))
                        } else {
                            this.store.dispatch(new DeleteToDoActionFailure(result.wo))
                        }
                    })
                    return { type: 'NO_ACTION'}
                }),
                catchError(() => of({ type: 'NO_ACTION' }))
            )
        })
    ))

    private customStrategySection: ResponseHandlingStrategy
    constructor(
        private actions$: Actions,
        private bookmarkService: BookmarkService,
        private responseHandlerService: ResponseHandlerService,
        private equipmentSortStackService: EquipmentSortStackService,
        private store: Store<AppState>,
        private toastr: ToastrService
    ) {
        this.customStrategySection = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastr)
            .responseStrategy
    }
}
