import { Component, EventEmitter, Output } from '@angular/core'
import { Store } from '@ngrx/store'

import { modalContent } from '@app/modules/modal-container/constants/modal-message-content.constant'
import { Modal } from '@app/modules/modal-container/models/modal.model'
import { AppState } from '@app/store/app.store'
import { ShowModalAction } from '@app/store/modal/modal.actions'
import { deepCopy } from '@app/utils/app-utils.function'

@Component({
    selector: 'app-header-navigation',
    templateUrl: './header-navigation.component.html',
    styleUrls: ['./header-navigation.component.scss']
})
export class HeaderNavigationComponent {
    @Output() showMenu: EventEmitter<boolean> = new EventEmitter()
    constructor(
        private store: Store<AppState>
    ) { }

    public navigationBlock(): void {
        const modal: Modal = deepCopy(modalContent.notSupported)
        this.store.dispatch(new ShowModalAction(modal))
    }

    public closeMenu(): void {
        this.showMenu.next(false)
    }
}
