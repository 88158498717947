import { Component, Input, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { isEmpty, size } from 'lodash'

import { HyperlinkInfo, Procedure } from '@app/modules/calibration/models/hyperlink.model'
import { HyperlinkService } from '@app/modules/calibration/services/hyperlink.service'
import { hyperlinkListModalObject } from '@app/modules/shared/components/hyperlink-info/hyperlink-list-modal/hyperlink.modal-object'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { OfflineService } from '@app/services/offline.service'
import { AppState } from '@app/store/app.store'
import { GetHyperlinkInfoAction } from '@app/store/calibration/calibration.actions'
import { hyperlinkState } from '@app/store/calibration/calibration.selectors'
import { ShowModalAction } from '@app/store/modal/modal.actions'

@Component({
    selector: 'app-hyperlink-info',
    templateUrl: './hyperlink-info.component.html',
    styleUrls: ['./hyperlink-info.component.scss']
})
export class HyperlinkInfoComponent extends SafeUnsubscriberComponent implements OnInit {

    @Input() equipmentId: string

    public hyperlinkInfo: HyperlinkInfo
    public linkCount = 0
    public isLoading = true
    public hasError = false

    constructor(private store: Store<AppState>, private offlineService: OfflineService, private hyperlinkService: HyperlinkService) {
        super()
        this.onClickProcedure = this.onClickProcedure.bind(this)
    }

    public get isOnline(): boolean {
        return this.offlineService.isOnline
    }

    ngOnInit(): void {
        this.addSubscription(
            this.store.select(hyperlinkState).subscribe(state => {
                if (state.equipmentId === this.equipmentId) {
                    this.hyperlinkInfo = state.hyperlinkInfo
                    this.linkCount = size(state.hyperlinkInfo?.procedureList)
                    this.hasError = state.hasError
                    this.isLoading = false
                }
            })
        )
    }

    public onClickViewAll(): void {
        if (!this.isLoading && !this.hasError) {
            this.store.dispatch(new ShowModalAction({
                ...hyperlinkListModalObject,
                params: {
                    links: this.hyperlinkInfo?.procedureList,
                    onClickProcedure: this.onClickProcedure
                }
            }))
        }
    }

    public onClickProcedure(procedure: Procedure): void {
        this.hyperlinkService.showExternalLinkWarningModal(procedure.externalLink)
    }

    public getProcedureLinkName(procedure: Procedure): string {
        return isEmpty(procedure?.attachmentName) ? procedure?.attachmentDescription : procedure?.attachmentName
    }

    public reloadHyperlink(): void {
        this.isLoading = true
        this.hasError = false
        this.store.dispatch(new GetHyperlinkInfoAction(this.equipmentId))
    }
}
