<div class="em-c-table-object ">
    <ng-content select="header"></ng-content>
    <div class="em-c-table-object__body">
        <div class="em-c-table-object__body-inner">
            <table class="em-c-table ">
                <ng-content></ng-content>
                <tfoot class="em-c-table__footer">
                    <tr class="em-c-table__footer-row"></tr>
                </tfoot>
            </table>
        </div>
    </div>
    <div class="em-c-table-object__footer" *ngIf="querySummary">
        <div class="em-c-table-object__footer-actions">
            <div class="em-c-toolbar ">
                <div class="em-c-toolbar__item">
                    {{ querySummary.totalItemCount }} Results
                </div>
                <div *ngIf="perPageEnable" class="em-c-toolbar__item em-is-aligned-right">
                    <div
                        class="em-u-display-inline-block em-u-margin-right-half"
                    >
                        View:
                    </div>
                    <div *ngFor="let pageSize of paginationSize"
                        class="em-u-display-inline-block em-u-margin-right-half"
                    >
                        <div *ngIf="querySummary.itemPerPage !== pageSize">
                            <a
                                (click)="changePageSize(pageSize)"
                                class="em-u-font-style-regular em-u-clickable"
                                >{{pageSize}}</a
                            >
                        </div>
                        <div
                            *ngIf="querySummary.itemPerPage === pageSize"
                            class="em-u-font-style-semibold"
                        >
                            {{pageSize}}
                        </div>
                    </div>
                    <!-- TO DO: this block is to be removed when backend supports string pageSize -->
                    <div class="em-u-display-inline-block em-u-margin-right-half">
                        <div *ngIf="querySummary.itemPerPage !== 'all'">
                            <a
                                (click)="changePageSize('all')"
                                class="em-u-font-style-regular em-u-clickable"
                                >All</a
                            >
                        </div>
                        <div
                            *ngIf="querySummary.itemPerPage === 'all'"
                            class="em-u-font-style-semibold"
                        >
                            All
                        </div>
                    </div>
                    <!-- TO DO: this block is to be removed when backend supports string pageSize -->
                </div>
                <div class="em-c-toolbar__item" [ngClass]="{'em-is-aligned-right': !perPageEnable}">
                    <ol
                        class="em-c-pagination"
                        role="navigation"
                        aria-labelledby="pagination-label"
                    >
                        <li class="em-c-pagination__item em-u-clickable">
                            <a
                                class="em-c-pagination__link"
                                [ngClass]="{
                                    'em-is-disabled':
                                        querySummary.currentPage === 1 ||
                                        querySummary.totalItemCount === 0
                                }"
                                (click)="goToPage(querySummary.currentPage - 1)"
                            >
                                <app-unity-icon unitySvgName="icon-caret-left" size="small"></app-unity-icon>
                            </a>
                        </li>

                        <li
                            class="em-c-pagination__item"
                            [ngClass]="{
                                'em-u-clickable': page !== querySummary.currentPage
                            }"
                            *ngFor="let page of paginationService.getPaginationList(querySummary)"
                        >
                            <a
                                class="em-c-pagination__link"
                                [ngClass]="{
                                    'em-is-current':
                                        page === querySummary.currentPage
                                }"
                                (click)="goToPage(page)"
                                >{{ page }}</a
                            >
                        </li>

                        <li class="em-c-pagination__item em-u-clickable">
                            <a
                                class="em-c-pagination__link"
                                [ngClass]="{
                                    'em-is-disabled':
                                        querySummary.currentPage === paginationService.getTotalPage(querySummary)
                                        || querySummary.totalItemCount === 0
                                }"
                                (click)="goToPage(querySummary.currentPage + 1)"
                            >
                                <app-unity-icon unitySvgName="icon-caret-right" size="small"></app-unity-icon>
                            </a>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>
