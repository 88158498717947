import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { combineLatest, Observable } from 'rxjs'
import { filter } from 'rxjs/internal/operators/filter'

import { CacheStatusEnum } from '@app/models/offline-status.enum'
import { SlideUpOverlayType } from '@app/modules/shared/constants/slide-up-overlay.enum'
import { SelectionDropdownInfoOption } from '@app/modules/shared/models/dropdown-view-more-info.model'
import { PageName } from '@app/modules/shared/models/page-name.enum'
import { SelectionDrawerName, SelectionDrawerOption } from '@app/modules/shared/models/selection-drawer.model'
import { WorkOrderListItem } from '@app/modules/work-order-search/models/work-order-list-item.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { OfflineService } from '@app/services/offline.service'
import { RouterService } from '@app/services/router.service'
import { AppState } from '@app/store/app.store'
import { isOnline } from '@app/store/connection/connection.selectors'
import { selectWorkOrderCache } from '@app/store/offline/offline.selectors'
import { SlideOverlayAction } from '@app/store/overlay/overlay.actions'
import { ToggleSelectionDrawerAction } from '@app/store/selection-drawer/selection-drawer.actions'
import { AddToDoAction, DeleteToDoAction } from '@app/store/to-do/to-do.actions'
import { toDos } from '@app/store/to-do/to-do.selector'
import { SelectWorkOrderAction } from '@app/store/work-order-search/search.actions'
import { SearchType } from '@app/store/work-order-search/search.reducer'
import { LoadWorkOrderDetailsAction } from '@app/store/work-order/work-order.actions'
import { workOrderDetails } from '@app/store/work-order/work-order.selectors'
import { deepCopy } from '@app/utils/app-utils.function'
import { CalibrationSummary } from '../../models/calibration-summary.model'
import { EquipmentCalibration } from '../../models/equipment-calibration.model'
import { WorkOrderDetails } from '../../models/work-order-details.model'
import { MassUpdateService } from '../../services/mass-update.service'
import { WorkOrderService } from '../../services/work-order.service'

@Component({
    selector: 'app-work-order-overview',
    templateUrl: './work-order-overview.component.html',
    styleUrls: ['./work-order-overview.component.scss']
})
export class WorkOrderOverviewComponent extends SafeUnsubscriberComponent implements OnInit, OnDestroy {
    public isOnline$: Observable<boolean>
    public workOrderDetails: WorkOrderDetails
    public workOrderOverview: WorkOrderListItem
    public cacheStatusEnum = CacheStatusEnum
    public pageName = PageName

    public moreDrawerInfoOptions: SelectionDrawerOption[]
    public moreDropdownInfoOptions: SelectionDropdownInfoOption[]
    public selectedEquipmentCalibration: EquipmentCalibration[] = []
    public moreInfoName = SelectionDrawerName.CalibrationInfoOption

    private workOrderId: string
    private searchBy: SearchType
    private searchQuery: string

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private store: Store<AppState>,
        private routerService: RouterService,
        private workOrderService: WorkOrderService,
        private offlineService: OfflineService,
        private massUpdateService: MassUpdateService
    ) {
        super()
    }

    ngOnInit(): void {
        this.clearAllOverlayAndDrawer()
        this.isOnline$ = this.store.select(isOnline)

        this.addSubscription(this.route.params.subscribe(params => {
            this.workOrderId = params['id']
            if (this.workOrderId) {
                this.store.dispatch(LoadWorkOrderDetailsAction({ id: this.workOrderId, fetchNotification: false }))
            }
        }))

        this.addSubscription(combineLatest([
            this.store.select(workOrderDetails),
            this.store.select(selectWorkOrderCache),
            this.store.select(toDos)
        ]).pipe(
            filter(([details, _]) => details?.workOrderNumber === this.workOrderId)
        ).subscribe(([details, workOrders, toDoList]) => {
            if (!this.offlineService.isOnline) {
                this.workOrderDetails = this.workOrderService.handleWorkOrderDetailsWithCache(
                    deepCopy(details),
                    workOrders
                )
            } else {
                this.workOrderDetails = details
            }

            this.workOrderOverview = toDoList.find(wo => wo.workOrderNumber === this.workOrderId)

            // Use default data from workOrderDetails when WO is not bookmarked
            if (!this.workOrderOverview) {
                this.workOrderOverview = {
                    isBookmarked: false,
                    workOrderNumber: this.workOrderDetails.workOrderNumber,
                    lacd: this.workOrderDetails.lacd,
                    workOrderDescription: this.workOrderDetails.description,
                    readyForOffline: this.workOrderDetails.notification.isOpen ? true : false,
                    equipments: []
                }
            }

            this.loadInfoOptions()
            this.store.dispatch(new SelectWorkOrderAction(this.workOrderId))
        }))

        this.addSubscription(this.massUpdateService.selectedEquipmentCalibrations$
            .subscribe(selectedEquipmentCalibration => this.selectedEquipmentCalibration = selectedEquipmentCalibration))
    }

    ngOnDestroy(): void {
        this.clearAllOverlayAndDrawer()
    }

    public showInfoOptions(): void {
        this.store.dispatch(new ToggleSelectionDrawerAction({
            name: this.moreInfoName,
            visibility: true
        }))
    }

    public viewProcedure(procedureURL: string): void {
        if (!this.offlineService.isOnline) {
            return
        }

        const win = window.open(procedureURL)
        win.focus()
    }

    public isBackToDoList(): boolean {
        if (!this.offlineService.isOnline) {
            return true
        }

        return (this.routerService && this.routerService.getRootUrl() === '/to-dos')
    }

    public navigateBack(): void {
        if (!this.offlineService.isOnline) {
            this.router.navigateByUrl(`/to-dos`)
            return
        }

        if (this.routerService.getRootUrl() === '/to-dos') {
            this.router.navigateByUrl(`/to-dos`)
            return
        }

        if (this.routerService.getRootUrl().indexOf('/search') !== -1) {
            this.router.navigateByUrl(this.routerService.getRootUrl())
            return
        }

        if (!this.searchBy || !this.searchQuery) {
            this.router.navigateByUrl(`/search`)
            return
        }

        this.router.navigateByUrl(`/search/${this.searchBy}/${this.searchQuery}`)
    }

    public getCalibrationSummaryFormat(workOrder: WorkOrderDetails): CalibrationSummary[] {
        const calibrationSummary: Array<CalibrationSummary> = []

        for (const equipment of workOrder.equipmentCalibrations) {
            calibrationSummary.push({
                result: equipment.result,
                status: equipment.status
            })
        }

        return calibrationSummary
    }

    public toggleBookmark(event: Event, workOrder: WorkOrderListItem): void {
        if (workOrder.isBookmarked) {
            this.store.dispatch(new DeleteToDoAction(workOrder))
            this.offlineService.removeWorkOrderDetailsFromCache(workOrder.workOrderNumber)
        } else {
            this.store.dispatch(new AddToDoAction(workOrder))
        }
    }

    public checkWorkOrderItemCacheStatus(): CacheStatusEnum {
        if (this.workOrderId) {
            return this.offlineService.getWorkOrderCacheStatus(this.workOrderId)
        } else {
            return CacheStatusEnum.FAILED
        }
    }

    private loadInfoOptions(): void {
        this.moreDropdownInfoOptions = this.moreDrawerInfoOptions = [
            {
                optionName: 'Work Order Details',
                callback: () => this.store.dispatch(new SlideOverlayAction({
                    id: SlideUpOverlayType.WorkOrderDetails,
                    visibility: true
                })),
            },
            {
                optionName: 'Work Order Long Text',
                callback: () => this.store.dispatch(new SlideOverlayAction({
                    id: SlideUpOverlayType.WorkOrderLongText,
                    visibility: true
                })),
                disabled: !this.workOrderDetails?.longText,
            },
            {
                optionName: 'Procedure',
                callback: () => this.viewProcedure(this.workOrderDetails.procedureURL),
            }
        ]
    }

    private clearAllOverlayAndDrawer(): void {
        this.store.dispatch(new SlideOverlayAction({ visibility: false, id: SlideUpOverlayType.WorkOrderDetails }))
        this.store.dispatch(new SlideOverlayAction({ visibility: false, id: SlideUpOverlayType.WorkOrderLongText }))
        this.store.dispatch(new ToggleSelectionDrawerAction({ name: this.moreInfoName, visibility: false }))
    }
}
