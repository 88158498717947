import { Injectable } from '@angular/core'

import { APIPagedListUIState } from '../models/api-paged-list-ui-state.model'

@Injectable({
    providedIn: 'root'
})
export class APIPagedListUIStateService {

    private savedState = new Map<string, APIPagedListUIState>()

    public setState(key: string, state: APIPagedListUIState): void {
        this.savedState.set(key, state)
    }

    public getState(key: string): APIPagedListUIState | null | undefined {
        return this.savedState.get(key)
    }
}


