import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
    selector: 'app-icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {
    @Input() unitySvgName = 'thumbs-up'
    @Input() svgPath: string
    @Input() bold = true
    @Input() extraStyle

    @Input() isDisabled = false

    @Output() buttonClick = new EventEmitter()
}
