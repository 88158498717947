import {
    ApplyEquipmentListABCIndicatorFilterAction, ApplyEquipmentListCategoryFilterAction, ApplyEquipmentListClassificationFilterAction,
    ApplyEquipmentListLocationFilterAction, ApplyEquipmentListMaintenancePlantFilterAction, ApplyEquipmentListMainWorkCenterFilterAction,
    ApplyEquipmentListPlannerGroupFilterAction, ApplyEquipmentListPlantSectionFilterAction
} from '@app/store/equipment/actions/equipment-list.actions'
import { FilterFields } from '../models/equipment-filter-fields.model'

export const equipmentFilterLists = {
    criticality: {
        name: 'Criticality',
        value: [],
        filterAction: (payload?: FilterFields[]) => new ApplyEquipmentListABCIndicatorFilterAction(payload)
    },
    plannerGroup: {
        name: 'Planner Group',
        value: [],
        filterAction: (payload?: FilterFields[]) => new ApplyEquipmentListPlannerGroupFilterAction(payload)
    },
    mainWorkCenter: {
        name: 'Main Work Center',
        value: [],
        filterAction: (payload?: FilterFields[]) => new ApplyEquipmentListMainWorkCenterFilterAction(payload)
    },
    maintenancePlant: {
        name: 'Maintenance Plant',
        value: [],
        filterAction: (payload?: FilterFields[]) => new ApplyEquipmentListMaintenancePlantFilterAction(payload)
    },
    plantSection: {
        name: 'Plant Section',
        value: [],
        filterAction: (payload?: FilterFields[]) => new ApplyEquipmentListPlantSectionFilterAction(payload)
    },
    location: {
        name: 'Location',
        value: [],
        filterAction: (payload?: FilterFields[]) => new ApplyEquipmentListLocationFilterAction(payload)
    },
    category: {
        name: 'Category',
        value: [],
        filterAction: (payload?: FilterFields[]) => new ApplyEquipmentListCategoryFilterAction(payload)
    },
    classification: {
        name: 'Classification',
        value: [],
        filterAction: (payload?: FilterFields[]) => new ApplyEquipmentListClassificationFilterAction(payload)
    },
}
