<div class="wrapper"
     [ngClass]="{ disabled: isDisabled}">
    <label [for]="id"
           class="em-c-input-group em-c-checkbox--container">
        <input type="checkbox"
               class="em-c-input-group__control"
               [name]="name"
               [id]="id"
               [value]="value"
               [checked]="checked"
               [disabled]="isDisabled"
               (change)="checkboxChanged($event)">
        <span>
            <ng-content></ng-content>
        </span>
    </label>
</div>
