import { Injectable } from '@angular/core'
import { FormArray, FormControl, FormGroup } from '@angular/forms'

import { isNotAValue } from '@app/utils/app-utils.function'
import { CalibrationDetails } from '../models/calibration-details.model'
import { CalibrationResultStatusEnum } from '../models/calibration-result-status.enum'
import { CalibrationValidationStatus } from '../models/calibration-validation-status.enum'
import { RangeCalculationService } from './range-calculation.service'

@Injectable({
    providedIn: 'root'
})
export class CompareCalibrationService {
    constructor(private rangeCalculation: RangeCalculationService) { }

    public updateCalibrationResult(asFound: number, asLeft: number): CalibrationResultStatusEnum {
        if (asFound === CalibrationValidationStatus.Valid && asLeft === CalibrationValidationStatus.Valid) {
            return CalibrationResultStatusEnum.Passed
        }

        if (asLeft === CalibrationValidationStatus.Valid) {
            return CalibrationResultStatusEnum.FailedAdjustedPassed
        }

        return CalibrationResultStatusEnum.Failed
    }

    public isValid(formGroup: FormGroup, calibrationDetails: CalibrationDetails): { asFound: boolean, asLeft: boolean } {
        const calibrationResult = formGroup.get('results.calibrationResult.results') as FormArray
        const calibrationValue = calibrationResult.controls[0].get('resultSet') as FormArray
        const calibrationResultStatus = calibrationResult.controls[0].get('resultStatus') as FormControl
        const tolerance = calibrationDetails.calibrationTemplate.tolerance.value

        const asFoundArray = calibrationValue.controls.map(cur => Number(cur.get('asFound').value))
        const asLeftArray = calibrationValue.controls.map(cur => Number(cur.get('asLeft').value))
        const asFound = this.rangeCalculation.calculateRangeDifference(asFoundArray)
        const asLeft = this.rangeCalculation.calculateRangeDifference(asLeftArray)

        const numberOfPoint = calibrationDetails.calibrationTemplate.numberOfPoint
        let asFoundCompleted = 0
        let asLeftCompleted = 0
        let isAsFoundValid = false
        let isAsLeftValid = false

        // Validate tolorance in as found and set form error
        if (asFound > tolerance) {
            calibrationResultStatus.patchValue({ asFound: CalibrationValidationStatus.Invalid }, { emitEvent: false })
            isAsFoundValid = false
        } else {
            calibrationResultStatus.patchValue({ asFound: CalibrationValidationStatus.Valid }, { emitEvent: false })
            isAsFoundValid = true
        }

        // Validate tolorance in as left and set form error
        if (asLeft > tolerance) {
            calibrationResultStatus.patchValue({ asLeft: CalibrationValidationStatus.Invalid }, { emitEvent: false })
            isAsLeftValid = false
        } else {
            calibrationResultStatus.patchValue({ asLeft: CalibrationValidationStatus.Valid }, { emitEvent: false })
            isAsLeftValid = true
        }

        // Count if as found and as left the input contains value
        calibrationValue.controls.forEach(control =>
            asFoundCompleted += isNotAValue(control.get('asFound').value) ? 0 : 1
        )
        calibrationValue.controls.forEach(control =>
            asLeftCompleted += isNotAValue(control.get('asLeft').value) ? 0 : 1
        )

        // Revert error to initialize state if each section of as found / as left input are filled
        if (asFoundCompleted !== numberOfPoint) {
            calibrationResultStatus.patchValue({ asFound: CalibrationValidationStatus.Initialize }, { emitEvent: false })
            isAsFoundValid = false
        }
        if (asLeftCompleted !== numberOfPoint) {
            calibrationResultStatus.patchValue({ asLeft: CalibrationValidationStatus.Initialize }, { emitEvent: false })
            isAsLeftValid = false
        }

        return { asFound: isAsFoundValid, asLeft: isAsLeftValid }
    }

    public autoPopulate(formGroup: FormGroup, calibrationDetails: CalibrationDetails): void {
        const calibrationResult = formGroup.get('results.calibrationResult.results') as FormArray
        const calibrationValue = calibrationResult.controls[0].get('resultSet') as FormArray
        const calibrationResultStatus = calibrationResult.controls[0].get('resultStatus') as FormControl
        const numberOfPoint = calibrationDetails.calibrationTemplate.numberOfPoint

        let asFoundCompleted = 0
        calibrationValue.controls.forEach(control =>
            asFoundCompleted += isNotAValue(control.get('asFound').value) ? 0 : 1
        )

        let asLeftCompleted = 0
        calibrationValue.controls.forEach(control =>
            asLeftCompleted += isNotAValue(control.get('asLeft').value) ? 0 : 1
        )

        if (asFoundCompleted === numberOfPoint &&
            asLeftCompleted === 0 &&
            calibrationResultStatus.get('asFound').value === CalibrationValidationStatus.Valid
        ) {
            calibrationValue.controls.forEach(control =>
                control.patchValue(
                    { asLeft: control.get('asFound').value },
                    { emitEvent: false }
                )
            )
        }
    }
}
