import { Component, ContentChild, TemplateRef } from '@angular/core'

@Component({
    selector: 'app-sticky-bottom-bar',
    templateUrl: './sticky-bottom-bar.component.html',
    styleUrls: ['./sticky-bottom-bar.component.scss']
})
export class StickyBottomBarComponent {

    @ContentChild('leftToolbar') leftToolbar: TemplateRef<any>
    @ContentChild('rightToolbar') rightToolbar: TemplateRef<any>
}
