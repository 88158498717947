import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
    selector: 'app-date-picker-form',
    templateUrl: './date-picker-form.component.html',
    styleUrls: ['./date-picker-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatePickerFormComponent),
            multi: true
        }
    ]
})
export class DatePickerFormComponent implements ControlValueAccessor {

    @Input() placeHolder: string
    @Input() isClearable = false
    @Input() maxDate: Date = null
    @Output() valueChange = new EventEmitter<Date>()

    public disabled = false
    private _value: Date

    public get value(): Date {
        return this._value
    }
    public set value(v: Date) {
        // This will prevent the value from being updated
        // if the control is disabled.
        // ** Will still allow programmatic change though **
        if (this.disabled) {
            return
        }
        this._value = v
        this.onChange(v)
        this.onTouched()
    }

    // This will be fire when the value has been change **programmatically**.
    // It should update the UI but NOT call onChange()
    writeValue(v): void {
        this._value = v
    }

    // This handle UI to model change, it should call OnChange to allow
    // the form to update its model.
    onDatePickerDateChanged(date: Date): void {
        this.value = date
        this.valueChange.emit()
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled
    }

    registerOnChange(fn): void {
        this.onChange = fn
    }

    registerOnTouched(fn): void {
        this.onTouched = fn
    }

    // These functions will be registered automatically by Angular
    private onChange: any = () => { }
    private onTouched: any = () => { }
}
