import { Injectable } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import { filter } from 'rxjs/operators'

@Injectable({
    providedIn: 'root'
})
export class RouterService {
    public rootUrl: string

    constructor(private router: Router) { }

    public getRootUrl(): string {
        return !this.rootUrl ? '' : this.rootUrl
    }

    public setRouting(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
                if (urlAfterRedirects.indexOf('/to-dos') !== -1 ||
                    urlAfterRedirects.indexOf('/search/') !== -1) {
                    this.rootUrl = urlAfterRedirects
                }
            })
    }
}
