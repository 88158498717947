import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { combineLatest } from 'rxjs'
import { filter } from 'rxjs/operators'

import { SpecialTemplateId } from '@app/constants/app-special.constants'
import { CalibrationDetails } from '@app/modules/calibration/models/calibration-details.model'
import { CalibrationInitializerService } from '@app/modules/calibration/services/calibration-initializer.service'
import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { TemplateType } from '@app/modules/equipment/models/template-type.model'
import { EquipmentTemplateUtilService } from '@app/modules/equipment/services/equipment-template-util.service'
import { TemplateTypeUtilService } from '@app/modules/equipment/services/template-type-util.service'
import { EquipmentCalibration } from '@app/modules/work-order/models/equipment-calibration.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import { LoadCalibrationSuccessAction } from '@app/store/calibration/calibration.actions'
import { calibrationDetails, selectedTemplateId } from '@app/store/calibration/calibration.selectors'
import { equipmentTemplateList } from '@app/store/equipment/selectors/equipment-template.selectors'
import * as ModalAction from '@app/store/modal/modal.actions'
import { workOrderDetails } from '@app/store/work-order/work-order.selectors'
import { sortArray } from '@app/utils/app-utils.function'

@Component({
    selector: 'app-calibration-template-selection',
    templateUrl: '../../../../equipment/components/modals/template-selection/template-selection.component.html',
    styleUrls: ['../../../../equipment/components/modals/template-selection/template-selection.component.scss']
})
export class CalibrationTemplateSelectionComponent extends SafeUnsubscriberComponent implements OnInit {
    public availableTemplates: TemplateType[] = []
    public selectedTemplateId: number
    public isCalibrationPage: boolean

    protected equipmentId: string
    protected actualTemplateDetails: EquipmentTemplateCoreDetails[] = []
    protected equipmentCalibration: EquipmentCalibration
    protected currentCalibration: CalibrationDetails

    constructor(
        protected store: Store<AppState>,
        protected route: Router,
        protected templateTypeUtilService: TemplateTypeUtilService,
        protected equipmentTemplateUtilService: EquipmentTemplateUtilService,
        protected calibrationInitializerService: CalibrationInitializerService,
    ) {
        super()
    }

    ngOnInit(): void {
        this.initCurrentCalibrationDetail()
        this.initEquipmentInfo()
        this.initData()
    }

    public selectTemplate(equipmentTemplate: TemplateType): void {
        const selectedTemplate = this.actualTemplateDetails.find(actualTemplate => actualTemplate.id === equipmentTemplate.id)
        const calibrationDetail = this.calibrationInitializerService.generateCalibrationObject(
            selectedTemplate, this.equipmentCalibration, this.currentCalibration, true
        )
        this.store.dispatch(new LoadCalibrationSuccessAction(calibrationDetail, true))
        this.store.dispatch(new ModalAction.HideModalAction())
    }

    protected initEquipmentInfo(): void {
        this.equipmentId = this.route.url.match(/[^\/]+$/g)[0]
    }

    protected initCurrentCalibrationDetail(): void {
        this.isCalibrationPage = this.route.url.includes('calibration')
        this.addSubscription(
            this.store.select(calibrationDetails).subscribe(calibration => {
                this.currentCalibration = calibration
            })
        )
    }

    private initData(): void {
        this.addSubscription(
            combineLatest(
                [this.store.select(equipmentTemplateList).pipe(filter(templates =>
                    this.equipmentTemplateUtilService.findEquipmentTemplateById(templates, this.equipmentId) ? true : false
                )),
                this.store.select(workOrderDetails),
                this.store.select(selectedTemplateId)]
            ).subscribe(([existingTemplates, workOrder, templateId]) => {
                if (workOrder) {
                    this.equipmentCalibration = workOrder.equipmentCalibrations.find(equipment =>
                        equipment.equipmentId === this.equipmentId
                    )
                }

                this.selectedTemplateId = templateId
                this.actualTemplateDetails = existingTemplates

                this.availableTemplates = existingTemplates
                    .map(template => {
                        const tempTemplateObject = {
                            id: template.templateTypeId,
                            name: template.templateTypeName,
                            numberOfPoint: [template.detail.numberOfPoint],
                            isActive: template.isActive,
                            processName: template.processName,
                            alias: template.detail.alias
                        } as TemplateType
                        tempTemplateObject.name = this.templateTypeUtilService.getTemplateTypeName(tempTemplateObject)
                        tempTemplateObject.id = template.id
                        return tempTemplateObject
                    })

                this.availableTemplates = sortArray(this.availableTemplates, 'name', false)
                this.availableTemplates.push({
                    id: SpecialTemplateId.NoTemplateTemplateId,
                    name: `Don't use a template`,
                    numberOfPoint: [0],
                    isActive: false,
                    processName: '',
                    alias: ''
                })
            })
        )
    }
}
