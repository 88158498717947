import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { combineLatest, fromEvent, Observable, of } from 'rxjs'
import { catchError, filter, take, timeout } from 'rxjs/operators'

import { mrmaAlertConfigs } from '@app/models/alert-configuration.model'
import { AdminOrSupportAccessPolicy, AdminPolicy, EngineerPolicy, TechnicianPolicy } from '@app/models/app-policy/all-policies'
import { ServiceWorkerStatusEnum } from '@app/models/service-worker-status.enum'
import { Modal } from '@app/modules/modal-container/models/modal.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppPolicy } from '@app/services/app-policies.service'
import { IdentityService } from '@app/services/identity.service'
import { OfflineService } from '@app/services/offline.service'
import { RouterService } from '@app/services/router.service'
import { ServiceWorkerService } from '@app/services/service-worker.service'
import { AppState } from '@app/store/app.store'
import { ConnectedAction, DisconnectedAction } from '@app/store/connection/connection.actions'
import { GetCustomFormTemplatesAction } from '@app/store/custom-form/custom-form.actions'
import {
    GetCategoryListAction,
    GetClassificationListAction,
    GetCriticalityListAction,
    GetServiceFluidListAction
} from '@app/store/equipment/actions/equipment-list.actions'
import { GetProcessConfigurationAction } from '@app/store/equipment/actions/process.actions'
import { GetTemplateTypesAction } from '@app/store/equipment/actions/template-type.actions'
import { GetUOMListAction } from '@app/store/equipment/actions/uom.actions'
import { isAuthenticated } from '@app/store/identity/identity.selectors'
import { AppHasFinishedInitializingAction } from '@app/store/loader/loader.actions'
import { isLoading } from '@app/store/loader/loader.selectors'
import { modal } from '@app/store/modal/modal.selectors'
import { ClearOfflineCache, GetAutoDownloadEnabledAction } from '@app/store/offline/offline.actions'
import { GetTestEquipmentAction } from '@app/store/test-equipment/test-equipment.actions'
import { GetToDoAction } from '@app/store/to-do/to-do.actions'
import { GetAllUserAction } from '@app/store/user/user.actions'
import {
    GetWorkOrderLocationAction, GetWorkOrderMaintenancePlantAction, GetWorkOrderMainWorkCenterAction, GetWorkOrderPlannerGroupAction,
    GetWorkOrderPlantSectionAction
} from '@app/store/work-order/work-order.actions'
import { environment } from '@environments/environment'
import { EquipmentListService } from '../equipment/services/equipment-list.service'

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends SafeUnsubscriberComponent implements OnInit, AfterViewInit {
    public isLoading: boolean
    public modal$: Observable<Modal>

    constructor(
        private ref: ChangeDetectorRef,
        private store: Store<AppState>,
        private toastr: ToastrService,
        private routerService: RouterService,
        private identityService: IdentityService,
        private equipmentListService: EquipmentListService,
        private appPolicy: AppPolicy,
        public offlineService: OfflineService,
        public serviceWorkerService: ServiceWorkerService
    ) {
        super()
        this.configureAppPolicies()
    }

    ngAfterViewInit(): void {
        this.store.dispatch(new AppHasFinishedInitializingAction())
    }

    ngOnInit(): void {
        this.modal$ = this.store.select(modal)

        this.store.dispatch(new GetAutoDownloadEnabledAction())

        // To fix hanging observable "isLoading" when loading in report page stall
        // causing css "loading" to hang in mrma-container making report page unscrollable
        // Switching to subscribe() seem to be more stable then assigning it to observable$
        this.addSubscription(
            this.store.select(isLoading).subscribe(loading => {
                this.isLoading = loading
                this.ref.detectChanges()
            }),
        )

        this.handleInternetConnectionStatus()
        this.routerService.setRouting()
        this.appUpdateSynchronizationWatch()
        this.warmUpApp()
    }

    @HostListener('window:beforeunload', ['$event'])
    public unloadHandler(event: Event): void {
        if (navigator.onLine) {
            this.store.dispatch(new ClearOfflineCache())
        }
    }

    private handleInternetConnectionStatus(): void {
        // Listen to internet connectivity
        this.addSubscription(fromEvent(window, 'online')
            .subscribe(() => this.store.dispatch(new ConnectedAction())))
        this.addSubscription(fromEvent(window, 'offline')
            .subscribe(() => this.store.dispatch(new DisconnectedAction())))

        // To handle page reload when offline because event listener above doesn't take effect
        if (navigator.onLine) {
            this.store.dispatch(new ConnectedAction())
        } else {
            this.store.dispatch(new DisconnectedAction())
        }
    }

    private appUpdateSynchronizationWatch(): void {
        this.addSubscription(
            this.serviceWorkerService.serviceWorkerStatus$.subscribe(status => {
                const config = mrmaAlertConfigs.workflowInfo.configuration
                let message = 'MRMA is downloading the new updates…'
                if (status === ServiceWorkerStatusEnum.UPDATING) {
                    this.toastr.info(message, '', config)
                }

                message = 'You have the new update. <span class="click-tap"></span> HERE to get MRMA up to date.'
                if (status === ServiceWorkerStatusEnum.FIRST_INSTALL || status === ServiceWorkerStatusEnum.UPDATE_AVAILABLE) {
                    this.toastr.clear()
                    this.toastr.info(message, '', config)
                        .onHidden
                        .pipe(take(1))
                        .subscribe(() => this.serviceWorkerService.updateApplication())
                }
            })
        )
    }

    // Pre-fetch certain long loading API to speed up things
    private warmUpApp(): void {
        this.identityService.isCurrentUserAnAdmin$.pipe(
            filter(isAdmin => isAdmin),
            timeout(10000),
            catchError(e => of(false)),
            take(1)
        ).subscribe(isAdmin => {
            // If timeout occur, catchError will pass `false` forward
            if (isAdmin) {
                this.equipmentListService.pagedListDataSource(0, 40)
            }
        })

        this.addSubscription(combineLatest([
            this.store.select(isAuthenticated),
            this.serviceWorkerService.serviceWorkerStatus$
        ]).subscribe(([authenticated, serviceWorkerStatus]) => {
            const isReadyWithServiceWorker = authenticated && serviceWorkerStatus === ServiceWorkerStatusEnum.READY
            const isReadyWithoutServiceWorker = authenticated && !environment.serviceWorker
            if (isReadyWithServiceWorker || isReadyWithoutServiceWorker) {
                // App critical data
                this.store.dispatch(new GetToDoAction())
                this.store.dispatch(new GetAllUserAction())
                this.store.dispatch(new GetTestEquipmentAction())
                this.store.dispatch(new GetCustomFormTemplatesAction())
                this.store.dispatch(new GetProcessConfigurationAction())
                this.store.dispatch(new GetUOMListAction())

                // Report filter
                this.store.dispatch(GetWorkOrderMainWorkCenterAction())
                this.store.dispatch(GetWorkOrderPlannerGroupAction())
                this.store.dispatch(GetWorkOrderPlantSectionAction())
                this.store.dispatch(GetWorkOrderMaintenancePlantAction())
                this.store.dispatch(GetWorkOrderLocationAction())
                this.store.dispatch(new GetCriticalityListAction())
                this.store.dispatch(new GetCategoryListAction())
                this.store.dispatch(new GetClassificationListAction())
                this.store.dispatch(new GetTemplateTypesAction())
                this.store.dispatch(new GetServiceFluidListAction())
            }
        }))
    }

    private configureAppPolicies(): void {
        this.appPolicy.addPolicy(new EngineerPolicy())
        this.appPolicy.addPolicy(new AdminPolicy())
        this.appPolicy.addPolicy(new AdminOrSupportAccessPolicy())
        this.appPolicy.addPolicy(new TechnicianPolicy())
    }
}
