<div class="grid-container primary-equipment"
     *ngIf="isPrimaryEquipment">
    <span class="primary-pill label">Primary</span>
</div>
<div class="grid-container">
    <span>ID</span>
    <p [ngClass]="{'bold': sortBy === 'equipmentId'}">
        {{ equipment?.equipmentId }}
    </p>
</div>
<div class="grid-container">
    <span>Tag</span>
    <p [ngClass]="{'bold': sortBy === 'equipmentTag'}">
        {{ equipment?.equipmentTag }}
    </p>
</div>
<div class="grid-container">
    <span>Tech ID</span>
    <p [ngClass]="{'bold': sortBy === 'techIdentificationNumber'}">
        {{ equipment?.techIdentificationNumber }}
    </p>
</div>
<div class="grid-container">
    <span>FLOC</span>
    <p [ngClass]="{'bold': sortBy === 'functionalLocation'}">
        {{ equipment?.functionalLocation }}
    </p>
</div>