<div class="date-range-container">
    <button class="em-c-btn em-c-btn--is-dropdown em-js-dropdown-trigger"
            data-cy="date-range-picker"
            [ngClass]="{'toggled': showDateRange}"
            [ngStyle]="styleObj"
            (click)="toggleDropdown()">
        <div class="em-c-btn__inner">
            <div>
                <div class="em-c-btn__pm_text"
                  [ngClass]="{'gray-out-text': grayOutText}">{{ title }}</div>
                <div class="em-c-btn__pm_date"
                  [ngClass]="{'gray-out-text': grayOutText}">{{ dateRange.from | date: 'dd/MM/yyyy' }} - {{ dateRange.to | date: 'dd/MM/yyyy' }}</div>
            </div>
            <svg class="em-c-icon em-c-icon--small em-c-btn__icon em-c-btn__icon-only icon">
                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="./assets/unity-1.2.0/images/em-icons.svg#caret-down"></use>
            </svg>
        </div>
    </button>
    <div class="date-range"
         [hidden]="!showDateRange">
        <div class="em-c-field__body">
            <label class="em-c-field__label">From</label>
            <app-date-picker [inputValue]="dateRange.from"
                             [format]="dateFormat"
                             (inputValueChange)="onDateChange($event, true)"></app-date-picker>
        </div>
        <div class="em-c-field__body">
            <label class="em-c-field__label">To</label>
            <app-date-picker [inputValue]="dateRange.to"
                             [format]="dateFormat"
                             (inputValueChange)="onDateChange($event, false)"></app-date-picker>
        </div>
    </div>
</div>