import { Component, Input, TemplateRef } from '@angular/core'
import { Store } from '@ngrx/store'

import { AppState } from '@app/store/app.store'
import { AcknowledgeTipAction, SkipFeatureTipsAction } from '@app/store/tooltip/tooltip.actions'
import { FeatureTips } from '../../models/tooltip.model'

@Component({
    selector: 'app-tooltips',
    templateUrl: './tooltips.component.html',
    styleUrls: ['./tooltips.component.scss']
})
export class TooltipsComponent {
    @Input() featureTips: FeatureTips
    @Input() tipIndex: number

    public template: TemplateRef<any>

    constructor(private store: Store<AppState>) { }

    public get isLastTips(): boolean {
        return this.featureTips.tips.length - 1 === this.tipIndex
    }

    public acknowledgeTip(): void {
        this.store.dispatch(AcknowledgeTipAction({ featureName: this.featureTips.featureName, tipIndex: this.tipIndex }))
    }

    public skipFeatureTips(): void {
        this.store.dispatch(SkipFeatureTipsAction({ featureName: this.featureTips.featureName }))
    }

}
