<section class="cal-info">
    <div class="responsive-calibration-field-container first-container">
        <div class="web-view calibration-info">
            <label>Work Order</label>
            <p class="inline">{{workOrderDetails.workOrderNumber}}</p>
            <p class="label"
               [ngClass]="workOrderStatus === 'open' ? 'label-warning' : 'label-default'"
               *ngIf="!isReport">
                {{workOrderStatus}}
            </p>
        </div>
        <div class="web-view calibration-info">
            <app-notification-status [notificationNumber]="getEquipmentNotification(equipment.equipmentNotificationNumber)"
                                     [needTooltip]="needNotificationTooltip"
                                     notificationType="equipment"></app-notification-status>
        </div>
        <div class="web-view calibration-info">
            <label>PM Status</label>
            <p [class]="calibrationStatusDisplay?.classLabel">{{calibrationStatusDisplay?.name}}</p>
            <p *ngIf="calibration.dataSource === 'GRT'"
               class="label label-grt">{{calibration.dataSource}}</p>
        </div>
        <div class="web-view calibration-info">
            <label>PM Result</label>
            <p [ngClass]="{'result-status-text-error': calibrationFormService.isPMDeferredOrNotTested$ | async}">
                {{(calibrationFormService.resultsValue$ | async)?.finalPMResultStatus?.name || '-'}}
            </p>
        </div>
        <div class="web-view calibration-info">
            <label>Equipment ID</label>
            <p class="inline">{{ equipment?.equipmentId || '-' }}</p>
            <p class="primary-pill label"
               *ngIf="isPrimaryEquipment">Primary</p>
        </div>
        <div class="web-view calibration-info">
            <label>Equipment Tag</label>
            <p>{{ equipment?.equipmentTag || '-' }}</p>
        </div>
        <div class="web-view calibration-info">
            <label>Equipment Tech ID</label>
            <p>{{ equipment?.techIdentificationNumber || '-' }}</p>
        </div>
        <div class="web-view calibration-info">
            <label>Functional Location</label>
            <p>{{ equipment?.functionalLocation || '-' }}</p>
        </div>
    </div>
    <div class="responsive-calibration-field-container second-container">
        <div class="web-view calibration-info">
            <app-hyperlink-info [equipmentId]="equipment?.equipmentId"></app-hyperlink-info>
        </div>
        <div class="web-view calibration-info note-to-tech"
             *ngIf="equipment?.equipmentNote && showNoteToTechnician">
            <label>Note to Technician</label>
            <p>{{ equipment.equipmentNote }}</p>
        </div>
    </div>

    <div *ngIf="!(calibrationFormService.isPMDeferredOrNotTested$ | async) && appService.isSupervisoryReviewEnable(calibration?.processId)"
         class="responsive-calibration-field-container">
        <div class="calibration-info">
            <label>Review Status</label>
            <p [class]="reviewStatusDisplay(calibration?.reviewStatusId)?.classLabel">
                {{reviewStatusDisplay(calibration?.reviewStatusId)?.name}}
            </p>
        </div>
        <div *ngIf="reviewDate"
             class="calibration-info">
            <label>Reviewed Date</label>
            <p>{{reviewDate}}</p>
        </div>
        <div *ngIf="calibration?.reviewer"
             class="calibration-info">
            <label>Reviewed By</label>
            <p>{{calibration?.reviewer}}</p>
        </div>
    </div>
    <div class="responsive-view responsive-calibration-field-container">
        <div (click)="viewProcedure(workOrderDetails.procedureURL)"
             class="calibration-info">
            <label>Procedure Document</label>
            <p>View &gt;</p>
        </div>
        <div (click)="viewHistory()"
             class="calibration-info">
            <label>PM History</label>
            <p>View &gt;</p>
        </div>
    </div>
</section>