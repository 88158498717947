import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { isEqual } from 'lodash'
import { BehaviorSubject } from 'rxjs'
import { filter } from 'rxjs/operators'

import { NavAwayInterceptorGuardDataSource } from '@app/guards/nav-away-interceptor.guard'
import { CacheStatusEnum } from '@app/models/offline-status.enum'
import { CalibrationFlamingoService } from '@app/modules/calibration/services/calibration-flamingo.service'
import { CalibrationFormComponentService } from '@app/modules/calibration/services/calibration-form-component.service'
import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { Equipment } from '@app/modules/equipment/models/equipment.model'
import { TemplateTypeEnum } from '@app/modules/equipment/models/template-type.enum'
import { modalContent } from '@app/modules/modal-container/constants/modal-message-content.constant'
import { modalMessageBody } from '@app/modules/modal-container/constants/modal-message.constant'
import { Modal, ModalType } from '@app/modules/modal-container/models/modal.model'
import { ModalContainerService } from '@app/modules/modal-container/services/modal-container.service'
import { HyperlinkListModalComponent } from '@app/modules/shared/components/hyperlink-info/hyperlink-list-modal/hyperlink-list-modal.component'
import { SlideUpOverlayType } from '@app/modules/shared/constants/slide-up-overlay.enum'
import { SelectionDropdownInfoOption } from '@app/modules/shared/models/dropdown-view-more-info.model'
import { PageName } from '@app/modules/shared/models/page-name.enum'
import { SelectionDrawerName } from '@app/modules/shared/models/selection-drawer.model'
import { CalibrationFormService } from '@app/modules/shared/services/calibration-form.service'
import { EquipmentCalibration } from '@app/modules/work-order/models/equipment-calibration.model'
import { WorkOrderDetails } from '@app/modules/work-order/models/work-order-details.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { IdentityService } from '@app/services/identity.service'
import { OfflineService } from '@app/services/offline.service'
import { ScreenSizeService } from '@app/services/screen-size.service'
import { AppState } from '@app/store/app.store'
import { GetHyperlinkInfoAction, LoadCalibrationSuccessAction } from '@app/store/calibration/calibration.actions'
import { GetEquipmentFlamingoFormMappingAction } from '@app/store/equipment/actions/equipment-flamingo-form.actions'
import { equipmentDetail } from '@app/store/equipment/selectors/equipment.selectors'
import { DestroyModalAction, ShowModalAction } from '@app/store/modal/modal.actions'
import { SlideOverlayAction } from '@app/store/overlay/overlay.actions'
import { ToggleSelectionDrawerAction } from '@app/store/selection-drawer/selection-drawer.actions'
import { deepCopy } from '@app/utils/app-utils.function'
import { environment } from '@environments/environment'
import { CalibrationDetails } from '../../models/calibration-details.model'
import { CalibrationInterventionStep } from '../../models/calibration-intervertion-step.enum'
import { CalibrationResult } from '../../models/calibration-result.model'
import { CalibrationStatusEnum } from '../../models/calibration-status.enum'
import { CalibrationTemplate } from '../../models/calibration-template.model'
import { EmploymentType } from '../../models/employment-type.enum'
import { CalibrationInitializerService } from '../../services/calibration-initializer.service'
import { ReopenCalibrationService } from '../../services/reopen-calibration.service'
import { CalibrationFormComponent } from '../calibration-form/calibration-form.component'
import {
    CalibrationTemplateSelectionComponent
} from '../modals/calibration-template-selection/calibration-template-selection.component'
import {
    calibrationTemplateSelectionModalObject
} from '../modals/calibration-template-selection/calibration-template-selection.modal-object'

@Component({
    selector: 'app-calibration',
    templateUrl: './calibration.component.html',
    styleUrls: ['./calibration.component.scss'],
    providers: [
        CalibrationFormComponentService,
        CalibrationFormService,
        CalibrationFlamingoService
    ]
})
export class CalibrationComponent extends SafeUnsubscriberComponent
    implements OnInit, OnDestroy, AfterViewChecked, NavAwayInterceptorGuardDataSource {
    @ViewChild(CalibrationFormComponent) calibrationSection: CalibrationFormComponent

    public equipmentCalibration: EquipmentCalibration
    public calibration: CalibrationDetails
    public dataLoaded: boolean
    public workOrderDetails: WorkOrderDetails
    public equipmentDetails: Equipment
    public equipmentId: string
    public cacheStatusEnum = CacheStatusEnum
    public pageName = PageName
    public calibrationInterventionStep = CalibrationInterventionStep
    public isATechnician$: BehaviorSubject<boolean>
    public moreDropdownInfoOptions: SelectionDropdownInfoOption[]
    public calibrationIntervention$: BehaviorSubject<CalibrationInterventionStep>
    public isCalibrationDataReady$: BehaviorSubject<{
        calibrationReady: boolean,
        workOrderReady: boolean,
        equipmentTemplateReady: boolean
    }>

    private equipmentTemplates: EquipmentTemplateCoreDetails[]
    private workOrderId: string
    private isInitDataValidated = false
    private calibrationProcessId: number
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private store: Store<AppState>,
        private offlineService: OfflineService,
        private identityService: IdentityService,
        private modalContainerService: ModalContainerService,
        private reopenCalibrationService: ReopenCalibrationService,
        private calibrationInitializerService: CalibrationInitializerService,
        public calibrationFormService: CalibrationFormService,
        public calibrationFlamingoService: CalibrationFlamingoService,
        private screenSizeService: ScreenSizeService,
        public cdRef: ChangeDetectorRef
    ) {
        super()
        this.modalContainerService.registerComponent(CalibrationTemplateSelectionComponent, ModalType.CalibrationTemplateSelection)
        this.modalContainerService.registerComponent(HyperlinkListModalComponent, ModalType.CalibrationHyperLinkList)
        this.isATechnician$ = identityService.isCurrentUserATechnician$
        this.isCalibrationDataReady$ = new BehaviorSubject({ calibrationReady: false, workOrderReady: false, equipmentTemplateReady: false })
        this.calibrationIntervention$ = new BehaviorSubject(null)
    }

    public navigateBack(): void {
        this.router.navigateByUrl(`/work-order/${this.workOrderId}/overview`)
    }

    canDeactivate = () => {
        if (this.calibrationFormService.isCalibrationDataChanged.value || this.calibrationFlamingoService.isFlamingoDataChanged.value) {
            return confirm('Changes are not saved. Do you want to navigate away?')
        }
        return true
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeBrowserUnload(): boolean {
        return this.canDeactivate()
    }

    public showTemplateSelector(): void {
        if (environment.features['flamingo-viewer']) {
            this.store.dispatch(GetEquipmentFlamingoFormMappingAction({ equipmentId: this.equipmentId }))
        }

        if (this.screenSizeService.isOnMobileOrTabletDevice()) {
            this.store.dispatch(new ToggleSelectionDrawerAction({
                name: SelectionDrawerName.CalibrationChangeTemplate,
                visibility: true
            }))
        } else {
            this.store.dispatch(new ShowModalAction(calibrationTemplateSelectionModalObject))
        }
    }

    public checkWorkOrderItemCacheStatus(workOrderNumber: string): CacheStatusEnum {
        if (workOrderNumber) {
            return this.offlineService.getWorkOrderCacheStatus(workOrderNumber)
        } else {
            return CacheStatusEnum.FAILED
        }
    }

    ngOnInit(): void {
        this.setupSubscription()
    }

    ngOnDestroy(): void {
        super.ngOnDestroy()
        this.store.dispatch(new DestroyModalAction())
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges()
    }

    public viewProcedure(procedureURL: string): void {
        if (!this.offlineService.isOnline) {
            return
        }

        const win = window.open(procedureURL)
        win.focus()
    }

    public reopenCalibrationEnabled(): boolean {
        return this.isATechnician$.value && this.offlineService.isOnline
    }

    public resumeCalibrationEnabled(): boolean {
        return this.reopenCalibrationEnabled()
    }

    private isTemplateModified(): boolean {

        const hasCalibrationTemplateData = this.calibration?.templateId
        const isCalibrationCompleted = this.calibration?.calibrationStatus.id === Number(CalibrationStatusEnum.Completed)
        const originalTemplateProcess = deepCopy(this.equipmentTemplates?.find(template => template.id === this.calibration?.templateId)?.detail)
        const snapshotProcess = deepCopy(this.calibration?.calibrationTemplate)

        delete originalTemplateProcess?.procedure
        delete snapshotProcess?.procedure

        const originalProcessId = deepCopy(this.equipmentTemplates?.find(template => template.id === this.calibration?.templateId)?.processId)
        const snapshotProcessId = this.calibrationProcessId

        return hasCalibrationTemplateData &&
            !isCalibrationCompleted &&
            originalProcessId &&
            (
                !isEqual(originalProcessId, snapshotProcessId) ||
                !isEqual(originalTemplateProcess, snapshotProcess)
            )
    }

    private setupSubscription(): void {
        this.addSubscriptions([
            this.isCalibrationDataReady$.subscribe(params => {
                const isDataReady = [
                    params.calibrationReady,
                    params.equipmentTemplateReady,
                    params.workOrderReady].every((isReady) => isReady)

                if (isDataReady) {
                    this.validateInitCalibrationData()
                }
            }),
            this.route.params.subscribe(params => {
                this.equipmentId = params['equipmentId']
                this.workOrderId = params['id']
                this.calibrationInitializerService.loadData(this.workOrderId, this.equipmentId)
                this.store.dispatch(new GetHyperlinkInfoAction(this.equipmentId))
            }),

            this.calibrationInitializerService.getDataObservable(this.workOrderId, this.equipmentId)
                .pipe(
                    filter(([, , , isLoading]) => !isLoading)
                )
                .subscribe(([workOrder, equipmentTemplates, calibration, isLoading]) => {
                    this.workOrderDetails = workOrder
                    this.equipmentTemplates = equipmentTemplates
                    this.equipmentCalibration = workOrder.equipmentCalibrations.find(eq => eq.equipmentId === this.equipmentId)
                    this.loadCalibration(calibration)
                    this.loadInfoOptions()

                    // To correctly load online and offline calibration form
                    // WARNING!!! Logic below has multiple dependency be careful when refactoring
                    if (this.canStartNewCalibration()) {
                        if (this.hasMultipleTemplates()) {
                            this.showTemplateSelector()
                        } else {
                            this.createNewCalibration()
                        }
                    }

                    if (!this.calibration) {
                        this.calibration = null
                    }

                    this.updateInitDataStatus()
                    this.dataLoaded = true
                }),

            this.store.select(equipmentDetail).subscribe(_equipmentDetail => {
                this.equipmentDetails = _equipmentDetail
            })
        ])
    }

    private canStartNewCalibration(): boolean {
        return !this.calibration
            && this.isATechnician$.value
            && this.technicianNotExpired()
    }

    private createNewCalibration(): void {
        const calibrationDetail = this.calibrationInitializerService.generateCalibrationObject(
            this.equipmentTemplates[0],
            this.equipmentCalibration,
            this.calibration
        )

        if (environment.features['flamingo-viewer']) {
            this.store.dispatch(GetEquipmentFlamingoFormMappingAction({ equipmentId: this.equipmentId }))
        }
        this.store.dispatch(new LoadCalibrationSuccessAction(calibrationDetail))
    }

    private loadCalibration(calibration: CalibrationDetails): void {
        if (!this.calibration && Object.keys(calibration).length === 0) {
            return
        }

        this.calibration = deepCopy(calibration)
        const resultSet = calibration?.calibrationResult?.results
        const eqTemplateIndex = this.equipmentTemplates.findIndex(eq => eq.id === calibration?.templateId)
        if (this.calibration.calibrationStatus?.name.toLowerCase() === 'draft' && eqTemplateIndex >= 0) {
            this.calibrationProcessId = this.calibration.processId
            this.calibration.processName = this.equipmentTemplates[eqTemplateIndex].processName
            this.calibration.processId = this.equipmentTemplates[eqTemplateIndex].processId
        }
        if (!this.isCheckListTypeTemplate(calibration) && !resultSet && calibration?.templateId) {

            this.calibration = this.calibrationInitializerService.generateCalibrationObject(
                this.equipmentTemplates[eqTemplateIndex],
                this.equipmentCalibration,
                this.calibration
            )
        }

        if (environment.features['flamingo-viewer']) {
            this.store.dispatch(GetEquipmentFlamingoFormMappingAction({ equipmentId: this.equipmentId }))
        }

        this.refreshTemplateDetail()
    }

    private loadInfoOptions(): void {

        const equipmentDetailOption = {
            optionName: 'Equipment Details',
            callback: () => this.store.dispatch(new SlideOverlayAction({
                id: SlideUpOverlayType.EquipmentDetails,
                visibility: true
            })),
            disabled: false
        }

        const equipmentLongTextOption = {
            optionName: 'Equipment Long Text',
            callback: () => this.store.dispatch(new SlideOverlayAction({
                id: SlideUpOverlayType.EquipmentLongText,
                visibility: true
            })),
            disabled: !this.equipmentDetails?.longText,
        }

        // This subscription rely on the above options being initialized, so it has to be here.
        this.addSubscription(this.offlineService.isOnline$.subscribe(isOnline => {
            equipmentDetailOption.optionName = 'Equipment Details' + (isOnline ? '' : ' (Offline)')
            equipmentDetailOption.disabled = !isOnline

            equipmentLongTextOption.optionName = 'Equipment Long Text' + (isOnline ? '' : ' (Offline)')
            equipmentLongTextOption.disabled = !this.equipmentDetails?.longText || !isOnline
        }))

        this.moreDropdownInfoOptions = [
            equipmentDetailOption,
            equipmentLongTextOption,
            {
                optionName: 'Work Order Details',
                callback: () => this.store.dispatch(new SlideOverlayAction({
                    id: SlideUpOverlayType.WorkOrderDetails,
                    visibility: true
                })),
            },
            {
                optionName: 'Work Order Long Text',
                callback: () => this.store.dispatch(new SlideOverlayAction({
                    id: SlideUpOverlayType.WorkOrderLongText,
                    visibility: true
                })),
                disabled: !this.workOrderDetails?.longText,
            },
            {
                optionName: 'Maintenance History',
                callback: () => this.store.dispatch(new SlideOverlayAction({
                    id: SlideUpOverlayType.MaintenanceHistory,
                    visibility: true
                })),
            },
            {
                optionName: 'Procedure',
                callback: () => this.calibrationSection.isProcedureLink()
                    ? this.viewProcedure(this.calibrationSection.calibrationForm.get('overview.procedureNumber').value)
                    : this.viewProcedure(this.workOrderDetails.procedureURL),
            }
        ]
    }

    private isCheckListTypeTemplate(calibration: CalibrationDetails): boolean {
        return (
            calibration?.templateTypeId === TemplateTypeEnum[TemplateTypeEnum.passFailCheck] ||
            calibration?.templateTypeId === TemplateTypeEnum[TemplateTypeEnum.thirdParty] ||
            calibration?.templateTypeId === TemplateTypeEnum[TemplateTypeEnum.leakCheck]
        )
    }

    private refreshTemplateDetail(): void {
        if (this.calibration && this.isCurrentTemplateAvailable()) {
            const tempProcedureNumber = this.calibration.calibrationTemplate?.procedure ?? ''

            if (this.isInitDataValidated && this.calibration.calibrationStatus.id !== Number(CalibrationStatusEnum.Completed)) {
                this.calibration.calibrationTemplate = deepCopy(this.getCurrentTemplate())
            }

            if (this.calibration.calibrationTemplate && !this.calibration.calibrationTemplate?.procedure) {
                this.calibration.calibrationTemplate.procedure = tempProcedureNumber
            }
        }
    }

    private hasMultipleTemplates(): boolean {
        return (this.equipmentTemplates?.length ?? 0) > 1
    }

    private isCurrentTemplateAvailable(): boolean {
        if (!this.equipmentTemplates) {
            return false
        }
        return !!this.equipmentTemplates
            .find(template => template.id === this.calibration?.templateId)
    }

    private getCurrentTemplate(): CalibrationTemplate {
        return this.equipmentTemplates?.find(template =>
            template.id === this.calibration?.templateId)?.detail as CalibrationTemplate
    }

    private technicianNotExpired(): boolean {
        const expiryDate = (this.identityService?.currentIdentity?.certificationExpiryDate?.toString() ?? null)
        const employmentType = (this.identityService?.currentIdentity?.employmentType?.id ?? null) as number

        if (employmentType === EmploymentType.Employee) {
            return true
        }

        if (employmentType === EmploymentType.NonEmployee && expiryDate) {
            return new Date(expiryDate) > new Date()
        }
        return false
    }

    private updateInitDataStatus(): void {
        this.isCalibrationDataReady$.next({
            calibrationReady: this.calibration !== undefined,
            workOrderReady: this.workOrderDetails !== undefined,
            equipmentTemplateReady: this.equipmentTemplates !== undefined
        })
    }

    private validateInitCalibrationData(): void {
        const intervention = this.getCalibrationInterventionStep()

        if (intervention === CalibrationInterventionStep.TemplateModified) {
            this.calibrationIntervention$.next(null)
        } else {
            this.calibrationIntervention$.next(intervention)
        }

        if (!this.isInitDataValidated) {
            this.isInitDataValidated = true
            this.showModal(intervention)
        }
    }

    private getCalibrationInterventionStep(): CalibrationInterventionStep {
        if (this.isInitDataValidated) {
            return null
        }

        if (!this.calibration && !this.isATechnician$.value) {
            return CalibrationInterventionStep.NotTechnician
        }

        if (!this.technicianNotExpired()) {
            return CalibrationInterventionStep.TechnicianExpired
        }

        if (this.isTemplateModified()) {
            return CalibrationInterventionStep.TemplateModified
        }

        if (!this.calibration && this.hasMultipleTemplates()) {
            return CalibrationInterventionStep.HasMultipleTemplate
        }

        return null
    }

    private showModal(calibrationIntervention: CalibrationInterventionStep): void {
        let modal: Modal = deepCopy(modalContent.calibrationBlocked)
        const end = this.router.url.indexOf('calibration')
        const url = this.router.url.slice(0, end) + 'overview'
        let modalCallBack = () => this.router.navigateByUrl(url)

        switch (calibrationIntervention) {
            case CalibrationInterventionStep.TechnicianExpired:
                modal.title = modalMessageBody.technicianCertExpired.title
                modal.body = modalMessageBody.technicianCertExpired.body
                break
            case CalibrationInterventionStep.NotTechnician:
                modal.title = modalMessageBody.notATechnician.title
                modal.body = modalMessageBody.notATechnician.body
                break
            case CalibrationInterventionStep.TemplateModified:
                this.calibration.calibrationResult = { results: [{ resultSetName: '', resultSet: [] }] } as CalibrationResult
                this.calibration.calibrationTemplate = deepCopy(this.getCurrentTemplate())

                modal = deepCopy(modalContent.templateModifiedWarning) as Modal
                modal.confirmCallback = () => { }
                modalCallBack = () => Promise.resolve(true)
                break
            default:
                // There is a API race condition which cause this function to run couple of times
                // At first few hit of this function call some API data is not fully loaded
                // Causing the modal to be open at incorrect state while offline
                // A DestroyModalAction() to correct the final call
                if (!this.offlineService.isOnline) {
                    this.store.dispatch(new DestroyModalAction())
                }
                return
        }

        modal.closeCallback = modalCallBack
        this.store.dispatch(new ShowModalAction(modal))
    }
}
