import { Component, Injector, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'

import { SpecialUomCode } from '@app/models/special-uom.model'
import { CalibrationDetails } from '@app/modules/calibration/models/calibration-details.model'
import { CalibrationResultSet } from '@app/modules/calibration/models/calibration-result-set.model'
import { CalibrationResult } from '@app/modules/calibration/models/calibration-result.model'
import { CalibrationValidationStatus } from '@app/modules/calibration/models/calibration-validation-status.enum'
import { SafetyValveTemplate } from '@app/modules/calibration/models/safety-valve-template.model'
import { CalibrationInitializerService } from '@app/modules/calibration/services/calibration-initializer.service'
import { ToleranceCalculationType } from '@app/modules/shared/models/engineering-units/setpoint.model'
import { Tolerance } from '@app/modules/shared/models/engineering-units/tolerance.model'
import { UnitRange } from '@app/modules/shared/models/engineering-units/unit-range.model'
import { UnitValue } from '@app/modules/shared/models/engineering-units/unit-value.model'
import { staticRangeValidator } from '@app/modules/shared/validators/static-range-validator/static-range-validator'
import { EngineeringCalculationService } from '@app/services/engineering-logic-services/engineering-calculation.service'
import { AbstractCalibrationTemplateComponent } from '../abstract-calibration-template.component'

@Component({
    selector: 'app-safety-valve',
    templateUrl: './safety-valve.component.html',
    styleUrls: ['./safety-valve.component.scss']
})
export class SafetyValveComponent extends AbstractCalibrationTemplateComponent implements OnInit {

    public templateSetting: SafetyValveTemplate

    public asFoundValidationStatuses = [
        CalibrationValidationStatus.Initialize,
        CalibrationValidationStatus.Initialize,
        CalibrationValidationStatus.Initialize
    ]
    public asLeftValidationStatus = CalibrationValidationStatus.Initialize


    public upperExpectedValue: number
    public lowerExpectedValue: number
    public upperAsLeftExpectedValue: number
    public lowerAsLeftExpectedValue: number

    private readonly numberOfPoints = 3

    constructor(
        injector: Injector,
        private calibrationInitializerService: CalibrationInitializerService,
        private formBuilder: FormBuilder
    ) {
        super(injector)
    }

    // TODO: Remove this once calibrationResultStatus calculation logic is ready
    public get templateWillCalculateCalibrationResultStatus(): boolean {
        return false
    }

    public get resultSetControl(): FormArray {
        const resultFormArray = this.calibrationResult.get('results') as FormArray
        return resultFormArray.at(0).get('resultSet') as FormArray
    }

    ngOnInit(): void {
        super.ngOnInit()

        this.templateSetting = this.calibration.calibrationTemplate as SafetyValveTemplate

        this.initializeForm(this.calibrationFormResults, this.calibration)

        const results = this.calibrationResult.get('results') as FormArray
        for (const r of results.controls) {
            const resultSetGroup = r as FormGroup

            this.attachValidatorsForResultSet(
                resultSetGroup.get('resultSet') as FormArray,
                this.templateSetting
            )
        }

    }

    public updateCalibrationResultStatus(): void {
        // For this template, CalibrationResultStatus should be selected and not calculated
        return
    }

    public fillInSkippedAsFound(): void {
        const results = this.calibrationResult.get('results') as FormArray
        const resultSet = results.at(0).get('resultSet') as FormArray

        for (let i = 0; i < resultSet.controls.length - 1; i++) {
            const asFoundAtIndex = resultSet.at(i).get('asFound') as FormControl
            const asFoundAtNextIndex = resultSet.at(i + 1).get('asFound') as FormControl
            if (asFoundAtIndex.value === null || asFoundAtIndex.value === undefined) {
                asFoundAtIndex.patchValue(asFoundAtNextIndex.value)
                asFoundAtNextIndex.patchValue(null)
            }
        }
    }

    private initializeForm(
        calibrationFormResults: FormGroup,
        calibrationDetails: CalibrationDetails
    ): void {

        const calibrationResult = (calibrationDetails?.calibrationResult ?? new CalibrationResult())

        // Init resultSet FormGroup with existing values, if any exist
        const resultSet = this.calibrationInitializerService.initializeResultSetForm(
            '',
            calibrationResult.results[0] || new CalibrationResultSet(),
            this.numberOfPoints
        )

        // This template only have one type of resultSet but we still need to
        // wrap it in a FormArray then in a FormGroup
        const calibrationResultFormGroup = this.formBuilder.group({
            results: this.formBuilder.array([resultSet])
        })

        // Replace the default FormControl with this newly created Form structure
        calibrationFormResults.setControl('calibrationResult', calibrationResultFormGroup)

    }

    private attachValidatorsForResultSet(
        resultSets: FormArray,
        templateSetting: SafetyValveTemplate
    ): void {
        const expectedValueRange = this.calculateAcceptableRange(templateSetting.setPoint, templateSetting.tolerance)
        const asLeftExpectedValueRange = this.calculateAcceptableRange(templateSetting.setPoint, templateSetting.asLeftTolerance)

        this.upperExpectedValue = expectedValueRange.maximumRange
        this.lowerExpectedValue = expectedValueRange.minimumRange
        this.upperAsLeftExpectedValue = asLeftExpectedValueRange.maximumRange
        this.lowerAsLeftExpectedValue = asLeftExpectedValueRange.minimumRange

        resultSets.controls.forEach((r, i) => {
            const resultSet = r as FormGroup

            resultSet.get('asFound').setValidators([staticRangeValidator({
                max: this.upperExpectedValue,
                min: this.lowerExpectedValue,
                onValid: () => this.asFoundValidationStatuses[i] = CalibrationValidationStatus.Valid,
                onInvalid: () => this.asFoundValidationStatuses[i] = CalibrationValidationStatus.Invalid,
                onNeutral: () => this.asFoundValidationStatuses[i] = CalibrationValidationStatus.Initialize
            })])

            // Init validation for AsLeft we've to provide for all result set objects
            // But for the UI just render only fisrt AsLeft that's why we've to add this condition to set this.asLeftValidationStatus properly
            const isFirstResultSet = i === 0
            const asLeftValidator = isFirstResultSet ?
                {
                    onValid: () => this.asLeftValidationStatus = CalibrationValidationStatus.Valid,
                    onInvalid: () => this.asLeftValidationStatus = CalibrationValidationStatus.Invalid,
                    onNeutral: () => this.asLeftValidationStatus = CalibrationValidationStatus.Initialize
                } : {}

            resultSet.get('asLeft').setValidators([staticRangeValidator({
                max: this.upperAsLeftExpectedValue,
                min: this.lowerAsLeftExpectedValue,
                ...asLeftValidator
            })])
        })
    }

    private calculateAcceptableRange(setPoint: UnitValue, tolerance: Tolerance): UnitRange {
        const setPointUom = setPoint.unitOfMeasurement
        const isSetPointPercentage = setPointUom.uomCode === SpecialUomCode.Percentage

        return EngineeringCalculationService.calculateAcceptableRange(
            setPoint,
            tolerance,
            this.getToleranceCalculationType(tolerance, isSetPointPercentage)
        )
    }

    private getToleranceCalculationType(tolerance: Tolerance, isSetPointPercentage: boolean): ToleranceCalculationType {
        const toleranceUom = tolerance.unitOfMeasurement
        const isTolerancePercentage = toleranceUom.uomCode === SpecialUomCode.Percentage

        return isTolerancePercentage && !isSetPointPercentage ?
            ToleranceCalculationType.Multiplicative : ToleranceCalculationType.Additive
    }
}
