import { Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { combineLatest, Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'

import { Plant, UserProfile } from '@app/models/user.model'
import { modalContent } from '@app/modules/modal-container/constants/modal-message-content.constant'
import { modalMessageBody } from '@app/modules/modal-container/constants/modal-message.constant'
import { Modal } from '@app/modules/modal-container/models/modal.model'
import { AppState } from '@app/store/app.store'
import { isOnline } from '@app/store/connection/connection.selectors'
import { UpdateUserAction } from '@app/store/identity/identity.actions'
import { currentUser, plants, selectedPlant } from '@app/store/identity/identity.selectors'
import { ShowModalAction } from '@app/store/modal/modal.actions'
import { ClearOfflineCache } from '@app/store/offline/offline.actions'
import { deepCopy } from '@app/utils/app-utils.function'
import { some } from 'lodash'

@Component({
    selector: 'app-plant-selection',
    templateUrl: './plant-selection.component.html',
    styleUrls: ['./plant-selection.component.scss']
})
export class PlantSelectionComponent implements OnInit {
    public plants$: Observable<Plant[]>
    public selectedPlant$: Observable<Plant>
    public isOnline$: Observable<boolean>

    constructor(private store: Store<AppState>) { }

    ngOnInit(): void {
        this.plants$ = this.store.pipe(select(plants))
        this.selectedPlant$ = combineLatest(
            [this.store.pipe(select(selectedPlant)),
            this.store.pipe(select(plants))]
        ).pipe(
            map(([selectedPlantCode, plantList]) =>
                plantList.find(p => p.plantCode === selectedPlantCode) || { plantCode: selectedPlantCode, plantName: null })
        )
        this.isOnline$ = this.store.pipe(select(isOnline))
    }

    public selectPlant(plant: Plant, currentSelectedPlant: Plant): void {
        if (plant.plantCode === currentSelectedPlant.plantCode) {
            return
        }

        const modal = deepCopy(modalContent.changePlant) as Modal
        modal.body = modalMessageBody.changePlant.body.replace('[plantCode]', this.formatPlant(plant))
        modal.confirmCallback = () => this.confirmPlantSelectionAction(plant)

        this.store.dispatch(new ShowModalAction(modal))
    }

    public formatPlant(plant: Plant): string {
        return `${plant.plantCode}${plant.plantName ? ' - ' + plant.plantName : ''}`
    }

    public hasMorePlants(): Observable<boolean> {
        return combineLatest(
            [this.plants$,
            this.selectedPlant$]
        ).pipe(
            map(([plantList, currentSelectedPlant]) =>
                (plantList.length > 1 || !some(plantList, currentSelectedPlant)))
        )
    }

    public confirmPlantSelectionAction(plant: Plant): void {
        this.store.dispatch(new ClearOfflineCache())
        this.store
            .pipe(select(currentUser), take(1))
            .subscribe((userProfile: UserProfile) => {
                const updatedUser = { ...userProfile }
                updatedUser.preferredPlantCode = plant.plantCode
                this.store.dispatch(new UpdateUserAction(updatedUser))
            })
    }
}
