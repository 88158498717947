import { Component, Injector, Input, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { debounceTime } from 'rxjs/operators'

import { CalibrationResultValue } from '@app/modules/calibration/models/calibration-result-value.model'
import { CalibrationResult } from '@app/modules/calibration/models/calibration-result.model'
import { CalibrationValidationStatus } from '@app/modules/calibration/models/calibration-validation-status.enum'
import { MultiPointTemplate } from '@app/modules/calibration/models/multi-point-template.model'
import { CalibrationInitializerService } from '@app/modules/calibration/services/calibration-initializer.service'
import { CalibrationValidatorService } from '@app/modules/calibration/services/calibration-validator.service'
import { CalibrationResultStatusService } from '@app/modules/calibration/services/calibration-result-status.service'
import { MultiPointCalibrationService } from '@app/modules/calibration/services/multi-point-calibration.service'
import { Tolerance } from '@app/modules/shared/models/engineering-units/tolerance.model'
import { SetPointService } from '@app/services/engineering-logic-services/setpoint.service'
import { isNotAValue, round } from '@app/utils/app-utils.function'
import { AbstractCalibrationTemplateComponent } from '../abstract-calibration-template.component'
import { UnitRange } from '@app/modules/shared/models/engineering-units/unit-range.model'
import { CalibrationResultStatusEnum } from '@app/modules/calibration/models/calibration-result-status.enum'

@Component({
    selector: 'app-multi-point',
    templateUrl: './multi-point.component.html',
    styleUrls: ['./multi-point.component.scss']
})
export class MultiPointComponent extends AbstractCalibrationTemplateComponent implements OnInit {
    @Input() extraUpdateResultStatus

    public template: MultiPointTemplate

    public storedValidationStatuses: { asFound: number, asLeft: number }[] = []
    public expectedRange: UnitRange

    constructor(
        protected calibrationResultStatusService: CalibrationResultStatusService,
        private calibrationValidatorService: CalibrationValidatorService,
        private calibrationInitializerService: CalibrationInitializerService,
        private multiPointCalibrationService: MultiPointCalibrationService,
        private formBuilder: FormBuilder,
        injector: Injector
    ) {
        super(injector)
    }

    ngOnInit(): void {
        super.ngOnInit()
        this.template = this.calibration.calibrationTemplate as MultiPointTemplate
        this.initForm()
    }

    public getUOM(section: 'setPoint' | 'expectedReading' | 'tolerance', index = 0): string {
        const setPoint = this.template?.setPoints[index]

        if (isNotAValue(setPoint)) {
            return ''
        }
        return SetPointService.getFormattedUOMBySection(setPoint, section)
    }

    public isSetPointAdjustable(index: number): boolean {
        return this.template.setPoints[index].isSetPointAdjustable
    }

    public getToleranceRange(tolerance: Tolerance): string {
        return tolerance.lowerRange + ' to ' + tolerance.higherRange
    }

    public getExpectedUOM(): string {
        return this.expectedRange?.unitOfMeasurement?.uomCodeForTech ?? ''
    }

    public getExpectedRange(index: number): string {
        const resultSet = this.getResultSetFormArray().controls[index].value as CalibrationResultValue
        this.expectedRange = SetPointService.getAcceptableRange(this.template.setPoints[index], resultSet.adjustedInjectedInput)
        this.expectedRange.minimumRange = round(this.expectedRange.minimumRange)
        this.expectedRange.maximumRange = round(this.expectedRange.maximumRange)
        return this.expectedRange.minimumRange + ' to ' + this.expectedRange.maximumRange
    }

    public getExpectedRangeValue(index: number): UnitRange {
        const resultSet = this.getResultSetFormArray().controls[index].value as CalibrationResultValue
        this.expectedRange = SetPointService.getAcceptableRange(this.template.setPoints[index], resultSet.adjustedInjectedInput)
        this.expectedRange.minimumRange = round(this.expectedRange.minimumRange)
        this.expectedRange.maximumRange = round(this.expectedRange.maximumRange)
        return this.expectedRange
    }

    public autoPopulateAsLeft(): void {
        const populated = this.multiPointCalibrationService.autoPopulate(this.getResultSetFormArray(), this.template)
        if (populated) {
            for (let i = 0; i < this.template.numberOfPoint; i++) {
                this.revalidateInputAtIndex(i, 'asLeft')
            }
        }
        this.updateCalibrationResultStatus()
    }

    public getCalculatedResultStatusBySetpoints(): CalibrationResultStatusEnum {
        const numberOfPoint = this.template.numberOfPoint
        const calibrationValues = this.getResultSetFormArray().getRawValue()
        const allInputResultAreEmpty = this.calibrationValidatorService.areAllInputResultEmpty(this.getResultSetFormArray())

        if (allInputResultAreEmpty) {
            return undefined
        }

        let asFoundPass = 0
        let asLeftPass = 0

        calibrationValues.forEach(resultSet => {
            asFoundPass += this.multiPointCalibrationService.isInRange(this.template, resultSet, 'asFound') ? 1 : 0
        })

        calibrationValues.forEach(resultSet => {
            asLeftPass += this.multiPointCalibrationService.isInRange(this.template, resultSet, 'asLeft') ? 1 : 0
        })

        return  this.multiPointCalibrationService.calculateResultStatus(asFoundPass, asLeftPass, numberOfPoint)
    }

    public updateCalibrationResultStatus(): void {
        const result = this.getCalculatedResultStatusBySetpoints()
        if (this.extraUpdateResultStatus) {
            this.extraUpdateResultStatus(result)
            return
        }
        this.updateStatusDirectly(result)
    }

    public revalidateInputAtIndex(index: number, field: 'asFound' | 'asLeft'): number {
        const resultSet = this.getResultSetFormArray().controls[index].value
        this.storedValidationStatuses[index][field] = this.validateInput(resultSet, field)
        return this.storedValidationStatuses[index][field]
    }

    private updateStatusDirectly(resultStatusCalculatedBySetpoint): void {
        const resultStatusForm = this.calibrationForm.get('results.calibrationResultStatus') as FormControl
        this.calibrationResultStatusService.updateCalibrationResultStatus(resultStatusForm, resultStatusCalculatedBySetpoint)
    }

    private validateInput(resultSet: CalibrationResultValue, field: 'asFound' | 'asLeft'): number {
        if (resultSet[field] === null) {
            return CalibrationValidationStatus.Initialize
        }
        if (this.multiPointCalibrationService.isInRange(this.template, resultSet, field)) {
            return CalibrationValidationStatus.Valid
        }

        return CalibrationValidationStatus.Invalid
    }

    private initForm(): void {
        const numberOfPoint = this.template.numberOfPoint
        const calibrationResultSet = this.calibration?.calibrationResult ?? new CalibrationResult()

        // Initialze inner formArray, patching any saved input in the process
        const _resultSetFormArray = this.calibrationInitializerService.initializeResultSetForm('', calibrationResultSet.results[0], numberOfPoint)

        // If adjusted input is allow, populate those fields as well
        const resultSet = _resultSetFormArray.get('resultSet') as FormArray
        resultSet.controls.forEach((result, i) => {
            if (this.template.setPoints[i].isSetPointAdjustable) {
                const adjustedInjectedInputControl = result.get('adjustedInjectedInput')
                const userAdjustedInjectedInput = calibrationResultSet.results[0].resultSet[i]?.adjustedInjectedInput

                // If the field is empty, set it to the value from admin.
                if (isNotAValue(userAdjustedInjectedInput)) {
                    adjustedInjectedInputControl.setValue(this.template.setPoints[i].setPoint.value)
                } else {
                    adjustedInjectedInputControl.setValue(userAdjustedInjectedInput)
                }
            }

            // Calculate initial validation statuses
            this.storedValidationStatuses.push({
                asFound: this.validateInput(result.value, 'asFound'),
                asLeft: this.validateInput(result.value, 'asLeft')
            })

        })

        // Now, insert this processed form into our main form.
        const innerForm = this.calibrationForm.get('results') as FormGroup
        innerForm.setControl('calibrationResult', this.formBuilder.group({
            results: this.formBuilder.array([_resultSetFormArray])
        }))

        this.addSubscription(this.calibrationForm.get('results.calibrationResult.results').valueChanges.pipe(
            debounceTime(300)
        ).subscribe(() => {
            this.updateCalibrationResultStatus()
        }))

    }
}
