import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { Store } from '@ngrx/store'

import { PageComponentName, PageComponentState } from '@app/models/page-component-state.model'
import { AppState } from '@app/store/app.store'
import { LogToPageHistoryAction } from '@app/store/page-history/page-history.actions'
import { pageHistorySettingForPageName } from '@app/store/page-history/page-history.selectors'
import { SectionLoaderEnum } from '../../models/section-loader.enum'
import { SectionLoaderService } from '../../services/section-loader.service'
import { ListComponent } from '../list/list.component'

@Component({
    selector: 'app-paged-list',
    templateUrl: './paged-list.component.html',
    styleUrls: ['./paged-list.component.scss']
})

export class PagedListComponent extends ListComponent implements OnInit, OnChanges, OnDestroy {
    @Input() itemsPerPage = 10
    @Input() pageName: PageComponentName
    @Output() pageChanged = new EventEmitter<number>()

    public pages: any[][] = [[]]
    public scrollPosition = 0
    public selectedPage = 0
    public equipmentLoaded = false
    public pageConfigState: PageComponentState

    constructor(
        private store: Store<AppState>,
        sectionLoaderService: SectionLoaderService
    ) {
        super(sectionLoaderService)
    }
    @ViewChild('pagedListReference', { static: true }) set sectionLoader(ref: ElementRef) {
        if (!this.sectionLoaderRef) {
            this.registerSectionLoader(SectionLoaderEnum.PagedList, ref)
        }
    }

    public get isFirstPage(): boolean {
        return this.selectedPage === 0
    }

    public get isLastPage(): boolean {
        return this.selectedPage === this.pages.length - 1
    }

    public get firstItemIndex(): number {
        return this.selectedPage * this.itemsPerPage + 1
    }

    public get lastItemIndex(): number {
        return this.firstItemIndex + this.pages[this.selectedPage].length - 1
    }

    ngOnInit(): void {
        this.pages = this.createPage()
        this.initializeHistory()
    }

    ngOnChanges(): void {
        this.pages = this.createPage()
    }

    ngOnDestroy(): void {
        this.logHistory()
    }

    @HostListener('window:scroll', ['$event.srcElement.scrollingElement.scrollTop'])
    recordScrollPosition(scrollTop: number): void {
        this.scrollPosition = scrollTop
    }

    public initializeHistory(): void {
        this.addSubscription(
            this.store.select(pageHistorySettingForPageName(this.pageName)).subscribe(config => {
                this.pageConfigState = config
                this.restorePageConfigState()
            })
        )

        this.restorePageConfigState()
    }

    public restorePageConfigState(): void {
        if (this.pageConfigState) {
            setTimeout(() => {
                this.selectedPage = this.pageConfigState.selectedPage
                window.scrollTo(0, this.pageConfigState.scrollPosition)
            })
        }
    }

    public createPage(): any[][] {
        const newItems = this.items.filter(item => item.isNew === true)
        this.pages = [[]]
        this.items.filter(item => item.isNew === false || !item.isNew)
            .forEach((item: any, index: number) => {
                let pageNumber = Math.trunc(index / this.itemsPerPage)
                pageNumber = this.selectedPage === pageNumber && newItems.length > 0 ?
                    Math.trunc((index + newItems.length) / this.itemsPerPage) : pageNumber

                if (!this.pages[pageNumber]) {
                    this.pages[pageNumber] = []
                }

                this.pages[pageNumber].push(item)
            })

        if (newItems.length > 0) {
            newItems.forEach(newItem => this.pages[this.selectedPage].unshift(newItem))
        }

        if (this.items.length < this.firstItemIndex) {
            this.selectedPage = 0
        }

        return this.pages
    }

    public nextPage(): void {
        if (!this.isLastPage) {
            ++this.selectedPage
        }

        if (this.items.filter(item => item.isNew === true).length > 0) {
            this.pageChanged.next(1)
        }
    }

    public previousPage(): void {
        if (!this.isFirstPage) {
            --this.selectedPage
        }

        if (this.items.filter(item => item.isNew === true).length > 0) {
            this.pageChanged.next(1)
        }
    }

    public logHistory(): void {
        this.store.dispatch(new LogToPageHistoryAction({
            name: this.pageName,
            scrollPosition: this.scrollPosition,
            selectedPage: this.selectedPage
        }))
    }
}
