<app-calibration-template-header [template]="template"></app-calibration-template-header>
<section *ngIf="!isOnMobileOrTabletDevice">
    <div *ngIf="isInjected; else notInjected">
        <app-result-set-header [template]="template"
                               [hasInjectedInput]="isInjected"
                               [isAsFound]="true"></app-result-set-header>
        <div *ngFor="let form of getResultSetControl().controls; let i = index"
             class="em-l-grid em-l-grid--4up result-row">
            <div class="em-l-grid__item">
                <div class="em-c-field__label mobile-label">Input ({{template.input.unitOfMeasurement.uomCodeForTech}})</div>
                {{ form.get('input').value }}
            </div>
            <div class="em-l-grid__item">
                <div class="em-c-field__label mobile-label">Adjusted Input ({{template.input.unitOfMeasurement.uomCodeForTech}})</div>
                <app-label [classList]="isDisabled ? 'em-is-disabled' : ''">
                    <app-numeric-input [appInputDisplayReport]="isReport"
                                       [formControl]="form.get('injectedInput')"
                                       [tabIndex]="i + 1"
                                       [inputId]="'adjusted-asfound-' + i"
                                       placeholder="0.0">
                    </app-numeric-input>
                </app-label>
            </div>
            <div class="em-l-grid__item">
                <div class="em-c-field__label mobile-label">Expected ({{template.expected.unitOfMeasurement.uomCodeForTech}})</div>
                {{ form.get('asFoundExpected').value }}
            </div>
            <div class="em-l-grid__item">
                <div class="em-c-field__label mobile-label">As Found ({{template.expected.unitOfMeasurement.uomCodeForTech}})</div>
                <app-label [classList]="isDisabled ? 'em-is-disabled' : ''">
                    <app-numeric-input appInputValidator
                                       [appCalibrationInputReport]="isReport"
                                       [formControl]="form.get('asFound')"
                                       [input]="validateInput(true, form)"
                                       [tabIndex]="i + 2"
                                       [inputId]="'asfound-' + i"
                                       (inputBlur)="autoPopulateAsLeft()"
                                       placeholder="0.0">
                    </app-numeric-input>
                </app-label>
            </div>
        </div>
        <app-result-set-header [template]="template"
                               [hasInjectedInput]="isInjected"
                               [isAsFound]="false"></app-result-set-header>
        <div *ngFor="let form of getResultSetControl().controls; let i = index"
             class="em-l-grid em-l-grid--4up result-row">
            <div class="em-l-grid__item">
                <div class="em-c-field__label mobile-label">Input ({{template.input.unitOfMeasurement.uomCodeForTech}})</div>
                {{ form.get('input').value }}
            </div>
            <div class="em-l-grid__item">
                <div class="em-c-field__label mobile-label">Adjusted Input ({{template.input.unitOfMeasurement.uomCodeForTech}})</div>
                <app-label [classList]="isDisabled ? 'em-is-disabled' : ''">
                    <app-numeric-input [appInputDisplayReport]="isReport"
                                       [formControl]="form.get('adjustedInjectedInput')"
                                       [tabIndex]="i + 1 + (template.numberOfPoint * 2)"
                                       [inputId]="'adjusted-asleft-' + i"
                                       placeholder="0.0">
                    </app-numeric-input>
                </app-label>
            </div>
            <div class="em-l-grid__item">
                <div class="em-c-field__label mobile-label">Expected ({{template.expected.unitOfMeasurement.uomCodeForTech}})</div>
                {{ form.get('asLeftExpected').value }}
            </div>
            <div class="em-l-grid__item">
                <div class="em-c-field__label mobile-label">As Left ({{template.expected.unitOfMeasurement.uomCodeForTech}})</div>
                <app-label [classList]="isDisabled ? 'em-is-disabled' : ''">
                    <app-numeric-input appInputValidator
                                       [appCalibrationInputReport]="isReport"
                                       [formControl]="form.get('asLeft')"
                                       [input]="validateInput(false, form)"
                                       [tabIndex]="i + 2 + (template.numberOfPoint * 2)"
                                       [inputId]="'asleft-' + i"
                                       placeholder="0.0">
                    </app-numeric-input>
                </app-label>
            </div>
        </div>
    </div>
    <ng-template #notInjected>
        <app-result-set-header [template]="template"
                               [hasInjectedInput]="isInjected"
                               [isAsFound]="false"></app-result-set-header>
        <div *ngFor="let form of getResultSetControl().controls; let i = index"
             class="em-l-grid em-l-grid--4up">
            <div class="em-l-grid__item">
                {{ form.get('input').value }}
            </div>
            <div class="em-l-grid__item">
                {{ form.get('asLeftExpected').value }}
            </div>
            <div class="em-l-grid__item">
                <app-label [classList]="isDisabled ? 'em-is-disabled' : ''">
                    <app-numeric-input appInputValidator
                                       [appCalibrationInputReport]="isReport"
                                       [formControl]="form.get('asFound')"
                                       [input]="validateInput(true, form)"
                                       [tabIndex]="i + 1"
                                       (inputBlur)="autoPopulateAsLeft()"
                                       placeholder="0.0">
                    </app-numeric-input>
                </app-label>
            </div>
            <div class="em-l-grid__item">
                <app-label [classList]="isDisabled ? 'em-is-disabled' : ''">
                    <app-numeric-input appInputValidator
                                       [appCalibrationInputReport]="isReport"
                                       [formControl]="form.get('asLeft')"
                                       [input]="validateInput(false, form)"
                                       [tabIndex]="i + template.numberOfPoint + 1"
                                       placeholder="0.0">
                    </app-numeric-input>
                </app-label>
            </div>
        </div>
    </ng-template>
</section>

<section *ngIf="isOnMobileOrTabletDevice">
    <app-section [title]="'As Found (' + template.input.unitOfMeasurement.uomCodeForTech + ')'">
        <div body
             class="responsive-spacing">
            <div *ngFor="let form of getResultSetControl().controls; let i = index">
                <app-responsive-calibration-input [allowReading]="true"
                                                  [showLineBreak]="true"
                                                  [layoutSimple]="false"
                                                  [displayInputLabel]="'Input (' + template.input.unitOfMeasurement.uomCodeForTech + ')'"
                                                  [displayResultLabel]="form.get('input').value"
                                                  [isAdjusted]="isInjected"
                                                  [adjusted]="'Input ' + (i + 1) + ' (' + template.input.unitOfMeasurement.uomCodeForTech + ')'"
                                                  [reading]="'Reading ' + (i + 1) + ' (' + template.expected.unitOfMeasurement.uomCodeForTech + ')'"
                                                  [readingExpected]="'Expected ' + form.get('asFoundExpected').value">
                    <div adjustedInput>
                        <app-numeric-input [appInputDisplayReport]="isReport"
                                           [formControl]="form.get('injectedInput')"
                                           [blurToOriginalValue]="true"
                                           placeholder="0.0">
                        </app-numeric-input>
                    </div>
                    <div readingInput>
                        <app-numeric-input appInputValidator
                                           [appCalibrationInputReport]="isReport"
                                           [formControl]="form.get('asFound')"
                                           [input]="validateInput(true, form)"
                                           (inputBlur)="autoPopulateAsLeft()"
                                           placeholder="0.0">
                        </app-numeric-input>
                    </div>
                </app-responsive-calibration-input>
            </div>
        </div>
    </app-section>
    <app-section [title]="'As Left (' + template.input.unitOfMeasurement.uomCodeForTech + ')'">
        <div body
             class="responsive-spacing">
            <div *ngFor="let form of getResultSetControl().controls; let i = index">
                <app-responsive-calibration-input [allowReading]="true"
                                                  [showLineBreak]="true"
                                                  [layoutSimple]="false"
                                                  [displayInputLabel]="'Input (' + template.input.unitOfMeasurement.uomCodeForTech + ')'"
                                                  [displayResultLabel]="form.get('input').value"
                                                  [isAdjusted]="isInjected"
                                                  [adjusted]="'Input ' + (i + 1) + ' (' + template.input.unitOfMeasurement.uomCodeForTech + ')'"
                                                  [reading]="'Reading ' + (i + 1) + ' (' + template.expected.unitOfMeasurement.uomCodeForTech + ')'"
                                                  [readingExpected]="'Expected ' + form.get('asLeftExpected').value">
                    <div adjustedInput>
                        <app-numeric-input [appInputDisplayReport]="isReport"
                                           [formControl]="form.get('adjustedInjectedInput')"
                                           [blurToOriginalValue]="true"
                                           placeholder="0.0">
                        </app-numeric-input>
                    </div>
                    <div readingInput>
                        <app-numeric-input appInputValidator
                                           [appCalibrationInputReport]="isReport"
                                           [formControl]="form.get('asLeft')"
                                           [input]="validateInput(false, form)"
                                           (inputBlur)="autoPopulateAsLeft()"
                                           placeholder="0.0">
                        </app-numeric-input>
                    </div>
                </app-responsive-calibration-input>
            </div>
        </div>
    </app-section>
</section>
