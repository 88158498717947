import { FormControlError } from '@app/modules/shared/models/form-control-error.model'
import { FormValidatorResult } from '@app/modules/shared/models/form-validator-result.model'
import { humanizeStr } from '@app/utils/strings/humanize-str'
import { FormErrorHandler } from './abstract-form-error-handler'

export class DefaultErrorHanlder extends FormErrorHandler {
    public handle(formControlError: FormControlError, results: FormValidatorResult, ...args: any): FormValidatorResult {
        if (this.next) {
            throw new Error('DefaultErrorHandler (FormErrorHandler) should be the last handler in the chain.')
        }
        const errorMessage = humanizeStr`The field '${formControlError.controlName}' is invalid`

        return {
            ...results,
            preventSubmit: true,
            errorMessages: [
                ...results.errorMessages, errorMessage
            ]
        }
    }

}
