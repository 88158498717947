import { Pipe, PipeTransform } from '@angular/core'

import { testingDirectionOptions } from '@app/modules/equipment/models/default-template-options-values'
import { TestingDirection } from '@app/modules/equipment/models/testing-direction.enum'

@Pipe({
    name: 'toReadableTestingDirection',
    pure: true
})
export class ToReadableTestingDirection implements PipeTransform {
    transform(value: TestingDirection): string {
        const option = testingDirectionOptions.find(i => i.key === value)
        return (option && option.value) || '-'
    }
}
