import { Injectable } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'

import { SpecialUomCode } from '@app/models/special-uom.model'
import { UnitRange } from '@app/modules/shared/models/engineering-units/unit-range.model'
import { isNotAValue, round } from '@app/utils/app-utils.function'
import { CalibrationResultSet } from '../models/calibration-result-set.model'
import { CalibrationResultStatusEnum } from '../models/calibration-result-status.enum'
import { RepeatabilityCalibrationResultRow } from '../models/calibration-result-value.model'
import { CalibrationValidationStatus } from '../models/calibration-validation-status.enum'
import { RepeatabilityTemplate } from '../models/repeatability-template.model'
import { CalibrationInitializerService } from './calibration-initializer.service'
import { CalibrationValidatorService } from './calibration-validator.service'
import { CalibrationResultStatusService } from './calibration-result-status.service'
import { RangeCalculationService } from './range-calculation.service'

@Injectable({
    providedIn: 'root'
})
export class RepeatabilityCalibrationService {

    constructor(
        private formBuilder: FormBuilder,
        private rangeCalculator: RangeCalculationService,
        private calibrationValidatorService: CalibrationValidatorService,
        private calibrationResultStatusService: CalibrationResultStatusService,
        private calibrationInitializerService: CalibrationInitializerService) { }

    public initialize(repeatabiltiyForm: FormGroup, resultSet: CalibrationResultSet, template: RepeatabilityTemplate): void {
        const resultSetControl = this.calibrationInitializerService.initializeResultSetForm(
            '',
            resultSet,
            template.numberOfPoint,
            false
        )

        this.initializeInjectedInput(
            resultSetControl.get('resultSet') as FormArray,
            template
        )

        this.transformResultSet(
            resultSetControl.get('resultSet') as FormArray,
            resultSet.resultSet as RepeatabilityCalibrationResultRow[],
            template
        )
        this.updateCalibrationForm(resultSetControl, repeatabiltiyForm)
    }

    public initializeInjectedInput(resultSetControl: FormArray, template: RepeatabilityTemplate): void {
        const injectedInput = resultSetControl.at(0).get('injectedInput').value
        if (isNotAValue(injectedInput)) {
            resultSetControl.controls.forEach(resultControl => {
                resultControl.patchValue({
                    injectedInput: template.input.minimumRange
                })
            })
        }
    }

    public autoPopulate(calibrationForm: FormArray, template: RepeatabilityTemplate): void {
        let isValid = 0
        calibrationForm.controls.forEach((control: FormGroup) => {
            const status = this.validateInput(true, control, template, calibrationForm.value)
            isValid += status === CalibrationValidationStatus.Valid ? 1 : 0
        })

        let asLeftCompleted = 0
        calibrationForm.controls.forEach(control =>
            asLeftCompleted += isNotAValue(control.get('asLeft').value) ? 0 : 1
        )

        if (isValid === template.numberOfPoint && asLeftCompleted === 0) {
            calibrationForm.controls.forEach((control: FormGroup) => {
                control.patchValue({
                    adjustedInjectedInput: control.value.injectedInput,
                    asLeft: control.value.asFound
                })
            })
        }
    }

    public updateCalibrationResultStatus(resultStatusControl: FormControl, resultSetControl: FormArray, template: RepeatabilityTemplate): void {
        const allResultValueFilled = this.calibrationValidatorService.allInputResultHasValue(resultSetControl)

        if (allResultValueFilled) {
            const resultStatusValue = this.calculateCalibrationResult(resultSetControl, template)

            this.calibrationResultStatusService.updateCalibrationResultStatus(resultStatusControl, resultStatusValue)
        } else {
            this.calibrationResultStatusService.updateCalibrationResultStatus(resultStatusControl, null)
        }
    }

    public calculateCalibrationResult(calibrationForm: FormArray, template: RepeatabilityTemplate): CalibrationResultStatusEnum {
        let validAsFound = 0
        let validAsLeft = 0

        calibrationForm.controls.forEach((control: FormGroup) => {
            const asFoundStatus = this.validateInput(true, control, template, calibrationForm.value)
            const asLeftStatus = this.validateInput(false, control, template, calibrationForm.value)
            validAsFound += asFoundStatus === CalibrationValidationStatus.Valid ? 1 : 0
            validAsLeft += asLeftStatus === CalibrationValidationStatus.Valid ? 1 : 0
        })

        if (validAsFound === template.numberOfPoint && validAsLeft === template.numberOfPoint) {
            return CalibrationResultStatusEnum.Passed
        }

        if (validAsLeft === template.numberOfPoint) {
            return CalibrationResultStatusEnum.FailedAdjustedPassed
        }

        return CalibrationResultStatusEnum.Failed
    }

    public calculateAverageReadingInTolerance(
        isAsFound: boolean,
        calibrationForm: FormArray,
        template: RepeatabilityTemplate
    ): CalibrationValidationStatus {
        let valid = 0
        calibrationForm.controls.forEach((control: FormGroup) => {
            const status = this.validateInput(isAsFound, control, template, calibrationForm.value)
            valid += status === CalibrationValidationStatus.Valid ? 1 : 0
        })
        return valid === template.numberOfPoint ? CalibrationValidationStatus.Valid : CalibrationValidationStatus.Invalid
    }

    public allInputInTheSameColumnHasValue(isAsFound: boolean, calibrationForm: FormArray): boolean {
        for (const input of calibrationForm.controls) {
            const isAsFoundHasNoValue = isAsFound && isNotAValue(input.value.asFound)
            const isAsLeftHasNoValue = !isAsFound && isNotAValue(input.value.asLeft)
            if (isAsFoundHasNoValue || isAsLeftHasNoValue) {
                return false
            }
        }
        return true
    }

    public validateInput(
        isAsFound: boolean,
        pointControl: FormGroup,
        template: RepeatabilityTemplate,
        resultSetValue: RepeatabilityCalibrationResultRow[]
    ): CalibrationValidationStatus {
        const asFoundValue = pointControl.get('asFound').value
        const asLeftValue = pointControl.get('asLeft').value

        if ((isAsFound && isNotAValue(asFoundValue)) || (!isAsFound && isNotAValue(asLeftValue))) {
            return CalibrationValidationStatus.Initialize
        }

        let valid: boolean
        if (template.isAverageReadingUsed) {
            const expectedValue = resultSetValue[0].injectedInput
            const accuracyTolerance = this.getAccuracyTolerance(template, expectedValue)
            const repeatabilityTolerance = template.repeatabilityTolerance.value

            let value: number
            let average: number

            if (isAsFound) {
                value = asFoundValue
                average = this.getAverage(resultSetValue.map(result => result.asFound))
            } else {
                value = asLeftValue
                average = this.getAverage(resultSetValue.map(result => result.asLeft))
            }

            if (template.isAccuracyTesting) {
                valid = this.updateValidity(
                    valid,
                    this.calculateValueInTolerance(expectedValue, average, accuracyTolerance)
                )
            }

            if (template.isRepeatabilityTesting) {
                valid = this.updateValidity(
                    valid,
                    this.calculateValueInTolerance(value, average, repeatabilityTolerance)
                )
            }
        } else {
            const accuracyExpectedRange = this.calculateAccuracyOutputRange(template, resultSetValue[0].injectedInput)
            if (template.isAccuracyTesting) {
                if (isAsFound) {
                    valid = this.updateValidity(
                        valid,
                        this.rangeCalculator.calculateInTolerance(accuracyExpectedRange, asFoundValue))
                } else {
                    valid = this.updateValidity(
                        valid,
                        this.rangeCalculator.calculateInTolerance(accuracyExpectedRange, asLeftValue)
                    )
                }
            }

            if (template.isRepeatabilityTesting) {
                const repeatabilityTolerance = template.repeatabilityTolerance.value

                if (isAsFound) {
                    const minAsFound = Math.min.apply(Math, resultSetValue.map(result => result.asFound))
                    const maxAsFound = Math.max.apply(Math, resultSetValue.map(result => result.asFound))

                    valid = this.updateValidity(
                        valid,
                        this.calculateValueInTolerance(maxAsFound, minAsFound, repeatabilityTolerance)
                    )
                } else {
                    const minAsLeft = Math.min.apply(Math, resultSetValue.map(result => result.asLeft))
                    const maxAsLeft = Math.max.apply(Math, resultSetValue.map(result => result.asLeft))

                    valid = this.updateValidity(
                        valid,
                        this.calculateValueInTolerance(maxAsLeft, minAsLeft, repeatabilityTolerance)
                    )
                }
            }
        }

        return valid ? CalibrationValidationStatus.Valid : CalibrationValidationStatus.Invalid
    }

    public getAverage(values: number[]): number {
        return round(values.reduce((sum, current) => sum + current, 0) / values.length)
    }

    public updateInjectedInput(resultSetArray: FormArray): void {
        const resultSet = resultSetArray.at(0).get('resultSet') as FormArray
        const injectedValue = resultSet.at(0).get('injectedInput').value
        for (const result of resultSet.controls) {
            result.patchValue({
                injectedInput: injectedValue
            }, { emitEvent: false })
        }
    }

    private calculateAccuracyOutputRange(template: RepeatabilityTemplate, injectedValue: number): UnitRange {
        const accuracyTolerance = this.getAccuracyTolerance(template, injectedValue)
        const accuracyExpectedRange = {
            maximumRange: round(injectedValue + accuracyTolerance),
            minimumRange: round(injectedValue - accuracyTolerance),
            unitOfMeasurement: template.expected.unitOfMeasurement
        } as UnitRange

        if (template.isInjectedInputRequired) {
            accuracyExpectedRange.maximumRange = round(injectedValue + accuracyTolerance)
            accuracyExpectedRange.minimumRange = round(injectedValue - accuracyTolerance)
        }

        return accuracyExpectedRange
    }

    private updateCalibrationForm(resultSetControl: FormGroup, repeatabilityForm: FormGroup): void {
        const results = this.formBuilder.group({
            results: this.formBuilder.array([resultSetControl])
        })

        repeatabilityForm.setControl('calibrationResult', results)
    }

    private transformResultSet(
        calibrationForm: FormArray,
        originalResult: RepeatabilityCalibrationResultRow[],
        template: RepeatabilityTemplate
    ): void {
        calibrationForm.controls.forEach((control: FormGroup) => {
            const originalPoint = originalResult.find(result =>
                result.pointNumber === control.get('pointNumber').value
            ) as RepeatabilityCalibrationResultRow

            control.addControl(
                'referencingDevice',
                this.formBuilder.control(originalPoint ? originalPoint.referencingDevice : '')
            )

            if (!originalPoint) {
                return
            }

            if (isNotAValue(originalPoint?.injectedInput)) {
                originalPoint.injectedInput = template.expected.minimumRange
            }

            control.patchValue({
                injectedInput: originalPoint ? originalPoint.injectedInput : template.expected.minimumRange
            })
        })
    }

    private updateValidity(isValid: boolean, newValue: boolean): boolean {
        if (isValid || isValid === undefined) {
            isValid = newValue
        }

        return isValid
    }

    private calculateValueInTolerance(
        value1: number,
        value2: number,
        toleranceValue: number
    ): boolean {
        return Math.abs(round(value1 - value2)) <= toleranceValue
    }

    private getAccuracyTolerance(template: RepeatabilityTemplate, injectedValue: number): number {
        const accuracyPercentageTolerance = template.accuracyTolerance.unitOfMeasurement.uomCode === SpecialUomCode.Percentage
        return accuracyPercentageTolerance
            ? this.rangeCalculator.calculatePercentageToleranceToValue(injectedValue, template.accuracyTolerance.value)
            : template.accuracyTolerance.value
    }
}
