import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'

import { SpecialUomCode } from '@app/models/special-uom.model'
import * as TemplateOptions from '@app/modules/equipment/models/default-template-options-values'
import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { EquipmentTemplateDetailInput } from '@app/modules/equipment/models/equipment-template-detail-input.model'
import { Process } from '@app/modules/equipment/models/process.model'
import { TemplateTypeOption } from '@app/modules/equipment/models/template-type.model'
import { SequentialTemplateValidationsService } from '@app/modules/equipment/services/template-validations/sequential-template-validations.service'
import { TemperatureTemplateValidationService } from '@app/modules/equipment/services/template-validations/temperature-template-validations.service'
import { UOM } from '@app/modules/shared/models/engineering-units/unit-of-measurement.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import * as EquipmentTemplateAction from '@app/store/equipment/actions/equipment-template.actions'
import * as TemplateFormAction from '@app/store/equipment/actions/template-form.actions'
import { equipmentTemplateList, selectedTemplate } from '@app/store/equipment/selectors/equipment-template.selectors'
import { process } from '@app/store/equipment/selectors/process.selectors'
import { uomList } from '@app/store/equipment/selectors/uom.selectors'
import { deepCopy, findOptionWithKey } from '@app/utils/app-utils.function'
import { mapUomObjToUomDisplay } from '@app/utils/uom/uom.util'

@Component({
    selector: 'app-temperature',
    templateUrl: './temperature.component.html'
})
export class TemperatureComponent extends SafeUnsubscriberComponent implements OnInit {
    @Input() isAdmin: boolean

    public uomList$: Observable<UOM[]>
    public processes$: Observable<Process[]>
    public selectiveUOMList = [] as UOM[]
    public temperatureTemplateForm: FormGroup
    public numberOfPointOptions = TemplateOptions.numberOfPointTemperatureOptions
    public yesNoOption = TemplateOptions.yesNoOption
    public isRequiredOption = TemplateOptions.isRequiredOption
    public getOption = findOptionWithKey
    public mapUomObjToUomDisplay = mapUomObjToUomDisplay

    private selectedTemplate: TemplateTypeOption
    private templateDetails: EquipmentTemplateCoreDetails
    private percentageUOM: UOM

    constructor(
        private store: Store<AppState>,
        private formBuilder: FormBuilder,
        private sequentialValidation: SequentialTemplateValidationsService,
        private temperatureValidation: TemperatureTemplateValidationService
    ) {
        super()
    }

    ngOnInit(): void {
        this.uomList$ = this.store.select(uomList)
        this.processes$ = this.store.select(process)
        this.initPercentageUOM()
        this.watchSelectedTemplate()
        this.initTemplateData()
        this.watchMainFormControl()
        this.watchExpectedFormControl()
        this.watchInputFormControl()
    }

    private watchMainFormControl(): void {
        this.addSubscription(
            this.temperatureTemplateForm.valueChanges.subscribe(form => {
                const point = this.temperatureTemplateForm.get('numberOfPoints.point').value
                const eqCoreDetails = deepCopy(this.templateDetails) as EquipmentTemplateCoreDetails
                eqCoreDetails.detail.numberOfPoint = point['key'] as number

                this.store.dispatch(new TemplateFormAction.SelectCurrentTemplateFormsAction(form))
                this.store.dispatch(new EquipmentTemplateAction.EquipmentTemplateWasModifiedAction(
                    eqCoreDetails,
                    this.temperatureValidation.isValid(this.temperatureTemplateForm.value)
                ))
            })
        )
    }

    private watchExpectedFormControl(): void {
        this.addSubscription(
            this.temperatureTemplateForm.controls['expected'].valueChanges.subscribe(form => {
                this.updateToleranceUOM(form)
                this.temperatureTemplateForm.patchValue({
                    input: {
                        unitOfMeasurement: form.unitOfMeasurement,
                        minimumRange: form.minimumRange,
                        maximumRange: form.maximumRange
                    }
                }, { emitEvent: false })
            })
        )
    }

    private watchInputFormControl(): void {
        this.addSubscription(
            this.temperatureTemplateForm.controls['input'].valueChanges.subscribe(form => {
                this.updateToleranceUOM(form)
                this.temperatureTemplateForm.patchValue({
                    expected: {
                        unitOfMeasurement: form.unitOfMeasurement,
                        minimumRange: form.minimumRange,
                        maximumRange: form.maximumRange
                    }
                }, { emitEvent: false })
            })
        )
    }

    private initPercentageUOM(): void {
        this.addSubscription(
            this.uomList$.subscribe(uoms => {
                this.percentageUOM = uoms.find(uom => uom.uomCode === SpecialUomCode.Percentage)
            })
        )
    }

    private updateToleranceUOM(
        expectedOrInput: EquipmentTemplateDetailInput,
        tolerance: EquipmentTemplateDetailInput = null
    ): void {
        this.selectiveUOMList = this.sequentialValidation.limitUOMType(expectedOrInput, tolerance, this.percentageUOM)
        this.temperatureTemplateForm.patchValue({
            tolerance: { unitOfMeasurement: this.selectiveUOMList[0] }
        })
    }

    private initTemplateData(): void {
        this.yesNoOption = TemplateOptions.yesNoOption

        this.store.select(equipmentTemplateList).pipe(take(1)).subscribe(equipmentTemplate => {
            this.templateDetails = equipmentTemplate.find(template =>
                template.id === this.selectedTemplate.templateUniqueId
            )

            this.temperatureTemplateForm = this.formBuilder.group({
                process: {
                    id: (this.templateDetails?.processId ?? 1),
                    name: (this.templateDetails?.processName ?? 'Generic')
                },
                alias: (this.templateDetails?.detail?.alias ?? ''),
                numberOfPoints: this.formBuilder.group({
                    point: this.numberOfPointOptions.find(point =>
                        point.key === (this.templateDetails?.detail?.numberOfPoint?.toString() ?? '')
                    )
                }),
                input: this.formBuilder.group({
                    unitOfMeasurement: (this.templateDetails?.detail?.input?.unitOfMeasurement ?? null),
                    minimumRange: (this.templateDetails?.detail?.input?.minimumRange ?? 0),
                    maximumRange: (this.templateDetails?.detail?.input?.maximumRange ?? 0)
                }),
                expected: this.formBuilder.group({
                    unitOfMeasurement: (this.templateDetails?.detail?.expected?.unitOfMeasurement ?? null),
                    minimumRange: (this.templateDetails?.detail?.expected?.minimumRange ?? 0),
                    maximumRange: (this.templateDetails?.detail?.expected?.maximumRange ?? 0)
                }),
                tolerance: this.formBuilder.group({
                    unitOfMeasurement: (this.templateDetails?.detail?.tolerance?.unitOfMeasurement ?? null),
                    value: (this.templateDetails?.detail?.tolerance?.value ?? 0)
                }),
                procedure: (this.templateDetails?.detail?.procedure ?? ''),
                isInstrumentRequired: (this.templateDetails?.detail?.isInstrumentRequired ?? 'true').toString(),
                isTestEquipmentRequired: (this.templateDetails?.detail?.isTestEquipmentRequired ?? 'true').toString(),
                isChecklistRequired: 'false'
            })
            if (this.temperatureTemplateForm.value.tolerance.unitOfMeasurement !== null) {
                this.updateToleranceUOM(
                    this.temperatureTemplateForm.value.expected,
                    this.temperatureTemplateForm.value.tolerance
                )
            }

            this.store.dispatch(new TemplateFormAction.SelectCurrentTemplateFormsAction(
                this.temperatureTemplateForm.value
            ))

            if (!this.isAdmin) {
                this.temperatureTemplateForm.disable()
            }
        })
    }

    private watchSelectedTemplate(): void {
        this.addSubscription(
            this.store.select(selectedTemplate).subscribe(template => this.selectedTemplate = template)
        )
    }
}
