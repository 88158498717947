import { Directive, HostListener } from '@angular/core'

@Directive({
    selector: '[appPreventFormSubmitOnEnter]'
})
export class PreventFormSubmitOnEnterDirective {
    /**
     * Some HTML elements will not submit the form on enter
     * and need to be allowed to function properly.
     */
    private static readonly exceptionType = ['textarea']

    @HostListener('keydown.enter', ['$event'])
    enterHandler(event: any): void {
        if (PreventFormSubmitOnEnterDirective.exceptionType.findIndex(type => type === event?.target?.type) >= 0) {
            return
        }
        event?.preventDefault()
    }

}
