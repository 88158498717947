import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { MsalGuard, MsalService } from '@azure/msal-angular'
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Observable, of } from 'rxjs'
import { catchError, filter, switchMap, timeout } from 'rxjs/operators'

import { AppState } from '@app/store/app.store'
import { isOnline } from '@app/store/connection/connection.selectors'
import { environment } from '@environments/environment'

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {
    private isOnline$: Observable<boolean>

    constructor(
        private authService: MsalService,
        private msalGuard: MsalGuard,
        private router: Router,
        private store: Store<AppState>,
        private toastrService: ToastrService
    ) {
        this.isOnline$ = this.store.select(isOnline)
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        : Observable<boolean | UrlTree> {

        return this.isOnline$.pipe(
            filter(online => {
                if (!online && !(this.authService.instance.getActiveAccount())) {
                    // If we are unable to find an existing account
                    // and we can't get online to authenticate within a certain
                    // period, then redirect to '/unauthorized'
                    this.toastrService.warning('Trying to connect to the network for authentication...', 'No login info found.')
                    return false
                }
                return true
            }),
            timeout(environment.authTimeout),

            switchMap(online => {
                if (!online) {
                    // If the user's token expired, MSAL Guard will initiate a login flow
                    // and redirect the user to their login page.
                    // However, this will fail to load when the user is offline,
                    // preventing them from using the app.
                    // As they will not be calling any API while offline anyway, we want
                    // to let them continue using the app so we must return `true` here.
                    return of(true)
                }

                // If the user is online, let MSAL handle it. MSAL will use cached token first,
                // and will reach out to network only if the token expired.
                const msalCanActivate$ = this.msalGuard.canActivate(route, state)
                if (typeof msalCanActivate$ === 'boolean') {
                    return of(msalCanActivate$)
                }
                return msalCanActivate$
            }),
            catchError(err => of(this.router.parseUrl('/unauthorized')))
        )
    }
}
