import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-main-container',
    templateUrl: './main-container.component.html',
    styleUrls: ['./main-container.component.scss']
})
export class MainContainerComponent {
    @Input() height = 'unset'
    @Input() marginBottom = 'unset'
}
