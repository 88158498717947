<div class="input-section">
    <input #pikaday
           [disabled]="isDisabled"
           placeholder="{{ placeHolder }}"
           type="text"
           class="em-c-input date-picker"
           readonly />
    <svg *ngIf="!isDisabled && (!inputValue || !isClearable)"
         class="em-c-icon em-c-icon--medium em-c-field__icon em-u-clickable date-picker"
         (click)="showPicker()">
        <use xmlns:xlink="http://www.w3.org/1999/xlink"
             xlink:href="./assets/unity-1.2.0/images/24/em-icons.svg#calendar"></use>
    </svg>
    <div *ngIf="!isDisabled && isClearable && inputValue"
         class="remove-button"
         (click)="clearDate()">
        <svg class="em-c-icon em-c-icon--small">
            <use xmlns:xlink="http://www.w3.org/1999/xlink"
                 xlink:href="./assets/unity-1.2.0/images/24/em-icons.svg#icon-x"></use>
        </svg>
    </div>
</div>