export const modalMessageBody = {
    screenSizeNotSupported: {
        title: 'Screen size not supported',
        body: `
            <p>This feature is not supported for your screen size</p>
            <p>To view this page, please open on desktop or turn you tablet landscape</p>
        `
    },
    changePlant: {
        title: 'Change My Plant',
        body: `
            <p>You're about to change plant to be</p>
            <p><b>[plantCode]</b></p>
            <p>Please make sure to finish all of the ongoing works before changing plant,
            all caches and pending synchronizations will be removed.</p>
            <p>Would you like to proceed?</p>
        `
    },
    confirmDeleteAttachment: {
        title: 'Confirm Deletion',
        body: `
            <p><b>[attachmentName]</b> will be permanently deleted from the system. Would you like to proceed?</p>
        `
    },
    confirmReplaceAttachment: {
        title: 'Duplicate File',
        body: `
            <p><b>[fileName]</b> already exists for this PM. Would you like to replace it?</p>
        `
    },
    technicianCertExpired: {
        title: 'Certification date expired',
        body: `
            <p>Your technician certification date expired.</p>
            <p>Please request your supervisor to update it.</p>
        `
    },
    notATechnician: {
        title: 'Unauthorized',
        body: `
            <p>You're unauthorized to enter PM</p>
            <p>Please request your supervisor to grant you the technician role.</p>
        `
    },
    notificationClosedOutsideMRMA: {
        title: 'Notification closed',
        body: `
            <p>Notification is closed outside of MRMA application.</p>
            <p>It must be re-open before you can enter the PM result.</p>
        `
    },
    notificationClosedNoReopen: {
        title: 'Notification closed',
        body: `
            <p>Notification is closed outside of MRMA application.</p>
        `
    },
    reopenPM: {
        title: 'Re-Open PM',
        body: ``
    },
    unableToReopenPM: {
        title: 'Unable to Re-Open PM',
        body: ``
    },
    confirmAttachmentDeletion: {
        title: 'Confirm Deletion',
        body: `<p><b>[attachmentName]</b> will be permanently deleted from the system. Would you like to proceed?</p>`
    },
    confirmAttachmentReplace: {
        title: 'Duplicate File',
        body: `<p><b>[fileName]</b> already exists for this PM. Would you like to replace it?</p>`
    },
    resumePM: {
        title: 'Resume PM',
        body: `
        <p>This allows you to continue the PM. SAP Notification will automatically be re-open.</p>
        <p>Would you like to proceed?</p>
        `
    },
    overridingPMResultCompleteSelected: {
        title: 'Overriding PM Result',
        body: `
        <p>There [completeIsAre] <b>[numberOfCompleted]</b> that [completeIsAre] already completed.</p>
        <p>The previous result will be overridden.</p></br>
        <p>Would you like to proceed?</p>`
    },
    confirmRemoveBookmark: {
        title: 'REMOVE BOOKMARKS',
        body: `
        <p>You're about to remove [workOrderNumber] work order(s) from My Bookmarks.</p>
        <p>Do you want to proceed?</p>`
    },
    overridingPMResultDraftSelected: {
        title: 'Overriding PM Result',
        body: `
        <p>There [draftIsAre] <b>[numberOfDraft]</b> that [draftIsAre] in draft.</p>
        <p>Any saved data or result will be overridden.</p></br>
        <p><b>Selecting draft PMs should only be possible for data imported from GRT. If this is not the case, please do not proceed.</b><p></br>
        <p>Would you like to proceed?</p>`
    },
    overridingPMResultDraftAndCompleteSelected: {
        title: 'Overriding PM Result',
        body: `
        <p>There are <b>[numberOfCompleted]</b> that [completeIsAre] already completed and <b>[numberOfDraft]</b> that [draftIsAre] in draft.</p>
        <p>Any saved data or previous result will be overridden.</p></br>
        <p><b>Selecting draft PMs should only be possible for data imported from GRT. If this is not the case, please do not proceed.</b><p></br>
        <p>Would you like to proceed?</p>`
    },
    notificationFioriChapp: {
        title: 'Open Fiori/Chapp',
        body: `
        <p>You're about to open FIORI/CHAPP in another tab.</p>
        <p>This will allow you to modify Notification data</p></br>
        <p>Would you like to proceed?</p>`
    },
    saveEmailSubscription: {
        title: 'SAVE CHANGES',
        body: `<p>Do you want to save the changes for this subscription?</p>`
    },
    confirmReOpenNotification: {
        title: 'Notification closed',
        body: `
        <p>Notification is closed outside of MRMA application.</p>
        <p>It must be re-open before you can enter the PM result.</p>`
    },
    warningHasFlamingoLoading: {
        title: 'Flamingo Not Loaded',
        body: `
        <p>Flamingo haven't fully loaded, complete without loading will lose flamingo information.</p>
        <p>You can save the calibration and refresh page to reload flamingo form</p>`
    }
}
