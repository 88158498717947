import { Component, HostListener, Input, OnInit } from '@angular/core'
import { AbstractControl, FormArray } from '@angular/forms'

import { TemperatureTemplate } from '@app/modules/calibration/models/temperature-template.model'
import { TemperatureCalibrationService } from '@app/modules/calibration/services/temperature-calibration.service'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { ScreenSizeService } from '@app/services/screen-size.service'
import { isNotAValue } from '@app/utils/app-utils.function'

@Component({
    selector: 'app-result-set',
    templateUrl: './result-set.component.html',
    styleUrls: ['./result-set.component.scss']
})
export class ResultSetComponent extends SafeUnsubscriberComponent implements OnInit {
    @Input() template: TemperatureTemplate
    @Input() resultSetName: string
    @Input() resultSetForm: FormArray
    @Input() hasInjectedInput: boolean
    @Input() isCombinedToleranceAsFoundFailed: boolean
    @Input() isCombinedToleranceAsLeftFailed: boolean
    @Input() startingTabIndex: number
    @Input() isReport: boolean

    public maxOutOfToleranceForAsFound: number
    public maxOutOfToleranceForAsLeft: number
    public isOnMobileOrTabletDevice: boolean

    constructor(
        private temperatureCalibrationService: TemperatureCalibrationService,
        private screenSizeService: ScreenSizeService
    ) {
        super()
    }

    public get isAsLeftEntered(): boolean {
        return this.resultSetForm.controls.findIndex(control => !isNotAValue(control.get('asLeft').value)) !== -1
    }

    public get isAsFoundCompletedAndPassed(): boolean {
        const isResultCompleted = this.resultSetForm.controls.findIndex(control => isNotAValue(control.get('asFound').value)) === -1
        const isAllAsFoundPassed = this.resultSetForm.controls.findIndex(control => !this.isInTolerance(control)) === -1

        return isResultCompleted && isAllAsFoundPassed
    }

    ngOnInit(): void {
        this.isOnMobileOrTabletDevice = this.screenSizeService.isOnMobileOrTabletDevice()
        this.setMaxOutOfTolerance(this.resultSetForm)
        this.addSubscription(this.resultSetForm.valueChanges.subscribe(resultSet => {
            this.setMaxOutOfTolerance(this.resultSetForm)
        }))
    }

    @HostListener('window:resize')
    onResize(): void {
        this.isOnMobileOrTabletDevice = this.screenSizeService.isOnMobileOrTabletDevice()
    }

    public autoPopulateResult(): void {
        if (this.isAsFoundCompletedAndPassed && !this.isCombinedToleranceAsFoundFailed && !this.isAsLeftEntered) {
            this.resultSetForm.controls.map(resultRow => {
                const asFoundControl = resultRow.get('asFound')
                const asLeftControl = resultRow.get('asLeft')
                asLeftControl.setValue(asFoundControl.value)

                const asFoundInjectedInput = resultRow.get('injectedInput')
                const asLeftInjectedInput = resultRow.get('adjustedInjectedInput')
                asLeftInjectedInput.setValue(asFoundInjectedInput.value)
            })
        }
    }

    private isInTolerance(resultRow: AbstractControl): boolean {
        const expectedRange = this.hasInjectedInput ? resultRow.get('expectedAsFound').value : resultRow.get('expected').value
        const asFound = resultRow.get('asFound').value
        return expectedRange.max >= asFound && asFound >= expectedRange.min
    }

    private setMaxOutOfTolerance(resultSetForm: FormArray): void {
        this.maxOutOfToleranceForAsFound = this.temperatureCalibrationService.getMaxOutOfTolerance(resultSetForm, true)
        this.maxOutOfToleranceForAsLeft = this.temperatureCalibrationService.getMaxOutOfTolerance(resultSetForm, false)
    }
}
