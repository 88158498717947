import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Store } from '@ngrx/store'

import { NotificationService } from '@app/modules/shared/services/notification.service'
import { NotificationBasic } from '@app/modules/work-order/models/notification-basic.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { OfflineService } from '@app/services/offline.service'
import { AppState } from '@app/store/app.store'
import { GetNotificationAction } from '@app/store/work-order/work-order.actions'
import { notifications, workOrderDetails } from '@app/store/work-order/work-order.selectors'

@Component({
    selector: 'app-notification-status',
    templateUrl: './notification-status.component.html',
    styleUrls: ['./notification-status.component.scss']
})
export class NotificationStatusComponent extends SafeUnsubscriberComponent implements OnChanges {
    @Input() notificationNumber: string = null
    @Input() needTooltip = false
    @Input() notificationType: 'workOrder' | 'equipment' = 'workOrder'
    @Input() showLabel: boolean = true
    @Input() noLink: boolean = false
    @Input() addStyle: string = ""

    public isNotificationLoading: boolean
    public notificationFromStore: NotificationBasic
    public tooltipDisplayControl = new FormControl(false)

    constructor(
        private store: Store<AppState>,
        private notificationService: NotificationService,
        private offlineService: OfflineService
    ) {
        super()
    }

    public get isOnline(): boolean {
        return this.offlineService.isOnline
    }

    public get isNotificationOpen(): boolean {
        return this.notificationFromStore?.isOpen
    }

    public get canShowTooltip(): boolean {
        return !this.isNotificationLoading && this.notificationFromStore
            && this.notificationFromStore.shouldWorkOrderNotificationBeClosed && this.notificationFromStore.isOpen
    }

    public get notificationLabel(): string {
        return this.notificationType === 'workOrder' ? 'Primary Notification' : 'Equipment Notification'
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.notificationNumber) {
            this.isNotificationLoading = true

            this.addSubscriptions([
                this.store.select(notifications).subscribe((notificationInfo) => {
                    this.isNotificationLoading = !!(notificationInfo[changes.notificationNumber.currentValue]?.isLoading)
                    const unknownNotificationStatus = !this.notificationFromStore?.fioriUrl || !this.notificationFromStore?.type
                    const hasNotificationStatusChanged = this.notificationFromStore && notificationInfo && notificationInfo[changes.notificationNumber.currentValue]?.isOpen !== this.notificationFromStore?.isOpen
                    if (unknownNotificationStatus || hasNotificationStatusChanged) {
                        this.notificationFromStore = notificationInfo[changes.notificationNumber.currentValue]
                        this.initTooltip()
                    }
                })
            ])
        }
    }

    public openNotificationPopup(): void {
        if (!this.isOnline) {
            return
        }

        this.notificationService.showNotificationPopup(this.notificationFromStore.fioriUrl)
    }

    public reloadNotification(event: Event): void {
        event.stopPropagation()
        this.store.select(workOrderDetails).subscribe(details => {
            this.store.dispatch(GetNotificationAction({
                workOrderNumber: details.workOrderNumber,
                notificationNumber: this.notificationNumber
            }))
        })
    }

    private initTooltip(): void {
        if (this.needTooltip && this.canShowTooltip) {
            this.tooltipDisplayControl.setValue(true)
        }
    }
}
