import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root'
})
export class ReopenCalibrationMessageService {

    public composeMessage(lacd: Date, workOrderStatus: string): string {
        let message = `<p>This allows you to enter or update the PM.</p>` +
            `<p>SAP Notification will be automatically re-open.</p>`

        if (lacd) {
            let lacdMessage = ''
            const currentDate = new Date()
            const warngingDate = new Date(lacd)
            warngingDate.setDate(warngingDate.getDate() - 7)
            if (currentDate > warngingDate) {
                const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' }
                lacdMessage = `<br>Please note that LACD is on ${new Date(lacd).toLocaleDateString('en-US', options)}.`
            }

            if (currentDate > new Date(lacd)) {
                lacdMessage = `<br>Please note that LACD has already passed.`
            }

            message = `${message}${lacdMessage}<p>Would you like to proceed?</p>`
        }

        return message
    }
}
