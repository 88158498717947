import { modalMessageBody } from '@app/modules/modal-container/constants/modal-message.constant'
import { Modal, ModalType } from '../models/modal.model'

const notSupported: Modal = {
    title: modalMessageBody.screenSizeNotSupported.title,
    icon: 'circle-exclamation-mark',
    body: modalMessageBody.screenSizeNotSupported.body,
    closeLabel: 'Back to menu',
    warningOverlay: true,
    footer: true,
    close: true,
    type: ModalType.Generic
}

const reopenCalibration: Modal = {
    title: modalMessageBody.reopenPM.title,
    icon: 'circle-exclamation-mark',
    body: '',
    confirmLabel: 'Confirm',
    closeLabel: 'Cancel',
    warningOverlay: false,
    footer: true,
    close: true,
    type: ModalType.Generic
}

const reopenNotification: Modal = {
    title: modalMessageBody.notificationClosedOutsideMRMA.title,
    icon: 'circle-exclamation-mark',
    body: modalMessageBody.notificationClosedOutsideMRMA.body,
    confirmLabel: 'Re-open Notification',
    closeLabel: 'Cancel',
    warningOverlay: false,
    footer: true,
    close: true,
    type: ModalType.Generic
}

const confirmAttachmentDeletion: Modal = {
    title: modalMessageBody.confirmAttachmentDeletion.title,
    icon: 'circle-exclamation-mark',
    body: modalMessageBody.confirmAttachmentDeletion.body,
    confirmLabel: 'Ok',
    closeLabel: 'Cancel',
    warningOverlay: false,
    footer: true,
    close: true,
    type: ModalType.Generic
}

const confirmAttachmentReplace: Modal = {
    title: modalMessageBody.confirmAttachmentReplace.title,
    icon: 'circle-exclamation-mark',
    body: modalMessageBody.confirmAttachmentReplace.body,
    confirmLabel: 'Ok',
    closeLabel: 'Cancel',
    warningOverlay: false,
    footer: true,
    close: true,
    type: ModalType.Generic
}

const resumeCalibration: Modal = {
    title: modalMessageBody.resumePM.title,
    icon: 'circle-exclamation-mark',
    body: modalMessageBody.resumePM.body,
    confirmLabel: 'Ok',
    closeLabel: 'Cancel',
    warningOverlay: false,
    footer: true,
    close: true,
    type: ModalType.Generic
}

const changePlant: Modal = {
    title: modalMessageBody.changePlant.title,
    icon: 'circle-exclamation-mark',
    body: modalMessageBody.changePlant.body,
    confirmLabel: 'Confirm',
    closeLabel: 'Cancel',
    warningOverlay: false,
    footer: true,
    close: true,
    type: ModalType.Generic
}

const pmBlocked: Modal = {
    title: 'PM Blocked',
    icon: 'circle-exclamation-mark',
    body: '',
    closeLabel: 'Back to WO overview',
    warningOverlay: true,
    footer: true,
    close: true,
    type: ModalType.Generic
}

const overridingPMResult: Modal = {
    title: modalMessageBody.overridingPMResultCompleteSelected.title,
    icon: 'circle-exclamation-mark',
    body: modalMessageBody.overridingPMResultCompleteSelected.body,
    confirmLabel: 'Proceed',
    closeLabel: 'Cancel',
    warningOverlay: true,
    footer: true,
    close: true,
    type: ModalType.Generic
}

const notificationFioriChapp: Modal = {
    title: modalMessageBody.notificationFioriChapp.title,
    icon: 'circle-exclamation-mark',
    body: modalMessageBody.notificationFioriChapp.body,
    confirmLabel: 'Proceed',
    closeLabel: 'Cancel',
    warningOverlay: true,
    footer: true,
    close: true,
    type: ModalType.Generic
}

const flamingoNotificationModal: Modal = {
    title: 'Something has changed!',
    icon: 'circle-exclamation-mark',
    body: '',
    confirmLabel: 'Ok',
    warningOverlay: true,
    footer: true,
    close: false,
    type: ModalType.Generic
}

const workManagementEmptyFilterWarning: Modal = {
    title: 'Report filters contain empty selection',
    icon: 'circle-exclamation-mark',
    body: `This would result in an empty report. Please select "All" if you don't need a filter.`,
    confirmLabel: 'Ok',
    warningOverlay: true,
    footer: true,
    close: false,
    type: ModalType.Generic
}

const templateModifiedWarning: Modal = {
    title: 'Something has changed!',
    icon: 'circle-exclamation-mark',
    body: `
    <p>It looks like the admin has changed the template.</p>
    <p>Your previous PM data will be reset and you have to re-enter it.</p>
    <p>Please contact your site admin if you think this is incorrect.</p>`,
    confirmLabel: 'Ok',
    warningOverlay: true,
    footer: true,
    close: false,
    type: ModalType.Generic
}

const saveEmailSubscriptionWarning: Modal = {
    title: modalMessageBody.saveEmailSubscription.title,
    icon: 'circle-exclamation-mark',
    body: modalMessageBody.saveEmailSubscription.body,
    confirmLabel: 'Save',
    closeLabel: 'Cancel',
    warningOverlay: true,
    footer: true,
    close: true,
    type: ModalType.Generic
}

const confirmReOpenNotification: Modal = {
    title: modalMessageBody.confirmReOpenNotification.title,
    icon: 'circle-exclamation-mark',
    body: modalMessageBody.confirmReOpenNotification.body,
    confirmLabel: 'Re-open Notification',
    closeLabel: 'Cancel',
    warningOverlay: false,
    footer: true,
    close: true,
    type: ModalType.Generic
}

const confirmRemoveBookmark: Modal = {
    title: modalMessageBody.confirmRemoveBookmark.title,
    icon: 'circle-exclamation-mark',
    body: modalMessageBody.confirmRemoveBookmark.body,
    confirmLabel: 'Remove',
    closeLabel: 'Cancel',
    warningOverlay: true,
    footer: true,
    close: true,
    type: ModalType.Generic
}

const warningHasFlamingoLoading: Modal = {
    title: modalMessageBody.warningHasFlamingoLoading.title,
    icon: 'circle-exclamation-mark',
    body: modalMessageBody.warningHasFlamingoLoading.body,
    confirmLabel: 'Complete',
    closeLabel: 'Cancel',
    warningOverlay: false,
    footer: true,
    close: true,
    type: ModalType.Generic
}


const notATechnician = pmBlocked
const technicianCertExpired = pmBlocked
const templateNotMapped = pmBlocked

export const modalContent = {
    confirmAttachmentDeletion,
    confirmAttachmentReplace,
    technicianCertExpired,
    calibrationBlocked: pmBlocked,
    reopenNotification,
    resumeCalibration,
    reopenCalibration,
    templateNotMapped,
    notATechnician,
    notSupported,
    changePlant,
    overridingPMResult,
    notificationFioriChapp,
    flamingoNotificationModal,
    workManagementEmptyFilterWarning,
    templateModifiedWarning,
    saveEmailSubscriptionWarning,
    confirmReOpenNotification,
    confirmRemoveBookmark,
    warningHasFlamingoLoading
}
