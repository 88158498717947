import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Store } from '@ngrx/store'

import { EquipmentHistory } from '@app/modules/calibration/models/equipment-history.model'
import { SectionLoaderHelperComponent } from '@app/modules/shared/components/section-loader/section-loader-helper-component'
import { SlideUpOverlayType } from '@app/modules/shared/constants/slide-up-overlay.enum'
import { SectionLoaderEnum } from '@app/modules/shared/models/section-loader.enum'
import { SectionLoaderService } from '@app/modules/shared/services/section-loader.service'
import { AppState } from '@app/store/app.store'
import { GetEquipmentHistoryAction } from '@app/store/equipment-history/equipment-history.actions'
import { equipmentHistory } from '@app/store/equipment-history/equipment-history.selectors'
import { overlaySlider } from '@app/store/overlay/overlay.selectors'

@Component({
    selector: 'app-overlay-maintenance-history',
    templateUrl: './overlay-maintenance-history.component.html',
    styleUrls: ['./overlay-maintenance-history.component.scss']
})
export class OverlayMaintenanceHistoryComponent extends SectionLoaderHelperComponent implements OnInit {

    public overlayId = SlideUpOverlayType.MaintenanceHistory
    public equipmentHistory: EquipmentHistory
    private equipmentId: string

    constructor(
        public store: Store<AppState>,
        private route: ActivatedRoute,
        sectionLoaderService: SectionLoaderService
    ) {
        super(sectionLoaderService)
    }
    @ViewChild('equipmentHistoryRef', { static: true }) set sectionLoader(ref: ElementRef) {
        if (!this.sectionLoaderRef) {
            this.registerSectionLoader(SectionLoaderEnum.EquipmentHistory, ref)
        }
    }

    ngOnInit(): void {
        this.initEquipmentHistory()
    }

    private initEquipmentHistory(): void {
        this.addSubscriptions([
            this.route.params.subscribe(params => this.equipmentId = params['equipmentId']),
            this.store.select(equipmentHistory).subscribe(eqHistory => this.equipmentHistory = eqHistory),
            this.store.select(overlaySlider).subscribe(overlay => {
                const isNewEquipmentHistory = !this.equipmentHistory || this.equipmentHistory.equipmentId !== this.equipmentId
                const overlayIsOpen = overlay.id === this.overlayId && overlay.visibility === true
                if (isNewEquipmentHistory && overlayIsOpen) {
                    this.store.dispatch(new GetEquipmentHistoryAction(this.equipmentId))
                }
            })
        ])
    }
}
