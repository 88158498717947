import { createSelector } from '@ngrx/store'

import { CustomSearchEquipment } from '@app/modules/equipment/models/custom-search-equipment.model'
import { Equipment } from '@app/modules/equipment/models/equipment.model'
import { SapEquipment } from '@app/modules/equipment/models/sap-equipment.model'
import { equipmentList } from '@app/store/equipment/selectors/equipment-list.selectors'
import { SapEquipmentState } from '../reducers/sap-equipment.reducer'

export const selectedSAPEquipmentsState = (state): SapEquipmentState => state.equipmentState.sapEquipment

export const sapEquipmentListQuery = createSelector(
    selectedSAPEquipmentsState, sapEquipment => sapEquipment.sapEquipmentListQuery)

export const sapEquipmentList =
    createSelector(selectedSAPEquipmentsState, sapEquipment => sapEquipment.sapEquipmentList)

export const equipmentToBeAdded = createSelector(
    sapEquipmentList,
    equipmentList,
    (
        sapEquipment: SapEquipment[],
        equipment: Equipment[]
    ) => {
        if (sapEquipment && equipment) {
            return sapEquipment.map(se => {
                const searchedEquipment: CustomSearchEquipment = {
                    equipmentId: se.equipmentId,
                    equipmentTag: se.equipmentTag,
                    description: se.description,
                    functionalLocation: se.functionalLocation,
                    functionalLocationDescription: se.functionalLocationDescription,
                    location: se.location,
                    plantCode: se.plantCode,
                    abcIndicator: se.abcIndicator,
                    abcDescription: se.abcDescription,
                    isExisting: equipment.findIndex(e => e.equipmentId === se.equipmentId) !== -1
                }
                return searchedEquipment
            })
        }
    }
)
