<div class="wrapper">
    <ng-container *ngFor="let option of options">
        <div class="option">
            <label>
                <input type="radio"
                       [value]="option.value"
                       [name]="name"
                       [formControl]="innerControl"
                       (change)="radioSelectionChangedFromView()">
                <span>{{option.name}}</span>
            </label>
            <ng-container *ngIf="renderUnderChecked && option.value === innerControl.value">
                <div class="checked-content"
                     [@fadeInOut]>
                    <div class="checked-content--inner">
                        <ng-container *ngTemplateOutlet="renderUnderChecked; context: { $implicit: option }"></ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
