<div class="slide-up-overlay">
    <app-overlay-header-info [header]="header"
                             [subHeader]="category"
                             [itemId]="itemId"
                             [itemDescription]="itemDescription"
                             [overlayId]="overlayId"></app-overlay-header-info>
    <div class="body">
        <hr class="details-line long-text-line responsive-line">
        <div class="em-l-container">
            <hr class="details-line long-text-line web-line">
            <p class="long-text-info">{{ longText }}</p>
        </div>
    </div>
</div>