<section>
    <div *ngIf="title"
         class="header"
         [ngClass]="{'title-responsive': titleMobileOnly}">
        <div class="header-main">
            <label class="section-title em-u-margin-right-half">{{title}}</label>
            <ng-content select="[subtitle]"></ng-content>
        </div>
        <div class="header-content">
            <ng-content select="[header]"></ng-content>
        </div>
    </div>
    <hr *ngIf="showDetailsLine">
    <ng-content select="[body]"></ng-content>
</section>