<div class="res-element"
     [ngClass]="{
         'desktop-standalone': desktopStyle === 'standalone',
         'desktop-table-element': desktopStyle === 'table-element',
         'stack-mode': (mobileLabelPosition === 'top'),
         'fill-view-width': fillViewWidthOnMobile,
         'border-top': mobileBorders === 'top' || mobileBorders === 'top-bottom',
         'border-bottom': mobileBorders === 'bottom' || mobileBorders === 'top-bottom'
      }"
     [ngStyle]="elementStyles">
    <div class="res-element-label-wrapper"
         [ngClass]="{'hide-on-desktop': hideLabelOnDesktop}">
        <div class="res-element-label-wrapper__label"
             [ngClass]="{'label-bold': labelStyle === 'bold'}">{{ labelText }}</div>
        <span class="res-element-label-wrapper__span-label">{{ spanLabelText }}</span>
        <div class="res-element-label-wrapper__sub-label">
            {{ subLabelText }}
        </div>
    </div>
    <div class="res-element__content desktop-align--{{desktopContentAlign}}"
         [ngClass]="{'report-alignment': isReportAlignment}"
         [ngStyle]="contentStyles">
        <ng-content></ng-content>
    </div>
</div>