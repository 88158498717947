<div class="relative dropdown-customize float" [style]="customStyle">
    <input class="em-c-input"
           type="text"
           [(ngModel)]="searchText"
           (ngModelChange)="filterOptionsBySearchText($event)"
           [ngClass]="{'red-box': isError }"
           [disabled]="isDisabled"
           (focus)="isExpandDropdown = true"
           [placeholder]="placeholder"
           (input)="inputEvent.emit($event)"
    />
    <div *ngIf="filteredOptions"
         class="dropdown em-c-field__menu"
         [ngClass]="{'em-is-active': isExpandDropdown}">
        <ul class="em-c-typeahead-list">
            <li class="em-c-typeahead-list__item"
                *ngFor="let item of filteredOptions"
                [ngClass]="{'em-is-disabled': item.isExpired}"
                (click)="select(item)">
                <span *ngIf="!customizeSlot" [innerHTML]="highlightPartText(item[displayField], searchText)"></span>
                <ng-container
                    *ngTemplateOutlet="customizeSlot; context: { $implicit: item, searchText: searchText  }"></ng-container>
            </li>
        </ul>
    </div>
    <app-unity-icon size="small"
                    unitySvgName="icon-x"
                    class="remove-button"
                    *ngIf="showRemoveButton"
                    (click)="unselect()"
    ></app-unity-icon>
</div>
