import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
    ViewContainerRef
} from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { Action, MemoizedSelector, Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Subscription } from 'rxjs'
import { debounceTime, tap } from 'rxjs/operators'

import { mrmaAlertConfigs } from '@app/models/alert-configuration.model'
import { User } from '@app/models/user.model'
import { CalibrationResultStatusEnum } from '@app/modules/calibration/models/calibration-result-status.enum'
import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { Equipment } from '@app/modules/equipment/models/equipment.model'
import { TemplateTypeEnum } from '@app/modules/equipment/models/template-type.enum'
import { FlamingoAuthenticationService } from '@app/modules/shared/services/flamingo-auth.service'
import { TestEquipmentListItem } from '@app/modules/test-equipment/models/test-equipment.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { IdentityService } from '@app/services/identity.service'
import { AppState } from '@app/store/app.store'
import {
    DeleteCalibrationAttachmentAction,
    GetCalibrationAttachmentsAction,
    ReplaceCalibrationAttachmentAction,
    UploadCalibrationAttachmentAction
} from '@app/store/attachment/attachment.actions'
import { hasAttachment } from '@app/store/attachment/attachment.selectors'
import { equipmentTemplateList } from '@app/store/equipment/selectors/equipment-template.selectors'
import { GetTestEquipmentAction } from '@app/store/test-equipment/test-equipment.actions'
import { testEquipments } from '@app/store/test-equipment/test-equipment.selectors'
import { GetAllUserAction } from '@app/store/user/user.actions'
import { techniciansInCurrentPlant } from '@app/store/user/user.selectors'
import { highlightPartText, isNotAValue, safeDefaultObjGet } from '@app/utils/app-utils.function'
import { mdIsALink, mdLink } from '@app/utils/strings/markdown'
import { CalibrationFormService } from '../../../shared/services/calibration-form.service'
import { CalibrationDetails, Cylinder } from '../../models/calibration-details.model'
import { CalibrationStatusEnum } from '../../models/calibration-status.enum'
import { CalibrationFlamingoService } from '../../services/calibration-flamingo.service'
import { CalibrationLifeCycleService } from '../../services/calibration-lifecycle.service'
import { CalibrationService } from '../../services/calibration.service'
import { TemplateContainerService } from '../../services/template-container.service'
import { AbstractCalibrationTemplateComponent } from '../calibration-template/abstract-calibration-template.component'
import { CalibrationValidatorService } from '@app/modules/calibration/services/calibration-validator.service'
import { cylinderIdDuplicatedError, incompleteCylindersError } from '@app/modules/calibration/constants/calibration-error-tips'
import { getLocalDateString } from '@app/utils/date.utils'
import moment from 'moment'
import { isEmpty } from 'lodash'

@Component({
    selector: 'app-calibration-results',
    templateUrl: './calibration-results.component.html',
    styleUrls: ['./calibration-results.component.scss']
})
export class CalibrationResultsComponent extends SafeUnsubscriberComponent implements OnInit, AfterViewChecked, OnChanges, OnDestroy {

    @Input() calibration: CalibrationDetails
    @Input() calibrationForm: FormGroup
    @Input() equipment: Equipment
    @Input() equipmentDetails: Equipment
    @Input() isReport: boolean
    @Input() isATechnician: boolean
    @Output() fileUpload = new EventEmitter()

    @ViewChildren('flamingo') flamingoComponents: QueryList<ElementRef>[]
    @ViewChild('calibrationResult', { read: ViewContainerRef, static: true }) container?: ViewContainerRef

    public isDisabled = true
    public useManualModeForFinalPMResultStatusSelector = false
    public shouldHideCalibrationResults = true
    public templateType = TemplateTypeEnum

    public uncompletedCylindersError = incompleteCylindersError
    public cylinderIdDuplicatedError = cylinderIdDuplicatedError
    public getLocalDateString = getLocalDateString
    public highlightPartText = highlightPartText

    public hasAttachment: boolean
    public equipmentTemplate: EquipmentTemplateCoreDetails[]
    public showReplacementEquipment: boolean
    public showReferenceMaterial: boolean

    public hasCalibrationTemplateLoadingError = false

    // Import utility functions from model for HTML use
    public isNoTemplate = CalibrationDetails.isNoTemplate
    public datePickerMaxDate = new Date()

    /**
     * Workaround to make comment box faster.
     * Will disable the current component's auto CD and manually
     * trigger the CD with debounce while the comment box is in focus.
     */
    public commentBoxCDController = (() => {
        let valChangeSub: Subscription
        return {
            focus: () => {
                this.cdRef.detach()
                if (valChangeSub) {
                    valChangeSub.unsubscribe()
                }
                if (this.calibrationForm) {
                    valChangeSub = this.calibrationForm.get('results.comments')
                        .valueChanges.pipe(
                            debounceTime(500),
                            tap(() => {
                                this.cdRef.detectChanges()
                            })
                        ).subscribe()
                }
            },
            blur: () => {
                this.cdRef.reattach()
                if (valChangeSub) {
                    valChangeSub.unsubscribe()
                }
            }
        }
    })()

    public equipmentCylinderHistory: Cylinder[]

    private componentRef: ComponentRef<any>
    private flamingoWatchEventStarted = false

    constructor(
        private store: Store<AppState>,
        private resolver: ComponentFactoryResolver,
        private templateContainerService: TemplateContainerService,
        private calibrationLifeCycleService: CalibrationLifeCycleService,
        private toastrService: ToastrService,
        private cdRef: ChangeDetectorRef,
        private flamingoAuthenticationService: FlamingoAuthenticationService,
        private identityService: IdentityService,
        public calibrationFlamingoService: CalibrationFlamingoService,
        public calibrationFormService: CalibrationFormService,
        private formBuilder: FormBuilder,
        public calibrationValidatorService: CalibrationValidatorService,
        public calibrationService: CalibrationService
    ) {
        super()
    }

    public get isCalibrationStatusCompleted(): boolean {
        return this.calibration?.calibrationStatus?.id === CalibrationStatusEnum.Completed
    }

    public get isRepairWorkOrderRequired(): boolean {
        return this.calibration?.finalPMResultStatus?.id === CalibrationResultStatusEnum.Failed
            && this.calibration?.calibrationStatus?.id === CalibrationStatusEnum.Completed
    }

    public get invalidRepairWorkOrderNumber(): boolean {
        const repairWorkOrder = this.calibrationForm.get('overview.repairWorkOrderNumber')
        return !!repairWorkOrder?.errors?.pattern
    }

    public get testEquipmentSelector(): MemoizedSelector<any, TestEquipmentListItem[]> {
        return testEquipments
    }

    public get testEquipmentGetAction(): Action {
        return new GetTestEquipmentAction()
    }

    public get techniciansInCurrentPlantSelector(): MemoizedSelector<any, User[]> {
        return techniciansInCurrentPlant
    }

    public get userGetAction(): Action {
        return new GetAllUserAction()
    }

    public get calibrationAttachmentGetAction(): Action {
        return new GetCalibrationAttachmentsAction(
            this.calibration.id,
            this.calibration.calibrationStatus.id,
            this.calibration.templateTypeId
        )
    }

    public get isReplaceEquipmentDisplayed(): boolean {
        return !isNotAValue(this.calibrationForm.get('results.equipmentReplacedDate').value)
            || !isNotAValue(this.calibrationForm.get('results.equipmentReplacedTechnicians').value[0])
            || !isNotAValue(this.calibrationForm.get('results.replacedEquipmentSerialNumber').value)
    }

    public get isReplaceEquipmentSameAsOriginalEquipment(): boolean {
        return this.calibrationFormService.isReplaceEquipmentSameAsOriginal()
    }

    public get calibrationWithoutFlamingoForm(): boolean {
        return this.calibration.flamingoForm?.some(ff => isEmpty(ff.formSchema) && isEmpty(ff.formData))
    }

    public get flamingoFormReadOnly(): boolean {
        return this.calibrationFlamingoService.inReportPage
            || this.isCalibrationStatusCompleted
            || !this.identityService.isCurrentUserATechnician$.value
    }

    public get cylinders(): FormArray {
        return this.calibrationForm.get('results.referenceMaterials') as FormArray
    }

    public get isCalibrationEditable(): boolean {
        return this.calibration.calibrationStatus.id !== CalibrationStatusEnum.Completed && this.isATechnician
    }

    ngOnInit(): void {
        this.calibrationFlamingoService.initFlamingoData()

        this.showReferenceMaterial = this.isReport

        this.addSubscriptions([
            this.store.select(hasAttachment).subscribe(_hasAttachment => this.hasAttachment = _hasAttachment),
            this.store.select(equipmentTemplateList).subscribe(templates => this.equipmentTemplate = templates),
            this.flamingoAuthenticationService.flamingoToken$.subscribe(token => this.calibrationFlamingoService.flamingoToken = token),
            ...this.calibrationFlamingoService.flamingoSubscriptions,
            this.getEquipmentCylinderHistory()
        ])
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.calibration?.currentValue) {
            if (!this.calibrationFormService.isPMDeferredOrNotTested$.value) {
                this.createComponent(this.calibration.templateTypeId)
            } else {
                this.container?.clear()
            }

            this.addSubscription(this.getEquipmentCylinderHistory())

            this.calibrationFlamingoService.handleReferenceFlamingoFormOnSaveCompleteReopen(this.calibration)

            // 2020-09-24: Workaround due to Angular Reactive Form Bug again
            // ----------------------------------------------------------------
            const pmPerformedDateFormControl = this.calibrationForm.get('results.performedDate') as FormControl
            const isPerformedDateDisabled = pmPerformedDateFormControl.disabled
            setTimeout(() => {
                // This is a workaround to get disable()/enable() to work.
                // setTimeout() will trigger Angular Change Detection cycle upon completion
                // which may be what allow the disabled state to stick properly...
                isPerformedDateDisabled ?
                    pmPerformedDateFormControl.disable({ emitEvent: false }) :
                    pmPerformedDateFormControl.enable({ emitEvent: false })

                // 2022-03-24: Workaround due to Angular Reactive Form Bug again finding No.2
                if (!this.isCalibrationStatusCompleted) {
                    this.calibrationForm.get('results.equipmentSerialNumber').enable()
                    this.calibrationForm.get('results.modelNumber').enable()
                    this.calibrationForm.get('results.manufacturer').enable()
                }
            })
            // ----------------------------------------------------------------

        }
        this.showReplacementEquipment = this.isReplaceEquipmentDisplayed
    }

    ngOnDestroy(): void {
        if (this.componentRef) {
            this.componentRef.destroy()

            if (this.componentRef.instance instanceof AbstractCalibrationTemplateComponent) {
                this.calibrationLifeCycleService.detachHooks(this.componentRef.instance)
            }
        }

        this.calibrationFlamingoService.destroy()
    }

    ngAfterViewChecked(): void {
        // Putting in AfterViewInit sometime this.flamingoComponent is still empty
        // causing the this.calibrationFlamingoService.watchFlamingoEvent have nothing to watch.
        if (!this.flamingoWatchEventStarted && this.flamingoComponents.length > 0) {
            this.flamingoWatchEventStarted = true
            this.calibrationFlamingoService.watchFlamingoEvent(this.flamingoComponents, this.calibration)
        }
    }

    public isProcedureEditable(): boolean {
        if (!this.isCalibrationEditable) {
            this.calibrationForm.get('overview.procedureNumber').disable({ emitEvent: false })
            return false
        }

        const equipmentTemplate = this.equipmentTemplate.find(x => x.id === this.calibration.templateId)
        const templateProcedure = equipmentTemplate?.detail?.procedure
        if (this.calibration.calibrationStatus.id !== CalibrationStatusEnum.Completed && templateProcedure) {
            this.calibrationForm.get('overview.procedureNumber').disable({ emitEvent: false })
            return false
        }

        this.calibrationForm.get('overview.procedureNumber').enable({ emitEvent: false })
        return true
    }

    public isProcedureLink(): boolean {
        return CalibrationService.isProcedureLink(this.equipmentTemplate, this.calibration.templateId)
    }

    public formatProcedureLink(): string {
        let procedureLink = this.calibrationForm.get('overview.procedureNumber').value

        if (mdIsALink(procedureLink)) {
            return mdLink(procedureLink).linkText
        }

        let lastSlashIndex = procedureLink.lastIndexOf('/')
        if (lastSlashIndex + 1 === procedureLink.length) {
            lastSlashIndex = procedureLink.lastIndexOf('/', lastSlashIndex - 1)
        }
        procedureLink = procedureLink.substring(lastSlashIndex)
        return procedureLink
    }

    public extractLinkFromProcedure(): string {
        const procedureLink = this.calibrationForm.get('overview.procedureNumber').value
        if (mdIsALink(procedureLink)) {
            return mdLink(procedureLink).url
        }

        return procedureLink
    }

    public isAttachmentRequired(): boolean {
        if (this.hasAttachment) {
            return false
        }
        return this.calibration.templateTypeId === Number(TemplateTypeEnum[TemplateTypeEnum.thirdParty])
    }

    public isProcedureRequired(): boolean {
        const procedure = this.calibrationForm.get('overview.procedureNumber')
        return !!procedure?.errors?.requiredText
    }

    public isRequiredTestEQ(): boolean {
        const hasTestEquipment = this.calibrationForm.get('results.testEquipments').value[0] !== undefined
        const isRequiredTestEQ = safeDefaultObjGet(this.calibration.calibrationTemplate, 'isTestEquipmentRequired', false)
        return !hasTestEquipment && isRequiredTestEQ && !this.isReport
    }

    public isReferenceIdInvalid(formControl, index): boolean {
        return this.isFieldEmpty(formControl) || this.isReferenceIdDuplicated(formControl, index)
    }

    public isFieldEmpty(formControl): boolean {
        return !formControl.value && this.calibrationValidatorService.getHasIncompleteCylinders()
    }

    public isReferenceIdDuplicated(formControl, index): boolean {
        return formControl.value && this.calibrationValidatorService.isCylinderIdDuplicated(formControl.value, index, this.cylinders)
    }

    public deleteAttachment(attachmentId: string): void {
        this.fileUpload.emit(this.calibration)
        this.store.dispatch(
            new DeleteCalibrationAttachmentAction(this.calibration.workOrderNumber, this.calibration.equipmentId, this.calibration.id, attachmentId)
        )
    }

    public replaceAttachment(event: { attachmentId: string, file: File }): void {
        this.fileUpload.emit(this.calibration)
        this.store.dispatch(new ReplaceCalibrationAttachmentAction(this.calibration.id, event.attachmentId, event.file))
    }

    public uploadAttachment(file: File): void {
        this.fileUpload.emit(this.calibration)
        this.store.dispatch(
            new UploadCalibrationAttachmentAction(this.calibration.workOrderNumber, this.calibration.equipmentId, this.calibration.id, file)
        )
    }

    public addCylinder(): void {
        this.calibrationValidatorService.hasUncompletedCylindersForAddAction(this.cylinders)
        if (this.calibrationValidatorService.getHasIncompleteCylinders() || this.calibrationValidatorService.getIsDuplicatedReferenceId(this.cylinders)) {
            return
        }
        this.cylinders.push(this.formBuilder.group(
            {
                referenceId: '',
                content: '',
                expiryDate: ''
            }
        ))
    }

    public removeCylinder(index): void {
        this.cylinders.removeAt(index)
        this.calibrationValidatorService.validateAllCylindersCompletely(this.cylinders)
    }

    public onReferenceIdInput(event: Event, index): void {
        const referenceId = (event.target as HTMLInputElement).value?.trim()
        this.cylinders.controls[index].patchValue({ ...this.cylinders.controls[index], referenceId })
        this.calibrationValidatorService.isCylinderIdDuplicated(referenceId, index, this.cylinders)
        this.calibrationValidatorService.validateCylinderCompletely(this.cylinders.controls[index])
    }

    public applyCylinderInfo(cylinder: Cylinder, index): void {
        if (cylinder) {
            this.cylinders.controls[index].patchValue(cylinder)
        } else {
            this.cylinders.controls[index].patchValue({
                referenceId: '',
                content: '',
                expiryDate: ''
            })
        }
        this.calibrationValidatorService.isCylinderIdDuplicated(cylinder?.referenceId, index, this.cylinders)
        this.calibrationValidatorService.validateCylinderCompletely(this.cylinders.controls[index])
    }

    private getIsExpired(expiryDate): boolean {
        return moment().isAfter(expiryDate)
    }

    private getEquipmentCylinderHistory(): Subscription {
        return this.calibrationService.getEquipmentCylinderHistory(this.calibration.equipmentId).subscribe(data => {
            this.equipmentCylinderHistory = data.map(item => ({ ...item, isExpired: this.getIsExpired(item.expiryDate) }))
        })
    }

    private createComponent(templateType: TemplateTypeEnum): void {
        this.container?.clear()

        try {
            this.hasCalibrationTemplateLoadingError = false
            const templateContent = this.templateContainerService.getComponent(templateType)

            const factory = this.resolver.resolveComponentFactory(templateContent)
            this.componentRef = this.container?.createComponent(factory)
            this.componentRef.instance.calibration = this.calibration
            this.componentRef.instance.calibrationForm = this.calibrationForm
            this.componentRef.instance.equipment = this.equipment
            this.componentRef.instance.isReport = this.isReport

            if (this.componentRef.instance instanceof AbstractCalibrationTemplateComponent) {
                // Bootstrap the component's life cycle/functions to the calibrationService
                // to allow other component to trigger the life cycle/functions through it
                this.calibrationLifeCycleService.attachHooks(this.componentRef.instance)

                // Sync the template's disabled state.
                this.componentRef.instance.isDisabled$.subscribe(disabled => {
                    this.isDisabled = disabled
                })

                // If template will NOT calculate calibrationResultStatus,
                // then use manual mode.
                this.useManualModeForFinalPMResultStatusSelector =
                    !this.componentRef.instance.templateWillCalculateCalibrationResultStatus

                this.shouldHideCalibrationResults =
                    this.componentRef.instance.templateWillNotRenderCalibrationResultsCard
            }

        } catch (error) {

            // TODO: Send the user back to work order page or disable Complete/Save button if template failed to load.
            // For now, set isReport to true to prevent editing and confusion
            this.isReport = true
            this.hasCalibrationTemplateLoadingError = true

            const errorObj = (error as Error)
            this.toastrService.error(errorObj.message, '', mrmaAlertConfigs.CalibrationError.configuration)
        }
    }
}
