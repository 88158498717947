import { AfterViewInit, Component, Renderer2, ViewChild } from '@angular/core'

@Component({
    selector: 'app-hoverdown',
    templateUrl: './hoverdown.component.html',
    styleUrls: ['./hoverdown.component.scss']
})
export class HoverdownComponent implements AfterViewInit {
    @ViewChild('hoverdown') hoverdown

    constructor(private renderer: Renderer2) { }

    ngAfterViewInit(): void {
        const parentNode = this.hoverdown.nativeElement.parentNode.parentNode
        this.renderer.addClass(parentNode, 'hover-dropdown-master')
    }
}
