export class QueryParameter {
    sortBy: string
    page: string
    perPage: string
}

export class QuerySummary<T> {
    queryParameters?: T
    currentPage: number
    itemPerPage: string
    totalItemCount?: number
}

