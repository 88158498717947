<div class="em-c-field" [title]="selectedOption?.displayValue">
    <select [disabled]="disabled" class="em-c-select em-u-width-100" [(ngModel)]="selectedOption">
        <option [ngValue]="null" disabled>-- select an option --</option>
        <option *ngIf="enableSelectNone"
                [ngValue]="noValueDropdownOption">{{noValueDropdownOption.displayValue}}</option>
        <option *ngFor="let option of options"
                [classList]="getOptionStyle(option)"
                [ngValue]="option"
        >
            {{ option.displayValue}}
        </option>
    </select>
</div>
