import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-sorting-caret',
    templateUrl: './sorting-caret.component.html',
    styleUrls: ['./sorting-caret.component.scss']
})

export class SortingCaretComponent {
    @Input() isDescending: boolean
    @Input() isSorted: boolean
    @Input() iconSize: 'tiny' | 'small' = 'tiny'
}
