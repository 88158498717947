<app-section *ngIf="allCount > 1"
             title="PM Summary">
    <div body
         class="responsive-calibration-container">
        <div class="calibration-info">
            <label>Status</label>
            <p>{{completeCountLabel}}</p>
        </div>
        <div class="calibration-info">
            <label>Failed</label>
            <p>{{failedCount}}</p>
        </div>
        <div class="calibration-info">
            <label>Failed-Adjusted-Passed</label>
            <p>{{failedAdjustedCount}}</p>
        </div>
        <div class="calibration-info">
            <label>Deferred</label>
            <p>{{deferredCount}}</p>
        </div>
        <div class="calibration-info">
            <label>Not Tested</label>
            <p>{{notTestedCount}}</p>
        </div>
        <div class="calibration-info">
            <label>Passed</label>
            <p>{{passedCount}}</p>
        </div>
        <div class="calibration-info">
            <label>Passed-Adjusted-Passed</label>
            <p>{{passedAdjustedPassedCount}}</p>
        </div>
    </div>
</app-section>
