import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
    selector: 'app-star-check',
    templateUrl: './star-check.component.html',
    styleUrls: ['./star-check.component.scss']
})
export class StarCheckComponent {
    @Input() checked: boolean
    @Input() disabled: boolean
    @Output() check = new EventEmitter()

    toggleCheck(event: Event): void {
        if (!this.disabled) {
            this.check.emit(event)
        }
    }
}
