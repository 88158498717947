<div [ngClass]="{'loading-height': isSectionLoading()}"
     #listReference>
    <table class="em-c-table em-u-margin-bottom-none table">
        <thead class="em-c-table__header">
            <tr class="em-c-table__header-row">
                <ng-container *ngTemplateOutlet="header"></ng-container>
            </tr>
        </thead>
        <tbody class="em-c-table__body">
            <ng-container *ngIf="!displayNoRecord">
                <tr class="em-c-table__row em-u-margin-bottom-none"
                    [ngClass]="{'non-clickable': !clickable,
                            'em-u-clickable': clickable}"
                    *ngFor="let item of items;let i= index"
                    (click)="itemClicked(item)">
                    <ng-container *ngTemplateOutlet="content;context: { $implicit: item, index: i }"></ng-container>
                </tr>
            </ng-container>
            <tr *ngIf="!items || items.length <= 0 || displayNoRecord">
                <td class="em-c-table__cell em-u-text-align-center"
                    colspan="10">
                    No record found
                </td>
            </tr>
        </tbody>
        <tfoot class="em-c-table__footer">
            <tr class="em-c-table__footer-row"></tr>
        </tfoot>
    </table>

    <div class="tiles em-u-margin-top">
        <ul class="em-c-card-list  em-l-grid em-l-grid--2up" *ngIf="!displayNoRecord">
            <li class="em-c-card-list__item em-l-grid__item"
                [ngClass]="{'non-clickable': !clickable, 'em-u-clickable': clickable}"
                *ngFor="let item of items">
                <div (click)="itemClicked(item)">
                    <ng-container *ngTemplateOutlet="content;context: { $implicit: item }"></ng-container>
                </div>
            </li>
        </ul>
    </div>
</div>
