import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-res-section-header',
    styleUrls: ['./res-section-header.component.scss'],

    template: `
        <div class="res-section-header"
            [ngClass]="{
                'hide-on-desktop': hideOnDesktop,
                'fill-parent': widthMode === 'fill-parent',
                'fill-view': widthMode === 'fill-view'
            }"
        >
            {{ headerTitle }}
        </div>
        <ng-content></ng-content>
    `

})
export class ResSectionHeaderComponent {

    @Input() headerTitle: string

    @Input() hideOnDesktop = true
    /**
     * `fill-view` will extend the section across the entire view port.
     * **Only render correctly if the section is centered in the view port.**
     * Useful if the parent has a padding that you want the section to ignore.
     *
     * `fill-parent` will fit the section to the parent's width.
     */
    @Input() widthMode: 'fill-view' | 'fill-parent' = 'fill-view'

}
