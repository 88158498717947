import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core'

@Component({
    selector: 'app-expandable-label',
    templateUrl: './expandable-label.component.html',
    styleUrls: ['./expandable-label.component.scss']
})
export class ExpandableLabelComponent implements OnInit {
    @Input() value: string
    @ViewChild('content', { static: true }) content: ElementRef

    public collapsed = true
    public overflows = false

    constructor(private element: ElementRef) { }

    ngOnInit(): void {
        setTimeout(() => {
            this.overflows = this.element.nativeElement.childNodes[0].offsetWidth < this.content.nativeElement.scrollWidth
        })
    }

    public toggleText(): void {
        this.collapsed = !this.collapsed
    }
}
