import { Component, Injector, OnInit } from '@angular/core'
import { FormArray, FormControl } from '@angular/forms'

import { CalibrationResultStatusEnum } from '@app/modules/calibration/models/calibration-result-status.enum'
import { TemperatureResultSetEnum } from '@app/modules/calibration/models/temperature-result-set.enum'
import { TemperatureTemplate } from '@app/modules/calibration/models/temperature-template.model'
import { CalibrationInitializerService } from '@app/modules/calibration/services/calibration-initializer.service'
import { CalibrationResultStatusService } from '@app/modules/calibration/services/calibration-result-status.service'
import { RangeCalculationService } from '@app/modules/calibration/services/range-calculation.service'
import { TemperatureCalibrationService } from '@app/modules/calibration/services/temperature-calibration.service'
import { AbstractCalibrationTemplateComponent } from '../abstract-calibration-template.component'

@Component({
    selector: 'app-temperature',
    templateUrl: './temperature.component.html',
    styleUrls: ['./temperature.component.scss']
})
export class TemperatureComponent extends AbstractCalibrationTemplateComponent implements OnInit {

    public template: TemperatureTemplate
    public isAsFoundFailed: boolean
    public isAsLeftFailed: boolean

    constructor(
        private temperatureCalibrationService: TemperatureCalibrationService,
        private rangeCalculationService: RangeCalculationService,
        private calibrationResultStatusService: CalibrationResultStatusService,
        private calibrationInitializerService: CalibrationInitializerService,
        injector: Injector
    ) {
        super(injector)
    }

    public get calibrationResultForm(): FormControl {
        return this.calibrationForm.get('results.calibrationResult') as FormControl
    }

    public get instrumentResultSetForm(): FormArray {
        return (this.calibrationResultForm.get('results') as FormArray).controls
            .find(control => control.get('resultSetName').value === TemperatureResultSetEnum.instrument)
            .get('resultSet') as FormArray
    }

    public get sensingElementResultSetForm(): FormArray {
        return (this.calibrationResultForm.get('results') as FormArray).controls
            .find(control => control.get('resultSetName').value === TemperatureResultSetEnum.sensingElement)
            .get('resultSet') as FormArray
    }

    ngOnInit(): void {
        super.ngOnInit()

        this.template = this.calibration.calibrationTemplate as TemperatureTemplate
        this.temperatureCalibrationService.initialize(this.calibrationForm, this.calibration, this.template)
        if (this.template.isInstrumentRequired) {
            this.temperatureCalibrationService.fullResultSet(this.instrumentResultSetForm, this.template, false)
        }
        this.temperatureCalibrationService.fullResultSet(this.sensingElementResultSetForm, this.template, true)
        this.setResponsiveTemplateData()
        this._updateCalibrationResultStatus(this.calibrationResultForm)
        this.addSubscription(this.calibrationResultForm.valueChanges.subscribe(() => {
            this._updateCalibrationResultStatus(this.calibrationResultForm)
        }))
    }

    protected updateCalibrationResultStatus(): void {
        this._updateCalibrationResultStatus(this.calibrationResultForm)
    }

    private setResponsiveTemplateData(): void {
        if (this.isOnMobileOrTabletDevice) {
            this.calibrationInitializerService.updateMobileViewInjectAndAdjustedInput(this.calibrationForm)
        }
    }

    private _updateCalibrationResultStatus(resultSets: FormControl): void {
        const combinedOutOfTelorance = this.temperatureCalibrationService.calculateCombineOutOfTolerance(resultSets)
        const tolerance = this.rangeCalculationService.calculateTolerance(this.template.tolerance, this.template.expected)
        this.isAsFoundFailed = combinedOutOfTelorance.asFound > tolerance
        this.isAsLeftFailed = combinedOutOfTelorance.asLeft > tolerance

        const resultStatusForm = this.calibrationForm.get('results.calibrationResultStatus') as FormControl

        if (this.calibrationResultForm.valid || this.calibrationResultForm.disabled) {
            if (combinedOutOfTelorance.asLeft > tolerance) {
                this.calibrationResultStatusService.updateCalibrationResultStatus(resultStatusForm, CalibrationResultStatusEnum.Failed)
            } else if (combinedOutOfTelorance.asFound > tolerance) {
                this.calibrationResultStatusService.updateCalibrationResultStatus(resultStatusForm, CalibrationResultStatusEnum.FailedAdjustedPassed)
            } else {
                this.calibrationResultStatusService.updateCalibrationResultStatus(resultStatusForm, CalibrationResultStatusEnum.Passed)
            }
        } else {
            this.calibrationResultStatusService.updateCalibrationResultStatus(resultStatusForm, null)
        }
    }
}
