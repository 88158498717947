import { Injectable } from '@angular/core'
import { CanDeactivate } from '@angular/router'
import { Observable } from 'rxjs'

export interface NavAwayInterceptorGuardDataSource {
    canDeactivate: () => boolean | Observable<boolean>
}

/**
 * **Note: This does not work if user try to navigate OUTSIDE of Angular app (change website, close tab).
 * Those need to be handled with `@HostListener` inside the component itself.
 */
@Injectable({
    providedIn: 'root'
})
export class NavAwayInterceptorGuard implements CanDeactivate<NavAwayInterceptorGuardDataSource> {
    canDeactivate(source: NavAwayInterceptorGuardDataSource): boolean | Observable<boolean> {
        return source.canDeactivate()
    }
}
