export abstract class Status {
    status: string
    statusName: string
    className: string
}

export const equipmentCalibrationResultStatus = [
    {
        status: null,
        statusName: '',
        className: ''
    },
    {
        status: 'Passed',
        statusName: 'Passed',
        className: 'success'
    },
    {
        status: 'Failed Adjusted Passed',
        statusName: 'Failed Adjusted Passed',
        className: 'success'
    },
    {
        status: 'Failed',
        statusName: 'Failed',
        className: 'failed'
    }
] as Status[]

export const equipmentCalibrationRequiredAction = [
    {
        status: 'repair',
        statusName: 'Repair work order required',
        className: 'action-require'
    },
    {
        status: 'attachment',
        statusName: 'Attachment required',
        className: 'action-require'
    },
] as Status[]

export const equipmentCalibrationStatus = [
    {
        status: null,
        statusName: '',
        className: ''
    },
    {
        status: 'draft',
        statusName: 'Draft',
        className: 'label label-warning'
    },
    {
        status: 'completed',
        statusName: 'Completed',
        className: 'label label-success'
    },
] as Status[]

export const equipmentConsolidatedStatus = [
    {
        status: null,
        statusName: '',
        className: ''
    },
    {
        status: 'Draft',
        statusName: '',
        className: 'action-require'
    },
    {
        status: 'Passed',
        statusName: 'Passed',
        className: 'success'
    },
    {
        status: 'Failed Adjusted Passed',
        statusName: 'Failed Adjusted Passed',
        className: 'warning'
    },
    {
        status: 'Failed',
        statusName: 'Failed',
        className: 'failed'
    },
    {
        status: 'Deferred',
        statusName: 'Deferred',
        className: 'failed'
    },
    {
        status: 'Not Tested',
        statusName: 'Not Tested',
        className: 'failed'
    }
] as Status[]

export const consolidatedStatusPrioritization = [
    [1, 'Draft'],
    [2, 'Passed'],
    [3, 'Failed Adjusted Passed'],
    [4, 'Failed'],
    [5, 'Deferred'],
    [6, 'Not Tested']
] as any[]
