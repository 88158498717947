<div class="responsive-lg options-container">
    <div *ngFor="let option of radioOptions"
         class="em-u-padding-half option-row"
         [ngClass]="{'em-u-clickable': !isDisabled}"
         (click)="optionClicked(option)">

        <div class="option-row__values">
            <svg class="em-c-icon em-c-icon--medium"
                 [ngStyle]="(customColor && !useBlack && (option.id === value?.id))? { color: customColor, fill: customColor }: {}"
                 [ngClass]="{
                    'em-c-icon--blue': !useBlack && option.id === value?.id,
                    'em-c-icon--black': useBlack && option.id === value?.id,
                    'em-c-icon--gray': option.id !== value?.id
                }">
                <use *ngIf="option?.id === value?.id"
                     xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="./assets/images/mrma-icons.svg#circle-checked"
                     [attr.data-testid]="'selectedCalibrationResult-' + option.id"></use>
                <use *ngIf="option?.id !== value?.id"
                     xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="./assets/images/mrma-icons.svg#circle-grey"></use>
            </svg>
        </div>
        <div class="option-row__details"
             [ngStyle]="(customColor && !useBlack && (option.id === value?.id))? { color: customColor, fill: customColor }: {}"
             [ngClass]="{
                'em-c-icon--blue': !useBlack && option.id === value?.id,
                'is-not-selected': value && option.id !== value?.id
            }">
            {{ option.name }}
        </div>

    </div>
</div>
<div class="responsive-sm">
    <div class="responsive-calibration-field-container">
        <div class="responsive-checklist"
             *ngFor="let option of radioOptions"
             (click)="optionClicked(option)">
            <svg class="em-c-icon em-c-icon--medium"
                 [ngStyle]="(customColor && !useBlack && (option.id === value?.id))? { color: customColor, fill: customColor }: {}"
                 [ngClass]="{
                'em-c-icon--blue': !useBlack && option.id === value?.id,
                'em-c-icon--black': useBlack && option.id === value?.id,
                'em-c-icon--gray': option.id !== value?.id
            }">
                <use *ngIf="option?.id === value?.id"
                     xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="./assets/images/mrma-icons.svg#circle-checked"></use>
                <use *ngIf="option?.id !== value?.id"
                     xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="./assets/images/mrma-icons.svg#circle-grey"></use>
            </svg>
            <span class="em-u-padding-left"
                  [ngStyle]="(customColor && !useBlack && (option.id === value?.id))? { color: customColor, fill: customColor }: {}"
                  [ngClass]="{
                    'em-c-icon--blue': !useBlack && option.id === value?.id,
                    'is-not-selected': value && option.id !== value?.id
                    }">{{ option.name }}</span>
        </div>
    </div>
</div>