<div class="responsive-lg">
    <div class="em-u-padding-half custom-flex">
        <div class="custom-flex__values">
            <div class="custom-flex-item__value"
                 *ngFor="let option of radioOptions; let i = index">
                <svg class="em-c-icon em-c-icon--medium radio-icon"
                     [ngClass]="{
                        'em-c-icon--blue': !useBlack && i === currentIndex,
                        'em-c-icon--black': useBlack && i === currentIndex,
                        'em-c-icon--gray': i !== currentIndex,
                        'em-u-clickable': !isDisabled
                    }"
                     (click)="optionClicked(i)">

                    <use xmlns:xlink="http://www.w3.org/1999/xlink"
                         [attr.xlink:href]="option.icon"></use>
                </svg>
            </div>
        </div>
        <div class="custom-flex__question">
            {{ question }}
        </div>
    </div>
</div>
<div class="responsive-sm">
    <div class="responsive-questionaire">
        <span>
            {{ question }}
        </span>
        <div class="answers-list">
            <div class="answer"
                 *ngFor="let option of radioOptions; let i = index"
                 (click)="optionClicked(i)">
                <label>
                    <svg class="em-c-icon em-c-icon--medium"
                         [ngClass]="{
                        'em-c-icon--blue': !useBlack && i === currentIndex,
                        'em-c-icon--black': useBlack && i === currentIndex,
                        'em-c-icon--gray': i !== currentIndex,
                        'em-u-clickable': !isDisabled
                    }">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink"
                             [attr.xlink:href]="option.icon"></use>
                    </svg>
                    {{ option.optionText }}
                </label>
            </div>
        </div>
    </div>
</div>