<section>
    <h1 class="responsive-view">{{title}}</h1>

    <div class="report-filter-row">
        <span>You're about to delete <span class="filter-name">{{presetName}}</span>.</span>
        <span>This cannot be undone. Do you want to proceed?</span>
    </div>
    <div class="action-buttons">
        <button (click)="delete()"
                class="em-c-btn em-c-btn--primary">
            Delete
        </button>
        <button (click)="close()"
                class="em-c-btn">
            Cancel
        </button>
    </div>
</section>