import { AfterViewInit, Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, TemplateRef } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, of } from 'rxjs'

import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { APIPagedListUIStateService } from '../../services/api-paged-list-ui-state.service'
import { APIPagedListService } from '../../services/api-paged-list.service'

@Component({
    selector: 'app-api-paged-list',
    templateUrl: './api-paged-list.component.html',
    styleUrls: ['./api-paged-list.component.scss']
})
export class APIPagedListComponent extends SafeUnsubscriberComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() noRecordDescription = ''

    @Input() currentOffset = 0
    @Input() pageSize = 40
    @Input() numberOfItems = -1

    @Input() pagedListDataSource: APIPagedListDataSource

    @Input() isDisabled = false
    @Input() clickable = true

    @Output() clickItem = new EventEmitter<any>()

    @ContentChild('header') header: TemplateRef<any>
    @ContentChild('content') content: TemplateRef<any>

    public currentPage$: Observable<any[]> = of([])
    public isLoading$: Observable<boolean>

    // Use to identify the component for state storing
    private currentRoute = ''

    constructor(
        @Optional() private apiPagedListService: APIPagedListService,
        private apiPagedListUIStateService: APIPagedListUIStateService,
        private router: Router
    ) {
        super()
        this.addSubscriptions([
            this.apiPagedListService?.refreshList$.subscribe(({ resetPosition }) => {
                this.refreshList(resetPosition)
            })
        ])

        this.currentRoute = this.router.url.split('?')[0]
        this.isLoading$ = this.apiPagedListService?.isRequestInProgress$
    }

    public get isFirstPage(): boolean {
        return this.currentOffset === 0
    }

    public get isLastPage(): boolean {
        return (this.currentOffset + this.pageSize) >= this.numberOfItems
    }

    public get showingItemFrom(): number {
        return this.currentOffset + 1
    }
    public get showingItemTo(): number {
        const end = this.currentOffset + this.pageSize
        return Math.min(end, this.numberOfItems)
    }

    ngOnInit(): void {
        this.restoreState()
        this.refreshList()
    }

    ngAfterViewInit(): void {
        this.restoreScroll()
    }

    ngOnDestroy(): void {
        this.saveState()
    }

    public nextPage(): void {
        if (this.isLastPage || this.isDisabled) {
            return
        }
        this.currentOffset += this.pageSize
        this.refreshList()
    }

    public previousPage(): void {
        if (this.isFirstPage || this.isDisabled) {
            return
        }
        this.currentOffset -= this.pageSize
        this.refreshList()
    }

    public itemClicked(item: any): void {
        if (this.clickable) {
            this.clickItem.next(item)
        }
    }

    private refreshList(resetPosition = false): void {
        if (resetPosition) {
            this.currentOffset = 0
        }
        this.currentPage$ = this.pagedListDataSource(this.currentOffset, this.pageSize)
    }

    private saveState(): void {
        const state = {
            currentOffset: this.currentOffset,
            numberOfItems: this.numberOfItems,
            scrollPositionY: window.pageYOffset
        }
        this.apiPagedListUIStateService.setState(this.currentRoute, state)
    }

    private restoreState(): void {
        const state = this.apiPagedListUIStateService.getState(this.currentRoute)
        if (!state) {
            return
        }
        this.currentOffset = state.currentOffset
        this.numberOfItems = state.numberOfItems
    }

    // Must be done in AfterViewInit
    private restoreScroll(): void {
        const state = this.apiPagedListUIStateService.getState(this.currentRoute)
        if (!state) {
            return
        }
        window.scrollTo(0, state.scrollPositionY)
    }
}

export type APIPagedListDataSource = (offset, pageSize) => Observable<any[]>
