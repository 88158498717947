import { Component, ComponentFactoryResolver, ComponentRef, Input, OnDestroy, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core'
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms'

import { CustomFormElementEnum } from '../models/custom-form-element.enum'
import { CustomFormResultValue } from '../models/custom-form-result.model'
import { CustomFormElement } from '../models/custom-form.model'
import { CustomFormElementService } from '../services/custom-form-element.service'

@Component({
    selector: 'app-custom-form-section',
    templateUrl: './custom-form-section.component.html',
})
export class CustomFormSectionComponent implements OnInit, OnDestroy {
    @Input() formTemplate: CustomFormElement[]
    @Input() formResult: CustomFormResultValue[]
    @Input() formControls: FormArray
    @Input() isReport: boolean

    @ViewChild('elements', { static: true, read: ViewContainerRef }) container: ViewContainerRef

    private componentRef: ComponentRef<any>

    constructor(
        private resolver: ComponentFactoryResolver,
        private formBuilder: FormBuilder,
        private customFormService: CustomFormElementService,
        private renderer: Renderer2
    ) { }

    ngOnInit(): void {
        this.buildFormElements()
    }

    ngOnDestroy(): void {
        this.componentRef.destroy()
    }

    private buildFormElements(): void {
        this.formTemplate.forEach(element => this.addElement(element))
    }

    private addElement(element: CustomFormElement): void {
        const type = CustomFormElementEnum[element.type]
        const elComponent = this.customFormService.getComponent(type)
        const factory = this.resolver.resolveComponentFactory(elComponent)
        const column = element.column || ''

        this.componentRef = this.container.createComponent(factory)
        this.componentRef.instance.formDetail = element
        this.componentRef.instance.formControl = this.addFormControlResult(element)
        this.componentRef.instance.isReport = this.isReport
        this.renderer.setStyle(this.componentRef.location.nativeElement, 'grid-column', column)
    }

    private addFormControlResult(element: CustomFormElement): FormControl {
        const form = this.formResult.find(x => x.id === element.id)
        const validator = element?.required ? Validators.required : undefined
        const formControl = this.formBuilder.group({
            id: element.id,
            value: [form?.value, validator]
        })

        if (this.formControls.disabled) {
            formControl.disable()
        }

        this.formControls.push(formControl)
        return formControl.get('value') as FormControl
    }
}
