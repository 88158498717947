import { Component, Input } from '@angular/core'
import { FormControl } from '@angular/forms'

@Component({
    selector: 'app-input-form',
    templateUrl: './input-form.component.html',
    styleUrls: ['./input-form.component.scss']
})
export class InputFormComponent {
    @Input() control: FormControl
    @Input() placeholder = ''
}
