import { Component, EventEmitter, Input, Output } from '@angular/core'

import { PaginationSize } from '../../constants/pagination-size.enum'
import { QueryParameter, QuerySummary } from '../../models/query-summary.model'
import { PaginationService } from '../../services/pagination.service'

@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.component.html'
})

export class DataTableComponent {
    @Input() querySummary: QuerySummary<QueryParameter>
    @Input() perPageEnable: boolean
    @Output() queryChange: EventEmitter<QueryParameter>

    public paginationSize: string[]

    constructor(private paginationService: PaginationService) {
        this.queryChange = new EventEmitter<QueryParameter>()
        this.paginationSize = Object.keys(PaginationSize).map(key => PaginationSize[key])
    }

    public changePageSize(pageSize: string): void {
        const queryParameters = { ...this.querySummary.queryParameters }
        queryParameters.perPage = pageSize
        this.queryChange.next(queryParameters)
    }

    public goToPage(pageNumber: string): void {
        const totalPage = this.paginationService.getTotalPage(this.querySummary)
        if (totalPage > 1 && Number(pageNumber) > 0 && Number(pageNumber) <= totalPage) {
            const queryParameters = { ...this.querySummary.queryParameters }
            queryParameters.page = pageNumber
            this.queryChange.next(queryParameters)
        }
    }
}
