<div
    class="em-c-field"
    [ngClass]="{ 'em-is-disabled': form.get(formControlPath).disabled }"
    [formGroup]="form"
    *ngIf="form"
>
    <label
        for="file"
        class="em-c-field__label em-u-font-size-small-2"
        [innerHTML]="title"
    ></label>
    <div class="em-c-field__body">
        <input
            type="text"
            class="em-c-input em-u-font-style-regular"
            [formControlName]="formControlPath"
        />
    </div>
</div>