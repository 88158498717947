<div class="mrma-container"
     [ngClass]="{'loading': isLoading}">
    <app-offline-upload-status-queue></app-offline-upload-status-queue>
    <app-header></app-header>
    <section class="mrma__flex-wrapper">
        <div class="spinner"
             *ngIf="isLoading">
            <div class="spinner-inner">
                <img src="./assets/unity-1.2.0/images/icon-spinner.svg"
                     alt="Loading" />
            </div>
        </div>
        <main role="main">
            <router-outlet></router-outlet>
        </main>
    </section>
</div>
