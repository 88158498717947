<app-main-container>
    <div class="download-card" *ngIf="isFileNotFound">
        <h1>Your report is not found. It is probably expired.</h1>
        <p>To generate a new report, click report section on the top right.</p>
    </div>
    <div class="download-card" *ngIf="!isFileNotFound">
        <h1>Your report is ready to download.</h1>
        <p>Click the button below to start download.</p>
        <button class="em-c-btn em-c-btn--primary"
                *ngIf="!isDownloadInProgress"
                (click)="downloadFileClick()">Download</button>

        <div class="progress-tracker"
             *ngIf="isDownloadInProgress && downloadProgress < 100">
            <p class="warning-message">Please do not navigate away from this page until the download is completed.</p>
            <div class="progress-bar">
                <div class="progress-bar--bar"
                     *appLet="downloadProgress$ | async as progress"
                     [ngStyle]="{ 'width.%': progress }"></div>
            </div>
            <div class="progress-bar--text">{{downloadProgress >= 0 ? downloadProgress + "%" : 'Download starting...'}}</div>
        </div>
        <div *ngIf="downloadProgress === 100">Download Completed</div>
    </div>
</app-main-container>
