import {
    AfterContentInit, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges, ViewChild
} from '@angular/core'
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { get } from 'lodash'
import { ToastrService } from 'ngx-toastr'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

import { mrmaAlertConfigs } from '@app/models/alert-configuration.model'
import {
    calibrationTemplateSelectionModalObject
} from '@app/modules/calibration/components/modals/calibration-template-selection/calibration-template-selection.modal-object'
import { DeferredNotTestedComponent } from '@app/modules/calibration/components/modals/deferred-not-tested/deferred-not-tested.component'
import { deferredOrNotTestedModalObject } from '@app/modules/calibration/components/modals/deferred-not-tested/deferred-not-tested.modal-object'
import { cylinderIdDuplicatedError, incompleteCylindersError } from '@app/modules/calibration/constants/calibration-error-tips'
import { CalibrationDetails } from '@app/modules/calibration/models/calibration-details.model'
import { CalibrationResultStatusEnum } from '@app/modules/calibration/models/calibration-result-status.enum'
import { CalibrationResultStatus } from '@app/modules/calibration/models/calibration-result-status.model'
import { CalibrationStatusEnum } from '@app/modules/calibration/models/calibration-status.enum'
import { CalibrationStatus, CalibrationStatusWithPreviousState } from '@app/modules/calibration/models/calibration-status.model'
import { CalibrationChecklist } from '@app/modules/calibration/models/checklist.model'
import { CalibrationAlertService } from '@app/modules/calibration/services/calibration-alert.service'
import { CalibrationFlamingoService } from '@app/modules/calibration/services/calibration-flamingo.service'
import { CalibrationFormComponentService } from '@app/modules/calibration/services/calibration-form-component.service'
import { CalibrationLifeCycleService } from '@app/modules/calibration/services/calibration-lifecycle.service'
import { CalibrationStatusService } from '@app/modules/calibration/services/calibration-status.service'
import { CalibrationValidatorService } from '@app/modules/calibration/services/calibration-validator.service'
import { CalibrationService } from '@app/modules/calibration/services/calibration.service'
import { ReopenCalibrationService } from '@app/modules/calibration/services/reopen-calibration.service'
import { ResumeCalibrationService } from '@app/modules/calibration/services/resume-calibration.service'
import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { Equipment } from '@app/modules/equipment/models/equipment.model'
import { TemplateTypeEnum } from '@app/modules/equipment/models/template-type.enum'
import { modalContent } from '@app/modules/modal-container/constants/modal-message-content.constant'
import { ModalType } from '@app/modules/modal-container/models/modal.model'
import { ModalContainerService } from '@app/modules/modal-container/services/modal-container.service'
import { remindMessage } from '@app/modules/shared/constants/remind-message.constant'
import { SlideUpOverlayType } from '@app/modules/shared/constants/slide-up-overlay.enum'
import { SelectionDrawerName, SelectionDrawerOption } from '@app/modules/shared/models/selection-drawer.model'
import { CalibrationFormService } from '@app/modules/shared/services/calibration-form.service'
import { EquipmentCalibration } from '@app/modules/work-order/models/equipment-calibration.model'
import { WorkOrderDetails } from '@app/modules/work-order/models/work-order-details.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppService } from '@app/services/app.service'
import { OfflineService } from '@app/services/offline.service'
import { AppState } from '@app/store/app.store'
import { reopenNotification } from '@app/store/calibration/calibration.selectors'
import { equipmentTemplateList } from '@app/store/equipment/selectors/equipment-template.selectors'
import { DestroyModalAction, ShowModalAction } from '@app/store/modal/modal.actions'
import { SlideOverlayAction } from '@app/store/overlay/overlay.actions'
import { ToggleSelectionDrawerAction } from '@app/store/selection-drawer/selection-drawer.actions'
import { notifications } from '@app/store/work-order/work-order.selectors'
import { deepCopy, isNotAValue, safeDefaultObjGet } from '@app/utils/app-utils.function'

@Component({
    selector: 'app-calibration-form',
    templateUrl: './calibration-form.component.html',
    styleUrls: ['./calibration-form.component.scss']
})
export class CalibrationFormComponent extends SafeUnsubscriberComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit {

    @ViewChild('tabbar', { static: true }) tabBar: ElementRef

    @Input() calibration: CalibrationDetails
    @Input() equipment: EquipmentCalibration
    @Input() workOrderDetails: WorkOrderDetails
    @Input() equipmentDetails: Equipment
    @Input() isReport: boolean
    @Input() reopenCalibrationEnabled = false
    @Input() isATechnician = true

    public calibrationForm: FormGroup
    public moreDrawerName = SelectionDrawerName.CalibrationMoreOption
    public moreInfoName = SelectionDrawerName.CalibrationInfoOption
    public submitDrawerName = SelectionDrawerName.CalibrationSubmitOption
    public moreDrawerInfoOptions: SelectionDrawerOption[]
    public moreDrawerOptions: SelectionDrawerOption[] = []
    public submitDrawerOptions: SelectionDrawerOption[] = []
    public remindMessage = remindMessage
    public calibrationGrtDataSourceMessage = remindMessage.info.pmRecord

    private notificationIsOpen: boolean
    private notificationIsLoading = false
    private equipmentTemplate: EquipmentTemplateCoreDetails[]
    private hasNoTemplate = false
    private innerWindowHeight = window.innerHeight
    private innerWindowWidth = window.innerWidth
    private isOnlySaveAllowed$: BehaviorSubject<boolean>

    private updateObjectFromForm = (() => {
        let _previousFormValue: string
        return (formValue: any, forceUpdate = false): void => {
            const serializedFormValue = JSON.stringify(formValue)
            if (!forceUpdate && serializedFormValue === _previousFormValue) {
                return
            }

            _previousFormValue = serializedFormValue

            const { results, overview, customForm } = formValue

            if (this.calibration.calibrationTemplate) {
                this.calibration.calibrationTemplate.procedure = overview.procedureNumber
            }
            if (results.calibrationResult) {
                results.calibrationResult.customForms = customForm || []
            }

            this.calibration.repairWorkOrderNumber = overview.repairWorkOrderNumber

            this.calibration.pmPerformedDate = results.performedDate
            this.calibration.modifiedDate = results.modifiedDate
            this.calibration.comments = results.comments
            this.calibration.calibrationResult = results.calibrationResult
            this.calibration.calibrationResultStatus = results.calibrationResultStatus
            this.calibration.finalPMResultStatus = results.finalPMResultStatus
            this.calibration.isFinalPMResultStatusManuallySet = results.isFinalPMResultStatusManuallySet
            this.calibration.calibrationChecklist = results.calibrationChecklist
            this.calibration.equipmentSerialNumber = results.equipmentSerialNumber ?? ''
            this.calibration.equipmentReplacedDate = results.equipmentReplacedDate
            this.calibration.modelNumber = results.modelNumber ?? ''
            this.calibration.manufacturer = results.manufacturer ?? ''
            this.calibration.replacedModelNumber = results.replacedModelNumber ?? ''
            this.calibration.replacedManufacturer = results.replacedManufacturer ?? ''
            this.calibration.replacedEquipmentSerialNumber = results.replacedEquipmentSerialNumber ?? ''
            this.calibration.atoNumber = results.atoNumber
            this.calibration.atoDate = results.atoDate

            this.calibration.equipmentReplacedTechnicians = results.equipmentReplacedTechnicians.filter(technician => !!technician)
            this.calibration.pmPerformedTechnicians = results.pmPerformedTechnicians.filter(technician => !!technician)
            this.calibration.testEquipments = results.testEquipments.filter(testEquipment => !!testEquipment)
            this.calibration.cylinders = this.filterNullData(results.referenceMaterials)
        }
    })()

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private renderer: Renderer2,
        private appService: AppService,
        private formBuilder: FormBuilder,
        private toastrService: ToastrService,
        private offlineService: OfflineService,
        private modalContainerService: ModalContainerService,
        private calibrationAlertService: CalibrationAlertService,
        private reopenCalibrationService: ReopenCalibrationService,
        private resumeCalibrationService: ResumeCalibrationService,
        private calibrationStatusService: CalibrationStatusService,
        private calibrationFormComponentService: CalibrationFormComponentService,
        private calibrationLifeCycleService: CalibrationLifeCycleService,
        private calibrationFlamingoService: CalibrationFlamingoService,
        public calibrationFormService: CalibrationFormService,
        public calibrationValidatorService: CalibrationValidatorService
    ) {
        super()
        this.modalContainerService.registerComponent(DeferredNotTestedComponent, ModalType.DeferredNotTested)
        this.saveCalibration = this.saveCalibration.bind(this)
    }

    public get isOnline(): boolean {
        return this.offlineService.isOnline
    }

    public get calibrationCreated(): boolean {
        return this.calibration.calibrationStatus.id !== CalibrationStatusEnum.NotStarted
    }

    public get resumeCalibrationEnabled(): boolean {
        return this.reopenCalibrationEnabled && this.isNotificationStatusAvailable
    }

    public get saveButtonEnable(): boolean {
        const noRepairWO = isNotAValue(get(this.calibrationForm.value, 'overview.repairWorkOrderNumber'))

        return !(this.isRepairWOInvalid() && !noRepairWO) && this.isATechnician
    }

    public get canModifyCalibration(): boolean {
        return !this.isReport && !this.calibrationFormService.isPMDeferredOrNotTested$.value
            && !this.calibrationCompleted && this.isATechnician
    }

    public get enableChangeTemplate(): boolean {
        return this.canModifyCalibration && !this.hasNoTemplate && this.isNotificationStatusAvailable
    }

    public get isDeferredNotTestedOptionEnabled(): boolean {
        const isNotificationReady = this.notificationIsOpen || (!this.notificationIsOpen && this.isOnline)
        return this.canModifyCalibration && isNotificationReady
    }

    public get calibrationCompleted(): boolean {
        return this.calibration.calibrationStatus.id === CalibrationStatusEnum.Completed
    }

    public get isNotificationStatusAvailable(): boolean {
        return !isNotAValue(this.notificationIsOpen) && !this.notificationIsLoading
    }

    public get canReopenCalibration(): boolean {
        return this.calibrationCompleted && this.reopenCalibrationEnabled
    }

    public get canCompleteCalibration(): boolean {
        return (this.isOnline && this.isNotificationStatusAvailable)
            || (!this.isOnline && this.notificationIsOpen && !this.notificationIsLoading)
    }

    public get moreActionIsEnabled(): boolean {
        return (this.enableChangeTemplate
            || (this.isDeferredNotTestedOptionEnabled && !this.calibrationCompleted)
            || (this.reopenCalibrationEnabled && !this.calibrationFormService.isPMDeferredOrNotTested$.value)
        )
    }

    public get isOnlySaveAllowed(): boolean {
        const isOnlySaveAllowed = !this.calibrationCompleted && !this.notificationIsOpen && !this.isOnline

        if (isOnlySaveAllowed !== this.isOnlySaveAllowed$.value) {
            this.isOnlySaveAllowed$.next(isOnlySaveAllowed)
        }

        return isOnlySaveAllowed
    }

    @HostListener('window:resize', ['$event'])
    public onResize($event: any): void {
        if (this.innerWindowHeight < $event.target.innerHeight) {
            this.innerWindowHeight = $event.target.innerHeight
        }
        // Workaround to detect orientation change as orientationchange event can be fired when virtual keyboard appears also.
        if (this.innerWindowWidth !== $event.target.innerWidth) {
            this.innerWindowHeight = $event.target.innerHeight
            this.innerWindowWidth = $event.target.innerWidth
        } else {
            this.toggleTabBarVisibilityWhenKeyboardAppears()
        }
    }

    ngOnInit(): void {
        this.clearAllDrawers()
        this.checkIfTemplateExist()
        this.loadInfoOptions()
        this.notificationStatusHandler()
        this.addSubscriptions([
            combineLatest([
                this.calibrationFormService.isPMDeferredOrNotTested$,
                this.calibrationForm.get('overview.repairWorkOrderNumber').valueChanges
            ]).subscribe(_ => {
                this.loadSubmitDrawerOptions()
                this.loadMoreDrawerOptions()
            }),
        ],)
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.calibration?.currentValue) {
            super.ngOnDestroy()
            this.setupFormGroup()
            this.setupFormSubscription()
            this.setDataUnchanged()
            this.loadSubmitDrawerOptions()
            this.loadMoreDrawerOptions()
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy()
        this.calibrationAlertService.removeAllAlert()
        this.clearAllDrawers()
    }

    ngAfterContentInit(): void {
        this.setDataUnchanged()
    }

    public setDataUnchanged(): void {
        this.calibrationFormService.isCalibrationDataChanged.next(false)
        this.calibrationFlamingoService.isFlamingoDataChanged.next(false)
    }

    public changeTemplate(): void {
        if (this.enableChangeTemplate) {
            this.store.dispatch(new ShowModalAction(calibrationTemplateSelectionModalObject))
        }
    }

    public deferredNotTested(): void {
        if (!this.isDeferredNotTestedOptionEnabled) {
            return
        }

        this.store.dispatch(new ShowModalAction(deferredOrNotTestedModalObject))
    }

    public saveCalibration(calibration: CalibrationDetails): void {
        if (!this.isATechnician) {
            this.toastrService.error(
                'Seems like you don\'t have a Technician role or equivalent.',
                'Unable to save calibration.',
                mrmaAlertConfigs.Validation.configuration)
            return
        }

        if (this.calibrationValidatorService.getHasIncompleteCylinders()) {
            this.toastrService.error(
                incompleteCylindersError,
                '',
                mrmaAlertConfigs.Validation.configuration
            )
            return
        }

        if (this.calibrationValidatorService.getIsDuplicatedReferenceId(this.calibrationForm.get('results.referenceMaterials') as FormArray)) {
            this.toastrService.error(
                cylinderIdDuplicatedError,
                '',
                mrmaAlertConfigs.Validation.configuration
            )
            return
        }

        const clonedCalibrationDetail = deepCopy(calibration) as CalibrationDetails

        if (this.isRepairWOInvalid()) {
            clonedCalibrationDetail.repairWorkOrderNumber = ''
        }

        this.calibrationStatusService.createOrUpdateCalibration(
            this.workOrderDetails.workOrderNumber,
            this.equipment.equipmentId,
            clonedCalibrationDetail
        )

        this.calibrationAlertService.removeAllAlert()
        this.calibrationFlamingoService.reconcileLocalFormData()
        this.setDataUnchanged()
    }

    public validateCompleteCalibration(calibration: CalibrationDetails): void {
        if (!this.canCompleteCalibration) {
            return
        }

        if (!this.isATechnician) {
            this.toastrService.error(
                'Seems like you don\'t have a Technician role or equivalent.',
                'Unable to complete calibration.',
                mrmaAlertConfigs.Validation.configuration)
            return
        }

        if (this.calibrationFormService.isReplaceEquipmentSameAsOriginal()) {
            this.toastrService.error(
                'The same information as original is indicated as Replacement. Unable to complete calibration, please revise information.',
                '',
                mrmaAlertConfigs.Validation.configuration)
            return
        }

        if (this.calibrationValidatorService.getHasIncompleteCylinders()) {
            this.toastrService.error(
                incompleteCylindersError,
                '',
                mrmaAlertConfigs.Validation.configuration
            )
            return
        }

        if (this.calibrationValidatorService.getIsDuplicatedReferenceId(this.calibrationForm.get('results.referenceMaterials') as FormArray)) {
            this.toastrService.error(
                cylinderIdDuplicatedError,
                '',
                mrmaAlertConfigs.Validation.configuration
            )
            return
        }

        // FIXME: With the way this hook is implemented, there is no guarantee
        // that every thing will finish updating before it move the the next
        // function call.
        // e.g. if the template's implementation of the hook rely on a callback
        // to finish updating its data, it may creates a RACE condition.
        this.calibrationLifeCycleService.calibrationWillComplete()

        // Ensure we have the most up-to-date object.
        this.updateObjectFromForm(this.calibrationForm.getRawValue())
        const canComplete = this.calibrationFormComponentService.canCalibrationComplete(this.calibrationForm)

        if (!canComplete) {
            return
        }

        if (this.equipment.equipmentNotificationNumber) {
            calibration.notificationNumber = this.equipment.equipmentNotificationNumber
        }

        if (!this.notificationIsOpen) {
            const modal = deepCopy(modalContent.confirmReOpenNotification)
            modal.confirmCallback = () => {
                this.store.select(reopenNotification).subscribe(reopenResult => {
                    if (reopenResult === true) {
                        this.saveCompleteCalibration(calibration)
                    }
                })
                this.reopenCalibrationService.confirmReopenNotification(
                    this.workOrderDetails.workOrderNumber,
                    this.equipment.equipmentId,
                    this.calibration)
            }
            this.store.dispatch(new ShowModalAction(modal))
        } else if (this.calibrationFlamingoService.isAnyFlamingoLoading()) {
            const modal = deepCopy(modalContent.warningHasFlamingoLoading)
            modal.confirmCallback = () => {
                this.saveCompleteCalibration(calibration)
            }
            this.store.dispatch(new ShowModalAction(modal))
        } else {
            this.saveCompleteCalibration(calibration)
        }
    }

    public confirmReopenCalibration(calibration: CalibrationDetails): void {
        if (this.reopenCalibrationEnabled) {
            this.reopenCalibrationService.confirmReopenCalibration(
                calibration,
                this.workOrderDetails,
                () => this.store.dispatch(new DestroyModalAction()),
                () => this.setDataUnchanged()
            )
        }
    }

    public confirmResumeCalibration(calibration: CalibrationDetails): void {
        if (this.resumeCalibrationEnabled) {
            this.resumeCalibrationService.confirmResume(calibration)
        }
    }

    public viewProcedure(procedureURL: string): void {
        if (!this.isOnline) {
            return
        }

        const win = window.open(procedureURL)
        win.focus()
    }

    public showMoreOptions(): void {
        if (!this.calibrationFormService.isPMDeferredOrNotTested$.value) {
            this.store.dispatch(new ToggleSelectionDrawerAction({
                name: this.moreDrawerName,
                visibility: true
            }))
        }
    }

    public showInfoOptions(): void {
        this.store.dispatch(new ToggleSelectionDrawerAction({
            name: this.moreInfoName,
            visibility: true
        }))
    }

    public loadMoreDrawerOptions(): void {
        this.moreDrawerOptions = []

        if (!this.calibrationCompleted) {
            this.moreDrawerOptions.push({
                optionName: 'Select Template',
                callback: () => this.store.dispatch(new ToggleSelectionDrawerAction({
                    name: SelectionDrawerName.CalibrationChangeTemplate,
                    visibility: true
                })),
                disabled: !this.enableChangeTemplate
            })
            this.moreDrawerOptions.push({
                optionName: 'Defer/Not Test PM',
                disabled: !this.isDeferredNotTestedOptionEnabled,
                callback: () => this.deferredNotTested()
            })
        }

        if (this.canReopenCalibration) {
            this.moreDrawerOptions.push({
                optionName: 'Re-open PM',
                callback: () => this.confirmReopenCalibration(this.calibration),
                disabled: !this.reopenCalibrationEnabled
            })
        }
    }

    public showSubmitOptions(): void {
        this.store.dispatch(new ToggleSelectionDrawerAction({
            name: this.submitDrawerName,
            visibility: true
        }))
    }

    public isProcedureLink(): boolean {
        return CalibrationService.isProcedureLink(this.equipmentTemplate, this.calibration.templateId)
    }

    private loadInfoOptions(): void {
        this.moreDrawerInfoOptions = [
            {
                optionName: 'Hyperlink',
                callback: () => this.store.dispatch(new SlideOverlayAction({
                    id: SlideUpOverlayType.Hyperlink,
                    visibility: true
                }))
            },
            {
                optionName: 'Note To Technician',
                callback: () => this.store.dispatch(new SlideOverlayAction({
                    id: SlideUpOverlayType.NoteToTechnician,
                    visibility: true
                })),
                disabled: !this.equipment?.equipmentNote
            },
            {
                optionName: 'Equipment Details',
                callback: () => this.store.dispatch(new SlideOverlayAction({
                    id: SlideUpOverlayType.EquipmentDetails,
                    visibility: true
                }))
            },
            {
                optionName: 'Equipment Long Text',
                callback: () => this.store.dispatch(new SlideOverlayAction({
                    id: SlideUpOverlayType.EquipmentLongText,
                    visibility: true
                })),
                disabled: !this.equipmentDetails?.longText
            },
            {
                optionName: 'Work Order Details',
                callback: () => this.store.dispatch(new SlideOverlayAction({
                    id: SlideUpOverlayType.WorkOrderDetails,
                    visibility: true
                }))
            },
            {
                optionName: 'Work Order Long Text',
                callback: () => this.store.dispatch(new SlideOverlayAction({
                    id: SlideUpOverlayType.WorkOrderLongText,
                    visibility: true
                })),
                disabled: !this.workOrderDetails?.longText
            },
            {
                optionName: 'Maintenance History',
                callback: () => this.store.dispatch(new SlideOverlayAction({
                    id: SlideUpOverlayType.MaintenanceHistory,
                    visibility: true
                }))
            },
            {
                optionName: 'Procedure',
                callback: () => this.isProcedureLink()
                    ? this.viewProcedure(this.calibrationForm.get('overview.procedureNumber').value)
                    : this.viewProcedure(this.workOrderDetails.procedureURL)
            }
        ]
    }

    private loadSubmitDrawerOptions(): void {
        const saveLabel = this.calibrationCreated ? 'Save' : 'Save as Draft'
        this.submitDrawerOptions = []
        this.submitDrawerOptions.push({
            optionName: saveLabel,
            callback: () => this.saveCalibration(this.calibration),
            disabled: !this.saveButtonEnable
        })
        if (!this.calibrationCompleted) {
            this.submitDrawerOptions.push({
                optionName: 'Complete',
                callback: () => this.validateCompleteCalibration(this.calibration),
                disabled: !this.canModifyCalibration
            })
        }
        if (this.calibrationCompleted && this.calibrationFormService.isPMDeferredOrNotTested$.value) {
            this.submitDrawerOptions.push({
                optionName: 'Resume',
                callback: () => this.confirmResumeCalibration(this.calibration),
                disabled: !this.resumeCalibrationEnabled
            })
        }
    }

    private toggleTabBarVisibilityWhenKeyboardAppears(): void {
        if (this.innerWindowHeight - window.innerHeight > 200) {
            this.renderer.setStyle(this.tabBar.nativeElement, 'visibility', 'hidden')
        } else {
            this.renderer.setStyle(this.tabBar.nativeElement, 'visibility', 'visible')
        }
    }

    private setupFormGroup(): void {
        const isNoTemplate = CalibrationDetails.isNoTemplate(this.calibration)
        const haveTextValidator = isNoTemplate ? null : this.haveTextValidator()
        const haveCalibrationResultValidator = isNoTemplate ? null : Validators.required
        this.calibrationForm = this.formBuilder.group({
            overview: this.formBuilder.group({
                procedureNumber: [this.calibration.calibrationTemplate?.procedure ?? '', haveTextValidator],
                repairWorkOrderNumber: [
                    this.calibration.repairWorkOrderNumber,
                    [
                        this.repairWOValidator(this.calibration.calibrationStatus, this.calibration.finalPMResultStatus),
                        Validators.pattern('^[0-9]{8}$|^$')
                    ]
                ]
            }),
            results: this.formBuilder.group({
                performedDate: [this.calibration.pmPerformedDate, Validators.required],
                modifiedDate: [this.calibration.modifiedDate],
                pmPerformedTechnicians:
                    this.formBuilder.array(this.calibration.pmPerformedTechnicians.map(value =>
                        this.formBuilder.control(value))),
                testEquipments:
                    safeDefaultObjGet(this.calibration.calibrationTemplate, 'isTestEquipmentRequired', false) ?
                        this.formBuilder.array(this.calibration.testEquipments.map(value =>
                            this.formBuilder.control(value, Validators.required)), this.haveItemInArrayValidator()) :
                        this.formBuilder.array(this.calibration.testEquipments.map(value =>
                            this.formBuilder.control(value))),
                comments: [this.calibration.comments],
                calibrationResult: [this.calibration.calibrationResult, haveCalibrationResultValidator],
                calibrationResultStatus: [this.calibration.calibrationResultStatus],
                calibrationChecklist: this.getCalibrationChecklistForm(),
                equipmentSerialNumber: [this.calibration.equipmentSerialNumber],
                equipmentReplacedDate: [this.calibration.equipmentReplacedDate],
                modelNumber: [this.calibration.modelNumber],
                manufacturer: [this.calibration.manufacturer],
                replacedModelNumber: [this.calibration.replacedModelNumber],
                replacedManufacturer: [this.calibration.replacedManufacturer],
                equipmentReplacedTechnicians:
                    this.formBuilder.array(this.calibration.equipmentReplacedTechnicians.map(value =>
                        this.formBuilder.control(value))),
                replacedEquipmentSerialNumber: [this.calibration.replacedEquipmentSerialNumber],
                finalPMResultStatus: [this.calibration.finalPMResultStatus, Validators.required],
                isFinalPMResultStatusManuallySet: [this.calibration.isFinalPMResultStatusManuallySet ?? false],
                atoNumber: [this.calibration.atoNumber],
                atoDate: [this.calibration.atoDate],
                reasonForNotComplete: [this.calibration.reasonForNotComplete],
                referenceMaterials: this.getCylindersInitValue()
            })
        })

        if (this.calibration.calibrationStatus.id === CalibrationStatusEnum.Completed) {
            this.calibrationForm.disable()
            this.calibrationForm.get('results.comments').enable()
            this.calibrationForm.get('results.equipmentReplacedDate').enable()
            this.calibrationForm.get('results.equipmentReplacedTechnicians').enable()
            this.calibrationForm.get('results.replacedEquipmentSerialNumber').enable()
            this.calibrationForm.get('results.replacedModelNumber').enable()
            this.calibrationForm.get('results.replacedManufacturer').enable()

            if (!this.calibrationForm.get('results.reasonForNotComplete').value) {
                this.calibrationForm.get('results.reasonForNotComplete').enable()
            }

            if (!this.calibrationForm.get('results.atoNumber').value) {
                this.calibrationForm.get('results.atoNumber').enable()
            }

            if (!this.calibrationForm.get('results.atoDate').value) {
                this.calibrationForm.get('results.atoDate').enable()
            }

            const finalPMResultStatusId = this.calibration?.finalPMResultStatus.id
            if (finalPMResultStatusId === CalibrationResultStatusEnum.Failed && !this.calibration.repairWorkOrderNumber) {
                this.calibrationForm.get('overview.repairWorkOrderNumber').enable()
            }
        }

        if (!this.isATechnician) {
            this.calibrationForm.disable()
        }

        // Hand over the form object to the service to manage
        this.calibrationFormService.calibrationForm = this.calibrationForm
    }

    private setupFormSubscription(): void {
        this.addSubscription(
            this.calibrationForm.valueChanges.pipe(debounceTime(300)).subscribe(() =>
                this.updateObjectFromForm(this.calibrationForm.getRawValue())
            )
        )
    }

    private isRepairWOInvalid(): boolean {
        const workOrderNumberControl = this.calibrationForm.get('overview.repairWorkOrderNumber')

        return !!workOrderNumberControl?.errors?.pattern
    }

    private repairWOValidator(calibrationStatus: CalibrationStatus, finalPMResultStatus: CalibrationResultStatus): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (calibrationStatus.id === CalibrationStatusEnum.Completed &&
                finalPMResultStatus.id === CalibrationResultStatusEnum.Failed &&
                control.value === null
            ) {
                return { requiredRepairWO: true }
            }
            return null
        }
    }

    private haveTextValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null =>
            control.value === null || control.value.trim() === '' ? { requiredText: true } : null
    }

    private haveItemInArrayValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null =>
            control.value[0] === null || control.value[0] === undefined ? { requiredItem: true } : null
    }

    private getCalibrationChecklistForm(): FormArray {
        if (this.calibration.templateTypeId !== TemplateTypeEnum[TemplateTypeEnum.thirdParty] &&
            this.calibration.templateTypeId !== TemplateTypeEnum[TemplateTypeEnum.leakCheck]
        ) {
            return this.formBuilder.array([])
        }

        return this.formBuilder.array(this.calibration.calibrationChecklist.map((value: CalibrationChecklist) =>
            this.formBuilder.group({
                question: [value.question],
                response: [value.response, Validators.required]
            })
        ))
    }

    private clearAllDrawers(): void {
        this.store.dispatch(new ToggleSelectionDrawerAction({
            name: this.moreDrawerName,
            visibility: false
        }))
        this.store.dispatch(new ToggleSelectionDrawerAction({
            name: this.moreInfoName,
            visibility: false
        }))
        this.store.dispatch(new ToggleSelectionDrawerAction({
            name: this.submitDrawerName,
            visibility: false
        }))
    }

    private checkIfTemplateExist(): void {
        this.store.select(equipmentTemplateList).subscribe(templateList => {
            this.equipmentTemplate = templateList
            this.hasNoTemplate = templateList.length <= 0
            this.loadMoreDrawerOptions()
        })
    }

    private notificationStatusHandler(): void {
        this.isOnlySaveAllowed$ = new BehaviorSubject(false)

        this.store.select(notifications).subscribe((notificationStatus) => {
            let isNotificationOpen = this.equipment.equipmentNotificationNumber ?
            notificationStatus[this.equipment.equipmentNotificationNumber]?.isOpen :
            notificationStatus[this.calibration.notificationNumber]?.isOpen

            this.notificationIsOpen = isNotificationOpen
            this.notificationIsLoading = this.equipment.equipmentNotificationNumber ?
            !!(notificationStatus[this.equipment.equipmentNotificationNumber]?.isLoading) :
            !!(notificationStatus[this.calibration.notificationNumber]?.isLoading)
            this.loadSubmitDrawerOptions()
            this.loadMoreDrawerOptions()
        })

        this.isOnlySaveAllowed$.subscribe(isAllowed => {
            if (isAllowed === true) {
                this.toastrService.error(
                    remindMessage.error.offlineCompletionIsDisabled,
                    '',
                    mrmaAlertConfigs.Validation.configuration)
            }
        })
    }

    private saveCompleteCalibration(calibration: CalibrationDetails): void {
        const clonedCalibrationDetails: CalibrationDetails = deepCopy(calibration)

        clonedCalibrationDetails.calibrationStatus = {
            id: CalibrationStatusEnum.Completed,
            name: 'completed',
            previousStatus: {
                id: CalibrationStatusEnum.Draft
            }
        } as CalibrationStatusWithPreviousState

        this.saveCalibration(clonedCalibrationDetails)
    }

    private getCylindersInitValue(): FormArray {
        if (this.calibration.cylinders === null || this.calibration.cylinders?.length === 0) {
            return this.formBuilder.array([
                this.formBuilder.group(
                    {
                        referenceId: '',
                        content: '',
                        expiryDate: ''
                    }
                )
            ]
            )
        } else {
            return this.formBuilder.array(
                this.calibration.cylinders.map(cylinder => this.formBuilder.group(
                    cylinder
                ))
            )
        }
    }

    private filterNullData(referenceMaterials): [] | null {
        const temp = referenceMaterials.filter(
            cylinder => cylinder.referenceId && cylinder.content && cylinder.expiryDate
        )
        return temp.length === 0 ? null : temp
    }
}
