<svg class="em-c-icon  em-c-icon--gray em-c-icon-tmp-fix em-c-icon--yellow"
     [ngClass]="'em-c-icon--'+iconSize"
     *ngIf="isDescending">
    <use xmlns:xlink="http://www.w3.org/1999/xlink"
         xlink:href="./assets/unity-1.2.0/images/em-icons.svg#icon-caret-up"></use>
</svg>

<svg *ngIf="!isDescending"
     class="em-c-icon em-c-icon-tmp-fix"
     [ngClass]="[(isSorted ? 'em-c-icon--yellow' : ''), 'em-c-icon--'+iconSize]">
    <use xmlns:xlink="http://www.w3.org/1999/xlink"
         xlink:href="./assets/unity-1.2.0/images/em-icons.svg#icon-caret-down"></use>
</svg>
