import { Component, Injector, OnInit } from '@angular/core'
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'

import { ThirdPartyCalibrationResult } from '@app/modules/calibration/models/calibration-result.model'
import { ChecklistResponseEnum } from '@app/modules/calibration/models/checklist-response.enum'
import { ChecklistQuestion } from '@app/modules/calibration/models/checklist.model'
import { TemplateTypeEnum } from '@app/modules/equipment/models/template-type.enum'
import { RadioHorizontalOption, RadioHorizontalOptionIcon } from '@app/modules/shared/components/radio-horizontal/radio-horizontal-option.model'
import { AbstractCalibrationTemplateComponent } from '../abstract-calibration-template.component'

@Component({
    selector: 'app-third-party',
    templateUrl: './third-party.component.html',
    styleUrls: ['./third-party.component.scss']
})
export class ThirdPartyComponent extends AbstractCalibrationTemplateComponent implements OnInit {

    public thirdPartyForm: FormGroup

    // Options for the RadioHorizontalComponent
    public radioOptions: RadioHorizontalOption[] = [{
        icon: RadioHorizontalOptionIcon.CHECK,
        optionText: 'YES',
        value: { response: 'Yes', id: ChecklistResponseEnum.Yes }
    },
    {
        icon: RadioHorizontalOptionIcon.CROSS,
        optionText: 'NO',
        value: { response: 'No', id: ChecklistResponseEnum.No }
    },
    {
        icon: RadioHorizontalOptionIcon.MINUS,
        optionText: 'N/A',
        value: { response: 'N/A', id: ChecklistResponseEnum.NA }
    }]

    // For the RadioHorizontalComponent to compare option's value
    public customValueComparer = ((a, b) => a.id === b.id)

    constructor(
        private formBuilder: FormBuilder,
        injector: Injector
    ) {
        super(injector)
    }

    public get templateWillCalculateCalibrationResultStatus(): boolean {
        return false
    }

    public get checklistBySectionControls(): FormGroup[] {
        return (this.thirdPartyForm.get('checklistBySection') as FormArray).controls as FormGroup[]
    }

    public get isLeakCheck(): boolean {
        return this.calibration.templateTypeId === TemplateTypeEnum['Leak Check']
    }

    ngOnInit(): void {
        super.ngOnInit()

        this.initForm()
        this.loadChecklistBySection()
    }

    public initForm(): void {
        this.thirdPartyForm = this.formBuilder.group({
            checklistBySection: this.formBuilder.array([])
        })

        if (!this.isLeakCheck) {
            const companyNameControl = this.calibrationForm.get('results.calibrationResult.companyName')
            const companyName = companyNameControl
                ? companyNameControl.value
                : (this.calibration.calibrationResult as ThirdPartyCalibrationResult).companyName

            const innerForm = this.calibrationForm.get('results') as FormGroup
            innerForm.setControl('calibrationResult', this.formBuilder.group({
                companyName: {
                    value: companyName || '',
                    disabled: this.isDisabled
                }
            }))
        }
    }

    public loadChecklistBySection(): void {
        const calibrationChecklistControls = (this.calibrationForm.get('results.calibrationChecklist') as FormArray)
        const checklistBySectionControls = this.thirdPartyForm.get('checklistBySection') as FormArray
        for (const item of calibrationChecklistControls.controls) {
            const questionControl = item.get('question') as FormControl
            const questionSectionName = (questionControl.value as ChecklistQuestion).checklistQuestionSection.name
            const targetSection = (checklistBySectionControls.controls as FormGroup[]).find((sectionFormGroup: FormGroup) => {
                return sectionFormGroup.get('sectionName').value === questionSectionName
            })

            if (targetSection) {
                const checklistControls = targetSection.get('checklist') as FormArray
                checklistControls.push(item as FormGroup)
            } else {
                const checklist = new FormArray([item as FormGroup])
                const newSection = this.formBuilder.group({
                    sectionName: new FormControl((questionControl.value as ChecklistQuestion).checklistQuestionSection.name),
                    checklist,
                    possibleResponse: this.formBuilder.array(
                        questionControl.value.possibleResponse
                            .slice()
                            .sort((a: { id: number; }, b: { id: number; }) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
                    )
                })
                checklistBySectionControls.push(newSection)
            }
            item.setParent(calibrationChecklistControls)
        }
    }

    public checkSectionValid(section: AbstractControl): boolean {
        for (const list of (section.get('checklist') as FormArray).controls) {
            if (list.value.response === null) {
                return true
            }
        }
        return false
    }

    public getChecklistControl(sectionControl: FormGroup): FormArray {
        return sectionControl.get('checklist') as FormArray
    }

    protected updateCalibrationResultStatus(): void {
        // No custom update to be made. Calibration result must be
        // manually selected by CalibrationResultSelectorComponent
        return
    }
}
