<div *ngIf="offlineStatusEnabled"
     [ngClass]="getAlignmentClass">
    <div class="icon-container"
         *ngIf="getAvailableOfflineStatus(offlineStatus.CACHED)">
        <p>
            <img class="em-c-icon em-c-icon--small"
                 src="./assets/images/ic_check_circle_14px.svg" />
            <span>Available for Offline</span>
        </p>
    </div>
    <div class="icon-container"
         *ngIf="getAvailableOfflineStatus(offlineStatus.FAILED)">
        <p>
            <img class="em-c-icon em-c-icon--small"
                 src="./assets/images/ic_cancel_14px.svg" />
            <span>Not Available Offline</span>
        </p>
    </div>
    <div class="icon-container"
         *ngIf="getAvailableOfflineStatus(offlineStatus.LOADING)">
        <p>
            <svg class="em-c-icon em-c-icon--small">
                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="./assets/unity-1.2.0/images/em-icons.svg#refresh"></use>
            </svg>
            <span>Loading...</span>
        </p>
    </div>
</div>