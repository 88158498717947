import { Component, Input } from '@angular/core'

// TODO: Add layout support for Desktop Label (Label on top like stack mode)
// TODO: Add support for subtitle/sub-label
@Component({
    selector: 'app-res-labeled-element',
    templateUrl: './res-labeled-element.component.html',
    styleUrls: ['./res-labeled-element.component.scss']
})
export class ResLabeledElementComponent {

    @Input() labelText: string
    @Input() labelStyle: 'normal' | 'bold' = 'normal'
    @Input() mobileLabelPosition: 'left' | 'top' = 'left'

    @Input() subLabelText: string
    @Input() spanLabelText: string
    @Input() hideSublabelOnDesktop = true

    @Input() hideLabelOnDesktop = true

    @Input() desktopContentAlign: 'left' | 'center' | 'right' = 'center'
    @Input() desktopStyle: 'standalone' | 'table-element' = 'table-element'

    @Input() isReportAlignment: boolean

    /**
     * If set to false, the element will only fill parent's width.
     * This is so that borders would fill up the entire horizontal width.
     * The element itself have its own padding around.
     */
    @Input() fillViewWidthOnMobile = true
    @Input() mobileBorders: 'top' | 'bottom' | 'top-bottom' | 'none' = 'none'
    @Input() injectedElementStyles: { [key: string]: any } = {}
    @Input() injectedContentStyles: { [key: string]: any } = {}

    public get elementStyles(): { [key: string]: any } {
        return { ...this.injectedElementStyles }
    }
    public get contentStyles(): { [key: string]: any } {
        return { ...this.injectedContentStyles }
    }
}
