<section class="cal-info">
    <div class="responsive-calibration-field-container">
        <div class="web-view calibration-info">
            <label>Work Order</label>
            <p class="inline">{{workOrderDetails.workOrderNumber}}</p>
            <p class="label"
               [ngClass]="workOrderStatus === 'open' ? 'label-warning' : 'label-default'"
               *ngIf="!isReport">
                {{workOrderStatus}}
            </p>
        </div>
        <div class="web-view calibration-info">
            <label>Notification</label>
            <p class="inline">
                {{workOrderDetails.notification.number}}
            </p>
            <p *ngIf="workOrderDetails.notification.isOpen"
               class="label label-warning">Open</p>
            <p *ngIf="!workOrderDetails.notification.isOpen"
               class="label label-default">Closed</p>
        </div>
        <div class="web-view calibration-info">
            <label>PM Status</label>
            <p [class]="calibrationStatusDisplay.classLabel">{{calibrationStatusDisplay.name}}</p>
            <p *ngIf="calibration.dataSource === 'GRT'"
               class="label label-grt">{{calibration.dataSource}}</p>
        </div>
        <div class="web-view calibration-info">
            <label>PM Result</label>
            <p [ngClass]="{'result-status-text-error': calibrationFormService.isPMDeferredOrNotTested$ | async}">
                {{(calibrationFormService.resultsValue$ | async)?.finalPMResultStatus?.name || '-'}}
            </p>
        </div>
    </div>

    <div *ngIf="calibrationFormService.isPMDeferredOrNotTested$ | async"
         class="web-view responsive-calibration-field-container reason">
        <div class="calibration-info">
            <label>Reason for not completing the work</label>
            <textarea disabled
                      readonly>{{notCompleteReason}}</textarea>
        </div>
    </div>

    <div *ngIf="!(calibrationFormService.isPMDeferredOrNotTested$ | async) && calibration && !isNoTemplate(calibration)"
         class="responsive-calibration-field-container">
        <div class="calibration-info">
            <label>Process</label>
            <p>{{processName}}</p>
        </div>
        <div class="calibration-info">
            <label>Template Type</label>
            <p>{{templateType[calibration.templateTypeId]}}</p>
        </div>
        <div class="calibration-info">
            <label>Alias</label>
            <p>{{calibration?.calibrationTemplate?.alias || '-'}}</p>
        </div>
    </div>

    <div *ngIf="!(calibrationFormService.isPMDeferredOrNotTested$ | async) && appService.isSupervisoryReviewEnable(calibration?.processId)"
         class="responsive-calibration-field-container">
        <div class="calibration-info">
            <label>Review Status</label>
            <p [class]="reviewStatusDisplay(calibration?.reviewStatusId)?.classLabel">
                {{reviewStatusDisplay(calibration?.reviewStatusId)?.name}}
            </p>
        </div>
        <div *ngIf="reviewDate"
             class="calibration-info">
            <label>Reviewed Date</label>
            <p>{{reviewDate}}</p>
        </div>
        <div *ngIf="calibration?.reviewer"
             class="calibration-info">
            <label>Reviewed By</label>
            <p>{{calibration?.reviewer}}</p>
        </div>
    </div>
    <div class="responsive-view responsive-calibration-field-container">
        <div (click)="viewProcedure(workOrderDetails.procedureURL)"
             class="calibration-info">
            <label>Procedure Document</label>
            <p>View &gt;</p>
        </div>
        <div (click)="viewHistory()"
             class="calibration-info">
            <label>PM History</label>
            <p>View &gt;</p>
        </div>
    </div>
</section>
