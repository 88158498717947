import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { CoreModule } from '../core/core.module'
import { HomeRoutingModule } from './home-routing.module'
import { HomeComponent } from './home.component'
import { SharedModule } from '../shared/shared.module'

@NgModule({
    declarations: [HomeComponent],
    imports: [
        CoreModule,
        SharedModule,
        CommonModule,
        HomeRoutingModule,
    ],
    providers: []
})
export class HomeModule { }
