import { Component, Injector, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'

import { CalibrationResultStatusEnum } from '@app/modules/calibration/models/calibration-result-status.enum'
import { CalibrationResultRow } from '@app/modules/calibration/models/calibration-result-value.model'
import { CalibrationResult } from '@app/modules/calibration/models/calibration-result.model'
import { CalibrationValidationStatus } from '@app/modules/calibration/models/calibration-validation-status.enum'
import { WeightTemplate } from '@app/modules/calibration/models/weight-template.model'
import { CalibrationInitializerService } from '@app/modules/calibration/services/calibration-initializer.service'
import { CalibrationResultStatusService } from '@app/modules/calibration/services/calibration-result-status.service'
import { RangeCalculationService } from '@app/modules/calibration/services/range-calculation.service'
import { WeightCalibrationService } from '@app/modules/calibration/services/weight-calibration.service'
import { TemplateTypeEnum } from '@app/modules/equipment/models/template-type.enum'
import { UnitRange } from '@app/modules/shared/models/engineering-units/unit-range.model'
import { AbstractCalibrationTemplateComponent } from '../abstract-calibration-template.component'

@Component({
    selector: 'app-weight',
    templateUrl: './weight.component.html',
    styleUrls: ['./weight.component.scss']
})
export class WeightComponent extends AbstractCalibrationTemplateComponent implements OnInit {

    public template: WeightTemplate
    public resultSet: CalibrationResultRow[]
    public weightForm: FormArray
    public templateType: TemplateTypeEnum

    constructor(
        public weightCalibrationService: WeightCalibrationService,
        private formBuilder: FormBuilder,
        private rangeCalculationService: RangeCalculationService,
        private calibrationInitializerService: CalibrationInitializerService,
        private calibrationResultStatusService: CalibrationResultStatusService,
        injector: Injector
    ) {
        super(injector)
    }

    public get expectedTolerance(): string {
        const range = {
            maximumRange: this.template.input.maximumRange,
            minimumRange: 0
        } as UnitRange

        const expected = this.rangeCalculationService.calculateTolerance(this.template.tolerance, range)

        return `${range.maximumRange - expected} to ${range.maximumRange + expected}`
    }

    ngOnInit(): void {
        super.ngOnInit()
        this.template = this.calibration.calibrationTemplate as WeightTemplate
        this.initForm()
        this.addSubscription(this.calibrationForm.get('results.calibrationResult').valueChanges.subscribe(() => {
            this._updateCalibrationResultStatus()
        }))
    }

    public getResultSetControl(): FormArray {
        const calibrationResult = this.calibrationForm.get('results.calibrationResult.results') as FormArray
        return calibrationResult.controls[0].get('resultSet') as FormArray
    }

    public inputValidation(value: number): number {
        if (value === null) {
            return CalibrationValidationStatus.Initialize
        }

        if (this.weightCalibrationService.isInRange(this.calibration, this.template, value)) {
            return CalibrationValidationStatus.Valid
        }

        return CalibrationValidationStatus.Invalid

    }

    public autoPopulateAsLeft(): void {
        this.weightCalibrationService.autoPopulated(this.calibrationForm, this.calibration, this.template)
    }

    protected updateCalibrationResultStatus(): void {
        this._updateCalibrationResultStatus()
    }

    private initForm(): void {
        const numberOfPoint = this.calibration.calibrationTemplate.numberOfPoint
        const calibrationResultSet = (this.calibration?.calibrationResult ?? new CalibrationResult())
        const calibrationForm = this.calibrationInitializerService.initializeResultSetForm(
            '', calibrationResultSet.results[0], numberOfPoint
        )
        const innerForm = this.calibrationForm.get('results') as FormGroup
        innerForm.setControl('calibrationResult', this.formBuilder.group({
            results: this.formBuilder.array([calibrationForm])
        }))

        this.templateType = numberOfPoint === 4 ? TemplateTypeEnum.weightFourPoint : TemplateTypeEnum.weightSixPoint
    }

    private _updateCalibrationResultStatus(): void {
        const calibrationResult = this.calibrationForm.get('results.calibrationResult.results') as FormArray
        const calibrationValue = (calibrationResult.controls[0].get('resultSet') as FormArray).getRawValue()
        const resultStatusForm = this.calibrationForm.get('results.calibrationResultStatus') as FormControl
        let asFoundPass = 0
        let asLeftPass = 0

        calibrationValue.forEach(value => {
            asFoundPass += this.weightCalibrationService.isInRange(this.calibration, this.template, value['asFound']) ? 1 : 0
        })

        calibrationValue.forEach(value => {
            asLeftPass += this.weightCalibrationService.isInRange(this.calibration, this.template, value['asLeft']) ? 1 : 0
        })

        if (calibrationResult.valid || calibrationResult.disabled) {
            if (asFoundPass === this.template.numberOfPoint && asLeftPass === this.template.numberOfPoint) {
                this.calibrationResultStatusService.updateCalibrationResultStatus(resultStatusForm, CalibrationResultStatusEnum.Passed)
            } else if (asLeftPass === this.template.numberOfPoint) {
                this.calibrationResultStatusService.updateCalibrationResultStatus(resultStatusForm, CalibrationResultStatusEnum.FailedAdjustedPassed)
            } else {
                this.calibrationResultStatusService.updateCalibrationResultStatus(resultStatusForm, CalibrationResultStatusEnum.Failed)
            }
        } else {
            this.calibrationResultStatusService.updateCalibrationResultStatus(resultStatusForm, undefined)
        }
    }
}
