import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { filter, map } from 'rxjs/operators'

import { environment } from '@environments/environment'
import { IdentityService } from './identity.service'

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {

    constructor(private identityService: IdentityService) { }

    // TODO: Make this return a BehaviorSubject instead
    public isFeatureOff$(featureName: string): Observable<boolean> {
        const featureFlagVal = environment.features[featureName]
        const typeOfFeatureFlag = typeof featureFlagVal

        if (typeOfFeatureFlag === 'boolean') {
            return of(!featureFlagVal)
        }

        return this.identityService.currentIdentity$.pipe(
            filter(currentIdentity => !!currentIdentity),
            map(currentIdentity => currentIdentity.preferredPlantCode),
            map(preferredPlantCode =>
                (typeOfFeatureFlag === 'object' && !!featureFlagVal.find(plant => plant === preferredPlantCode))
            ),
            map(isFeatureOn => !isFeatureOn)
        )
    }

    public isFeatureOn$(featureName: string): Observable<boolean> {
        return this.isFeatureOff$(featureName).pipe(map(v => !v))
    }

    public isFeatureOff(featureName: string): boolean {
        const featureFlagVal = environment.features[featureName]
        const typeOfFeatureFlag = typeof featureFlagVal

        if (typeOfFeatureFlag === 'boolean') {
            return !environment.features[featureName]
        }

        // FIXME: Potential RACE condition
        const currentPlantCode = this.identityService.currentIdentity$.value.preferredPlantCode
        if (typeOfFeatureFlag === 'object' && !!featureFlagVal.find(plant => plant === currentPlantCode)) {
            return false
        }

        return true
    }

    public isFeatureOn(featureName: string): boolean {
        return !this.isFeatureOff(featureName)
    }
}
