import { Directive, HostBinding, Input } from '@angular/core'

/**
 * Use to add custom column start / end style (grid-column).
 */
@Directive({
    selector: '[appResTableColumn]'
})
export class ResTableColumnDirective {

    @Input('appResTableColumn')
    @HostBinding('style.grid-column')
    gridColumn: string

}
