<app-res-section-header headerTitle="Pop Test"></app-res-section-header>
<h3 class="form-section section-title">Pop Test</h3>
<app-res-table [numberOfColumns]="4"
               desktopTableGap="m">
    <div class="calibration-info">
        <app-label title="CDTP"
                   [subtitle]="'('+templateSetting.setPoint.unitOfMeasurement?.uomCodeForTech+')'">
            <div>
                {{ templateSetting.setPoint.value }}
            </div>
        </app-label>
    </div>
    <div class="calibration-info">
        <app-label title="Testing Direction">
            <div>
                {{ templateSetting.testingDirection | toReadableTestingDirection }}
            </div>
        </app-label>
    </div>
    <div *ngIf="templateSetting.serviceFluid?.serviceFluidName" class="calibration-info service-fluid">
        <app-label title="Service Fluid">
            <div>
                {{ templateSetting.serviceFluid.serviceFluidName }}
            </div>
        </app-label>
    </div>
    <!-- PopTest -->
    <div class="em-c-field__body calibration-field"
         appResTableNewRow>
        <div class="calibration-info responsive-calibration-field-container">
            <app-label title="Expected As Found"
                       [subtitle]="'('+templateSetting.setPoint.unitOfMeasurement?.uomCodeForTech+')'">
                <div>
                    {{ lowerExpectedValue }} to {{ upperExpectedValue }}
                </div>
            </app-label>
        </div>
        <app-label title="As Found"
                   [subtitle]="'('+templateSetting.expectedReading.unitOfMeasurement?.uomCodeForTech+')'"
                   [classList]="isDisabled ? 'em-is-disabled' : ''">
            <div class="field-labels">
                <span class="reading-label">Reading</span>
            </div>
            <ng-container *ngFor="let point of resultSetControl.controls; let i = index">
                <div class="field-input">
                    <app-numeric-input appInputValidator
                                       [input]="asFoundValidationStatuses[i]"
                                       [appCalibrationInputReport]="isReport"
                                       valueIfEmpty="-"
                                       [formControl]="point.get('asFound')"
                                       [placeholder]="(i + 1 | ordinal) + ' Attempt'"
                                       (inputBlur)="fillInSkippedAsFound()">
                    </app-numeric-input>
                </div>
            </ng-container>
        </app-label>
    </div>
    <div class="em-c-field__body calibration-field">
        <div class="calibration-info responsive-calibration-field-container">
            <app-label title="Expected As Left"
                       [subtitle]="'('+templateSetting.setPoint.unitOfMeasurement?.uomCodeForTech+')'">
                <div>
                    {{ lowerAsLeftExpectedValue }} to {{ upperAsLeftExpectedValue }}
                </div>
            </app-label>
        </div>
        <app-label title="As Left"
                   [subtitle]="'('+templateSetting.expectedReading.unitOfMeasurement?.uomCodeForTech+')'"
                   [classList]="isDisabled ? 'em-is-disabled' : ''">
            <div class="field-labels">
                <span class="reading-label">Reading</span>
            </div>
            <div class="field-input">
                <app-numeric-input appInputValidator
                                   [input]="asLeftValidationStatus"
                                   [appCalibrationInputReport]="isReport"
                                   valueIfEmpty="-"
                                   [formControl]="resultSetControl.controls[0].get('asLeft')"
                                   placeholder="0.0">
                </app-numeric-input>
            </div>
        </app-label>
    </div>
</app-res-table>

<app-custom-form [calibration]="calibration"
                 [calibrationForm]="calibrationForm"
                 [isReport]="isReport"></app-custom-form>
