import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Store } from '@ngrx/store'
import { isEqual } from 'lodash'
import { switchMap } from 'rxjs/operators'

import { defaultWorkOrderFilters } from '@app/modules/equipment/constants/workOrderFilterLists.constant'
import { ModalType } from '@app/modules/modal-container/models/modal.model'
import { ModalContainerService } from '@app/modules/modal-container/services/modal-container.service'
import {
    ChildFiltersUnderPreset,
    ReportFilterFields,
    CalibrationPresets
} from '@app/modules/report/constants/report-filter-list.constant'
import { ReportFilterPresetModalComponent } from '@app/modules/report/modals/report-filter/report-filter.component'
import { reportFilterNewModal } from '@app/modules/report/modals/report-filter/report-filter.model-object'
import { ReportListQueryParameter } from '@app/modules/report/models/report-list-query.model'
import { filterQueryFields } from '@app/modules/report/utils/utils'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import {
    equipmentABCIndicatorFilterOptions, equipmentCategoryFilterOptions, equipmentClassificationFilterOptions
} from '@app/store/equipment/selectors/equipment-list.selectors'
import { ShowModalAction } from '@app/store/modal/modal.actions'
import { DeselectAllFiltersAction, EditFilterPresetAction, SaveReportFilterListAction } from '@app/store/report/report.actions'
import { calibrationPresetList } from '@app/store/report/report.selectors'
import {
    workOrderLocation, workOrderMaintenancePlant, workOrderMainWorkCenter, workOrderPlannerGroup, workOrderPlantSection
} from '@app/store/work-order/work-order.selectors'
import { deepCopy, safeCallback } from '@app/utils/app-utils.function'

@Component({
    selector: 'app-report-combined-filter',
    templateUrl: './report-combined-filter.component.html',
    styleUrls: ['./report-combined-filter.component.scss']
})
export class ReportCombinedFilterComponent extends SafeUnsubscriberComponent implements OnInit {
    @Input() query: ReportListQueryParameter
    @Input() reportFilters: ReportFilterFields
    @Input() clearFilterCallback: (...args: any) => any | void
    @Input() selectedFilterCallback: (...args: any) => any | void
    @Output() triggerGetReports = new EventEmitter<ChildFiltersUnderPreset>(null)

    public workOrderFilters = deepCopy(defaultWorkOrderFilters)
    public presets: CalibrationPresets[] = []

    constructor(
        private store: Store<AppState>,
        private modalContainerService: ModalContainerService
    ) {
        super()
        this.modalContainerService.registerComponent(ReportFilterPresetModalComponent, ModalType.ReportNewFilterPreset)
    }

    get workOrderFilterKeys(): string[] {
        return Object.keys(this.workOrderFilters)
    }

    public get selectedFilters(): boolean {
        return this.presets.some(item => item.selected)
    }

    public get isFiltersChanged(): boolean {
        const selectedItem = this.presets.find(item => item.selected)
        return this.selectedFilters && (
            !isEqual(selectedItem.childFilter.reportFilters, this.reportFilters) ||
            !isEqual(filterQueryFields(selectedItem.childFilter.queryParams), filterQueryFields(this.query))
        )
    }

    public get saveButtonEnable(): boolean {
        return !this.selectedFilters || this.isFiltersChanged
    }

    ngOnInit(): void {
        this.addSubscription(
            this.store.select(workOrderMainWorkCenter).pipe(
                switchMap(options => {
                        this.workOrderFilters.mainWorkCenter.value = deepCopy(options)
                        return this.store.select(workOrderPlannerGroup)
                    }
                ))
                .pipe(
                    switchMap(options => {
                        this.workOrderFilters.plannerGroup.value = deepCopy(options)
                        return this.store.select(workOrderPlantSection)
                    }),
                    switchMap(options => {
                        this.workOrderFilters.plantSection.value = deepCopy(options)
                        return this.store.select(workOrderMaintenancePlant)
                    }),
                    switchMap(options => {
                        this.workOrderFilters.maintenancePlant.value = deepCopy(options)
                        return this.store.select(workOrderLocation)
                    }),
                    switchMap(options => {
                        this.workOrderFilters.location.value = deepCopy(options)
                        return this.store.select(equipmentABCIndicatorFilterOptions)
                    }),
                    switchMap(options => {
                        this.workOrderFilters.criticality.value = deepCopy(options)
                        return this.store.select(equipmentCategoryFilterOptions)
                    }),
                    switchMap(options => {
                        this.workOrderFilters.category.value = deepCopy(options)
                        return this.store.select(equipmentClassificationFilterOptions)
                    }),
                    switchMap(options => {
                        this.workOrderFilters.classification.value = deepCopy(options)
                        return this.store.select(calibrationPresetList)
                    })
                )
                .subscribe(options => {
                    this.presets = options
                    // apply default preset
                    this.onPresetOptionChange(this.presets.findIndex(item => item.selected))
                })
        )
    }


    public isEquipmentFilterSelected(filterName: string): boolean {
        const filterField = this.workOrderFilters[filterName] as { value: any[] }
        return filterField.value.some(f => f?.selected)
    }

    public clearAllFilters(): void {
        Object.keys(this.workOrderFilters).forEach(key => {
            this.workOrderFilters[key].value =
                this.workOrderFilters[key].value.map(f => ({ ...f, selected: false }))
        })
        safeCallback(this.clearFilterCallback)
        this.store.dispatch(new DeselectAllFiltersAction())
    }

    public saveFilter(): void {
        if (this.selectedFilters) {
            this.store.dispatch(new EditFilterPresetAction({ id: this.presets.find(item => item.selected).id }))
        } else {
            this.saveAsNewFilter()
        }
    }

    public saveAsNewFilter(): void {
        this.store.dispatch(new ShowModalAction(reportFilterNewModal))
    }

    public onPresetOptionChange(index: number): void {
        if (index === -1) {
            return
        }
        const { childFilter } = this.presets[index]
        this.query = deepCopy(childFilter.queryParams)
        this.applyFilterStateFromQuery()
        this.store.dispatch(new SaveReportFilterListAction(childFilter.reportFilters))
        this.getReportList(deepCopy(childFilter.reportFilters))
    }

    public getReportList(reportFilterList?: ReportFilterFields): void {
        this.triggerGetReports.next({ workOrderFilters: this.workOrderFilters, reportFilters: reportFilterList })
    }

    private applyFilterStateFromQuery(): void {
        const eqf = this.workOrderFilters
        const queryStateList = [
            { name: 'criticality', hasQuery: this.query?.criticality, hasOption: eqf.criticality.value.length > 0 },
            { name: 'plannerGroup', hasQuery: this.query?.plannerGroup, hasOption: eqf.plannerGroup.value.length > 0 },
            { name: 'mainWorkCenter', hasQuery: this.query?.mainWorkCenter, hasOption: eqf.mainWorkCenter.value.length > 0 },
            { name: 'maintenancePlant', hasQuery: this.query?.maintenancePlant, hasOption: eqf.maintenancePlant.value.length > 0 },
            { name: 'plantSection', hasQuery: this.query?.plantSection, hasOption: eqf.plantSection.value.length > 0 },
            { name: 'location', hasQuery: this.query?.location, hasOption: eqf.location.value.length > 0 },
            { name: 'category', hasQuery: this.query?.category, hasOption: eqf.category.value.length > 0 },
            { name: 'classification', hasQuery: this.query?.classification, hasOption: eqf.classification.value.length > 0 }
        ]

        for (const state of queryStateList) {
            if (!state.hasOption) {
                continue
            }

            if (state.hasQuery) {
                const params = this.query[state.name].split(',')
                this.workOrderFilters[state.name].value = eqf[state.name].value.map(option => {
                    const optionFound = params.some(p => p === option.name)
                    if (optionFound) {
                        return {
                            ...option,
                            selected: true
                        }
                    } else {
                        return {
                            ...option,
                            selected: false
                        }
                    }
                })
            } else {
                this.workOrderFilters[state.name].value = eqf[state.name].value.map(item => ({
                    ...item,
                    selected: false
                }))
            }

        }
    }

}
