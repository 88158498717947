import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'

import { SharedModule } from '../shared/shared.module'
import { HeaderMobileComponent } from './components/header/header-mobile/header-mobile.component'
import { HeaderNavigationComponent } from './components/header/header-navigation/header-navigation.component'
import { HeaderStatusComponent } from './components/header/header-status/header-status.component'
import { SyncStatusListComponent } from './components/header/header-status/sync-status-list/sync-status-list.component'
import { HeaderComponent } from './components/header/header.component'
import { NotfoundComponent } from './components/notfound/notfound.component'
import { OnlineRestrictedComponent } from './components/online-restricted/online-restricted.component'
import { PlantSelectionComponent } from './components/plant-selection/plant-selection.component'
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component'

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule
    ],
    declarations: [
        HeaderStatusComponent,
        HeaderNavigationComponent,
        HeaderMobileComponent,
        HeaderComponent,
        PlantSelectionComponent,
        UnauthorizedComponent,
        OnlineRestrictedComponent,
        NotfoundComponent,
        SyncStatusListComponent
    ],
    exports: [
        HeaderComponent,
    ]
})
export class CoreModule { }
