<div class="res-table"
     [ngStyle]="{
        'grid-template-columns': gridTemplateColumns
    }"
     [ngClass]="[
            'desktop-align-' + desktopRowContentAlign,
            'desktop-gap-' + desktopTableGap
        ]">
    <ng-content></ng-content>
</div>
