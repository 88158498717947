import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
    selector: 'app-expandable-section',
    templateUrl: './expandable-section.component.html',
    styleUrls: ['./expandable-section.component.scss']
})
export class ExpandableSectionComponent {

    @Input() sectionTitle: string
    @Input() isSectionExpanded = false
    @Input() isReport = false
    @Input() toggleOnAdd = false
    @Output() isSectionExpandedChange = new EventEmitter<boolean>()
    @Output() addEvent = new EventEmitter<any>()

    public toggleSection(): void {
        this.isSectionExpanded = !this.isSectionExpanded
        this.isSectionExpandedChange.emit(this.isSectionExpanded)
    }

    public handleAddClick(): void {
        this.addEvent.emit()
    }
}
