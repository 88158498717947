import { NgModule } from '@angular/core'

import { environment } from '@environments/environment'
import { WebComponentLoadStatusService } from './services/web-component-load-status.service'

// These cannot be inside @NgModule class as that can get created multiple times
const loadedComponents: { [key: string]: false | 'cdn' | 'npm' } = {}
const loadScriptFromURI = (src: string, onload: () => void, onerror: () => void) => {
    const scriptTag = document.createElement('script')
    scriptTag.type = 'text/javascript'
    scriptTag.src = `${src}?cachebust=${new Date()}`
    scriptTag.onload = onload
    scriptTag.onerror = onerror

    document.getElementsByTagName('head')[0].appendChild(scriptTag)
}
const loadComponent = (name: string, loader: () => void) => {
    if (environment.features[name]) {
        const webComponentsEnv = environment['webComponents']
        const config = webComponentsEnv?.[name]
        if (config?.loadFromCDN) {
            if (!config.src) {
                console.warn(`No CDN src found for ${name}`)
            }
            loadScriptFromURI(config.src,
                () => { loadedComponents[name] = 'cdn' },
                () => {
                    if (environment.development) {
                        console.warn(`Unable to import ${name} from ${config.src} `)
                    }
                    loadedComponents[name] = false
                }
            )
        } else {
            try {
                loader()
                loadedComponents[name] = 'npm'
            } catch (e) {
                if (environment.development) {
                    console.warn(`Unable to import ${name} from node_modules`)
                }
                loadedComponents[name] = false
            }
        }
    } else {
        loadedComponents[name] = false
        if (environment.development) {
            console.warn(`Skipping import for ${name} due to feature-toggle.`)
        }
    }
}

{
    // ================================================================
    // Component loading start here
    // ================================================================
    // - ES6 `import()` cannot be used as it block compilation if module is not found
    // - NPM package name **cannot** be a variable otherwise Webpack will not be able to find it (so we cannot loop through an array).
    loadComponent('flamingo-viewer', () => {
        require('@exxonmobil/flamingo-viewer')
    })
    loadComponent('flamingo-builder', () => {
        require('@exxonmobil/flamingo-builder')
    })
}

@NgModule()
export class WebComponentModule {
    constructor(private webComponentLoadStatusService: WebComponentLoadStatusService) {
        this.webComponentLoadStatusService.loadedWebComponents$.next(loadedComponents)
    }
}
