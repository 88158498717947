<app-calibration-template-header [template]="template"
                                 [templateType]="templateType"></app-calibration-template-header>

<section *ngIf="!isOnMobileOrTabletDevice">
    <div class="em-l-grid em-l-grid--4up header-row">
        <div class="em-l-grid__item">
            <app-label [title]="'Input'"
                       [subtitle]="' ('+template.input.unitOfMeasurement.uomCodeForTech+')'">
            </app-label>
        </div>
        <div class="em-l-grid__item">
            <app-label [title]="'Expected'"
                       [subtitle]="' ('+template.input.unitOfMeasurement.uomCodeForTech+')'">
            </app-label>
        </div>
        <div class="em-l-grid__item">
            <app-label [title]="'As Found'"
                       [subtitle]="' ('+template.input.unitOfMeasurement.uomCodeForTech+')'">
            </app-label>
        </div>
        <div class="em-l-grid__item">
            <app-label [title]="'As Left'"
                       [subtitle]="' ('+template.input.unitOfMeasurement.uomCodeForTech+')'">
            </app-label>
        </div>
    </div>

    <div class="em-l-grid em-l-grid--4up result-row"
         *ngFor="let row of getResultSetControl().controls">
        <div class="em-l-grid__item">
            <div class="em-c-field__label mobile-label"
                 *ngIf="row.get('pointNumber').value===1">Input ({{template.input.unitOfMeasurement.uomCodeForTech}}) </div>
            <div class="em-c-field__body grid-value"
                 *ngIf="row.get('pointNumber').value===1">{{template.input.maximumRange}}</div>
        </div>
        <div class="em-l-grid__item">
            <div class="em-c-field__label mobile-label"
                 *ngIf="row.get('pointNumber').value===1">Expected ({{template.input.unitOfMeasurement.uomCodeForTech}})</div>
            <div class="em-c-field__body grid-value"
                 *ngIf="row.get('pointNumber').value===1">
                {{ expectedTolerance }}
            </div>
        </div>
        <div class="em-l-grid__item">
            <div class="em-c-field__label mobile-label">As Found ({{template.input.unitOfMeasurement.uomCodeForTech}})</div>
            <app-label [classList]="isDisabled ? 'em-is-disabled' : ''">
                <app-numeric-input appInputValidator
                                   [appCalibrationInputReport]="isReport"
                                   placeholder="0"
                                   [formControl]="row.get('asFound')"
                                   [input]="inputValidation(row.get('asFound').value)"
                                   (inputBlur)="autoPopulateAsLeft()"
                                   tabIndex="{{ row.get('pointNumber').value }}">
                </app-numeric-input>
            </app-label>
        </div>
        <div class="em-l-grid__item">
            <div class="em-c-field__label mobile-label">As Left ({{template.input.unitOfMeasurement.uomCodeForTech}})</div>
            <app-label [classList]="isDisabled ? 'em-is-disabled' : ''">
                <app-numeric-input appInputValidator
                                   [appCalibrationInputReport]="isReport"
                                   placeholder="0"
                                   [formControl]="row.get('asLeft')"
                                   [input]="inputValidation(row.get('asLeft').value)"
                                   tabIndex="{{ row.get('pointNumber').value + template.numberOfPoint }}">
                </app-numeric-input>
            </app-label>
        </div>
    </div>
</section>

<section *ngIf="isOnMobileOrTabletDevice"
         class="no-line">
    <app-section [title]="'As Found (' + template.input.unitOfMeasurement.uomCodeForTech + ')'">
        <div body
             class="responsive-spacing">
            <div *ngFor="let row of getResultSetControl().controls; let i = index">
                <app-responsive-calibration-input [allowReading]="true"
                                                  [displayInputLabel]="'Reading ' + (i + 1)"
                                                  [displayResultLabel]="'Expected ' + expectedTolerance">
                    <div readingInput>
                        <app-numeric-input appInputValidator
                                           [appCalibrationInputReport]="isReport"
                                           [formControl]="row.get('asFound')"
                                           [input]="inputValidation(row.get('asFound').value)"
                                           (inputBlur)="autoPopulateAsLeft()"
                                           placeholder="0.0">
                        </app-numeric-input>
                    </div>
                </app-responsive-calibration-input>
            </div>
        </div>
    </app-section>
    <app-section [title]="'As Left (' + template.input.unitOfMeasurement.uomCodeForTech + ')'">
        <div body
             class="responsive-spacing">
            <div *ngFor="let row of getResultSetControl().controls; let i = index">
                <app-responsive-calibration-input [allowReading]="true"
                                                  [displayInputLabel]="'Reading ' + (i + 1)"
                                                  [displayResultLabel]="'Expected ' + expectedTolerance">
                    <div readingInput>
                        <app-numeric-input appInputValidator
                                           [appCalibrationInputReport]="isReport"
                                           [formControl]="row.get('asLeft')"
                                           [input]="inputValidation(row.get('asLeft').value)"
                                           (inputBlur)="autoPopulateAsLeft()"
                                           placeholder="0.0">
                        </app-numeric-input>
                    </div>
                </app-responsive-calibration-input>
            </div>
        </div>
    </app-section>
</section>
