<div class="em-c-collapsible-toolbar">
    <div class="em-c-toolbar">
        <div class="select-filter">
            <app-dropdown-filter class="filter-option"
                                 width="100%"
                                 padding="0.86rem 0.6rem"
                                 data-cy="preset-selection"
                                 [options]="presets"
                                 [childFilterChanged]="isFiltersChanged"
                                 (optionChange)="onPresetOptionChange($event)"></app-dropdown-filter>
        </div>
        <div class="line"></div>
        <div class="filter-section">
            <ng-content></ng-content>
            <app-dropdown-checkbox
                *ngFor="let key of workOrderFilterKeys"
                width="100%"
                padding="0.86rem 0.6rem"
                [options]="workOrderFilters[key].value"
                [title]="workOrderFilters[key].name"
                [callback]="getReportList.bind(this)"
                [grayOutText]="!isEquipmentFilterSelected(key)"
                [showSearchBox]="true"
                [scrollableBox]="true"
                [showClearBtn]="true"
                [showSelected]="true"></app-dropdown-checkbox>
            <div class="clear-filter-section">
                <p (click)="clearAllFilters()"
                   data-cy="preset-clear-all-selections-button">
                    <svg class="em-c-icon em-c-icon--small em-c-btn__icon em-c-btn__icon-only">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink"
                             xlink:href="./assets/unity-1.2.0/images/em-icons.svg#x-filled"></use>
                    </svg>
                    Clear All Selection
                </p>
            </div>
            <button *ngIf="selectedFilters"
                    data-cy="preset-save-as-button"
                    class="em-c-btn save-as-btn"
                    (click)="saveAsNewFilter()">
                Save As
            </button>
            <button class="em-c-btn em-c-btn--primary save-btn"
                    data-cy="preset-save-button"
                    (click)="saveFilter()"
                    [ngClass]="{'em-c-btn--disabled': !saveButtonEnable}"
                    [disabled]="!saveButtonEnable">{{!saveButtonEnable ? 'Saved' : 'Save'}}</button>
        </div>
    </div>
</div>
