<div [ngClass]="{ 'em-is-active': isOpen }"
     class="em-c-dropdown-check dropdown-check">
    <button (click)="toggleDropdown()"
            class="em-c-btn em-c-btn--small em-c-btn--is-dropdown dropdown-trigger">
        <div class="em-c-btn__inner">
            <span class="em-c-btn__text">{{ title }}</span>
            <svg class="em-c-icon em-c-icon--small em-c-btn__icon em-c-btn__icon-only">
                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="./assets/unity-1.2.0/images/em-icons.svg#caret-down"></use>
            </svg>
        </div>
    </button>
    <div class="em-c-dropdown-check__panel">
        <ul class="em-c-dropdown-check__list">
            <li *ngFor="let option of options; let i = index"
                (click)="callback(option.sortField)">
                <app-sorting-caret iconSize="small"
                                   [isSorted]="sortingState(option.sortField)"
                                   [isDescending]="decendingState(option.sortField)"></app-sorting-caret>
                <span class="em-c-input-group__text">{{option.name}}</span>
            </li>
        </ul>
    </div>
</div>
