import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

import { mrmaStatus } from '@app/modules/shared/models/mrma-status-label.model'
import { isNotAValue } from '@app/utils/app-utils.function'

export const reasonRequiredWhenDeferNotTest: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const finalPMResultStatus = control.get('finalPMResultStatus')?.value
    const reasonForNotComplete = control.get('reasonForNotComplete')?.value

    return (finalPMResultStatus?.id === mrmaStatus.resultStatus.deferred.id ||
        finalPMResultStatus?.id === mrmaStatus.resultStatus.notTested.id) &&
        isNotAValue(reasonForNotComplete) ?
        { invalid: true }
        : null
}
