import { Injectable, Type } from '@angular/core'

import {
    CustomFormDropdownComponent
} from '@app/modules/mrma-ui-components/controls/form-builder/custom-form-dropdown/custom-form-dropdown.component'
import { CustomFormInputComponent } from '@app/modules/mrma-ui-components/controls/form-builder/custom-form-input/custom-form-input.component'
import {
    CustomFormTextareaComponent
} from '@app/modules/mrma-ui-components/controls/form-builder/custom-form-textarea/custom-form-textarea.component'
import { CustomFormElementEnum } from '../models/custom-form-element.enum'

@Injectable({
    providedIn: 'root'
})
export class CustomFormElementService {
    private elementRegistry = {}

    constructor() {
        this.elementRegistry[CustomFormElementEnum.dropdown] = CustomFormDropdownComponent
        this.elementRegistry[CustomFormElementEnum.input] = CustomFormInputComponent
        this.elementRegistry[CustomFormElementEnum.textarea] = CustomFormTextareaComponent
    }

    public getComponent(elementType: CustomFormElementEnum): Type<{}> {
        if (!this.elementRegistry[elementType]) {
            throw new Error(`Element type of ${elementType} can not be found!`)
        }

        return this.elementRegistry[elementType]
    }
}
