import { Component, EventEmitter, Input, Output } from '@angular/core'

import { EquipmentStackSortingCaret } from '../../models/equipment-stack-sorting-state.model'

@Component({
    selector: 'app-equipment-sort-stack',
    templateUrl: './equipment-sort-stack.component.html',
    styleUrls: ['./equipment-sort-stack.component.scss']
})
export class EquipmentSortStackComponent {
    @Input() applySorting: (column: string) => void
    @Input() getDescendingState: (column: string) => boolean
    @Input() getSortedState: (column: string) => boolean

    @Output() sortingState = new EventEmitter<EquipmentStackSortingCaret>()

    public applySort(column: string): void {
        this.applySorting(column)
        this.sortingState.emit({
            isSorted: this.getSortedState(column),
            isDescending: this.getDescendingState(column),
            sortBy: column
        })
    }
}
