import { Component, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
    selector: 'app-admin-form-row',
    templateUrl: './admin-form-row.component.html',
    styleUrls: ['./admin-form-row.component.scss']
})
export class AdminFormRowComponent {
    @Input() label: string
    @Input() description: string
    @Input() formGroup: FormGroup
}
