import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core'

import { getIcon } from '@app/modules/calibration/constants/directive-html.icon'
import { CalibrationValidationStatus } from '@app/modules/calibration/models/calibration-validation-status.enum'
import { generateId } from '@app/utils/app-utils.function'

@Directive({
    selector: '[appInputValidator]'
})
export class InputValidatorDirective implements OnChanges, AfterViewInit {
    @Input() input: number
    private id: string

    private previousError: number

    // FIXME: This needs to use Renderer2 (see: https://angular.io/api/core/ElementRef)
    constructor(private el: ElementRef) {
        this.id = generateId(5)
    }

    ngAfterViewInit(): void {
        const parentNode = this.el.nativeElement.parentNode as HTMLElement
        parentNode.style.position = 'relative'
        this.el.nativeElement.insertAdjacentHTML('afterend',
            getIcon(this.id, 'circle-check', 'em-c-icon--green')
        )

        this.removeIcon()
        this.display(this.input)
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.display(Number(changes.input.currentValue))

    }

    private display(error: number): void {

        if (error === this.previousError) {
            return
        }

        const svgElement = document.getElementById(this.id)
        const useElement = document.getElementById('use-' + this.id)

        // In some cases, the function is called before SVG finished init
        if (!svgElement || !useElement) {
            return
        }

        // If we save `previousError` before SVG finished init, it will
        // not get proper style afterward as the above check will block it
        this.previousError = error

        switch (error) {
            case CalibrationValidationStatus.Initialize:
                this.applyInitializeStyle()
                break
            case CalibrationValidationStatus.Valid:
                this.applyValidStyle()
                break
            case CalibrationValidationStatus.Invalid:
                this.applyInvalidStyle()
                break
            default:
                this.applyInitializeStyle()
                break
        }
    }

    private applyInitializeStyle(): void {
        this.el.nativeElement.classList.remove('green-box', 'red-box')
        this.removeIcon()
    }

    private applyValidStyle(): void {
        this.el.nativeElement.classList.add('green-box')
        this.el.nativeElement.classList.remove('red-box')
        this.removeIcon()
        this.showIcon('assets/unity-1.2.0/images/em-icons.svg#circle-check', 'em-c-icon--green')
    }

    private applyInvalidStyle(): void {
        this.el.nativeElement.classList.add('red-box')
        this.el.nativeElement.classList.remove('green-box')
        this.removeIcon()
        this.showIcon('assets/unity-1.2.0/images/em-icons.svg#warning', 'em-c-icon--red')
    }

    private removeIcon(): void {
        const svgElement = document.getElementById(this.id)
        if (svgElement) {
            document.getElementById(this.id).classList.remove('em-c-icon--red', 'em-c-icon--green', 'em-u-is-hidden')
            document.getElementById(this.id).classList.add('em-u-is-hidden')
        }
    }

    private showIcon(iconPath: string, className: string): void {
        const svgElement = document.getElementById(this.id)
        const useElement = document.getElementById('use-' + this.id)

        if (svgElement) {
            svgElement.classList.remove('em-u-is-hidden')
            svgElement.classList.add(className)
        }

        if (useElement) {
            useElement.setAttribute('xlink:href', iconPath)
        }
    }
}
