export const environment = {
    version: require('../../package.json').version,
    authTimeout: 30000, // ms
    debug: {
        logLevel: '',
        logCDTime: false,
        enableClassProfiler: false,
        enableFnProfiler: false
    },
    appInsights: {
        config: {
            instrumentationKey: '54531966-7269-45fa-89b6-eaa089c4df65',
            autoTrackPageVisitTime: true,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            correlationHeaderExcludedDomains: [
                '*datadoghq.com'
            ]
        }
    }
}
