<!--
    TODO: Setup webpack to share SASS Variable with TypeScript so we can abstract the color code from here
    TODO: Add *Required text and logic
    TODO: Handle special case for `null`
-->
<app-calibration-result-selector [formControl]="calibrationForm.get('results.finalPMResultStatus')"
                                 [useBlack]="useBlack"
                                 [customColor]="shouldShowWarning ? '#ff9d0a' : ''"
                                 [isDisabled]="isDisabled"
                                 (selectionChange)="selectionChange()"></app-calibration-result-selector>

<div class="message-area"
     *ngIf="shouldShowWarning">
    <p class="message">You are manually overriding <b>PM Result</b>.
        <ng-container *ngIf="calibrationForm.get('results.calibrationResultStatus').value?.name as resultStatusName else moreInfoText">
            The original result was <b>{{resultStatusName}} </b>
        </ng-container>
        <ng-template #moreInfoText>
            More info is required to calculate PM Result.
        </ng-template>
        <span class="action"
              *ngIf="!isDisabled"
              (click)="revertResult()">(↩ Revert)</span>
    </p>
</div>
