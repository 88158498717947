import { Component, OnInit } from '@angular/core'

import { AbstractCalibrationTemplateComponent } from '../abstract-calibration-template.component'

@Component({
    selector: 'app-pass-fail-check',
    templateUrl: './pass-fail-check.component.html',
    styleUrls: ['./pass-fail-check.component.scss']
})
export class PassFailCheckComponent extends AbstractCalibrationTemplateComponent implements OnInit {

    public get templateWillCalculateCalibrationResultStatus(): boolean {
        return false
    }

    // Pass/Fail have no Calibration Result section to show...
    public get templateWillNotRenderCalibrationResultsCard(): boolean {
        return true
    }

    ngOnInit(): void {
        super.ngOnInit()
    }

    protected updateCalibrationResultStatus(): void {
        // No custom update to be made. Calibration result must be
        // manually selected by CalibrationResultSelectorComponent
        return
    }
}
