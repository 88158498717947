<!-- The :host position is relative -->
<!-- the `pattern` is here to let mobile know to show numpad -->

<!-- wrap inside a div to position popup box below it -->
<div class="input-box-wrapper">
    <input type="text"
           inputmode="decimal"
           class="input-box"
           [ngClass]="injectedClass + (errorMessage && ' error') + (warningMessage && ' warning')"
           [ngStyle]="styles"
           [placeholder]="placeholder"
           (input)="onInput($event)"
           (change)="onInputChange($event)"
           (focus)="onFocus($event)"
           (blur)="onBlur($event)"
           (paste)="onPaste($event)"
           [disabled]="disabled"
           [tabindex]="tabIndex"
           pattern="^([-+,0-9.]+)"
           [attr.data-testid]="inputId"
           #inputBox />
    <div class="msg-popup"
         *ngIf="this.popupController.shouldDisplayPopup()"
         [@fadeInOut]>
        <svg class="em-c-icon em-c-icon--medium">
            <use xmlns:xlink="http://www.w3.org/1999/xlink"
                 xlink:href="./assets/unity-1.2.0/images/em-icons.svg#circle-exclamation-mark-filled"></use>
        </svg>
        <div class="msg">
            <span>
                {{popupController.getMessage()}}
            </span>
        </div>
    </div>
</div>

<div class="msg-box"
     [ngClass]="{'msg-box--absolute': useAbsolutePositionMessages}">
    <span *ngIf="errorMessage"
          class="msg msg--error">{{ errorMessage }}</span>
    <span *ngIf="warningMessage"
          class="msg msg--warning">{{ warningMessage }}</span>
    <span *ngIf="normalMessage"
          class="msg msg--normal">{{ normalMessage }}</span>
</div>
