import { Component, Input } from '@angular/core'
import { FormControl } from '@angular/forms'

import { CustomFormDropdown } from '@app/modules/calibration/components/custom-form/models/custom-form.model'

@Component({
    selector: 'app-custom-form-dropdown',
    templateUrl: './custom-form-dropdown.component.html',
    styleUrls: ['./custom-form-dropdown.component.scss']
})
export class CustomFormDropdownComponent {
    @Input() formDetail: CustomFormDropdown
    @Input() formControl = new FormControl(null)
    @Input() isReport: boolean
}
