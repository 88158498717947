import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { FormControl } from '@angular/forms'

@Component({
    selector: 'app-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnChanges {

    @Input() control: FormControl
    @Input() isDisabled = false

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isDisabled) {
            if (changes.isDisabled.currentValue) {
                this.control.disable({ emitEvent: false })
            } else {
                this.control.enable({ emitEvent: false })
            }
        }
    }

}
