import { Injectable } from '@angular/core'
import { AbstractControl } from '@angular/forms'
import { ToastrService } from 'ngx-toastr'

import { mrmaAlertConfigs } from '@app/models/alert-configuration.model'
import { FormService } from '@app/modules/shared/services/form.service'
import { DefaultErrorHanlder } from '@app/utils/classes/form-error-handlers/default-error-handler'
import { RequiredErrorHandler } from '@app/utils/classes/form-error-handlers/required-error-handler'
import { SpecialControlRequiredErrorHandler } from '@app/utils/classes/form-error-handlers/special-control-required-error-handler'
import { ValueOutOfRangeErrorHandler } from '@app/utils/classes/form-error-handlers/value-out-of-range-error-handler'
import { FormErrorValidator } from '@app/utils/classes/form-error-validator'
import { GeneralAfAlErrorHandler } from '@app/utils/classes/form-error-handlers/general-af-al-error-handler'

/**
 * Manage and provide utility functions for CalibrationFormComponent
 */
@Injectable()
export class CalibrationFormComponentService {

    constructor(
        private toastrService: ToastrService,
        private formService: FormService
    ) {
    }

    public canCalibrationComplete(calibrationForm: AbstractControl, showToastMessage = true): boolean {
        if (calibrationForm.valid) {
            return true
        }
        const errors = this.formService.getErrorsRecursive(calibrationForm)
        let preventSubmit = false
        const errorMessages = []

        for (const error of errors) {
            const result = new FormErrorValidator()
                .use(new ValueOutOfRangeErrorHandler())
                .use(new SpecialControlRequiredErrorHandler())
                .use(new RequiredErrorHandler())
                .use(new GeneralAfAlErrorHandler())
                .use(new DefaultErrorHanlder())
                .handleError(error)

            // If ANY error result preventSubmit, set form level preventSubmit to true as well.
            if (result.preventSubmit) {
                preventSubmit = true
            }
            if (result.errorMessages) {
                errorMessages.push(...result.errorMessages)
            }
        }

        if (showToastMessage) {
            errorMessages.forEach(message => {
                this.toastrService.error(message, '', mrmaAlertConfigs.Validation.configuration)
            })
        }

        if (preventSubmit) {
            return false
        }

        return true
    }
}
