<!-- This is needed as we cannot have [formArray] wihtout a parent [formGroup] -->
<ng-container [formGroup]="resultsFormArray?.controls[0]">

    <app-res-section-header headerTitle="Set Point"></app-res-section-header>
    <div class="setpoint-header">
        <app-res-table [headerColumnWidth]="'20%'"
                       desktopRowContentAlign="center">
            <div class="em-c-field__label responsive-lg align-start">
                <span class="index">#</span>
                Set Point Name
            </div>
            <div class="em-c-field__label setpoint-header-col responsive-lg">
                Set Point
            </div>
            <div class="em-c-field__label setpoint-header-col responsive-lg">
                Tolerance
            </div>
            <div class="em-c-field__label setpoint-header-col responsive-lg">
                Expected
            </div>
            <div class="em-c-field__label setpoint-header-col responsive-lg">
                As Found
            </div>
            <div class="em-c-field__label setpoint-header-col responsive-lg">
                As Left
            </div>
        </app-res-table>
    </div>

    <div [formArrayName]="'resultSet'">
        <ng-container *ngFor="let row of getResultSetFormArray()?.controls; let i = index"
                      [formGroupName]="i">

            <!-- Setpoint Row -->
            <div [ngClass]=" !isOnMobileOrTabletDevice ? 'setpoint-row' :'setpoint--mobile' ">
                <app-res-table [headerColumnWidth]="'20%'"
                               desktopRowContentAlign="center">

                    <div class="calibration-element--header"
                         [ngClass]=" !isOnMobileOrTabletDevice ? 'header-row' :'header--mobile' "
                         appResTableNewRow>
                        <div class="index responsive-lg">{{ template.setPoints[i].pointNumber }}</div>
                        <div class="index--mobile responsive-sm">{{ "#"+template.setPoints[i].pointNumber }}</div>
                        <div class="setpoint-name">{{ template.setPoints[i].name }}</div>
                    </div>

                    <!-- not adjustable setpoint -->
                    <div *ngIf="!isSetPointAdjustable(i)">
                        <app-res-labeled-element class="responsive-sm"
                                                 labelText='Set Point'
                                                 labelStyle="bold">
                            {{ template.setPoints[i].setPoint.value }}
                            <span class="uom--mobile">{{ getUOM('setPoint', i) }}</span>
                        </app-res-labeled-element>

                        <div class="responsive-lg setpoint">
                            <div class="uom-val">
                                {{ template.setPoints[i].setPoint.value }}
                            </div>

                            <div class="uom-ext">
                                {{ getUOM('setPoint', i) }}
                            </div>
                        </div>
                    </div>

                    <!-- adjustable setpoint -->
                    <app-res-labeled-element *ngIf="isSetPointAdjustable(i)"
                                             labelText='Set Point'
                                             labelStyle="bold"
                                             spanLabelText="{{'('+getUOM('setPoint', i)+')'}}"
                                             [mobileLabelPosition]="'top'"
                                             [injectedContentStyles]="{'text-align':'right'}"
                                             [injectedElementStyles]="{'justify-content':'right',
                                             'margin': (!isOnMobileOrTabletDevice ? '0px':null)}">
                        <app-numeric-input appInputValidator
                                           [appCalibrationInputReport]="isReport"
                                           [formControlName]="'adjustedInjectedInput'"
                                           [decimalPlaces]="5"
                                           [textAlign]="'right'"
                                           [tabIndex]="i + 1"
                                           (inputBlur)="revalidateInputAtIndex(i, 'asFound') && revalidateInputAtIndex(i, 'asLeft')"
                                           placeholder="0.0"></app-numeric-input>
                        <span class="input-span-field-label responsive-lg">{{getUOM('setPoint', i) }}</span>
                    </app-res-labeled-element>

                    <!-- Tolerance -->
                    <div>
                        <app-res-labeled-element labelText='Tolerance'
                                                 labelStyle="bold"
                                                 class="responsive-sm">
                            {{ getToleranceRange(template.setPoints[i].tolerance) }}
                            <span class="uom--mobile">{{ getUOM('tolerance', i) }}</span>
                        </app-res-labeled-element>

                        <div class="responsive-lg setpoint-range">
                            <div class='uom-val'>
                                <div>
                                    {{ template.setPoints[i].tolerance.lowerRange }}
                                </div>
                                <div>
                                    {{ template.setPoints[i].tolerance.higherRange }}
                                </div>
                            </div>
                            <div class="to">
                                to
                            </div>
                            <div class="uom-ext">
                                {{ getUOM('tolerance', i) }}
                            </div>
                        </div>
                    </div>

                    <!-- ExpectedReading -->
                    <div>
                        <app-res-labeled-element labelText='Expected'
                                                 labelStyle="bold"
                                                 class="responsive-sm">
                            {{ getExpectedRange(i) }}
                            <span class="uom--mobile">{{ getExpectedUOM() }}</span>
                        </app-res-labeled-element>

                        <div class="responsive-lg setpoint-range">
                            <div class='uom-val'>
                                <div>
                                    {{ getExpectedRangeValue(i).minimumRange }}
                                </div>
                                <div>
                                    {{ getExpectedRangeValue(i).maximumRange }}
                                </div>
                            </div>
                            <div class="to">
                                to
                            </div>
                            <div class="uom-ext">
                                {{ getExpectedUOM() }}
                            </div>
                        </div>
                    </div>

                    <app-res-labeled-element labelText='As Found '
                                             spanLabelText="{{'('+getExpectedUOM()+')'}}"
                                             mobileLabelPosition="top"
                                             labelStyle="bold"
                                             [isReportAlignment]="true"
                                             [injectedElementStyles]="{'justify-content':'right',
                                             'margin': (!isOnMobileOrTabletDevice ? '0px':null)}">
                        <app-numeric-input appInputValidator
                                           [input]="storedValidationStatuses[i].asFound"
                                           [appCalibrationInputReport]="isReport"
                                           [formControlName]="'asFound'"
                                           (inputUpdate)="revalidateInputAtIndex(i, 'asFound')"
                                           [decimalPlaces]="5"
                                           [textAlign]="'right'"
                                           [tabIndex]="i + 2"
                                           (inputBlur)="autoPopulateAsLeft()"
                                           placeholder="0.0"></app-numeric-input>
                        <span class="input-span-field-label responsive-lg">{{ getExpectedUOM() }}</span>
                    </app-res-labeled-element>

                    <!-- The asLeft still need to be present on desktop so we cannot simply use ngIf -->
                    <app-res-labeled-element labelText='As Left '
                                             spanLabelText="{{'('+getExpectedUOM()+')'}}"
                                             mobileLabelPosition="top"
                                             mobileBorders="bottom"
                                             labelStyle="bold"
                                             [isReportAlignment]="true"
                                             [injectedElementStyles]="{'justify-content':'right',
                                             'margin': (!isOnMobileOrTabletDevice ? '0px':null)}">
                        <app-numeric-input appInputValidator
                                           [input]="storedValidationStatuses[i].asLeft"
                                           [appCalibrationInputReport]="isReport"
                                           [formControlName]="'asLeft'"
                                           (inputUpdate)="revalidateInputAtIndex(i, 'asLeft')"
                                           [decimalPlaces]="5"
                                           [textAlign]="'right'"
                                           [tabIndex]="template.numberOfPoint > 1 ? i + (template.numberOfPoint * template.numberOfPoint) : 3"
                                           placeholder="0.0"></app-numeric-input>
                        <span class="input-span-field-label responsive-lg">{{ getExpectedUOM() }}</span>
                    </app-res-labeled-element>

                </app-res-table>
            </div>

        </ng-container>
    </div>

</ng-container>
