export class PageComponentState {
    name: PageComponentName
    scrollPosition: number
    selectedPage: number
}

export enum PageComponentName {
    EquipmentList = 'Equipment list',
    ReportList = 'Report list'
}
