import { Component, Input, OnInit } from '@angular/core'
import { AbstractControl, FormGroup } from '@angular/forms'
import { Store } from '@ngrx/store'
import { get, isNil } from 'lodash'
import { map } from 'rxjs/operators'

import { CalibrationResultSet } from '@app/modules/calibration/models/calibration-result-set.model'
import { DropdownOption, noValueDropdownOption, SelectedOption } from '@app/modules/shared/components/dropdown/dropdown-no-form.component'
import { UOM } from '@app/modules/shared/models/engineering-units/unit-of-measurement.model'
import { AppState } from '@app/store/app.store'
import { uomList } from '@app/store/equipment/selectors/uom.selectors'
import { deepCopy } from '@app/utils/app-utils.function'
import { isGeneralAfAlResultValid } from '@app/utils/classes/form-error-handlers/general-af-al-error-handler'
import { mapUomObjToUomDisplay, uomListToUomOptions } from '@app/utils/uom/uom.util'

const generalAfAlResultSetName = 'Generic AF/AL'
const emptyGeneralAfAlResultSet = {
    pointNumber: 1,
    asFound: null,
    asLeft: null,
    injectedInput: null,
    adjustedInjectedInput: null,
    unitOfMeasurement: null
}

const emptyGeneralAfAlResult: CalibrationResultSet = {
    resultSetName: generalAfAlResultSetName,
    resultSet: [emptyGeneralAfAlResultSet]
}

@Component({
    selector: 'app-no-template[calibrationForm][isReport]',
    templateUrl: './no-template.component.html',
    styleUrls: ['./no-template.component.scss']
})
export class NoTemplateComponent implements OnInit {

    @Input() isReport = false
    @Input() editable = true

    public uomOptions: DropdownOption<UOM>[] = []
    public initAsFoundValue: number = null
    public initAsLeftValue: number = null
    public initUomOption: DropdownOption<UOM> = noValueDropdownOption

    private _calibrationForm: FormGroup

    constructor(private store: Store<AppState>) {
    }

    @Input()
    set calibrationForm(value: FormGroup) {
        this._calibrationForm = value
        this.initFieldsValue()
    }

    get calibrationForm(): FormGroup {
        return this._calibrationForm
    }

    get uomDisplayForReport(): string | null {
        if (isNil(this.initUomOption.value)) {
            return null
        }
        return mapUomObjToUomDisplay(this.initUomOption.value)
    }

    ngOnInit(): void {
        this.store.select(uomList).pipe(map(uomListToUomOptions)).subscribe(options => {
            this.uomOptions = options
            this.initFieldsValue()
        })
        this.initCalibrationResultFormValueAndValidator()
    }

    public onUomOptionChange(selectedUom: SelectedOption<UOM>): void {
        this.changeUomCodeInCalibrationResultInFromCtl(selectedUom.value)
    }

    public onAsFoundChange(asFoundReading: number | null): void {
        this.changeAsFoundInCalibrationResultInFromCtl(asFoundReading)
    }

    public onAsLeftChange(asLeftReading: number | null): void {
        this.changeAsLeftInCalibrationResultInFromCtl(asLeftReading)
    }

    private initFieldsValue(): void {
        const generalAfAlData = this.getGeneralAfAlData()
        if (!isNil(generalAfAlData)) {
            const data = generalAfAlData.resultSet[0]
            this.initAsLeftValue = data.asLeft
            this.initAsFoundValue = data.asFound
            const initOption = this.uomOptions.find(option => option.value.uomCode === data.unitOfMeasurement?.uomCode)
            this.initUomOption = initOption ? initOption : noValueDropdownOption
        } else {
            this.initAsLeftValue = null
            this.initAsFoundValue = null
            this.initUomOption = noValueDropdownOption
        }
    }

    private initCalibrationResultFormValueAndValidator(): void {
        const formControl = this.getCalibrationResultFromControl()
        const generalAfAlData = this.getGeneralAfAlData()
        if (isNil(generalAfAlData)) {
            formControl.setValue({
                results: [emptyGeneralAfAlResult]
            })
        }
        formControl.setValidators([isGeneralAfAlResultValid])
    }

    private changeAsFoundInCalibrationResultInFromCtl(value: number): void {
        const generalAfAlData = deepCopy(this.getGeneralAfAlData())
        if (!isNil(generalAfAlData)) {
            const data = generalAfAlData.resultSet[0]
            data.asFound = value
            this.calibrationForm.updateValueAndValidity()
            this.getCalibrationResultFromControl().setValue({ results: [generalAfAlData] })
        }
    }

    private changeAsLeftInCalibrationResultInFromCtl(value: number): void {
        const generalAfAlData = deepCopy(this.getGeneralAfAlData())
        if (!isNil(generalAfAlData)) {
            const data = generalAfAlData.resultSet[0]
            data.asLeft = value
            this.calibrationForm.updateValueAndValidity()
            this.getCalibrationResultFromControl().setValue({ results: [generalAfAlData] })
        }
    }

    private changeUomCodeInCalibrationResultInFromCtl(value: UOM): void {
        const generalAfAlData = deepCopy(this.getGeneralAfAlData())
        if (!isNil(generalAfAlData)) {
            const data = generalAfAlData.resultSet[0]
            data.unitOfMeasurement = value
            this.calibrationForm.updateValueAndValidity()
            this.getCalibrationResultFromControl().setValue({ results: [generalAfAlData] })
        }
    }

    private getCalibrationResultFromControl(): AbstractControl {
        return this.calibrationForm.get('results.calibrationResult')
    }

    private getGeneralAfAlData(): CalibrationResultSet | undefined {
        return get(
            this.getCalibrationResultFromControl()?.value,
            ['results']
        )?.find(res => res.resultSetName === generalAfAlResultSetName)
    }

}
