import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import { modalContent } from '@app/modules/modal-container/constants/modal-message-content.constant'
import { Modal } from '@app/modules/modal-container/models/modal.model'
import { AppState } from '@app/store/app.store'
import { ShowModalAction } from '@app/store/modal/modal.actions'
import { deepCopy } from '@app/utils/app-utils.function'

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(
        private store: Store<AppState>,
    ) { }

    public showNotificationPopup(url: string): void {
        const modal: Modal = deepCopy(modalContent.notificationFioriChapp)
        modal.confirmCallback = () => this.redirectUrl(url)
        this.store.dispatch(new ShowModalAction(modal))
    }

    private redirectUrl(url: string): void {
        const win = window.open(url)
        win.focus()
    }
}
