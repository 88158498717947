import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import { modalContent } from '@app/modules/modal-container/constants/modal-message-content.constant'
import { Modal } from '@app/modules/modal-container/models/modal.model'
import { WorkOrderDetails } from '@app/modules/work-order/models/work-order-details.model'
import { AppState } from '@app/store/app.store'
import { ReopenCalibration, ReopenNotification } from '@app/store/calibration/calibration.actions'
import { ShowModalAction } from '@app/store/modal/modal.actions'
import { deepCopy, safeCallback } from '@app/utils/app-utils.function'
import { CalibrationDetails } from '../models/calibration-details.model'
import { ReopenCalibrationMessageService } from './reopen-calibration-message.service'

@Injectable({
    providedIn: 'root'
})
export class ReopenCalibrationService {
    constructor(
        private store: Store<AppState>,
        private reopenCalibrationMessageService: ReopenCalibrationMessageService
    ) { }

    public confirmReopenCalibration(
        calibration: CalibrationDetails,
        workOrderDetails: WorkOrderDetails,
        closeCallback?: any,
        confirmCallback?: any,
    ): void {
        const message = this.reopenCalibrationMessageService.composeMessage(
            workOrderDetails?.lacd,
            workOrderDetails?.systemStatus
        )

        let modal: Modal
        modal = deepCopy(modalContent.reopenCalibration) as Modal
        modal.body = message
        modal.confirmCallback = () => {
            safeCallback(confirmCallback)
            this.store.dispatch(new ReopenCalibration(calibration.workOrderNumber, calibration.equipmentId, calibration))
        }
        modal.closeCallback = () => safeCallback(closeCallback)
        this.store.dispatch(new ShowModalAction(modal))
    }

    public confirmReopenNotification(
        workOrderNumber: string,
        equipmentId: string,
        calibration: CalibrationDetails): void {
        this.store.dispatch(new ReopenNotification(
            workOrderNumber,
            equipmentId,
            calibration
        ))
    }
}
