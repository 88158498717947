import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'

import { SectionLoaderHelperComponent } from '@app/modules/shared/components/section-loader/section-loader-helper-component'
import { SectionLoaderService } from '@app/modules/shared/services/section-loader.service'
import { AppState } from '@app/store/app.store'
import { dateToLocalISOString } from '@app/utils/date.utils'
import { modal } from '@app/store/modal/modal.selectors'
import { Modal } from '@app/modules/modal-container/models/modal.model'

@Component({
    selector: 'app-test-equipment-update-modal',
    templateUrl: './test-equipment-update-modal.component.html',
    styleUrls: ['./test-equipment-update-modal.component.scss']
})
export class TestEquipmentUpdateModalComponent extends SectionLoaderHelperComponent implements OnInit {
    public testEquipmentForm: FormGroup
    public options: any[] = [{ id: 1, name: 'Active' }, { id: 2, name: 'Inactive' }]
    public confirmCallbackParams

    constructor(private store: Store<AppState>, sectionLoaderService: SectionLoaderService, private formBuilder: FormBuilder) {
        super(sectionLoaderService)
    }

    ngOnInit(): void {
        this.setUpTestEquipmentForm()
    }

    private setUpTestEquipmentForm(): void {
        this.testEquipmentForm = this.formBuilder.group({
            status: this.formBuilder.control(null),
            certificationExpiryDate: new FormControl(undefined, Validators.required)
        })
        this.testEquipmentForm.valueChanges.subscribe(value => {
            this.confirmCallbackParams = {
                isActive: value.status.id === 1,
                certificationExpiryDate: dateToLocalISOString(new Date(value.certificationExpiryDate), true, true)
            }
        })
        this.addSubscription(
            this.store.select(modal).subscribe((modalState: Modal) => {
                if (modalState) {
                    this.confirmCallbackParams = {
                        isActive: modalState.params.isActive,
                        certificationExpiryDate: modalState.params.certificationExpiryDate
                    }
                    this.testEquipmentForm.setValue({
                        status: modalState.params.isActive ? this.options[0] : this.options[1],
                        certificationExpiryDate: modalState.params.certificationExpiryDate
                    }, { emitEvent: false })
                }
            })
        )
    }
}
