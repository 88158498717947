import { DOCUMENT } from '@angular/common'
import { AfterContentInit, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, ViewChild } from '@angular/core'
import { isFunction } from 'lodash'

export interface DropdownCheckboxOption {
    selected: boolean,
    name: string
}

@Component({
    selector: 'app-dropdown-checkbox',
    templateUrl: './dropdown-checkbox.component.html',
    styleUrls: ['./dropdown-checkbox.component.scss']
})

export class DropdownCheckboxComponent implements OnInit, AfterContentInit {
    @Input() title = ''
    @Input() options: DropdownCheckboxOption[]
    @Input() callback: () => any
    @Input() clearCallback: () => any
    @Input() width = 'auto'
    @Input() padding = '.375rem .75rem' // em-c-btn--small
    @Input() grayOutText = false
    @Input() showSearchBox = false
    @Input() scrollableBox = false
    @Input() showClearBtn = false
    @Input() showSelected = false
    @Input() additionalSelectedCount = 0
    @Output() optionChange: EventEmitter<any> = new EventEmitter<any>()
    @ViewChild('expandedArea') expandedArea: ElementRef<HTMLElement>

    public isOpen: boolean
    public hiddenOptions: any[]
    public componentId = ''


    constructor(
        private eRef: ElementRef,
        @Inject(DOCUMENT) private document: any
    ) {
        this.isOpen = false
    }

    public get selectedItemCount(): string {
        const selected = this.options?.filter(option => option.selected)?.length + this.additionalSelectedCount
        return this.showSelected && selected ? `(${selected})` : ''
    }

    public get styleObj(): object {
        return {
            width: this.width,
            padding: this.padding
        }
    }

    ngOnInit(): void {
        this.hiddenOptions = Array(this.options.length).fill(false)
    }

    ngAfterContentInit(): void {
        this.componentId = this.title
            .toLocaleLowerCase()
            .replace(/\s/g, '')
    }

    public onOptionChange(): void {
        if (typeof this.callback === 'function' && this.callback) {
            this.callback()
        }
    }

    public clearCheckbox(): void {
        this.options.map(option => option.selected = false)

        if (isFunction(this.clearCallback)) {
            this.clearCallback()
        } else if (isFunction(this.callback)) {
            this.callback()
        }
    }

    public applySearch(inputText: string): void {
        this.hiddenOptions = Array(this.options.length).fill(false)

        if (inputText) {
            const inputTextLower = inputText.toLowerCase()

            this.options.forEach((value, index) => {
                if (value.name.toLowerCase().indexOf(inputTextLower) <= -1) {
                    this.hiddenOptions[index] = true
                }
            })
        }
    }

    @HostListener('document:click', ['$event'])
    public toggleDropdown(globalEvent): void {
        if (this.eventTriggeredInsideHost(globalEvent)) {
            // click inside host then open/close dropdown
            if (this.options.length === 0) {
                return
            }
            // click expanded area shouldn't close dropdown
            if (this.expandedArea.nativeElement.contains(globalEvent.target)) {
                return
            }
            this.isOpen = !this.isOpen
        } else {
            // click outside host then close dropdown
            this.isOpen = false
            this.optionChange.next(this.options.filter(option => option.selected))
        }

    }

    private eventTriggeredInsideHost(event): boolean {
        const current = event.target
        const host = this.eRef.nativeElement
        return host.contains(current)
    }
}
