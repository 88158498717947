<section [ngClass]="{'hide-line': !showLineBreak}">
    <div *ngIf="!singleFieldColumn"
         class="section-responsive-col">
        <div *ngIf="!isAdjusted && notEmpty(displayInputLabel)"
             class="responsive-calibration-field-container">
            <div class="calibration-field-result res-column"
                 [ngClass]="{'layout-label': layoutSimple}">
                <label>{{ displayInputLabel }}</label>
                <p>{{ displayResultLabel }}</p>
            </div>
        </div>
        <div *ngIf="isAdjusted"
             class="responsive-calibration-field-container">
            <div class="calibration-field-result width75p-100p float-left">
                <label>{{ adjusted }}</label>
                <ng-content select="[adjustedInput]"></ng-content>
            </div>
        </div>
        <div *ngIf="allowReading"
             class="responsive-calibration-field-container">
            <div class="calibration-field-result width75p-100p"
                 [ngClass]="{'layout-input': layoutSimple}">
                <div class="calibration-label">
                    <label *ngIf="notEmpty(reading)">{{ reading }}</label>
                    <sub *ngIf="notEmpty(readingExpected)">{{ readingExpected }}</sub>
                </div>
                <ng-content select="[readingInput]"></ng-content>
            </div>
        </div>
    </div>
    <div *ngIf="singleFieldColumn"
         class="section-single-col">
        <div *ngIf="notEmpty(rowLabelHead)"
             class="responsive-calibration-field-container">
            <div class="row-label">
                <label>{{ rowLabelHead }}</label>
                <sub [ngClass]="averageValidationClass">{{ rowLabelResult }}</sub>
            </div>
        </div>
    </div>
</section>
