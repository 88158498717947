<app-calibration-template-header [template]="template"></app-calibration-template-header>
<section *ngIf="template.isInstrumentRequired">
    <app-result-set resultSetName="Instrument"
                    [template]="template"
                    [resultSetForm]="instrumentResultSetForm"
                    [hasInjectedInput]="false"
                    [isReport]="isReport"
                    [isCombinedToleranceAsFoundFailed]="isAsFoundFailed"
                    [isCombinedToleranceAsLeftFailed]="isAsLeftFailed"
                    [startingTabIndex]="0">
    </app-result-set>
</section>
<section>
    <app-result-set resultSetName="Sensing Element"
                    [template]="template"
                    [resultSetForm]="sensingElementResultSetForm"
                    [hasInjectedInput]="true"
                    [isReport]="isReport"
                    [isCombinedToleranceAsFoundFailed]="isAsFoundFailed"
                    [isCombinedToleranceAsLeftFailed]="isAsLeftFailed"
                    [startingTabIndex]="template.numberOfPoint*2">
    </app-result-set>
</section>
