import { Store } from '@ngrx/store'
import { Component, OnInit } from '@angular/core'

import { User } from '@app/models/user.model'
import { AppState } from '@app/store/app.store'
import { SelectUserAction } from '@app/store/user/user.actions'
import { SectionLoaderService } from '@app/modules/shared/services/section-loader.service'
import { SectionLoaderHelperComponent } from '@app/modules/shared/components/section-loader/section-loader-helper-component'
import { selectedUser } from '@app/store/user/user.selectors'

@Component({
    selector: 'app-user-update-modal',
    templateUrl: './user-update-modal.component.html',
    styleUrls: ['./user-update-modal.component.scss']
})
export class UserUpdateModalComponent extends SectionLoaderHelperComponent implements OnInit {
    public user: User

    constructor(private store: Store<AppState>, sectionLoaderService: SectionLoaderService) {
        super(sectionLoaderService)
    }

    ngOnInit(): void {
        this.addSubscription(this.store.select(selectedUser).subscribe(user => this.user = user))
    }

    public inputValueChange(date: Date): void {
        this.store.dispatch(new SelectUserAction({ ...this.user, certificationExpiryDate: date }))
    }
}
