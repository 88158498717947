<div class="wrapper" [ngClass]="{ 'wrapper-content-between': layout === 'content-between' }">
    <label class="em-c-field__label"
           [ngClass]="{'error-label': isError, 'label-vertical-center': labelVerticalCenter}">{{labelText}}</label>
    <div class="content"
         [ngClass]="{'border-error': isError, 'has-content-border': !excludeContentBorder}">
        <ng-content></ng-content>
    </div>
    <p *ngIf="isError"
       class="error-text">{{errorText}}</p>
</div>
