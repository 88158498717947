import { Component, Input } from '@angular/core'

type LayoutType  = 'centered' | 'content-between'

@Component({
    selector: 'app-left-labeled-sheet',
    templateUrl: './left-labeled-sheet.component.html',
    styleUrls: ['./left-labeled-sheet.component.scss']
})
export class LeftLabeledSheetComponent {
    @Input() labelText = 'Label'
    @Input() isError = false
    @Input() errorText = ''
    @Input() layout: LayoutType = 'centered'
    @Input() excludeContentBorder = false
    @Input() labelVerticalCenter = false
}
