import { animate, style, transition, trigger } from '@angular/animations'
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import _, { isEmpty, toLower } from 'lodash'
import { ToastrService } from 'ngx-toastr'
import { combineLatest } from 'rxjs'
import { map, take } from 'rxjs/operators'

import { mrmaAlertConfigs } from '@app/models/alert-configuration.model'
import { BackgroundSyncStatusEnum } from '@app/models/offline-status.enum'
import { CalibrationResultStatusService } from '@app/modules/calibration/services/calibration-result-status.service'
import { Equipment } from '@app/modules/equipment/models/equipment.model'
import { modalContent } from '@app/modules/modal-container/constants/modal-message-content.constant'
import { modalMessageBody } from '@app/modules/modal-container/constants/modal-message.constant'
import { Modal } from '@app/modules/modal-container/models/modal.model'
import { remindMessage } from '@app/modules/shared/constants/remind-message.constant'
import { EquipmentStackSortingCaret } from '@app/modules/shared/models/equipment-stack-sorting-state.model'
import { SortedState, SortingState } from '@app/modules/shared/models/filter.model'
import { EquipmentSortStackService } from '@app/modules/shared/services/equipment-sort-stack.service'
import { FilteringService } from '@app/modules/shared/services/filtering.service'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppMonitoringService } from '@app/services/app-monitoring.service'
import { IdentityService } from '@app/services/identity.service'
import { OfflineService } from '@app/services/offline.service'
import { AppState } from '@app/store/app.store'
import { ShowModalAction } from '@app/store/modal/modal.actions'
import { GetNotificationsAction } from '@app/store/work-order/work-order.actions'
import { deepCopy, sortArray } from '@app/utils/app-utils.function'
import { scw } from '@app/utils/classes/simple-changes-wrapper'
import { singularPluralFormatter, strReplaceAll } from '@app/utils/strings/string.utils'
import { equipmentCalibrationSortSetting } from '../../constants/equipmentCalibrationSortSetting'
import {
    consolidatedStatusPrioritization, equipmentCalibrationRequiredAction, equipmentCalibrationStatus, equipmentConsolidatedStatus, Status
} from '../../models/equipment-calibration-status.model'
import { EquipmentCalibration } from '../../models/equipment-calibration.model'
import { WorkOrderDetails } from '../../models/work-order-details.model'
import { MassUpdateService } from '../../services/mass-update.service'
import { WorkOrderService } from '../../services/work-order.service'

@Component({
    selector: 'app-work-order-equipment',
    templateUrl: './work-order-equipment.component.html',
    styleUrls: ['./work-order-equipment.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'translateY(100%)'
                }),
                animate('200ms ease-out',
                    style({
                        opacity: 1,
                        transform: 'translateY(0%)'
                    })
                )
            ]),
            transition(':leave', [
                style({ opacity: 1, transform: 'translateY(0%)' }),
                animate('200ms ease-in', style({
                    opacity: 0,
                    transform: 'translateY(100%)'
                }))
            ])
        ])
    ]
})
export class WorkOrderEquipmentComponent extends SafeUnsubscriberComponent implements OnChanges, OnInit {
    @Input() workOrder: WorkOrderDetails

    public equipmentViewModels: any[] = []
    public searchResultView: EquipmentCalibration[] = []
    public defaultOrder: EquipmentCalibration[] = []

    public numEqSelected = 0
    public newSelectableEqCount = 0
    public allSelectableEqCount = 0
    public numCompleteEqSelected = 0
    public deferredEqCount = 0
    public notTestedEqCount = 0

    // Special case for GRT only
    public numDraftSelected = 0

    public isMassUpdateMode = false
    public shouldShowGRTBanner = false

    public searchText = ''
    public searchBoxPlaceholder = 'Id, Tag, FLOC, Desc'

    public isMassUpdateEnable = false

    public sortStates: SortedState[] = equipmentCalibrationSortSetting
    public equipmentStackSortingCaret: EquipmentStackSortingCaret
    public calibrationGrtDataSourceMessage = remindMessage.info.workOrder

    @ViewChild('selectedElem') selectedElem: ElementRef

    private hasPrimaryEquipment: boolean

    constructor(
        public offlineService: OfflineService,
        public identityService: IdentityService,
        private router: Router,
        private route: ActivatedRoute,
        private calibrationResultStatusService: CalibrationResultStatusService,
        private massUpdateService: MassUpdateService,
        private toastrService: ToastrService,
        private store: Store<AppState>,
        private filteringService: FilteringService,
        private equipmentSortStackService: EquipmentSortStackService,
        private appMonitoringService: AppMonitoringService,
        private workOrderService: WorkOrderService
    ) { super() }

    ngOnInit(): void {
        this.getEquipmentNotifications(this.workOrder.workOrderNumber, this.workOrder.equipmentCalibrations)

        // Load current selection from the service and update the UI
        this.addSubscriptions([
            this.massUpdateService.selectedEquipmentCalibrations$.pipe(
                // Only sync the state back at the start.
                take(1),
                map(equipmentCalbrations => equipmentCalbrations.map(ec => ec.equipmentId)),
            ).subscribe(selectedEquipmentIds => {
                this.numEqSelected = selectedEquipmentIds.length
                if (this.numEqSelected > 0) {
                    this.isMassUpdateMode = true
                }

                this.equipmentViewModels.forEach(viewModel => {
                    const isSelected = selectedEquipmentIds.includes(viewModel.equipmentId)
                    viewModel._viewMeta._isSelected = isSelected
                })
            }),

            this.massUpdateService.selectedEquipmentCalibrations$.subscribe(selectedEq => {
                this.numCompleteEqSelected = selectedEq.filter(eq => eq.status === 'completed').length
                this.numDraftSelected = selectedEq.filter(eq => eq.status === 'draft').length
            }),

            combineLatest([
                this.offlineService.isOnline$,
                this.identityService.isCurrentUserATechnician$,
                this.identityService.isCurrentUserASupport$
            ]).subscribe(([isOnline, isTechnician, isSupport]) => {
                this.isMassUpdateEnable = isOnline && (isTechnician || isSupport) && (this.allSelectableEqCount > 0)
            })
        ])

        this.defaultOrder = this.workOrder.equipmentCalibrations
        this.hasPrimaryEquipment = this.workOrder?.hasPrimaryEquipment
    }

    ngOnChanges(changes: SimpleChanges): void {
        scw(changes)
            .forChangeIn('workOrder')
            .do(({ currentValue: currentWorkOrder }) => {
                if (!currentWorkOrder) {
                    return
                }
                this.initializedViewModels(currentWorkOrder)
                this.massUpdateService.currentWorkOrder = currentWorkOrder
            })
    }


    public getEquipmentNotification(notificationNumber: string): string {
        const _notificationNumber = notificationNumber ? notificationNumber : this.workOrder.notification.number
        return this.workOrderService.getEquipmentNotification(_notificationNumber, this.workOrder.equipmentCalibrations)
    }

    public getEquipmentNotifications(workOrderNumber, equipmentCalibrations): void {
        const equipmentCalibrationsList = equipmentCalibrations.map((equipment: any) => equipment.equipmentNotificationNumber).filter(notification => notification)

        if (equipmentCalibrationsList.length) {
            this.store.dispatch(GetNotificationsAction({
                workOrderNumber,
                notificationNumbers: equipmentCalibrationsList
            }))
        }
    }

    public equipmentCalibrationSelected(equipment: Equipment | any): void {
        if (this.isMassUpdateEnable && this.isMassUpdateMode) {
            if (equipment.status === 'draft' && equipment.dataSource !== 'GRT') {
                this.toastrService.error(mrmaAlertConfigs.massCompleteDraftError.message)
                return
            }

            const selected = !equipment._viewMeta._isSelected
            equipment._viewMeta._isSelected = selected
            this.numEqSelected += selected ? 1 : -1
            this.equipmentSelectionChanged()
            return
        }
        this.router.navigate(['../calibration', equipment.equipmentId], { relativeTo: this.route })
    }

    public getStatusLabel(type: string, status: string): Status {
        if (type === 'calibration-status') {
            return equipmentCalibrationStatus.find(item => item.status === status)
        } else if (type === 'calibration-action-required') {
            return equipmentCalibrationRequiredAction.find(item => item.status === status)
        }
    }

    public isItemFinalPMResultDeferredOrNotTested(finalPMResultStatus: string): boolean {
        // This is from WorkOrderDetail endpoint and the field is actually called `result` from BE
        return this.calibrationResultStatusService.isCalibrationDeferredOrNotTested(finalPMResultStatus)
    }

    public isEquipmentFailedToSync(equipmentId: string): boolean {
        if (this.workOrder) {
            return this.offlineService.getCalibrationBackgroundSyncStatus(
                this.workOrder.workOrderNumber,
                equipmentId
            ) === BackgroundSyncStatusEnum.FAILED
        }
        return false
    }

    public equipmentErrorStatus(equipment: EquipmentCalibration): number {
        if (this.isEquipmentFailedToSync(equipment.equipmentId)) {
            return 1
        }
        return 0
    }

    public getConsolidatedLabelStatus(equipment: EquipmentCalibration): Status {
        const calibrationStatus = equipment.status || ''
        const resultStatus = equipment.result || ''

        const consolidateStatus = [
            calibrationStatus.toLowerCase(),
            resultStatus.toLowerCase()
        ]

        const statusFound = consolidatedStatusPrioritization.filter(it => {
            const pStatus = it[1].toLowerCase()
            return pStatus === consolidateStatus.find(status => status === pStatus)
        })

        const sortedStatus = statusFound.sort((a, b) => a[0] < b[0] ? 1 : -1)
        const statusName = sortedStatus.length > 0 ? sortedStatus[0][1] : ''
        const finalStatus = equipmentConsolidatedStatus.find(it => it.status === statusName)

        if (!finalStatus) {
            return {
                status: '',
                statusName: '',
                className: ''
            } as Status
        }

        return finalStatus
    }

    public equipmentSelected(event: InputEvent): void {
        // Stop click event so it doesn't reach the table cell and redirect us to Calibration Page
        event.stopPropagation()

        // Calculate if the selectAll checkbox should be checked
        const newCheckedState = event.target['checked']
        this.numEqSelected += newCheckedState ? 1 : -1
        this.equipmentSelectionChanged()
    }

    public massUpdateSelectionSelected(event: string): void {
        switch (event) {
            case 'all':
                this.numEqSelected = this.allSelectableEqCount
                this.equipmentViewModels.forEach(eqViewModel => {
                    if (!eqViewModel._viewMeta._selectable) {
                        return
                    }
                    eqViewModel._viewMeta._isSelected = true
                })
                break

            case 'new':
                this.numEqSelected = this.newSelectableEqCount
                this.equipmentViewModels.forEach(eqViewModel => {
                    if (!eqViewModel._viewMeta._selectable) {
                        return
                    }
                    eqViewModel._viewMeta._isSelected = eqViewModel.status === null ? true : false
                })
                break

            case 'deferred':
                this.numEqSelected = this.deferredEqCount
                this.equipmentViewModels.forEach(eqViewModel => {
                    if (!eqViewModel._viewMeta._selectable) {
                        return
                    }
                    eqViewModel._viewMeta._isSelected = eqViewModel.result === 'Deferred'
                })
                break

            case 'not-tested':
                this.numEqSelected = this.notTestedEqCount
                this.equipmentViewModels.forEach(eqViewModel => {
                    if (!eqViewModel._viewMeta._selectable) {
                        return
                    }
                    eqViewModel._viewMeta._isSelected = eqViewModel.result === 'Not Tested'
                })
                break

            case 'deferred-not-tested':
                this.numEqSelected = this.deferredEqCount + this.notTestedEqCount
                this.equipmentViewModels.forEach(eqViewModel => {
                    if (!eqViewModel._viewMeta._selectable) {
                        return
                    }
                    eqViewModel._viewMeta._isSelected = (eqViewModel.result === 'Deferred' || eqViewModel.result === 'Not Tested')
                })
                break

            default:
                this.numEqSelected = 0
                this.equipmentViewModels.forEach(eqViewModel => {
                    if (!eqViewModel._viewMeta._selectable) {
                        return
                    }
                    eqViewModel._viewMeta._isSelected = false
                })
                break
        }
        this.selectedElem.nativeElement.value = 'select'
        this.equipmentSelectionChanged()
    }

    public cancelMassUpdateMode(): void {
        this.isMassUpdateMode = false
        this.numEqSelected = 0
        this.equipmentViewModels.forEach(eqViewModel => {
            if (!eqViewModel._viewMeta._selectable) {
                return
            }
            eqViewModel._viewMeta._isSelected = false
        })
        this.equipmentSelectionChanged()
    }

    public startMassUpdateMode(): void {
        if (!this.offlineService.isOnline) {
            this.toastrService.warning(
                'Please check your internet connection and try again.',
                'Mass Update is not available while offline.'
            )
            return
        }
        if (this.allSelectableEqCount <= 0) {
            this.toastrService.error(mrmaAlertConfigs.massCompleteDraftError.message)
            return
        }
        if (!this.isMassUpdateEnable) {
            this.toastrService.warning(
                `You don't have the permission to edit calibration.`
            )
            return
        }
        this.isMassUpdateMode = true
    }

    public confirmMassUpdate(): void {
        if (this.numCompleteEqSelected > 0 || this.numDraftSelected > 0) {
            this.displayConfirmation()
        } else {
            this.proceedToMassUpdate()
        }
    }

    public applySearch(searchText: string): void {
        this.searchText = searchText
        if (isEmpty(searchText)) {
            this.searchResultView = this.equipmentViewModels
        } else {
            this.searchResultView = this.equipmentViewModels.filter(eqViewModel =>
                toLower(eqViewModel.equipmentTag).includes(toLower(searchText)) ||
                toLower(eqViewModel.description).includes(toLower(searchText)) ||
                toLower(eqViewModel.equipmentId).includes(toLower(searchText)) ||
                toLower(eqViewModel.functionalLocation).includes(toLower(searchText))
            )
        }

        this.defaultOrder = this.searchResultView
        this.applySorting()
    }

    public applySorting(column?: string): void {
        let sortedResult = this.searchResultView.slice()
        let isSorting = false

        if (column) {
            this.sortStates = this.filteringService.configSorting(deepCopy(this.sortStates), column)
        }

        this.sortStates.forEach(config => {
            if (config.sortingState !== SortingState.None) {
                const isDescending = config.sortingState === SortingState.Descending ? true : false
                sortedResult = sortArray(sortedResult, config.sortId, isDescending)
                isSorting = true
            }
        })

        if (!isSorting) {
            sortedResult = this.defaultOrder
        }

        this.equipmentStackSortingCaret = this.equipmentSortStackService.refreshSortingCaret(this.equipmentStackSortingCaret, column)
        this.searchResultView = sortedResult
    }

    public isPrimaryEquipment(equipmentCalibration: EquipmentCalibration): boolean {
        const isFirstEquipment = equipmentCalibration.equipmentId === this.workOrder.equipmentCalibrations[0].equipmentId

        return isFirstEquipment && this.hasPrimaryEquipment
    }

    public getDescendingState(column: string): boolean {
        return this.filteringService.isDescending(this.sortStates, column)
    }

    public getSortedState(column: string): boolean {
        return this.filteringService.isSorted(this.sortStates, column)
    }

    public getEquipmentStackSortingCaretState(state: EquipmentStackSortingCaret): void {
        this.equipmentStackSortingCaret = state
    }

    private displayConfirmation(): void {
        const modal = deepCopy(modalContent.overridingPMResult) as Modal
        let modalBodyText = ''
        if (this.numCompleteEqSelected > 0 && this.numDraftSelected > 0) {
            modalBodyText = deepCopy(modalMessageBody.overridingPMResultDraftAndCompleteSelected.body)
        } else if (this.numCompleteEqSelected > 0) {
            modalBodyText = deepCopy(modalMessageBody.overridingPMResultCompleteSelected.body)
        } else if (this.numDraftSelected > 0) {
            modalBodyText = deepCopy(modalMessageBody.overridingPMResultDraftSelected.body)
        }
        const unitFormatter = singularPluralFormatter(' PM', ' PM records')
        const isAreFormatter = singularPluralFormatter('is', 'are')
        modal.body = strReplaceAll(modalBodyText, {
            '[numberOfCompleted]': this.numCompleteEqSelected.toString() + unitFormatter(this.numCompleteEqSelected),
            '[numberOfDraft]': this.numDraftSelected.toString() + unitFormatter(this.numDraftSelected),
            '[completeIsAre]': isAreFormatter(this.numCompleteEqSelected),
            '[draftIsAre]': isAreFormatter(this.numDraftSelected),
        })
        modal.confirmCallback = () => this.proceedToMassUpdate()

        this.store.dispatch(new ShowModalAction(modal))
    }

    private proceedToMassUpdate(): void {
        if (!this.offlineService.isOnline) {
            this.toastrService.warning(
                'Please check your Internet Connection and try again.',
                'Mass Update is not available while offline.'
            )
            return
        }
        this.router.navigate(['../mass-update'], { relativeTo: this.route })
    }

    private initializedViewModels(workOrder: WorkOrderDetails): void {
        this.allSelectableEqCount = 0
        this.newSelectableEqCount = 0
        this.shouldShowGRTBanner = _.some(workOrder.equipmentCalibrations, eq => eq.dataSource === 'GRT')
        this.equipmentViewModels = workOrder.equipmentCalibrations
            .map(eqCalibration => {
                const isGRTEq = eqCalibration.dataSource === 'GRT'
                const isSelectable = eqCalibration.status !== 'draft' || isGRTEq
                const isNewEqCalibration = eqCalibration.status === null
                if (isSelectable) {
                    this.allSelectableEqCount++
                }
                if (isNewEqCalibration) {
                    this.newSelectableEqCount++
                    // if the Eq is new, it will not have a result so
                    // putting additional check for result in else if
                    // below can optimize the loop
                } else if (eqCalibration.result === 'Deferred') {
                    this.deferredEqCount++
                } else if (eqCalibration.result === 'Not Tested') {
                    this.notTestedEqCount++
                }

                if (this.shouldShowGRTBanner && !isGRTEq) {
                    this.appMonitoringService?.instance?.trackException({
                        error: new Error(`WO #${this.workOrder.workOrderNumber} contain a mixed between GRT and non-GRT data source items.`)
                    })
                }

                return {
                    ...eqCalibration,
                    _viewMeta: {
                        _isSelected: false,
                        _selectable: isSelectable
                    }
                }
            })
        this.searchResultView = this.equipmentViewModels
    }

    private equipmentSelectionChanged(): void {
        //  This make sure [(ngModel)] finish updating first
        setTimeout(() => {
            // Notify @Output() of changes
            const newSelection = this.equipmentViewModels
                .filter(eqViewModel => eqViewModel._viewMeta._isSelected)
                .map(selectedEqViewModel => {
                    return {
                        ...selectedEqViewModel,
                        _viewMeta: undefined
                    }
                })
            this.massUpdateService.setSelection(newSelection)
        })
    }
}
