<div class="radio-container">
    <ng-container *ngFor="let config of radioOptions">
        <label>
            <input type="radio" [value]="config.value" [name]="groupName" [checked]="hasSelect(config.value)"
                   (click)="onSelectOption($event, config.value)">
            <span>{{ config.name }}</span>
        </label>
    </ng-container>
</div>
<div class="radio-related-content">
    <ng-content></ng-content>
</div>
