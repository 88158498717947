<section #attachment
         *ngIf="!isReport"
         class="em-c-solid-card em-c-solid-card--gray">
    <div class="em-c-solid-card__footer custom-center"
         *ngIf="!hasAttachment()">
        <svg class="em-c-icon em-c-icon--large em-u-margin-bottom-half">
            <use xmlns:xlink="http://www.w3.org/1999/xlink"
                 xlink:href="./assets/images/mrma-icons.svg#icon-parts-doc"></use>
        </svg>
        <h5 *ngIf="(isOnline$ | async)">Attach Files</h5>
        <h5 *ngIf="!(isOnline$ | async)">Feature disabled when offline</h5>
    </div>
    <div class="em-c-solid-card__footer"
         *ngIf="hasAttachment()">
        <div class="em-c-btn__text em-u-padding-bottom-half"
             *ngFor="let attachment of attachments; let i = index">
            <svg class="em-c-icon em-c-icon--medium em-u-clickable custom-file-icon"
                 *ngIf="!editAttachmentMode"
                 (click)="viewAttachment(attachment.blobUrl)">
                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="./assets/images/mrma-icons.svg#icon-parts-doc"></use>
            </svg>
            <svg class="em-c-icon em-c-icon--medium custom-file-icon em-u-clickable"
                 *ngIf="editAttachmentMode"
                 (click)="confirmDeleteAttachment(attachment.attachmentID, attachment.filename)">
                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="./assets/unity-1.2.0/images/em-icons.svg#icon-x"></use>
            </svg>
            <span class="custom-attachment"
                  [ngClass]="{'custom-attachment-disabled': (isOnline$ | async) === false}"
                  (click)="viewAttachment(attachment.blobUrl)">
                {{ attachment.filename }}
            </span>
        </div>
    </div>
    <div class="em-c-toolbar em-u-display-inline-block em-u-text-align-right">
        <div *ngIf="(isOnline$ | async)"
             class="em-c-toolbar__item em-u-display-inline-block">
            <input #fileInput
                   id="fileInput"
                   class="em-u-is-hidden"
                   type="file"
                   accept="image/*,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,video/*,application/vnd.ms-outlook"
                   (change)="inputFilesSelected($event)" />
            <div class="em-c-btn em-c-btn--primary em-c-btn--small"
                 *ngIf="(!editAttachmentMode || !hasAttachment()) && isUserAuthorized"
                 [ngClass]="{'em-c-btn--disabled': !isAttachmentAllowed}"
                 (click)="selectAttachmentFile()">
                <span class="em-c-btn__text ">Attach</span>
            </div>
            <div class="em-c-btn em-c-btn--secondary em-c-btn--small"
                 *ngIf="(!editAttachmentMode || !hasAttachment()) && isUserAuthorized"
                 [ngClass]="{'em-c-btn--disabled': !hasAttachment()}"
                 (click)="switchEditMode()">
                <span class="em-c-btn__text ">Delete</span>
            </div>
            <div class="em-c-btn em-c-btn--secondary em-c-btn--small"
                 *ngIf="editAttachmentMode && hasAttachment()"
                 (click)="switchEditMode()">
                <span class="em-c-btn__text ">Back</span>
            </div>
        </div>
    </div>
</section>
<section #attachment
         *ngIf="isReport">
    <div class="em-u-padding-bottom-double">
        <div *ngFor="let attachment of attachments; let i = index"
             class="em-c-btn__text em-u-padding-bottom-half">
            <svg (click)="viewAttachment(attachment.blobUrl)"
                 class="em-c-icon em-c-icon--medium em-u-clickable custom-file-icon">
                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="./assets/images/mrma-icons.svg#icon-parts-doc"></use>
            </svg>
            <span (click)="viewAttachment(attachment.blobUrl)"
                  class="custom-attachment">
                {{ attachment.filename }}
                <span class="label label-warning"
                      *ngIf="isAttachmentRequired">
                    Required
                </span>
            </span>
        </div>
        <div *ngIf="attachments.length === 0">
            No attachment
        </div>
    </div>
</section>