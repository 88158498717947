<div class="em-c-field">
    <label class="em-c-field__label"
           [ngClass]="{'required': formControl.status === 'INVALID'}">{{ formDetail?.title }}</label>
    <div class="em-c-field__body">
        <select [appInputDisplayReport]="isReport"
                [formControl]="formControl"
                class="em-c-select"
                [id]="formDetail?.id"
                placeholder="Placeholder"
                [disabled]="formControl.disabled">
            <option [ngValue]="null"
                    disabled>-- select an option --</option>
            <option *ngFor="let item of formDetail?.options"
                    [selected]="formControl.value === item"
                    [value]="item">{{ item }}</option>
        </select>
    </div>
</div>
