<section class="expandable-section em-c-section em-c-section--expandable"
         [ngClass]="{'em-is-closed': !isSectionExpanded}">
    <div class="expandable-section__background"
         [ngClass]="{'no-backgroud': isReport}"></div>
        <header class="em-c-section__header em-js-section-trigger"
                (click)="toggleSection()"
                *ngIf="!isReport">

            <div class="btn-replace-eq">
                <svg class="em-c-icon em-c-icon--small em-c-section__icon blue-button">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink"
                         xlink:href="./assets/unity-1.2.0/images/em-icons.svg#caret-down"></use>
                </svg>
                <label class="em-c-section__title blue-button">
                    {{sectionTitle}}
                </label>
            </div>
            <div *ngIf="toggleOnAdd && isSectionExpanded" class="blue-button add-button" (click)="handleAddClick()" appClickStopPropagation >
                + Add
            </div>
        </header>
    <div class="em-c-section__body">
        <ng-content></ng-content>
        <div *ngIf="toggleOnAdd && isSectionExpanded" class="button-bar" >
            <div class="blue-button add-button-mobile" (click)="handleAddClick()" appClickStopPropagation>+ Add</div>
        </div>
    </div>
</section>
