import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { QueryBuilderService } from '@app/services/query-builder.service'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { WorkOrderListItem } from '../models/work-order-list-item.model'

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    private readonly url = `${environment.baseUrl}/api/${AppSettings.apiVersion}/WorkOrderItems`

    constructor(private queryBuilderService: QueryBuilderService) { }

    public searchWorkOrdersByEquipmentTags(equipmentTags: string): Observable<WorkOrderListItem[]> {
        return this.searchWorkOrder('equipmentTags', equipmentTags)
    }

    public searchWorkOrdersByNumbers(workOrderNumbers: string): Observable<WorkOrderListItem[]> {
        return this.searchWorkOrder('workOrderNumbers', workOrderNumbers)
    }

    private searchWorkOrder(searchType: string, searchKey: string): Observable<WorkOrderListItem[]> {
        if (searchType === 'equipmentTags') {
            return this.queryBuilderService.get<any>(`${this.url}`, {
                equipmentTags: searchKey
            }).pipe(map(response => response.body))
        }
        return this.queryBuilderService
            .get<any>(`${this.url}?${searchType}=${searchKey}`)
            .pipe(map(response => response.body))
    }
}
