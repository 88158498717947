import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { take } from 'rxjs/operators'

import { TemplateType } from '@app/modules/equipment/models/template-type.model'
import { TemplateTypeUtilService } from '@app/modules/equipment/services/template-type-util.service'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import { AddEquipmentTemplateAction, SelectTemplateTypeAction } from '@app/store/equipment/actions/equipment-template.actions'
import { equipmentDetail } from '@app/store/equipment/selectors/equipment.selectors'
import { templateTypes } from '@app/store/equipment/selectors/template-type.selectors'
import { selectedPlant } from '@app/store/identity/identity.selectors'
import * as ModalAction from '@app/store/modal/modal.actions'

@Component({
    selector: 'app-template-selection',
    templateUrl: './template-selection.component.html',
    styleUrls: ['./template-selection.component.scss']
})
export class TemplateSelectionComponent extends SafeUnsubscriberComponent implements OnInit {
    public availableTemplates: TemplateType[] = []
    public isCalibrationPage: boolean

    private equipmentId: string
    private plantCode: string

    constructor(
        private store: Store<AppState>,
        private templateTypeUtilService: TemplateTypeUtilService
    ) {
        super()
    }

    ngOnInit(): void {
        this.initEquipmentInfo()
        this.initAvailableTemplate()
    }

    public selectTemplate(templateType: TemplateType): void {
        const equipmentTemplate = this.templateTypeUtilService
            .getDefaultEquipmentTemplate(
                this.plantCode,
                this.equipmentId,
                templateType
            )

        this.store.dispatch(new AddEquipmentTemplateAction(equipmentTemplate))
        this.store.dispatch(new SelectTemplateTypeAction(equipmentTemplate))
        this.store.dispatch(new ModalAction.DestroyModalAction())
    }

    private initEquipmentInfo(): void {
        this.addSubscriptions([
            this.store.select(selectedPlant).subscribe(plantCode => this.plantCode = plantCode),
            this.store.select(equipmentDetail).subscribe(currentEquipment => this.equipmentId = currentEquipment.equipmentId)
        ])
    }

    private initAvailableTemplate(): void {
        this.store.select(templateTypes).pipe(take(1)).subscribe(templates => {
            this.availableTemplates = [] as TemplateType[]
            templates.forEach(template => {
                this.availableTemplates.push({
                    id: template.id,
                    name: template.name,
                    numberOfPoint: template.numberOfPoint,
                    isActive: template.isActive
                })
            })

            this.availableTemplates = this.templateTypeUtilService.refactorTemplateTypeList(this.availableTemplates)
        })
    }
}
