import { DOCUMENT } from '@angular/common'
import { Component, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators'

import { AppState } from '@app/store/app.store'
import { isNotAValue } from '@app/utils/app-utils.function'
import { FilteringService } from '../../services/filtering.service'
import { get, isEmpty } from 'lodash'

@Component({
    selector: 'app-dropdown-search',
    templateUrl: './dropdown-search.component.html',
    styleUrls: ['./dropdown-search.component.scss']
})
export class DropdownSearchComponent implements OnInit, OnDestroy, OnChanges {
    @Input() control: FormControl
    @Input() selector: any
    @Input() excludedItems: any[] = []
    @Input() disablingFunction: any
    @Input() displayField: string
    @Input() idField: string
    @Input() index: number
    @Input() callback: any
    @Input() inputId = ''
    @Input() placeholder = ''
    @Input() needEmitEventWhenValidateDataFirstly = true

    public selectionFocus = false
    public dataInitiated = false
    public dataSource$: Observable<any>

    public searchText: BehaviorSubject<string> = new BehaviorSubject('')
    public searchText$: Observable<string> = this.searchText.asObservable()
    private focusHandler: any

    constructor(
        private store: Store<AppState>,
        private elementRef: ElementRef,
        private filteringService: FilteringService,
        @Inject(DOCUMENT) private document: any) {
    }

    ngOnInit(): void {
        this.idField = isEmpty(this.idField) ? this.displayField : this.idField
        this.loadDataSource()
    }

    ngOnChanges(): void {
        if (isNotAValue(this.control.value) && !isNotAValue(this.searchText.value)) {
            this.searchText.next(this.searchText.value)
        }
    }

    ngOnDestroy(): void {
        this.removeFocusState()
    }

    public initWithCurrentValue(dataSource: any[]): void {
        const currentItemId = get(this.control.value, this.idField)
        if (currentItemId) {
            const matchedCurrentData = dataSource.find(data => data[this.idField] === currentItemId)

            if (matchedCurrentData) {
                this.select(matchedCurrentData, this.needEmitEventWhenValidateDataFirstly)
            }
        }
        this.dataInitiated = true
    }

    public select(item: any, emitEvent = true): void {
        if (item.disabled) {
            return
        }
        this.searchText.next(item ? item[this.displayField] : undefined)
        this.control.setValue(item, { emitEvent })
        this.removeFocusState()
    }

    public unselect(): void {
        this.searchText.next(undefined)
        this.control.setValue(undefined)
        this.removeFocusState()
        this.loadDataSource()

        if (this.index && this.callback) {
            this.callback(this.index)
        } else if (this.callback) {
            this.callback()
        }
    }

    public showRemoveButton(): boolean {
        return this.control.value && !this.control.disabled
    }

    public setFocusState(): void {
        if (this.selectionFocus) {
            return
        }

        this.selectionFocus = true
        this.focusHandler = event => {
            if (!this.dropdownClick(event.target)) {
                this.removeFocusState()
            }
        }

        this.document.addEventListener('click', this.focusHandler, {
            passive: true
        })
    }

    private loadDataSource(): void {
        const selector$ = this.store.pipe(select(this.selector))
        const searchText$ = this.searchText$.pipe(debounceTime(250), distinctUntilChanged())
        this.dataSource$ = combineLatest([selector$, searchText$]).pipe(
            filter(value => value[0] && value[0].length > 0),
            tap(value => {
                if (!this.dataInitiated) {
                    this.initWithCurrentValue(value[0])
                }
            }),
            map(value => {
                const selectableItems = value[0].filter((item: any) =>
                    !this.excludedItems.find(excludedItem =>
                        excludedItem[this.idField] === item[this.idField])
                )
                const filteredItems = this.filteringService.filter(selectableItems, this.displayField, value[1])
                return this.disablingFunction ? filteredItems.map(this.disablingFunction) : filteredItems
            })
        )
    }

    private removeFocusState(): void {
        this.selectionFocus = false
        if (this.control && this.searchText.getValue() !== this.control.value) {
            if (this.control.value && this.searchText.getValue()) {
                this.searchText.next(this.control.value[this.displayField])
            } else {
                this.searchText.next(undefined)
                this.control.setValue(undefined)
            }
        }

        this.document.removeEventListener('click', this.focusHandler)
    }

    private dropdownClick(target: any): boolean {
        return this.elementRef.nativeElement.contains(target)
    }
}
