<div class="unauthorized-container">
    <div style="max-width: 600px;">
        <div style="text-align: center;">
            <img src="./assets/images/undraw_safe_bnk7.svg"
                alt="Lock Image"
                style="
                    height: 12rem;
                    max-height: 30vh;
                    max-width: 80vw;
                    margin-bottom: 3rem;
                ">
            <h2>{{title}}</h2>
            <h1>{{subtitle}}</h1>
            <div class="linear"></div>
        </div>
        
        <div>
            <p>Please try the following:</p>
            <div class="list-detail">
                <ul class="em-c-bulleted-list em-c-link-list">
                    <li class="em-c-bulleted-list__item">Please go to <a href="https://goto/mrmaAccess" class="em-c-link-list__link">https://goto/mrmaAccess</a> for troubleshooting.</li>
                    <li class="em-c-bulleted-list__item">Contact site administrator for access requests for this specific plant.</li>
                    <li class="em-c-bulleted-list__item">If you are contractor, Please confirm with your Admin that expiry date is active in user list.</li>
                    <li
                        class="em-c-bulleted-list__item"
                        *ngIf="isUnauthorizedDueToPlant && !hasZeroPlantAccess"
                    >
                        <div class="plant-selection-block">
                            <div>Change to the plant you are already have access to:</div>
                            <app-plant-selection class="plant-select border-plan"></app-plant-selection>
                        </div>
                    </li>
                    <li class="em-c-bulleted-list__item em-c-link-list__item"><a href="#" (click)="logout()" class="em-c-link-list__link">Please log out and try again</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
