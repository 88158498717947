<div class="result-row"
     *ngIf="!hasInjectedInput">
    <div class="em-l-grid em-l-grid--4up">
        <div class="em-l-grid__item">
            <div class="em-c-field__label unit">Input ({{template.input.unitOfMeasurement.uomCodeForTech}})</div>
            {{control.get('input').value}}
        </div>
        <div class="em-l-grid__item">
            <div class="em-c-field__label unit">Expected ({{template.expected.unitOfMeasurement.uomCodeForTech}})</div>
            {{expected.value.min}} to {{expected.value.max}}
        </div>
        <div class="em-l-grid__item">
            <div class="em-c-field__label unit">As Found ({{template.expected.unitOfMeasurement.uomCodeForTech}})</div>
            <app-label [classList]="isDisabled ? 'em-is-disabled' : ''">
                <app-numeric-input appInputValidator
                                   [appCalibrationInputReport]="isReport"
                                   class="em-c-input em-u-text-align-right"
                                   type="text"
                                   [formControl]="control.get('asFound')"
                                   (inputBlur)="autoPopulateResult()"
                                   placeholder="0.0"
                                   tabIndex="{{ startingTabIndex + pointNumber }}"
                                   [input]="validationStatus(control.get('asFound').value, true)">
                </app-numeric-input>
            </app-label>
        </div>
        <div class="em-l-grid__item">
            <div class="em-c-field__label unit">As Left ({{template.expected.unitOfMeasurement.uomCodeForTech}})</div>
            <app-label [classList]="isDisabled ? 'em-is-disabled' : ''">
                <app-numeric-input appInputValidator
                                   [appCalibrationInputReport]="isReport"
                                   class="em-c-input em-u-text-align-right"
                                   type="text"
                                   [formControl]="control.get('asLeft')"
                                   placeholder="0.0"
                                   tabIndex="{{ startingTabIndex + pointNumber + template.numberOfPoint }}"
                                   [input]="validationStatus(control.get('asLeft').value, false)">
                </app-numeric-input>
            </app-label>
        </div>
    </div>
</div>
<div class="result-row"
     *ngIf="hasInjectedInput">
    <div class="em-l-grid em-l-grid--4up">
        <div class="em-l-grid__item">
            <div class="em-c-field__label unit">Input ({{template.input.unitOfMeasurement.uomCodeForTech}})</div>
            {{control.get('input').value}}
        </div>
        <div class="em-l-grid__item">
            <div class="em-c-field__label unit">Adjusted Input ({{template.input.unitOfMeasurement.uomCodeForTech}})
            </div>
            <app-label [classList]="isDisabled ? 'em-is-disabled' : ''">
                <app-numeric-input class="em-c-input em-u-text-align-right"
                                   type="text"
                                   [appInputDisplayReport]="isReport"
                                   [formControl]="injectedInput"
                                   tabIndex="{{ tabIndex }}"
                                   placeholder="0.0">
                </app-numeric-input>
            </app-label>
        </div>
        <div class="em-l-grid__item">
            <div class="em-c-field__label unit">Expected ({{template.expected.unitOfMeasurement.uomCodeForTech}})</div>
            {{expected.value.min}} to {{expected.value.max}}
        </div>
        <div class="em-l-grid__item">
            <div class="em-c-field__label unit">
                <span *ngIf="!hasInjectedInput || (hasInjectedInput && isAsFound)">As Found
                    ({{template.expected.unitOfMeasurement.uomCodeForTech}})</span>
                <span *ngIf="!hasInjectedInput || (hasInjectedInput && !isAsFound)">As Left
                    ({{template.expected.unitOfMeasurement.uomCodeForTech}})</span>
            </div>
            <app-label [classList]="isDisabled ? 'em-is-disabled' : ''">
                <app-numeric-input appInputValidator
                                   [appCalibrationInputReport]="isReport"
                                   class="em-c-input em-u-text-align-right"
                                   type="text"
                                   [formControl]="result"
                                   placeholder="0.0"
                                   tabIndex="{{ tabIndex }}"
                                   (inputBlur)="autoPopulateResult()"
                                   [input]="validationStatus(result.value)">
                </app-numeric-input>
            </app-label>
        </div>
    </div>
</div>
