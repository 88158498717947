import { Injectable } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'

import { isNotAValue, round } from '@app/utils/app-utils.function'
import { CalculationStrategy } from '../models/calculcation-strategy.model'
import { CalibrationDetails } from '../models/calibration-details.model'
import { TemperatureResultSetEnum } from '../models/temperature-result-set.enum'
import { TemperatureTemplate } from '../models/temperature-template.model'
import { CalibrationInitializerService } from './calibration-initializer.service'
import { ICalculationStrategy, RangeCalculationService } from './range-calculation.service'

@Injectable({
    providedIn: 'root'
})
export class TemperatureCalibrationService {
    constructor(
        private calibrationInitializerService: CalibrationInitializerService,
        private pointCalculator: RangeCalculationService,
        private formBuilder: FormBuilder) { }

    public calculateCombineOutOfTolerance(resultSetsFormControl: FormControl): { asFound: number, asLeft: number } {
        let combinedOutOfToleranceAsFound: number
        let combinedOutOfToleranceAsLeft: number

        (resultSetsFormControl.get('results') as FormArray).controls.forEach(resultSet => {
            combinedOutOfToleranceAsFound = this.combineTolerance(
                combinedOutOfToleranceAsFound, this.getMaxOutOfTolerance(resultSet.get('resultSet') as FormArray, true)
            )
            combinedOutOfToleranceAsLeft = this.combineTolerance(
                combinedOutOfToleranceAsLeft, this.getMaxOutOfTolerance(resultSet.get('resultSet') as FormArray, false)
            )
        })

        return { asFound: combinedOutOfToleranceAsFound, asLeft: combinedOutOfToleranceAsLeft }
    }

    public combineTolerance(combinedOutOfTolerance: number, addingTolerance: number): number {
        if (!isNotAValue(addingTolerance)) {
            return !isNotAValue(combinedOutOfTolerance) ? round(combinedOutOfTolerance + addingTolerance) : addingTolerance
        }

        return 0
    }

    public getExpectedValue(resultRow: FormControl, isAsFound: boolean): number {
        if (isAsFound) {
            if (resultRow.get('expectedValueAsFound') && !isNotAValue(resultRow.get('expectedValueAsFound').value)) {
                return resultRow.get('expectedValueAsFound').value
            }

            return resultRow.get('expectedValue').value
        }

        if (resultRow.get('expectedValueAsFound') && !isNotAValue(resultRow.get('expectedValueAsFound').value)) {
            return resultRow.get('expectedValueAsLeft').value
        }

        return resultRow.get('expectedValue').value
    }

    public getMaxOutOfTolerance(resultSetForm: FormArray, isAsFound: boolean): number {
        return Math.max(...resultSetForm.controls.map((result) => {
            const expectedValue = this.getExpectedValue(result as FormControl, isAsFound)
            const compareValue = isAsFound ? result.get('asFound').value : result.get('asLeft').value

            return !isNotAValue(compareValue) ? Math.abs(round(expectedValue - compareValue)) : 0
        }))
    }

    public initialize(calibrationForm: FormGroup, calibration: CalibrationDetails, template: TemperatureTemplate): void {
        const resultsFormArray = this.formBuilder.array([])

        if (template.isInstrumentRequired) {
            const instrumentResultSet = calibration.calibrationResult.results
                .find(result => result.resultSetName === TemperatureResultSetEnum.instrument)
            const instrumentResultSetForm = this.calibrationInitializerService.initializeResultSetForm(
                TemperatureResultSetEnum.instrument, instrumentResultSet, template.numberOfPoint, false)
            resultsFormArray.push(instrumentResultSetForm)
        }

        const sensingElementResultSet = calibration.calibrationResult.results
            .find(result => result.resultSetName === TemperatureResultSetEnum.sensingElement)
        const sensingElementResultSetForm = this.calibrationInitializerService.initializeResultSetForm(
            TemperatureResultSetEnum.sensingElement, sensingElementResultSet, template.numberOfPoint, true)
        resultsFormArray.push(sensingElementResultSetForm)

        const results = this.formBuilder.group({
            results: resultsFormArray
        })
        const resultForm = calibrationForm.get('results') as FormGroup
        resultForm.setControl('calibrationResult', results)
    }

    public fullResultSet(resultSet: FormArray, template: TemperatureTemplate, isInjectedInputRequired: boolean): void {
        const calculationStrategy = {
            numberOfPoint: template.numberOfPoint,
            calculationType: { id: CalculationStrategy.Linear, name: 'Linear' },
            tolerance: template.tolerance
        } as ICalculationStrategy

        resultSet.controls.forEach(control => {
            const pointNumber = control.get('pointNumber').value
            const formGroup = control as FormGroup
            formGroup.addControl('input', new FormControl(this.pointCalculator.calculatePoint(
                calculationStrategy, template.input, pointNumber)))

            if (isInjectedInputRequired) {
                const injectedInput = formGroup.get('injectedInput').value
                const expectedValueAsFound = isNotAValue(injectedInput) ?
                    this.pointCalculator.calculatePoint(calculationStrategy, template.expected, pointNumber) :
                    this.pointCalculator.calculateInjectExpectedResult(
                        injectedInput,
                        template.input,
                        template.expected,
                        CalculationStrategy.Linear)

                const adjustedInjectedInput = formGroup.get('adjustedInjectedInput').value
                const expectedValueAsLeft = isNotAValue(adjustedInjectedInput) ?
                    this.pointCalculator.calculatePoint(calculationStrategy, template.expected, pointNumber) :
                    this.pointCalculator.calculateInjectExpectedResult(
                        adjustedInjectedInput,
                        template.input,
                        template.expected,
                        CalculationStrategy.Linear)

                const tolerance = this.pointCalculator.calculateTolerance(template.tolerance, template.expected)

                formGroup.addControl('expectedAsFound', new FormControl({
                    min: round(expectedValueAsFound - tolerance),
                    max: round(expectedValueAsFound + tolerance)
                }))
                formGroup.addControl('expectedValueAsFound', new FormControl(expectedValueAsFound))
                formGroup.addControl('expectedAsLeft', new FormControl({
                    min: round(expectedValueAsLeft - tolerance),
                    max: round(expectedValueAsLeft + tolerance)
                }))
                formGroup.addControl('expectedValueAsLeft', new FormControl(expectedValueAsLeft))
            } else {
                formGroup.addControl('expected', new FormControl(
                    this.pointCalculator.calculateRange(calculationStrategy, template.expected, pointNumber)))
                formGroup.addControl('expectedValue', new FormControl(
                    this.pointCalculator.calculatePoint(calculationStrategy, template.expected, pointNumber)))
            }
        })
    }
}
