<nav id="nav"
     class="em-c-primary-nav"
     role="navigation">
    <ul class="em-c-primary-nav__list">
        <li (click)="closeMenu()"
            class="em-c-primary-nav__item"
            routerLinkActive="em-is-current">
            <a class="em-c-primary-nav__link"
               routerLink="/search">Search</a>
        </li>
        <li (click)="closeMenu()"
            class="em-c-primary-nav__item"
            routerLinkActive="em-is-current">
            <a class="em-c-primary-nav__link"
               routerLink="/to-dos">My Bookmarks</a>
        </li>
        <li class="em-c-primary-nav__item hover">
            <a class="em-c-primary-nav__link em-c-primary-nav__link--has-children em-js-nav-dropdown-trigger">
                Reports<span>(Not supported in mobile view)</span>
                <svg class="em-c-icon em-c-icon--small em-c-primary-nav__icon">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink"
                         xlink:href="assets/unity-1.2.0/images/em-icons.svg#caret-down">
                    </use>
                </svg>
            </a>
            <div appClickStopPropagation
                 (click)="navigationBlock()"
                 class="navigation-block-btn"></div>
            <ul class="em-c-primary-nav__sublist em-js-nav-dropdown">
                <li class="em-c-primary-nav__subitem reports-nav-list">
                    <a class="em-c-primary-nav__sublink"
                       routerLink="/reports"
                       routerLinkActive="em-is-current">
                        Calibration
                    </a>
                    <a class="em-c-primary-nav__sublink"
                       routerLink="/reports/other"
                       routerLinkActive="em-is-current">
                        Download Report
                    </a>
                    <ng-container *appFeatureToggle="'email-subscription'">
                        <hr style="margin-bottom: 0.8rem; margin-top: 0" />
                        <a class="em-c-primary-nav__sublink"
                           routerLink="/email-subscription"
                           routerLinkActive="em-is-current">
                            My Email Subscription
                        </a>
                    </ng-container>
                </li>
            </ul>
        </li>
        <li class="em-c-primary-nav__item hover">
            <a class="em-c-primary-nav__link em-c-primary-nav__link--has-children em-js-nav-dropdown-trigger">
                Admin<span>(Not supported in mobile view)</span>
                <svg class="em-c-icon em-c-icon--small em-c-primary-nav__icon">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink"
                         xlink:href="assets/unity-1.2.0/images/em-icons.svg#caret-down">
                    </use>
                </svg>
            </a>
            <div appClickStopPropagation
                 (click)="navigationBlock()"
                 class="navigation-block-btn"></div>
            <ul class="em-c-primary-nav__sublist em-js-nav-dropdown">
                <li class="em-c-primary-nav__subitem">
                    <a class="em-c-primary-nav__sublink"
                       routerLink="/equipment"
                       routerLinkActive="em-is-current">
                        Equipment
                    </a>
                    <a class="em-c-primary-nav__sublink"
                       routerLink="/test-equipment"
                       routerLinkActive="em-is-current">
                        Test Equipment
                    </a>
                    <a class="em-c-primary-nav__sublink"
                       routerLink="/users"
                       routerLinkActive="em-is-current">
                        Users
                    </a>
                    <a class="em-c-primary-nav__sublink"
                       routerLink="/maintenance-plan"
                       routerLinkActive="em-is-current">
                        Maintenance Plan
                    </a>
                    <ng-container *appFeatureToggle="'flamingo-viewer'">
                        <a class="em-c-primary-nav__sublink"
                           routerLink="/custom-forms"
                           routerLinkActive="em-is-current">
                            Custom Form Management
                        </a>
                    </ng-container>
                </li>
            </ul>
        </li>
    </ul>
</nav>
