import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { combineLatest, Observable } from 'rxjs'

import { CacheStatusEnum } from '@app/models/offline-status.enum'
import { EquipmentListItem, WorkOrderListItem } from '@app/modules/work-order-search/models/work-order-list-item.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { OfflineService } from '@app/services/offline.service'
import { AppState } from '@app/store/app.store'
import { isOnline } from '@app/store/connection/connection.selectors'
import { isAnyLoading } from '@app/store/loader/loader.selectors'
import { AddToDoAction, DeleteToDoAction } from '@app/store/to-do/to-do.actions'
import { SelectWorkOrderAction } from '@app/store/work-order-search/search.actions'
import { deepCopy, sortArray } from '@app/utils/app-utils.function'
import { resultListSortSetting } from '../../constants/result-list-sort.constant'
import { EquipmentStackSortingCaret } from '../../models/equipment-stack-sorting-state.model'
import { SortedState, SortingState } from '../../models/filter.model'
import { mrmaStatus } from '../../models/mrma-status-label.model'
import { EquipmentSortStackService } from '../../services/equipment-sort-stack.service'
import { FilteringService } from '../../services/filtering.service'
import { selectAutoDownloadEnabled } from '@app/store/offline/offline.selectors'
import { FormArray, FormControl } from '@angular/forms'
import { findIndex, includes } from 'lodash'

@Component({
    selector: 'app-result-list',
    templateUrl: './result-list.component.html',
    styleUrls: ['./result-list.component.scss']
})
export class ResultListComponent extends SafeUnsubscriberComponent implements OnInit, OnDestroy {
    @Input() results: WorkOrderListItem[]
    @Input() displayOfflineColumn: boolean
    @Input() isLoading: boolean
    @Input() isSelectionMode = false
    @Input() selectedWONumbers: FormArray

    public cacheStatusEnum = CacheStatusEnum
    public isOnline$: Observable<boolean>
    public isSectionLoading$: Observable<boolean>
    public sortStates: SortedState[] = resultListSortSetting
    public equipmentStackSortingCaret: EquipmentStackSortingCaret
    public defaultOrder: WorkOrderListItem[]
    public shouldDisplayNoRecord

    constructor(
        private router: Router,
        private store: Store<AppState>,
        private offlineService: OfflineService,
        private filteringService: FilteringService,
        private equipmentSortStackService: EquipmentSortStackService
    ) {
        super()
    }

    ngOnInit(): void {
        this.isOnline$ = this.store.select(isOnline)
        this.isSectionLoading$ = this.store.select(isAnyLoading)
        this.defaultOrder = this.results

        combineLatest(
            this.store.select(isOnline),
            this.store.select(selectAutoDownloadEnabled)
        ).subscribe(([online, autoDownload]) => {
            this.shouldDisplayNoRecord = !online && !autoDownload
        })
    }

    public toggleBookmark(event: Event, workOrder: WorkOrderListItem): void {
        if (workOrder.isBookmarked) {
            this.store.dispatch(new DeleteToDoAction(workOrder))
            this.offlineService.removeWorkOrderDetailsFromCache(workOrder.workOrderNumber)
        } else {
            this.store.dispatch(new AddToDoAction(workOrder))
        }
    }

    public navigateToOverview(workOrderNumber: string): void {
        this.store.dispatch(new SelectWorkOrderAction(workOrderNumber))
        this.router.navigateByUrl(`work-order/${workOrderNumber}`)
    }

    public checkWorkOrderItemCacheStatus(workOrderNumber: string): CacheStatusEnum {
        if (workOrderNumber) {
            return this.offlineService.getWorkOrderCacheStatus(workOrderNumber)
        } else {
            return CacheStatusEnum.FAILED
        }
    }

    public getNumberOfEquipment(equipments: EquipmentListItem[]): number {
        return equipments.filter(i => i.equipmentId).length
    }

    public getConsolidatedAction(equipments: EquipmentListItem[]): string[] {
        return equipments.filter(eq => eq.equipmentId)
            .reduce((consolidatedAction, individualEquipment) => {
                individualEquipment.actionRequired.forEach(action => {
                    if (action && !consolidatedAction.includes(action)) {
                        consolidatedAction.push(action)
                    }
                })
                return consolidatedAction
            }, [] as string[])
            .sort()
    }

    public applySorting(column: string): void {
        let sortedResult = this.results.slice()
        let isSorting = false

        this.sortStates = this.filteringService.configSorting(deepCopy(this.sortStates), column)
        this.sortStates.forEach(config => {
            if (config.sortingState !== SortingState.None) {
                const isDescending = config.sortingState === SortingState.Descending ? true : false
                sortedResult = sortArray(sortedResult, config.sortId, isDescending)
                isSorting = true
            }
        })

        if (!isSorting) {
            sortedResult = this.defaultOrder
        }

        this.equipmentStackSortingCaret = this.equipmentSortStackService.refreshSortingCaret(this.equipmentStackSortingCaret, column)
        this.results = sortedResult
    }

    public getDescendingState(column: string): boolean {
        return this.filteringService.isDescending(this.sortStates, column)
    }

    public getSortedState(column: string): boolean {
        return this.filteringService.isSorted(this.sortStates, column)
    }

    public getEquipmentStackSortingCaretState(state: EquipmentStackSortingCaret): void {
        this.equipmentStackSortingCaret = state
    }

    public getCalibrationStatusClass(workOrder: WorkOrderListItem): string {
        const consolidatedStatus = workOrder.status
        const responsiveClass = mrmaStatus.calibrationStatus.find(status =>
            status.name.toUpperCase() === consolidatedStatus.toUpperCase()
        )

        if (responsiveClass) {
            return responsiveClass.classResponsive
        }

        return 'calibration-not-started'
    }

    public isItemChecked(item): boolean {
        return includes(this.selectedWONumbers?.value, item['workOrderNumber'])
    }

    public onItemCheckboxClick(event: any, workOrder: WorkOrderListItem): void {
        if (this.isSelectionMode) {
            event.stopPropagation()
            const foundIndex = findIndex(this.selectedWONumbers.value, id => id === workOrder['workOrderNumber'])
            if (foundIndex !== -1) {
                this.selectedWONumbers.removeAt(foundIndex)
            } else {
                this.selectedWONumbers.push(new FormControl(workOrder['workOrderNumber']))
            }
        }
    }
}
