import { Component, Input } from '@angular/core'

import { EquipmentTemplateMapping } from '@app/modules/equipment/models/equipment-template-mapping.model'
import { WorkOrderListItem } from '@app/modules/work-order-search/models/work-order-list-item.model'

@Component({
    selector: 'app-equipment-stack-info',
    templateUrl: './equipment-stack-info.component.html',
    styleUrls: ['./equipment-stack-info.component.scss']
})
export class EquipmentStackInfoComponent {
    @Input() equipment: WorkOrderListItem | EquipmentTemplateMapping
    @Input() sortBy: string
    @Input() isPrimaryEquipment = false
}
