import { Component, Input } from '@angular/core'

import { SelectionDropdownInfoOption } from '../../models/dropdown-view-more-info.model'

@Component({
    selector: 'app-dropdown-view-more-info',
    templateUrl: './dropdown-view-more-info.component.html',
    styleUrls: ['./dropdown-view-more-info.component.scss']
})
export class DropdownViewMoreInfoComponent {
    @Input() options: SelectionDropdownInfoOption[]

    public isViewButtonOpen = false

    public toggleViewInfoDropdown(): void {
        this.isViewButtonOpen = !this.isViewButtonOpen
    }

    public optionSelected(callback: () => any, disabled: boolean): void {
        if (!disabled) {
            callback()
        }
    }
}
