import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Store } from '@ngrx/store'

import { Equipment } from '@app/modules/equipment/models/equipment.model'
import { SlideUpOverlayType } from '@app/modules/shared/constants/slide-up-overlay.enum'
import { LabelStatus } from '@app/modules/shared/models/mrma-status-label.model'
import { EquipmentCalibration } from '@app/modules/work-order/models/equipment-calibration.model'
import { WorkOrderDetails } from '@app/modules/work-order/models/work-order-details.model'
import { WorkOrderService } from '@app/modules/work-order/services/work-order.service'
import { OfflineService } from '@app/services/offline.service'
import { ScreenSizeService } from '@app/services/screen-size.service'
import { AppState } from '@app/store/app.store'
import { SlideOverlayAction } from '@app/store/overlay/overlay.actions'
import { CalibrationFormService } from '../../../shared/services/calibration-form.service'
import { CalibrationDetails } from '../../models/calibration-details.model'
import { CalibrationStatusService } from '../../services/calibration-status.service'

@Component({
    selector: 'app-calibration-overview',
    templateUrl: './calibration-overview.component.html',
    styleUrls: ['./calibration-overview.component.scss']
})
export class CalibrationOverviewComponent implements OnInit, OnDestroy {
    @Input() calibration: CalibrationDetails
    @Input() calibrationForm: FormGroup
    @Input() isReport: boolean
    @Input() equipment: EquipmentCalibration
    @Input() workOrderDetails: WorkOrderDetails
    @Input() equipmentDetails: Equipment

    public showEquipmentHistory = false
    public workOrderStatus: string
    public isPrimaryEquipment: boolean

    private isFirstEquipment: boolean
    private hasPrimaryEquipment: boolean

    constructor(
        private store: Store<AppState>,
        private calibrationStatusService: CalibrationStatusService,
        private workOrderService: WorkOrderService,
        private offlineService: OfflineService,
        public calibrationFormService: CalibrationFormService,
        public screenSizeService: ScreenSizeService
    ) { }

    public get isMobileOrTabletView(): boolean {
        return this.screenSizeService.isOnMobileOrTabletDevice()
    }

    public get calibrationStatusDisplay(): LabelStatus {
        return this.calibrationStatusService.getCalibrationStatusDisplay(this.calibration.calibrationStatus)
    }

    public get isOnline(): boolean {
        return this.offlineService.isOnline
    }

    ngOnInit(): void {
        this.workOrderStatus = this.workOrderService.calculateWorkOrderStatusPill(this.workOrderDetails.systemStatus)
        this.isFirstEquipment = this.equipment?.equipmentId === this.workOrderDetails?.equipmentCalibrations?.[0].equipmentId
        this.hasPrimaryEquipment = this.workOrderDetails?.hasPrimaryEquipment
        this.isPrimaryEquipment = this.isFirstEquipment && this.hasPrimaryEquipment
        this.clearAllOverlay()
    }

    ngOnDestroy(): void {
        this.clearAllOverlay()
    }

    private clearAllOverlay(): void {
        this.store.dispatch(new SlideOverlayAction({ visibility: false, id: SlideUpOverlayType.EquipmentDetails }))
        this.store.dispatch(new SlideOverlayAction({ visibility: false, id: SlideUpOverlayType.EquipmentLongText }))
        this.store.dispatch(new SlideOverlayAction({ visibility: false, id: SlideUpOverlayType.WorkOrderDetails }))
        this.store.dispatch(new SlideOverlayAction({ visibility: false, id: SlideUpOverlayType.WorkOrderLongText }))
        this.store.dispatch(new SlideOverlayAction({ visibility: false, id: SlideUpOverlayType.MaintenanceHistory }))
    }

    public getEquipmentNotification(notificationNumber: string): string {
        const _notificationNumber = notificationNumber ? notificationNumber : this.workOrderDetails.notification.number
        return this.workOrderService.getEquipmentNotification(_notificationNumber, this.workOrderDetails.equipmentCalibrations)
    }
}
