import { DOCUMENT } from '@angular/common'
import { Component, ElementRef, Inject, Input, OnDestroy } from '@angular/core'

import { DateRange } from '@app/modules/report/models/date-range.model'
import { toLocalMidnight } from '@app/utils/date.utils'

@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnDestroy {
    @Input() title = 'PM Performed Date'
    @Input() width = '100%'
    @Input() padding = '0.76rem 0.6rem'
    @Input() grayOutText = true
    @Input() dateRange: DateRange
    @Input() isHighlighted = false
    @Input() callback: (...args: any) => any | void

    public dateFormat = { year: 'numeric', month: '2-digit', day: '2-digit' }
    public showDateRange = false

    private clickHandler: (event: any) => void

    constructor(
        private eRef: ElementRef,
        @Inject(DOCUMENT) private document: any
    ) { }

    public get styleObj(): object {
        return {
            width: this.width,
            padding: this.padding,
            borderColor: this.isHighlighted ? '#3a397b' : '#cccccd'
        }
    }

    ngOnDestroy(): void {
        this.document.removeEventListener('click', this.clickHandler)
    }

    public toggleDropdown(): void {
        this.showDateRange = !this.showDateRange

        if (!this.showDateRange) {
            this.document.removeEventListener('click', this.clickHandler)
            return
        }

        this.clickHandler = event => {
            if (!this.eRef.nativeElement.contains(event.target)) {
                this.toggleDropdown()
            }
        }
        this.document.addEventListener('click', this.clickHandler)
    }

    public onDateChange(date: Date, fromDate: boolean): void {
        if (fromDate) {
            this.dateRange = {
                ...this.dateRange,
                from: toLocalMidnight(date)
            }
        } else {
            this.dateRange = {
                ...this.dateRange,
                to: toLocalMidnight(date)
            }
        }

        this.callback(this.dateRange)
    }
}
