<div [ngClass]="{ 'em-is-active': isOpen }"
     class="em-c-dropdown-check em-js-dropdown-check">
    <button class="em-c-btn em-c-btn--is-dropdown em-js-dropdown-trigger"
            [ngClass]="{'em-c-btn--disabled': options?.length === 0}"
            [ngStyle]="styleObj">
        <div class="em-c-btn__inner">
            <span class="em-c-btn__text"
                  [ngClass]="{'gray-out-text': !isAnyOptionSelected}">{{ selectedTitle }} {{childFilterChanged ? '*': ''}}</span>
            <svg class="em-c-icon em-c-icon--small em-c-btn__icon em-c-btn__icon-only">
                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="./assets/unity-1.2.0/images/em-icons.svg#caret-down"></use>
            </svg>
        </div>
    </button>
    <div class="em-c-dropdown-check__panel">
        <ul class="em-c-dropdown-check__list">
            <li *ngFor="let option of options; let i = index"
                class="em-c-dropdown-filter__item"
                [ngClass]="{'selected-preset': selectedIndex === i}"
                [attr.data-cy]="'preset-select-dropdown-option-' + i"
                (click)="onOptionSelected(i)">
                <svg *ngIf="selectedIndex === i"
                     class="em-c-icon em-c-icon--small em-c-btn__icon em-c-btn__icon-only">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink"
                         xlink:href="./assets/unity-1.2.0/images/em-icons.svg#check"></use>
                </svg>
                <span>{{option.name}}</span>
                <div class="view-more-options">
                    <svg class="em-c-icon em-c-icon--small em-c-btn__icon em-c-btn__icon-only">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink"
                             xlink:href="./assets/unity-1.2.0/images/em-icons.svg#more-horizontal"></use>
                    </svg>
                    <div class="options">
                        <p (click)="editFilter(option.id, option.name)"
                           data-cy="preset-rename-button"
                           appClickStopPropagation>
                            <svg class="em-c-icon em-c-icon--small em-c-btn__icon em-c-btn__icon-only">
                                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                                     xlink:href="./assets/unity-1.2.0/images/em-icons.svg#edit"></use>
                            </svg>
                            Rename preset
                        </p>
                        <p (click)="deleteFilter(option.id, option.name)"
                           data-cy="preset-delete-button"
                           appClickStopPropagation>
                            <svg class="em-c-icon em-c-icon--small em-c-btn__icon em-c-btn__icon-only">
                                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                                     xlink:href="./assets/unity-1.2.0/images/em-icons.svg#trashcan"></use>
                            </svg>
                            Delete preset
                        </p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>