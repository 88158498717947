import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core'
import { Store } from '@ngrx/store'

import { SelectionDrawerName, SelectionDrawerOption } from '@app/modules/shared/models/selection-drawer.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import { ToggleSelectionDrawerAction } from '@app/store/selection-drawer/selection-drawer.actions'
import { selectionDrawerSettings } from '@app/store/selection-drawer/selection-drawer.selectors'


@Component({
    selector: 'app-selection-drawer',
    templateUrl: './selection-drawer.component.html',
    styleUrls: ['./selection-drawer.component.scss']
})
export class SelectionDrawerComponent extends SafeUnsubscriberComponent implements OnInit, AfterViewInit {
    @ViewChild('drawer', { static: true }) el: ElementRef
    @ViewChild('background', { static: true }) background: ElementRef

    @Input() drawerName: SelectionDrawerName

    // FIXME: currentSelection should not be a part of this array...
    // else, we need to loop the array everytime we want to change it.
    @Input() selectionOptions: SelectionDrawerOption[]

    constructor(private store: Store<AppState>, private renderer: Renderer2) {
        super()
    }

    ngOnInit(): void {
        this.addSubscription(
            this.store.select(selectionDrawerSettings).subscribe(drawerStates => {
                const currentDrawerState = drawerStates.find(x => x.name === this.drawerName)
                if (currentDrawerState?.visibility) {
                    this.drawerSlideUp()
                } else {
                    this.drawerSlideDown()
                }
            })
        )
    }

    ngAfterViewInit(): void {
        this.drawerSlideDown()
    }

    public optionSelected(callback: () => any, disabled: boolean, allowDisabledDrawerCallback: boolean): void {
        this.close()
        if (!disabled || allowDisabledDrawerCallback) {
            callback()
        }
    }

    public close(): void {
        this.store.dispatch(new ToggleSelectionDrawerAction({
            name: this.drawerName,
            visibility: false
        }))
    }

    private drawerSlideDown(): void {
        this.renderer.setStyle(this.background.nativeElement, 'opacity', '0')
        this.renderer.setStyle(this.background.nativeElement, 'visibility', 'hidden')
        this.renderer.setStyle(this.el.nativeElement, 'bottom', -this.el.nativeElement.offsetHeight + 'px')
        this.renderer.setStyle(this.el.nativeElement, 'visibility', 'hidden')
    }

    private drawerSlideUp(): void {
        this.renderer.setStyle(this.background.nativeElement, 'visibility', 'visible')
        this.renderer.setStyle(this.background.nativeElement, 'opacity', '80%')
        this.renderer.setStyle(this.el.nativeElement, 'bottom', 0)
        this.renderer.setStyle(this.el.nativeElement, 'visibility', 'visible')
    }
}
