import { Injectable } from '@angular/core'
import { FormArray, FormBuilder, FormGroup } from '@angular/forms'

import { CalculationType } from '@app/modules/equipment/models/calculation-type.model'
import { UnitRange } from '@app/modules/shared/models/engineering-units/unit-range.model'
import { isNotAValue, round } from '@app/utils/app-utils.function'
import { CalculationExpectedResult } from '../models/calculation-expected-result.model'
import { CalculationStrategy } from '../models/calculcation-strategy.model'
import { CalibrationResultStatusEnum } from '../models/calibration-result-status.enum'
import { CalibrationValidationStatus } from '../models/calibration-validation-status.enum'
import { SequentialTemplate } from '../models/sequential-template.model'
import { ICalculationStrategy, RangeCalculationService } from './range-calculation.service'

@Injectable({
    providedIn: 'root'
})
export class SequentialCalibrationService {
    constructor(
        private rangeCalculator: RangeCalculationService,
        private formBuilder: FormBuilder
    ) { }

    public updateCalibrationResult(calibrationForm: FormArray, template: SequentialTemplate): CalibrationResultStatusEnum {
        let asFound = 0
        let asLeft = 0

        calibrationForm.controls.forEach((control: FormGroup) => {
            const asFoundStatus = this.validateInput(true, control, template)
            const asLeftStatus = this.validateInput(false, control, template)
            asFound += asFoundStatus === CalibrationValidationStatus.Valid ? 1 : 0
            asLeft += asLeftStatus === CalibrationValidationStatus.Valid ? 1 : 0
        })

        if (asFound === template.numberOfPoint && asLeft === template.numberOfPoint) {
            return CalibrationResultStatusEnum.Passed
        }

        if (asLeft === template.numberOfPoint) {
            return CalibrationResultStatusEnum.FailedAdjustedPassed
        }

        return CalibrationResultStatusEnum.Failed
    }

    public updateExpectedResults(calibrationForm: FormArray, template: SequentialTemplate): void {
        calibrationForm.controls.forEach((control: FormGroup) => {
            const results = this.inputAndExpectedCalculation(control, template)
            control.patchValue({
                asFoundExpected: results.expectedText,
                asLeftExpected: results.adjustedExpectedText
            }, { emitEvent: false })
        })
    }

    public fillResultSet(calibrationForm: FormArray, template: SequentialTemplate): void {
        calibrationForm.controls.forEach((control: FormGroup) => {
            const results = this.inputAndExpectedCalculation(control, template)
            control.addControl('input', this.formBuilder.control(results.input))
            control.addControl('asFoundExpected', this.formBuilder.control(results.expectedText))
            control.addControl('asLeftExpected', this.formBuilder.control(results.adjustedExpectedText))
        })
    }

    public validateInput(asFound: boolean, form: FormGroup, template: SequentialTemplate): CalibrationValidationStatus {
        const result = this.inputAndExpectedCalculation(form, template)
        if ((asFound && isNotAValue(result.asFound)) || (!asFound && isNotAValue(result.asLeft))) {
            return CalibrationValidationStatus.Initialize
        }

        let valid: boolean
        if (asFound) {
            valid = this.rangeCalculator.calculateInTolerance(result.expected, result.asFound)
        } else {
            valid = this.rangeCalculator.calculateInTolerance(result.adjustedExpected, result.asLeft)
        }

        return valid ? CalibrationValidationStatus.Valid : CalibrationValidationStatus.Invalid
    }

    public autoPopulate(calibrationForm: FormArray, template: SequentialTemplate): void {
        let isValid = 0
        calibrationForm.controls.forEach((control: FormGroup) => {
            const status = this.validateInput(true, control, template)
            isValid += status === CalibrationValidationStatus.Valid ? 1 : 0
        })

        let asLeftCompleted = 0
        calibrationForm.controls.forEach(control =>
            asLeftCompleted += isNotAValue(control.get('asLeft').value) ? 0 : 1
        )

        if (isValid === template.numberOfPoint && asLeftCompleted === 0) {
            calibrationForm.controls.forEach((control: FormGroup) => {
                control.patchValue({
                    adjustedInjectedInput: control.value.injectedInput,
                    asLeft: control.value.asFound
                })
            })
        }
    }

    private inputAndExpectedCalculation(form: FormGroup, template: SequentialTemplate): CalculationExpectedResult {
        const pointNumber = form.get('pointNumber').value
        const injectedValue = form.get('injectedInput').value
        const adjustedInjectValue = form.get('adjustedInjectedInput').value
        const intputCalculationStrategy = this.setCalculationStrategy(true, template)
        const expectedCalculationStrategy = this.setCalculationStrategy(false, template)

        let injectedRange = this.setRangeValue(injectedValue, template.expected)
        let adjustedInjectedRange = this.setRangeValue(adjustedInjectValue, template.expected)

        const inputValue = this.rangeCalculator.calculatePoint(intputCalculationStrategy, template.input, pointNumber)
        let expectedValue = this.rangeCalculator.calculateRange(expectedCalculationStrategy, injectedRange, pointNumber)
        let adjustedExpectValue = this.rangeCalculator.calculateRange(expectedCalculationStrategy, adjustedInjectedRange, pointNumber)

        if (!isNotAValue(injectedValue)) {
            const injectedResult = this.rangeCalculator.calculateInjectExpectedResult(
                injectedValue, template.input, template.expected, template.calculationType.id
            )
            injectedRange = this.setRangeValue(injectedResult, template.input)
            expectedValue = this.rangeCalculator.calculateRange(expectedCalculationStrategy, injectedRange, 1)

            if (template.tolerance.unitOfMeasurement.uomCodeForTech === '%') {
                const tolerance = this.rangeCalculator.calculateTolerance(template.tolerance, template.expected)
                expectedValue.min = round(expectedValue.min - tolerance)
                expectedValue.max = round(expectedValue.max + tolerance)
            }
        }

        if (!isNotAValue(adjustedInjectValue)) {
            const injectedResult = this.rangeCalculator.calculateInjectExpectedResult(
                adjustedInjectValue, template.input, template.expected, template.calculationType.id
            )
            adjustedInjectedRange = this.setRangeValue(injectedResult, template.input)
            adjustedExpectValue = this.rangeCalculator.calculateRange(expectedCalculationStrategy, adjustedInjectedRange, 1)

            if (template.tolerance.unitOfMeasurement.uomCodeForTech === '%') {
                const tolerance = this.rangeCalculator.calculateTolerance(template.tolerance, template.expected)
                adjustedExpectValue.min = round(adjustedExpectValue.min - tolerance)
                adjustedExpectValue.max = round(adjustedExpectValue.max + tolerance)
            }
        }

        return {
            input: inputValue,
            expected: { minimumRange: expectedValue.min, maximumRange: expectedValue.max },
            asFound: form.get('asFound').value,
            asLeft: form.get('asLeft').value,
            adjustedExpected: { minimumRange: adjustedExpectValue.min, maximumRange: adjustedExpectValue.max },
            expectedText: expectedValue.min + ' to ' + expectedValue.max,
            adjustedExpectedText: adjustedExpectValue.min + ' to ' + adjustedExpectValue.max
        } as CalculationExpectedResult
    }

    private setCalculationStrategy(atInput: boolean, template: SequentialTemplate): ICalculationStrategy {
        let calculationTypeId = CalculationStrategy.Linear

        if (atInput) {
            calculationTypeId = template.calculationType.id === CalculationStrategy.SquareRootAtInput
                ? CalculationStrategy.SquareRootAtInput : CalculationStrategy.Linear
        } else {
            calculationTypeId = template.calculationType.id === CalculationStrategy.SquareRootAtOutput
                ? CalculationStrategy.SquareRootAtOutput : CalculationStrategy.Linear
        }

        return {
            numberOfPoint: template.numberOfPoint,
            calculationType: { id: calculationTypeId } as CalculationType,
            tolerance: template.tolerance
        }
    }

    private setRangeValue(injected: number, defaultRange: UnitRange): UnitRange {
        if (isNotAValue(injected)) {
            return defaultRange
        }

        return {
            minimumRange: injected,
            maximumRange: injected
        } as UnitRange
    }
}
