import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'

import { TemplateTypeEnum } from '@app/modules/equipment/models/template-type.enum'
import { scw } from '@app/utils/classes/simple-changes-wrapper'

@Component({
    selector: 'app-calibration-template-header',
    templateUrl: './calibration-template-header.component.html',
    styleUrls: ['./calibration-template-header.component.scss']
})
export class CalibrationTemplateHeaderComponent implements OnChanges {
    @Input() template: any
    @Input() templateType: TemplateTypeEnum

    public isWeightTemplate = false
    public isSinglePoint = false

    ngOnChanges(changes: SimpleChanges): void {
        scw(changes)
            .forChangeInAny(['templateType', 'template'])
            .do(() => this.setupViewParams())
    }

    public getRange(field: string): { minimumRange: number, maximumRange: number } {
        const range = (this.template[field] ?? null)
        return range ?? { minimumRange: undefined, maximumRange: undefined }
    }

    public getUomCodeForTech(field: string): string {
        const uom = this.template[field]?.unitOfMeasurement?.uomCodeForTech
        return uom ? ` (${uom})` : ''
    }

    private setupViewParams(): void {
        this.isWeightTemplate = this.templateType === TemplateTypeEnum.weightFourPoint
            || this.templateType === TemplateTypeEnum.weightSixPoint

        this.isSinglePoint = !(this.template?.numberOfPoint !== 1 && !this.isWeightTemplate)

    }
}
