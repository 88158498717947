<section class="web-view">
    <app-calibration-equipment-info [equipment]="equipment"
                                    [calibration]="calibration"
                                    [calibrationForm]="calibrationForm"
                                    [isReport]="isReport"
                                    [workOrderDetails]="workOrderDetails"
                                    [isPrimaryEquipment]="isPrimaryEquipment"></app-calibration-equipment-info>
</section>
<section class="responsive-view">
    <app-section [title]="'Work Information'">
        <div body
             class="responsive-calibration-field-container">
            <div class="calibration-info">
                <label>Work Order</label>
                <section>
                    <p class="inline">{{workOrderDetails.workOrderNumber}}</p>
                    <p class="label"
                       [ngClass]="workOrderStatus === 'open' ? 'label-warning' : 'label-default'"
                       *ngIf="!isReport">
                        {{workOrderStatus}}
                    </p>
                </section>
            </div>
            <div class="calibration-info notification">
                <app-notification-status [notificationNumber]="getEquipmentNotification(equipment.equipmentNotificationNumber)"
                                         [needTooltip]="isMobileOrTabletView"
                                         notificationType="equipment"></app-notification-status>
            </div>
            <div class="calibration-info">
                <label>PM Status</label>
                <p [class]="calibrationStatusDisplay.classLabel">{{calibrationStatusDisplay.name}}</p>
                <p *ngIf="calibration.dataSource === 'GRT'"
                   class="label label-grt">{{calibration.dataSource}}</p>
            </div>
            <div class="calibration-info">
                <label>PM Result</label>
                <p [ngClass]="{'status-text-error': calibrationFormService.isPMDeferredOrNotTested$ | async}">
                    {{(calibrationFormService.resultsValue$ | async)?.finalPMResultStatus?.name}}
                </p>
            </div>
            <div class="calibration-info equipment-id">
                <label>Equipment ID</label>
                <p class="inline">{{ equipment?.equipmentId || '-' }}</p>
                <p class="primary-pill label"
                   *ngIf="isPrimaryEquipment">Primary</p>
            </div>
            <div class="calibration-info">
                <label>Equipment Tag</label>
                <p>{{ equipment?.equipmentTag || '-' }}</p>
            </div>
            <div class="calibration-info">
                <label>Equipment Tech ID</label>
                <p>{{ equipment?.techIdentificationNumber || '-' }}</p>
            </div>
            <div class="calibration-info">
                <label>Functional Location</label>
                <p>{{ equipment?.functionalLocation || '-' }}</p>
            </div>
        </div>
    </app-section>
</section>

<!-- overlay page -->

<app-slide-up-overlay id="EquipmentDetails">
    <app-overlay-equipment-details [equipmentDetail]="equipmentDetails"></app-overlay-equipment-details>
</app-slide-up-overlay>

<app-slide-up-overlay id="EquipmentLongText">
    <app-overlay-longtext-details [category]="'Equipment'"
                                  [itemId]="equipmentDetails?.equipmentId"
                                  [itemDescription]="equipmentDetails?.description"
                                  [longText]="equipmentDetails?.longText"></app-overlay-longtext-details>
</app-slide-up-overlay>

<app-slide-up-overlay id="WorkOrderDetails">
    <app-overlay-workorder-details [workOrderDetails]="workOrderDetails"
                                   [isReport]="isReport"
                                   [lazyLoadNotification]="false"></app-overlay-workorder-details>
</app-slide-up-overlay>

<app-slide-up-overlay id="WorkOrderLongText">
    <app-overlay-longtext-details [category]="'Work Order'"
                                  [itemId]="workOrderDetails?.workOrderNumber"
                                  [itemDescription]="workOrderDetails?.description"
                                  [longText]="workOrderDetails?.longText"></app-overlay-longtext-details>
</app-slide-up-overlay>

<app-slide-up-overlay id="MaintenanceHistory">
    <app-overlay-maintenance-history></app-overlay-maintenance-history>
</app-slide-up-overlay>

<app-slide-up-overlay id="NoteToTechnician">
    <app-overlay-notetotech-details [equipmentId]="equipmentDetails?.equipmentId"
                                    [equipmentDescription]="equipmentDetails?.description"
                                    [note]="equipment?.equipmentNote">
    </app-overlay-notetotech-details>
</app-slide-up-overlay>

<app-slide-up-overlay id="EquipmentHistory">
    <app-equipment-history-mobile [fetchEquipmentHistory]="showEquipmentHistory"></app-equipment-history-mobile>
</app-slide-up-overlay>

<app-slide-up-overlay id="Hyperlink">
    <app-overlay-hyperlinks [equipmentId]="equipmentDetails?.equipmentId"></app-overlay-hyperlinks>
</app-slide-up-overlay>