<div class="table">
    <div class="table__header">
        <app-search (searchTextChangeWithDebounce)="applySearch($event)"
                    (searchTextSubmit)="applySearch($event)"
                    width="100%"
                    placeHolder="Search form name, form description"
                    [showButton]="false"></app-search>
    </div>

    <app-api-paged-list [pagedListDataSource]="pagedListDataSource"
                        [numberOfItems]="totalCount$ | async"
                        [clickable]="clickable"
                        (clickItem)="itemClicked($event)">
        <ng-template #header>
            <ng-container *ngTemplateOutlet="headerCell; context: {title: 'FORM TITLE', headerKey: 'name', classes: 'em--no-wrap'}">
            </ng-container>
            <ng-container *ngTemplateOutlet="headerCell; context: {title: 'MODIFIED BY', headerKey: 'modifiedByName', classes: 'em-u-width-40'}">
            </ng-container>
            <ng-container *ngTemplateOutlet="headerCell; context: {title: 'MODIFIED DATE', headerKey: 'modifiedDate', classes: 'em-u-width-40'}">
            </ng-container>
        </ng-template>

        <ng-template #content
                     let-flamingoForm>
            <td class="em-c-table__cell hcp__cell--highlight table-cell">
                {{ flamingoForm.name }}
            </td>
            <td class="em-c-table__cell em-u-width-40 table-cell">
                {{ flamingoForm.modifiedByName }}
            </td>
            <td class="em-c-table__cell em-u-width-40 table-cell">
                {{ flamingoForm.modifiedDate | date: 'MMM dd, yyyy' }}
            </td>
        </ng-template>
    </app-api-paged-list>
</div>

<!-----------------------------
    Header cell template
------------------------------->
<ng-template #headerCell
             let-title="title"
             let-headerKey="headerKey"
             let-classes="classes">
    <th scope="col"
        (click)="applySorting(headerKey)"
        class="em-c-table__header-cell em-u-clickable"
        [ngClass]="classess">
        {{title}}
        <app-sorting-caret [isSorted]="sortBy===headerKey"
                           [isDescending]="isDesc && sortBy===headerKey">
        </app-sorting-caret>
    </th>
</ng-template>
<!------------------------------>
