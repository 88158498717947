import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import { CalibrationResultStatusEnum } from '@app/modules/calibration/models/calibration-result-status.enum'
import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { ProcessConfiguration } from '@app/modules/equipment/models/process.model'
import { equipmentTemplateList } from '@app/store/equipment/selectors/equipment-template.selectors'
import { configuration } from '@app/store/equipment/selectors/process.selectors'
import { isNotAValue } from '@app/utils/app-utils.function'

@Injectable({
    providedIn: 'root'
})
export class AppService {
    private processConfigurations: ProcessConfiguration[]
    private templateDetails: EquipmentTemplateCoreDetails[]

    private isNotificationRequiredMemoize = {}

    constructor(private store: Store<AppService>) {
        this.store.select(configuration).subscribe(config =>
            this.processConfigurations = config
        )
        this.store.select(equipmentTemplateList).subscribe(templates =>
            this.templateDetails = templates)
    }

    public isSupervisoryReviewEnable(processId: number): boolean {
        return !!(this.processConfigurations.find(config => config.processId === processId)?.isReviewEnabled)
    }

    public isNotificationRequired(args: {
        processId?: number
        templateId?: number,
        resultStatus: number
    }): boolean {

        // The result is unlikely to change often so let's cache it.
        const memoizeKey = `${args.processId}-${args.templateId}-${args.resultStatus}`
        if (this.isNotificationRequiredMemoize?.hasOwnProperty(memoizeKey)) {
            return this.isNotificationRequiredMemoize[memoizeKey]
        }
        // Delete the cache automatically after a period of time
        setInterval(() => {
            delete this.isNotificationRequiredMemoize[memoizeKey]
        }, 300000)

        let processId = args.processId

        // If a template exist, prefer that over the given `processId`
        if (!isNotAValue(args.templateId)) {
            const template = this.templateDetails.find(t => t.id === args.templateId)
            if (template) {
                processId = template.processId
            }
        }

        const processConfig = this.processConfigurations.find(c => c.processId === processId)
        if (!processConfig || !(processConfig?.isNotificationEnabled ?? false)) {
            this.isNotificationRequiredMemoize[memoizeKey] = false
            return false
        }

        if (!(processConfig?.notifyOnAsFoundFailure ?? false)
            && !(processConfig?.notifyOnAsLeftFailure ?? false)) {
            this.isNotificationRequiredMemoize[memoizeKey] = true
            return true
        }

        if ((processConfig?.notifyOnAsFoundFailure ?? false)
            && args.resultStatus === Number(CalibrationResultStatusEnum.FailedAdjustedPassed)) {
            this.isNotificationRequiredMemoize[memoizeKey] = true
            return true
        }

        if ((processConfig?.notifyOnAsLeftFailure ?? false)
            && args.resultStatus === Number(CalibrationResultStatusEnum.Failed)) {
            this.isNotificationRequiredMemoize[memoizeKey] = true
            return true
        }

        this.isNotificationRequiredMemoize[memoizeKey] = false
        return false
    }
}
