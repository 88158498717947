<nav role="navigation" class="tab-container">
    <ul class="em-c-tree__list em-c-tree__list--flush"
        role="tablist">
        <li class="tab" (click)="onClickTab(tabIndex)"
            *ngFor="let tab of tabs; let tabIndex = index" [ngClass]="{'tab-selected': selectedTabIndex === tabIndex}">
            <span class="subscription-status" [ngClass]="tab.value?.isNotificationEnable === 'true' ? 'open' : 'close'"></span>
            <a class="tab-content" [ngClass]="{ 'is-editing': tab.touched }">
                <span class="em-c-tree__text template-type">{{ tab.value?.subscriptionName }}</span>
            </a>
            <app-icon-button *ngIf="tabContentIconName" class="tab-icon"
                             appClickStopPropagation
                             (click)="onClickTabIcon(tabIndex)"
                             [extraStyle]="{'color': 'black'}"
                             [unitySvgName]="tabContentIconName"></app-icon-button>
        </li>
        <li class="em-c-tree__item action-area" *ngIf="extraButtonText">
            <app-icon-button unitySvgName="circle-plus" [extraStyle]="{'text-align': 'start'}"
                             (click)="onClickAction()">{{extraButtonText}}</app-icon-button>
        </li>
    </ul>
</nav>

