import { Component, OnInit, Input } from '@angular/core'

import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { DestroyModalAction } from '@app/store/modal/modal.actions'
import { modal } from '@app/store/modal/modal.selectors'
import { Modal } from '@app/modules/modal-container/models/modal.model'
import { DeleteFilterPresetAction } from '@app/store/report/report.actions'
import { Store } from '@ngrx/store'

@Component({
    selector: 'app-report-filter-delete',
    templateUrl: './report-filter-delete.component.html',
    styleUrls: ['./report-filter-delete.component.scss']
})
export class ReportFilterPresetDeleteComponent extends SafeUnsubscriberComponent implements OnInit {
    @Input() params: any

    public presetName: string = null
    public title: string = null

    constructor(private store: Store) {
        super()
    }

    ngOnInit(): void {
        this.addSubscription(
            this.store.select(modal).subscribe((modalState: Modal) => {
                if (modalState) {
                    this.title = modalState.title
                }
            })
        )

        this.presetName = this.params?.name
    }
    public delete(): void {
        this.store.dispatch(new DeleteFilterPresetAction({ id: this.params.id }))
        this.close()
    }

    public close(): void {
        this.store.dispatch(new DestroyModalAction())
    }
}
