<div class="slide-up-overlay">
    <app-overlay-header-info [header]="'Work Order Details'"
                             [subHeader]="'Work Order'"
                             [itemId]="workOrderDetails?.workOrderNumber"
                             [itemDescription]="workOrderDetails?.description"
                             [overlayId]="overlayId"></app-overlay-header-info>
    <div class="body">
        <div class="em-l-container">
            <hr class="details-line">
            <div class="web-single-line">
                <div class="calibration-info">
                    <label>Work Order</label>
                    <p class="inline"> {{workOrderDetails?.workOrderNumber || '-'}}
                        <span class="label status-icon-size"
                              [ngClass]="workOrderStatus === 'open' ? 'label-warning' : 'label-default'"
                              *ngIf="!isReport">
                            {{workOrderStatus}}
                        </span>
                    </p>

                </div>
                <div class="calibration-info">
                    <label>Work Order Type</label>
                    <p> {{workOrderDetails?.workOrderType || '-'}} </p>
                </div>
                <div class="calibration-info">
                    <app-notification-status
                                             [notificationNumber]="getPrimaryNotification(workOrderDetails.notification.number)"></app-notification-status>
                </div>
                <div class="calibration-info">
                    <label>Notification Type</label>
                    <p> {{workOrderDetails?.notification.type || '-'}} </p>
                </div>
            </div>
            <hr class="details-line responsive-line">
            <div class="web-single-line light-grey-background">
                <div class="calibration-info">
                    <label>System Status</label>
                    <p> {{workOrderDetails?.systemStatus || '-'}} </p>
                </div>
                <div class="calibration-info">
                    <label>User Status</label>
                    <p> {{workOrderDetails?.userStatus || '-'}} </p>
                </div>
                <!-- TODO: Add User Status without Status No. later on -->
                <!-- <div class="calibration-info">
                    <label>User Status without Status No.</label>
                    <p> NOT INCLUDED YET </p>
                    <h6 class="description">Description</h6>
                </div> -->
                <div class="calibration-info">
                    <label>Criticality</label>
                    <p> {{workOrderDetails?.abcIndicator || '-'}} </p>
                    <h6 class="description">{{workOrderDetails?.abcDescription}}</h6>
                </div>
            </div>
            <hr class="details-line">
            <div class="web-single-line">
                <div class="calibration-info">
                    <label>Planning Plant</label>
                    <p>{{workOrderDetails?.plantCode || '-'}}</p>
                    <h6 class="description">{{workOrderDetails?.plantDesc}}</h6>
                </div>
                <div class="calibration-info">
                    <label>Planner Group</label>
                    <p>{{workOrderDetails?.plannerGroup || '-'}}</p>
                    <h6 class="description">{{workOrderDetails?.plannerGroupDesc}}</h6>
                </div>
                <div class="calibration-info">
                    <label>Main Work Center</label>
                    <p> {{workOrderDetails?.mainWorkCenter || '-'}} </p>
                    <h6 class="description">{{workOrderDetails?.mainWorkCenterDesc}}</h6>
                </div>
            </div>
            <hr class="details-line responsive-line">
            <div class="web-single-line light-grey-background">
                <div class="calibration-info">
                    <label>Maintenance Plant</label>
                    <p> {{workOrderDetails?.maintenancePlant || '-'}} </p>
                    <h6 class="description">{{workOrderDetails?.maintenancePlantDesc}}</h6>
                </div>
                <div class="calibration-info">
                    <label>Plant Section</label>
                    <p> {{workOrderDetails?.plantSection || '-'}} </p>
                    <h6 class="description">{{workOrderDetails?.plantSectionDesc}}</h6>
                </div>
                <div class="calibration-info">
                    <label>Location</label>
                    <p> {{workOrderDetails?.location || '-'}} </p>
                    <h6 class="description">{{workOrderDetails?.locationDesc}}</h6>
                </div>
            </div>
            <hr class="details-line">
            <div class="web-single-line">
                <div class="calibration-info">
                    <label>Priority</label>
                    <p> {{workOrderDetails?.priority || '-'}} </p>
                </div>
                <div class="calibration-info">
                    <label>Basic Start Date</label>
                    <p> {{(workOrderDetails?.basicStartDate | date: 'MMM dd, yyyy':'+0000') || '-'}} </p>
                </div>
                <div class="calibration-info">
                    <label>Basic Finished Date</label>
                    <p> {{(workOrderDetails?.basicFinishDate | date: 'MMM dd, yyyy':'+0000') || '-'}} </p>
                </div>
                <div class="calibration-info">
                    <label>LACD</label>
                    <p>{{(workOrderDetails?.lacd | date: 'MMM dd, yyyy':'+0000') || '-'}}</p>
                </div>
            </div>
            <hr class="details-line responsive-line">
            <div class="web-single-line light-grey-background">
                <div class="calibration-info">
                    <label>Maintenance Plan</label>
                    <p> {{workOrderDetails?.pmPlan || '-'}} </p>
                </div>
                <div class="calibration-info">
                    <label>Maintenance Plan Interval</label>
                    <p> {{workOrderDetails?.pmFrequency || '-'}} </p>
                </div>
                <div class="calibration-info">
                    <label>Maintenance Item</label>
                    <p> {{workOrderDetails?.pmPlanItem || '-'}} </p>
                </div>
            </div>
        </div>
    </div>
</div>