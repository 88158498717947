import { FormControlError } from '@app/modules/shared/models/form-control-error.model'
import { FormValidatorResult } from '@app/modules/shared/models/form-validator-result.model'
import { humanizeStr } from '@app/utils/strings/humanize-str'
import { FormErrorHandler } from './abstract-form-error-handler'

export class ValueOutOfRangeErrorHandler extends FormErrorHandler {
    public handle(formControlError: FormControlError, results: any, ...args: any): FormValidatorResult {
        if (formControlError.errorKey !== 'ValueOutOfRange') {
            return this.next?.handle(formControlError, results, ...args)
        }

        if (!formControlError.errorValue.allowSubmit) {
            const errorMessage =
                humanizeStr`The value in field '${formControlError.controlName}' is out-of-range.`

            return {
                ...results,
                preventSubmit: false,
                errorMessages: [
                    ...results.errorMessages, errorMessage
                ]
            }
        }

        return results
    }
}
