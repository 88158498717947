import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { Action, Store } from '@ngrx/store'
import { Observable } from 'rxjs'

import { CalibrationDetails } from '@app/modules/calibration/models/calibration-details.model'
import { modalContent } from '@app/modules/modal-container/constants/modal-message-content.constant'
import { modalMessageBody } from '@app/modules/modal-container/constants/modal-message.constant'
import { SectionLoaderHelperComponent } from '@app/modules/shared/components/section-loader/section-loader-helper-component'
import { SectionLoaderEnum } from '@app/modules/shared/models/section-loader.enum'
import { SectionLoaderService } from '@app/modules/shared/services/section-loader.service'
import { AttachmentService } from '@app/services/attachment.service'
import { AppState } from '@app/store/app.store'
import { attachments } from '@app/store/attachment/attachment.selectors'
import { isOnline } from '@app/store/connection/connection.selectors'
import { isLoading } from '@app/store/loader/loader.selectors'
import { ShowModalAction } from '@app/store/modal/modal.actions'
import { deepCopy } from '@app/utils/app-utils.function'
import { Attachment } from '../../models/attachment.model'

@Component({
    selector: 'app-attachment',
    templateUrl: './attachment.component.html',
    styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent extends SectionLoaderHelperComponent implements OnInit, OnChanges {
    @Input() isAttachmentAllowed = true
    @Input() isAttachmentRequired = false
    @Input() isReport = false
    @Input() isUserAuthorized = false
    @Input() calibration: CalibrationDetails
    @Input() getAttachmentAction: Action
    @Output() uploadAttachment = new EventEmitter<File>()
    @Output() replaceAttachment = new EventEmitter<{ attachmentId: string, file: File }>()
    @Output() deleteAttachment = new EventEmitter<string>()

    public isOnline$: Observable<boolean>
    public isOnline: boolean
    public attachments: Attachment[] = []
    public editAttachmentMode = false

    @ViewChild('fileInput') fileInput: ElementRef

    constructor(
        private store: Store<AppState>,
        private attachmentService: AttachmentService,
        sectionLoaderService: SectionLoaderService,
    ) {
        super(sectionLoaderService)
    }
    @ViewChild('attachment') set sectionLoader(ref: ElementRef) {
        if (!this.sectionLoaderRef) {
            this.registerSectionLoader(SectionLoaderEnum.Attachment, ref)
        }
    }

    ngOnInit(): void {
        this.isOnline$ = this.store.select(isOnline)

        this.addSubscription(this.store.select(attachments).subscribe(files => {
            if (this.isAttachmentAllowed) {
                this.attachments = files
            }
        }))

        this.addSubscription(this.store.select(isLoading).subscribe(loading => this.editAttachmentMode = loading ? false : this.editAttachmentMode))

        this.addSubscription(this.isOnline$.subscribe(online => {
            this.isOnline = online
            this.dispatchAttachment()
        }))
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.calibration?.currentValue) {
            this.dispatchAttachment()
        }
    }

    public confirmDeleteAttachment(
        attachmentId: string,
        attachmentName: string
    ): void {
        if (!this.isUserAuthorized) {
            return
        }

        const modal = deepCopy(modalContent.confirmAttachmentDeletion)
        modal.body = modalMessageBody.confirmDeleteAttachment.body.replace('[attachmentName]', attachmentName)
        modal.confirmCallback = () => this._deleteAttachment(attachmentId)
        this.store.dispatch(new ShowModalAction(modal))
    }

    public inputFilesSelected(event: any): void {
        const files: File[] = event.currentTarget.files

        if (!files || files.length === 0) {
            this.clearFileInput()
            return
        }

        const file = files[0]
        let isFilenameDup = false
        let attachmentId = null
        let i = 0

        if (this.attachments.length > 0) {
            for (i; i < this.attachments.length; i++) {
                if (this.attachments[i].filename === file.name) {
                    isFilenameDup = true
                    attachmentId = this.attachments[i].attachmentID
                    break
                }
            }
        }

        if (isFilenameDup) {
            const modal = deepCopy(modalContent.confirmAttachmentReplace)
            modal.body = modalMessageBody.confirmAttachmentReplace.body.replace('[fileName]', file.name)
            modal.confirmCallback = () => this._replaceAttachment(attachmentId, file)
            this.store.dispatch(new ShowModalAction(modal))
        } else {
            this._uploadAttachment(file)
        }

        this.clearFileInput()
    }

    public selectAttachmentFile(): void {
        if (!this.isUserAuthorized || !this.isAttachmentAllowed) {
            return
        }

        this.fileInput.nativeElement.click()
    }

    public hasAttachment(): boolean {
        return this.attachments && this.attachments.length > 0
    }

    public switchEditMode(): void {
        if (!this.isUserAuthorized) {
            return
        }

        if (this.attachments.length !== 0) {
            this.editAttachmentMode = !this.editAttachmentMode
        } else {
            this.editAttachmentMode = false
        }
    }

    public viewAttachment(attachmentBlobUrl: string): void {
        if (!this.isOnline) {
            return
        }
        this.attachmentService.getAttachment(attachmentBlobUrl)
    }

    private dispatchAttachment(): void {
        if (this.isOnline === true && this.isAttachmentAllowed) {
            this.store.dispatch(this.getAttachmentAction)
        }
    }

    private clearFileInput(): void {
        this.fileInput.nativeElement.value = ''
    }

    private _deleteAttachment(attachmentId: string): void {
        this.deleteAttachment.emit(attachmentId)
    }

    private _replaceAttachment(attachmentId: string, file: File): void {
        this.replaceAttachment.emit({ attachmentId, file })
    }

    private _uploadAttachment(file: File): void {
        this.uploadAttachment.emit(file)
    }
}
