import { Injectable } from '@angular/core'
import { Subject, Subscription } from 'rxjs'

import { AbstractCalibrationTemplateComponent } from '../components/calibration-template/abstract-calibration-template.component'

// TODO: Eventually, all of these should be controlled by NgRX --> using reducers/effect
// to trigger the hooks.

/**
 * Handle life cycle calls for Calibration Template.
 * Allow other components to notify the Calibration Template of life cycle events
 * that the notifying component is about to cause (e.g. when the parent is about
 * to save or complete a calibration and need the CalibrationTemplateComponent
 * to ensure that all data is up-to-date)
 */
@Injectable({ providedIn: 'root' })
export class CalibrationLifeCycleService {

    /**
     * Use to store subscriptions so they can be unsubscribe later on.
     *
     * Map is used in case multiple templates are subscribing at once (Not likely).
     * This will allow per-component unsubscription.
     */
    private subscriptionsMap = new Map<any, Subscription[]>()

    /**
     * @internal
     * Use to trigger calibrationWillComplete() hooks.
     * Will do nothing if no template is initialized and attach to this service yet.
     */
    private calibrationWillComplete$ = new Subject()

    /**
     * Subscribe and setup lifecycle hooks for the given component so that it can
     * be called through this service.
     * @param calibrationTemplateComponent an instance of AbstractCalibrationTemplateComponent
     */
    public attachHooks(calibrationTemplateComponent: AbstractCalibrationTemplateComponent): Subscription[] {
        const subscriptions: Subscription[] = []

        subscriptions.push(this.calibrationWillComplete$.subscribe(() => {
            calibrationTemplateComponent.calibrationWillComplete()
        }))

        // Save these subscriptions so we can unsubscribe upon destroying.
        this.subscriptionsMap.set(calibrationTemplateComponent, subscriptions)

        // Also return them just in case.
        return subscriptions
    }

    /**
     * Detach and unsubscribe to all hooks. Should be called when the template is being destroyed.
     * @param calibrationTemplateComponent an instance of AbstractCalibrationTemplateComponent
     */
    public detachHooks(calibrationTemplateComponent: AbstractCalibrationTemplateComponent): void {
        const subscriptions = this.subscriptionsMap.get(calibrationTemplateComponent)
        subscriptions.forEach(s => s.unsubscribe)
    }

    /**
     * Call calibrationWillComplete() hook for all attached CalibrationTemplates.
     * If no template is attached yet, nothing will happen.
     */
    public calibrationWillComplete(): void {
        this.calibrationWillComplete$.next(true)
    }
}
