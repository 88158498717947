import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'

import { CalculationStrategy } from '@app/modules/calibration/models/calculcation-strategy.model'
import { CalibrationValidationStatus } from '@app/modules/calibration/models/calibration-validation-status.enum'
import { TemperatureTemplate } from '@app/modules/calibration/models/temperature-template.model'
import { RangeCalculationService } from '@app/modules/calibration/services/range-calculation.service'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { isNotAValue, round } from '@app/utils/app-utils.function'

@Component({
    selector: 'app-result-row',
    templateUrl: './result-row.component.html',
    styleUrls: ['./result-row.component.scss']
})
export class ResultRowComponent extends SafeUnsubscriberComponent implements OnInit {
    @Input() control: FormControl
    @Input() template: TemperatureTemplate
    @Input() hasInjectedInput: boolean
    @Input() isAsFound: boolean
    @Input() maxOutOfToleranceForAsFound: number
    @Input() maxOutOfToleranceForAsLeft: number
    @Input() isCombinedToleranceAsFoundFailed: boolean
    @Input() isCombinedToleranceAsLeftFailed: boolean
    @Input() startingTabIndex: number
    @Input() isReport: boolean
    @Output() autoPopulate = new EventEmitter()

    public isDisabled: boolean

    constructor(private rangeCalculationService: RangeCalculationService) {
        super()
    }

    public get isAsLeftFactor(): number {
        return this.isAsFound === true ? 0 : 1
    }

    public get pointNumber(): number {
        return Number(this.control.get('pointNumber').value)
    }

    public get expected(): FormControl {
        if (this.hasInjectedInput) {
            return this.isAsFound ?
                this.control.get('expectedAsFound') as FormControl :
                this.control.get('expectedAsLeft') as FormControl
        }

        return this.control.get('expected') as FormControl
    }

    public get injectedInput(): FormControl {
        return this.isAsFound ?
            this.control.get('injectedInput') as FormControl :
            this.control.get('adjustedInjectedInput') as FormControl
    }

    public get result(): FormControl {
        return this.isAsFound ?
            this.control.get('asFound') as FormControl :
            this.control.get('asLeft') as FormControl
    }

    public get expectedValue(): FormControl {
        if (this.hasInjectedInput) {
            return this.isAsFound ?
                this.control.get('expectedValueAsFound') as FormControl :
                this.control.get('expectedValueAsLeft') as FormControl
        }

        return this.control.get('expectedValue') as FormControl
    }

    public get tabIndex(): number {
        return this.startingTabIndex + ((this.pointNumber * 2) - 1 + this.isAsLeftFactor) + (this.isAsLeftFactor * this.template.numberOfPoint * 2)
    }

    ngOnInit(): void {
        this.isDisabled = this.control.disabled
        this.addSubscription(this.injectedInput.valueChanges.subscribe(injectedInput => {
            if (!isNotAValue(injectedInput)) {
                const tolerance = this.rangeCalculationService.calculateTolerance(this.template.tolerance, this.template.expected)
                this.expected.setValue(
                    {
                        min: round(Number(injectedInput) - tolerance),
                        max: round(Number(injectedInput) + tolerance)
                    },
                    {
                        emitEvent: false
                    }
                )
                this.expectedValue.setValue(injectedInput, { emitEvent: false })
            } else {
                const pointNumber = this.control.get('pointNumber').value
                const calculationStrategy = {
                    numberOfPoint: this.template.numberOfPoint,
                    calculationType: { id: CalculationStrategy.Linear, name: 'Linear' },
                    tolerance: this.template.tolerance
                }
                this.expected.setValue(this.rangeCalculationService.calculateRange(calculationStrategy, this.template.expected, pointNumber),
                    { emitEvent: false })
                this.expectedValue.setValue(this.rangeCalculationService.calculatePoint(calculationStrategy, this.template.expected, pointNumber),
                    { emitEvent: false })
            }
        }))
    }

    public autoPopulateResult(): void {
        if (this.isAsFound === undefined || this.isAsFound) {
            this.autoPopulate.next(true)
        }
    }

    public validationStatus(value: number, isAsFound?: boolean): CalibrationValidationStatus {
        if (isAsFound === undefined) {
            isAsFound = this.isAsFound
        }

        if (!isNotAValue(value) && this.isInTolerance(value) && !this.isMaxOutOfTolerance(value, isAsFound)) {
            return CalibrationValidationStatus.Valid
        }

        if (!isNotAValue(value) && (!this.isInTolerance || this.isMaxOutOfTolerance)) {
            return CalibrationValidationStatus.Invalid
        }

        return CalibrationValidationStatus.Initialize
    }

    private isInTolerance(value: number): boolean {
        return value >= this.expected.value.min && value <= this.expected.value.max
    }

    private isMaxOutOfTolerance(value: number, isAsFound: boolean): boolean {
        return this.isCombinedToleranceFailed(isAsFound)
            && round(Math.abs(Number(value) - this.expectedValue.value)) === this.getMaxOutOfTolerance(isAsFound)
    }

    private isCombinedToleranceFailed(isAsFound: boolean): boolean {
        return isAsFound ?
            this.isCombinedToleranceAsFoundFailed :
            this.isCombinedToleranceAsLeftFailed
    }

    private getMaxOutOfTolerance(isAsFound: boolean): number {
        return isAsFound ?
            this.maxOutOfToleranceForAsFound :
            this.maxOutOfToleranceForAsLeft
    }
}
