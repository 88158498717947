<div class="{{ 'template-selection-wrapper ' + (isCalibrationPage && 'calibration-page') }}">
    <div class="em-c-field__body">
        <div *ngFor="let template of availableTemplates"
             class="em-c-btn-group em-u-margin-top em-u-margin">
            <button (click)="selectTemplate(template)"
                    class="em-c-btn em-c-btn--primary custom-btn-center">
                <svg class="em-c-icon em-c-icon--medium"
                     *ngIf="selectedTemplateId === template.id">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink"
                         xlink:href="./assets/unity-1.2.0/images/em-icons.svg#check"></use>
                </svg>
                <div *ngIf="template.processName"
                     class="em-c-tooltip em-js-tooltip">
                    <span class="em-c-btn__text em-u-font-size-small-2">
                        {{ template.processName }}
                    </span>
                    <span class="template-alias"
                          *ngIf="template?.alias?.length > 0">
                        ({{ template.alias | truncate:[25, '...'] }})
                    </span>
                    <span *ngIf="template.alias?.length >= 25"
                          class="em-c-tooltip__content">
                        <p>{{ template.alias }}</p>
                    </span>
                </div>
                <div>
                    <span class="em-u-font-style-light em-u-font-size-small-2">
                        {{ template.name }}
                    </span>
                </div>
            </button>
        </div>
    </div>
</div>