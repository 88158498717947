import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'

import { scw } from '@app/utils/classes/simple-changes-wrapper'

/**
 * Type alias to make the code a bit clearer.
 * Indicate that the `string` here should be a valid css width
 * (e.g. '10px', '15%', '1em')
 */
type cssWidth = string

@Component({
    selector: 'app-res-table',
    templateUrl: './res-table.component.html',
    styleUrls: ['./res-table.component.scss']
})
export class ResTableComponent implements OnInit, OnChanges {

    @Input() headerColumnWidth = '1fr'
    @Input() contentColumnsWidth: cssWidth | 'auto' = 'auto'

    @Input() desktopRowContentAlign: 'start' | 'center' | 'end' = 'start'
    @Input() desktopTableGap: 'none' | 's' | 'm' | 'l' = 's'

    /* This include the header column */
    @Input() numberOfColumns: 'auto-fit' | number = 'auto-fit'

    public gridTemplateColumns = `30% repeat(auto-fit, minmax(100px, 1fr))`

    ngOnInit(): void {
        this.recalculateGridSizing()
    }

    ngOnChanges(changes: SimpleChanges): void {
        scw(changes)
            .forChangeInAny(['headerColumnWidth', 'contentColumnWidth', 'numberOfColumns'])
            .do(() => {
                this.recalculateGridSizing()
            })
    }

    private recalculateGridSizing(): void {
        this.gridTemplateColumns =
            `${this.headerColumnWidth || ''} `
            + `repeat(${this.numberOfColumns === 'auto-fit' ? this.numberOfColumns : this.numberOfColumns - 1}, `
            + `${this.contentColumnsWidth === 'auto' ? ' minmax(100px, 1fr)' : this.contentColumnsWidth
            })`
    }

}
