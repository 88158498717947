import { Component, Input, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { isEmpty, size } from 'lodash'

import { HyperlinkInfo, Procedure } from '@app/modules/calibration/models/hyperlink.model'
import { HyperlinkService } from '@app/modules/calibration/services/hyperlink.service'
import { SlideUpOverlayType } from '@app/modules/shared/constants/slide-up-overlay.enum'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { OfflineService } from '@app/services/offline.service'
import { AppState } from '@app/store/app.store'
import { GetHyperlinkInfoAction } from '@app/store/calibration/calibration.actions'
import { hyperlinkState } from '@app/store/calibration/calibration.selectors'

@Component({
    selector: 'app-overlay-hyperlinks',
    templateUrl: './overlay-hyperlinks.component.html',
    styleUrls: ['./overlay-hyperlinks.component.scss']
})
export class OverlayHyperlinksComponent extends SafeUnsubscriberComponent implements OnInit {
    @Input() equipmentId: string

    public overlayId = SlideUpOverlayType.Hyperlink
    public hyperlinkInfo: HyperlinkInfo
    public linkCount = 0
    public isLoading = true
    public hasError = false

    constructor(private hyperlinkService: HyperlinkService, private offlineService: OfflineService, private store: Store<AppState>) {
        super()
    }

    public get isOnline(): boolean {
        return this.offlineService.isOnline
    }

    ngOnInit(): void {
        this.addSubscriptions([
            this.store.select(hyperlinkState).subscribe(state => {
                if (state.equipmentId === this.equipmentId) {
                    this.hyperlinkInfo = state.hyperlinkInfo
                    this.linkCount = size(state.hyperlinkInfo?.procedureList)
                    this.hasError = state.hasError
                    this.isLoading = false
                }
            })
        ])
    }

    public onClickProcedure(procedure: Procedure): void {
        this.hyperlinkService.showExternalLinkWarningModal(procedure.externalLink)
    }

    public getProcedureLinkName(procedure: Procedure): string {
        return isEmpty(procedure?.attachmentName) ? procedure?.attachmentDescription : procedure?.attachmentName
    }

    public reloadHyperlinkInfo(): void {
        this.isLoading = true
        this.hasError = false
        this.store.dispatch(new GetHyperlinkInfoAction(this.equipmentId))
    }
}
