import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Store } from '@ngrx/store'

import { CalibrationDetails } from '@app/modules/calibration/models/calibration-details.model'
import { CalibrationStatusService } from '@app/modules/calibration/services/calibration-status.service'
import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { TemplateTypeEnum } from '@app/modules/equipment/models/template-type.enum'
import { SlideUpOverlayType } from '@app/modules/shared/constants/slide-up-overlay.enum'
import { LabelStatus, mrmaStatus } from '@app/modules/shared/models/mrma-status-label.model'
import { CalibrationFormService } from '@app/modules/shared/services/calibration-form.service'
import { EquipmentCalibration } from '@app/modules/work-order/models/equipment-calibration.model'
import { WorkOrderDetails } from '@app/modules/work-order/models/work-order-details.model'
import { WorkOrderService } from '@app/modules/work-order/services/work-order.service'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppService } from '@app/services/app.service'
import { OfflineService } from '@app/services/offline.service'
import { ScreenSizeService } from '@app/services/screen-size.service'
import { AppState } from '@app/store/app.store'
import { equipmentTemplateList } from '@app/store/equipment/selectors/equipment-template.selectors'
import { SlideOverlayAction } from '@app/store/overlay/overlay.actions'
import { GetNotificationAction } from '@app/store/work-order/work-order.actions'

@Component({
    selector: 'app-calibration-equipment-info',
    templateUrl: './calibration-equipment-info.component.html',
    styleUrls: ['./calibration-equipment-info.component.scss']
})
export class CalibrationEquipmentInfoComponent extends SafeUnsubscriberComponent implements OnInit, OnChanges {
    @Input() showNoteToTechnician = true
    @Input() isPrimaryEquipment = false
    @Input() equipment: EquipmentCalibration
    @Input() calibration: CalibrationDetails
    @Input() calibrationForm: FormGroup
    @Input() isReport: boolean
    @Input() workOrderDetails: WorkOrderDetails

    @Output() loadEquipmentHistory: EventEmitter<boolean> = new EventEmitter()

    public templateType = TemplateTypeEnum
    public equipmentTemplate: EquipmentTemplateCoreDetails[]
    public workOrderStatus: string

    constructor(
        public appService: AppService,
        public calibrationFormService: CalibrationFormService,
        private store: Store<AppState>,
        private offlineService: OfflineService,
        private calibrationStatusService: CalibrationStatusService,
        private workOrderService: WorkOrderService,
        private screenSizeService: ScreenSizeService
    ) {
        super()
    }

    public get needNotificationTooltip(): boolean {
        return !this.isReport && !this.screenSizeService.isOnMobileOrTabletDevice()
    }

    public get isOnline(): boolean {
        return this.offlineService.isOnline
    }

    public get reviewDate(): string {
        return this.calibration.reviewDate ? new Date(this.calibration.reviewDate).toLocaleDateString() : ''
    }

    public get calibrationStatusDisplay(): LabelStatus {
        return this.calibrationStatusService.getCalibrationStatusDisplay(this.calibration.calibrationStatus)
    }

    public get processName(): string {
        return (this.calibration?.processName ?? '')
    }

    ngOnInit(): void {
        this.addSubscription(
            this.store.select(equipmentTemplateList).subscribe(templateList => {
                this.equipmentTemplate = templateList
            })
        )

        this.workOrderStatus = this.workOrderService.calculateWorkOrderStatusPill(this.workOrderDetails.systemStatus)
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.equipment && changes?.equipment.currentValue.equipmentNotificationNumber) {
            this.store.dispatch(GetNotificationAction({
                workOrderNumber: this.workOrderDetails.workOrderNumber,
                notificationNumber: changes?.equipment.currentValue.equipmentNotificationNumber
            }))
        }
    }

    public getEquipmentNotification(notificationNumber: string): string {
        const _notificationNumber = notificationNumber ? notificationNumber : this.workOrderDetails.notification.number
        return this.workOrderService.getEquipmentNotification(_notificationNumber, this.workOrderDetails.equipmentCalibrations)
    }

    public reviewStatusDisplay(id: number): LabelStatus {
        return id ? mrmaStatus.reviewStatus.find(x => x.id === id) : mrmaStatus.reviewStatus.find(x => x.id === null)
    }

    public viewHistory(): void {
        if (!this.isOnline) {
            return
        }

        this.loadEquipmentHistory.next(true)
        this.store.dispatch(new SlideOverlayAction({ visibility: true, id: SlideUpOverlayType.EquipmentHistory }))
    }

    public viewProcedure(procedureURL: string): void {
        if (!this.isOnline) {
            return
        }

        const win = window.open(procedureURL)
        win.focus()
    }
}
