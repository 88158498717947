<div (click)="toggleCheck($event)">
    <div *ngIf="checked" class="star-icon blue" [ngClass]="disabled ? 'is-disabled': ''">
        <svg class="em-c-icon em-c-icon--medium">
            <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="./assets/unity-1.2.0/images/em-icons.svg#bookmark-full"
            ></use>
        </svg>
    </div>
    <div *ngIf="!checked" class="star-icon grey" [ngClass]="disabled ? 'is-disabled': ''">
        <svg class="em-c-icon em-c-icon--medium">
            <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="./assets/unity-1.2.0/images/em-icons.svg#bookmark-line"
            ></use>
        </svg>
    </div>
</div>
