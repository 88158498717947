import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'

import { SharedModule } from '@app/modules/shared/shared.module'
import { MrmaUiComponentsModule } from '../mrma-ui-components/mrma-ui-components.module'
import { MassUpdateComponent } from './components/mass-update/mass-update.component'
import { WorkOrderCalibrationSummaryComponent } from './components/work-order-calibration-summary/work-order-calibration-summary.component'
import { WorkOrderEquipmentComponent } from './components/work-order-equipment/work-order-equipment.component'
import { WorkOrderNotificationComponent } from './components/work-order-notification/work-order-notification.component'
import { WorkOrderOverviewComponent } from './components/work-order-overview/work-order-overview.component'

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        MrmaUiComponentsModule
    ],
    declarations: [
        WorkOrderOverviewComponent,
        WorkOrderEquipmentComponent,
        WorkOrderCalibrationSummaryComponent,
        WorkOrderNotificationComponent,
        MassUpdateComponent
    ],
    exports: [WorkOrderCalibrationSummaryComponent]
})
export class WorkOrderModule { }
