/**
 * Debounce a funtion call. Useful for limiting the number of function call caused by
 * some events.
 *
 * Will always fire once before starting the debounce.
 *
 * **Should not be used with a function that have a return type or that may cause RACE
 * condition on other functions as this will essentially turn the funtion into a `void
 * async` function.
 * **
 *
 * @param delay - time to delay in ms
 */
export function DebounceTime(delay: number): MethodDecorator {
    return function (target: any, propKey: string, descriptor: PropertyDescriptor) {
        const original = descriptor.value

        // This will be used to store our timeout object inside our 'this'
        const key = Symbol('timeoutObj')

        let isInDebounce = false

        descriptor.value = function (...args: any[]): any {
            if (!isInDebounce) {
                isInDebounce = true
                clearTimeout(this[key])
                this[key] = setTimeout(() => isInDebounce = false, delay)
                original.apply(this, args)
            } else {
                clearTimeout(this[key])
                this[key] = setTimeout(() => {
                    isInDebounce = false
                    original.apply(this, args)
                }, delay)
            }
        }

        return descriptor
    }
}
