<div class="responsive-calibration-field-container">
    <div class="calibration-info">
        <label>Equipment ID</label>
        <p>{{ equipment?.equipmentId || '-' }}</p>
    </div>
    <div class="calibration-info">
        <label>Equipment Tag</label>
        <p>{{ equipment?.equipmentTag || '-' }}</p>
    </div>
    <div class="calibration-info">
        <label>Equipment Tech ID</label>
        <p>{{ equipment?.techIdentificationNumber || '-' }}</p>
    </div>
    <div class="calibration-info">
        <label>Functional Location</label>
        <p>{{ equipment?.functionalLocation || '-' }}</p>
    </div>
    <div class="calibration-info">
        <label>Criticality</label>
        <p>{{ equipment?.abcIndicator || '-' }}</p>
    </div>
</div>
<app-mrma-card title="Note to Technician"
               class="desktop-margin-bottom__md"
               *ngIf="equipment?.equipmentNote && showNoteToTechnician">
    <p class="note-content">{{ equipment.equipmentNote }}</p>
</app-mrma-card>