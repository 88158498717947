import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { ApplicationRef, ErrorHandler, InjectionToken, NgModule, NgZone } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
    MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalService
} from '@azure/msal-angular'
import { EffectsModule } from '@ngrx/effects'
import { DefaultRouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store'
import { ActionReducerMap, StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { ActiveToast, IndividualConfig, ToastrModule, ToastrService } from 'ngx-toastr'

import { AppRoutingModule } from '@app/app-routing.module'
import { AppComponent } from '@app/app.component'
import { CoreModule } from '@app/modules/core/core.module'
import { ModalContainerModule } from '@app/modules/modal-container/modal-container.module'
import { SharedModule } from '@app/modules/shared/shared.module'
import { appEffects, appReducers, AppState } from '@app/store/app.store'
import { metaReducers } from '@app/store/storeFreeze.reducer'
import { environment } from '@environments/environment'
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from '@settings/msal.settings'
import { HTTPErrorHandlerInterceptor } from './interceptors/http-error-handler.interceptor'
import { OauthInterceptor } from './interceptors/oauth.interceptor'
import { CalibrationModule } from './modules/calibration/calibration.module'
import { HomeModule } from './modules/home/home.module'
import { WorkOrderModule } from './modules/work-order/work-order.module'
import { AppMonitoringService } from './services/app-monitoring.service'
import { BackgroundQueueHandlerService } from './services/background-queue-handler.service'
import { PolicyCheckerServiceInjectorHelper } from './services/policy-checker-injector-helper.service'
import { GlobalErrorHandler } from './utils/classes/app-error-handlers/global-error-handler'
import { dateToLocalISOString } from './utils/date.utils'
import { OverlayModule } from '@angular/cdk/overlay'

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('root reducer')

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        CoreModule,
        HomeModule,
        MsalModule,
        CommonModule,
        SharedModule,
        BrowserModule,
        WorkOrderModule,
        AppRoutingModule,
        HttpClientModule,
        CalibrationModule,
        ModalContainerModule,
        BrowserAnimationsModule,
        OverlayModule,
        EffectsModule.forRoot(appEffects),
        ToastrModule.forRoot({ preventDuplicates: true, newestOnTop: false }),
        StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer }),
        StoreModule.forRoot(REDUCER_TOKEN, { metaReducers, runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true } }),
        environment.development ? StoreDevtoolsModule.instrument() : []
    ],
    bootstrap: [AppComponent],
    providers: [
        BackgroundQueueHandlerService,
        {
            provide: REDUCER_TOKEN,
            useValue: appReducers
        },
        MsalInterceptor,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OauthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HTTPErrorHandlerInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        PolicyCheckerServiceInjectorHelper
    ]
})
export class AppModule {

    constructor(
        applicationRef: ApplicationRef,
        private ngZone: NgZone,
        toastrService: ToastrService,
        appMonitoringService: AppMonitoringService,
        // Do not remove @AccessibleByPolicy() use this behind the scene
        // This is an injector method that allow angular DI to be use statically anywhere
        private policyCheckerInjectorHelper: PolicyCheckerServiceInjectorHelper
    ) {
        const envName = environment.name
        const toastrErrorFunc = toastrService.error.bind(toastrService)
        toastrService.error = (
            message?: string,
            title?: string,
            override?: Partial<IndividualConfig>,
            includeSessionInfo = true
        ): ActiveToast<any> => {

            if (!includeSessionInfo) {
                return toastrErrorFunc(message, title, override)
            }

            const dateTime = dateToLocalISOString(new Date())
            const shortSessionId = appMonitoringService.sessionId?.substring(0, 6) ?? '-'

            const messageWithSessionInfo = `
            ${message}

            <span class="toaster-error-meta">
            (${dateTime}) SessionId: ${shortSessionId} - ${envName}
            </span>
            `
            return toastrErrorFunc(messageWithSessionInfo, title, {
                ...override,
                enableHtml: true
            })
        }

        if (environment.development && environment?.debug?.logCDTime) {
            // Monkey Patch Angular's CD to print CD time
            const originalTick = applicationRef.tick

            const logLevel = environment?.debug?.logLevel
            const logTemplate = ['%c🚀 CD TIME %c %s ms', 'color: #5BBB96; background-color: #47458B;']
            const warningTextStyle = 'color: #E30505'
            const normalTextStyle = 'color: #9966FF'

            let count = 0
            let culmulativeTimeMs = 0
            this.ngZone.runOutsideAngular(() => {
                setInterval(() => {
                    if (count > 50 || culmulativeTimeMs > 1000) {
                        console.warn('CD ran', count, 'times totalling', culmulativeTimeMs, 'ms in the last 10 seconds')
                    }
                    count = 0
                    culmulativeTimeMs = 0
                }, 10000)
            })

            // Arrow function will not work here since we need "this"
            // eslint-disable-next-line @typescript-eslint/typedef, @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types, space-before-function-paren
            applicationRef.tick = function () {
                const windowsPerfomance = window.performance
                const before = windowsPerfomance.now()
                const retValue = originalTick.apply(this, arguments)
                const after = windowsPerfomance.now()
                const runTime = after - before

                count++
                culmulativeTimeMs += runTime

                if (runTime > 100) {
                    window.console.warn(...logTemplate, warningTextStyle, runTime)
                } else if (logLevel === 'INFO') {
                    window.console.info(...logTemplate, normalTextStyle, runTime)
                } else {
                    // On Chrome, this is `verbose`
                    window.console.debug(...logTemplate, normalTextStyle, runTime)
                }

                return retValue
            }
        }
    }
}
