import { EquipmentCalibration } from './equipment-calibration.model'
import { NotificationBasic } from './notification-basic.model'

export class WorkOrderDetails {
    abcIndicator: string
    abcDescription: string
    description: string
    functionalLocation: string
    lacd: Date
    location: string
    mainWorkCenter: string
    plantCode: string
    maintenancePlant: string
    plantSection: string
    priority: string
    systemStatus: string
    isBookmarked: boolean
    workOrderNumber: string
    equipmentCalibrations: EquipmentCalibration[]
    notification: NotificationBasic
    procedureURL: string
    pmPlan: string
    pmFrequency: string
    pmPlanItem: string
    readyForOffline: boolean
    workOrderType: string
    plannerGroup: string
    longText?: string
    plannerGroupDesc?: string
    plantDesc?: string
    mainWorkCenterDesc?: string
    maintenancePlantDesc?: string
    locationDesc?: string
    plantSectionDesc?: string
    userStatus?: string
    basicStartDate?: Date
    basicFinishDate?: Date
    hasPrimaryEquipment?: boolean
}
