import { AfterViewInit, Component } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'

import { AppState } from '@app/store/app.store'
import { getProgressBarStatus } from '@app/store/loader/loader.selectors'

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements AfterViewInit {
    public progress = -1

    constructor(
        private route: Router,
        private store: Store<AppState>
    ) { }

    ngAfterViewInit(): void {
        this.store.select(getProgressBarStatus).subscribe(progressBar => {
            let routeFound = false
            progressBar.forEach(p => {
                for (const route of p.progressBarVisibleInRoute) {
                    if (this.route.url.includes(route)) {
                        routeFound = true
                        this.progress = p.progressBar
                        if (p.progressBar >= 100) {
                            this.progress = -1
                        }
                    }
                }
            })

            if (!routeFound) {
                this.progress = -1
            }
        })
    }
}
