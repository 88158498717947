import { Component, ContentChild, Input, TemplateRef } from '@angular/core'

@Component({
    selector: 'app-detailed-page-header',
    templateUrl: './detailed-page-header.component.html',
    styleUrls: ['./detailed-page-header.component.scss']
})
export class DetailedPageHeaderComponent {

    @Input() subtitle: string
    @Input() titleMain: string
    @Input() titleDesc: string

    @Input() backButtonText: string
    /**
     * Command to pass to [routerLink].
     * By default, it will navigate back one URL fragment (../)
     */
    @Input() backButtonRouterLink: string | any[] = ['../']
    @Input() backButtonQueryParams: { [k: string]: any } = {}

    @ContentChild('leftToolbar') leftToolbar: TemplateRef<any>
    @ContentChild('rightToolbar') rightToolbar: TemplateRef<any>

    @Input() showLine = true
}
