import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-mrma-card',
    templateUrl: './mrma-card.component.html',
    styleUrls: ['./mrma-card.component.scss']
})
export class MRMACardComponent {
    @Input() title: string
}
