<section *ngIf="!isOnMobileOrTabletDevice">
    <div class="em-l-grid em-l-grid--4up">
        <div class="em-l-grid__item">
            <app-label [title]="'Referencing Device'"
                       [classList]="isDisabled ? 'em-is-disabled' : ''">
                <input [appInputDisplayReport]="isReport"
                       valueIfEmpty="-"
                       [formControl]="getReferencingDevice"
                       [tabIndex]="1"
                       type="text"
                       placeholder="Device name"
                       class="em-c-input em-u-font-style-regular" />
            </app-label>
        </div>
        <div class="em-l-grid__item">
            <app-label [title]="'Testing Value'"
                       [subtitle]="inputUoM"
                       [classList]="isDisabled ? 'em-is-disabled' : ''">
                <div *ngIf="isInch16 else injectedInput"
                     class="feet-inch16-wrapper">
                    <app-feet-inches-inch16 [isInch16]="false"
                                            [form]="injectedInputControl"
                                            [displayAsText]="!template.isInjectedInputRequired || isReport"
                                            [startingTabIndex]="2">
                    </app-feet-inches-inch16>
                </div>
                <ng-template #injectedInput>
                    <div class="em-c-field__body"
                         [ngClass]="{'div__injected--enabled': !template.isInjectedInputRequired}">
                        <app-numeric-input [formControl]="injectedInputControl"
                                           (inputBlur)="injectedBlur($event)"
                                           [tabIndex]="2"
                                           [appInputDisplayReport]="!template.isInjectedInputRequired || isReport">
                        </app-numeric-input>
                    </div>
                </ng-template>
            </app-label>
        </div>
        <div class="em-l-grid__item">
            <app-label [title]="'Tolerance'"
                       [subtitle]="accuracyToleranceUoM">
                <div [ngClass]="{'div__injected--enabled': isInjectedInputBoxVisible}">
                    {{ accuracyToleranceValueString }}
                </div>
            </app-label>
        </div>
    </div>
    <div class="em-l-grid em-l-grid--4up">
        <div class="em-l-grid__item em-c-field__label">
            As Found {{ inputUoM }}
        </div>
        <div class="em-l-grid__item em-c-field__label">
            As Left {{ inputUoM }}
        </div>
    </div>
    <div *ngFor="let point of resultSetControl.controls; let i = index"
         class="em-l-grid em-l-grid--4up">
        <div class="em-l-grid__item em-c-field__body">
            <app-label [classList]="isDisabled ? 'em-is-disabled' : ''">
                <div class="feet-inch16-wrapper">
                    <app-feet-inches-inch16 *ngIf="isInch16 else asFoundDefaultInput"
                                            [onBlur]="autoPopulateAsLeft.bind(this)"
                                            [isInch16]="false"
                                            [form]="point.get('asFound')"
                                            [validationStatus]="asFoundValidationStatuses[i]"
                                            (inchChange)="pointInputChange(i, true, point)"
                                            [startingTabIndex]="i + (inch16TabIndex * i) + 5"
                                            [displayAsText]="isReport"
                                            (allInch16UnitHaveValue)="countCompletedAsFound($event, i)">
                    </app-feet-inches-inch16>
                </div>
                <ng-template #asFoundDefaultInput>
                    <app-numeric-input appInputValidator
                                       [input]="asFoundValidationStatuses[i]"
                                       (emitWhenInputLosingFocus)="pointInputChange(i, true, point)"
                                       [appCalibrationInputReport]="isReport"
                                       [formControl]="point.get('asFound')"
                                       [tabIndex]="i + 5"
                                       placeholder="0.0"
                                       (inputBlur)="autoPopulateAsLeft()">
                    </app-numeric-input>
                </ng-template>
            </app-label>
        </div>
        <div class="em-l-grid__item em-c-field__body">
            <app-label [classList]="isDisabled ? 'em-is-disabled' : ''">
                <div class="feet-inch16-wrapper">
                    <app-feet-inches-inch16 *ngIf="isInch16 else asLeftDefaultInput"
                                            [isInch16]="false"
                                            [form]="point.get('asLeft')"
                                            [validationStatus]="asLeftValidationStatuses[i]"
                                            (inchChange)="pointInputChange(i, false, point)"
                                            [startingTabIndex]="i + (4 * numberOfPoint) + (i * 2) + 5"
                                            [displayAsText]="isReport">
                    </app-feet-inches-inch16>
                </div>
                <ng-template #asLeftDefaultInput>
                    <app-numeric-input appInputValidator
                                       [input]="asLeftValidationStatuses[i]"
                                       (emitWhenInputLosingFocus)="pointInputChange(i, false, point)"
                                       [appCalibrationInputReport]="isReport"
                                       [formControl]="point.get('asLeft')"
                                       [tabIndex]="i + refDeviceTabIndex + numberOfPoint + 5"
                                       placeholder="0.0">
                    </app-numeric-input>
                </ng-template>
            </app-label>
        </div>
    </div>
    <div *ngIf="template.isAverageReadingUsed"
         class="em-l-grid em-l-grid--4up">
        <div class="em-l-grid__item">
            <app-label [title]="'Average'"
                       [subtitle]="inputUoM"
                       [ngClass]="[
                    isColumnPopulated(true) ? 'average-completed':'',
                    getAverageInTolerance(true) ? 'average-value--passed':'average-value--failed'
                ]">
                {{ getAverageAsFound() }}
            </app-label>
        </div>
        <div class="em-l-grid__item">
            <app-label [title]="'Average'"
                       [subtitle]="inputUoM"
                       [ngClass]="[
                    isColumnPopulated(false) ? 'average-completed':'',
                    getAverageInTolerance(false) ?'average-value--passed':'average-value--failed'
                ]">
                {{ getAverageAsLeft() }}
            </app-label>
        </div>
    </div>
</section>
<section *ngIf="isOnMobileOrTabletDevice">
    <app-section title="Ranges and Tolerances">
        <div body
             class="responsive-spacing">
            <app-responsive-calibration-input [allowReading]="true"
                                              [displayInputLabel]="'Referencing Device'">
                <div readingInput>
                    <input [appInputDisplayReport]="isReport"
                           valueIfEmpty="-"
                           [formControl]="getReferencingDevice"
                           [tabIndex]="1"
                           type="text"
                           placeholder="Device name"
                           class="em-c-input em-u-font-style-regular" />
                </div>
            </app-responsive-calibration-input>
            <app-responsive-calibration-input [allowReading]="true"
                                              [displayInputLabel]="'Testing Value ' + inputUoM">
                <div readingInput>
                    <div *ngIf="isInch16 else injectedInput"
                         class="feet-inch16-wrapper">
                        <app-feet-inches-inch16 [isInch16]="false"
                                                [form]="injectedInputControl"
                                                [displayAsText]="!template.isInjectedInputRequired || isReport"
                                                [startingTabIndex]="2">
                        </app-feet-inches-inch16>
                    </div>
                    <ng-template #injectedInput>
                        <div class="em-c-field__body"
                             [ngClass]="{'div__injected--enabled': !template.isInjectedInputRequired}">
                            <app-numeric-input [formControl]="injectedInputControl"
                                               (inputBlur)="injectedBlur($event)"
                                               [tabIndex]="2"
                                               [appInputDisplayReport]="!template.isInjectedInputRequired || isReport">
                            </app-numeric-input>
                        </div>
                    </ng-template>
                </div>
            </app-responsive-calibration-input>
            <app-responsive-calibration-input [rowLabelHead]="'Tolerance ' + accuracyToleranceUoM"
                                              [rowLabelResult]="accuracyToleranceValueString"
                                              [singleFieldColumn]="true">
            </app-responsive-calibration-input>
        </div>
    </app-section>
    <app-section [title]="'As Found ' + inputUoM">
        <div body
             class="responsive-spacing">
            <div *ngFor="let point of resultSetControl.controls; let i = index">
                <app-responsive-calibration-input *ngIf="!isInch16"
                                                  [allowReading]="true"
                                                  [displayInputLabel]="'Reading ' + (i + 1)">
                    <div readingInput>
                        <app-numeric-input appInputValidator
                                           [input]="asFoundValidationStatuses[i]"
                                           (emitWhenInputLosingFocus)="pointInputChange(i, true, point)"
                                           [appCalibrationInputReport]="isReport"
                                           [formControl]="point.get('asFound')"
                                           placeholder="0.0"
                                           (inputBlur)="autoPopulateAsLeft()">
                        </app-numeric-input>
                    </div>
                </app-responsive-calibration-input>
                <app-responsive-calibration-input *ngIf="isInch16"
                                                  [allowReading]="true"
                                                  [displayInputLabel]="'Reading ' + (i + 1)">
                    <div readingInput>
                        <div class="feet-inch16-wrapper">
                            <app-feet-inches-inch16 [onBlur]="autoPopulateAsLeft.bind(this)"
                                                    [isInch16]="false"
                                                    [form]="point.get('asFound')"
                                                    [validationStatus]="asFoundValidationStatuses[i]"
                                                    (inchChange)="pointInputChange(i, true, point)"
                                                    [displayAsText]="isReport"
                                                    (allInch16UnitHaveValue)="countCompletedAsFound($event, i)">
                            </app-feet-inches-inch16>
                        </div>
                    </div>
                </app-responsive-calibration-input>
            </div>
            <app-responsive-calibration-input *ngIf="template.isAverageReadingUsed"
                                              [rowLabelHead]="'Average'"
                                              [rowLabelResult]="getAverageAsFound()"
                                              [singleFieldColumn]="true"
                                              [averageValidationClass]="[
                                                isColumnPopulated(true) ? 'average-completed':'',
                                                getAverageInTolerance(true) ?'average-value--passed':'average-value--failed'
                                              ]">
            </app-responsive-calibration-input>
        </div>
    </app-section>
    <app-section [title]="'As Left ' + inputUoM">
        <div body
             class="responsive-spacing">
            <div *ngFor="let point of resultSetControl.controls; let i = index">
                <app-responsive-calibration-input *ngIf="!isInch16"
                                                  [allowReading]="true"
                                                  [displayInputLabel]="'Reading ' + (i + 1)">
                    <div readingInput>
                        <app-numeric-input appInputValidator
                                           [input]="asLeftValidationStatuses[i]"
                                           (emitWhenInputLosingFocus)="pointInputChange(i, false, point)"
                                           [appCalibrationInputReport]="isReport"
                                           [formControl]="point.get('asLeft')"
                                           [tabIndex]="i + refDeviceTabIndex + numberOfPoint + 5"
                                           placeholder="0.0">
                        </app-numeric-input>
                    </div>
                </app-responsive-calibration-input>
                <app-responsive-calibration-input *ngIf="isInch16"
                                                  [allowReading]="true"
                                                  [displayInputLabel]="'Reading ' + (i + 1)">
                    <div readingInput>
                        <div class="feet-inch16-wrapper">
                            <app-feet-inches-inch16 [isInch16]="false"
                                                    [form]="point.get('asLeft')"
                                                    [validationStatus]="asLeftValidationStatuses[i]"
                                                    (inchChange)="pointInputChange(i, false, point)"
                                                    [startingTabIndex]="i + (4 * numberOfPoint) + (i * 2) + 5"
                                                    [displayAsText]="isReport">
                            </app-feet-inches-inch16>
                        </div>
                    </div>
                </app-responsive-calibration-input>
            </div>
            <app-responsive-calibration-input *ngIf="template.isAverageReadingUsed"
                                              [rowLabelHead]="'Average'"
                                              [rowLabelResult]="getAverageAsLeft()"
                                              [singleFieldColumn]="true"
                                              [averageValidationClass]="[
                                                isColumnPopulated(true) ? 'average-completed':'',
                                                getAverageInTolerance(true) ?'average-value--passed':'average-value--failed'
                                              ]">
            </app-responsive-calibration-input>
        </div>
    </app-section>
</section>
