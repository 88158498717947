/**
 * Wrap the main `calibrationForm: FormGroup` and provide interfaces for accessing
 * its value with some typed return.
 */
import { Injectable, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { BehaviorSubject, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'

import { isNotAValue } from '@app/utils/app-utils.function'
import { CalibrationResultStatusService } from '../../calibration/services/calibration-result-status.service'
import {
    CalibrationFormFormModel,
    OverviewFormModel,
    ResultsFormModel
} from '../models/calibration-form-form-model.model'
import { isArrayEqual, isObjectDeepEqual } from '@app/utils/data.utils'
import { filter, flatten, isEmpty, isEqual, isFunction, isNil, map as _map, pick, size, some } from 'lodash'

const isObjectEqualInComparingFields = (object, anotherObject, comparingFields) => {
    // When object is null, it's because of that the page is initializing data from the beginning.
    // It's also considered object equal
    if (isNil(object) || isNil(anotherObject)) {
        return true
    }

    const longerPropsObject = Object.keys(object).length >= Object.keys(anotherObject).length ? object : anotherObject
    for (const propKey in longerPropsObject) {
        if (some(comparingFields, field => field === propKey)) {
            if (!isObjectDeepEqual(object[propKey], anotherObject[propKey])) {
                return false
            }
        }
    }

    return true
}

const isResultValueEqual = (originalValue: ResultsFormModel, updatedValue: ResultsFormModel): boolean => {
    if (isNil(originalValue)) {
        return true
    }

    const isPmPerformedTechniciansEqual = size(originalValue.pmPerformedTechnicians) === size(updatedValue.pmPerformedTechnicians)

    const pickPureResult = (results) => filter(_map(results, value =>
            _map(value.resultSet, innerValue =>
                pick(innerValue, ['asFound', 'asLeft', 'unitOfMeasurement', 'injectedInput', 'adjustedInjectedInput']))),
        resultArr => !isEmpty(resultArr))
    const originalCalibrationResult = pickPureResult(originalValue.calibrationResult?.results)
    const updatedCalibrationResult = pickPureResult(updatedValue.calibrationResult?.results)
    const isCalibrationResultEqual = size(flatten(originalCalibrationResult)) !== size(flatten(updatedCalibrationResult))
        || isArrayEqual(originalCalibrationResult, updatedCalibrationResult)

    const originalTestEquipmentIds = _map(originalValue.testEquipments, 'equipmentId')
    const updatedTestEquipmentIds = _map(updatedValue.testEquipments, 'equipmentId')
    const isCalibrationTestEquipmentEqual = isArrayEqual(originalTestEquipmentIds, updatedTestEquipmentIds)

    const originalEQTechnicians = _map(originalValue.equipmentReplacedTechnicians, 'guid')
    const updatedEQTechnicians = _map(updatedValue.equipmentReplacedTechnicians, 'guid')
    const isEQTechniciansEqual = isArrayEqual(originalEQTechnicians, updatedEQTechnicians)

    const isCylindersEqual = isArrayEqual(originalValue.referenceMaterials, updatedValue.referenceMaterials)

    return isObjectEqualInComparingFields(
        originalValue,
        updatedValue,
        [
            'performedDate', 'equipmentSerialNumber', 'modelNumber', 'manufacturer',
            'reasonForNotComplete', 'atoNumber', 'atoDate', 'equipmentReplacedDate',
            'replacedEquipmentSerialNumber', 'replacedModelNumber', 'replacedManufacturer',
            'comments', 'finalPMResultStatus', 'calibrationChecklist'
        ])
        && isPmPerformedTechniciansEqual && isCalibrationResultEqual && isCalibrationTestEquipmentEqual && isCylindersEqual
        && isEQTechniciansEqual
}

const isCustomValueEqual = (originalValue: any, updatedValue: any): boolean => {
    if (size(originalValue) !== size(updatedValue)) {
        // The value comes from `customForm`. It will be incrementally added
        return true
    }

    return isEqual(_map(originalValue, 'value'), _map(updatedValue, 'value'))
}

const isOverviewValueEqual = (originalValue: any, updatedValue: any): boolean => {
    return isObjectEqualInComparingFields(originalValue, updatedValue, ['procedureNumber', 'repairWorkOrderNumber'])
}

// Do not provide this in 'root' else you may have trouble with data inconsistency
@Injectable()
export class CalibrationFormService implements OnDestroy {

    public readonly formValue$ = new BehaviorSubject<CalibrationFormFormModel>(null)

    public readonly resultsValue$ = new BehaviorSubject<ResultsFormModel>(null)
    public readonly overviewValue$ = new BehaviorSubject<OverviewFormModel>(null)
    public readonly customFormValue$ = new BehaviorSubject<any>(null)

    public readonly isPMDeferredOrNotTested$ = new BehaviorSubject<boolean>(false)
    public readonly isPMDeferred$ = new BehaviorSubject<boolean>(false)
    public readonly isPMNotTested$ = new BehaviorSubject<boolean>(false)

    public readonly isCalibrationDataChanged = new BehaviorSubject<boolean>(false)

    private _calibrationForm: FormGroup
    private _formValueChangesSub: Subscription

    private updateValues = (() => {
        const emitIfDifferent = <T>(bSub: BehaviorSubject<T>, challengingValue: any, isDataEqual?) => {
            const currValueJSONString = JSON.stringify(bSub.value)
            const challengingValueJSONString = JSON.stringify(challengingValue)
            if (isFunction(isDataEqual) && !isDataEqual(bSub.value, challengingValue)) {
                this.isCalibrationDataChanged.next(true)
            }
            if (currValueJSONString !== challengingValueJSONString) {
                bSub.next(challengingValue)
            }
        }

        const updateDeferredNotTestedValues = (calibrationFormValue: CalibrationFormFormModel) => {
            const { finalPMResultStatus } = calibrationFormValue?.results

            const isPMDeferred = this.calibrationResultStatusService.isCalibrationDeferred(finalPMResultStatus)
            const isPMNotTested = this.calibrationResultStatusService.isCalibrationNotTested(finalPMResultStatus)
            const isPMDeferredOrNotTested = this.calibrationResultStatusService.isCalibrationDeferredOrNotTested(finalPMResultStatus)

            this.isPMDeferred$.next(isPMDeferred)
            this.isPMNotTested$.next(isPMNotTested)
            this.isPMDeferredOrNotTested$.next(isPMDeferredOrNotTested)
        }

        const emitFormValues = (calibrationFormValue: CalibrationFormFormModel) => {
            this.formValue$.next(calibrationFormValue)
            emitIfDifferent(this.resultsValue$, calibrationFormValue?.results, isResultValueEqual)
            emitIfDifferent(this.overviewValue$, calibrationFormValue?.overview, isOverviewValueEqual)
            emitIfDifferent(this.customFormValue$, calibrationFormValue?.customForm, isCustomValueEqual)
        }

        // Main updateValues sequences:
        return (calibrationFormValue: CalibrationFormFormModel) => {
            emitFormValues(calibrationFormValue)
            updateDeferredNotTestedValues(calibrationFormValue)
        }
    })()

    constructor(
        private calibrationResultStatusService: CalibrationResultStatusService
    ) {
    }

    public get calibrationForm(): FormGroup {
        return this._calibrationForm
    }

    public set calibrationForm(v: FormGroup) {
        this._calibrationForm = v
        this.initDataFlow()
    }

    ngOnDestroy(): void {
        this._formValueChangesSub?.unsubscribe()
    }

    public isReplaceEquipmentSameAsOriginal(): boolean {
        const {
            equipmentSerialNumber,
            replacedEquipmentSerialNumber,
            modelNumber,
            replacedModelNumber,
            manufacturer,
            replacedManufacturer
        } = this._calibrationForm.get('results').value
        return (!isNotAValue(equipmentSerialNumber) && equipmentSerialNumber?.trim() === replacedEquipmentSerialNumber?.trim())
            && (!isNotAValue(modelNumber) && modelNumber?.trim() === replacedModelNumber?.trim())
            && (!isNotAValue(manufacturer) && manufacturer?.trim() === replacedManufacturer?.trim())
    }

    /**
     * Suppose to run when the calibrationForm object reference change (not mutate).
     * Will rebind value changes and recalculate all values for getter/Subjects
     */
    private initDataFlow(): void {
        // Initialize the Subjects's value
        const currentFormValue = this.calibrationForm.getRawValue()
        this.updateValues(currentFormValue)

        // Subscribe to form changes to pipe future values to the Subjects
        this._formValueChangesSub?.unsubscribe()
        this._formValueChangesSub = this.calibrationForm.valueChanges.pipe(
            map(() => this.calibrationForm.getRawValue())
        ).subscribe(calibrationFormValue => {
            this.updateValues(calibrationFormValue)
        })
    }


}
