<section *ngIf="!isOnMobileOrTabletDevice">
    <h3 class="em-c-solid-card__title">{{ resultSetName }}</h3>
    <app-result-set-header [template]="template"
                           [hasInjectedInput]="hasInjectedInput"
                           [isAsFound]="hasInjectedInput ? true: undefined">
    </app-result-set-header>
    <div *ngFor="let resultRow of resultSetForm.controls">
        <app-result-row [control]="resultRow"
                        [template]="template"
                        [hasInjectedInput]="hasInjectedInput"
                        [isAsFound]="hasInjectedInput ? true: undefined"
                        [maxOutOfToleranceForAsFound]="maxOutOfToleranceForAsFound"
                        [maxOutOfToleranceForAsLeft]="maxOutOfToleranceForAsLeft"
                        [isCombinedToleranceAsFoundFailed]="isCombinedToleranceAsFoundFailed"
                        [isCombinedToleranceAsLeftFailed]="isCombinedToleranceAsLeftFailed"
                        [startingTabIndex]="startingTabIndex"
                        [isReport]="isReport"
                        (autoPopulate)="autoPopulateResult()">
        </app-result-row>
    </div>
    <div *ngIf="hasInjectedInput">
        <app-result-set-header [template]="template"
                               [hasInjectedInput]="hasInjectedInput"
                               [isAsFound]="hasInjectedInput ? false: undefined">
        </app-result-set-header>
        <div *ngFor="let resultRow of resultSetForm.controls">
            <app-result-row [control]="resultRow"
                            [template]="template"
                            [hasInjectedInput]="hasInjectedInput"
                            [isAsFound]="hasInjectedInput ? false: undefined"
                            [maxOutOfToleranceForAsFound]="maxOutOfToleranceForAsFound"
                            [maxOutOfToleranceForAsLeft]="maxOutOfToleranceForAsLeft"
                            [isCombinedToleranceAsFoundFailed]="isCombinedToleranceAsFoundFailed"
                            [isCombinedToleranceAsLeftFailed]="isCombinedToleranceAsLeftFailed"
                            [startingTabIndex]="startingTabIndex"
                            [isReport]="isReport"
                            (autoPopulate)="autoPopulateResult()">
            </app-result-row>
        </div>
    </div>
</section>

<section *ngIf="isOnMobileOrTabletDevice">
    <app-section [title]="resultSetName + ' - As Found (' + template.input.unitOfMeasurement.uomCodeForTech + ')'">
        <div body
             class="responsive-spacing">
            <div *ngFor="let resultRow of resultSetForm.controls">
                <app-result-responsive [control]="resultRow"
                                       [template]="template"
                                       [hasInjectedInput]="hasInjectedInput"
                                       [isAsFound]="true"
                                       [maxOutOfToleranceForAsFound]="maxOutOfToleranceForAsFound"
                                       [maxOutOfToleranceForAsLeft]="maxOutOfToleranceForAsLeft"
                                       [isCombinedToleranceAsFoundFailed]="isCombinedToleranceAsFoundFailed"
                                       [isCombinedToleranceAsLeftFailed]="isCombinedToleranceAsLeftFailed"
                                       (autoPopulate)="autoPopulateResult()">
                </app-result-responsive>
            </div>
        </div>
    </app-section>
    <app-section [title]="resultSetName + ' - As Left (' + template.input.unitOfMeasurement.uomCodeForTech + ')'">
        <div body
             class="responsive-spacing">
            <div *ngFor="let resultRow of resultSetForm.controls">
                <app-result-responsive [control]="resultRow"
                                       [template]="template"
                                       [hasInjectedInput]="hasInjectedInput"
                                       [isAsFound]="false"
                                       [maxOutOfToleranceForAsFound]="maxOutOfToleranceForAsFound"
                                       [maxOutOfToleranceForAsLeft]="maxOutOfToleranceForAsLeft"
                                       [isCombinedToleranceAsFoundFailed]="isCombinedToleranceAsFoundFailed"
                                       [isCombinedToleranceAsLeftFailed]="isCombinedToleranceAsLeftFailed"
                                       (autoPopulate)="autoPopulateResult()">
                </app-result-responsive>
            </div>
        </div>
    </app-section>
</section>
