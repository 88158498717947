export const getIcon = (id: string, iconName: string, className: string): string => {
    let icon = `
        <svg id="{id}" class="em-c-icon em-c-icon--small hcp-calibration-icon {className}">
            <use id="use-{id}" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="assets/unity-1.2.0/images/em-icons.svg#{iconName}"></use>
        </svg>
    `
    if (id) {
        icon = icon.replace(/{id}/g, id)
    }

    if (className) {
        icon = icon.replace('{className}', className)
    }

    if (iconName) {
        icon = icon.replace('{iconName}', iconName)
    }

    return icon
}
