import { Injectable } from '@angular/core'
import { Router, CanActivate } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { map } from 'rxjs/operators'

import { AppState } from '@app/store/app.store'
import { Observable } from 'rxjs'
import { isOnline } from '@app/store/connection/connection.selectors'

@Injectable({
    providedIn: 'root'
})
export class OnlineFeatureGuard implements CanActivate {
    constructor(private router: Router, private store: Store<AppState>) { }

    canActivate(): Observable<boolean> {
        return this.store.pipe(
            select(isOnline),
            map(online => {
                if (!online) {
                    this.router.navigate(['online-restricted'], {
                        skipLocationChange: true
                    })
                }

                return online
            })
        )
    }
}
