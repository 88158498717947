import { Component, Input } from '@angular/core'
import { FormControl } from '@angular/forms'

import { CustomFormInput } from '@app/modules/calibration/components/custom-form/models/custom-form.model'

@Component({
    selector: 'app-custom-form-textarea',
    templateUrl: './custom-form-textarea.component.html',
    styleUrls: ['./custom-form-textarea.component.scss']
})
export class CustomFormTextareaComponent {
    @Input() formDetail: CustomFormInput
    @Input() formControl = new FormControl(null)
}
