<app-responsive-calibration-input body
                                  [allowReading]="true"
                                  [showLineBreak]="true"
                                  [layoutSimple]="false"
                                  [displayInputLabel]="'Input (' + template.input.unitOfMeasurement.uomCodeForTech + ')'"
                                  [displayResultLabel]="control.get('input').value"
                                  [isAdjusted]="hasInjectedInput"
                                  [adjusted]="'Input ' + pointNumber"
                                  [reading]="'Reading ' + pointNumber"
                                  [readingExpected]="'Expected ' + expected.value.min + ' to ' + expected.value.max">
    <div adjustedInput>
        <app-numeric-input [appInputDisplayReport]="isReport"
                           [formControl]="injectedInput"
                           [blurToOriginalValue]="true">
        </app-numeric-input>
    </div>
    <div readingInput>
        <app-numeric-input appInputValidator
                           [appCalibrationInputReport]="isReport"
                           class="em-c-input em-u-text-align-right"
                           type="text"
                           inputmode="decimal"
                           pattern="^([-+,0-9.]+)"
                           [formControl]="result"
                           placeholder="0.0"
                           tabIndex="{{ tabIndex }}"
                           (inputBlur)="autoPopulateResult()"
                           [input]="validationStatus(result.value)">
        </app-numeric-input>
    </div>
</app-responsive-calibration-input>
