<div class="em-l-grid__item em-u-text-align-left">
    <div class="em-c-field__body">
        <label class="em-c-search__label">
            EXPIRY DATE
        </label>
        <app-date-picker [inputValue]="user?.certificationExpiryDate"
                         (inputValueChange)="inputValueChange($event)"
                         placeholder="Date"></app-date-picker>
    </div>
</div>
