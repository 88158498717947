<app-main-container marginBottom="5em">
    <app-detailed-page-header backButtonText="Back to Custom Form Management"
                              [backButtonRouterLink]="['/','custom-forms']"
                              [titleMain]="formId ? 'Edit Custom Form' : 'Add New Custom Form'"></app-detailed-page-header>
    <div #flamingoFormEditorRef
         *ngIf="flamingoToken$ | async as flamingoToken">
        <app-mrma-card [title]="'Form Editor'">
            <flamingo-builder [environment]="flamingoEnv"
                              [accessToken]="flamingoToken"
                              [appName]="'MRMA'"
                              [enableToaster]="false"
                              [disabledAppNameField]="true"
                              [formTemplateId]="formId"
                              (formSubmit)="onFormSubmit($event)"
                              (formCreateSuccess)="onFormCreateOrUpdateSuccess($event)"
                              (formUpdateSuccess)="onFormCreateOrUpdateSuccess($event, true)"
                              (toastSuccess)="onToastMessage($event, 'success')"
                              (toastError)="onToastMessage($event, 'error')"
                              (toastWarning)="onToastMessage($event, 'warning')"
                              (toastInfo)="onToastMessage($event, 'info')"></flamingo-builder>
        </app-mrma-card>
    </div>
</app-main-container>

<!-- TODO: Add this back once flamingo component support external save button -->
<!-- <app-sticky-bottom-bar>
    <ng-template #leftToolbar>
        <button class="footer-bar-element em-c-btn em-c-btn--primary">Save</button>
        <button class="footer-bar-element em-c-btn">Cancel</button>
    </ng-template>
</app-sticky-bottom-bar> -->
