import { Component, Input } from '@angular/core'

type IconSize = 'tiny' | 'small'

@Component({
    selector: 'app-unity-icon',
    templateUrl: './unity-icon.component.html',
    styleUrls: ['./unity-icon.component.scss']
})
export class UnityIconComponent {
    @Input() unitySvgName = 'thumbs-up'
    @Input() svgPath: string
    @Input() size: IconSize = 'tiny'
    @Input() isDisabled = false
}
