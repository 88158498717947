<app-section *ngIf="!isLeakCheck"
             class="third-party-section"
             [showDetailsLine]="false">
    <div body
         class="responsive-calibration-field-container border-top">
        <div class="calibration-field-result">
            <label [classList]="isDisabled ? 'em-is-disabled' : ''">Company Name</label>
            <input [formControl]="calibrationForm.get('results.calibrationResult.companyName')"
                   [appInputDisplayReport]="isReport"
                   valueIfEmpty="-"
                   type="text"
                   placeholder="Company Name"
                   class="em-c-input em-u-font-style-regular input-section"
                   maxlength="50"
                   [disabled]="isDisabled" />

        </div>
    </div>
</app-section>

<app-section class="third-party-section em-u-margin-bottom-double"
             [showDetailsLine]="i !== 0"
             *ngFor="let section of checklistBySectionControls; index as i"
             [title]="section.get('sectionName').value">
    <div subtitle>
        <h3 class="text-error text-error-padding"
            *ngIf="checkSectionValid(section)">
            *Required
        </h3>
    </div>
    <div body>
        <div class="responsive-lg">
            <div class="em-u-padding-half custom-flex">
                <div *ngFor="let response of section.get('possibleResponse').value"
                     class="custom-flex-item__inner">
                    {{response.response}}
                </div>
            </div>
        </div>
        <app-radio-horizontal *ngFor="let checklist of getChecklistControl(section).controls"
                              [radioOptions]="radioOptions"
                              [formControl]="checklist.get('response')"
                              [question]="checklist.get('question').value.question"
                              [isDisabled]="isDisabled"
                              [customValueComparer]="customValueComparer"
                              [useBlack]="isReport || isCalibrationStatusCompleted"></app-radio-horizontal>
    </div>
</app-section>
