<ul class="em-c-primary-nav__list">
    <li class="em-c-primary-nav__item">
        <a class="em-c-primary-nav__link em-c-primary-nav__link--has-children em-js-nav-dropdown-trigger em-u-clickable"
           [appToggleActive]="true">
            <img *ngIf="isInSyncingState"
                 class="syncing"
                 src="assets/images/status-loading.svg">
            <span class="em-c-btn__text">Latest Data Sync</span>
            <svg class="em-c-icon em-c-icon--small em-c-primary-nav__icon">
                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                     xlink:href="assets/unity-1.2.0/images/em-icons.svg#caret-down"></use>
            </svg>
        </a>
        <ul class="em-c-primary-nav__sublist em-js-nav-dropdown">
            <li class="em-c-primary-nav__subitem">
                <div class="status">
                    <span class="em-u-padding-none">
                        User list: {{ displayUserListSyncDate }}
                    </span>
                </div>
            </li>
            <li class="em-c-primary-nav__subitem">
                <div class="status">
                    <span class="em-u-padding-none">
                        Work Orders: {{ displayWorkOrderSyncDate }}
                    </span>
                </div>
            </li>
            <li class="em-c-primary-nav__subitem">
                <div class="status">
                    <span class="em-u-padding-none">
                        Equipment: {{ displayEquipmentSyncDate }}
                    </span>
                </div>
            </li>
            <li class="em-c-primary-nav__subitem">
                <div class="status">
                    <span class="em-u-padding-none">
                        Maintenance Plans: {{ displayMaintenancePlanSyncDate }}
                    </span>
                </div>
            </li>
        </ul>
    </li>
</ul>
