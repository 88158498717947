import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { FormArray, FormBuilder, Validators } from '@angular/forms'
import { Action, MemoizedSelector, Store } from '@ngrx/store'

import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import { deepCopy } from '@app/utils/app-utils.function'

@Component({
    selector: 'app-multiple-selector',
    templateUrl: './multiple-selector.component.html',
    styleUrls: ['./multiple-selector.component.scss']
})
export class MultipleSelectorComponent<T> extends SafeUnsubscriberComponent implements OnInit, OnChanges {

    @Input() selectorFormArray: FormArray
    @Input() maxNumberOfItems = 10
    @Input() isRequired: boolean
    @Input() initAction: Action
    @Input() displayField: string
    @Input() idField: string
    @Input() itemSelector: MemoizedSelector<any, any[]>
    @Input() hasFixedFirstValue = false
    @Input() inputId = ''

    constructor(private store: Store<AppState>, private formBuilder: FormBuilder) {
        super()
    }

    public get existingItems(): T[] {
        return this.selectorFormArray.getRawValue().filter(item => !!item)
    }

    ngOnInit(): void {
        this.store.dispatch(this.initAction)
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.selectorFormArray?.currentValue) {
            this.invalidate()
        }
    }

    public addItem(): void {
        this.selectorFormArray.push(this.formBuilder.control({ value: undefined, disabled: this.selectorFormArray.disabled }))
    }

    public removeItem(index: number): void {
        if ((!index || index === 0) && this.selectorFormArray.controls.length === 1) {
            return
        }
        this.selectorFormArray.removeAt(index)
    }

    public addSelector(): void {
        if (this.selectorFormArray.controls.length < this.maxNumberOfItems &&
            this.selectorFormArray.controls[this.selectorFormArray.length - 1] &&
            this.selectorFormArray.controls[this.selectorFormArray.length - 1].value &&
            !this.selectorFormArray.disabled
        ) {
            this.selectorFormArray.push(
                this.formBuilder.control({
                    value: undefined,
                    disabled: this.selectorFormArray.disabled
                })
            )
        }
    }

    public disableInactiveAndExpiredItem(item: T): T {
        const clonedItem = deepCopy(item)

        const isUserOrTestEquipment = item.hasOwnProperty('isActive') || item.hasOwnProperty('isExpired')
        if (isUserOrTestEquipment && (!clonedItem['isActive'] || clonedItem['isExpired'])) {
            clonedItem['disabled'] = true
        }

        return clonedItem
    }

    private invalidate(): void {
        super.unsubscribeAll()
        this.addDefaultEmptyBoxIfNeeded()
        this.setupSubscription()
    }

    private addDefaultEmptyBoxIfNeeded(): void {
        if (this.selectorFormArray.length === 0) {
            if (this.isRequired) {
                this.selectorFormArray.push(this.formBuilder.control({
                    value: undefined, disabled: this.selectorFormArray.disabled
                }, Validators.required))
            } else {
                this.addItem()
            }
        }
    }

    private setupSubscription(): void {
        this.addSubscription(this.selectorFormArray.valueChanges.subscribe(() => {
            this.addDefaultEmptyBoxIfNeeded()
            let order = 1
            this.selectorFormArray.controls.map(control => {
                if (control.value) {
                    control.setValue({ ...control.value, displayOrder: order }, { emitEvent: false })
                    order += 1
                }
            })
        }))
    }
}
