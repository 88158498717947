import { Route } from '@angular/router'

import { DevModeGuard } from './guards/dev-mode.guard'

export const routes: Route[] = [
    {
        path: 'devmode',
        loadChildren: () => import('@app/modules/dev/dev.module').then(m => m.DevModule),
        canActivate: [DevModeGuard]
    },
    {
        // Needed for hash routing
        path: 'code',
        loadChildren: () => import('@app/modules/home/home.module').then(m => m.HomeModule)
    },
    {
        path: '',
        loadChildren: () => import('@app/modules/home/home.module').then(m => m.HomeModule)
    }
]
