<section>
    <h1 class="responsive-view">Defer/Not Test PM</h1>

    <p *ngIf="shouldShowCheckbox">Some or all PMs selected contain existing info. Select what you what to <b>override.</b></p>
    <p class="warning-text"
       *ngIf="shouldShowCheckbox && notAllPMInMassUpdateListAreDeferredOrNotTested">
        Reason for not completing and Deferred or Not Tested selection are required as some PMs selected are not already deferred or not tested.
    </p>

    <app-simple-checkbox *ngIf="shouldShowCheckbox && !notAllPMInMassUpdateListAreDeferredOrNotTested; else normalReasonText"
                         name="reasonForNotComplete"
                         [value]="true"
                         [disabled]="notAllPMInMassUpdateListAreDeferredOrNotTested"
                         [(ngModel)]="whatToInclude.reason"
                         id="reasonForNotComplete">
        <p [ngClass]="{ required: notAllPMInMassUpdateListAreDeferredOrNotTested}">Update <b>reason for not completing</b></p>
    </app-simple-checkbox>
    <ng-template #normalReasonText>
        <p>
            <span>Reason for not completing the work</span>
            <span class="required-error-msg"
                  *ngIf="!reason">*Required</span>
        </p>

    </ng-template>
    <textarea [(ngModel)]="reason"
              ng-model-options="{ updateOn: 'blur' }"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              [disabled]="!whatToInclude.reason"
              spellcheck="false"></textarea>

    <div class="mark-as">
        <app-simple-checkbox *ngIf="shouldShowCheckbox && !notAllPMInMassUpdateListAreDeferredOrNotTested; else normalMarkAsText"
                             name="markAs"
                             [value]="true"
                             [disabled]="notAllPMInMassUpdateListAreDeferredOrNotTested"
                             [(ngModel)]="whatToInclude.markAs"
                             id="markAs">
            <p [ngClass]="{ required: notAllPMInMassUpdateListAreDeferredOrNotTested}">Mark <b>selected PMs as</b></p>
        </app-simple-checkbox>
        <ng-template #normalMarkAsText>
            <p>{{isMassUpdateMode ? 'Mark all selected PM as' : 'Mark this PM as'}}</p>
        </ng-template>
        <div class="switch-buttons">
            <button (click)="isDeferred = true"
                    [disabled]="!whatToInclude.markAs"
                    class="em-c-btn"
                    [ngClass]="{'em-c-btn--primary': isDeferred, 'em-c-btn--disabled': !whatToInclude.markAs}">
                <svg *ngIf="isDeferred"
                     class="em-c-icon em-c-icon--small em-u-margin-right-half a-icon">
                    <use xlink:href="./assets/unity-1.2.0/images/em-icons.svg#check"
                         xmlns:xlink="http://www.w3.org/1999/xlink"></use>
                </svg>
                Deferred
            </button>
            <button (click)="isDeferred = false"
                    [disabled]="!whatToInclude.markAs"
                    class="em-c-btn"
                    [ngClass]="{'em-c-btn--primary': isDeferred === false, 'em-c-btn--disabled': !whatToInclude.markAs}">
                <svg *ngIf="isDeferred === false"
                     class="em-c-icon em-c-icon--small em-u-margin-right-half a-icon">
                    <use xlink:href="./assets/unity-1.2.0/images/em-icons.svg#check"
                         xmlns:xlink="http://www.w3.org/1999/xlink"></use>
                </svg>
                Not Tested
            </button>
        </div>
    </div>
    <hr>
    <div class="ato">
        <div class="ato-number">
            <app-simple-checkbox *ngIf="shouldShowCheckbox"
                                 name="atoNumber"
                                 [value]="true"
                                 [(ngModel)]="whatToInclude.atoNumber"
                                 id="atoNumber">Set <b>ATO Number</b> as</app-simple-checkbox>
            <label *ngIf="!shouldShowCheckbox">ATO Number</label>
            <input type="text"
                   [(ngModel)]="atoNumber"
                   [disabled]="!whatToInclude.atoNumber"
                   placeholder="Please enter ATO number"
                   maxlength="100">
        </div>
        <div class="ato-date">
            <app-simple-checkbox *ngIf="shouldShowCheckbox"
                                 name="atoDate"
                                 [value]="true"
                                 [(ngModel)]="whatToInclude.atoDate"
                                 id="atoDate">Set <b>ATO Date</b> as</app-simple-checkbox>
            <label *ngIf="!shouldShowCheckbox">ATO Date</label>
            <app-date-picker [inputValue]="atoDate"
                             [isDisabled]="!whatToInclude.atoDate"
                             [placeHolder]="'Select Date'"
                             [minDate]="today"
                             (inputValueChange)="onDatePickerDateChanged($event)"
                             [isClearable]="false"></app-date-picker>
        </div>
    </div>
    <div class="action-buttons">
        <span class="required-error-msg em-u-margin-bottom-half">
            Warning: Submitting with Defer or Not tested result will wipe the previously filled PM records
        </span>
        <button (click)="submit()"
                class="em-c-btn em-c-btn--primary"
                [ngClass]="{'em-c-btn--disabled': !isFormValid}">
            Submit
        </button>
        <button (click)="close()"
                class="em-c-btn">
            Cancel
        </button>
    </div>
</section>
