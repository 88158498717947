import { Pipe, PipeTransform } from '@angular/core'
import { Selector, Store } from '@ngrx/store'

import { AppState } from '@app/store/app.store'

@Pipe({
    name: 'selectStore',
    pure: true
})
export class SelectStorePipe implements PipeTransform {

    constructor(private readonly store: Store<AppState>) { }

    transform(selector: Selector<any, any>): any {
        return this.store.select(selector)
    }
}
