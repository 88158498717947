import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { MsalService } from '@azure/msal-angular'
import { Store } from '@ngrx/store'
import { combineLatest, timer } from 'rxjs'
import { distinctUntilChanged, take, tap } from 'rxjs/operators'

import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import { isOnline } from '@app/store/connection/connection.selectors'
import { GetCurrentUserAction } from '@app/store/identity/identity.actions'
import { currentUser } from '@app/store/identity/identity.selectors'
import { AppHasFinishedInitializingAction } from '@app/store/loader/loader.actions'
import { LogoutUserAction } from '@app/store/user/user.actions'

@Component({
    selector: 'app-unauthorized',
    styleUrls: ['./unauthorized.component.scss'],
    templateUrl: './unauthorized.component.html'
})
export class UnauthorizedComponent extends SafeUnsubscriberComponent implements OnInit, AfterViewInit {

    public title = '403 Forbidden'
    public subtitle = 'You are not authorized to access MRMA.'
    public isUnauthorizedDueToPlant = true
    public hasZeroPlantAccess = true

    constructor(
        private store: Store<AppState>,
        private authService: MsalService,
        private ngZone: NgZone,
        private router: Router
    ) { super() }

    ngOnInit(): void {
        this.startPollingForUserAuthStatus()
    }

    ngAfterViewInit(): void {
        this.store.dispatch(new AppHasFinishedInitializingAction())
    }

    public logout() {
        this.store.dispatch(new LogoutUserAction())
    }

    /**
     * In case the user somehow land on this page due to RACE condition or other mistake,
     * this component will monitor the user's login and authorization status
     * and redirect the user to '/' if they now have access.
     */
    private startPollingForUserAuthStatus(delayMs = 100, tickIntervalMs = 1000, maxTick = 20): void {
        const ticker = combineLatest([
            timer(delayMs, tickIntervalMs),
            this.store.select(isOnline),
            this.store.select(currentUser),
        ])
            .pipe(
                // Retry for at most `maxTick` times.
                // If they are still not auth-ed, they will need to manually refresh
                take(maxTick),

                // The code below will trigger CD, only allow code to flow down if something
                // actually changed.
                distinctUntilChanged(([, prevOnline, prevUser], [, currOnline, currUser]) => {
                    return (prevOnline === currOnline) && (prevUser?.guid === currUser?.guid)
                }),
                tap(user => {
                    if (!user) {
                        // TODO: refactor this to App's bootstrap
                        // Current user not yet cached. Ask @ngrx to load it.
                        this.store.dispatch(new GetCurrentUserAction())
                    }
                }),
                tap(([, online, user]) => {
                    this.hasZeroPlantAccess = !user || user?.plantCodes.length === 0
                    const accountExist = !!this.authService.instance.getActiveAccount()
                    const hasPlantAccess = !!user?.plantCodes.includes(user?.preferredPlantCode)

                    // This part may update the UI so we need to run it in ngZone()
                    this.ngZone.run(() => {
                        if (online && !accountExist) {
                            this.subtitle = 'Unable to find login information.'
                            this.isUnauthorizedDueToPlant = false
                            return
                        }

                        if (!hasPlantAccess) {
                            this.subtitle = `You are not authorized to access ${user?.preferredPlantCode ?? 'MRMA'}`
                            this.isUnauthorizedDueToPlant = true
                            return
                        }

                        // Redirect to home page
                        this.router.navigateByUrl('/')
                    })
                })
            )

        // Run this outside of Angular so we don't trigger CD every tick
        this.ngZone.runOutsideAngular(() => {
            this.addSubscription(ticker.subscribe())
        })
    }

}
