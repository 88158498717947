import { Pipe, PipeTransform } from '@angular/core'
import { isNil } from 'lodash'

/** If no value then return a '-' for displaying */
@Pipe({ name: 'nilDisplay' })
export class NilDisplayPipe implements PipeTransform {

    transform(value: any): any {
        return isNil(value) ? '-' : value
    }
}
