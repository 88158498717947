import { Component, Input } from '@angular/core'

import { isNotAValue } from '@app/utils/app-utils.function'

@Component({
    selector: 'app-responsive-calibration-input',
    templateUrl: './responsive-calibration-input.component.html',
    styleUrls: ['./responsive-calibration-input.component.scss']
})
export class ResponsiveCalibrationInputComponent {
    @Input() displayInputLabel: string
    @Input() displayResultLabel: string
    @Input() isAdjusted: boolean
    @Input() adjusted: string
    @Input() reading: string
    @Input() readingExpected: string
    @Input() rowLabelHead: string
    @Input() rowLabelResult: string
    @Input() showLineBreak = false
    @Input() layoutSimple = true
    @Input() averageValidationClass: string
    @Input() singleFieldColumn = false
    @Input() allowReading = false

    public notEmpty = (value) => !isNotAValue(value)
}
