import { createSelector } from '@ngrx/store'

import { CalibrationReportDetails } from '@app/modules/report/models/calibration-report-details.model'
import { ReportListQueryParameter } from '@app/modules/report/models/report-list-query.model'
import { QuerySummary } from '@app/modules/shared/models/query-summary.model'
import { deepCopy, sortArray } from '@app/utils/app-utils.function'
import { AppState } from '../app.store'
import { ReportState, selectAll } from './report.reducer'

export const selectReportList = (state: AppState) => state.report

export const reportList = createSelector(selectReportList, selectAll)
export const reportQuery = createSelector(selectReportList, state => state.query)
export const reportDetail = createSelector(selectReportList, state => state.reportDetail)
export const isReportListLoading = createSelector(selectReportList, (state: ReportState) => state.isListLoading)
export const totalReportListCount = createSelector(selectReportList, (state: ReportState) => state.query.totalItemCount)
export const calibrationPresetList = createSelector(selectReportList, (state: ReportState) => state.filterPresetList)
export const reportFilterList = createSelector(selectReportList, (state: ReportState) => state.reportFilterList)
export const pmPerformedDate = createSelector(selectReportList, (state: ReportState) => state.pmPerformedDate)

export const queryReportList = createSelector(
    reportList,
    (calibrationReportDetails: CalibrationReportDetails[], query: QuerySummary<ReportListQueryParameter>) => {
        const copyOfReportList = deepCopy(calibrationReportDetails)
        const sortedList = sortArray(copyOfReportList, query.queryParameters.sortBy, query.queryParameters.isDesc)
        const itemPerPage = parseInt(query.itemPerPage, 10)
        const startIndex = (query.currentPage - 1) * itemPerPage
        const endIndex = startIndex + itemPerPage

        return sortedList.slice(startIndex, endIndex)
    }
)
