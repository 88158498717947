import { Component, OnDestroy, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'

import { CustomSearchEquipment } from '@app/modules/equipment/models/custom-search-equipment.model'
import { Equipment } from '@app/modules/equipment/models/equipment.model'
import { SapEquipmentQueryParameter } from '@app/modules/equipment/models/sap-equipment-query-parameter.model'
import { SapEquipment } from '@app/modules/equipment/models/sap-equipment.model'
import { QuerySummary } from '@app/modules/shared/models/query-summary.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import { CreateEquipmentAction } from '@app/store/equipment/actions/equipment.actions'
import { GetSapEquipmentListAction } from '@app/store/equipment/actions/sap-equipment.actions'
import { equipmentToBeAdded, sapEquipmentList, sapEquipmentListQuery } from '@app/store/equipment/selectors/sap-equipment.selectors'
import { isLoading } from '@app/store/loader/loader.selectors'

@Component({
    selector: 'app-equipment-add-modal',
    templateUrl: './equipment-add-modal.component.html',
    styleUrls: ['./equipment-add-modal.component.scss']
})
export class EquipmentAddModalComponent extends SafeUnsubscriberComponent implements OnInit, OnDestroy {
    public isLoading$: Observable<boolean>
    public equipmentList$: Observable<Equipment[]>
    public sapEquipmentList$: Observable<SapEquipment[]>
    public equipmentToBeAdded$: Observable<CustomSearchEquipment[]>
    public sapEquipmentQuery$: Observable<QuerySummary<SapEquipmentQueryParameter>>
    public sapEquipmentQuery: QuerySummary<SapEquipmentQueryParameter>
    public isOpen: boolean

    public searchText: string

    constructor(private store: Store<AppState>) {
        super()
    }

    ngOnInit(): void {
        this.isLoading$ = this.store.select(isLoading)
        this.sapEquipmentList$ = this.store.pipe(select(sapEquipmentList))
        this.equipmentToBeAdded$ = this.store.pipe(select(equipmentToBeAdded))
        this.sapEquipmentQuery$ = this.store.pipe(select(sapEquipmentListQuery))
        this.addSubscription(
            this.sapEquipmentQuery$.subscribe(result => this.sapEquipmentQuery = result)
        )
        this.isOpen = false
    }

    public searchByEquipmentTag(searchText: string): void {
        const sapEquipmentQueryParameters = { ...this.sapEquipmentQuery.queryParameters }
        sapEquipmentQueryParameters.equipmentTag = searchText
        sapEquipmentQueryParameters.perPage = '5'
        this.store.dispatch(new GetSapEquipmentListAction({ ...sapEquipmentQueryParameters }))

        this.isOpen = true
    }

    public addButtonClicked(equipment: CustomSearchEquipment): void {
        const newEquipment: Equipment = {
            equipmentId: equipment.equipmentId,
            equipmentTag: equipment.equipmentTag,
            description: equipment.description,
            isActive: true,
            abcIndicator: equipment.abcIndicator,
            plantCode: equipment.plantCode,
            note: '',
            techIdentificationNumber: '',
            functionalLocation: ''
        }
        this.store.dispatch(new CreateEquipmentAction(newEquipment))
    }

    public closeModal(): void {
        this.isOpen = false
    }
}
