import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

import { CalibrationResultStatusEnum } from '@app/modules/calibration/models/calibration-result-status.enum'
import { CalibrationResultStatus } from '@app/modules/calibration/models/calibration-result-status.model'
import { CalibrationStatus } from '@app/modules/calibration/models/calibration-status.model'

@Component({
    selector: 'app-calibration-result-selector',
    templateUrl: './calibration-result-selector.html',
    styleUrls: ['./calibration-result-selector.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CalibrationResultSelectorComponent),
            multi: true
        }
    ]
})
export class CalibrationResultSelectorComponent implements ControlValueAccessor {

    @Input() isDisabled = false
    @Input() useBlack = false

    @Input() customColor: string

    @Output() selectionChange = new EventEmitter<CalibrationStatus>()

    public radioOptions: CalibrationResultStatus[] = [{
        name: 'Passed',
        id: CalibrationResultStatusEnum.Passed
    },
    {
        name: 'Passed-Adjusted-Passed',
        id: CalibrationResultStatusEnum.PassedAdjustedPassed
    },
    {
        name: 'Failed-Adjusted-Passed',
        id: CalibrationResultStatusEnum.FailedAdjustedPassed
    },
    {
        name: 'Failed',
        id: CalibrationResultStatusEnum.Failed
    }]

    private _value: CalibrationStatus

    get value(): CalibrationStatus {
        return this._value
    }

    set value(v: CalibrationStatus) {
        this._value = v
        this.onChange(v)
        this.onTouched()
    }

    registerOnChange(fn): void {
        this.onChange = fn
    }

    registerOnTouched(fn): void {
        this.onTouched = fn
    }

    writeValue(v): void {
        this._value = v
    }

    public optionClicked(option: CalibrationStatus): void {
        if (this.isDisabled) {
            return
        }
        this.value = option
        this.selectionChange.next(this.value)
    }

    // These functions will be registered automatically by Angular
    private onChange: any = () => { }
    private onTouched: any = () => { }

}
