<div class="em-l-grid__item em-u-padding-bottom-double em-is-aligned-left">
    <form role="search"
          class="em-c-search-form em-c-search-form--small">
        <label for="search"
               class="em-c-search__label">
            EQUIPMENT TAG
        </label>
        <app-search [placeHolder]="'Search Equipment Tag'"
                    [callback]="searchByEquipmentTag.bind(this)">
        </app-search>
    </form>
</div>
<div class="em-l-grid__item"
     *ngIf="isOpen">
    <app-paged-list *ngIf="(equipmentToBeAdded$ | async) as searchEquipment"
                    [items]="searchEquipment"
                    [clickable]="false"
                    [itemsPerPage]="5">
        <ng-template #header>
            <th scope="col"
                class="em-c-table__header-cell em--no-wrap em-u-width-10">
                EQUIPMENT #
            </th>
            <th scope="col"
                class="em-c-table__header-cell em--no-wrap em-u-width-15">
                EQUIPMENT TAG
            </th>
            <th scope="col"
                class="em-c-table__header-cell em--no-wrap em-u-width-30">
                DESCRIPTION
            </th>
            <th scope="col"
                class="em-c-table__header-cell em--no-wrap em-u-width-20">
                FLOC DESCRIPTION
            </th>
            <th scope="col"
                class="em-c-table__header-cell em--no-wrap">
            </th>
        </ng-template>
        <ng-template #content
                     let-item>
            <td class="em-c-table__cell em-u-width-10">
                {{item.equipmentId}}
            </td>
            <td class="em-c-table__cell em-u-width-15">
                {{item.equipmentTag}}
            </td>
            <td class="em-c-table__cell em-u-width-30">
                {{item.description}}
            </td>
            <td class="em-c-table__cell em-u-width-20">
                {{item.functionalLocation}}
            </td>
            <td class="em-c-table__cell"
                *ngIf="!item.isExisting">
                <div class="equipment-button em-u-font-size-medium-2">
                    <a class="em-c-text-link"
                       (click)="addButtonClicked(item)">Add</a>
                </div>
            </td>
            <td class="em-c-table__cell em-u-text-align-center"
                *ngIf="item.isExisting">
                <span class="em-c-btn__text">Added</span>
            </td>
        </ng-template>
    </app-paged-list>
</div>
