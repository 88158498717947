<section #equipmentHistoryMobileRef
         class="responsive-view slide-up-overlay">
    <div class="header">
        <div class="nav">
            <div (click)="closeOverlay()"
                 class="close">Close X</div>
            <h1>Equipment History</h1>
        </div>
        <div class="description">
            <h2>Equipment</h2>
            <h1>{{equipmentHistory?.equipmentTag}} (ID: {{equipmentHistory?.equipmentId}})</h1>
            <sub>{{equipmentHistory?.equipmentDescription}}</sub>
        </div>
    </div>
    <div class="body">
        <div *ngFor="let item of equipmentHistory?.calibrationHistories">
            <app-section [title]="'WN ' + item.notificationNumber">
                <div body
                     class="responsive-calibration-container">
                    <div *ngIf="item.pmPerformedDate"
                         class="calibration-info">
                        <label>Date PM Performed</label>
                        <p>{{ item.pmPerformedDate | date: 'MMM dd, yyyy' }}</p>
                    </div>
                    <div *ngIf="item.resultStatus"
                         class="calibration-info">
                        <label>Status</label>
                        <p>{{ item.resultStatus }}</p>
                    </div>
                    <div *ngIf="item.comment"
                         class="calibration-info">
                        <label>Comment</label>
                        <div [innerHTML]="item.comment"></div>
                    </div>
                    <div *ngIf="item.repairWorkOrderNumber"
                         class="calibration-info">
                        <label>Repair WO#</label>
                        <p>{{ item.repairWorkOrderNumber }}</p>
                    </div>
                </div>
            </app-section>
        </div>
    </div>
</section>
