import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { AuthenticatedGuard } from '@app/guards/authenticated.guard'
import { AutoDownloadsInterceptorGuard } from '@app/guards/auto-downloads-interceptor.guard'
import { IsTechnicianGuard } from '@app/guards/is-technician.guard'
import { NavAwayInterceptorGuard } from '@app/guards/nav-away-interceptor.guard'
import { OnlineFeatureGuard } from '@app/guards/online-feature.guard'
import { PlantAccessAuthorizedGuard } from '@app/guards/plant-access-authorized.guard'
import { CalibrationComponent } from '../calibration/components/calibration/calibration.component'
import { EquipmentHistoryWebComponent } from '../calibration/components/equipment-history/web/equipment-history-web/equipment-history-web.component'
import { OnlineRestrictedComponent } from '../core/components/online-restricted/online-restricted.component'
import { UnauthorizedComponent } from '../core/components/unauthorized/unauthorized.component'
import { MassUpdateComponent } from '../work-order/components/mass-update/mass-update.component'
import { WorkOrderOverviewComponent } from '../work-order/components/work-order-overview/work-order-overview.component'
import { HomeComponent } from './home.component'

export const routes: Routes = [
    {
        path: 'unauthorized',
        component: UnauthorizedComponent
    },
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthenticatedGuard, PlantAccessAuthorizedGuard],
        children: [
            {
                path: '',
                redirectTo: 'search',
                pathMatch: 'full',
            },
            {
                path: 'search',
                loadChildren: () => import('../work-order-search/work-order-search.module').then(m => m.WorkOrderSearchModule)
            },
            {
                path: 'to-dos',
                loadChildren: () => import('../to-do/to-do.module').then(m => m.ToDoModule)
            },
            {
                path: 'work-order',
                canActivate: [AutoDownloadsInterceptorGuard],
                children: [
                    {
                        path: ':id',
                        children: [
                            {
                                path: '',
                                pathMatch: 'prefix',
                                redirectTo: 'overview',
                            },
                            {
                                path: 'overview',
                                component: WorkOrderOverviewComponent
                            },
                            {
                                path: 'mass-update',
                                component: MassUpdateComponent,
                                canActivate: [IsTechnicianGuard]
                            },
                            {
                                path: 'calibration',
                                children: [
                                    {
                                        path: ':equipmentId',
                                        children: [
                                            {
                                                path: '',
                                                canDeactivate: [NavAwayInterceptorGuard],
                                                component: CalibrationComponent
                                            },
                                            {
                                                path: 'equipment-history',
                                                component: EquipmentHistoryWebComponent
                                            }
                                        ]
                                    }
                                ],
                            }
                        ],
                    },
                ]
            },
            {
                path: 'reports',
                canActivate: [OnlineFeatureGuard],
                loadChildren: () => import('@app/modules/report/report.module').then(m => m.ReportModule),
            },
            {
                path: 'equipment',
                canActivate: [OnlineFeatureGuard],
                loadChildren: () => import('@app/modules/equipment/equipment.module').then(m => m.EquipmentModule)
            },
            {
                path: 'maintenance-plan',
                canActivate: [OnlineFeatureGuard],
                loadChildren: () => import('@app/modules/maintenance-plan/maintenance-plan.module').then(m => m.MaintenancePlanModule)
            },
            {
                path: 'test-equipment',
                canActivate: [OnlineFeatureGuard],
                loadChildren: () => import('@app/modules/test-equipment/test-equipment.module').then(m => m.TestEquipmentModule)
            },
            {
                path: 'users',
                canActivate: [OnlineFeatureGuard],
                loadChildren: () => import('@app/modules/user/user.module').then(m => m.UserModule)
            },
            {
                path: 'custom-forms',
                canActivate: [OnlineFeatureGuard],
                loadChildren: () => import('@app/modules/flamingo/flamingo.module').then(m => m.FlamingoModule)
            },
            {
                path: 'online-restricted',
                component: OnlineRestrictedComponent
            },
            {
                path: 'email-subscription',
                canActivate: [OnlineFeatureGuard],
                loadChildren: () => import('@app/modules/email-subscription/email-subscription.module').then(m => m.EmailSubscriptionModule)
            }
        ],
    },
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class HomeRoutingModule { }
