import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Store } from '@ngrx/store'
import { combineLatest, Observable, of } from 'rxjs'
import { catchError, filter, map, tap, timeout } from 'rxjs/operators'

import { AppState } from '@app/store/app.store'
import { GetCurrentUserAction } from '@app/store/identity/identity.actions'
import { currentUser } from '@app/store/identity/identity.selectors'
import { isNotAValue } from '@app/utils/app-utils.function'
import { environment } from '@environments/environment'
import { AuthenticatedGuard } from './authenticated.guard'

/**
 * Check if the user has the permission to access the currently selected plant.
 * Redirect to '/unauthorized' otherwise.
 *
 * Will load `currentUser` into the `@ngrx` store so this should run before
 * any other guards that rely on the `currentUser` value.
 */
@Injectable({
    providedIn: 'root'
})
export class PlantAccessAuthorizedGuard implements CanActivate {

    private hasAuthPassed = false

    constructor(
        private router: Router,
        private store: Store<AppState>,
        private authGuard: AuthenticatedGuard
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean | UrlTree> {

        return combineLatest([
            this.store.select(currentUser),
            this.authGuard.canActivate(route, state)
        ]).pipe(
            filter(([, authGuardPassed]) => authGuardPassed === true),
            tap(() => this.hasAuthPassed = true),
            tap(([user, _]) => {
                if (!user) {
                    // Current user not yet been cached. Ask @ngrx to load it.
                    this.store.dispatch(new GetCurrentUserAction())
                }
            }),
            filter(([user, _]) => !!user),
            timeout(environment.authTimeout),
            map(([user, _]) => {
                const hasAccess = !isNotAValue(user) && user.plantCodes.includes(user.preferredPlantCode)
                if (!hasAccess) {
                    return this.router.parseUrl('/unauthorized')
                }
                return hasAccess
            }),
            catchError((e) => {
                console.error('Plant Access Guard Timeout.'
                    + this.hasAuthPassed ? 'Unable to authenticate user.' : 'Unable to get current user from /ME')

                if (environment.development) {
                    console.error(e)
                }
                return of(this.router.parseUrl('/unauthorized'))
            })
        )
    }
}
