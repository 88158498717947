import { Component, EventEmitter, Output, Input } from '@angular/core'
import { OfflineService } from '@app/services/offline.service'

@Component({
    selector: 'app-header-mobile',
    templateUrl: './header-mobile.component.html',
    styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent {
    @Input() isMenuActive = false
    @Output() menuToggled = new EventEmitter<boolean>()

    constructor(public offlineService: OfflineService) { }

    public toggleMenu(): void {
        // TODO: handle this by object/service/store
        this.isMenuActive = !this.isMenuActive
        this.menuToggled.next(this.isMenuActive)
    }
}
